import { Box, FormControlLabel, InputLabel, Typography } from '@mui/material';
import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import PieChartCommon from 'components/Common/PieChartCommon';
import SkeletonCharts from 'components/Common/SkeletonCharts';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useClientSettingsStyle } from 'components/Dashboard/ClientSettings/ClientSettingsStyle';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import randomColor from 'randomcolor';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getDataPlantHealthAnalytic, getSizePlantHealthAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSizePlantHealthList, mapViewSelector, setDataPlantHealth } from 'store/slices/mapViewSlice';
import PlantHealthCardLayer from '../../Layer/PlantHealth';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import InfoTable from '../CropCover/InfoTable';

const PlantHealthAnalytics = () => {
  const dispatch = useAppDispatch();
  const classes = useClientSettingsStyle();

  // react redux
  const { analyticId, sizePlantHealthSelected, dataPlantHealth, sizePlantHealthList } = useAppSelector(mapViewSelector);

  //state
  const [chartMode, setChartMode] = useState(false);

  useQuery([QUERY_KEY.PLANT_HEALTH_ANALYTIC, analyticId], () => getSizePlantHealthAnalytic(analyticId || ''), {
    enabled: !!analyticId && isEmpty(sizePlantHealthList),
    onSuccess: (response) => {
      if (response.data) {
        dispatch(changeSizePlantHealthList(response.data));
      }
    },
  });

  const { data: dataCropType } = useQuery(
    [QUERY_KEY.SIZE_PLANT_HEALTH_ANALYTIC, analyticId, sizePlantHealthSelected],
    () => getDataPlantHealthAnalytic(analyticId || '', sizePlantHealthSelected + ''),
    { enabled: !!analyticId && !!sizePlantHealthSelected }
  );

  // if all items of 'dataPlantHealth' are zero/empty (the 'totalTree' is 0)
  // hidden the "Tree Count" column
  const hasTreeCount = !!(dataPlantHealth.length && dataPlantHealth.some((item) => item.totalTree > 0));

  const dataPie = useMemo(
    () =>
      dataPlantHealth.map((_item) => ({
        name: _item.type,
        value: _item.lduseArea,
        totalTree: _item.totalTree,
      })),
    [dataPlantHealth]
  );

  const getDataChart = useMemo(
    () =>
      dataPlantHealth.map((_item) => ({
        name: _item.type,
        value: chartMode ? _item.totalTree : _item.lduseArea,
      })),
    [chartMode, dataPlantHealth]
  );

  const dataPlanHealthColors = useMemo(() => {
    if (!dataCropType?.data) {
      return {
        colors: [],
        data: [],
      };
    }

    const colors: string[] = []; // create list colors to pie chart

    // add corresponding color to each data type
    const updateData = dataCropType.data.map((item: any, index: number) => {
      const colorRandom = randomColor({ luminosity: 'dark' });
      const _color = item.color || get(DIFFERENT_COLOR, `[${index}]`, colorRandom);

      colors.push(_color);

      return {
        ...item,
        visible: true,
        color: _color,
      };
    });

    return {
      colors,
      data: updateData,
    };
  }, [dataCropType?.data]);

  useEffect(() => {
    // set data plan health to store
    dispatch(setDataPlantHealth(dataPlanHealthColors.data));
  }, [dataPlanHealthColors.data, dispatch]);

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD}
      analyticTab={
        <>
          {dataPlantHealth.length > 0 ? (
            <>
              <PieChartCommon
                title="Plant health field"
                dataChart={getDataChart}
                colorCharts={dataPlanHealthColors.colors}
              />
              {hasTreeCount && (
                <>
                  <InputLabel sx={{ marginLeft: '25px', fontSize: '14px' }}>Chart Mode:</InputLabel>
                  <FormControlLabel
                    sx={{ marginLeft: '30px' }}
                    control={
                      <SwitchCustom
                        checked={chartMode}
                        onClick={() => {
                          setChartMode(!chartMode);
                        }}
                      />
                    }
                    label={<Typography className={classes.textSetting}>{chartMode ? 'Tree Count' : 'Area'}</Typography>}
                  />
                </>
              )}
              <Box component={'div'} sx={{ marginTop: '30px' }}>
                <InfoTable dataPie={dataPie} hasTreeCount={hasTreeCount} />
              </Box>
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<PlantHealthCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD} />}
      isShowDownloadButton={dataPlantHealth.length > 0}
    />
  );
};

export default PlantHealthAnalytics;
