export const MapViewIcon = ({ isSelected }: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={isSelected ? '#23BE6A' : '#737373'}
      xmlns="http://www.w3.org/2000/svg">
      <g id="Vector">
        <mask
          id="path-1-outside-1_6500_134477"
          maskUnits="userSpaceOnUse"
          x="0.000183105"
          y="0"
          width="18"
          height="18"
          fill={isSelected ? '#23BE6A' : '#737373'}>
          <rect fill={isSelected ? '#23BE6A' : '#737373'} x="0.000183105" width="18" height="18" />
          <path d="M16.4288 1C16.34 0.999967 16.2524 1.02059 16.1731 1.06027L11.8429 3.22596L6.94638 1.04942C6.93838 1.046 6.92924 1.0454 6.92066 1.04199C6.89863 1.03375 6.8761 1.02689 6.85323 1.02143C6.8305 1.0153 6.8074 1.01051 6.78409 1.00713C6.73769 1.00331 6.69105 1.00331 6.64468 1.00713C6.62137 1.01051 6.59827 1.0153 6.57554 1.02143C6.55267 1.02689 6.53013 1.03375 6.5081 1.04199C6.49953 1.0454 6.49039 1.04597 6.48239 1.04942L1.33962 3.33511C1.13317 3.42678 1.00015 3.63148 1.00018 3.85735V16.4286C1.00028 16.6219 1.09808 16.8021 1.26017 16.9074C1.42229 17.013 1.62676 17.0293 1.80357 16.9509L6.71435 14.768L11.6251 16.9509C11.6337 16.9543 11.6428 16.9509 11.6514 16.956C11.7898 17.0179 11.9486 17.0143 12.084 16.9463C12.0931 16.9423 12.1034 16.9463 12.1125 16.94L16.6839 14.6543C16.8776 14.5575 17 14.3595 16.9999 14.1429V1.5717C17.0001 1.2561 16.7443 1.00013 16.4288 1ZM6.14295 13.7715L2.14301 15.5492V4.2288L6.14295 2.45109V13.7715ZM11.2857 15.5492L7.28578 13.7715V2.45109L11.2857 4.22876V15.5492ZM15.8571 13.7898L12.4286 15.504V4.21048L15.8571 2.49622V13.7898V13.7898Z" />
        </mask>
        <path
          d="M16.4288 1C16.34 0.999967 16.2524 1.02059 16.1731 1.06027L11.8429 3.22596L6.94638 1.04942C6.93838 1.046 6.92924 1.0454 6.92066 1.04199C6.89863 1.03375 6.8761 1.02689 6.85323 1.02143C6.8305 1.0153 6.8074 1.01051 6.78409 1.00713C6.73769 1.00331 6.69105 1.00331 6.64468 1.00713C6.62137 1.01051 6.59827 1.0153 6.57554 1.02143C6.55267 1.02689 6.53013 1.03375 6.5081 1.04199C6.49953 1.0454 6.49039 1.04597 6.48239 1.04942L1.33962 3.33511C1.13317 3.42678 1.00015 3.63148 1.00018 3.85735V16.4286C1.00028 16.6219 1.09808 16.8021 1.26017 16.9074C1.42229 17.013 1.62676 17.0293 1.80357 16.9509L6.71435 14.768L11.6251 16.9509C11.6337 16.9543 11.6428 16.9509 11.6514 16.956C11.7898 17.0179 11.9486 17.0143 12.084 16.9463C12.0931 16.9423 12.1034 16.9463 12.1125 16.94L16.6839 14.6543C16.8776 14.5575 17 14.3595 16.9999 14.1429V1.5717C17.0001 1.2561 16.7443 1.00013 16.4288 1ZM6.14295 13.7715L2.14301 15.5492V4.2288L6.14295 2.45109V13.7715ZM11.2857 15.5492L7.28578 13.7715V2.45109L11.2857 4.22876V15.5492ZM15.8571 13.7898L12.4286 15.504V4.21048L15.8571 2.49622V13.7898V13.7898Z"
          fill={isSelected ? '#23BE6A' : '#737373'}
        />
        <path
          d="M16.4288 1C16.34 0.999967 16.2524 1.02059 16.1731 1.06027L11.8429 3.22596L6.94638 1.04942C6.93838 1.046 6.92924 1.0454 6.92066 1.04199C6.89863 1.03375 6.8761 1.02689 6.85323 1.02143C6.8305 1.0153 6.8074 1.01051 6.78409 1.00713C6.73769 1.00331 6.69105 1.00331 6.64468 1.00713C6.62137 1.01051 6.59827 1.0153 6.57554 1.02143C6.55267 1.02689 6.53013 1.03375 6.5081 1.04199C6.49953 1.0454 6.49039 1.04597 6.48239 1.04942L1.33962 3.33511C1.13317 3.42678 1.00015 3.63148 1.00018 3.85735V16.4286C1.00028 16.6219 1.09808 16.8021 1.26017 16.9074C1.42229 17.013 1.62676 17.0293 1.80357 16.9509L6.71435 14.768L11.6251 16.9509C11.6337 16.9543 11.6428 16.9509 11.6514 16.956C11.7898 17.0179 11.9486 17.0143 12.084 16.9463C12.0931 16.9423 12.1034 16.9463 12.1125 16.94L16.6839 14.6543C16.8776 14.5575 17 14.3595 16.9999 14.1429V1.5717C17.0001 1.2561 16.7443 1.00013 16.4288 1ZM6.14295 13.7715L2.14301 15.5492V4.2288L6.14295 2.45109V13.7715ZM11.2857 15.5492L7.28578 13.7715V2.45109L11.2857 4.22876V15.5492ZM15.8571 13.7898L12.4286 15.504V4.21048L15.8571 2.49622V13.7898V13.7898Z"
          stroke={isSelected ? '#23BE6A' : '#737373'}
          strokeWidth="0.4"
          mask="url(#path-1-outside-1_6500_134477)"
        />
      </g>
    </svg>
  );
};
