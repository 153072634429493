import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface IAnalyticsConfigSlice {
  sensorId: string | null;
  typeOfAnalytics: string | null;
  listRasterPagination: any[];
  pageRaster: number;
}

const initialState: IAnalyticsConfigSlice = {
  sensorId: null,
  typeOfAnalytics: null,
  listRasterPagination: [],
  pageRaster: 1,
};

export const analyticsConfigSlice = createSlice({
  name: 'analyticConfig',
  initialState,
  reducers: {
    changeSensorSelected: (state, action: PayloadAction<{ sensor: string | null; type: string | null }>) => {
      state.sensorId = action.payload.sensor;
      state.typeOfAnalytics = action.payload.type;
    },
    changeRasterPagination: (state, action: PayloadAction<any>) => {
      state.listRasterPagination = action.payload;
    },
    resetRasterPagination: (state) => {
      state.listRasterPagination = [];
    },
    changePageRasterPagination: (state, action: PayloadAction<number>) => {
      state.pageRaster = action.payload;
    }
  },
});

export const { changeSensorSelected, changeRasterPagination, resetRasterPagination, changePageRasterPagination } = analyticsConfigSlice.actions;

export const anaLyticConfigSelector = (state: RootState) => state.analyticsConfig;

export default analyticsConfigSlice.reducer;
