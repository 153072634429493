import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Typography } from '@mui/material';
import { MapEnum } from 'common/defines/constants';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import StreamLineOrderCollapse from 'components/SwitchRasterComp/VectorCollapse/StreamLineOrderCollapse';
import VectorContourCollapse from 'components/SwitchRasterComp/VectorCollapse/VectorContourCollapse';
import { useState } from 'react';

const VectorCollapseItelligent = () => {
  const [isShowVector, setIsShowVector] = useState<boolean>(false);
  const classes = useLayerSelectCardStyle();

  return (
    <>
      <Button
        startIcon={
          isShowVector ? (
            <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
          ) : (
            <ChevronRightIcon style={{ fontWeight: 'bold' }} />
          )
        }
        onClick={() => setIsShowVector(!isShowVector)}
        classes={{ root: classes.buttonTextStyle }}
        style={{ width: '100%' }}>
        <Typography
          className={classes.buttonTextStyle}
          style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '13px !important' }}>
          Vector
        </Typography>
      </Button>
      {/* collapse contour */}
      <VectorContourCollapse
        isShowVector={isShowVector}
        isShowListVectorContour={true}
        mode={MapEnum.CROP_INTELLIGENT}
      />
      <StreamLineOrderCollapse isShowVector={isShowVector} mode={MapEnum.CROP_INTELLIGENT} />
    </>
  );
};

export default VectorCollapseItelligent;
