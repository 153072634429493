import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, MenuProps, SxProps, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { QUERY_KEY } from 'constants/constants';
import { CSSProperties, useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryMe } from 'services/clients/apiClient.services';

type ClientMenuProps = {
  open: boolean;
  anchorEl: null | HTMLElement;
  onOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  onDelete?: () => void;
  handleEdit?: () => void;
  mainMenu: boolean;
  styles?: CSSProperties | undefined;
  sx?: SxProps<Theme> | undefined;
} & MenuProps;

export const ClientMenu = ({
  open,
  anchorEl,
  onOpen,
  onDelete,
  onClose,
  mainMenu,
  handleEdit,
  styles,
  sx,
  ...props
}: ClientMenuProps) => {
  const { data: checkSuperAdmin } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: false,
  });
  // superUser is same as superAdmin except for the permission to delete client
  const isSuperUser = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperUser;
  }, [checkSuperAdmin]);

  const theme = useTheme();

  return (
    <>
      <IconButton
        sx={{
          borderRadius: theme.palette.mode === 'dark' ? '50%' : '0px',
          backgroundColor: mainMenu ? 'transparent' : theme.palette.mode === 'dark' ? '#transparent' : '#F2F2F2',
          '&:hover': {
            backgroundColor: mainMenu ? 'transparent' : theme.palette.mode === 'dark' ? '#transparent' : '#F2F2F2',
          },
          ...sx,
        }}
        aria-label="more"
        id="client-menu"
        aria-controls={open ? 'client-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={onOpen}>
        <MoreVertIcon
          sx={{
            height: '100%',
            '&:hover': {
              backgroundColor: mainMenu ? 'transparent' : theme.palette.mode === 'dark' ? '#transparent' : '#F2F2F2',
            },
          }}
        />
      </IconButton>
      {open && (
        <Menu
          id="client-menu"
          MenuListProps={{
            'aria-labelledby': 'client-menu',
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={onClose}
          sx={{
            '.MuiMenu-paper': {
              boxShadow: mainMenu ? '1px 1px 2px rgb(0 0 0 / 10%)' : '2px 2px 3px rgb(0 0 0 / 20%)',
            },
          }}
          {...props}>
          {handleEdit && (
            <MenuItem onClick={handleEdit}>
              <Typography
                sx={{
                  fontFamily: 'Barlow',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: theme.palette.mode === 'dark' ? '#fff' : '#616161',
                }}>
                Edit
              </Typography>
            </MenuItem>
          )}
          {!isSuperUser && (
            <MenuItem onClick={onDelete}>
              <Typography
                sx={{
                  fontFamily: 'Barlow',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: theme.palette.mode === 'dark' ? '#fff' : '#616161',
                }}>
                Delete
              </Typography>
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};
