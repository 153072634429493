import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { getClientList, getClientSummary, queryMe } from 'services/clients/apiClient.services';

interface Client {
  avatar: string | undefined;
  id: string;
  clientName: string;
  checked: boolean;
  email: string;
}

interface ClientsDialogProps {
  onClose: () => void;
  open: boolean;
  listIdClients: string[];
  setListIdClients: (arg: string[]) => void;
}

const ClientsDialog: FC<ClientsDialogProps> = ({ open, onClose, listIdClients, setListIdClients }) => {
  const theme = useTheme();
  const titleDialog = useRef<HTMLDivElement>(null);
  const [clients, setClients] = useState<any>([]);
  const [searchClients, setSearchClients] = useState<any>([]);
  const [allChecked, setAllChecked] = useState(false);
  const queryClient = useQueryClient();

  //   Call API to get list of clients
  useQuery(QUERY_KEY.CLIENT_LIST_ALL, () => getClientList(), {
    // enabled: (isSuperAdmin || isSuperUser) && !clientId,
    enabled: open,
    onSuccess: (data) => {
      const clientNameData = data.data.map((client: any) => {
        return {
          id: client._id,
          clientName: client.name,
          avatar: client.avatar,
          email: client.email,
          checked: listIdClients.includes(client._id),
        };
      });
      setClients(clientNameData);
      setSearchClients(clientNameData);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // useQuery([QUERY_KEY.CLIENT_LIST_IN_SUMMARY], () => getClientInSummaryList(), {
  //   onSuccess: (data) => {
  //     if (data) {
  //       const checkedList = data.data;
  //       setCheckedClients(checkedList);
  //       const newClients = clients.filter((client: any) => ({ ...client, checked: checkedList.includes(client.id) }));
  //       setClients(newClients);
  //     }
  //   },
  //   onError: (error) => {
  //     console.log('error', error);
  //   },
  // });

  const debounceSearchClient = debounce((value: string) => {
    handleFilterClientData(value);
  }, 500);

  const handleFilterClientData = (value: string) => {
    if (value) {
      const searchClient = clients.filter((client: Client) => {
        return (
          client.clientName.toLowerCase().includes(value.toLowerCase()) ||
          client.email.toLowerCase().includes(value.toLowerCase())
        );
      });
      setSearchClients(searchClient);
    } else {
      setSearchClients(clients);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const listFilterIds = useMemo(() => {
    return searchClients.filter((client: Client) => client.checked).map((client: Client) => client.id);
  }, [searchClients]);

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const sumAreaMode = useMemo(() => {
    return userInfo?.data.sumAreaMode;
  }, [userInfo]);

  // mutation update list checked clients
  const updateListCheckedClients = useMutation(() => getClientSummary({ ids: listFilterIds, sumAreaMode }), {
    onSuccess: (data) => {
      setListIdClients(listFilterIds);
      toast.success('Update list clients successfully');
      onClose();
    },
    onError: (error: any) => {
      toast.error(error.data.message[0]);
    },
  });

  const handleSubmitForm = () => {
    updateListCheckedClients.mutate();
  };

  const handleToggle = (id: string) => {
    setSearchClients(
      searchClients.map((client: Client) => {
        if (client.id === id) {
          return {
            ...client,
            checked: !client.checked,
          };
        }
        return client;
      })
    );
    setClients(
      clients.map((client: Client) => {
        if (client.id === id) {
          return {
            ...client,
            checked: !client.checked,
          };
        }
        return client;
      })
    );
  };

  const isAllChecked = useMemo(() => {
    return searchClients.every((client: Client) => client.checked);
  }, [searchClients]);

  useEffect(() => {
    setAllChecked(searchClients.every((client: Client) => client.checked));
  }, [searchClients]);

  const handleCheckedAll = () => {
    if (isAllChecked) {
      setSearchClients(
        searchClients.map((client: Client) => {
          return {
            ...client,
            checked: false,
          };
        })
      );
      setClients(
        clients.map((client: Client) => {
          const newClients = searchClients.find((_client: Client) => client.id === _client.id);
          if (newClients) {
            return {
              ...client,
              checked: false,
            };
          } else {
            return client;
          }
        })
      );
    } else {
      setSearchClients(
        searchClients.map((client: Client) => {
          return {
            ...client,
            checked: true,
          };
        })
      );
      setClients(
        clients.map((client: Client) => {
          const newClients = searchClients.find((_client: Client) => client.id === _client.id);
          if (newClients) {
            return {
              ...client,
              checked: true,
            };
          } else {
            return client;
          }
        })
      );
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& MuiPaper-root': {
            overflowY: 'scroll',
            background: theme.palette.background.paper,
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        },
      }}>
      <DialogTitle
        ref={titleDialog}
        sx={{
          color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
          fontSize: '20px',
          fontWeight: '600',
          background: theme.palette.background.paper,
        }}>
        List of Clients
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          p: 0,
          position: 'absolute',
          right: 8,
          top: 8,
          '.MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box
          className="FormControlAll"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Button onClick={handleCheckedAll} color="primary">
            {allChecked && isAllChecked ? 'Uncheck All' : 'Check All'}
          </Button>
          {/* Create an input to filter client name or emails */}
          <TextField
            type="text"
            placeholder="Search Clients..."
            sx={{
              m: '12px',
              // width: 'calc(100% - 24px)',
              height: '40px',
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '.MuiOutlinedInput-root': {
                height: '40px',
              },
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '5px',
            }}
            onChange={(e) => {
              debounceSearchClient(e.target.value);
            }}
            InputProps={{
              size: 'small',
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ height: '24px', width: '24px' }} />
                </InputAdornment>
              ),
              inputProps: {
                style: {
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '17px',
                },
              },
            }}
          />
        </Box>
        <Box className="ListControlClients">
          <List>
            <Scrollbars style={{ height: 400 }}>
              {searchClients.length > 0 ? (
                searchClients.map((client: Client) => (
                  <ListItem
                    key={client.id}
                    dense
                    button
                    sx={{
                      padding: '12px 0px',
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}>
                    <Checkbox checked={client.checked} onChange={() => handleToggle(client.id)} />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Box
                        sx={{
                          borderRadius: '50%',
                          border: `1px solid ${theme.palette.divider}`,
                          mr: '12px',
                        }}>
                        <Avatar alt={client?.clientName} src={client?.avatar} />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: '800',
                          }}>
                          {client.clientName}
                        </Typography>
                        <ListItemText primary={client.email} />
                      </Box>
                    </Box>
                    <ListItemSecondaryAction />
                  </ListItem>
                ))
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}>
                  <Typography
                    sx={{
                      fontWeight: '800',
                    }}>
                    No Clients Found
                  </Typography>
                </Box>
              )}
            </Scrollbars>
          </List>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
          fontSize: '20px',
          fontWeight: '600',
          background: theme.palette.background.paper,
        }}>
        <LoadingButton loading={updateListCheckedClients.isLoading} onClick={() => handleSubmitForm()}>
          Submit Change
        </LoadingButton>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientsDialog;
