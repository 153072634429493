import { Box, FormControlLabel, Typography } from '@mui/material';
import { MapEnum } from 'common/defines/constants';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeAllStreamlineOrderDisplay, rightBarSelector } from 'store/slices/rightBarSlice';
import SwitchStreamlineOrder from './SwitchStreamlineOrder';

interface SwitchStreamlineOrderListProps {
  mode: MapEnum;
  _id: string | undefined;
}

const SwitchStreamlineOrderList: FC<SwitchStreamlineOrderListProps> = ({ mode, _id }) => {
  const [isUnselectedAll, setIsUnselectedAll] = useState<boolean>(true);
  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { streamlineOrderDisplay } = useAppSelector(rightBarSelector);

  const handleChangeSelectAll = () => {
    setIsUnselectedAll(!isUnselectedAll);
    const newStreamlineOrderDisplay = streamlineOrderDisplay.map((item) => ({ ...item, visible: isUnselectedAll }));
    dispatch(changeAllStreamlineOrderDisplay(newStreamlineOrderDisplay));
  };

  return (
    <Box sx={{ mt: '8px' }}>
      <FormControlLabel
        control={<SwitchCustom checked={!isUnselectedAll} onClick={handleChangeSelectAll} />}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography className={classes.selectText}>
              {isUnselectedAll ? t('trans.select_all') : t('trans.unselect_all')}
            </Typography>
          </Box>
        }
      />
      {!!streamlineOrderDisplay.length &&
        streamlineOrderDisplay.map((item, index: number) => (
          <SwitchStreamlineOrder key={index} item={item} mode={mode} _id={_id} />
        ))}
    </Box>
  );
};

export default SwitchStreamlineOrderList;
