import { Box, Collapse, Grid } from '@mui/material';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import React, { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { getVacantSpot } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector, setDataVacantAreaSpot } from 'store/slices/mapViewSlice';

interface FilterProps {
  data: {
    name: string;
    parentId: string;
    _id: string;
  };
  analytics: string;
}

export const Filter = (props: FilterProps) => {
  const classes = useLayerSelectCardStyle();
  const [isDisplayExpand, setIsDisplayExpand] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const {
    sizeChlorophyllPaddySelected,
    sizeTilerDensitySelected,
    sizeVigorPaddySelected,
    dataVacantAreaSpot,
    sizeStressFieldSelected,
    sizeWaterUptakeFieldSelected,
  } = useAppSelector(mapViewSelector);

  const dispatch = useAppDispatch();
  const handleGetVacantSpot = useMutation(
    ({ vacantId, size }: { vacantId: string; size: number }) => getVacantSpot(vacantId, size),
    {
      onSuccess: (data: any) => {
        dispatch(
          setDataVacantAreaSpot({
            ...data.data.vacantSpot,
            color: '#fc0303',
            visible: true,
            isNull: data.data.vacantSpot === null,
          })
        );
      },
    }
  );

  const sizeAnalyticsSelected = useMemo(() => {
    switch (props.analytics) {
      case 'chlorophyllPaddy':
        return sizeChlorophyllPaddySelected;
      case 'tilerDensity':
        return sizeTilerDensitySelected;
      case 'vigorPaddy':
        return sizeVigorPaddySelected;
      case 'stressField':
        return sizeStressFieldSelected;
      case 'waterUptakeField':
        return sizeWaterUptakeFieldSelected;
      default:
        return 300;
    }
  }, [
    props.analytics,
    sizeChlorophyllPaddySelected,
    sizeTilerDensitySelected,
    sizeVigorPaddySelected,
    sizeStressFieldSelected,
    sizeWaterUptakeFieldSelected,
  ]);

  return (
    <div>
      <Collapse in={isDisplayExpand} timeout="auto" unmountOnExit sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Box sx={{ px: 1.5 }}></Box>
        </Grid>
      </Collapse>
    </div>
  );
};
