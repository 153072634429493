import { IStreamlineOrderData, SettingUIName } from 'common/defines/clients';
import { MapEnum } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getStreamlineOrderList } from 'services/analytics/apiAnalyticsConfig.services';
import { getAllStreamlineOrderIntelligent } from 'services/workspaces';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';
import { SortDateEnum } from '../RightBar/IssuesTab/interfaces';

interface StreamlineOrderLayerProps {
  mode: MapEnum;
}

const StreamlineOrderLayer: FC<StreamlineOrderLayerProps> = ({ mode }) => {
  const { streamlineOrderDisplay, streamlineOrderSensorId: sensorId } = useAppSelector(rightBarSelector);
  const { selectedTaskId } = useAppSelector(tilingMapSelector);

  const { clientSetting } = useQueryClientSetting();
  const uiSetting = clientSetting?.uiSetting;
  const strokeWidthValue = uiSetting && uiSetting.find((item) => item.name === SettingUIName.STROKE_WIDTH)?.value;

  const { data: streamlineInfo } = useQuery(
    [QUERY_KEY.GET_STREAMLINE_ORDER_LIST, sensorId],
    () => getStreamlineOrderList({ page: 1, perPage: 20, sortDate: SortDateEnum.DESC, sensorId }),
    {
      enabled: !!sensorId && mode === MapEnum.MAP_VIEW,
    }
  );

  const { data: streamlineIntelligent } = useQuery(
    [QUERY_KEY.GET_STREAMLINE_ORDER_INTELLIGENT_LIST, selectedTaskId],
    () => getAllStreamlineOrderIntelligent(selectedTaskId),
    {
      enabled: !!selectedTaskId && mode === MapEnum.CROP_INTELLIGENT,
    }
  );

  const streamlineOrderList: IStreamlineOrderData[] = useMemo(() => {
    return mode === MapEnum.MAP_VIEW ? streamlineInfo?.data?.docs : streamlineIntelligent?.data;
  }, [mode, streamlineInfo?.data?.docs, streamlineIntelligent?.data]);

  const convertDataVector = (): GeoJSON.FeatureCollection => {
    return {
      type: 'FeatureCollection',
      features: streamlineOrderList
        ?.filter((item) => {
          const listVisibleOrder = streamlineOrderDisplay.filter((ele) => ele.visible).map((ele) => ele.order);
          return listVisibleOrder.includes(item.order);
        })
        .map(
          (item) =>
            ({
              type: 'Feature',
              geometry: item.geometry,
              properties: {
                color: item.colorCode,
              },
            } as GeoJSON.Feature)
        ),
    };
  };

  return (
    <>
      {streamlineOrderList?.length && (
        <Source id="streamline_order_source" type="geojson" data={convertDataVector()} lineMetrics={true}>
          <Layer
            id={`streamline_order`}
            type="line"
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
              visibility: 'visible',
            }}
            paint={{
              'line-opacity': 1,
              'line-width': strokeWidthValue || 1.4,
              'line-color': ['get', 'color'],
            }}
          />
        </Source>
      )}
    </>
  );
};

export default StreamlineOrderLayer;
