import { Avatar, Box, Skeleton, Typography } from '@mui/material';
import { getCurrentLevel } from 'common/utils/util';
import { QUERY_KEY } from 'constants/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Marker } from 'react-map-gl';
import { useQueryClient } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeCropTypeLevelId,
  changePointCenterSelected,
  changePointIdSelected,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import { PopupInfo } from './PopupInfo';
interface IMarkerPoint {
  item: any;
  pointCenter: { longitude: number; latitude: number };
}

export const MarkerPoint = ({ item, pointCenter }: IMarkerPoint) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { pointIdSelected, cropType } = useAppSelector(mapViewSelector);
  const [onEnterLayer, setOnEnterLayer] = useState<boolean>(false);
  const [isMouseEnterPopup, setIsMouseEnterPopup] = useState<boolean>(false);

  useEffect(() => {
    if (onEnterLayer) {
      dispatch(changePointIdSelected(item._id));
    } else {
      dispatch(changePointIdSelected(null));
    }
  }, [onEnterLayer, dispatch, item]);

  const currentLevel = useMemo(() => {
    const levelList = queryClient.getQueryData([QUERY_KEY.LEVEL_LIST, cropType]);
    if (levelList) {
      return getCurrentLevel(levelList, item._id);
    }
    return null;
  }, [queryClient, cropType, item]);

  const isChildrenLevelLasted = useMemo(() => {
    return currentLevel && currentLevel.isLastLevel;
  }, [currentLevel]);

  const onDBPoint = useCallback(() => {
    dispatch(
      changeCropTypeLevelId({ cropType: item.cropType._id, levelId: item._id, isLastLevel: isChildrenLevelLasted })
    );
  }, [item, isChildrenLevelLasted, dispatch]);

  const icon = useMemo(() => {
    return item?.cropType.icon || '';
  }, [item]);

  const cropTypeColor = useMemo(() => {
    return item?.cropType.color || '#1EBADB';
  }, [item]);

  return (
    <>
      <Marker
        longitude={pointCenter.longitude}
        key={`${item._id}-maker`}
        latitude={pointCenter.latitude}
        style={{ zIndex: 9 }}>
        <Typography
          component="div"
          onMouseEnter={() => setOnEnterLayer(true)}
          onMouseLeave={() => setOnEnterLayer(false)}
          onClick={() => {
            dispatch(changePointCenterSelected(item._id));
          }}
          onDoubleClick={() => onDBPoint()}
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            border: '2px solid white',
            backgroundColor: cropTypeColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}>
          {icon ? (
            <Avatar
              src={icon}
              sx={{
                height: '20px',
                width: '20px',
              }}
            />
          ) : (
            <Skeleton variant="circular" width={'20px'} height={'20px'} />
          )}
        </Typography>
      </Marker>
      {((onEnterLayer && pointIdSelected === item._id && pointCenter) || isMouseEnterPopup) && (
        <Marker
          key={`${item._id}_popup`}
          longitude={pointCenter.longitude}
          latitude={pointCenter.latitude}
          offset={[-0, -72]}
          style={{ zIndex: 9 }}>
          <Box onMouseEnter={() => setIsMouseEnterPopup(true)} onMouseLeave={() => setIsMouseEnterPopup(false)}>
            <PopupInfo
              area={item?.area || 0}
              name={item.name}
              cropType={item.cropType}
              count={item?.totalTree}
              icon={icon}
              iconColor={cropTypeColor}
              noOfFields={item?.totalLastLevel || 0}
            />
          </Box>
        </Marker>
      )}
    </>
  );
};
