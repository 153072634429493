import { Box, FormControlLabel, InputLabel, Typography } from '@mui/material';
import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { changeSizeChlorophyllPaddyList, mapViewSelector, setDataChlorophyllPaddy } from 'store/slices/mapViewSlice';
import { get, isEmpty } from 'lodash';
import {
  getChlorophyllPaddyAnalytic,
  getSizeChlorophyllPaddyAnalytic,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMemo, useState } from 'react';

import ChlorophyllPaddyCardLayer from '../../Layer/ChlorophyllPaddy';
import InfoTable from '../CropCover/InfoTable';
import PieChartCommon from 'components/Common/PieChartCommon';
import { QUERY_KEY } from 'constants/constants';
import SkeletonCharts from 'components/Common/SkeletonCharts';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import randomColor from 'randomcolor';
import { useClientSettingsStyle } from 'components/Dashboard/ClientSettings/ClientSettingsStyle';
import { useQuery } from 'react-query';

const ChlorophyllPaddyAnalytics = () => {
  const dispatch = useAppDispatch();
  const [chartMode, setChartMode] = useState(false);
  const classes = useClientSettingsStyle();

  const { analyticId, sizeChlorophyllPaddySelected, dataChlorophyllPaddy, sizeChlorophyllPaddyList } =
    useAppSelector(mapViewSelector);

  useQuery(
    [QUERY_KEY.CHLOROPHYLL_PADDY_ANALYTIC, analyticId],
    () => getSizeChlorophyllPaddyAnalytic(analyticId || ''),
    {
      enabled: !!analyticId && isEmpty(sizeChlorophyllPaddyList),
      onSuccess: (response) => {
        if (response.data) {
          dispatch(changeSizeChlorophyllPaddyList(response.data));
        }
      },
    }
  );

  const hasTreeCount = !!(dataChlorophyllPaddy?.length && dataChlorophyllPaddy.some((item: any) => item.totalTree > 0))

  const { data: dataCropType } = useQuery(
    [QUERY_KEY.SIZE_CHLOROPHYLL_PADDY_ANALYTIC, analyticId, sizeChlorophyllPaddySelected],
    () => getChlorophyllPaddyAnalytic(analyticId || '', sizeChlorophyllPaddySelected + ''),
    { enabled: !!analyticId && !!sizeChlorophyllPaddySelected }
  );

  const dataPie = useMemo(() => {
    if (dataChlorophyllPaddy) {
      return dataChlorophyllPaddy.map((_item: any) => ({
        name: _item.type,
        value: _item.lduseArea,
        totalTree: _item.totalTree,
      }));
    }
    return [];
  }, [dataChlorophyllPaddy]);

  const getDataChart = useMemo(() => {
    return dataChlorophyllPaddy?.map((_item: any) => ({
      name: _item.type,
      value: chartMode ? _item.totalTree : _item.lduseArea,
    }));
  }, [chartMode, dataChlorophyllPaddy]);

  const colorChlorophyllPaddy = useMemo(() => {
    if (dataCropType?.data) {
      let colors: string[] = [];
      dispatch(
        setDataChlorophyllPaddy(
          dataCropType.data.map((item: any, index: number) => {
            const colorRandom = randomColor({ luminosity: 'dark' });
            const _color = item.color || get(DIFFERENT_COLOR, `[${index}]`, colorRandom);
            colors.push(_color);
            return {
              ...item,
              visible: true,
              color: _color,
            };
          })
        )
      );
      return colors;
    }
    return [];
  }, [dataCropType, dispatch]);

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD}
      analyticTab={
        <>
          {dataChlorophyllPaddy?.length > 0 ? (
            <>
              <PieChartCommon title="Chlorophyll Field" dataChart={getDataChart} colorCharts={colorChlorophyllPaddy} />
              {hasTreeCount && (
                <>
                  <InputLabel sx={{ marginLeft: '25px', fontSize: '14px' }}>Chart Mode:</InputLabel>
                  <FormControlLabel
                    sx={{ marginLeft: '30px' }}
                    control={
                      <SwitchCustom
                        checked={chartMode}
                        onClick={() => {
                          setChartMode(!chartMode);
                        }}
                      />
                    }
                    label={<Typography className={classes.textSetting}>{chartMode ? 'Tree Count' : 'Area'}</Typography>}
                  />
                </>
              )}
              <Box component={'div'} sx={{ marginTop: '30px' }}>
                <InfoTable dataPie={dataPie} hasTreeCount={hasTreeCount} />
              </Box>
            </>
          ) : (
            <>
              <SkeletonCharts count={1} />
            </>
          )}
        </>
      }
      layerTab={<ChlorophyllPaddyCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD} />}
      isShowDownloadButton={dataChlorophyllPaddy?.length > 0}
    />
  );
};

export default ChlorophyllPaddyAnalytics;
