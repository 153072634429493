import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Grid, IconButton, InputLabel, Stack, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { ButtonCustom } from './ButtonCustom';

interface ClientCardType {
  labelFileSelected: string;
  subLabelTypeFile: string;
  onSelectFile: (file: any) => void;
  acceptFile: string | RegExp;
  fileSelected: any;
  disabled?: boolean;
  customNameFile?: string;
}

const DropFileCustom = ({
  onSelectFile,
  acceptFile,
  labelFileSelected,
  subLabelTypeFile,
  fileSelected,
  disabled,
  customNameFile,
}: ClientCardType) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (files: any) => {
      const regex = new RegExp(acceptFile);
      if (regex.test(files[0].name)) {
        onSelectFile(files[0]);
      } else {
        toast.error('Drop file failed', { toastId: 1 });
      }
    },
  });

  const theme = useTheme();

  const formatBytes = useMemo(() => {
    const bytes = fileSelected?.size;
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }, [fileSelected]);

  return (
    <>
      <Stack className="container">
        {fileSelected ? (
          <>
            <InputLabel sx={{ fontSize: '14px' }} htmlFor="uncontrolled-native">
              {labelFileSelected}
            </InputLabel>
            <Grid container alignItems="center" xs={12}>
              <Grid item xs={1}>
                <InsertDriveFileIcon fontSize="large" color="info" />
              </Grid>
              <Grid item xs={9}>
                <Grid>
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      fontSize: '12px',
                    }}>
                    {fileSelected.name ? fileSelected.name : `${customNameFile}.pdf`}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography color="disabled" sx={{ fontSize: '12px' }}>
                    {moment(fileSelected.lastModifiedDate).format('YYYY-MM-DD')}, {formatBytes}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                {!disabled && (
                  <IconButton>
                    <ClearOutlinedIcon color="disabled" onClick={() => onSelectFile(null)} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <Stack
            {...getRootProps({ className: 'dropzone' })}
            sx={{
              flex: '1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px',
              borderRadius: '8px',
              border: theme.palette.mode === 'dark' ? '2px dashed red' : '2px dashed #bdbdbd',
              borderColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#bdbdbd',
              color: '#bdbdbd',
              outline: 'none',
              transition: 'border .24s ease-in-out',
            }}>
            <input {...getInputProps()} />
            <Stack sx={{ alignItems: 'center' }}>
              <CloudUploadOutlinedIcon />
            </Stack>
            <Typography sx={{ textAlign: 'center', mt: 1, mb: 1, color: 'black' }}>
              Drag and drop your files here
            </Typography>
            <ButtonCustom
              title="Browse File"
              border="0px"
              colorLabel="#FFF"
              sx={{ width: '100px' }}
              backgroundColor={theme.palette.primary.main}
              onClick={open}
            />
            <Typography sx={{ textAlign: 'center', mt: 1, mb: 1 }}>{subLabelTypeFile}</Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default DropFileCustom;
