import { useTheme } from '@mui/material';
import { lineString, multiLineString, nearestPointOnLine, point } from '@turf/turf';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';

interface PerpendicularLineProps {
  hoverInfo: any;
}

const PerpendicularLine: FC<PerpendicularLineProps> = ({ hoverInfo }) => {
  const theme = useTheme();
  const {
    properties: { longX, latY },
  } = hoverInfo || {};
  const rootPoint = point([longX, latY]);
  const { linesVectorDisplay } = useAppSelector(rightBarSelector);

  const [displayedLine] = linesVectorDisplay.filter((item) => item.visible);
  if (!displayedLine) return null;

  const { type, coordinates } = displayedLine?.geometry || {};
  let lines: any;
  if (type === 'MultiLineString') {
    lines = multiLineString(coordinates);
  } else if (type === 'LineString') {
    lines = lineString(coordinates);
  }

  const targetPoint = nearestPointOnLine(lines, rootPoint, { units: 'meters' });
  const showedLine = lineString([rootPoint?.geometry?.coordinates, targetPoint?.geometry?.coordinates]);

  const geojsonLine: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [showedLine],
  };

  return (
    rootPoint &&
    targetPoint && (
      <>
        <Source id="perpendicular_line_source" type="geojson" data={geojsonLine}>
          <Layer
            id="perpendicular_line_layer"
            type="line"
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
            }}
            paint={{
              'line-color': theme.palette.primary.main,
              'line-width': 2,
            }}
          />
        </Source>
      </>
    )
  );
};

export default PerpendicularLine;
