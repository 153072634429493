import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Box, styled, Typography } from '@mui/material';
import { RenderTree } from 'common/defines/clients';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { postLevelRecent } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeLevelId, mapViewSelector } from 'store/slices/mapViewSlice';
import { changeAnalyticName } from 'store/slices/rightBarSlice';
import { clearVigorAnalytics } from '../../../store/slices/map-view/vigorAnalytics';
import { useMapViewStyle } from '../MapViewStyle';
interface IStyledTreeItem {
  bgColor?: string;
  color?: string;
  labelInfo?: string;
  labelText: string;
  level: number;
  isLastLevel?: boolean;
  isFavoriteLocation?: boolean;
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    width: 'inherit',
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const StyledTreeItem = (props: TreeItemProps & IStyledTreeItem) => {
  const { bgColor, color, labelInfo, labelText, isLastLevel, ...other } = props;
  const dispatch = useAppDispatch();
  const classes = useMapViewStyle();
  const { levelId } = useAppSelector(mapViewSelector);

  const onSelected = useCallback(() => {
    if (levelId !== other.nodeId) {
      dispatch(changeLevelId({ levelId: other.nodeId, isLevelLasted: !!isLastLevel }));
      dispatch(changeAnalyticName(null));
      //reset vigor analytic
      dispatch(clearVigorAnalytics());
    }
  }, [dispatch, other, isLastLevel, levelId]);

  return (
    <StyledTreeItemRoot
      {...other}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }} onClick={() => onSelected()}>
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit" className={classes.labelInfo}>
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
    />
  );
};

export const LevelTreeRecent = (props: any) => {
  const { levels } = props;
  const { levelId } = useAppSelector(mapViewSelector);
  const [expanded, setExpanded] = useState<any>([]);

  useEffect(() => {
    setExpanded((item: any) => {
      return [...new Set([levelId, ...item])];
    });
  }, [levelId, setExpanded]);

  const setRecentLocation = useMutation((levelId: string) => postLevelRecent(levelId));

  const renderTree = (nodes: RenderTree) => {
    return (
      <StyledTreeItem
        level={nodes.level}
        key={nodes._id}
        nodeId={nodes._id}
        isLastLevel={nodes.isLastLevel}
        labelText={nodes.name}
        bgColor={'#6FE4A5'}
        color={'black'}
        onClick={() => {
          setRecentLocation.mutate(nodes._id);
        }}
        labelInfo={(nodes.children?.length || '') + ''}>
        {Array.isArray(nodes.children) ? nodes.children.map((node: any) => renderTree(node)) : null}
      </StyledTreeItem>
    );
  };

  return (
    <TreeView
      aria-label="structure"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={levelId ? [levelId] : []}
      onNodeToggle={(_, nodeIds: any) => setExpanded(nodeIds)}
      expanded={expanded}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ flexGrow: 1, mt: 2 }}>
      {levels && levels.map(renderTree)}
    </TreeView>
  );
};
