import { Box, useTheme } from '@mui/material';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import configs from 'constants/config';
import { DEFAULT_VIEWPORT, QUERY_KEY } from 'constants/constants';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { Map2dOptionEnum, Map3dOptionEnum } from 'interfaces/workspace';
import { Map, MercatorCoordinate } from 'mapbox-gl';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Layer } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getMinMaxZoom } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeFlyingToState, tilingMapSelector } from 'store/slices/tilingMapSlice';
import * as THREE from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const baseURl = configs.API_DOMAIN;

interface Mapbox3dProps {
  mapStyle: string;
  isShowNavbar: boolean;
  projectId: string;
  taskId: string;
}

const Mapbox3d: FC<Mapbox3dProps> = ({ mapStyle, isShowNavbar, projectId, taskId }) => {
  const [metaData, setMetaData] = useState<{ center: number[] }>();
  const [mapStyleCustom, setMapStyleCustom] = useState<string>(mapStyle);
  const [topModel, setTopModel] = useState<any>(null);

  const theme = useTheme();

  useEffect(() => {
    setMapStyleCustom('');
    setMapStyleCustom(mapStyle);
  }, [mapStyle]);
  const mapRef = useRef<any>(null);
  const {
    selectedFieldId,
    flyTo: { isDone: isFlyDone },
  } = useAppSelector(tilingMapSelector);

  const { selectedTask } = useQueryListTaskOfField();
  const { status, _id: fieldId, maxX, maxY, zoom } = selectedTask || {};
  const dispatch = useAppDispatch();

  const isEnableLogic = status && taskId && projectId && fieldId;
  const folderName = 'odm_texturing';
  const fileName = 'odm_textured_model_geo.glb';

  useQuery(
    [QUERY_KEY.GET_MIN_MAX_ZOOM, projectId, taskId],
    () => getMinMaxZoom(projectId!, taskId!, Map2dOptionEnum.dsm),
    {
      onSuccess(res) {
        setMetaData(res.data);
      },
      enabled: !!isEnableLogic,
    }
  );

  const renderCenterCoordinates = useCallback(() => {
    if (maxX && maxY && zoom) {
      return [Number(maxX), Number(maxY)];
    } else {
      return metaData?.center?.slice(0, 2) || [DEFAULT_VIEWPORT.longitude, DEFAULT_VIEWPORT.latitude];
    }
  }, [maxX, maxY, metaData?.center, zoom]);

  // centralized field the first time
  useEffect(() => {
    if (selectedFieldId) {
      mapRef.current?.flyTo({
        center: renderCenterCoordinates(),
        zoom: zoom || metaData?.center[2] || 20,
        essential: true,
        curve: 1,
      });
    }
  }, [maxX, maxY, metaData?.center, renderCenterCoordinates, selectedFieldId, zoom]);

  // centralized after click search button
  useEffect(() => {
    if (!isFlyDone) {
      mapRef.current?.flyTo({
        center: renderCenterCoordinates(),
        zoom: zoom || metaData?.center[2] || 20,
        essential: true,
        curve: 1,
      });
    }
    dispatch(changeFlyingToState({ isDone: true }));
  }, [dispatch, isFlyDone, maxX, maxY, metaData?.center, renderCenterCoordinates, zoom]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (map.getLayer('3d-model')) {
        map.removeLayer('3d-model');
      }
    }
  }, [selectedFieldId]);

  useEffect(() => {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader(dracoLoader);

    loader.load(
      `${baseURl}/field/3D/${projectId}/${taskId}/${folderName}/${fileName}?typeView=${Map3dOptionEnum.MODEL_3D}`,
      (gltf: any) => {
        const model = gltf.scene;
        if (model?.visible) {
          setTopModel(model);
        }
      }
    );
    return () => {
      setTopModel(null);
    };
  }, [projectId, taskId]);

  const modelOrigin = renderCenterCoordinates() as [number, number];
  const modelAltitude = -30;
  const modelAsMercatorCoordinate = MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);
  const modelRotate = [0, 0, 0];

  const modelTransform = {
    translateX: modelAsMercatorCoordinate.x,
    translateY: modelAsMercatorCoordinate.y,
    translateZ: modelAsMercatorCoordinate.z,
    rotateX: modelRotate[0],
    rotateY: modelRotate[1],
    rotateZ: modelRotate[2],
    scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
  };

  let camera = new THREE.Camera();
  let scene = new THREE.Scene();
  let ourMap: Map;
  let renderer: THREE.WebGLRenderer;

  return (
    <Box
      sx={{
        height: '100%',
        '& .mapboxgl-ctrl-top-left': {
          left: isShowNavbar ? '400px' : '0px',
          '& .active': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        '& .finish-drawing': {
          left: isShowNavbar ? '450px' : '50px',
        },
      }}>
      <ReactMapGL
        initialViewState={DEFAULT_VIEWPORT}
        mapboxAccessToken={configs.MAP_BOX_API}
        ref={mapRef}
        mapStyle={mapStyleCustom}>
        {topModel?.visible ? (
          <Layer
            id="3d-model"
            type="custom"
            renderingMode="3d"
            onAdd={(map, gl) => {
              // use the three.js GLTF loader to add the 3D model to the three.js scene
              scene.add(topModel);
              ourMap = map;

              // use the Mapbox GL JS map canvas for three.js
              renderer = new THREE.WebGLRenderer({
                canvas: map.getCanvas(),
                context: gl,
                antialias: true,
              });

              renderer.autoClear = false;
            }}
            render={(gl, matrix) => {
              const m = new THREE.Matrix4().fromArray(matrix);
              const l = new THREE.Matrix4()
                .makeTranslation(modelTransform.translateX, modelTransform.translateY, modelTransform.translateZ!)
                .scale(new THREE.Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale));

              camera.projectionMatrix = m.multiply(l);
              renderer.resetState();
              renderer.render(scene, camera);
              ourMap.triggerRepaint();
            }}
          />
        ) : (
          <WaitingPageAPI />
        )}
      </ReactMapGL>
    </Box>
  );
};

export default Mapbox3d;
