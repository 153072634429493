import { useGetClientSettingMapView } from 'hooks/map-view/useGetClientSettingMapView';
import { useClientData } from 'hooks/useClientData';
import useClientDataConfig from 'hooks/useClientDataConfig';
import { useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { changeFixedSizePointGap, changeZoomLayerGap, gapAnalyticsSelector } from 'store/slices/map-view/gapAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';

export const useGapAnalytics = ({ mapRef }: any) => {
  const { dataGeoJsonPoint, dataGeoJsonPolygon } = useAppSelector(gapAnalyticsSelector);
  const { isLayer3D, isInEditTab } = useAppSelector(mapViewSelector);
  const dispatch = useDispatch();

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, getOpacityExtrusion } =
    useGetClientSettingMapView();

  const { contourSetting } = useClientData();
  const { isFixedPoint, value } = useClientDataConfig();
  const { analyticId } = useAppSelector(mapViewSelector);

  const zoomLevel = useMemo(() => {
    if (!mapRef?.current) return 0;
    return mapRef?.current?.getZoom();
  }, [mapRef?.current?.getZoom()]);

  useEffect(() => {
    if (!mapRef?.current) return;

    if (isFixedPoint && value) {
      dispatch(changeFixedSizePointGap(value));
    } else dispatch(changeZoomLayerGap(zoomLevel));
  }, [dispatch, isFixedPoint, mapRef, value, zoomLevel, analyticId]);

  const layerGap = useMemo(() => {
    if (!dataGeoJsonPoint && !dataGeoJsonPolygon) {
      return null;
    }

    return (
      <>
        {dataGeoJsonPoint && !isInEditTab && (
          <Source id={`gap-polygon-point`} type="geojson" data={dataGeoJsonPoint}>
            {/* 2D Point */}
            <Layer
              id={`fill-top-surface-point`}
              type="fill"
              source={`gap-polygon-point`}
              paint={{
                'fill-color': ['get', 'color'],
              }}
              layout={{ visibility: !isLayer3D ? 'visible' : 'none' }}
            />
            {/* 3D Cylinder */}
            <Layer
              id={`gap-top-surface-point`}
              type="fill-extrusion"
              source={`gap-polygon-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
            />
            <Layer
              id={`gap-side-surface-point`}
              type="fill-extrusion"
              source={`gap-polygon-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                // getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
            />
          </Source>
        )}
        {dataGeoJsonPolygon && !isInEditTab && (
          <Source id={`gap-source`} type="geojson" data={dataGeoJsonPolygon}>
            {/* 2D Contour */}
            <Layer
              id={`gap_line`}
              type="line"
              paint={{
                'line-color': ['get', 'color'],
                'line-width': 2,
              }}
              source={`gap-source`}
              layout={{ visibility: !isLayer3D ? 'visible' : 'none' }}
            />
            {/* 3D Filled contour */}
            <Layer
              id={`gap_fill-polygon`}
              type="fill"
              paint={{
                'fill-color': ['get', 'color'],
                'fill-opacity': 1,
              }}
              source={`gap-source`}
              layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
            />
            <Layer
              id={`gap_top-surface`}
              type="fill-extrusion"
              beforeId={`gap_fill-polygon`}
              source={`gap_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
            />
            <Layer
              id={`gap_side-surface`}
              type="fill-extrusion"
              beforeId={`gap_top-surface`}
              source={`gap_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
            />
          </Source>
        )}
      </>
    );
  }, [
    colorTransparent,
    contourSetting,
    dataGeoJsonPoint,
    dataGeoJsonPolygon,
    getOpacityExtrusion,
    getTopSurfaceOpacity,
    isInEditTab,
    isLayer3D,
    isSameColorTopSurface,
  ]);
  return { layerGap };
};
