import { Box, Button, Grid, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { FC, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { sendEmailSharing } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import ListUserSharing from './list-user-sharing';

interface ShareIssueModalProps {
  isShow: boolean;
  handleClose: () => void;
}

const ShareIssueModal: FC<ShareIssueModalProps> = ({ isShow, handleClose }) => {
  const {
    issuesTab: { shareIssueModal },
  } = useAppSelector(rightBarSelector);
  const [listUserChecked, setListUserChecked] = useState<string[]>([]);
  const [listUserDontSendFile, setListUserDontSendFile] = useState<string[]>([]);
  const [isReset, setReset] = useState<boolean>(false);
  const mapViewStylesClasses = useMapViewStyle();

  const postDataEmail = useMutation(sendEmailSharing, {
    onSuccess: () => {
      setReset(false);
      toast.success('Sharing successes', { toastId: 1 });
      setListUserChecked([]);
      handleClose();
    },
    onError: (e: any) => {
      setReset(false);
      if (e.status === 400) {
        toast.error(e.data.message[0], { toastId: 1 });
        return;
      }
    },
  });
  const getValueChecked = (data: any) => {
    const listItemChecked: any = [];
    const listDontSendFile: any = [];
    data.map((item: any) => {
      if (item.checked && !item.dontSendFile) {
        listItemChecked.push(item.value);
      }
      if (item.checked && item.dontSendFile) {
        listDontSendFile.push(item.value);
      }
    });
    setListUserChecked(listItemChecked);
    setListUserDontSendFile(listDontSendFile);
  };

  const hanldeSubmitSendEmail = (data: any) => {
    setReset(true);
    postDataEmail.mutate(data);
    handleClose();
  };

  return (
    <CommonModal
      isShow={isShow}
      handleClose={() => {
        setReset(true);
        handleClose();
      }}>
      <Box sx={{ p: '32px', width: '864px' }}>
        <Box sx={{ mb: 2 }}>
          <Typography className={mapViewStylesClasses.titleInput}>Share Issue</Typography>
        </Box>
        <Grid container spacing={1} sx={{ m: 0 }}>
          <Grid item xs={12} sx={{ pl: '0 !important' }}>
            <Typography sx={{ display: 'inline-block', fontWeight: '500' }}>Description: &nbsp;</Typography>
            <Typography sx={{ display: 'inline-block' }}>
              {shareIssueModal.data && shareIssueModal.data.description}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pl: '0 !important', mt: '8px' }}>
            <Scrollbars style={{ width: '800px', height: '600px' }}>
              <ListUserSharing onChangeValue={getValueChecked} isReset={isReset} />
            </Scrollbars>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'right', mt: '10px', mr: '8px' }}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={!listUserChecked.length}
                onClick={() =>
                  hanldeSubmitSendEmail({
                    emailsWithoutAttachment: listUserChecked,
                    emailsWithAttachment: listUserDontSendFile,
                    id: shareIssueModal.data._id,
                  })
                }>
                Share
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default ShareIssueModal;
