import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import moment from 'moment';
import { ChangeEvent, FC, useEffect } from 'react';
import { ICheckbox } from '.';

interface DeletedListTaskModalProps {
  isSelectedCheckAll: boolean;
  listCheckBox: ICheckbox[];
  setIsSelectedCheckAll: (arg: boolean) => void;
  setListCheckbox: (arg: ICheckbox[]) => void;
  setIsShowConfirmDeleteModal: (arg: boolean) => void;
  handleClose: () => void;
}

const DeletedListTaskModal: FC<DeletedListTaskModalProps> = ({
  isSelectedCheckAll,
  listCheckBox,
  setIsSelectedCheckAll,
  setListCheckbox,
  setIsShowConfirmDeleteModal,
  handleClose,
}) => {
  const mapViewStylesClasses = useMapViewStyle();
  const { taskList } = useQueryListTaskOfField();

  useEffect(() => {
    setIsSelectedCheckAll(false);
  }, [setIsSelectedCheckAll]);

  useEffect(() => {
    const initialListCheckbox = taskList.map((item) => ({ ...item, checked: false }));
    setListCheckbox(initialListCheckbox);
  }, [setListCheckbox, taskList]);

  const onChangeSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSelectedCheckAll(event.target.checked);
    const newListCheckBox = listCheckBox.map((item) => ({ ...item, checked: event.target.checked }));
    setListCheckbox(newListCheckBox);
  };

  const onChangeItem = (event: ChangeEvent<HTMLInputElement>, item: ICheckbox) => {
    const newListCheckBox = listCheckBox.map((ele) =>
      ele._id === item._id ? { ...ele, checked: event.target.checked } : ele
    );
    setListCheckbox(newListCheckBox);

    const isCheckedAll = newListCheckBox.every((item) => item.checked);
    setIsSelectedCheckAll(isCheckedAll);
  };

  const handleDeleteTasks = () => {
    setIsShowConfirmDeleteModal(true);
  };

  const isDisableButton = listCheckBox.every((item) => !item.checked);

  const renderLabelComp = (item: ICheckbox) => (
    <Box>
      <Typography component="span" sx={{ mr: '8px' }}>
        {moment(item.date).format('DD-MM-YYYY')}
      </Typography>
      <Typography component="span">{item.description}</Typography>
    </Box>
  );

  return (
    <CommonModal isShow={true} handleClose={handleClose}>
      <Box sx={{ width: '400px', padding: '32px' }}>
        <Typography className={mapViewStylesClasses.titleInput} sx={{ mb: '24px', fontSize: '18px' }}>
          Select the dates to be deleted
        </Typography>
        <Stack>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isSelectedCheckAll} onChange={(e) => onChangeSelectAll(e)} />}
              label={<Typography fontWeight={700}>Select All</Typography>}
            />
            {listCheckBox.map((item) => (
              <FormControlLabel
                control={<Checkbox onChange={(e) => onChangeItem(e, item)} checked={item.checked ?? false} />}
                label={renderLabelComp(item)}
              />
            ))}
          </FormGroup>
        </Stack>
        <Box sx={{ mt: '32px', textAlign: 'center' }}>
          <Button variant="contained" color="error" onClick={handleDeleteTasks} disabled={isDisableButton}>
            Confirm
          </Button>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default DeletedListTaskModal;
