import { Box, Button, Checkbox, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { permissionsData } from 'constants/constants';
import { useCallback, useState } from 'react';

const SinglePermission = (props: any) => {
  const { data, setPermissionRole, permissionRole } = props;
  const [isAllSelected, setIsAllSelected] = useState(false);
  const theme = useTheme();

  const handleSelectAll = (text: string) => {
    setIsAllSelected(!isAllSelected);
    const selectedPermission = permissionsData.find((data) => data.name === text);
    selectedPermission?.items.forEach((data) => {
      permissionRole.push(data.key);
      const permissionUnique: string[] = Array.from(new Set(permissionRole));
      return setPermissionRole(permissionUnique);
    });
  };

  const handleUnselectAll = (text: string) => {
    setIsAllSelected(!isAllSelected);
    const selectedPermission = permissionsData.find((data) => data.name === text);
    const unselectedPermission = permissionRole.filter(
      (val: any) => !selectedPermission?.items.some((data) => data.key === val)
    );
    setPermissionRole(unselectedPermission);
  };

  const handleOnSelect = useCallback(
    (text: string, isSelected: boolean) => {
      if (!permissionRole) return;
      if (!isSelected) {
        return setPermissionRole((prev: any) => {
          return prev?.filter((val: any) => val !== text) || [];
        });
      }
      permissionRole.push(text);
      const permissionUnique: string[] = Array.from(new Set(permissionRole));
      return setPermissionRole(permissionUnique);
    },
    [permissionRole, setPermissionRole]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        width: 'calc(100% - 32px)',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        padding: '16px',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontStyle: 'normal',
              fontSize: '16px',
              color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              lineHeight: '16px',
              fontWeight: '600',
            }}>
            {data.label}
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{
              marginRight: '10px',
            }}
            disabled={isAllSelected}
            variant="contained"
            onClick={() => handleSelectAll(data.name)}>
            Select All
          </Button>
          <Button disabled={!isAllSelected} variant="outlined" onClick={() => handleUnselectAll(data.name)}>
            Unselect All
          </Button>
        </Box>
      </Box>
      <Box sx={{ border: `1px solid ${theme.palette.divider}` }} />
      <Grid container rowSpacing={1}>
        {data.items.map((dt: any) => {
          return (
            <Grid item xs={3} key={dt.key}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissionRole.includes(`${dt.key}`)}
                    onChange={(event) => handleOnSelect(`${dt.key}`, event.target.checked)}
                  />
                }
                label={dt.name}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SinglePermission;
