import { Grid, styled, Tab, Tabs } from '@mui/material';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { TabPanel } from 'components/MapView/TabPanel';
import { SyntheticEvent, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import FieldList from './field-list';

const a11yProps = (index: number) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
};

const TabStyled = styled(Tab)(({ theme }) => ({
  textTransform: 'unset',
  color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey3,
  fontWeight: 500,
  fontSize: '12px',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));
const tabMenu = [
  {
    id: 0,
    label: 'List',
  },
  {
    id: 1,
    label: 'Favorites',
  },
];

const NavbarTilingMap = () => {
  const classes = useMapViewStyle();
  const [tabNumber, setTabNumber] = useState<number>(0);

  const handleChange = (_event: SyntheticEvent<Element, Event>, newValue: any) => {
    setTabNumber(newValue);
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default),
      }}
      height="100%">
      <Grid direction="row" sx={{ height: '104px' }}>
        <Grid direction="row">
          <Tabs value={tabNumber} onChange={handleChange} variant="fullWidth" className={classes.mapViewTab}>
            {tabMenu.map((item, i) => (
              <TabStyled key={i} label={item.label} {...a11yProps(item.id)} />
            ))}
          </Tabs>
        </Grid>
        <Grid direction="row">
          <SwipeableViews axis={'x-reverse'} index={tabNumber}>
            <TabPanel value={tabNumber} index={0} isAnalyticsTab={true}>
              <FieldList />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NavbarTilingMap;
