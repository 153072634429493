import { Close } from '@mui/icons-material';
import { Box, IconButton, InputLabel, Link, Tooltip, useTheme } from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { IOptionalPropertyType } from '../../../common/defines/clients';
import { FieldUploadFile } from '../../FieldUploadFile';
import { ClientMenu } from '../ClientMenu';

export const PropertyTypeFile = ({
  index,
  item,
  control,
  setValue,
  remove,
  handleOpenModal,
  setEditAdditionalData,
}: any) => {
  const [isShowEdit, setIsShowEdit] = useState<boolean>(false);

  const initialAnchorEl = {
    field: null,
    removeField: null,
  };

  const [anchorEl, setAnchorEl] = useState<{
    field: null | HTMLElement;
    removeField: null | HTMLElement;
  }>(initialAnchorEl);

  const openMenu = (anchorName: string, isDeleteLevel?: boolean) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl({
      ...anchorEl,
      [anchorName]: event.currentTarget,
    });
  };
  const closeMenu = (anchorName: string) => () => {
    setAnchorEl({
      ...anchorEl,
      [anchorName]: null,
    });
  };
  const theme = useTheme();

  return (
    <Box
      sx={{ display: 'flex', position: 'relative', height: '70px' }}
      onMouseLeave={() => setIsShowEdit(false)}
      onMouseEnter={() => setIsShowEdit(true)}>
      <Box sx={{ width: '100%' }}>
        {item.value?.length > 0 ? (
          <Controller
            name={`additionalInformation.${index}.value`}
            control={control}
            render={({ field }) => (
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <InputLabel>{item.name}</InputLabel>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    pl: 1,
                    height: '40px',
                    mt: '7px',
                    wordBreak: 'break-all',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}>
                  <Box
                    sx={{
                      pr: 2,
                      // display: 'webkit-box',
                      wordBreak: 'break-all',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Tooltip
                      title={
                        Array.isArray(item.value) ? item.value[0]?.split('/')?.at(-1) : item.value?.split('/')?.at(-1)
                      }
                      arrow
                      placement="left">
                      <Link
                        underline="none"
                        href={Array.isArray(item.value) ? item.value[0] : item.value}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 500,
                          cursor: 'pointer',
                        }}>
                        {Array.isArray(item.value) ? item.value[0]?.split('/')?.at(-1) : item.value?.split('/')?.at(-1)}
                      </Link>
                    </Tooltip>
                  </Box>
                  <IconButton
                    sx={{ pr: 1 }}
                    onClick={() => {
                      setValue(`additionalInformation.${index}.value`, undefined);
                      setValue(`additionalInformation.${index}.uploadedBy`, undefined);
                    }}>
                    <Close fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            )}
          />
        ) : (
          <FieldUploadFile
            name={`additionalInformation.${index}.value`}
            index={index}
            control={control}
            propertyItem={item}
            setValue={setValue}
            sx={{ height: '70px' }}
          />
        )}
      </Box>
      {isShowEdit && (
        <ClientMenu
          styles={{ position: 'absolute', marginLeft: '600px' }}
          sx={{ top: '18px' }}
          open={Boolean(anchorEl.removeField)}
          anchorEl={anchorEl.removeField}
          onOpen={openMenu('removeField', true)}
          onDelete={() => {
            remove(index);
            setIsShowEdit(false);
          }}
          handleEdit={() => {
            setEditAdditionalData({
              index: index,
              name: item.name,
              type: IOptionalPropertyType.FILE,
              value: '',
            });
            handleOpenModal();
          }}
          onClose={closeMenu('removeField')}
          mainMenu
        />
      )}
    </Box>
  );
};
