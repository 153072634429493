import { QUERY_KEY } from 'constants/constants';
import { IField } from 'interfaces/workspace';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getFieldDetail } from 'services/workspaces';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

const useQueryFieldDetail = () => {
  const [fieldDetail, setFieldDetail] = useState<IField>();
  const { selectedFieldId, newestCreatedFieldId } = useAppSelector(tilingMapSelector);

  const fieldId = selectedFieldId || newestCreatedFieldId;

  useQuery([QUERY_KEY.GET_FIELD_DETAIL, selectedFieldId, newestCreatedFieldId], () => getFieldDetail(fieldId), {
    onSuccess(res) {
      setFieldDetail(res.data);
    },
    enabled: !!fieldId,
  });

  return { fieldDetail, setFieldDetail };
};

export default useQueryFieldDetail;
