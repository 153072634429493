import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, TextField, Typography, useTheme } from '@mui/material';
import { circle } from '@turf/turf';
import { ISwitchItem } from 'common/defines/analytic';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLandUsageLayerStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { ChangeEvent, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateEditedCircumference } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeCircumferenceEditedDetail,
  changeSwitchCircumferenceListValue,
  circumferenceAnalyticsSelector,
} from 'store/slices/map-view/circumferenceAnalytics';
import {
  changeClickedStandPointId,
  changeIsStandPointEditMode,
  changeLayer3D,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import CommonSwitchItem from '../components/CommonSwitchItem';

const defaultDecimalPlacesValue = 2;
const CircumferenceEdit = () => {
  const [isSelectAll, setIsSelectAll] = useState<boolean>(true);
  const [isShowCircumference, setIsShowCircumference] = useState<boolean>(true);
  const [isShowEditMode, setIsShowEditMode] = useState<boolean>(true);
  const [decimalPlacesValue, setDecimalPlaceValue] = useState<number>(defaultDecimalPlacesValue);

  const dispatch = useAppDispatch();
  const classes = useLandUsageLayerStyle();
  const theme = useTheme();
  const { isLayer3D, isStandPointEditMode, analyticId } = useAppSelector(mapViewSelector);
  const { switchCircumferenceList, circumferenceEditedDetail } = useAppSelector(circumferenceAnalyticsSelector);
  const { diaLength, _id, treeId, longX, latY, initialDiaLength, cirLength } = circumferenceEditedDetail || {};
  const { colorAnalysisLayer } = useQueryClientSetting();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  // reset decimal places to default each time change id
  useEffect(() => {
    setDecimalPlaceValue(defaultDecimalPlacesValue);
  }, [_id]);

  // reset clicked stand point when turn off Point Edit
  useEffect(() => {
    if (!isStandPointEditMode) {
      dispatch(changeClickedStandPointId(''));
    }
  }, [dispatch, isStandPointEditMode]);

  // initial color for standCount and circumference from client-setting
  useEffect(() => {
    switchCircumferenceList.forEach((item) => {
      const fullAnalyticId = `${analyticId}_${item.key}`;
      const getColorFromApi = colorAnalysisLayer?.find((item) => item.analysisId === fullAnalyticId);
      if (getColorFromApi) {
        dispatch(changeSwitchCircumferenceListValue({ ...item, color: getColorFromApi.color }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticId, colorAnalysisLayer, dispatch]);

  const updateCircumferenceMutation = useMutation(updateEditedCircumference, {
    onSuccess() {
      toast.success('Update circumference successful');
      queryClient.invalidateQueries([QUERY_KEY.CIRCUMFERENCE_ANALYSIS]);
      queryClient.invalidateQueries([QUERY_KEY.CIRCUMFERENCE_POLYGON]);
      dispatch(changeCircumferenceEditedDetail());
    },
  });

  const numberWithDecimalPlaces = (value: number | undefined) => {
    if (!value) return 0;
    return Number.parseFloat(value.toFixed(decimalPlacesValue));
  };

  const handleChangeSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.checked;
    switchCircumferenceList.forEach((item) => {
      dispatch(changeSwitchCircumferenceListValue({ key: item.key, visible: changedValue }));
    });
    setIsSelectAll(changedValue);
  };

  const handleSwitchListValue = (item: ISwitchItem) => {
    dispatch(changeSwitchCircumferenceListValue({ key: item.key, visible: !item.visible }));
  };

  const handleChangeCircleDiaLength = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    circumferenceEditedDetail &&
      dispatch(
        changeCircumferenceEditedDetail({
          ...circumferenceEditedDetail,
          diaLength: Number.parseFloat(value),
          cirLength: Number.parseFloat(value) * Math.PI,
        })
      );
  };

  const handleDelete = () => {
    if (_id && longX && latY)
      updateCircumferenceMutation.mutate({
        cirLength: 0,
        diaLength: 0,
        id: _id,
        geometry: {
          type: 'Point',
          coordinates: [longX, latY],
        },
      });
  };

  const handleReset = () => {
    setDecimalPlaceValue(defaultDecimalPlacesValue);
    initialDiaLength &&
      circumferenceEditedDetail &&
      dispatch(changeCircumferenceEditedDetail({ ...circumferenceEditedDetail, diaLength: initialDiaLength }));
  };

  const handleSave = () => {
    if (longX && latY && cirLength && diaLength && _id) {
      const center = [longX, latY];
      const radius = diaLength ? diaLength / 2 : 1;
      const newCircle = circle(center, radius, { units: 'meters' });
      const geometry = newCircle.geometry;
      updateCircumferenceMutation.mutate({ cirLength, diaLength, id: _id, geometry });
    }
  };

  const renderDetailInfo = circumferenceEditedDetail && [
    { key: 1, label: 'Tree Id: ', value: treeId },
    { key: 2, label: 'Longitude: ', value: longX },
    { key: 3, label: 'Latitude: ', value: latY },
    {
      key: 4,
      label: 'Decimal Places:',
      component: (
        <TextField
          type="number"
          fullWidth
          defaultValue={defaultDecimalPlacesValue}
          size="small"
          value={decimalPlacesValue}
          InputProps={{
            inputProps: { min: 0, step: 1 },
          }}
          onChange={(e: any) => setDecimalPlaceValue(e.target.value)}
        />
      ),
    },
    {
      key: 5,
      label: 'Diameters:',
      component: (
        <TextField
          type="number"
          fullWidth
          defaultValue={10}
          size="small"
          value={numberWithDecimalPlaces(diaLength)}
          InputProps={{
            inputProps: { min: 0, step: Math.pow(10, -decimalPlacesValue) },
          }}
          onChange={handleChangeCircleDiaLength}
        />
      ),
    },
  ];

  return (
    <Scrollbars style={{ height: 'calc(100vh - 350px)' }} autoHide>
      <Grid container classes={{ root: classes.containerStyle }} direction="column" sx={{ display: 'flex', flex: 1 }}>
        <Box className={classes.boxStyle} p="10px" pt="16px">
          <Box sx={{ pl: '16px' }}>
            <FormControlLabel
              control={
                <SwitchCustom
                  checked={isStandPointEditMode}
                  onClick={() => dispatch(changeIsStandPointEditMode(!isStandPointEditMode))}
                />
              }
              label={<Typography className={classes.selectText}>{t('trans.point_edit')}</Typography>}
            />
          </Box>
          <Button
            startIcon={isShowCircumference ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setIsShowCircumference((prev) => !prev)}
            classes={{ root: classes.buttonTextStyle }}>
            {t('trans.label')}
          </Button>
          <Collapse in={isShowCircumference} timeout="auto" sx={{ px: '16px' }}>
            <Box>
              <FormControlLabel
                control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                label={
                  <Typography classes={{ root: classes.selectText }}>
                    {isLayer3D ? t('trans.3d_fill') : t('trans.2d_fill')}
                  </Typography>
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<SwitchCustom checked={isSelectAll} onClick={handleChangeSelectAll} />}
                label={
                  <Typography classes={{ root: classes.selectText }}>
                    {isSelectAll ? t('trans.unselect_all') : t('trans.select_all')}
                  </Typography>
                }
              />
            </Box>
            {switchCircumferenceList
              .map((item) => ({ ...item, label: t(convertTextToMultiLanguage(item.label, 'mapView')) }))
              .map((item) => (
                <Box>
                  <CommonSwitchItem
                    item={item}
                    handleSwitchListValue={() => {
                      handleSwitchListValue(item);
                    }}
                  />
                </Box>
              ))}
          </Collapse>

          <Button
            startIcon={isShowEditMode ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setIsShowEditMode((prev) => !prev)}
            classes={{ root: classes.buttonTextStyle }}>
            {t('trans.edit_mode')}
          </Button>
          <Collapse in={isShowEditMode} timeout="auto" sx={{ px: '8px' }}>
            {circumferenceEditedDetail && (
              <Box sx={{ color: theme.palette.mode === 'dark' ? '#ffffff' : '#3C4043' }}>
                {renderDetailInfo?.map((item) => (
                  <Grid container key={item.key} sx={{ minHeight: '40px', display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={4}>
                      <Typography sx={{ display: 'inline-block', fontWeight: 600 }}>{item.label}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {item.value ? (
                        <Typography sx={{ display: 'inline-block', wordWrap: 'break-word', maxWidth: '100%' }}>
                          {item.value}
                        </Typography>
                      ) : (
                        item.component
                      )}
                    </Grid>
                  </Grid>
                ))}
                <Grid container alignItems="center" sx={{ mt: '32px' }}>
                  <Grid item xs={4}>
                    <DeleteForeverIcon
                      color="error"
                      onClick={handleDelete}
                      sx={{ cursor: 'pointer', fontSize: '30px' }}
                    />
                  </Grid>
                  <Grid item xs={7} textAlign="right">
                    <Button size="small" variant="contained" color="neutral" onClick={handleReset}>
                      Reset
                    </Button>
                    <Button size="small" variant="contained" color="primary" sx={{ ml: '8px' }} onClick={handleSave}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Collapse>
        </Box>
      </Grid>
    </Scrollbars>
  );
};

export default CircumferenceEdit;
