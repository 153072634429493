import { useCallback, useEffect, useRef, useState } from 'react';

import { CollapseItemPayload } from './TreeView/TreeItem/TreeItem';
import { RenderTree } from 'common/defines/clients';
import { TreeViewDnd } from './TreeView';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type TreeProps = {
  levels: RenderTree[];
};

type CustomRenderTree = RenderTree & {
  collapsed: boolean;
  id: string;
  handleCollapseItem: (payload: CollapseItemPayload) => void
}

const LevelTree = ({ levels }: TreeProps) => {
  const [treeData, setTreeData] = useState<CustomRenderTree[]>([])
  const statusTree = useRef<Map<string, boolean>>(new Map())

  const handleCollapseItem = ({ key, collapsed }: CollapseItemPayload) => {
    statusTree.current?.set(key, collapsed)
  }

  const convertData = useCallback((data: (RenderTree | CustomRenderTree)[]): CustomRenderTree[] => {
    if (!data) return [];

    return data.map((item) => {
      // check and get previous status of level if it has already existed
      const collapsed = !statusTree.current?.get((item as CustomRenderTree).key)
      const _data: CustomRenderTree = {
        ...item,
        collapsed,
        id: item._id,
        handleCollapseItem,
        children: item.children ? convertData(item.children) : undefined,
      };

      return _data
    })
  }, [])

  useEffect(() => {
    setTreeData(convertData(levels))
  }, [convertData, levels])

  return (
    <TreeViewDnd
      levels={treeData}
    />
  );
};

export default LevelTree;
