import { QUERY_KEY } from 'constants/constants';
import { ITask } from 'interfaces/workspace';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getWorkspaceAnalysisRaster } from 'services/workspaces';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

const useQueryListTaskOfField = () => {
  const [taskList, setTaskList] = useState<ITask[]>([]);
  const { selectedFieldId, selectedTaskId } = useAppSelector(tilingMapSelector);

  useQuery([QUERY_KEY.GET_TASK_LIST_OF_FIELD, selectedFieldId], () => getWorkspaceAnalysisRaster(selectedFieldId), {
    onSuccess(res) {
      setTaskList(res.data);
    },
    enabled: !!selectedFieldId,
  });

  const findLatestCreatedTask = (taskList: ITask[]) => {
    if (taskList.length === 0) {
      return undefined; // Return null if the array is empty
    }

    let latestTask = taskList[0]; // Initialize latestTask with the first object in the taskList

    for (let i = 1; i < taskList.length; i++) {
      if (taskList[i].createdAt > latestTask.createdAt) {
        latestTask = taskList[i]; // Update latestTask if a higher value is found
      }
    }

    return latestTask;
  };

  const theLastTask = findLatestCreatedTask(taskList);

  const selectedTask = taskList.find((item) => item._id === selectedTaskId);

  return { taskList, setTaskList, theLastTask, selectedTask };
};

export default useQueryListTaskOfField;
