import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Tooltip } from '@mui/material';
import { CreateAndEditMode } from 'interfaces/workspace';
import { FC, MouseEvent } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeCreateAndEditFieldModal, changeIsAddDataMode } from 'store/slices/tilingMapSlice';

interface AddDataProps {
  isIconActive: boolean;
}

const AddData: FC<AddDataProps> = ({ isIconActive }) => {
  const dispatch = useAppDispatch();

  const handleAddData = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isIconActive) {
      dispatch(changeIsAddDataMode(true));
      dispatch(changeCreateAndEditFieldModal({ isShow: true, mode: CreateAndEditMode.TASK }))
    }
  };

  return (
    <Box onClick={handleAddData}>
      <Tooltip title="Add Date">
        <AddOutlinedIcon color="primary" />
      </Tooltip>
    </Box>
  );
};

export default AddData;
