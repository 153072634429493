import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { S3UsageItem } from 'common/defines/clients';
import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';

interface IS3usageTable {
  dataChart: S3UsageItem[];
}

const S3UsageTable: FC<IS3usageTable> = ({ dataChart }) => {
  const [isSortColumnSize, setIsSortColumnSize] = useState<boolean>(true);
  const theme = useTheme();
  const totalSize = useMemo(() => {
    let total = 0;
    dataChart?.forEach((item) => {
      total += item.value;
    });
    return total;
  }, [dataChart]);

  const getTotalStorage = useMemo(() => {
    let total = 0;
    dataChart.forEach((item) => (total += item.value));
    return total.toFixed(1);
  }, [dataChart]);

  return (
    <TableContainer sx={{ maxHeight: 700 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F2F2F2',
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
            }}>
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                border: 'none',
                flex: 1,
                padding: '16px 0 16px 16px',
              }}>
              <Typography sx={{ fontWeight: '700' }}>{t('trans.no')}</Typography>
            </TableCell>
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: 'none',
                flex: 6,
                padding: '16px 0',
              }}>
              <Typography sx={{ fontWeight: '700' }}>{t('trans.client')}</Typography>
            </TableCell>
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                gap: 1,
              }}>
              <Typography
                sx={{
                  fontWeight: '700',
                }}>
                {t('trans.size')}(GB)
              </Typography>
              <SvgIcon
                component={isSortColumnSize ? AscIcon : DescIcon}
                inheritViewBox
                sx={{ fontSize: '14px', cursor: 'pointer' }}
                onClick={() => setIsSortColumnSize(!isSortColumnSize)}
              />
            </TableCell>
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                gap: 1,
              }}>
              <Typography
                sx={{
                  fontWeight: '700',
                }}>
                {t('trans.percentage')}
              </Typography>
              <SvgIcon
                component={isSortColumnSize ? AscIcon : DescIcon}
                inheritViewBox
                sx={{ fontSize: '14px', cursor: 'pointer' }}
                onClick={() => setIsSortColumnSize(!isSortColumnSize)}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            pt: 0,
            flexDirection: 'column',
          }}>
          <Box
            className="scroll-bar-custom"
            sx={{
              minHeight: '540px',
              maxHeight: '540px',
              overflowY: 'scroll',
            }}>
            {dataChart
              ?.sort((a, b) => (isSortColumnSize ? b.value - a.value : a.value - b.value))
              .map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      display: 'flex',
                    }}>
                    <TableCell
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        border: 'none',
                        flex: 1,
                        padding: '16px 0 16px 16px',
                      }}
                      component="td">
                      <Typography
                        sx={{
                          fontWeight: '500',
                        }}>
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                        border: 'none',
                        flex: 6,
                        padding: 0,
                        width: '100%',
                      }}>
                      <Typography sx={{ fontWeight: '900' }}>{item.name}</Typography>
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        flex: 1,
                        border: 'none',
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                      }}>
                      <Typography>{item.value}</Typography>
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        flex: 1,
                        border: 'none',
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                      }}>
                      <Typography sx={{ fontWeight: '500' }}>{`${((item.value / totalSize) * 100).toFixed(
                        2
                      )} %`}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </Box>
          <TableRow
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F2F2F2',
            }}>
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                border: 'none',
                flex: 1,
                padding: '16px 0 16px 16px',
              }}
              component="td">
              <Typography
                sx={{
                  fontWeight: '700',
                }}>
                Total
              </Typography>
            </TableCell>
            <TableCell
              component="td"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
                border: 'none',
                flex: 6,
                padding: 0,
                width: '100%',
              }}>
              <Typography sx={{ fontWeight: '900' }}></Typography>
            </TableCell>
            <TableCell
              component="td"
              sx={{
                flex: 1,
                border: 'none',
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
              }}>
              <Typography sx={{ fontWeight: '700' }}>{getTotalStorage}</Typography>
            </TableCell>
            <TableCell
              component="td"
              sx={{
                flex: 1,
                border: 'none',
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
              }}>
              <Typography sx={{ fontWeight: '700' }}>{'100%'}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default S3UsageTable;
