import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/styles';
import { MousePositionEnum } from 'common/defines/constants';
import { FC, useRef, useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { IIssue } from '../../../interfaces';
import FullImageModal from './FullImageModal';
import SwiperBox from './SwiperBox';

interface IssueMarkProps {
  item: IIssue;
}

const fixedSize = {
  height: 135,
  width: 290,
};

export const StyledPopup = styled(Popup)({
  '& .mapboxgl-popup-tip': {
    display: 'none',
  },
  '& .mapboxgl-popup-content': {
    borderRadius: '8px',
    padding: 0,
  },
});

const IssueMark: FC<IssueMarkProps> = ({ item }) => {
  const [hoverIssueId, setHoverIssueId] = useState<string>('');
  const [isMoveIntoPopup, setIsMoveIntoPopup] = useState<boolean>(false);
  const [isShowFullImageModal, setIsShowFullImageModal] = useState<boolean>(false);
  const { drawerShow } = useAppSelector(mapViewSelector);
  const theme = useTheme();
  const mousePosition = useRef<MousePositionEnum>(MousePositionEnum.TOP_CENTER);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const isShowPopup = hoverIssueId === item._id || isMoveIntoPopup;

  const handleLeaveMarker = () => {
    if (!isMoveIntoPopup) {
      setHoverIssueId('');
    } else {
      setHoverIssueId(item._id);
    }
  };

  const detectMousePosition = (e: any) => {
    // 64px is width of menu bar
    let clientX = e.clientX - 64;
    let clientY = e.clientY;

    let innerWidth = window.innerWidth - 64;
    const innerHeight = window.innerHeight;

    // 300px is width of left sideBar
    if (drawerShow.leftBar) {
      clientX -= 300;
      innerWidth -= 300;
    }

    // 454px is width of right sideBar
    if (drawerShow.rightBar) {
      innerWidth -= 454;
    }

    if (clientY < innerHeight / 2) {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.TOP_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.TOP_RIGHT;
      else return MousePositionEnum.TOP_CENTER;
    } else {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_RIGHT;
      else return MousePositionEnum.BOTTOM_CENTER;
    }
  };

  const handleMouseMove = (e: any) => {
    if (!isShowPopup) {
      mousePosition.current = detectMousePosition(e);
    }
  };

  const calculateOffsetYWithPosition = () => {
    if (mousePosition.current.includes('top')) return 0;
    else return -36;
  };

  return (
    <>
      <Marker
        key={item._id}
        longitude={item.geometry.coordinates[0]}
        latitude={item.geometry.coordinates[1]}
        offset={[0, 0]}>
        <Box sx={{ position: 'relative' }} onMouseMove={handleMouseMove}>
          <RoomOutlinedIcon
            onMouseEnter={() => setHoverIssueId(item._id)}
            onMouseLeave={handleLeaveMarker}
            sx={{
              color: item.border?.color || theme.palette.primary.main,
              fontSize: '36px',
              cursor: 'pointer',
              position: 'absolute',
              top: 'calc(50% - 36px)',
              left: 'calc(50% - 18px)',
              strokeWidth: 1,
            }}
          />
        </Box>
      </Marker>
      {isShowPopup && (
        <StyledPopup
          longitude={item.geometry.coordinates[0]}
          latitude={item.geometry.coordinates[1]}
          anchor={mousePosition.current}
          closeButton={false}
          closeOnClick={false}
          style={{ ...fixedSize, maxWidth: 'unset' }}
          offset={[0, calculateOffsetYWithPosition()]}>
          <Box>
            <Box
              onMouseLeave={() => {
                setHoverIssueId('');
                setIsMoveIntoPopup(false);
              }}
              onMouseEnter={() => setIsMoveIntoPopup(true)}
              sx={{
                border: 0,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '8px',
                cursor: 'pointer',
                backgroundColor: (theme) => theme.palette.background.default,
              }}>
              <SwiperBox
                item={item}
                size={fixedSize}
                setIsShowFullImageModal={setIsShowFullImageModal}
                setActiveIndex={setActiveIndex}
              />
              <Box sx={{ p: '8px' }}>
                <Typography style={{ color: theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043' }}>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        </StyledPopup>
      )}
      {isShowFullImageModal && (
        <FullImageModal
          item={item}
          isShow={isShowFullImageModal}
          handleClose={() => setIsShowFullImageModal(false)}
          activeIndex={activeIndex}
        />
      )}
    </>
  );
};

export default IssueMark;
