import { SvgIcon } from '@mui/material';
import { GOOGLE_CLIENT_KEY } from 'common/dummy/dummyClients';
import React from 'react';
import GoogleLogin from 'react-google-login';
import { Google } from 'assets/icons';
import { TypeSocial } from 'common/defines/clients';

interface IGoogleLoginSocialProps {
  mutation: any;
}

const GoogleLoginSocial = ({ mutation }: IGoogleLoginSocialProps) => {
  const responseGoogle = (response: any) => {
    const data = {
      snsToken: response.accessToken,
      snsType: TypeSocial.GOOGLE,
    };
    mutation.mutate(data);
  };

  return (
    <GoogleLogin
      autoLoad={false}
      clientId={GOOGLE_CLIENT_KEY}
      render={(renderProps: any) => (
        <SvgIcon
          onClick={renderProps.onClick}
          component={Google}
          inheritViewBox
          fontSize="small"
          style={{ color: '#616161', cursor: 'pointer' }}
        />
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
    />
  );
};

export default GoogleLoginSocial;
