import SkeletonCharts from 'components/Common/SkeletonCharts';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAnalyticBoxPlot,
  getDataTreeTiltFallenAnalytic,
  updateInterval,
} from 'services/analytics/apiAnalyticsConfig.services';
import { getChartsTreeTiltFallen } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeChartDataTreeTiltFallenPreview,
  changeDataTreeTiltFallenAnalytics,
  changeDataTreeTiltFallenInterval,
} from 'store/slices/map-view/treeTiltFallenAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import { treeTiltFallenAnalyticsSelector } from '../../../../../../store/slices/map-view/treeTiltFallenAnalytics';
import { TreeTiltFallenLayer } from '../../Layer/TreeTiltFallen';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const TreeTiltFallenAnalytics = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { dataTreeTiltFallenInterval, chartDataTreeTiltFallenPreview } = useAppSelector(
    treeTiltFallenAnalyticsSelector
  );

  const { data: dataTreeTiltFallenBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.CHLOROPHYLL_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'TreeTiltFallen'),
    { enabled: !!analyticId }
  );

  useQuery(
    [QUERY_KEY.CHLOROPHYTE_ANALYTIC, analyticId],
    () => {
      return getDataTreeTiltFallenAnalytic(analyticId || '');
    },
    {
      enabled: !!analyticId,
      onSuccess: (mainData) => {
        dispatch(changeDataTreeTiltFallenAnalytics(mainData?.data || []));
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_CHLOROPHYLL_ANALYTIC, isDefaultInterval],
    () => getChartsTreeTiltFallen(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (data) => {
        dispatch(
          changeDataTreeTiltFallenInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataTreeTiltFallenInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY.CHARTS_CHLOROPHYLL_ANALYTIC, isDefaultInterval]).then(() => {});
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataTreeTiltFallenBoxPlot?.data) return [];
    return dataTreeTiltFallenBoxPlot.data;
  }, [dataTreeTiltFallenBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.TREE_TILT_FALLEN}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataTreeTiltFallenPreview
                      ? chartDataTreeTiltFallenPreview.chartData
                      : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.TREE_TILT_FALLEN}
                  getCharts={getChartsTreeTiltFallen}
                  intervalRange={
                    chartDataTreeTiltFallenPreview
                      ? chartDataTreeTiltFallenPreview?.intervalLimit?.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_CHLOROPHYLL_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataTreeTiltFallenPreview}
                  showXAxis={true}
                />
              )}
            </>
          ) : (
            <>
              <SkeletonCharts count={1} />
            </>
          )}
        </>
      }
      layerTab={<TreeTiltFallenLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.TREE_TILT_FALLEN} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
