import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { memo, useMemo } from 'react';

interface IPieChartCommon {
  title: string;
  dataChart: any;
  chartType: string;
  formatText: (data: any) => void;
}

const TreeChartCommon = ({ dataChart, chartType, formatText, title }: IPieChartCommon) => {
  const theme = useTheme();

  const options = useMemo(() => {
    return {
      legend: {
        orient: 'vertical',
        left: 0,
        top: 0,
      },
      tooltip: {
        trigger: 'item',
        formatter: (info: any) => formatText(info),
        padding: 5,
        backgroundColor: theme.palette.color.white1,
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
      },
      backgroundColor: theme.palette.color.white1,
      series: [
        {
          name: 'CATEGORY',
          type: chartType,
          visibleMin: 300,
          label: {
            show: true,
            formatter: '{b}',
          },
          upperLabel: {
            show: true,
            height: 30,
          },
          itemStyle: {
            borderColor: '#fff',
          },
          levels: [
            {
              itemStyle: {
                borderColor: '#777',
                borderWidth: 0,
                gapWidth: 1,
              },
              upperLabel: {
                show: false,
              },
            },
            {
              itemStyle: {
                borderColor: '#555',
                borderWidth: 5,
                gapWidth: 1,
              },
              emphasis: {
                itemStyle: {
                  borderColor: '#ddd',
                },
              },
            },
            {
              colorSaturation: [0.35, 0.5],
              itemStyle: {
                borderWidth: 5,
                gapWidth: 1,
                borderColorSaturation: 0.6,
              },
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          data: dataChart,
        },
      ],
    };
  }, [chartType, dataChart, formatText, theme.palette.mode]);

  return (
    <Box
      sx={{
        // border: `1px solid ${theme.palette.divider}`,
        // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        borderRadius: '5px',
      }}>
      <Grid container direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
            alignSelf: 'center',
            px: 3,
            pt: 1,
          }}>
          {title}
        </Typography>
      </Grid>
      <Stack sx={{ px: 2.5, my: '15px', height: '100%' }} direction="row" spacing={3}>
        <ReactECharts
          option={options}
          style={{ height: '350px', width: '100%' }}
          theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
        />
      </Stack>
    </Box>
  );
};

export default memo(TreeChartCommon);
