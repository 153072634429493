import { Box, Button, Typography } from '@mui/material';
import configs from 'constants/config';
import { QUERY_KEY } from 'constants/constants';
import { EventSourcePolyfill } from 'event-source-polyfill';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import { IEventSourceData, IRawFileUpload, ITask, OdmStatusCode } from 'interfaces/workspace';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useStopwatch } from 'react-timer-hook';
import { restartFieldProgress, stopFieldProgress } from 'services/workspaces';
import LinearProgressWithLabel from './components/LinearProgressWithLabel';

interface ProgressingDataProps {
  rawFileUpload: IRawFileUpload | undefined;
  theLastTask: ITask | undefined;
  handleClose: () => void;
}

const ProgressingData: FC<ProgressingDataProps> = ({ rawFileUpload, theLastTask }) => {
  const [progressData, setProgressData] = useState<IEventSourceData>();
  const [reconnectEventSource, setReconnectEventSource] = useState<boolean>(false);
  const { fieldDetail } = useQueryFieldDetail();
  const { _id } = theLastTask || {};
  const queryClient = useQueryClient();

  let stopwatchOffset = new Date();
  if (theLastTask) {
    stopwatchOffset.setSeconds(Math.round(moment(new Date()).diff(moment(theLastTask.updatedAt)) / 1000));
  }

  const stopwatch = useStopwatch({ offsetTimestamp: stopwatchOffset, autoStart: true });
  const token = localStorage.getItem('token');

  // get progress bar information upload raw file
  useEffect(() => {
    let eventSource: EventSource;

    let info = { projectId: '', taskId: '', status: false };
    if (rawFileUpload) {
      info = { projectId: fieldDetail?.projectId!, taskId: rawFileUpload?.workspaceRaster?.taskId, status: false };
    } else if (theLastTask) {
      info = { projectId: fieldDetail?.projectId!, taskId: theLastTask.taskId, status: theLastTask.status };
    }
    const { taskId, projectId, status } = info;
    console.log('🚀 ~ useEffect ~ info:', info);

    if (taskId && projectId && !status) {
      eventSource = new EventSourcePolyfill(`${configs.API_DOMAIN}/field/sse/${projectId}/${taskId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      eventSource.onmessage = (event) => {
        const progress = JSON.parse(event.data) as IEventSourceData;
        setProgressData(progress);
        if (
          progress.status === OdmStatusCode.COMPLETED ||
          progress.status === OdmStatusCode.CANCELED ||
          progress.lastErr
        ) {
          eventSource.close();
        }
        if (progress.status === OdmStatusCode.COMPLETED) {
          queryClient.invalidateQueries([QUERY_KEY.GET_LIST_FIELD]);
        }
      };
      eventSource.onerror = (error) => {
        eventSource.close();
        console.error(error);
      };
    }

    return () => {
      eventSource?.close();
    };
  }, [theLastTask, rawFileUpload, token, fieldDetail?.projectId, reconnectEventSource, queryClient]);

  const resetProcessingMutation = useMutation(restartFieldProgress, {
    onSuccess() {
      setReconnectEventSource((prev) => !prev);
    },
  });

  const stopProcessingMutation = useMutation(stopFieldProgress);

  const handleResetProcessing = () => {
    _id && resetProcessingMutation.mutate(_id);
  };

  const handleStopProcessing = () => {
    _id && stopProcessingMutation.mutate(_id);
  };

  return (
    <Box>
      {theLastTask?.name && (
        <Box>
          <Typography>Name: {theLastTask?.name}</Typography>
        </Box>
      )}
      <LinearProgressWithLabel
        errorMessage={progressData?.lastErr || ''}
        value={progressData ? Math.round(progressData.runningProgress * 100) : 0}
        status={progressData?.status}
        stopwatch={stopwatch}
      />
      {progressData?.status !== OdmStatusCode.COMPLETED && (
        <Box sx={{ textAlign: 'right', mt: '20px' }}>
          <Button variant="contained" size="small" color="neutral" onClick={handleResetProcessing}>
            Reset
          </Button>
          {progressData?.status !== OdmStatusCode.CANCELED && (
            <Button variant="contained" size="small" color="error" sx={{ ml: '12px' }} onClick={handleStopProcessing}>
              Stop
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProgressingData;
