import React, { useState } from 'react';
import CheckEmail from './CheckEmail';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [dataEmail, setDataEmail] = useState('');

  return isSendEmail ? (
    <CheckEmail data={dataEmail} />
  ) : (
    <ForgotPasswordForm setIsSendEmail={setIsSendEmail} setEmail={setDataEmail} />
  );
};

export default ForgotPassword;
