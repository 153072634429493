import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Typography, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { ITask } from 'interfaces/workspace';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateFieldBoundary } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeDrawingBoundary, tilingMapSelector } from 'store/slices/tilingMapSlice';
import CustomBox from './CustomBox';

interface DrawBoundaryBarProps {
  selectedTask: ITask;
}

const DrawBoundaryBar: FC<DrawBoundaryBarProps> = ({ selectedTask }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {
    drawingBoundary: { isShow, isLoading },
  } = useAppSelector(tilingMapSelector);

  const uploadBoundaryMutation = useMutation(updateFieldBoundary, {
    onSuccess() {
      toast.success('Remove boundary successful');
      queryClient.invalidateQueries([QUERY_KEY.GET_TASK_LIST_OF_FIELD]);
    },
    onError() {
      toast.error('Remove boundary failed');
    },
  });

  return (
    <CustomBox>
      <Typography fontSize="16px">Draw boundary</Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          {isShow || isLoading ? (
            <EditOffOutlinedIcon />
          ) : (
            <ModeEditOutlineOutlinedIcon
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
              onClick={() => dispatch(changeDrawingBoundary({ isShow: true, _id: selectedTask._id }))}
            />
          )}
        </Box>
        <DeleteForeverOutlinedIcon
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: theme.palette.error.main,
            },
          }}
          onClick={() => uploadBoundaryMutation.mutate({ _id: selectedTask._id, geojson: null })}
        />
      </Box>
    </CustomBox>
  );
};

export default DrawBoundaryBar;
