import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { verifyEmail } from 'services/clients/apiClient.services';
import { useVerifyEmailStyle } from './VerifyEmailStyle';
const styles = {
  formStyles: {
    width: '310px',
    m: 'auto',
    fontFamily: 'Barlow',
  },
  crossLineStyles: {
    borderTop: 1,
    width: '94px',
    mt: 1.25,
  },
  labelStyles: {
    textAlign: 'left',
    fontSize: '18px',
    height: '24px',
  },
  textStyles: {
    fontSize: '14px',
    height: '17px',
  },
  fontStyles: {
    fontFamily: 'Barlow',
  },
};

const VerifyEmailSuccess = (): React.ReactElement => {
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const classes = useVerifyEmailStyle();
  const handleVerifyEmail = useMutation(() => verifyEmail(tokenId || ''), {
    onSuccess: () => {
      toast.success('Verify email successed');
    },
    onError: () => {
      toast.error('Verify email failed');
    },
  });
  useEffect(() => {
    handleVerifyEmail.mutate();
    return () => {};
  }, []);

  return (
    <>
      {/* {isLoggingIn && <LoadingOverlay />} */}
      <Typography
        component="div"
        sx={{
          height: '100vh',
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1F1F1F' : '#f9f9f9'),
          display: ' flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Stack
          direction="row"
          sx={{
            height: 'fit-content',
            maxWidth: '1130px',
            m: 'auto',
            borderRadius: '10px',
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#3C4043' : '#fff'),
            py: 5,
          }}>
          <Box
            alignItems="center"
            sx={{
              width: '500px',
            }}>
            <Typography
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '310px',
                ...styles.formStyles,
              }}>
              <Typography
                component="div"
                sx={{
                  mt: '52px',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                  fontSize: '24px',
                  fontWeight: '600',
                }}>
                {'Verify Email Success!'}
              </Typography>
              <Button
                onClick={() => navigate('/request-infomation')}
                sx={{
                  ...styles.fontStyles,
                  textTransform: 'none',
                }}
                className={classes.loginBtn}
                data-testid="login-btn">
                Continue
              </Button>
            </Typography>
          </Box>
        </Stack>
      </Typography>
    </>
  );
};

export default VerifyEmailSuccess;
