import { makeStyles } from '@mui/styles';

export const calendarStyle = makeStyles((theme: any) => {
  return {
    modal: {
      padding: '24px',
      backgroundColor: theme.palette.mode === 'dark' ? '#24292E' : '#fff',
      border: `1px solid ${theme.palette.divider}`,
      '& > div': {
        '& > .rdrDefinedRangesWrapper': {
          background: theme.palette.mode === 'dark' ? '#24292E' : '#fff',
          '& > .rdrStaticRanges': {
            '& > .rdrStaticRange': {
              background: theme.palette.mode === 'dark' ? '#24292E' : '#fff',
              color: theme.palette.mode === 'dark' ? '#fff' : '#24292E',
              '&:hover': {
                color: theme.palette.mode === 'dark' ? '#000' : '#64748B',
              },
            },
          },
        },
        '& > .rdrCalendarWrapper': {
          background: theme.palette.mode === 'dark' ? '#24292E' : '#fff',
          color: theme.palette.mode === 'dark' ? '#fff' : '#24292E',
          '& > .rdrDateDisplayWrapper': {
            background: theme.palette.mode === 'dark' ? '#24292E' : '#fff',
          },
          '& > .rdrMonthAndYearWrapper': {
            '& > .rdrMonthAndYearPickers select': {
              color: theme.palette.mode === 'dark' ? '#fff !important' : '#24292E',
              background: theme.palette.mode === 'dark' ? '#24292E' : '#fff',
            },
          },
          '& > .rdrMonths': {
            '& > .rdrMonth': {
              '& > .rdrDays': {
                '& > .rdrDay.rdrDayPassive': {
                  '& > .rdrDayNumber span': {
                    color: theme.palette.mode === 'dark' ? '#64748B !important' : '#849095 !important',
                  },
                },
                '& > .rdrDay': {
                  '& > .rdrDayNumber span': {
                    color: theme.palette.mode === 'dark' ? '#fff' : '#24292E',
                  },
                },
              },
            },
          },
        },
      },
    },
  };
});
