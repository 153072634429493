import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Box, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { ITilingMapField, SensorTypeEnum } from 'interfaces/workspace';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeUploadRawImageList, tilingMapSelector } from 'store/slices/tilingMapSlice';
import DragAndDropFile from '../DragAndDropFile';

const sensorTypeRadioList = [
  { key: 1, value: SensorTypeEnum.RGB, label: 'RGB' },
  { key: 2, value: SensorTypeEnum.RGB_MS, label: 'RGB + MS' },
];

interface RawImagesProps {
  form: UseFormReturn<ITilingMapField>;
}

const RawImages: FC<RawImagesProps> = ({ form }) => {
  const theme = useTheme();
  const { control } = form;
  const dispatch = useAppDispatch();

  const {
    upload: { rawImageList },
  } = useAppSelector(tilingMapSelector);

  const handleRemoveSelectedDocument = () => {
    dispatch(changeUploadRawImageList([]));
  };

  const renderUploadedFile = () => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <PermMediaIcon fontSize="large" color="primary" />
      <Typography sx={{ fontSize: '16px', ml: '12px' }}>
        {rawImageList && `${rawImageList.length} images selected`}
      </Typography>
      {rawImageList.length && (
        <DeleteOutlineOutlinedIcon
          color="error"
          sx={{ fontSize: '24px', cursor: 'pointer', ml: '64px' }}
          onClick={handleRemoveSelectedDocument}
        />
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        border: `2px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        px: '24px',
        pb: '24px',
      }}>
      <Controller
        name="sensorType"
        control={control}
        defaultValue={SensorTypeEnum.RGB}
        render={({ field }) => (
          <RadioGroup {...field} row>
            {sensorTypeRadioList.map((item) => (
              <FormControlLabel key={item.key} value={item.value} control={<Radio />} label={item.label} />
            ))}
          </RadioGroup>
        )}
      />
      <Box sx={{ width: '100%', mt: '16px' }}>
        {rawImageList.length ? (
          renderUploadedFile()
        ) : (
          <DragAndDropFile description={'(Please upload .jpg or .png files)'} form={form} />
        )}
      </Box>
    </Box>
  );
};

export default RawImages;
