import { Box, FormControlLabel, InputLabel, Typography } from '@mui/material';
import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import PieChartCommon from 'components/Common/PieChartCommon';
import SkeletonCharts from 'components/Common/SkeletonCharts';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useClientSettingsStyle } from 'components/Dashboard/ClientSettings/ClientSettingsStyle';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import randomColor from 'randomcolor';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getSizeSlopeFieldAnalytic, getSlopeFieldAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSizeSlopeFieldList, mapViewSelector, setDataSlopeField } from 'store/slices/mapViewSlice';
import SlopeFieldCardLayer from '../../Layer/SlopeField';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import InfoTable from '../CropCover/InfoTable';

const SlopeFieldAnalytics = () => {
  const dispatch = useAppDispatch();
  const [chartMode, setChartMode] = useState(false);
  const classes = useClientSettingsStyle();

  const { analyticId, sizeSlopeFieldSelected, dataSlopeField, sizeSlopeFieldList } = useAppSelector(mapViewSelector);

  useQuery([QUERY_KEY.SIZE_SLOPE_FIELD_ANALYTIC, analyticId], () => getSizeSlopeFieldAnalytic(analyticId || ''), {
    enabled: !!analyticId && isEmpty(sizeSlopeFieldList),
    onSuccess: (response) => {
      if (response.data) {
        dispatch(changeSizeSlopeFieldList(response.data));
      }
    },
  });

  const { data: dataCropType } = useQuery(
    [QUERY_KEY.SLOPE_FIELD_ANALYTIC, analyticId, sizeSlopeFieldSelected],
    () => getSlopeFieldAnalytic(analyticId || '', sizeSlopeFieldSelected + ''),
    { enabled: !!analyticId && !!sizeSlopeFieldSelected }
  );

  const dataPie = useMemo(() => {
    if (dataSlopeField) {
      return dataSlopeField.map((_item: any) => ({
        name: _item.type,
        value: _item.lduseArea,
        totalTree: _item.totalTree,
      }));
    }
    return [];
  }, [dataSlopeField]);

  const getDataChart = useMemo(
    () =>
      dataSlopeField?.map((_item: any) => ({
        name: _item.type,
        value: chartMode ? _item.totalTree : _item.lduseArea,
      })),
    [chartMode, dataSlopeField]
  );

  const hasTreeCount = !!(dataSlopeField?.length && dataSlopeField.some((item: any) => item.totalTree > 0));

  const colorSlopeField = useMemo(() => {
    if (dataCropType?.data) {
      let colors: string[] = [];
      dispatch(
        setDataSlopeField(
          dataCropType.data.map((item: any, index: number) => {
            const colorRandom = randomColor({ luminosity: 'dark' });
            const _color = item.color || get(DIFFERENT_COLOR, `[${index}]`, colorRandom);
            colors.push(_color);
            return {
              ...item,
              visible: true,
              color: _color,
            };
          })
        )
      );
      return colors;
    }
    return [];
  }, [dataCropType, dispatch]);

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD}
      analyticTab={
        <>
          {dataSlopeField?.length > 0 ? (
            <>
              <PieChartCommon title="Slope Field" dataChart={getDataChart} colorCharts={colorSlopeField} />
              {hasTreeCount && (
                <>
                  <InputLabel sx={{ marginLeft: '25px', fontSize: '14px' }}>Chart Mode:</InputLabel>
                  <FormControlLabel
                    sx={{ marginLeft: '30px' }}
                    control={
                      <SwitchCustom
                        checked={chartMode}
                        onClick={() => {
                          setChartMode(!chartMode);
                        }}
                      />
                    }
                    label={<Typography className={classes.textSetting}>{chartMode ? 'Tree Count' : 'Area'}</Typography>}
                  />
                </>
              )}
              <Box component={'div'} sx={{ marginTop: '30px' }}>
                <InfoTable dataPie={dataPie} hasTreeCount={hasTreeCount} />
              </Box>
            </>
          ) : (
            <>
              <SkeletonCharts count={1} />
            </>
          )}
        </>
      }
      layerTab={<SlopeFieldCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD} />}
      isShowDownloadButton={dataSlopeField?.length > 0}
    />
  );
};

export default SlopeFieldAnalytics;
