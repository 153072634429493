import { makeStyles } from '@mui/styles';

export const useVerifyEmailStyle = makeStyles((theme: any) => {
  return {
    loginBtn: {
      marginTop: '48px !important',
      color: `${theme.palette.color.white} !important`,
      height: '48px',
      background: `linear-gradient(90deg, ${theme.palette.color.green1} 0%, ${theme.palette.color.green2} 97.14%)`,
    },
  };
});
