import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { DashboardIcon } from 'assets/icons';
import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetPassword } from 'services/clients/apiClient.services';
import { useForgotPasswordStyle } from './ForgotPasswordStyle';

const styles = {
  formStyles: {
    width: '310px',
    m: 'auto',
    fontFamily: 'Barlow',
  },
  labelStyles: {
    textAlign: 'left',
    fontSize: '18px',
    height: '24px',
  },
};

interface ICheckEmail {
  data: any;
}

const CheckEmail = ({ data }: ICheckEmail) => {
  const navigate = useNavigate();
  const classes = useForgotPasswordStyle();

  const handleResetPassword = useMutation((email: string) => resetPassword(email), {
    onSuccess: (res: any) => {
      console.log('res', res);
      toast.success('Reset password successfully. Please check your email!');
    },
    onError: (err: any) => {
      console.log('err', err);
    },
  });

  return (
    <Typography
      component="div"
      sx={{
        height: '100vh',
        backgroundColor: '#F9F9F9',
        display: ' flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Stack
        direction="row"
        sx={{
          height: '600px',
          maxWidth: '1130px',
          m: 'auto',
          borderRadius: '10px',
          backgroundColor: 'white',
          pt: 5,
        }}>
        <Box
          alignItems="center"
          className={classes.baseStyles}
          sx={{
            width: '500px',
          }}>
          <Typography
            component="div"
            sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <DashboardIcon />
            <Typography className={classes.brandStyles} sx={{ px: 2 }}>
              {' '}
              Agrimor
            </Typography>
          </Typography>
          <Typography
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '310px',
              ...styles.formStyles,
            }}>
            <Typography
              component="div"
              sx={{
                mt: '52px',
                color: '#3C4043',
                fontSize: '24px',
                fontWeight: '600',
              }}>
              {'Check your email'}
            </Typography>
            <Typography
              component="div"
              sx={{
                mt: 1,
                color: '#3C4043',
                fontSize: '18px',
                fontWeight: '400',
              }}>
              We have sent password reset instructions to <b>{data?.email}</b>
            </Typography>
            <Typography
              component="div"
              sx={{
                mt: 2,
                color: '#3C4043',
                fontSize: '18px',
                fontWeight: '400',
              }}>
              Didn’t receive the email?{' '}
              <Link
                sx={{ cursor: 'pointer' }}
                underline="none"
                color="#2196f3"
                onClick={() => handleResetPassword.mutate(data)}>
                Click to resend
              </Link>
            </Typography>

            <Stack sx={{ marginTop: '200px' }}>
              <Button
                onClick={() => navigate('/login')}
                sx={{
                  textTransform: 'none',
                }}
                className={classes.resetBtn}
                data-testid="login-btn">
                Okay
              </Button>
            </Stack>
          </Typography>
        </Box>
      </Stack>
    </Typography>
  );
};

export default CheckEmail;
