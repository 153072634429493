import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { StandCountCardLayer } from 'components/MapView/RightBar/AnalyticsTab/Layer/StandCount';
import { SwipeableViewsCustom } from 'components/MapView/RightBar/AnalyticsTab/SwipeableViewsCustom';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getAnalyticLayer } from 'services/MapView/apiMapViewConfig.services';
import { getWorkspaceStandCountAnalysis } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeStatusLoading } from 'store/slices/map-view/standCountAnalytics';
import { setDataStandCount } from 'store/slices/mapViewSlice';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';
import InfoTable from './InfoTable';

const StandCountAnalytic = () => {
  const dispatch = useAppDispatch();

  const { analysisId, selectedFieldId } = useAppSelector(tilingMapSelector);

  const { clientId } = useParams();

  const { data: rawDataStandCountAnalysis, isLoading } = useQuery(
    [QUERY_KEY.GET_WORKSPACE_STAND_COUNT, analysisId, selectedFieldId],
    () => getWorkspaceStandCountAnalysis(analysisId || ''),
    { enabled: !!analysisId }
  );

  const dataStandCountAnalysis = rawDataStandCountAnalysis?.data.map((item: any) => ({
    ...item,
    longX: item.long,
    latY: item.lat,
  }));

  const { data: colorsData, isLoading: isLoadingColors } = useQuery(
    [QUERY_KEY.COLOR_ANALYTIC_LAYER, clientId, analysisId],
    () => getAnalyticLayer(clientId || '', analysisId || ''),
    { enabled: !!clientId && !!analysisId }
  );

  useEffect(() => {
    dispatch(changeStatusLoading(isLoading || isLoadingColors));
  }, [dispatch, isLoading, isLoadingColors]);

  useEffect(() => {
    const colorsDataAnalytics = get(colorsData, 'data');
    if (dataStandCountAnalysis) {
      const standCountData: any = [];
      let indexStandCountStatus = 0;
      let colors: string[] = [];
      // count status of stand count tree or blank spot
      const statusList = [...new Set(dataStandCountAnalysis?.map((item: any) => item.status))];
      if (statusList.length === 1) {
        if (isEmpty(colorsDataAnalytics)) {
          colors = (statusList[0] + '').toLowerCase() === 'tree' ? [DIFFERENT_COLOR[1]] : [DIFFERENT_COLOR[0]];
        }
      } else if (isEmpty(colorsDataAnalytics)) {
        colors = [...DIFFERENT_COLOR];
      } else {
        colors = colorsDataAnalytics;
      }

      dataStandCountAnalysis.forEach((_item: any) => {
        const standCountCategory = standCountData.find((category: any) => category.status === _item.status);
        if (!standCountCategory) {
          standCountData.push({
            status: _item.status,
            visible: true,
            color: get(colors, `[${indexStandCountStatus}]`),
            data: [_item],
          });
          indexStandCountStatus++;
          return;
        }
        standCountCategory.data.push(_item);
      });
      if (!isLoadingColors && !isLoading) {
        dispatch(setDataStandCount(standCountData));
      }
    }
  }, [dataStandCountAnalysis, isLoadingColors, isLoading, colorsData, dispatch, selectedFieldId]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT}
      analyticTab={<InfoTable />}
      layerTab={<StandCountCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT} />}
      isShowDownloadButton={false}
      isInWorkspace
    />
  );
};

export default StandCountAnalytic;
