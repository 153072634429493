import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { AuditSingleLogStyle } from './AuditStyle';

const TableDatalogs = (props: any) => {
  const { data, date } = props;
  const classes = AuditSingleLogStyle();
  const [searchClient, setSearchClient] = useState('');
  const theme = useTheme();

  const dateRows = useMemo(() => {
    return date;
  }, [date]);

  const handleDataPerDate = useCallback((dataPerDate: { date: string; array: any[] }) => {
    const dataPerUser: any[] = [];
    dataPerDate?.array?.forEach((val: any) => {
      dataPerUser.push({
        name: val?.record[0]?.user?.name,
        email: val?.record[0]?.user?.email,
        count: val?.count,
      });
    });
    return dataPerUser;
  }, []);

  const dataLogUser = useMemo(() => {
    const dt: any[] = [];
    dateRows.forEach((value: any) => {
      const dataPerDate: any = data.find((val: any) => val.date === value);
      const setHandleDataPerDate = handleDataPerDate(dataPerDate);
      dt.push({
        date: value,
        array: setHandleDataPerDate,
      });
    });
    return dt;
  }, [data, dateRows, handleDataPerDate]);

  const debounceSearch = debounce((value) => setSearchClient(value), 500);

  const dataFilterSearch = useMemo(() => {
    if (!searchClient) return dataLogUser;
    const dataFilter = dataLogUser.map((val: any) => {
      return {
        date: val.date,
        array: val.array.filter((value: any) => {
          const name = value.name.toLowerCase();
          const email = value.email.toLowerCase();
          const searchLowerCase = searchClient.toLowerCase();
          return name.includes(searchLowerCase) || email.includes(searchLowerCase);
        }),
      };
    });
    return dataFilter;
  }, [dataLogUser, searchClient]);

  return (
    <Box className={classes.tableLogsContainer}>
      <Box className={classes.tableLogsContainer}>
        <Box className={classes.tableLogs}>
          <Typography className={classes.tableLogTitle}>Data User Logs</Typography>
          <Box className={classes.tableLogControl}>
            <TextField
              type="text"
              placeholder="Search User Name or Email..."
              sx={{
                mr: 1,
                width: '340px',
                minWidth: '340px',
                height: '40px',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '.MuiOutlinedInput-root': {
                  height: '40px',
                },
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '5px',
              }}
              onChange={(e) => {
                debounceSearch(e.target.value);
              }}
              InputProps={{
                size: 'small',
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ height: '24px', width: '24px' }} />
                  </InputAdornment>
                ),
                inputProps: {
                  style: {
                    fontFamily: 'Barlow',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '17px',
                  },
                },
              }}
            />
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 'none',
          }}>
          <Scrollbars
            style={{
              height: 'calc(100vh - 250px)',
              backgroundColor: theme.palette.mode === 'dark' ? '#3C4043' : '#fff',
            }}
            autoHide>
            <Table aria-label="collapsible table">
              <TableHead
                sx={{
                  borderBottom: `2px solid ${theme.palette.divider}`,
                }}>
                <TableRow
                  sx={{
                    height: '60px',
                  }}>
                  <TableCell className={classes.dateCellStyle}>Date</TableCell>
                  <Box className={classes.contentBox}>
                    <TableCell className={classes.cellStyle}>User Name</TableCell>
                    <TableCell className={classes.cellStyle}>User Email</TableCell>
                    <TableCell className={classes.cellStyleCenter}>Login Count</TableCell>
                  </Box>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataFilterSearch.map((row: any) => (
                  <React.Fragment key={row.date}>
                    <TableRow
                      sx={{
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}>
                      <TableCell
                        component="th"
                        sx={{
                          borderBottom: (theme) =>
                            theme.palette.mode === 'dark' ? `1px solid ${theme.palette.divider}` : 'none',
                        }}>
                        {row.date}
                      </TableCell>
                      {row.array.length ? (
                        row.array.map((value: any) => (
                          <Box className={classes.contentColumnBox} key={value.email}>
                            <TableCell className={classes.cellStyle}>{value.name}</TableCell>
                            <TableCell className={classes.cellStyle}>{value.email}</TableCell>
                            <TableCell className={classes.cellStyleCenter}>{value.count}</TableCell>
                          </Box>
                        ))
                      ) : (
                        <Box className={classes.contentColumnBox}>
                          <Typography className={classes.noDataCell}>No data</Typography>
                        </Box>
                      )}
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Scrollbars>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TableDatalogs;
