import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { circle, point } from '@turf/turf';
import { IStandPointData, ISwitchItem, ISwitchItemPayload, StandPointKeyEnum } from 'common/defines/analytic';
import { sizeByZoomLevel } from 'common/utils/util';
import update from 'immutability-helper';
import { RootState } from 'store/store';

interface IUserSlice {
  dataCircumference: any;
  dataCircumferencePolygon: any;
  dataCircumferenceInterval: any;
  isShowAllLayer: boolean;
  dataGeoJsonPoint: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  dataGeoJsonPolygon: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  zoomLayer: number;
  configSizePoint: any;
  sizeFixedPoint: number;
  chartDataCircumferencePreview: any;
  switchCircumferenceList: ISwitchItem[];
  circumferenceEditedDetail: IStandPointData | undefined;
}

const initialState: IUserSlice = {
  dataCircumference: null,
  dataCircumferencePolygon: [],
  dataCircumferenceInterval: null,
  isShowAllLayer: true,
  dataGeoJsonPoint: undefined,
  dataGeoJsonPolygon: undefined,
  zoomLayer: 16,
  configSizePoint: null,
  sizeFixedPoint: 0.0015,
  chartDataCircumferencePreview: null,
  switchCircumferenceList: [
    { key: StandPointKeyEnum.STAND_COUNT, label: 'Stand Count', visible: true, color: '#800080' },
    { key: StandPointKeyEnum.CIRCUMFERENCE, label: 'Circumference', visible: true, color: '#FFA500' },
  ],
  circumferenceEditedDetail: undefined,
};

export const circumferenceAnalyticsSlice = createSlice({
  name: 'circumferenceAnalytic',
  initialState,
  reducers: {
    clearCircumferenceAnalytics: () => {
      return initialState;
    },
    changeDataCircumferenceAnalytics: (state, action: PayloadAction<any>) => {
      state.dataCircumference = action.payload;
      if (!state.dataCircumferenceInterval || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((circumferenceInstance: any) => {
          let color = 'black';
          const circumferenceInterval = state.dataCircumferenceInterval?.range?.find(
            (item: any, index: number) =>
              item.from <= circumferenceInstance.cirLength &&
              (index === state.dataCircumferenceInterval?.range?.length - 1
                ? item.to >= circumferenceInstance.cirLength
                : item.to > circumferenceInstance.cirLength)
          );
          if (circumferenceInterval?.visible) {
            color = circumferenceInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(circumferenceInstance.longX || 0), Number(circumferenceInstance.latY || 0)], {
              ...circumferenceInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeVisibleIntervalCircumference: (state, action: PayloadAction<number>) => {
      const currentInterval = state.chartDataCircumferencePreview
        ? state.chartDataCircumferencePreview.intervalLimit
        : state.dataCircumferenceInterval;
      state.isShowAllLayer = currentInterval?.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      currentInterval.range = update(currentInterval?.range, {
        [action.payload]: {
          visible: { $set: !currentInterval.range[action.payload].visible },
        },
      });

      if (state.dataCircumference) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataCircumference.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.cirLength &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.cirLength
                  : item.to > vigorInstance.cirLength)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataCircumferencePolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataCircumferencePolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.cirLength &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.cirLength
                  : item.to > vigorInstance.cirLength)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeVisibleIntervalCircumferenceAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      if (state.dataCircumferenceInterval) {
        state.dataCircumferenceInterval.range = update(state.dataCircumferenceInterval.range, {
          $apply: (intervalRange: Array<any>) =>
            intervalRange.map((item: any) => {
              return {
                ...item,
                visible: action.payload,
              };
            }),
        });
      }
      if (state.dataCircumference) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataCircumference.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataCircumferenceInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.cirLength &&
                (index === state.dataCircumferenceInterval?.range?.length - 1
                  ? item.to >= vigorInstance.cirLength
                  : item.to > vigorInstance.cirLength)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataCircumferencePolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataCircumferencePolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataCircumferenceInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.cirLength &&
                (index === state.dataCircumferenceInterval?.range?.length - 1
                  ? item.to >= vigorInstance.cirLength
                  : item.to > vigorInstance.cirLength)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeDataCircumferenceInterval: (state, action: PayloadAction<any>) => {
      state.dataCircumferenceInterval = action.payload;
      if (state.dataCircumferencePolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataCircumferencePolygon.map((circumferenceInstance: any) => {
            let color = 'black';
            const circumferenceInterval = state.dataCircumferenceInterval.range?.find(
              (item: any, index: number) =>
                item.from <= circumferenceInstance.cirLength &&
                (index === state.dataCircumferenceInterval?.range?.length - 1
                  ? item.to >= circumferenceInstance.cirLength
                  : item.to > circumferenceInstance.cirLength)
            );
            if (circumferenceInterval?.visible) {
              color = circumferenceInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...circumferenceInstance.geometry },
              properties: {
                ...circumferenceInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
      if (!state.dataCircumference || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: state.dataCircumference.map((circumferenceInstance: any) => {
          let color = 'black';
          const vigorInterval = action.payload?.range?.find(
            (item: any, index: number) =>
              item.from <= circumferenceInstance.cirLength &&
              (index === action.payload?.range?.length - 1
                ? item.to >= circumferenceInstance.cirLength
                : item.to > circumferenceInstance.cirLength)
          );
          if (vigorInterval?.visible) {
            color = vigorInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(circumferenceInstance.longX || 0), Number(circumferenceInstance.latY || 0)], {
              ...circumferenceInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeDataPolygonCircumferenceAnalytics: (state, action: PayloadAction<any>) => {
      state.dataCircumferencePolygon = action.payload;
      if (!state.dataCircumferenceInterval) {
        return;
      }
      const geoJson = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((circumferenceInstance: any) => {
          let color = 'black';
          const circumferenceInterval = state.dataCircumferenceInterval.range?.find(
            (item: any, index: number) =>
              item.from <= circumferenceInstance.cirLength &&
              (index === state.dataCircumferenceInterval?.range?.length - 1
                ? item.to >= circumferenceInstance.cirLength
                : item.to > circumferenceInstance.cirLength)
          );
          if (circumferenceInterval?.visible) {
            color = circumferenceInterval.color;
          } else color = 'transparent';

          return {
            geometry: { ...circumferenceInstance.geometry },
            properties: {
              ...circumferenceInstance.properties,
              color: color,
            },
          };
        }),
      };
      state.dataGeoJsonPolygon = geoJson;
    },

    changeZoomLayerCircum: (state, action: PayloadAction<number>) => {
      state.zoomLayer = action.payload;
      const zoomLevel = action.payload;
      const currentRange = state.chartDataCircumferencePreview
        ? state.chartDataCircumferencePreview.intervalLimit?.range
        : state.dataCircumferenceInterval?.range;

      if (state.dataCircumference) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataCircumference.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentRange?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.cirLength &&
                (index === currentRange?.length - 1
                  ? item.to >= vigorInstance.cirLength
                  : item.to > vigorInstance.cirLength)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              sizeByZoomLevel(zoomLevel),
              { steps: 32 }
            );
          }),
        };
      }
    },
    changeFixedSizePoint: (state, action: PayloadAction<any>) => {
      state.sizeFixedPoint = action.payload;
    },
    changeChartDataCircumferencePreview: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        const intervalLimit = {
          ...state.dataCircumferenceInterval,
          range: action.payload.range,
        };
        const chartData: any = [];
        action.payload.range.forEach((item: any) => {
          const listByRange = state.dataCircumference.filter(
            (chlorophyll: any) => chlorophyll.cirLength >= item.from && chlorophyll.cirLength <= item.to
          );
          chartData.push({ [item.lable]: listByRange.length });
        });
        let dataPreview = { chartData, intervalLimit };

        state.chartDataCircumferencePreview = dataPreview;

        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataCircumference.map((circumferenceInstance: any) => {
            let color = 'black';
            const circumferenceInterval = state.chartDataCircumferencePreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= circumferenceInstance.cirLength &&
                (index === state.chartDataCircumferencePreview?.intervalLimit?.range?.length - 1
                  ? item.to >= circumferenceInstance.cirLength
                  : item.to > circumferenceInstance.cirLength)
            );
            if (circumferenceInterval?.visible) {
              color = circumferenceInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(circumferenceInstance.longX || 0), Number(circumferenceInstance.latY || 0)], {
                ...circumferenceInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
        state.dataGeoJsonPolygon = {
          type: 'FeatureCollection' as any,
          features: state.dataCircumferencePolygon.map((circumferenceInstance: any) => {
            let color = 'black';
            const circumferenceInterval = state.chartDataCircumferencePreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= circumferenceInstance.cirLength &&
                (index === state.chartDataCircumferencePreview?.intervalLimit?.range?.length - 1
                  ? item.to >= circumferenceInstance.cirLength
                  : item.to > circumferenceInstance.cirLength)
            );
            if (circumferenceInterval?.visible) {
              color = circumferenceInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...circumferenceInstance.geometry },
              properties: {
                ...circumferenceInstance.properties,
                color: color,
              },
            };
          }),
        };
      } else {
        state.chartDataCircumferencePreview = null;
      }
    },

    changeSwitchCircumferenceListValue: (state, action: PayloadAction<ISwitchItemPayload>) => {
      const itemKey = action.payload.key;
      state.switchCircumferenceList = state.switchCircumferenceList.map((item) =>
        item.key === itemKey ? { ...item, ...action.payload } : item
      );
    },

    changeCircumferenceEditedDetail: (state, action: PayloadAction<IStandPointData | undefined>) => {
      state.circumferenceEditedDetail = action.payload;
    },
  },
});

export const {
  clearCircumferenceAnalytics,
  changeDataCircumferenceAnalytics,
  changeDataCircumferenceInterval,
  changeVisibleIntervalCircumference,
  changeVisibleIntervalCircumferenceAllLayers,
  changeDataPolygonCircumferenceAnalytics,
  changeZoomLayerCircum,
  changeFixedSizePoint,
  changeChartDataCircumferencePreview,
  changeSwitchCircumferenceListValue,
  changeCircumferenceEditedDetail,
} = circumferenceAnalyticsSlice.actions;

export const circumferenceAnalyticsSelector = (state: RootState) => state.circumferenceAnalytic;

export default circumferenceAnalyticsSlice.reducer;
