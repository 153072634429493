import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import setupInterceptors from 'services';

function InjectAxiosInterceptors() {
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setupInterceptors(history, location);
  }, [history, location]);

  return null;
}

export default InjectAxiosInterceptors;
