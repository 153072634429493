import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/styles';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { startOfYear } from 'date-fns';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { changeAllIssuesStatus, getListDisplayedIssues } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import {
  changeShowDeleteIssueModal,
  changeShowIssueModal,
  changeShowShareIssueModal,
  rightBarSelector,
} from 'store/slices/rightBarSlice';
import CardIssueList from './components/CardIssueList';
import CalendarModal from './components/modals/CalendarModal';
import CreateAndEditIssueModal from './components/modals/create-and-edit-issue-modal';
import DeleteIssueModal from './components/modals/DeleteIssueModal';
import DropMarkerModal from './components/modals/DropMarkerModal';
import ShareIssueModal from './components/modals/ShareIssueModal';
import { IDateRange, IIssue } from './interfaces';

const IssuesTab = () => {
  const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);
  const [isShowDropMarkerModal, setIsShowDropMarkerModal] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<IDateRange>({
    endDate: new Date(),
    key: 'selection',
  });
  const { t } = useTranslation();
  const [dateOldestIssue, setDateOldestIssue] = useState<Date>(startOfYear(new Date()));

  const dispatch = useAppDispatch();
  const {
    issuesTab: { isShowIssueModal, deleteIssueModal, shareIssueModal },
  } = useAppSelector(rightBarSelector);
  const { levelId } = useAppSelector(mapViewSelector);

  useQuery([QUERY_KEY.GET_LIST_DISPLAYED_ISSUES, levelId], () => getListDisplayedIssues(levelId!), {
    onSuccess: (res: any) => {
      const resData = res.data as IIssue[];
      const dataSort = resData?.sort((issueA, issueB) => {
        return new Date(issueA.date).getTime() - new Date(issueB.date).getTime();
      });
      // First load, set oldest date or start date of current year, else set current date
      let startDate =
        selectedDate.startDate || (dataSort?.[0]?.date ? new Date(dataSort[0].date) : startOfYear(new Date()));

      if (dataSort?.[0]?.date) {
        setDateOldestIssue(new Date(dataSort[0].date));
      }

      // Set check all if all issue has status = true
      const hasIssueNotChecked = resData?.some((issue: IIssue) => issue.status === false);
      setCheckAll(resData?.length > 0 && !hasIssueNotChecked);

      // All issue checked, set oldest date or start date of current year
      if (resData?.length > 0 && !hasIssueNotChecked) {
        startDate = selectedDate.startDate
          ? selectedDate.startDate
          : dataSort?.[0]?.date
          ? new Date(dataSort[0].date)
          : startOfYear(new Date());
      }

      setSelectedDate({
        ...selectedDate,
        startDate,
      });
    },
    enabled: !!levelId,
  });

  const mapViewStylesClasses = useMapViewStyle();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const changeCheckAllMutation = useMutation(changeAllIssuesStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.GET_LIST_ISSUE_PAGINATION);
      queryClient.invalidateQueries(QUERY_KEY.GET_LIST_DISPLAYED_ISSUES);
    },
  });

  const formatDate = (date: Date | undefined) => {
    return moment(date).format('DD-MM-YYYY');
  };

  const handleAddIssueCard = () => {
    dispatch(changeShowIssueModal(true));
  };

  const handleChangeCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll(event.target.checked);
    changeCheckAllMutation.mutate({ status: event.target.checked });
  };

  const CustomBox = styled(Box)({
    borderRadius: '8px',
    border: `1px solid ${theme.palette.divider}`,
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const handlerChangeDateSelect = (date: IDateRange) => {
    setSelectedDate(date);
  };

  return (
    <Box>
      <Box sx={{ mb: '16px' }}>
        <Typography className={mapViewStylesClasses.titleInput}>{t('mapView.issues')}</Typography>
      </Box>
      {levelId && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: '4px' }}>
            <CustomBox>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked={checkAll} onChange={handleChangeCheckAll} />}
                  label={t('trans.all')}
                  sx={{ m: 0, mr: '9px' }}
                />
              </FormGroup>
            </CustomBox>
            <CustomBox sx={{ width: '42px', cursor: 'pointer' }} onClick={handleAddIssueCard}>
              <AddIcon sx={{ color: theme.palette.primary.main }} />
            </CustomBox>
            <CustomBox sx={{ flexGrow: 1, position: 'relative' }}>
              <Box sx={{ display: 'inline', fontWeight: '500' }}>{t('trans.filter_by_date')}: </Box>
              <Typography>
                &nbsp;{`${formatDate(selectedDate.startDate)} - ${formatDate(selectedDate.endDate)}`}
              </Typography>
              <MoreVertIcon
                onClick={() => setIsShowCalendar(true)}
                sx={{ cursor: 'pointer', position: 'relative', left: '6px', color: theme.palette.primary.main }}
              />
            </CustomBox>
          </Box>
          <Box sx={{ mt: '12px' }}>
            <CardIssueList selectedDate={selectedDate} />
          </Box>
        </>
      )}
      <CalendarModal
        isShow={isShowCalendar}
        handleClose={() => setIsShowCalendar(false)}
        setSelectedDate={handlerChangeDateSelect}
        selectedDate={selectedDate}
        dateOldestIssue={dateOldestIssue}
      />
      <CreateAndEditIssueModal
        isShow={isShowIssueModal}
        handleClose={() => dispatch(changeShowIssueModal(false))}
        setIsShowDropMarkerModal={setIsShowDropMarkerModal}
      />
      <DeleteIssueModal
        isShow={deleteIssueModal.isShow}
        handleClose={() => dispatch(changeShowDeleteIssueModal({ isShow: false, id: '' }))}
      />
      <DropMarkerModal isShow={isShowDropMarkerModal} handleClose={() => setIsShowDropMarkerModal(false)} />

      <ShareIssueModal
        isShow={shareIssueModal.isShow}
        handleClose={() => dispatch(changeShowShareIssueModal({ isShow: false, data: [] }))}
      />
    </Box>
  );
};

export default IssuesTab;
