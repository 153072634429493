import { SvgIcon } from '@mui/material';
import { Twitter2 } from 'assets/icons';
import { TypeSocial } from 'common/defines/clients';
import { TWITTER_CLIENT_ID } from 'common/dummy/dummyClients';
import React, { useCallback } from 'react';
import { LoginSocialTwitter } from 'reactjs-social-login';

interface ITwitterPageProps {
  mutation: any;
}

const TwitterLoginSocial = ({ mutation }: ITwitterPageProps) => {
  const onLoginStart = useCallback(() => {}, []);

  const responseTwitter = (res: any) => {
    const data = {
      snsToken: res?.data?.access_token,
      snsType: TypeSocial.TWITTER,
    };
    mutation.mutate(data);
  };
  return (
    <LoginSocialTwitter
      client_id={TWITTER_CLIENT_ID || ''}
      redirect_uri={`${window.location.origin}`}
      onLoginStart={onLoginStart}
      fields="profile_image_url"
      // onLogoutSuccess={onLogoutSuccess}
      onResolve={responseTwitter}
      onReject={(err: any) => {
        console.log(err);
      }}>
      <SvgIcon component={Twitter2} inheritViewBox style={{ cursor: 'pointer' }} fontSize="small" />
    </LoginSocialTwitter>
  );
};

export default TwitterLoginSocial;
