import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { ITask } from 'interfaces/workspace';
import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { deleteVectorContourIntelligent } from 'services/workspaces';
import { useAppDispatch } from 'store/hooks';
import { resetDataVectorContour } from 'store/slices/rightBarSlice';
import CustomBox from '../CustomBox';
import ContourGenerationSettingModal from './ContourGenerationSettingModal';

interface ContourGenerationProps {
  selectedTask: ITask;
}

const ContourGeneration: FC<ContourGenerationProps> = ({ selectedTask }) => {
  const [isShowSettingModal, setIsShowSettingModal] = useState<boolean>(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { dtmGeoTIFFPath, taskId } = selectedTask;
  const existDtm = !!dtmGeoTIFFPath;
  const isDisabledButton = !existDtm && !taskId;

  const renderTooltipTitle = isDisabledButton ? 'To generate vector contour, please upload the DTM file' : '';

  const deleteVectorContourIntelligentMutation = useMutation(deleteVectorContourIntelligent, {
    onSuccess() {
      toast.success('Delete vector contour successfully');
      dispatch(resetDataVectorContour());
    },
  });

  const handleDeleteVectorContourIntelligent = () => {
    deleteVectorContourIntelligentMutation.mutate(selectedTask?._id);
  };

  const handleClickSettingIcon = () => {
    if (isDisabledButton) return;
    setIsShowSettingModal(true);
  };

  return (
    <CustomBox>
      <Typography fontSize="16px">Contour</Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gap="8px">
        <Tooltip title={renderTooltipTitle}>
          <Box
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={handleClickSettingIcon}>
            <SettingsOutlinedIcon />
          </Box>
        </Tooltip>

        <DeleteForeverOutlinedIcon
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: theme.palette.error.main,
            },
          }}
          onClick={handleDeleteVectorContourIntelligent}
        />
      </Box>
      {isShowSettingModal && <ContourGenerationSettingModal handleClose={() => setIsShowSettingModal(false)} />}
    </CustomBox>
  );
};

export default ContourGeneration;
