import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Box, Tooltip, useTheme } from '@mui/material';
import { FC, MouseEvent } from 'react';

interface FieldDeleteProps {
  fieldId: string;
  isIconActive: boolean;
  isShowConfirmDeleteModal: boolean;
  setIsShowDeletedListTaskModal: (arg: boolean) => void;
}

const FieldDelete: FC<FieldDeleteProps> = ({ isIconActive, setIsShowDeletedListTaskModal }) => {
  const theme = useTheme();

  const handleDeleteField = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isIconActive) {
      setIsShowDeletedListTaskModal(true);
    }
  };

  return (
    <Box
      sx={{
        '&:hover': {
          color: isIconActive ? theme.palette.primary.main : '',
        },
      }}
      onClick={handleDeleteField}>
      <Tooltip title="Delete Field">
        <DeleteForeverOutlinedIcon sx={{ opacity: isIconActive ? 1 : 0.5 }} />
      </Tooltip>
    </Box>
  );
};

export default FieldDelete;
