import { QUERY_KEY } from 'constants/constants';
import { useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getStandCountBoundary } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const StandCountBoundary = () => {
  const [geometry, setGeometry] = useState<GeoJSON.Geometry>();
  const { isShowStandCountBoundary, analyticId } = useAppSelector(mapViewSelector);

  useQuery([QUERY_KEY.STAND_COUNT_BOUNDARY, analyticId], () => getStandCountBoundary(analyticId!), {
    onSuccess(res) {
      setGeometry(res.data?.geometry);
    },
    enabled: !!analyticId,
  });

  const boundaryGeojson: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry,
      },
    ] as GeoJSON.Feature[],
  };

  return (
    <Source id={`stand_count_boundary_source`} type="geojson" data={boundaryGeojson}>
      {/* 2d contour layer */}
      <Layer
        id={`stand_count_boundary_layer`}
        type="line"
        paint={{
          'line-color': '#1EBADB',
          'line-opacity': 1,
          'line-width': 2,
        }}
        layout={{ visibility: isShowStandCountBoundary ? 'visible' : 'none' }}
      />
    </Source>
  );
};

export default StandCountBoundary;
