import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { changeShowDeleteIssueModal } from 'store/slices/rightBarSlice';

interface DeleteIssueProps {
  id: string;
  colorByTheme?: string;
}
const DeleteIssue: FC<DeleteIssueProps> = ({ id, colorByTheme }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('mapView.issue.delete')}>
      <DeleteForeverOutlinedIcon
        sx={{ cursor: 'pointer', color: colorByTheme }}
        onClick={() => dispatch(changeShowDeleteIssueModal({ isShow: true, id }))}
      />
    </Tooltip>
  );
};

export default DeleteIssue;
