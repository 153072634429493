import SearchIcon from '@mui/icons-material/Search';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { changeFlyToState } from 'store/slices/rightBarSlice';
import { IIssue } from '../../interfaces';

interface FindIssueLocationProps {
  data: IIssue;
  colorByTheme: string;
}

const FindIssueLocation: FC<FindIssueLocationProps> = ({ data, colorByTheme }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleFlyToLocation = () => {
    dispatch(changeFlyToState({ geometry: data.geometry, done: false }));
  };

  return (
    <Tooltip title={t('mapView.issue.find_location')}>
      <SearchIcon sx={{ cursor: 'pointer', color: colorByTheme }} onClick={handleFlyToLocation} />
    </Tooltip>
  );
};

export default FindIssueLocation;
