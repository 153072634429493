import { SvgIcon } from '@mui/material';
import React from 'react';
import { Microsoft } from 'assets/icons';
import { loginRequest } from 'authConfig';
import { TypeSocial } from 'common/defines/clients';
import { useMsal } from '@azure/msal-react';

interface IMicrosoftLoginSocialProps {
  mutation: any;
}

export const MicrosoftLoginSocial = ({ mutation }: IMicrosoftLoginSocialProps) => {
  const { instance } = useMsal();
  const handleLoginMS = async (instance: any) => {
    const res = await instance.loginPopup(loginRequest).catch((e: any) => {
      console.error(e);
    });
    if (res) {
      const data = {
        snsToken: res.accessToken,
        snsType: TypeSocial.MICROSOFT,
      };
      mutation.mutate(data);
    }
  };
  return (
    <SvgIcon
      component={Microsoft}
      inheritViewBox
      style={{ cursor: 'pointer' }}
      fontSize="small"
      onClick={() => handleLoginMS(instance)}
    />
  );
};
