import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILinesVectorDisplay, IStreamlineOrderDisplay } from 'common/defines/clients';
import { DrawIssueShapeEnum, IIssue } from 'components/MapView/RightBar/IssuesTab/interfaces';
import type { RootState } from '../store';

interface IDrawIssueShape {
  type: DrawIssueShapeEnum | null;
  isShow: boolean;
  geometry?: {
    coordinates: any[];
    type: string;
  };
  initialData?: {
    id: string;
  };
  isLockMap?: boolean;
}

interface IDropMarker {
  isShow?: boolean;
  coordinates?: number[];
  initialData?: {
    id: string;
    coordinates: number[];
  };
}

interface IDataVectorContour {
  vectorId?: string;
  size: string;
  isLoading?: boolean;
  color: string;
  page?: number;
}

interface IPolygon3d {
  id: string;
  sideSurfaceOpacity: number;
  colorSideSurface: string;
  isSameColorTopSurface: boolean;
  isLayer3D: boolean;
  isDisplayBoundaryLine: boolean;
  topSurfaceOpacity: number;
  isSameSideSurface: boolean;
}

const initialPolygon3d: IPolygon3d = {
  id: '',
  colorSideSurface: 'rgb(255, 255, 255)',
  isSameColorTopSurface: false,
  sideSurfaceOpacity: 1,
  isLayer3D: false,
  isDisplayBoundaryLine: true,
  topSurfaceOpacity: 1,
  isSameSideSurface: false,
};
type TPolygon3dPayload = Pick<IPolygon3d, 'id'> & Partial<IPolygon3d>;

export interface IFlyTo {
  done: boolean;
  geometry: {
    coordinates: any[];
    type: string;
  };
}

interface IRightBarSlice {
  analyticName: string | null;
  analyticReLabel: string;
  dataVectorContour: IDataVectorContour;
  issuesTab: {
    isImageChecked: boolean;
    isShowIssueModal: boolean;
    dropMarker: IDropMarker;
    drawIssueShape: IDrawIssueShape;
    deleteIssueModal: {
      isShow: boolean;
      id: string;
    };
    editIssueData: IIssue;
    shareIssueModal: {
      isShow: boolean;
      data: any;
    };
    polygon3dList: IPolygon3d[];
    flyTo: IFlyTo;
  };
  streamlineOrderDisplay: IStreamlineOrderDisplay[];
  streamlineOrderSensorId: string;
  linesVectorDisplay: ILinesVectorDisplay[];
  linesVectorSensorId: string;
}

const initialDataVectorContour = {
  vectorId: '',
  size: '',
  isLoading: false,
  color: '',
  page: 1,
};

const initialState: IRightBarSlice = {
  analyticName: null,
  analyticReLabel: '',
  dataVectorContour: initialDataVectorContour,
  issuesTab: {
    isImageChecked: false,
    isShowIssueModal: false,
    shareIssueModal: {
      isShow: false,
      data: [],
    },
    dropMarker: {
      isShow: false,
      coordinates: [],
      initialData: {
        id: '',
        coordinates: [],
      },
    },
    drawIssueShape: {
      type: null,
      isShow: false,
      geometry: {
        coordinates: [],
        type: '',
      },
      initialData: {
        id: '',
      },
      isLockMap: false,
    },
    deleteIssueModal: {
      isShow: false,
      id: '',
    },
    editIssueData: {
      _id: '',
      date: '',
      description: '',
      document: '',
      geometry: {
        type: '',
        coordinates: [],
      },
      levelId: '',
      type: DrawIssueShapeEnum.IMAGE,
      images: [],
      area: 0,
      length: 0,
      isDisplayBoundaryLine: true,
      isLayer3D: false,
      colorSideSurface: 'rgb(255, 255, 255)',
      isSameColorTopSurface: false,
      sideSurfaceOpacity: 1,
      topSurfaceOpacity: 1,
      isSameSideSurface: false,
    },
    polygon3dList: [],
    flyTo: {
      done: false,
      geometry: {
        coordinates: [],
        type: '',
      },
    },
  },
  streamlineOrderDisplay: [],
  streamlineOrderSensorId: '',
  linesVectorDisplay: [],
  linesVectorSensorId: '',
};

export const rightBarSlice = createSlice({
  name: 'rightBar',
  initialState,
  reducers: {
    changeAnalyticName: (state, action: PayloadAction<string | null>) => {
      state.analyticName = action.payload;
    },
    changeAnalyticReLabel: (state, action: PayloadAction<string>) => {
      state.analyticReLabel = action.payload;
    },
    changeIssueShowCheckbox: (state, action: PayloadAction<boolean>) => {
      state.issuesTab.isImageChecked = action.payload;
    },
    changeShowIssueModal: (state, action: PayloadAction<boolean>) => {
      state.issuesTab.isShowIssueModal = action.payload;
    },
    changeDropMarkerInfo: (state, action: PayloadAction<IDropMarker>) => {
      state.issuesTab.dropMarker = { ...state.issuesTab.dropMarker, ...action.payload };
    },
    changeShowDeleteIssueModal: (state, action: PayloadAction<{ isShow: boolean; id: string }>) => {
      state.issuesTab.deleteIssueModal = action.payload;
    },
    updateEditIssueData: (state, action: PayloadAction<IIssue>) => {
      state.issuesTab.editIssueData = action.payload;
    },
    resetEditIssueData: (state) => {
      state.issuesTab.editIssueData = initialState.issuesTab.editIssueData;
    },
    changeDrawIssueShape: (state, action: PayloadAction<IDrawIssueShape>) => {
      state.issuesTab.drawIssueShape = { ...state.issuesTab.drawIssueShape, ...action.payload };
    },
    changeShowShareIssueModal: (state, action: PayloadAction<{ isShow: boolean; data: any }>) => {
      state.issuesTab.shareIssueModal = action.payload;
    },
    updateDataVectorContour: (state, action: PayloadAction<IDataVectorContour>) => {
      state.dataVectorContour = action.payload;
    },
    resetDataVectorContour: (state) => {
      state.dataVectorContour = initialDataVectorContour;
    },
    initial3dPolygonList: (state, action: PayloadAction<TPolygon3dPayload[]>) => {
      state.issuesTab.polygon3dList = action.payload.map((item) => ({ ...initialPolygon3d, ...item }));
    },
    change3dPolygonValue: (state, action: PayloadAction<TPolygon3dPayload>) => {
      const idList = state.issuesTab.polygon3dList.map((item) => item.id);
      if (idList.includes(action.payload.id)) {
        state.issuesTab.polygon3dList = state.issuesTab.polygon3dList.map((item) =>
          item.id !== action.payload.id ? item : { ...item, ...action.payload }
        );
      } else {
        state.issuesTab.polygon3dList = [...state.issuesTab.polygon3dList, { ...initialPolygon3d, ...action.payload }];
      }
    },
    changeFlyToState: (state, action: PayloadAction<Partial<IFlyTo>>) => {
      state.issuesTab.flyTo = { ...state.issuesTab.flyTo, ...action.payload };
    },
    changeAllStreamlineOrderDisplay: (state, action: PayloadAction<IStreamlineOrderDisplay[]>) => {
      state.streamlineOrderDisplay = action.payload;
    },
    changeEachStreamlineOrderDisplay: (state, action: PayloadAction<{ order: number }>) => {
      state.streamlineOrderDisplay = state.streamlineOrderDisplay.map((item) =>
        item.order === action.payload.order ? { ...item, visible: !item.visible } : item
      );
    },
    changeStreamlineOrderSensorId: (state, action: PayloadAction<string>) => {
      state.streamlineOrderSensorId = action.payload;
    },
    changeAllLinesVectorDisplay: (state, action: PayloadAction<ILinesVectorDisplay[]>) => {
      state.linesVectorDisplay = action.payload;
    },
    changeEachLinesVectorDisplay: (state, action: PayloadAction<{ _id: string }>) => {
      state.linesVectorDisplay = state.linesVectorDisplay.map((item) =>
        item._id === action.payload._id ? { ...item, visible: !item.visible } : { ...item, visible: false }
      );
    },
    changeLinesVectorSensorId: (state, action: PayloadAction<string>) => {
      state.linesVectorSensorId = action.payload;
    },
  },
});

export const {
  changeAnalyticName,
  changeAnalyticReLabel,
  changeIssueShowCheckbox,
  changeShowIssueModal,
  changeDropMarkerInfo,
  updateEditIssueData,
  resetEditIssueData,
  changeShowDeleteIssueModal,
  changeDrawIssueShape,
  changeShowShareIssueModal,
  updateDataVectorContour,
  resetDataVectorContour,
  initial3dPolygonList,
  change3dPolygonValue,
  changeFlyToState,
  changeAllStreamlineOrderDisplay,
  changeEachStreamlineOrderDisplay,
  changeStreamlineOrderSensorId,
  changeAllLinesVectorDisplay,
  changeEachLinesVectorDisplay,
  changeLinesVectorSensorId,
} = rightBarSlice.actions;

export const rightBarSelector = (state: RootState) => state.rightBar;

export default rightBarSlice.reducer;
