import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DatePicker } from '@mui/lab';
import { Box, Button, Collapse, Grid, InputLabel, TextField, TextFieldProps, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { DATA_RASTERS, LABEL_DATA_RASTERS, LayerTypeEnum, TypeRaster } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import SelectFile from 'components/Common/SelectFile';
import SkeletonCommon from 'components/Common/SkeletonCommon';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { getRasterDetails, updateRasterAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector, changePageRasterPagination } from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { ErrorsDialog } from './ErrorDialog';
interface IUpdateRaster {
  sensorId: string;
  name: TypeRaster;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

export const UpdateRaster = ({ sensorId, name, deleteFileGeojson }: IUpdateRaster) => {
  const queryClient = useQueryClient();
  const { levelId } = useAppSelector(mapViewSelector);
  const dispatch = useAppDispatch();
  const { pageRaster } = useAppSelector(anaLyticConfigSelector);
  const theme = useTheme();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [dataErrors, setDataErrors] = useState(null as any);
  const [isShowRaster, setShowRaster] = useState<boolean>(false);
  const [isShowVector, setShowVector] = useState<boolean>(false);
  const classes = useLayerSelectCardStyle();
  const { t } = useTranslation();

  const vectorFormList = [
    { key: 1, layerType: LayerTypeEnum.VECTOR_CONTOUR, acceptFile: new RegExp(/.zip$/) },
    { key: 2, layerType: LayerTypeEnum.STREAM_LINE_ORDER, acceptFile: new RegExp(/.geojson$/) },
    { key: 3, layerType: LayerTypeEnum.LINES, acceptFile: new RegExp(/.geojson$/) },
  ];

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const {
    data: rasterDetails,
    isLoading,
    refetch,
  } = useQuery([QUERY_KEY.RASTER_DETAILS, sensorId], () => getRasterDetails(sensorId));

  const updateAnalyticMutation = useMutation((data: any) => updateRasterAnalytic(data), {
    onSuccess: (res: any) => {
      if (res.data.status === 400) {
        toast.error('Update Raster Info Error', { toastId: 2 });
        return;
      }
      refetch();
      toast.success('Update Raster Info Successful', { toastId: 1 });
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message || 'Something went wrong!');
    },
  });

  const initialFields = useMemo(() => {
    let initParam = {
      date: isLoading ? null : moment(rasterDetails?.data?.date),
      description: rasterDetails?.data?.description,
    };

    if (rasterDetails?.data?.uploadHistory) {
      rasterDetails.data.uploadHistory.forEach((_item: any) => {
        initParam = {
          ...initParam,
          [_item.layerType]: _item,
        };
      });
    }

    return initParam;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, rasterDetails]);

  const isDisabled = (values: any) => {
    const dateMatches = moment(rasterDetails?.data?.date).isSame(values.date, 'day');
    const descriptionMatches = values.description === rasterDetails?.data?.description;
    const isLoading = updateAnalyticMutation.isLoading;

    return !values.date || (dateMatches && descriptionMatches) || isLoading;
  };

  return (
    <>
      {isLoading ? (
        <SkeletonCommon count={4} />
      ) : (
        <Formik
          initialValues={Object.assign(initialFields)}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            updateAnalyticMutation.mutate(
              {
                date: moment(values.date).format('YYYY-MM-DD'),
                id: sensorId,
                description: values.description,
              },
              {
                onSuccess: () => {
                  resetForm();
                },
              }
            );
          }}>
          {({ values, setFieldValue, handleSubmit, submitForm, handleChange, touched, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid display="flex" width="100%">
                  <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                    <Grid display="flex" alignItems="center" justifyContent="space-between">
                      <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                        {t('trans.date')}
                      </InputLabel>
                      <DatePicker
                        value={values.date}
                        disabled={updateAnalyticMutation.isLoading}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params: TextFieldProps) => (
                          <TextField type="text" {...params} sx={{ width: '350px !important' }} size="small" />
                        )}
                        onChange={(value: any) => setFieldValue('date', value)}
                      />
                    </Grid>
                    <Grid display="flex" alignItems="center" justifyContent="space-between" sx={{ pt: 2 }}>
                      <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                        {t('trans.description')}
                      </InputLabel>
                      <TextField
                        sx={{
                          width: '350px',
                          minWidth: '350px',
                          height: '40px',
                          padding: 0,
                          '.MuiInputBase-input': {
                            padding: '8px 10px !important',
                          },
                          borderRadius: '5px',
                        }}
                        fullWidth
                        id="description"
                        name="description"
                        placeholder={t('trans.description')}
                        type="text"
                        value={values.description}
                        onChange={handleChange}
                        error={touched.description && Boolean(errors.description)}
                      />
                    </Grid>

                    <Box>
                      <Button
                        style={{ padding: 0 }}
                        // startIcon={isShowRaster ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        onClick={() => setShowRaster(!isShowRaster)}
                        classes={{ root: classes.buttonTextStyle }}>
                        {!isShowRaster ? <ChevronRightIcon /> : <KeyboardArrowDownIcon />}
                        {t('trans.raster')}
                      </Button>
                      <Collapse in={isShowRaster} timeout="auto" sx={{ px: 1 }}>
                        <Box sx={{ paddingLeft: '12px' }}>
                          {DATA_RASTERS[name].map((item) => (
                            <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                              <InputLabel
                                variant="standard"
                                sx={{ fontSize: '14px', margin: 0 }}
                                htmlFor="uncontrolled-native">
                                {t(convertTextToMultiLanguage(LABEL_DATA_RASTERS[item], 'mapView'))}
                              </InputLabel>
                              <SelectFile
                                acceptFile={new RegExp(/.zip$/)}
                                fileSelected={values[item]}
                                disabled={updateAnalyticMutation.isLoading}
                                onSelectFile={(file) => setFieldValue(item, file)}
                                analysisId={sensorId}
                                layerType={item}
                                deleteFileGeojson={deleteFileGeojson}
                              />
                            </Grid>
                          ))}
                        </Box>
                      </Collapse>
                    </Box>

                    <Box>
                      <Button
                        style={{ padding: 0 }}
                        onClick={() => setShowVector(!isShowVector)}
                        classes={{ root: classes.buttonTextStyle }}>
                        {!isShowVector ? <ChevronRightIcon /> : <KeyboardArrowDownIcon />}
                        {t('trans.vector')}
                      </Button>
                      <Collapse in={isShowVector} timeout="auto" sx={{ px: 1 }}>
                        <Box sx={{ paddingLeft: '12px' }}>
                          {vectorFormList.map((item) => (
                            <Grid
                              key={item.key}
                              display="flex"
                              alignItems="center"
                              mt={2}
                              justifyContent="space-between">
                              <InputLabel
                                variant="standard"
                                sx={{ fontSize: '14px', margin: 0 }}
                                htmlFor="uncontrolled-native">
                                {t(convertTextToMultiLanguage(LABEL_DATA_RASTERS[item.layerType], 'mapView'))}
                              </InputLabel>
                              <SelectFile
                                acceptFile={item.acceptFile}
                                fileSelected={values[item.layerType]}
                                disabled={updateAnalyticMutation.isLoading}
                                onSelectFile={(file) => setFieldValue(item.layerType, file)}
                                analysisId={sensorId}
                                layerType={item.layerType}
                                deleteFileGeojson={deleteFileGeojson}
                              />
                            </Grid>
                          ))}
                        </Box>
                      </Collapse>
                    </Box>
                  </Grid>
                  <Grid marginLeft={'auto'}>
                    <ButtonCustom
                      title={t('trans.save')}
                      border="0px"
                      colorLabel="#FFF"
                      sx={{ width: '160px', height: '1.4375em' }}
                      backgroundColor={theme.palette.primary.main}
                      onClick={() => {
                        submitForm();
                      }}
                      disabled={isDisabled(values)}
                      loading={updateAnalyticMutation.isLoading}
                    />
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      )}
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </>
  );
};
