import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import ReactECharts from 'echarts-for-react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IPieChartCommon {
  title: string;
  dataChart: any;
  colorCharts: Array<string>;
  formatText?: (data: any) => void;
  tooltipFormatter?: string;
  withoutConvertMultiLanguage?: boolean;
}

const PieChartCommon = ({
  dataChart,
  colorCharts,
  formatText,
  title,
  tooltipFormatter = '{b} {d}%',
  withoutConvertMultiLanguage = false,
}: IPieChartCommon) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const dataChartWithoutConvert = dataChart?.map((item: any) => ({
    ...item,
    name: t(convertTextToMultiLanguage(item.name, 'mapView')),
  }));
  const convertedDataChart = withoutConvertMultiLanguage ? dataChart : dataChartWithoutConvert;

  const options = useMemo(() => {
    return {
      legend: {
        orient: 'vertical',
        left: 0,
        top: 'bottom',
        formatter: (name: any) => (formatText ? formatText(name) : name),
        textStyle: {
          height: 10,
          width: 300,
          overflow: 'truncate',
        },
        tooltip: {
          show: true,
          formatter: (item: any) => (formatText ? formatText(item.name) : item.name),
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: tooltipFormatter,
        padding: 5,
        position: function (pos: any) {
          return [pos[0] - 60, pos[1] + 25];
        },
        backgroundColor: theme.palette.background.paper,
        textStyle: {
          fontSize: 12,
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
        borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
        borderWidth: 1,
        z: 10,
      },
      backgroundColor: theme.palette.background.paper,
      series: [
        {
          type: 'pie',
          width: '200px',
          height: '200px',
          left: '100px',
          top: '10px',
          avoidLabelOverlap: false,
          data: convertedDataChart,
          selectedMode: true,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          color: colorCharts,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }, [
    tooltipFormatter,
    theme.palette.background.paper,
    theme.palette.mode,
    colorCharts,
    formatText,
    convertedDataChart,
  ]);

  const heightChart = useMemo(() => 220 + dataChart.length * 25, [dataChart]);

  return (
    <Box
      sx={{
        // border: `1px solid ${theme.palette.divider}`,
        // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        borderRadius: '5px',
      }}>
      <Grid container direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
            alignSelf: 'center',
            px: 3,
            pt: 1,
          }}>
          {title && t(convertTextToMultiLanguage(title, 'analytic'))}
        </Typography>
      </Grid>
      <Stack sx={{ px: 2.5, my: '15px' }} direction="row" spacing={3}>
        <ReactECharts
          option={options}
          style={{ height: `${heightChart}px`, width: '100%' }}
          theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
        />
      </Stack>
    </Box>
  );
};

export default memo(PieChartCommon);
