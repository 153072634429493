import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import BigNumber from 'bignumber.js';
import SkeletonCommon from 'components/Common/SkeletonCommon';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { SubmitHandler, useFieldArray } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createInterval, updateInterval } from 'services/analytics/apiAnalyticsConfig.services';
import { queryMe } from 'services/clients/apiClient.services';
import { DIFFERENT_COLOR } from '../../../../../../common/defines/constants';
import { QUERY_KEY } from '../../../../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { changeIsDefaultInterval, mapViewSelector } from '../../../../../../store/slices/mapViewSlice';
import { SwitchCustom } from '../../../../../Common/SwitchCustom';
import { ICircumferenceSetting } from './ChartsAnlytic.type';
import { IntervalSelect } from './IntervalSelect';

const circumferenceModalStyle = makeStyles((theme: any) => ({
  clientSettingsCard: {
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
    background: `${theme.palette.color.white}`,
    borderRadius: '8px',
    height: 'calc(100vh - 240px)',
    border: `1px solid ${theme.palette.color.white1}`,
    width: '100%',
    overflow: 'auto',
  },
  textSetting: {
    color: theme.palette.color.grey5,
  },
  dialogTitle: {
    color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.black1,
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '24px',
  },
  cancelButton: {
    background: theme.palette.color.grey1 + ' !important',
    width: '200px',
    height: '40px',
    align: 'right',
    color: theme.palette.color.black1 + ' !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.color.grey5,
    },
  },
  saveButton: {
    background: theme.palette.color.green4 + ' !important',
    width: '200px',
    height: '40px',
    align: 'right',
    lineHeight: '1.2 !important',
    color: theme.palette.mode === 'dark' ? '#fff !important' : theme.palette.color.white + ' !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.color.grey5,
    },
  },
  resetButton: {
    background: theme.palette.neutral.main + ' !important',
    width: '100px',
    height: '30px',
    align: 'right',
    lineHeight: '1.2 !important',
    color: theme.palette.mode === 'dark' ? '#fff !important' : theme.palette.color.white + ' !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.color.grey5,
    },
  },
  formBlockItem: {
    marginBottom: '1rem',
  },
  pickupColorBtn: {
    height: '37.13px',
    width: '50px',
    borderRadius: '5px',
    border: '2px solid white',
    marginLeft: '16px',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
    },
  },
  pickupColorValueContainer: {
    height: '200px',
    width: '200px',
    zIndex: 10,
    borderRadius: '10px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
  },
  editColor: {
    position: 'absolute',
    top: '-55px',
    left: '1px',
    width: '90px !important',
  },
  pickupColorBox: {
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  textSideSurface: {
    color: theme.palette.primary.main,
  },
}));

interface IIntervalSettingModal {
  open: boolean;
  onClose: () => void;
  form: any;
  title: string;
  queryKey: string;
  getCharts: (id: string, isDefaultInterval: boolean) => Promise<AxiosResponse<any, any>>;
  changeChartDataPreview?: ActionCreatorWithPayload<any>;
}

export const IntervalSettingModal = (props: IIntervalSettingModal) => {
  const { open, onClose, getCharts, form, title, queryKey, changeChartDataPreview } = props;
  const classes = circumferenceModalStyle();
  const { control, handleSubmit, reset, setValue } = form;
  const queryClient = useQueryClient();
  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const dispatch = useAppDispatch();
  const [countDefaultInterval, setCountDefaultInterval] = useState<number>(5);
  const [intervalCount, setIntervalCount] = useState<number>(5);
  const [isDefaultIntervalState, setIsDefaultIntervalState] = useState<boolean>(isDefaultInterval);
  const { fields: intervalSettingFields } = useFieldArray({
    name: 'range',
    control,
  });

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const handleClose = () => {
    onClose();
  };

  const { data: chartsData, isLoading } = useQuery(
    [`${QUERY_KEY.CHARTS_CIRCUMFERENCE_ANALYTIC}-ho`, isDefaultIntervalState],
    () => getCharts(analyticId || '', isDefaultIntervalState),
    {
      enabled: !!analyticId,
      onSuccess: (_chartsData) => {
        if (!_chartsData) {
          reset({
            range: [],
          });
          return;
        }
        if (_chartsData.data?.intervalLimit?.range) {
          if (isDefaultIntervalState) {
            setCountDefaultInterval(_chartsData.data?.intervalLimit?.range.length);
          }
          setIntervalCount(_chartsData.data?.intervalLimit?.range.length);
          const _intervals = _chartsData.data?.intervalLimit?.range?.map((range: any) => ({ ...range, visible: true }));
          // setIntervalArrayCustom(_intervals)
          reset({
            range: _intervals,
          });
          return;
        }
        if (_chartsData.data?.intervalLimit?.max && _chartsData.data?.intervalLimit?.min) {
          const interval = (_chartsData.data?.intervalLimit?.max - _chartsData.data?.intervalLimit?.min) / 5;
          setIntervalCount(5);
          createDataIntervals(interval, 5);
          return;
        }
        reset({
          range: [],
        });
      },
    }
  );

  const handleCreateIntervals = useMutation((data: any) => createInterval(data), {
    onSuccess: (response: any) => {
      onClose();
      toast.success('Create settings custom successfully');
      queryClient.invalidateQueries([queryKey, isDefaultIntervalState]).then(() => {});
    },
  });

  const intervalId = chartsData?.data?.intervalLimit?._id;

  const handleUpdateIntervals = useMutation((data: any) => updateInterval(intervalId, data), {
    onSuccess: (response: any) => {
      onClose();
      toast.success('Change settings successfully');
      queryClient.invalidateQueries([queryKey, isDefaultIntervalState]).then(() => {});
    },
  });

  const handleSubmitForm: SubmitHandler<ICircumferenceSetting> = (data) => {
    dispatch(changeIsDefaultInterval(isDefaultIntervalState));
    if (changeChartDataPreview) {
      dispatch(changeChartDataPreview(null));
    }
    if (!intervalId && !isDefaultIntervalState) {
      return handleCreateIntervals.mutate({
        ...data,
        analysisId: analyticId,
      });
    }
    return handleUpdateIntervals.mutate(data.range);
  };

  const viewChanges: SubmitHandler<ICircumferenceSetting> = (data) => {
    if (changeChartDataPreview) {
      dispatch(changeChartDataPreview(data));
      toast.success('Load view changes successfully');
      onClose();
    }
  };

  const handleResetToDefault: SubmitHandler<ICircumferenceSetting> = (data) => {
    setIntervalCount(countDefaultInterval);
    if (chartsData && countDefaultInterval) {
      const interval =
        (chartsData.data?.intervalLimit?.max - chartsData.data?.intervalLimit?.min) / countDefaultInterval;
      createDataIntervals(interval, countDefaultInterval);
    }
  };

  const getIntervalRange = (_intervalCount: number, min: number, max: number, count: number = 5) => {
    const minTypeBigNumber = new BigNumber(min);
    return Array.from({ length: count }, (_, i) => i).map((index) => {
      const from = minTypeBigNumber
        .plus(_intervalCount * index)
        .decimalPlaces(3)
        .toNumber();
      const to = minTypeBigNumber
        .plus(_intervalCount * (index + 1))
        .decimalPlaces(3)
        .toNumber();
      return {
        from: index === 0 ? min : from,
        to: index === count - 1 ? max : to,
        visible: true,
        color: DIFFERENT_COLOR[index],
        lable: `Label custom ${index + 1}`,
      };
    });
  };

  const createDataIntervals = (_intervalCount: number, count: number = 5) => {
    if (!chartsData) {
      return;
    }
    const intervalArray = getIntervalRange(
      _intervalCount,
      chartsData.data?.intervalLimit?.min,
      chartsData.data?.intervalLimit?.max,
      count
    );
    reset({
      range: intervalArray,
    });
  };

  const onChangeInterval = (data: { value: number | undefined; isFrom: boolean; index: number }) => {
    if (!chartsData) {
      return;
    }
    const { value, isFrom, index } = data;
    if (!value) {
      return;
    }
    const min = new BigNumber(chartsData.data?.intervalLimit?.min);
    const max = new BigNumber(chartsData.data?.intervalLimit?.max);

    if (isFrom) {
      if (value > max.toNumber()) {
        toast.error('From value must be less than max value', { toastId: 'from' });
        return;
      }
      if (value <= min.toNumber()) {
        toast.error('From value must be less than max value', { toastId: 'from' });
        return;
      }
      if (value <= get(intervalSettingFields, `[${index - 1}].from`)) {
        toast.error('From value must be less than max value', { toastId: 'from' });
        return;
      }
      if (value > get(intervalSettingFields, `[${index + 1}].from`)) {
        const arrayNewLength = intervalSettingFields.length - 1 - index;
        const valueTo = new BigNumber(value);
        const intervalGeneral = getIntervalRange(
          max.minus(valueTo.toNumber()).div(arrayNewLength).toNumber(),
          valueTo.toNumber(),
          max.toNumber(),
          arrayNewLength
        );
        const intervalArray = intervalSettingFields.map((item: any, _index: number) => {
          if (_index === index - 1) {
            return {
              ...item,
              from: item.from,
              to: new BigNumber(value).toNumber(),
            };
          }
          if (_index === index) {
            return {
              ...item,
              from: value,
              to: valueTo.toNumber(),
            };
          }
          if (_index > index) {
            return {
              ...item,
              from: intervalGeneral[_index - index - 1].from,
              to: intervalGeneral[_index - index - 1].to,
            };
          }
          return item;
        });
        reset({
          range: intervalArray,
        });
        return;
      }

      const intervalArray = intervalSettingFields.map((item: any, _index: number) => {
        if (_index === index - 1) {
          return {
            ...item,
            from: item.from,
            to: new BigNumber(value).toNumber(),
          };
        }
        if (_index === index) {
          return {
            ...item,
            from: value,
            to: item.to,
          };
        }
        return item;
      });
      reset({
        range: intervalArray,
      });
    } else {
      if (value > max.toNumber()) {
        toast.error('To value must be less than max value', { toastId: 'from' });
        return;
      }
      if (value <= get(intervalSettingFields, `[${index}].from`)) {
        toast.error('To value must be less than max value', { toastId: 'from' });
        return;
      }
      if (value > get(intervalSettingFields, `[${index + 1}].from`)) {
        const arrayNewLength = intervalSettingFields.length - 1 - index;
        const intervalGeneral = getIntervalRange(
          max.minus(new BigNumber(value)).div(arrayNewLength).toNumber(),
          new BigNumber(value).toNumber(),
          max.toNumber(),
          arrayNewLength
        );
        const intervalArray = intervalSettingFields.map((item: any, _index: number) => {
          if (_index === index) {
            return {
              ...item,
              from: item.from,
              to: value,
            };
          }
          if (_index > index) {
            return {
              ...item,
              from: intervalGeneral[_index - index - 1].from,
              to: intervalGeneral[_index - index - 1].to,
            };
          }
          return item;
        });
        reset({
          range: intervalArray,
        });
        return;
      }
      const intervalArray = intervalSettingFields.map((item: any, _index: number) => {
        if (_index === index + 1) {
          return {
            ...item,
            from: new BigNumber(value).toNumber(),
            to: item.to,
          };
        }
        if (_index === index) {
          return {
            ...item,
            from: item.from,
            to: value,
          };
        }
        return item;
      });
      reset({
        range: intervalArray,
      });
    }
  };

  const dialogFormContent = useMemo(() => {
    if (isLoading) {
      return (
        <>
          <SkeletonCommon count={5} />
        </>
      );
    }
    if (isDefaultIntervalState) {
      return (
        <>
          {intervalSettingFields.map((item: any, index: any) => {
            return (
              <IntervalSelect
                key={index}
                control={control}
                item={item}
                index={index}
                max={chartsData?.data?.intervalLimit?.max || 0}
                min={chartsData?.data?.intervalLimit?.min || 0}
                length={intervalSettingFields.length}
              />
            );
          })}
        </>
      );
    } else {
      return (
        <>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              justifyContent: 'center',
              mt: 2,
              height: '40px',
            }}>
            <InputLabel sx={{ alignContent: 'center' }}>Intervals</InputLabel>
            <NumericFormat
              customInput={TextField}
              variant="outlined"
              thousandSeparator={true}
              value={intervalCount}
              onValueChange={(e) => {
                setIntervalCount(Number(e.value));
                if (chartsData && Number(e.value)) {
                  const interval =
                    (chartsData.data?.intervalLimit?.max - chartsData.data?.intervalLimit?.min) / Number(e.value);
                  createDataIntervals(interval, Number(e.value));
                }
              }}
              isAllowed={(values) => {
                const { floatValue } = values;
                if (!floatValue) {
                  return true;
                }
                return floatValue >= 1 && floatValue <= 10;
              }}
              className={'input-number'}
              autoComplete="off"
            />
          </Box>
          {intervalSettingFields.map((item: any, index: any) => {
            return (
              <IntervalSelect
                key={index}
                control={control}
                item={item}
                index={index}
                max={chartsData?.data?.intervalLimit?.max || 0}
                min={chartsData?.data?.intervalLimit?.min || 0}
                length={intervalSettingFields.length}
                onChangeInterval={onChangeInterval}
              />
            );
          })}
        </>
      );
    }
  }, [control, intervalSettingFields, isDefaultIntervalState, isLoading]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '450px',
            overflowY: 'scroll',
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundImage: (theme) => 'none',
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        },
      }}>
      <DialogTitle className={classes.dialogTitle}>{title} Settings</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          p: 0,
          position: 'absolute',
          right: 8,
          top: 8,
          '.MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
        }}>
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          padding: '0rem 2rem 1rem 2rem',
        }}>
        <Typography
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
          }}>
          <DialogContent
            sx={{
              padding: 0,
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <FormControlLabel
                sx={{ ml: 0.5 }}
                control={
                  <SwitchCustom
                    checked={isDefaultIntervalState}
                    onChange={() => setIsDefaultIntervalState(!isDefaultIntervalState)}
                  />
                }
                label={isDefaultIntervalState ? 'Default' : 'Custom'}
              />
              {!isDefaultIntervalState && (isSuperAdmin || isSuperUser) && (
                <Button type="button" className={classes.resetButton} onClick={handleSubmit(handleResetToDefault)}>
                  Reset default
                </Button>
              )}
            </Box>
            {dialogFormContent}
          </DialogContent>
          <DialogActions sx={{ mt: 2 }}>
            <Button type="button" fullWidth className={classes.cancelButton} onClick={() => onClose()}>
              Cancel
            </Button>
            <Button type="button" fullWidth className={classes.saveButton} onClick={handleSubmit(viewChanges)}>
              View changes
            </Button>
            {(isSuperAdmin || isSuperUser) && (
              <Button type="submit" fullWidth className={classes.saveButton} onClick={handleSubmit(handleSubmitForm)}>
                Save permanently
              </Button>
            )}
          </DialogActions>
        </Typography>
      </Box>
    </Dialog>
  );
};
