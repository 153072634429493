import { useContext, useEffect, useState } from 'react';

import { LevelContext } from '../ClientAssetStructure';
import MinimalTreeItemComponent from './TreeItem/TreeItem';
import { RenderTree } from 'common/defines/clients';
import { SortableTree } from 'dnd-kit-sortable-tree';
import { toast } from 'react-toastify';
import { updateSortTree } from 'services/clients/apiClient.services';
import { useMutation } from 'react-query';

type TreeProps = {
  levels: RenderTree[];
};

export const TreeViewDnd = ({ levels }: TreeProps) => {
  const { refetchLevelTree } = useContext(LevelContext);
  const [items, setItems] = useState<any>(levels);

  useEffect(() => {
    setItems(levels);
  }, [levels]);

  const mutateUpdateSort = useMutation((data: any) => updateSortTree(data), {
    onSuccess: () => {
      refetchLevelTree();
    },
  });

  const updateSort = (items: any[], parent: any) => {
    let childrenSort = [];
    if (!parent) {
      childrenSort = items.map((item: any, index: number) => ({
        levelId: item.id,
        positionNumber: index + 1,
      }));
    } else {
      const parentSelect = findParentId(items, parent?.id);
      childrenSort = parentSelect.children.map((item: any, index: number) => ({
        levelId: item.id,
        positionNumber: index + 1,
      }));
    }
    mutateUpdateSort.mutate({
      hierarchyStructurePositionNumber: childrenSort,
    });
  };

  const onItemsChanged = (items: any, reason: any) => {
    if (reason.type === 'dropped') {
      if (!reason.draggedFromParent && !reason.droppedToParent) {
        setItems(items);
        updateSort(items, reason.draggedFromParent);
        return;
      }
      if (
        (reason.draggedFromParent && !reason.droppedToParent) ||
        (!reason.draggedFromParent && reason.droppedToParent)
      ) {
        toast.error('You cannot move to another parent');
        return;
      }
      if (reason.draggedFromParent.id === reason.droppedToParent.id) {
        setItems(items);
        updateSort(items, reason.draggedFromParent);
        return;
      }
      toast.error('You cannot move to another parent');
      return;
    }
    setItems(items);
    // toast.error('You cannot move to another parent');
    // updateSort(items, reason.draggedFromParent);
  };

  return (
    <SortableTree
      indicator
      items={items as any}
      onItemsChanged={onItemsChanged}
      TreeItemComponent={MinimalTreeItemComponent}
    />
  );
};

const findParentId: any = (items: any, id: string) => {
  for (const item of items) {
    if (item.id === id) {
      return { id: item.id, children: item.children };
    }
    if (item.children) {
      const parent = findParentId(item.children, id);
      if (parent) {
        return parent;
      }
    }
  }
  return null;
};
