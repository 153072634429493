import { IRange } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAnalyticBoxPlot, updateInterval } from 'services/analytics/apiAnalyticsConfig.services';
import { getChartsCo2Sequestration } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import {
  changeChartDataCo2SequestrationPreview,
  changeDataCo2SequestrationInterval,
  co2SequestrationAnalyticsSelector,
} from '../../../../../../store/slices/map-view/co2SequestrationAnalytics';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { Co2SequestrationCardLayer } from '../../Layer/Co2Sequestration';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const Co2SequestrationAnalytics = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { chartDataCo2SequestrationPreview } = useAppSelector(co2SequestrationAnalyticsSelector);

  const { data: dataCo2SequestrationBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.CO2_SEQUESTRATION_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'co2-sequestration'),
    { enabled: !!analyticId }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY.CHARTS_CO2_SEQUESTRATION_ANALYTIC, isDefaultInterval]).then(() => {});
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_CO2_SEQUESTRATION_ANALYTIC, isDefaultInterval],
    () => getChartsCo2Sequestration(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (res) => {
        const { _id, range }: { _id: string; range: IRange[] } = res.data?.intervalLimit;
        const newRange = range.map((range, index) => {
          return {
            key: index + 1,
            color: range.color,
            lable: range.lable,
            visible: true,
            from: range.from,
            to: range.to,
          };
        });
        dispatch(changeDataCo2SequestrationInterval({ _id, isDefault: true, range: newRange }));
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataCo2SequestrationBoxPlot?.data) return [];
    return dataCo2SequestrationBoxPlot?.data;
  }, [dataCo2SequestrationBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataCo2SequestrationPreview
                      ? chartDataCo2SequestrationPreview?.chartData
                      : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION}
                  getCharts={getChartsCo2Sequestration}
                  intervalRange={
                    chartDataCo2SequestrationPreview
                      ? chartDataCo2SequestrationPreview?.intervalLimit?.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_CO2_SEQUESTRATION_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataCo2SequestrationPreview}
                  showXAxis
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<Co2SequestrationCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
