import {
  Avatar,
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { QUERY_KEY } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getListUserShare } from 'services/clients/apiClient.services';

const flexValue = [4, 2, 1];

function ListUserSharing(props: any) {
  const { onChangeValue, isReset }: any = props;
  const theme = useTheme();
  const { clientId }: any = useParams();
  const [listValue, setListValue] = useState<any>([]);
  const { t } = useTranslation();

  const styles = {
    clientListTitle: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18.2px',
      height: '57px',
    },
    clientContainerName: {
      flex: flexValue[0],
      borderBottom: 'none',
      overflowX: 'hidden',
    },
    clientAvatar: {
      width: '33px',
      height: '33px',
      marginRight: '12px',
    },
    clientName: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '16px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
    },
    clientUpdateTime: {
      flex: flexValue[1],
    },
    clientStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flex: flexValue[2],
      color: theme.palette.primary.main,
      p: '5px 0',
      backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F3FDF7',
      borderRadius: '21px',
      border: `1px solid ${theme.palette.primary.main}`,
      minHeight: '50px',
    },
    clientActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: flexValue[2],
    },
    clientActionsIcon: {
      width: '16px',
      height: '16px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#9F9F9F',
    },
    clientShowTotal: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '20px 20px',
    },
  };

  const {
    data: dataUserList,
    refetch,
    isLoading,
  }: any = useQuery([QUERY_KEY.USER_SHARING], () => getListUserShare(clientId), { keepPreviousData: true });
  useEffect(() => {
    if (dataUserList?.data || isReset) {
      const newValue: any = [];
      dataUserList.data.map((item: any) => {
        newValue.push({
          ...item,
          checked: false,
          value: item.email,
          dontSendFile: false,
        });
      });
      return setListValue(newValue);
    }
  }, [dataUserList, isReset]);

  return (
    <TableContainer sx={{ overflow: 'hidden' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            component="tr"
            sx={{
              backgroundColor: theme.palette.background.default,
              py: '10px',
            }}>
            <TableCell component="th" sx={{ ...styles.clientListTitle, minWidth: '200px' }}>
              Name
            </TableCell>

            <TableCell component="th" sx={{ ...styles.clientListTitle, textAlign: 'center' }}>
              Role
            </TableCell>
            <TableCell component="th" sx={{ ...styles.clientListTitle, textAlign: 'center' }}>
              Actions
            </TableCell>
            <TableCell component="th" sx={{ ...styles.clientListTitle, textAlign: 'center' }}>
              Attachment
            </TableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          <>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{
                    padding: '16px 0',
                  }}>
                  <WaitingPageAPI />
                </TableCell>
              </TableRow>
            </TableBody>
          </>
        ) : (
          <TableBody
            sx={{
              pt: 0,
              flexDirection: 'column',
              minHeight: '510px',
              overflowY: 'scroll',
              '-ms-overflow-style': 'none' /* IE and Edge */,
              scrollbarWidth: 'none' /* Firefox */,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
            {listValue && listValue.length > 0 ? (
              listValue.map((userDetail: any, index: number) => {
                return (
                  <TableRow
                    component="tr"
                    sx={{
                      display: 'table-row',
                      flex: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      height: '50px',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#F5F5F5',
                      },
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                    key={index}>
                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientContainerName,
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={userDetail.avatar} sx={{ ...styles.clientAvatar }} />
                        <Box>
                          <Typography
                            sx={{
                              ...styles.clientName,
                            }}>
                            {userDetail?.name}
                          </Typography>
                          <Typography
                            sx={{
                              ...styles.clientName,
                              fontSize: '14px',
                            }}>
                            {userDetail.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell
                      component="td"
                      sx={{
                        px: '16px',
                        border: 'none',
                      }}>
                      {userDetail?.role.length > 0 ? (
                        <Typography
                          component="p"
                          style={{ fontSize: '12px' }}
                          sx={{
                            ...styles.clientStatus,
                          }}>
                          {userDetail?.role.map((item: any) => item.clientId === clientId && item.name)}
                        </Typography>
                      ) : (
                        <Typography
                          component="p"
                          style={{ fontSize: '12px' }}
                          sx={{
                            textAlign: 'center',
                            // ...styles.clientStatus,
                          }}>
                          No role found
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        px: '16px',
                        border: 'none',
                      }}>
                      <Box
                        sx={{
                          ...styles.clientActions,
                          px: '16px',
                          border: 'none',
                        }}>
                        <Checkbox
                          checked={userDetail.checked}
                          onChange={(e) => {
                            const newValue = listValue.map((obj: any) => {
                              if (obj.value === userDetail.email) {
                                obj.checked = e.target.checked;
                              }
                              return obj;
                            });
                            onChangeValue(newValue);
                            setListValue(newValue);
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        px: '16px',
                        border: 'none',
                      }}>
                      <Box
                        sx={{
                          ...styles.clientActions,
                          px: '16px',
                          border: 'none',
                        }}>
                        <Checkbox
                          checked={userDetail.dontSendFile}
                          onChange={(e) => {
                            const newValue = listValue.map((obj: any) => {
                              if (obj.value === userDetail.email) {
                                obj.dontSendFile = e.target.checked;
                              }
                              return obj;
                            });
                            onChangeValue(newValue);
                            setListValue(newValue);
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Typography variant="body1" color="textSecondary">
                    {t('dashboard.no_data_found_or_no_permission')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default ListUserSharing;
