import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getBufferSizeVegetationEncroachmentAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeBufferSizeVegetationEncroachmentSelected, mapViewSelector } from 'store/slices/mapViewSlice';

export const SelectBufferSize = () => {
  const [listBufferSize, setListBufferSize] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const classes = useLayerSelectCardStyle();
  const { t } = useTranslation();

  const { analyticId, bufferSizeVegetationEncroachmentSelected } = useAppSelector(mapViewSelector);

  useQuery(
    [QUERY_KEY.BUFFER_SIZE_VEGETATION_ENCROACHMENT_ANALYTIC, analyticId],
    () => getBufferSizeVegetationEncroachmentAnalytic(analyticId || ''),
    {
      enabled: !!analyticId,
      onSuccess: (response) => {
        if (response.data) {
          setListBufferSize(response.data);
          if (!bufferSizeVegetationEncroachmentSelected) {
            dispatch(changeBufferSizeVegetationEncroachmentSelected(response.data?.[0]));
          }
        }
      },
    }
  );

  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography className={classes.selectText} sx={{ ml: '-11px' }}>
          {t('trans.buffer_size')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Select
          sx={{
            border: 'none',
            height: '37px',
            margin: '8px 0 8px -11px',
          }}
          id="grid-size-select"
          value={bufferSizeVegetationEncroachmentSelected}
          onChange={(event: any) => dispatch(changeBufferSizeVegetationEncroachmentSelected(event.target.value))}>
          {listBufferSize.map((item: number) => (
            <MenuItem key={item} value={item}>
              {`${item}m`}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </>
  );
};
