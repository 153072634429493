import { Box, Grid, Stack, useTheme } from '@mui/material';
import MapGL from '@urbica/react-map-gl';
import AppLayout from 'components/AppLayout';
import Minimap from 'components/Minimap';
import { DEFAULT_VIEWPORT } from 'constants/constants';
import React, { useCallback, useRef, useState } from 'react';
import { MAPBOX_ACCESS_TOKEN } from '../../common/dummy/dummyClients';

const ClientDashboard = () => {
  const theme = useTheme();
  const [mapStyle, setMapStyle] = useState(
    theme.palette.mode === 'dark' ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10'
  );
  // const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/light-v10');

  const [viewport, setViewport] = useState({
    ...DEFAULT_VIEWPORT,
  });

  const mapRef = useRef<any>(null);

  const onChangeStyleMap = useCallback((style: string) => {
    style && setMapStyle(style);
  }, []);

  return (
    <AppLayout>
      <Box>
        <Grid container>
          <Grid item xs={24} sm={24} md={24} lg={24} xl={24} sx={{ position: 'relative' }}>
            <MapGL
              mapStyle={mapStyle}
              accessToken={MAPBOX_ACCESS_TOKEN}
              longitude={viewport.longitude}
              latitude={viewport.latitude}
              zoom={viewport.zoom}
              style={{
                width: '100%',
                height: '90vh',
                // flexGrow: 1,
              }}
              ref={mapRef}
              onViewportChange={setViewport}
              viewportChangeMethod="flyTo"
            />
            <Stack sx={{ position: 'fixed', bottom: '5%' }}>
              <Minimap onChangeStyleMap={onChangeStyleMap} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </AppLayout>
  );
};

export default ClientDashboard;
