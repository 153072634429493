import { ComparedIndex, LayerTypeEnum } from 'common/defines/constants';
import configs from 'constants/config';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { IDateInfo } from 'store/slices/mapPopupSlice';

interface ShowOrthoImageInMapPopupProps {
  comparedIndex: ComparedIndex;
  listDateInfo: IDateInfo[];
}

const ShowOrthoImageInMapPopup: FC<ShowOrthoImageInMapPopupProps> = ({ comparedIndex, listDateInfo }) => {
  const rasterId = listDateInfo[comparedIndex - 1].raster?._id;

  return (
    <Source
      key={`raster-${rasterId}`}
      id={`raster-${rasterId}`}
      type="raster"
      tiles={[
        `${configs.API_DOMAIN}/data-analytics/getPathDataAnalysisDefault/${rasterId}/{z}/{x}/{y}.png?layerType=${LayerTypeEnum.RGB_ORTHOIMAGE}`,
      ]}>
      <Layer
        id={`layer-${rasterId}`}
        beforeId="sky"
        type="raster"
        layout={{
          visibility: 'visible',
        }}
      />
    </Source>
  );
};

export default ShowOrthoImageInMapPopup;
