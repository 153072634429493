import { Box, Tab, Tabs, useTheme } from '@mui/material';
import * as React from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeBreadScrumbsSelected } from 'store/slices/breadcrumbsSlice';

const Navbars = ['Overview', 'User Management', 'Audit Trail', 'Global Settings'];

interface ITabsBar {
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
}

export const TabsBarAdmin = ({ tab, setTab }: ITabsBar) => {
  const dispatch = useAppDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: React.SetStateAction<string>) => {
    setTab(newValue);
    dispatch(changeBreadScrumbsSelected(newValue));
  };
  const theme = useTheme();

  const styles = {
    positionsCenter: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
      p: 'auto',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },

    textStyles: {
      textTransform: 'none !important',
      fontFamily: 'Barlow !important',
      fontStyle: 'normal',
      fontWeight: '500 !important',
      fontSize: '16px !important',
      m: '0 30px !important',
      color: theme.palette.mode === 'dark' ? '#FFFFFF !important' : '#3C4043 !important',
    },
  };

  return (
    <Box
      sx={{
        ...styles.positionsCenter,
        position: 'relative',
      }}>
      <Tabs value={tab} onChange={handleChange} textColor="primary" indicatorColor="primary">
        {Navbars.map((navbar) => (
          <Tab sx={{ ...styles.textStyles }} key={navbar} label={navbar} value={navbar} />
        ))}
      </Tabs>
    </Box>
  );
};
