import { SettingUIName } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';

const PolygonLayer = ({
  item,
  isLastLevel,
  isOnTopOfOrthoimage,
  colorBorder = '#1EBADB',
}: {
  item: any;
  isLastLevel?: boolean;
  isOnTopOfOrthoimage?: boolean;
  colorBorder?: string;
} & Record<string, unknown>) => {
  const { clientId } = useParams();

  const { data: clientData } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const widthSetting = useMemo(() => {
    if (!clientData?.uiSetting) return 1.4;
    const strokeSetting = clientData.uiSetting.find((val: any) => val.name === SettingUIName.STROKE_WIDTH);
    return strokeSetting?.value || 1.4;
  }, [clientData]);

  const hasFillLayer = isOnTopOfOrthoimage ? false : !isLastLevel;

  let key = `${item._id}`;
  if (isLastLevel) {
    key += '_isLast';
  }
  if (isOnTopOfOrthoimage) {
    key += '_isOnTopOfOrthoimage';
  }

  return (
    <Source id={`${key}_source_polygon`} key={`${key}_source_polygon`} type="geojson" data={item.location}>
      <Layer
        id={`${key}_border_polygon`}
        key={`${key}_border_polygon`}
        type="line"
        source={`${key}_source_polygon`}
        paint={{
          'line-color': colorBorder,
          'line-opacity': 0.8,
          'line-width': widthSetting,
        }}
      />
      {hasFillLayer && (
        <Layer
          id={`${key}_layer_polygon`}
          key={`${key}_layer_polygon`}
          type="fill"
          source={`${key}_source_polygon`}
          beforeId={`${key}_border_polygon`}
          paint={{
            'fill-color': colorBorder,
            'fill-opacity': 0.1,
          }}
        />
      )}
    </Source>
  );
};

export default PolygonLayer;
