import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography, useTheme } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import useQueryVectorContourIntelligent from 'hooks/workspace/useQueryVectorContourIntelligent';
import { ContourUnitEnum } from 'interfaces/workspace';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { generateVectorContour } from 'services/workspaces';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

interface IntervalItem {
  key: number;
  value: string;
  color: string;
}

const unitOptions = [{ key: 1, value: ContourUnitEnum.METERS, label: ContourUnitEnum.METERS }];

interface ContourGenerationSettingModalProps {
  handleClose: () => void;
}

const ContourGenerationSettingModal: FC<ContourGenerationSettingModalProps> = ({ handleClose }) => {
  const [unit, setUnit] = useState<ContourUnitEnum>(ContourUnitEnum.METERS);
  const [listInterval, setListInterval] = useState<IntervalItem[]>([]);
  const { selectedTaskId } = useAppSelector(tilingMapSelector);
  const mapViewStylesClasses = useMapViewStyle();
  const theme = useTheme();

  const { vectorIntervalIntelligent } = useQueryVectorContourIntelligent();

  useEffect(() => {
    if (vectorIntervalIntelligent?.length) {
      setListInterval(
        vectorIntervalIntelligent.map(({ color, size }, index) => ({ key: index + 1, color, value: size }))
      );
    }
  }, [vectorIntervalIntelligent]);

  const saveIntervalMutation = useMutation(generateVectorContour, {
    onSuccess() {
      toast.success('Save interval contour successful');
      handleClose();
    },
    onError() {
      toast.error('Generate interval contour failed');
    },
  });

  const handleChangeUnit = (e: SelectChangeEvent) => {
    setUnit(e.target.value as ContourUnitEnum);
  };

  const handleAddInterval = () => {
    const listIntervalLength = listInterval.length;
    if (listIntervalLength >= 3) return;
    const newValue = ((listIntervalLength + 1) * 5).toString();
    const newInterval = { key: listIntervalLength + 1, value: newValue, color: '' };
    setListInterval([...listInterval, newInterval]);
  };

  const handleDeleteInterval = (intervalItem: IntervalItem) => {
    const newListInterval = listInterval
      .filter((item) => item.key !== intervalItem.key)
      .map((item, index) => ({ ...item, key: index + 1 }));
    setListInterval(newListInterval);
  };

  const handleChangeFieldInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, item: IntervalItem) => {
    const value = e.target.value;
    const newListInterval = listInterval.map((ele) => (ele.key === item.key ? { ...ele, value: value } : ele));
    setListInterval(newListInterval);
  };

  const handleSaveInterval = () => {
    saveIntervalMutation.mutate({
      unit,
      config: listInterval.map(({ value, color }) => ({ color, size: value })),
      workspaceRasterId: selectedTaskId,
    });
  };

  return (
    <CommonModal style={{ width: '360px', py: '16px', px: '32px' }} isShow handleClose={handleClose}>
      <Typography className={mapViewStylesClasses.titleInput} sx={{ mb: '24px', fontSize: '18px' }}>
        Interval between contour lines
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '38px',
            width: '38px',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.divider}`,
            color: theme.palette.primary.main,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={handleAddInterval}>
          <AddIcon />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ml: '48px',
          }}>
          <Typography fontSize="16px">Unit</Typography>
          <Select
            labelId="unit_select_label"
            id="unit_select"
            size="small"
            value={unit}
            onChange={(e) => handleChangeUnit(e)}
            sx={{ ml: '8px' }}>
            {unitOptions.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Stack sx={{ mt: '32px', gap: '8px' }}>
        {listInterval.map((item) => (
          <Box key={item.key} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '16px', mr: '16px', textWrap: 'nowrap', width: '70px' }}>
              Interval {item.key}:
            </Typography>
            <TextField
              size="small"
              type="number"
              fullWidth
              sx={{ mr: '8px', width: '180px' }}
              value={item.value}
              onChange={(e) => handleChangeFieldInput(e, item)}
            />
            {item.key !== 1 && (
              <RemoveCircleOutlineOutlinedIcon
                color="error"
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => handleDeleteInterval(item)}
              />
            )}
          </Box>
        ))}
      </Stack>
      <Box sx={{ textAlign: 'right', mt: '32px' }}>
        <LoadingButton
          variant="contained"
          size="small"
          onClick={handleSaveInterval}
          loading={saveIntervalMutation.isLoading}>
          Save
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};

export default ContourGenerationSettingModal;
