import { yupResolver } from '@hookform/resolvers/yup';
import { PersonOutline } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { DashboardIcon } from 'assets/icons';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendVerifyEmail } from 'services/clients/apiClient.services';
import * as yup from 'yup';
import { useAddEmailStyle } from './AddEmailStyle';

const styles = {
  formStyles: {
    width: '310px',
    m: 'auto',
    fontFamily: 'Barlow',
  },
  labelStyles: {
    textAlign: 'left',
    fontSize: '18px',
    height: '24px',
  },
  fontStyles: {
    fontFamily: 'Barlow',
  },
};
interface IVerifyEmailForm {
  setIsSendEmail: (data: boolean) => void;
  setEmail: (email: string) => void;
}
const VerifyEmailValidationSchema = yup
  .object({
    email: yup.string().email('Invalid email format').required('Email is required'),
  })
  .required();

const AddEmailPage = ({ setIsSendEmail, setEmail }: IVerifyEmailForm) => {
  const navigate = useNavigate();
  const classes = useAddEmailStyle();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      email: '',
      id: JSON.parse(localStorage.getItem('account') || '{}')._id,
    },
    resolver: yupResolver(VerifyEmailValidationSchema),
  });

  const handleVerifyEmail = useMutation((dataUser: string) => sendVerifyEmail(dataUser), {
    onSuccess: () => {
      setIsSendEmail(true);
    },
    onError: (error) => {
      toast.error('Email does not exist!', { toastId: 1 });
    },
  });
  const id = JSON.parse(localStorage.getItem('account') || '{}')._id;
  const handleSubmitForm = handleSubmit((data) => {
    setEmail(data);
    handleVerifyEmail.mutate(data, id);
  });

  const handleChangeField = (e: any, fieldName: any) => {
    setValue(fieldName, e.target.value.trim());
  };
  return (
    <Typography
      component="div"
      sx={{
        height: '100vh',
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1F1F1F' : '#f9f9f9'),
        display: ' flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Stack
        direction="row"
        sx={{
          height: 'fit-content',
          maxWidth: '1130px',
          m: 'auto',
          borderRadius: '10px',
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#3C4043' : '#fff'),
          py: 5,
        }}>
        <Box
          alignItems="center"
          className={classes.baseStyles}
          sx={{
            width: '500px',
          }}>
          <Typography
            component="div"
            sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <DashboardIcon />
            <Typography sx={{ px: 2 }}> Agrimor</Typography>
          </Typography>
          <Typography
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '310px',
              ...styles.formStyles,
            }}
            onSubmit={handleSubmit(handleSubmitForm)}>
            <Typography
              component="div"
              sx={{
                mt: '52px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                fontSize: '24px',
                fontWeight: '600',
              }}>
              {'You need add your email! '}
            </Typography>
            <Typography
              component="div"
              sx={{
                mt: 1,
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                fontSize: '18px',
                fontWeight: '400',
              }}>
              {' Enter your email address and we’ll send you a link to verify'}
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <>
                  <Typography
                    component="div"
                    sx={{
                      mt: '30px',
                      ...styles.labelStyles,
                      ...styles.fontStyles,
                    }}>
                    Email
                  </Typography>
                  <TextField
                    type="email"
                    placeholder="someone@gmail.com"
                    sx={{
                      mt: '4px',
                      ...styles.fontStyles,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutline
                            sx={{
                              height: '24px',
                              width: '24px',
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    onChange={(e) => handleChangeField(e, 'email')}
                  />
                  {errors.email && (
                    <Typography
                      component="div"
                      className="errorMsg"
                      sx={{
                        fontFamily: 'Barlow',
                        fontSize: '14px',
                        lineHeight: '12px',
                        color: '#FF4646',
                        textAlign: 'left',
                        my: '10px',
                      }}>
                      {errors.email.message?.toString()}
                    </Typography>
                  )}
                </>
              )}
            />
            <Stack sx={{ marginTop: '100px' }}>
              <LoadingButton
                type="submit"
                sx={{
                  textTransform: 'none',
                  '.MuiLoadingButton-loadingIndicator': {
                    color: '#ffffff',
                  },
                }}
                className={classes.resetBtn}
                loading={handleVerifyEmail.isLoading}
                data-testid="login-btn">
                Send Email
              </LoadingButton>
              <Button
                onClick={() => {
                  const theme = localStorage.getItem('theme');
                  localStorage.clear();
                  localStorage.setItem('theme', theme || 'light');
                  navigate('/login');
                }}
                sx={{
                  textTransform: 'none',
                }}
                className={classes.returnLogin}
                data-testid="login-btn">
                Return to Login
              </Button>
            </Stack>
          </Typography>
        </Box>
      </Stack>
    </Typography>
  );
};
export default AddEmailPage;
