import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAnalyticBoxPlot,
  getAnalyticPolygon,
  getDataPlantStressAnalytic,
  updateInterval,
} from 'services/analytics/apiAnalyticsConfig.services';
import { getChartsStress } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeChartDataPlantStressPreview,
  changeDataPlantStressAnalytics,
  changeDataPlantStressInterval,
  changeDataPolygonStressAnalytics,
} from 'store/slices/map-view/plantStressAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import { plantStressAnalyticsSelector } from '../../../../../../store/slices/map-view/plantStressAnalytics';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { PlantStressLayer } from '../../Layer/PlantStress';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const PlantStressAnalytics = ({ uploadDate }: any) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { dataPlantStressInterval, dataPlantStressPolygon, chartDataPlantStressPreview } =
    useAppSelector(plantStressAnalyticsSelector);

  const { data: dataChlorophyllBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.STRESS_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'stress'),
    { enabled: !!analyticId }
  );

  const [currentPage, setCurrentPage] = useState(1);

  useQuery(
    [QUERY_KEY.STRESS_POLYGON, analyticId, currentPage],
    () => getAnalyticPolygon(analyticId || '', 'stress', currentPage),
    {
      enabled: !!analyticId,
      onSuccess: (data: any) => {
        if (data.data?.nextPage && data.data.nextPage !== currentPage) {
          setCurrentPage(data.data?.nextPage);
        }
        if (data.data?.docs) {
          dispatch(changeDataPolygonStressAnalytics([...dataPlantStressPolygon, ...data.data?.docs]));
        }
      },
    }
  );

  useQuery(
    [QUERY_KEY.PLANT_STRESS_ANALYTIC, analyticId],
    () => {
      return getDataPlantStressAnalytic(analyticId || '');
    },
    {
      enabled: !!analyticId,
      onSuccess: (mainData: any) => {
        dispatch(changeDataPlantStressAnalytics(mainData?.data || []));
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_STRESS_ANALYTIC, isDefaultInterval],
    () => getChartsStress(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (data) => {
        dispatch(
          changeDataPlantStressInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataPlantStressInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY.CHARTS_STRESS_ANALYTIC, isDefaultInterval]).then(() => {});
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataChlorophyllBoxPlot?.data) return [];
    return dataChlorophyllBoxPlot.data;
  }, [dataChlorophyllBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataPlantStressPreview
                      ? chartDataPlantStressPreview.chartData
                      : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE}
                  getCharts={getChartsStress}
                  intervalRange={
                    chartDataPlantStressPreview
                      ? chartDataPlantStressPreview.intervalLimit.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_STRESS_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataPlantStressPreview}
                  showXAxis
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<PlantStressLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
