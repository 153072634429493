import { circle, Units } from '@turf/turf';
import { IPaginationData, ITreeHeightData } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { sizeByZoomLevel } from 'common/utils/util';
import { QUERY_KEY } from 'constants/constants';
import { useGetClientSettingMapView } from 'hooks/map-view/useGetClientSettingMapView';
import { useClientData } from 'hooks/useClientData';
import { useEffect, useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getDataTreeHeightAnalyticPagination } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppSelector } from 'store/hooks';
import { treeHeightAnalyticsSelector } from 'store/slices/map-view/treeHeightAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';

export const useTreeHeightAnalytics = ({ mapRef }: any) => {
  const [treeHeightData, setTreeHeightData] = useState<ITreeHeightData[]>([]);
  const [page, setPage] = useState<number>(1);

  const {
    isLayer3D,
    isInEditTab,
    analyticId,
    mapViewState: { zoom },
  } = useAppSelector(mapViewSelector);
  const { analyticName } = useAppSelector(rightBarSelector);
  const { dataTreeHeightInterval } = useAppSelector(treeHeightAnalyticsSelector);
  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, getOpacityExtrusion } =
    useGetClientSettingMapView();
  const { contourSetting } = useClientData();
  // const { isFixedPoint, value } = useClientDataConfig();

  useQuery(
    [QUERY_KEY.TREE_HEIGHT_ANALYSIS, analyticId, page],
    () => getDataTreeHeightAnalyticPagination({ analysisId: analyticId || '', page }),
    {
      enabled: !!analyticId && analyticName === TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT,
      onSuccess(res) {
        const resData = res.data as IPaginationData;
        const hasNextPage = resData.hasNextPage;
        if (page === 1) {
          setTreeHeightData([...resData.docs]);
        } else {
          setTreeHeightData([...treeHeightData, ...resData.docs]);
        }
        if (hasNextPage) setPage(page + 1);
      },
    }
  );

  useEffect(() => {
    setTreeHeightData([]);
    setPage(1);
  }, [analyticId]);

  const ranges = dataTreeHeightInterval.range;

  const geoJsonData: GeoJSON.FeatureCollection = useMemo(() => {
    const evaluateColorValue = (item: ITreeHeightData) => {
      for (let range of ranges) {
        if (item.height >= range.from && item.height < range.to) {
          return range.visible ? range.color : 'transparent';
        }
      }
    };

    const featuresWithCirclePoint: GeoJSON.Feature[] = treeHeightData?.map((item) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.longX, item.latY],
      },
      properties: { color: evaluateColorValue(item) ?? 'transparent', ...item },
    }));

    const generateGeometryWithCircle = (item: ITreeHeightData) => {
      const center = [item.longX, item.latY];
      const radius = sizeByZoomLevel(zoom) * 1000;
      const options = {
        units: 'meters' as Units,
        properties: { color: evaluateColorValue(item) ?? 'transparent', ...item },
      };
      const circleGeometry = circle(center, radius, options);
      return circleGeometry;
    };

    return {
      type: 'FeatureCollection',
      features: isLayer3D
        ? treeHeightData?.map((item) => generateGeometryWithCircle(item))
        : (featuresWithCirclePoint as GeoJSON.Feature[]),
    };
  }, [isLayer3D, ranges, treeHeightData, zoom]);

  const layerTreeHeight = useMemo(() => {
    if (!ranges?.length) {
      return null;
    }
    return (
      geoJsonData &&
      !isInEditTab && (
        <Source id={`treeHeight-point`} type="geojson" data={geoJsonData}>
          {/* 2D Point */}
          <Layer
            id={`fill-top-surface-point`}
            type="circle"
            source={`treeHeight-circle-point`}
            paint={{
              'circle-color': ['get', 'color'],
              'circle-radius': 5,
            }}
            layout={{ visibility: !isLayer3D ? 'visible' : 'none' }}
          />
          {/* 3D */}
          <Layer
            id={`treeHeight-top-surface-point`}
            type="fill-extrusion"
            source={`treeHeight-circle-point`}
            filter={['!=', 'color', 'transparent']}
            paint={{
              'fill-extrusion-color': ['get', 'color'],
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': contourSetting,
              'fill-extrusion-opacity': getTopSurfaceOpacity,
              'fill-extrusion-vertical-gradient': false,
            }}
            layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
          />
          <Layer
            id={`treeHeight-side-surface-point`}
            type="fill-extrusion"
            source={`treeHeight-circle-point`}
            filter={['!=', 'color', 'transparent']}
            paint={{
              'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': 0,
              'fill-extrusion-opacity': getOpacityExtrusion,
              // getOpacityExtrusion,
              'fill-extrusion-vertical-gradient': false,
            }}
            layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
          />
        </Source>
      )
    );
  }, [
    colorTransparent,
    contourSetting,
    geoJsonData,
    getOpacityExtrusion,
    getTopSurfaceOpacity,
    isInEditTab,
    isLayer3D,
    isSameColorTopSurface,
    ranges?.length,
  ]);
  return { layerTreeHeight };
};
