import { QUERY_KEY } from 'constants/constants';
import { IWorkspace } from 'interfaces/workspace';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getWorkspaceInfo } from 'services/workspaces';

const useQueryWorkspaceInfo = () => {
  const { workspaceId } = useParams();
  const [workspaceData, setWorkspaceData] = useState<IWorkspace>();

  useQuery([QUERY_KEY.GET_WORKSPACE_INFO, workspaceId], () => getWorkspaceInfo(workspaceId!), {
    onSuccess(res) {
      setWorkspaceData(res.data);
    },
    enabled: !!workspaceId,
  });

  return { workspaceData };
};

export default useQueryWorkspaceInfo;
