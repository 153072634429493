import { Box, FormControlLabel, InputLabel, Typography } from '@mui/material';
import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { changeSizeStressFieldList, mapViewSelector, setDataStressField } from 'store/slices/mapViewSlice';
import { get, isEmpty } from 'lodash';
import { getSizeStressFieldAnalytic, getStressFieldAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMemo, useState } from 'react';

import InfoTable from '../CropCover/InfoTable';
import PieChartCommon from 'components/Common/PieChartCommon';
import { QUERY_KEY } from 'constants/constants';
import SkeletonCharts from 'components/Common/SkeletonCharts';
import StressFieldCardLayer from '../../Layer/StressField';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import randomColor from 'randomcolor';
import { useClientSettingsStyle } from 'components/Dashboard/ClientSettings/ClientSettingsStyle';
import { useQuery } from 'react-query';

const StressFieldAnalytics = () => {
  const dispatch = useAppDispatch();
  const [chartMode, setChartMode] = useState(false);
  const classes = useClientSettingsStyle();

  const { analyticId, sizeStressFieldSelected, dataStressField, sizeStressFieldList } = useAppSelector(mapViewSelector);

  useQuery([QUERY_KEY.STRESS_FIELD_ANALYTIC, analyticId], () => getSizeStressFieldAnalytic(analyticId || ''), {
    enabled: !!analyticId && isEmpty(sizeStressFieldList),
    onSuccess: (response) => {
      if (response.data) {
        dispatch(changeSizeStressFieldList(response.data));
      }
    },
  });

  const { data: dataCropType } = useQuery(
    [QUERY_KEY.SIZE_STRESS_FIELD_ANALYTIC, analyticId, sizeStressFieldSelected],
    () => getStressFieldAnalytic(analyticId || '', sizeStressFieldSelected + ''),
    { enabled: !!analyticId && !!sizeStressFieldSelected }
  );

  const dataPie = useMemo(() => {
    if (dataStressField) {
      return dataStressField.map((_item: any) => ({
        name: _item.type,
        value: _item.lduseArea,
        totalTree: _item.totalTree,
      }));
    }
    return [];
  }, [dataStressField]);

  const getDataChart = useMemo(() => dataStressField?.map((_item: any) => ({
    name: _item.type,
    value: chartMode ? _item.totalTree : _item.lduseArea,
  })), [chartMode, dataStressField]);

  const hasTreeCount = !!(dataStressField?.length && dataStressField.some((item: any) => item.totalTree > 0))

  const colorStressField = useMemo(() => {
    if (dataCropType?.data) {
      let colors: string[] = [];
      dispatch(
        setDataStressField(
          dataCropType.data.map((item: any, index: number) => {
            const colorRandom = randomColor({ luminosity: 'dark' });
            const _color = item.color || get(DIFFERENT_COLOR, `[${index}]`, colorRandom);
            colors.push(_color);
            return {
              ...item,
              visible: true,
              color: _color,
            };
          })
        )
      );
      return colors;
    }
    return [];
  }, [dataCropType, dispatch]);

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD}
      analyticTab={
        <>
          {dataStressField?.length > 0 ? (
            <>
              <PieChartCommon title="Stress Field" dataChart={getDataChart} colorCharts={colorStressField} />
              {hasTreeCount && (
                <>
                  <InputLabel sx={{ marginLeft: '25px', fontSize: '14px' }}>Chart Mode:</InputLabel>
                  <FormControlLabel
                    sx={{ marginLeft: '30px' }}
                    control={
                      <SwitchCustom
                        checked={chartMode}
                        onClick={() => {
                          setChartMode(!chartMode);
                        }}
                      />
                    }
                    label={<Typography className={classes.textSetting}>{chartMode ? 'Tree Count' : 'Area'}</Typography>}
                  />
                </>
              )}
              <Box component={'div'} sx={{ marginTop: '30px' }}>
                <InfoTable dataPie={dataPie} hasTreeCount={hasTreeCount} />
              </Box>
            </>
          ) : (
            <>
              <SkeletonCharts count={1} />
            </>
          )}
        </>
      }
      layerTab={<StressFieldCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD} />}
      isShowDownloadButton={dataStressField?.length > 0}
    />
  );
};

export default StressFieldAnalytics;
