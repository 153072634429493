import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { ICreateClientSettings } from 'common/defines/clients';
import { defaultClientSettings } from 'common/dummy/dummyClients';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updateClientSettingsById } from 'services/clients/apiClient.services';
import { useClientSettingsStyle } from './ClientSettingsStyle';

const ConfigSizeModal = (props: any) => {
  const { open, onClose, refetch, clientData, clientId, settingId, sizeOfPointSetting } = props;
  const classes = useClientSettingsStyle();

  const { t } = useTranslation();

  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    defaultValues: defaultClientSettings,

    // resolver: yupResolver(sizePointSchema),
  });

  // useEffect(() => {
  //     setValue('isFixedSizePoint', SizeOfPointSetting?.isFixedPoint)
  // }, [isFixedSize, setValue])

  const handleClose = () => {
    onClose();
  };

  const handleUpdateSizeConfig = useMutation(
    (data: ICreateClientSettings) => updateClientSettingsById(settingId || '', data),
    {
      onSuccess: () => {
        onClose();
        refetch();
        toast.success('Change settings successfully');
      },
    }
  );

  const handleSubmitForm: SubmitHandler<ICreateClientSettings> = (data) => {
    const dataBody = data.sizeOfPointSetting?.value;
    handleUpdateSizeConfig.mutate({
      ...data,
      clientId,
      sizeOfPointSetting: {
        isFixedPoint: data.sizeOfPointSetting?.isFixedPoint,
        value: data.sizeOfPointSetting?.isFixedPoint ? data.sizeOfPointSetting?.value || 0 : null,
      },
    });
  };

  const handleChangeSizePoint = useCallback(
    (e: any) => {
      setValue('sizeOfPointSetting.value', e.target.value);
    },
    [setValue]
  );

  const isFixedSizePoint = useMemo(() => {
    return getValues('sizeOfPointSetting.isFixedPoint');
  }, [getValues, watch('sizeOfPointSetting.isFixedPoint')]);

  useEffect(() => {
    if (sizeOfPointSetting?.isFixedPoint) {
      setValue('sizeOfPointSetting.value', sizeOfPointSetting?.value);
    }
    setValue('sizeOfPointSetting.isFixedPoint', sizeOfPointSetting?.isFixedPoint);
  }, [setValue, sizeOfPointSetting]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            overflowY: 'scroll',
            backgroundColor: (theme) => theme.palette.background.paper,
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        },
      }}>
      <DialogTitle className={classes.dialogTitle}>{t('trans.client_settings_edit')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          p: 0,
          position: 'absolute',
          right: 8,
          top: 8,
          '.MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
        }}>
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          padding: '1rem 2rem',
        }}>
        <DialogContent
          sx={{
            padding: 0,
          }}>
          <Typography
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
            }}
            onSubmit={handleSubmit(handleSubmitForm)}>
            <DialogContent
              sx={{
                padding: 0,
              }}>
              <Grid container>
                <Grid item sx={{ overflow: 'hidden' }} xs={12}>
                  <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}>
                      <Typography
                        sx={{
                          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#737373'),
                        }}>
                        {t('trans.fixed_size_point_config')}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}>
                        <Controller
                          name="sizeOfPointSetting.isFixedPoint"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Switch checked={field.value} onChange={(e: any) => field.onChange(e.target.checked)} />
                            );
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                {isFixedSizePoint ? (
                  <Grid item xs={12}>
                    <Controller
                      name="sizeOfPointSetting.value"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            flex: 1,
                          }}>
                          <InputLabel>{t('trans.size_of_point')} (m)</InputLabel>
                          <TextField
                            type="number"
                            size="small"
                            fullWidth
                            value={field.value}
                            onChange={(e: any) => handleChangeSizePoint(e)}
                          />
                        </Box>
                      )}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button type="submit" fullWidth className={classes.saveButton}>
                {t('trans.save')}
              </Button>
            </DialogActions>
          </Typography>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ConfigSizeModal;
