import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { ITask } from 'interfaces/workspace';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { deleteStreamlineOrderIntelligent, generateStreamlineOrderIntelligent } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeAllStreamlineOrderDisplay, rightBarSelector } from 'store/slices/rightBarSlice';
import {
  changeIsClickedGenerateStreamlineOrder,
  changeIsGeneratingStreamlineOrder,
  tilingMapSelector,
} from 'store/slices/tilingMapSlice';
import CustomBox from './CustomBox';

interface StreamlineOrderIntelligentProps {
  selectedTask: ITask;
}

const StreamlineOrderIntelligent: FC<StreamlineOrderIntelligentProps> = ({ selectedTask }) => {
  const queryClient = useQueryClient();
  const {
    rightBar: { isClickedGenerateStreamlineOrder },
    selectedTaskId,
  } = useAppSelector(tilingMapSelector);
  const { streamlineOrderDisplay } = useAppSelector(rightBarSelector);

  const existDtm = !!selectedTask.dtmGeoTIFFPath;
  const existStreamlineOrder = !!streamlineOrderDisplay.length;
  const isDisabledButton = !existDtm || existStreamlineOrder || isClickedGenerateStreamlineOrder;

  const renderTooltipTitle = isDisabledButton && !existDtm ? 'To generate Streamline, please upload the DTM file' : '';

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const generateStreamlineOrderMutation = useMutation(generateStreamlineOrderIntelligent, {
    onMutate() {
      toast.success('Start generating streamline order');
      dispatch(changeAllStreamlineOrderDisplay([]));
      dispatch(changeIsClickedGenerateStreamlineOrder(true));
      dispatch(changeIsGeneratingStreamlineOrder(true));
    },
    onSuccess() {
      toast.success('Generate streamline order successful');
      dispatch(changeIsGeneratingStreamlineOrder(false));
      queryClient.invalidateQueries([QUERY_KEY.GET_STREAMLINE_ORDER_INTELLIGENT_LIST]);
    },
    onError() {
      toast.error('Generate streamline order failed');
      dispatch(changeIsGeneratingStreamlineOrder(false));
      dispatch(changeIsClickedGenerateStreamlineOrder(false));
    },
  });

  const deleteStreamlineOrderMutation = useMutation(deleteStreamlineOrderIntelligent, {
    onSuccess() {
      queryClient.invalidateQueries([QUERY_KEY.GET_STREAMLINE_ORDER_INTELLIGENT_LIST]);
      dispatch(changeIsClickedGenerateStreamlineOrder(false));
      dispatch(changeAllStreamlineOrderDisplay([]));
      toast.success('Remove streamline order successful');
    },
  });

  return (
    <CustomBox>
      <Typography fontSize="16px">Streamline</Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gap="8px">
        <Tooltip title={renderTooltipTitle}>
          <span>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                generateStreamlineOrderMutation.mutate(selectedTaskId);
              }}
              disabled={isDisabledButton}>
              Start
            </Button>
          </span>
        </Tooltip>

        <DeleteForeverOutlinedIcon
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: theme.palette.error.main,
            },
          }}
          onClick={() => deleteStreamlineOrderMutation.mutate(selectedTaskId)}
        />
      </Box>
    </CustomBox>
  );
};

export default StreamlineOrderIntelligent;
