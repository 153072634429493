import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Tooltip, useTheme } from '@mui/material';
import { CreateAndEditMode } from 'interfaces/workspace';
import { FC, MouseEvent } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeCreateAndEditFieldModal } from 'store/slices/tilingMapSlice';

interface FieldEditDetailProps {
  isIconActive: boolean;
}

const FieldEditDetail: FC<FieldEditDetailProps> = ({ isIconActive }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleEditField = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isIconActive) {
      dispatch(changeCreateAndEditFieldModal({ isShow: true, mode: CreateAndEditMode.TASK }))
    }
  };

  return (
    <Box
      sx={{
        '&:hover': {
          color: isIconActive ? theme.palette.primary.main : '',
        },
      }}
      onClick={handleEditField}>
      <Tooltip title="Edit Field">
        <ModeEditOutlineOutlinedIcon aria-disabled={!isIconActive} sx={{ opacity: isIconActive ? 1 : 0.5 }} />
      </Tooltip>
    </Box>
  );
};

export default FieldEditDetail;
