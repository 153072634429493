import { IVegetationEncroachmentData } from '../VegetationEncroachment.types';

export const combineVegetationEncroachmentData = (resData: IVegetationEncroachmentData[]) => {
  const groupByType = resData?.reduce((acc: any, current) => {
    const { _id, buffer, bufferSize, type, lduseArea, color, size, totalTree, geometry } = current;
    if (!acc[type]) {
      acc[type] = {
        _id,
        lduseArea: 0,
        type,
        color,
        size,
        buffer,
        bufferSize,
        geometry,
        totalTree: 0,
      };
    }
    acc[type]._id = _id;
    acc[type].color = color;
    acc[type].lduseArea += lduseArea;
    acc[type].totalTree += totalTree;
    acc[type].buffer = Math.max(acc[type].buffer, buffer);
    acc[type].bufferSize = Math.max(acc[type].bufferSize, bufferSize);
    acc[type].geometry = {
      type: 'MultiPolygon',
      coordinates: [...acc[type].geometry?.coordinates, ...geometry?.coordinates],
    };
    return acc;
  }, {});

  return Object.values(groupByType || {});
};
