import PieChartIcon from '@mui/icons-material/PieChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ICreateClientAdminFormData, IUpdateViewS3Storage, ViewS3Storage } from 'common/defines/clients';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { QUERY_KEY } from 'constants/constants';
import ReactECharts from 'echarts-for-react';
import { isNil } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getClientListByPagination,
  getClientUserAdminByIdAxios,
  updateClientUserAdminByIdAxios,
} from 'services/clients/apiClient.services';
import S3UsageTable from './S3UsageTable';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
  borderRadius: '8px',
}));

export interface IS3Usage {
  userId: string;
}

const S3Usage: FC<IS3Usage> = ({ userId }) => {
  const [clientDataList, setClientDataList] = useState<any[]>([]);
  const [isChartMode, setIsChartMode] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const theme = useTheme();

  const { isLoading } = useQuery([QUERY_KEY.CLIENT_LIST_USAGE, page], () => getClientListByPagination(page, 30, ''), {
    onSuccess(res) {
      const resData: any[] = res?.data?.data;
      const metadata = res?.data?.metadata;
      setClientDataList(resData);
      if (metadata?.nextPage) {
        setPage(page + 1);
      }
      setClientDataList([...clientDataList, ...resData]);
    },
  });

  const dataChart = useMemo(() => {
    return clientDataList.map((item) => ({
      name: item?.name,
      value: item?.s3Usage,
    }));
  }, [clientDataList]);

  const isDataChartUnavailable = dataChart?.every((item) => isNil(item.value));

  useQuery([QUERY_KEY.USER_DETAILS, userId], () => getClientUserAdminByIdAxios(userId), {
    keepPreviousData: false,
    enabled: !!userId,
    onSuccess: (res: any) => {
      if (!res.data.viewS3Storage) {
        handleUpdateUser.mutate({
          id: userId,
          viewS3Storage: ViewS3Storage.CHART,
        });
      } else {
        setIsChartMode(res?.data?.viewS3Storage === ViewS3Storage.CHART);
      }
    },
  });

  useEffect(() => {
    return () => {
      setClientDataList([]);
    };
  }, []);

  const handleUpdateUser = useMutation(
    (data: IUpdateViewS3Storage) => updateClientUserAdminByIdAxios(data as ICreateClientAdminFormData),
    {
      onError: (err: any) => {
        console.log(err);
      },
    }
  );

  const changeChartMode = () => {
    setIsChartMode(!isChartMode);
    handleUpdateUser.mutate({
      id: userId,
      viewS3Storage: !isChartMode ? ViewS3Storage.CHART : ViewS3Storage.TABLE,
    });
  };

  const option = {
    title: {
      text: 'S3 Storage Utilization',
      left: 'center',
    },
    backgroundColor: theme.palette.background.paper,
    tooltip: {
      trigger: 'item',
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 10,
      top: 20,
      bottom: 20,
      data: dataChart,
    },
    series: [
      {
        name: 'Utilization Total (GB)',
        type: 'pie',
        radius: ['40%', '70%'],
        right: 300,
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: theme.palette.background.paper,
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: dataChart,
      },
    ],
  };

  const heightChart = useMemo(() => Math.max(200 + dataChart?.length * 8, 300), [dataChart]);

  const renderUnavailableMessage = (
    <Box
      sx={{
        textAlign: 'center',
      }}>
      <Typography fontSize="20px" fontWeight={700} mb="16px">
        S3 Storage Utilization
      </Typography>
      <Typography>Detailed data will be available on the next working day</Typography>
    </Box>
  );

  const renderChart = (
    <>
      {isLoading && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <WaitingPageAPI />
        </Grid>
      )}
      {!isLoading && (
        <>
          <Grid
            sx={{
              position: 'absolute',
              right: '4px',
              top: '4px',
            }}>
            <Box>
              <Tooltip title={isChartMode ? 'Table view' : 'Pie chart view'}>
                <IconButton
                  sx={{
                    padding: 0,
                    textTransform: 'none',
                    fontFamily: 'Barlow',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#A8AEB1',
                    width: '24px',
                    height: '24px',
                    marginLeft: '14px',
                    boxShadow: '0px 2px 10px rgba(97, 97, 97, 0.1)',
                  }}
                  onClick={changeChartMode}>
                  {isChartMode ? (
                    <TableChartIcon
                      sx={{
                        color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#A8AEB1',
                        fontSize: '30px',
                        cursor: 'pointer',
                      }}
                    />
                  ) : (
                    <PieChartIcon
                      sx={{
                        color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#A8AEB1',
                        fontSize: '30px',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          {isChartMode ? (
            <Grid item xs={12}>
              {dataChart && (
                <ReactECharts
                  option={option}
                  style={{ height: `${heightChart}px`, width: '100%' }}
                  theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
                />
              )}
            </Grid>
          ) : (
            <S3UsageTable dataChart={dataChart} />
          )}
        </>
      )}
    </>
  );

  return (
    <Item>
      <Grid
        container
        direction="row"
        sx={{
          minHeight: '160px',
          p: 4,
          position: 'relative',
        }}
        alignItems="center"
        justifyContent="center">
        {isDataChartUnavailable ? renderUnavailableMessage : renderChart}
      </Grid>
    </Item>
  );
};

export default S3Usage;
