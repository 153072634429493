import { Layer, Source } from 'react-map-gl';

// this fixed layer keep orthoimage always under background
const SkyLayer = () => {
  return (
    <Source id="layer-map-3d-source" type="raster-dem" url="mapbox://mapbox.mapbox-terrain-dem-v1" tileSize={512}>
      <Layer
        id="sky"
        type="sky"
        paint={{
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun-intensity': 15,
        }}
      />
    </Source>
  );
};

export default SkyLayer;
