import { Avatar, Button, Grid, InputLabel, useTheme } from '@mui/material';
import { convertCropTypeToMultiLanguage } from 'common/utils/convert';
import { useTranslation } from 'react-i18next';

interface IButtonCropType {
  label: string;
  icon: string;
  isSelected: boolean;
  selectedCropType: () => void;
}

export const ButtonCropType = ({ isSelected, label, selectedCropType, icon }: IButtonCropType) => {
  // const IconComponent = cloneElement(icon, { isSelected });
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Button
      sx={{
        minWidth: '120px',
        height: '40px',
        textTransform: 'none',
        fontWeight: '400',
        border: isSelected
          ? `1px solid ${theme.palette.primary.main}`
          : theme.palette.mode === 'dark'
          ? '1px solid #fff'
          : '1px solid #3c404380',
        borderRadius: '5px',
      }}
      onClick={selectedCropType}>
      <Grid display="flex" alignItems="center">
        {/* {IconComponent} */}
        <Avatar
          src={icon}
          sx={{
            width: '15px',
            height: '15px',
            filter: isSelected
              ? 'invert(56%) sepia(16%) saturate(1896%) hue-rotate(95deg) brightness(105%) contrast(88%)'
              : 'invert(24%) sepia(7%) saturate(390%) hue-rotate(163deg) brightness(97%) contrast(96%)',
          }}
        />
        <InputLabel
          sx={{
            m: 0,
            ml: 1,
            color: isSelected
              ? theme.palette.primary.main
              : theme.palette.mode === 'dark'
              ? '1px solid #fff'
              : '1px solid #3C4043',
          }}>
          {t(convertCropTypeToMultiLanguage(label))}
        </InputLabel>
      </Grid>
    </Button>
  );
};
