import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Avatar, Box, Button, useTheme } from '@mui/material';
import { ChangeEvent } from 'react';

type AvatarUserProps = {
  avatar: string;
  handleChangeFile: (event: ChangeEvent<HTMLInputElement>) => void;
  btnLabel?: string;
  heightAvatar?: string;
  widthAvatar?: string;
  heightBtnUpload?: string;
  widthBtnUpload?: string;
  backgroundColor?: string;
  pic?: boolean;
};

const AvatarUser = ({
  avatar,
  handleChangeFile,
  btnLabel = 'Upload File',
  heightAvatar = '50px',
  widthAvatar = '50px',
  heightBtnUpload = '40px',
  widthBtnUpload = '100px',
  backgroundColor = '#ccc',
  pic,
  ...props
}: AvatarUserProps) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}>
      {pic ? (
        <Avatar
          src={avatar}
          sx={{
            height: heightAvatar,
            width: widthAvatar,
            border: `1px solid ${theme.palette.primary.main}`,
            mr: '20px',
            backgroundColor: '#DDFFEB',
            '& .MuiAvatar-img': {
              objectFit: 'initial !important',
            },
          }}>
          <AddAPhotoIcon
            sx={{
              color: theme.palette.primary.main,
            }}
          />
        </Avatar>
      ) : (
        <Avatar
          src={avatar}
          sx={{
            height: heightAvatar,
            width: widthAvatar,
            border: `1px solid ${theme.palette.divider}`,
            mr: '20px',
            backgroundColor: backgroundColor,
            '& .MuiAvatar-img': {
              objectFit: 'initial !important',
            },
          }}
        />
      )}
      <Button
        variant="outlined"
        component="label"
        sx={{
          minHeight: heightBtnUpload,
          minWidth: widthBtnUpload,
        }}>
        {btnLabel}
        <input type="file" hidden onChange={handleChangeFile} />
      </Button>
    </Box>
  );
};

export default AvatarUser;
