import { Box, useTheme } from '@mui/material';
import { ComparedIndex, MapEnum, MapStateKey, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import StreamlineOrderLayer from 'components/MapView/Vector/StreamlineOrderLayer';
import VectorContourLayer from 'components/MapView/Vector/VectorContourLayer';
import Minimap from 'components/Minimap';
import configs from 'constants/config';
import { DEFAULT_VIEWPORT, QUERY_KEY } from 'constants/constants';
import { useLabelAnalytic } from 'hooks/map-view/useLabelAnalytic';
import { useViewAnalyticLayer } from 'hooks/map-view/useViewAnalyticLayer';
import useDataLabel from 'hooks/workspace/useDataLabel';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { Map2dOptionEnum } from 'interfaces/workspace';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactMapGL, { Layer, Source, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getMinMaxZoom, getWorkspaceIntervalLimit } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeAbleTransport, changeViewPort, mapPopupSelector } from 'store/slices/mapPopupSlice';
import { changeMapViewState } from 'store/slices/mapViewSlice';
import {
  changeFlyingToState,
  changeIsResetMapData,
  changeMapStyleSelected,
  tilingMapSelector,
} from 'store/slices/tilingMapSlice';
import DrawBoundary from './DrawBoundary';
import ShowBoundaryLayer from './ShowBoundaryLayer';
import SkyLayer from './SkyLayer';
import StandPointPopup from './StandPointPopup';

interface ReactMapGLTilingProps {
  isShowNavbar: boolean;
  mapStateKey: MapStateKey;
  comparedIndex?: ComparedIndex;
}

const ReactMapGLTiling: FC<ReactMapGLTilingProps> = ({ isShowNavbar, mapStateKey, comparedIndex }) => {
  const { mapStyleSelected } = useAppSelector(tilingMapSelector);
  const [mapStyle, setMapStyle] = useState<string>(mapStyleSelected);
  const [metaData, setMetaData] = useState<{ center: number[] }>();
  const [hoverInfo, setHoverInfo] = useState<any>();
  const mapRef = useRef<any>(null);
  const mouseRef = useRef<{ clientX: number; clientY: number }>({ clientX: 0, clientY: 0 });
  const theme = useTheme();

  const {
    selectedFieldId,
    rightBar: { options2d: options },
    drawingBoundary: { isLoading },
    flyTo: { isDone: isFlyDone },
    isResetMapData,
    analysisId,
    selectedAnalysisName,
  } = useAppSelector(tilingMapSelector);

  const { listDateInfo, viewPort: mapPopupViewPort, ableToTransport } = useAppSelector(mapPopupSelector);

  const { polygonAnalytics } = useViewAnalyticLayer({ mapRef, isMap3D: false });
  const { categoryByColor } = useLabelAnalytic();
  const { fieldDetail } = useQueryFieldDetail();
  const { projectId } = fieldDetail || {};
  const { selectedTask } = useQueryListTaskOfField();
  const { taskId, status, _id, maxX, maxY, zoom } = selectedTask || {};
  const dispatch = useAppDispatch();

  const dataLabel = useDataLabel();
  const selectedLayer = options.find((item) => item.value)?.name;

  const isEnableLogic = status && taskId && projectId && _id;
  const isEnableWithoutTaskId = status && projectId && _id;

  useQuery(
    [QUERY_KEY.GET_MIN_MAX_ZOOM, projectId, taskId],
    () => getMinMaxZoom(projectId!, taskId!, Map2dOptionEnum.dsm),
    {
      onSuccess(res) {
        setMetaData(res.data);
      },
      enabled: !!isEnableLogic,
    }
  );

  // set initial mapStyle
  useEffect(() => {
    const defaultMode =
      theme.palette.mode === 'dark' ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10';
    setMapStyle(mapStyleSelected || defaultMode);
  }, [theme.palette.mode, mapStyleSelected]);

  useEffect(() => {
    if (isEnableWithoutTaskId && !taskId && maxX && maxY && zoom) {
      setMetaData({ center: [Number(maxX), Number(maxY), zoom] });
    }
  }, [isEnableWithoutTaskId, maxX, maxY, taskId, zoom]);

  const { data: intervalLimit } = useQuery(
    [QUERY_KEY.GET_WORKSPACE_INTERVAL_LIMIT, analysisId, _id],
    () => getWorkspaceIntervalLimit(_id!, analysisId, true),
    {
      enabled: !!analysisId && !!_id,
    }
  );

  const renderCenterCoordinates = useCallback(() => {
    if (maxX && maxY && zoom) {
      return [maxX, maxY];
    } else {
      return metaData?.center?.slice(0, 2) || [DEFAULT_VIEWPORT.longitude, DEFAULT_VIEWPORT.latitude];
    }
  }, [maxX, maxY, metaData?.center, zoom]);

  // centralized field the first time
  useEffect(() => {
    if (selectedFieldId) {
      const flyToDetail = {
        center: renderCenterCoordinates(),
        zoom: zoom || metaData?.center[2] || 20,
        essential: true,
        curve: 1,
      };
      if (mapStateKey === MapStateKey.MAP_POPUP) {
        mapRef.current?.flyTo({ ...flyToDetail, duration: 0 });
      } else {
        mapRef.current?.flyTo({ ...flyToDetail, speed: 1.0 });
      }
    }
  }, [mapStateKey, maxX, maxY, metaData?.center, renderCenterCoordinates, selectedFieldId, zoom]);

  // centralized after click search button
  useEffect(() => {
    if (!isFlyDone) {
      mapRef.current?.flyTo({
        center: renderCenterCoordinates(),
        zoom: zoom || metaData?.center[2] || 20,
        essential: true,
        speed: 1.0,
        curve: 1,
      });
    }
    dispatch(changeFlyingToState({ isDone: true }));
  }, [dispatch, isFlyDone, maxX, maxY, metaData?.center, renderCenterCoordinates, zoom]);

  const chosenId = useMemo(() => {
    if (comparedIndex) {
      return listDateInfo[comparedIndex - 1]?.raster?._id || _id;
    } else {
      return _id;
    }
  }, [_id, comparedIndex, listDateInfo]);

  // reload 2d tiles layer after change dependency list
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (map.getLayer(`layer-tiles`)) {
        map.removeLayer(`layer-tiles`);
      }
      if (map.getSource(`raster-tiles`)) {
        map.removeSource(`raster-tiles`);
      }
    }
  }, [selectedLayer, taskId, isLoading, _id, chosenId]);

  // move to default viewport after reset map data
  useEffect(() => {
    if (isResetMapData) {
      mapRef.current?.flyTo({
        center: [DEFAULT_VIEWPORT.longitude, DEFAULT_VIEWPORT.latitude],
        zoom: DEFAULT_VIEWPORT.zoom,
        essential: true,
      });
    }
    dispatch(changeIsResetMapData(false));
  }, [dispatch, isResetMapData]);

  // move map view of the second popup map follow first map
  const {
    data: { longitude, latitude, ...props },
    comparedIndex: storeCI,
  } = mapPopupViewPort;

  useEffect(() => {
    if (
      ableToTransport &&
      ((storeCI === ComparedIndex.SECOND && comparedIndex === ComparedIndex.FIRST) ||
        (storeCI === ComparedIndex.FIRST && comparedIndex === ComparedIndex.SECOND))
    ) {
      mapRef.current?.flyTo({
        center: [longitude, latitude],
        essential: true,
        ...props,
      });
      dispatch(changeAbleTransport(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longitude, latitude]);

  const generateInteractiveLayerIds = () => {
    switch (selectedAnalysisName) {
      case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
      case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
        return ['fill-top-surface-point'];
      case TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT:
        return ['stand-count-layer-Tree'];
      default:
        return undefined;
    }
  };

  const onMouseMove = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    const { features } = event;
    if (!features || !features[0]) {
      setHoverInfo(undefined);
      return;
    }
    const hoveredFeature = features && features[0];
    setHoverInfo(hoveredFeature);
  }, []);

  const onMapMoveEnd = (event: ViewStateChangeEvent) => {
    if (mapStateKey === MapStateKey.MAP_POPUP && comparedIndex) {
      dispatch(changeViewPort({ data: event.viewState, comparedIndex }));
      dispatch(changeAbleTransport(true));
    }
  };

  const setMapViewState = (viewState: ViewState) => {
    const { longitude, latitude, zoom } = viewState;
    dispatch(changeMapViewState({ longitude, latitude, zoom }));
  };

  const renderRasterTiles = useCallback(() => {
    return (
      <Source
        key={`raster-tiles`}
        id={`raster-tiles`}
        type="raster"
        scheme={'tms'}
        tileSize={256}
        tiles={[`${configs.API_DOMAIN}/field/getWebODMAssets/${chosenId}/{z}/{x}/{y}?layerType=${selectedLayer}`]}>
        <Layer
          id={`layer-tiles`}
          type="raster"
          layout={{
            visibility: isEnableWithoutTaskId && selectedLayer ? 'visible' : 'none',
          }}
          beforeId="sky"
        />
      </Source>
    );
  }, [isEnableWithoutTaskId, selectedLayer, chosenId]);

  return (
    <Box
      sx={{
        height: '100%',
        '& .mapboxgl-ctrl-top-left': {
          left: isShowNavbar ? '370px' : '40px',
          '& .active': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        '& .finish-drawing': {
          left: isShowNavbar ? '420px' : '90px',
        },
      }}>
      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          left: isShowNavbar ? '350px' : '20px',
          display: 'block',
        }}>
        <Minimap
          onChangeStyleMap={(style) => {
            setMapStyle(style);
            dispatch(changeMapStyleSelected(style));
          }}
        />
      </Box>
      <ReactMapGL
        initialViewState={DEFAULT_VIEWPORT}
        mapboxAccessToken={configs.MAP_BOX_API}
        ref={mapRef}
        mapStyle={mapStyle}
        interactiveLayerIds={generateInteractiveLayerIds()}
        onMouseMove={onMouseMove}
        onMouseLeave={() => setHoverInfo(undefined)}
        onZoom={(e) => setMapViewState(e.viewState)}
        onMoveEnd={onMapMoveEnd}
        cursor="default">
        <SkyLayer />
        {renderRasterTiles()}
        <DrawBoundary />
        <ShowBoundaryLayer />
        <StreamlineOrderLayer mode={MapEnum.CROP_INTELLIGENT} />
        <VectorContourLayer mode={MapEnum.CROP_INTELLIGENT} />
        {polygonAnalytics}

        {hoverInfo && (
          <StandPointPopup
            mouseRef={mouseRef}
            hoverInfo={hoverInfo}
            analyticName={selectedAnalysisName}
            dataLabel={dataLabel}
            categoryByColor={categoryByColor}
            intervalLimitData={intervalLimit?.data}
          />
        )}
      </ReactMapGL>
    </Box>
  );
};

export default ReactMapGLTiling;
