import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';
import Mapbox3dModal from './Mapbox3dModal';
import Mesh3dModal from './Mesh3dModal';
import PotreePointCloudModal from './PotreePointCloudModal';

interface AllModal3dProps {
  projectId: string | undefined;
  taskId: string | undefined;
}

const AllModal3d: FC<AllModal3dProps> = ({ projectId, taskId }) => {
  const {
    rightBar: {
      show3dModal: { isShowPotree, isShow3dMesh, isShow3dMapbox },
    },
  } = useAppSelector(tilingMapSelector);

  return (
    <>
      {isShowPotree && projectId && taskId && (
        <PotreePointCloudModal isShow={isShowPotree} projectId={projectId} taskId={taskId} />
      )}
      {isShow3dMesh && <Mesh3dModal isShow={isShow3dMesh} />}
      {isShow3dMapbox && projectId && taskId && (
        <Mapbox3dModal isShow={isShow3dMapbox} projectId={projectId} taskId={taskId} />
      )}
    </>
  );
};

export default AllModal3d;
