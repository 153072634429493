import { Box } from '@mui/material';
import ExcelButton from 'components/Common/ExcelButton';
import { saveAs } from 'file-saver';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { exportExcelInformationTab } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import ClientInfo from './ClientInfo';
import InfoPolygonLayer from './InfoPolygonLayer';
import LevelInfo from './LevelInfo';

const InformationTab = () => {
  const { levelId, cropType } = useAppSelector(mapViewSelector);
  const { clientId } = useParams();

  const exportExcelMutation = useMutation(exportExcelInformationTab, {
    onSuccess(res) {
      saveAs(res.request.responseURL, 'Information.xlsx');
      toast.success('Download file successful');
    },
  });

  return (
    <Scrollbars style={{ height: 'calc(100vh - 150px)' }} autoHide>
      {levelId ? <LevelInfo levelId={levelId} cropType={cropType} clientId={clientId} /> : <ClientInfo />}
      <InfoPolygonLayer />
      <Box textAlign="center" mt="16px" mb="24px">
        <ExcelButton
          onClick={() => exportExcelMutation.mutate({ levelId: levelId || null, clientId: clientId!, cropType })}
          isLoading={exportExcelMutation.isLoading}
          disabled={exportExcelMutation.isLoading}
        />
      </Box>
    </Scrollbars>
  );
};

export default InformationTab;
