import { useTheme } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import configs from 'constants/config';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeShow3dModal } from 'store/slices/tilingMapSlice';

const Potree = (window as any).Potree;

interface PotreePointCloudModalProps {
  isShow: boolean;
  projectId: string;
  taskId: string;
}

const PotreePointCloudModal: FC<PotreePointCloudModalProps> = ({ isShow, projectId, taskId }) => {
  const [counter, setCounter] = useState<number>(0);
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const baseURl = configs.API_DOMAIN;
  const folderName = 'entwine_pointcloud';
  const fileName = 'ept.json';
  const url = `${baseURl}/field/3D/${projectId}/${taskId}/${folderName}/${fileName}`;

  const isLightMode = theme.palette.mode === 'light';

  // force render component
  useEffect(() => {
    setTimeout(() => {
      setCounter((count) => count + 1);
    }, 100);
  }, []);

  useEffect(() => {
    const potreeRenderArea = document.querySelector('#potree_render_area');
    if (!potreeRenderArea) return;
    let viewer = new Potree.Viewer(potreeRenderArea);
    viewer.setEDLEnabled(true);
    viewer.setFOV(60);
    viewer.setPointBudget(1 * 1000 * 1000);
    viewer.loadSettingsFromURL();
    viewer.loadGUI(() => {
      viewer.setLanguage('en');
    });
    Potree.loadPointCloud(url).then(
      (e: any) => {
        //let scene = viewer.scene;
        let pointcloud = e.pointcloud;
        let material = pointcloud.material;

        material.activeAttributeName = 'rgba';
        material.minSize = 2;
        material.pointSizeType = Potree.PointSizeType.FIXED;

        viewer.scene.addPointCloud(pointcloud);
        viewer.fitToScreen();
      },
      (e: any) => console.error('ERROR: ', e)
    );
  }, [url, counter]);

  return (
    <CommonModal
      isShow={isShow}
      handleClose={() => dispatch(changeShow3dModal({ isShowPotree: false }))}
      style={{ height: '100vh', width: '100vw' }}
      closeIconColor={isLightMode ? theme.palette.color.green5 : ''}>
      <div style={{ height: '100vh', width: '100vw' }} id="potree-root">
        <div
          className={'potree_container'}
          style={{ position: 'fixed', left: 0, top: 0, height: '100vh', width: '100vw' }}>
          <div id="potree_sidebar_container"></div>
          <div id="potree_render_area"></div>
        </div>
      </div>
    </CommonModal>
  );
};

export default PotreePointCloudModal;
