import { useGetClientSettingMapView } from 'hooks/map-view/useGetClientSettingMapView';
import { useClientData } from 'hooks/useClientData';
import useClientDataConfig from 'hooks/useClientDataConfig';
import { useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  changeFixedSizePointCrownArea,
  changeZoomLayerCrownArea,
  crownAreaAnalyticsSelector,
} from 'store/slices/map-view/crownAreaAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import EditTabLayer from './EditTabLayer';

export const useCrownAreaAnalytics = ({ mapRef }: any) => {
  const { dataGeoJsonPoint, dataGeoJsonPolygon } = useAppSelector(crownAreaAnalyticsSelector);
  const { isLayer3D, isDisplayBoundaryLine, isDisplay3DCylinder, isInEditTab } = useAppSelector(mapViewSelector);
  const dispatch = useDispatch();

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, getOpacityExtrusion } =
    useGetClientSettingMapView();

  const { contourSetting } = useClientData();
  const { isFixedPoint, value } = useClientDataConfig();
  const { analyticId } = useAppSelector(mapViewSelector);

  const zoomLevel = useMemo(() => {
    if (!mapRef?.current) return 0;
    return mapRef?.current?.getZoom();
  }, [mapRef?.current?.getZoom()]);

  useEffect(() => {
    if (!mapRef?.current) return;

    if (isFixedPoint && value) {
      dispatch(changeFixedSizePointCrownArea(value));
    } else dispatch(changeZoomLayerCrownArea(zoomLevel));
  }, [dispatch, isFixedPoint, mapRef, value, zoomLevel, analyticId]);

  const layerCrownArea = useMemo(() => {
    if (!dataGeoJsonPoint && !dataGeoJsonPolygon) {
      return null;
    }

    return (
      <>
        <EditTabLayer />
        {dataGeoJsonPoint && !isInEditTab && (
          <Source id={`chlorophyll-polygon-point`} type="geojson" data={dataGeoJsonPoint}>
            {/* 2D Point */}
            <Layer
              id={`fill-top-surface-point`}
              type="fill"
              source={`chlorophyll-circle-point`}
              paint={{
                'fill-color': ['get', 'color'],
              }}
              layout={{ visibility: !isLayer3D && !isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Cylinder */}
            <Layer
              id={`chlorophyll-top-surface-point`}
              type="fill-extrusion"
              source={`chlorophyll-circle-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`chlorophyll-side-surface-point`}
              type="fill-extrusion"
              source={`chlorophyll-circle-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                // getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
          </Source>
        )}
        {dataGeoJsonPolygon && !isInEditTab && (
          <Source id={`chlorophyll-source`} type="geojson" data={dataGeoJsonPolygon}>
            {/* 2D Contour */}
            <Layer
              id={`chlorophyll_polygon`}
              type="line"
              paint={{
                'line-color': ['get', 'color'],
                'line-width': 2,
                'line-dasharray': [2, 2],
              }}
              source={`chlorophyll-source`}
              layout={{ visibility: !isLayer3D && isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Filled contour */}
            <Layer
              id={`chlorophyll_fill-polygon`}
              type="fill"
              paint={{
                'fill-color': ['get', 'color'],
                'fill-opacity': 0,
              }}
              source={`chlorophyll-source`}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`chlorophyll_top-surface`}
              type="fill-extrusion"
              beforeId={`chlorophyll_fill-polygon`}
              source={`chlorophyll_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`chlorophyll_side-surface`}
              type="fill-extrusion"
              beforeId={`chlorophyll_top-surface`}
              source={`chlorophyll_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
          </Source>
        )}
      </>
    );
  }, [
    colorTransparent,
    contourSetting,
    dataGeoJsonPoint,
    dataGeoJsonPolygon,
    getOpacityExtrusion,
    getTopSurfaceOpacity,
    isDisplay3DCylinder,
    isDisplayBoundaryLine,
    isInEditTab,
    isLayer3D,
    isSameColorTopSurface,
  ]);
  return { layerCrownArea };
};
