import { Box, Button, FormControlLabel, Popover, Typography } from '@mui/material';
import { ISwitchItem } from 'common/defines/analytic';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { FC, MouseEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { saveColorsAnalyticLayer } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

interface CommonSwitchItemProps {
  item: ISwitchItem;
  handleSwitchListValue: () => void;
}

const CommonSwitchItem: FC<CommonSwitchItemProps> = ({ item, handleSwitchListValue }) => {
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { analyticId } = useAppSelector(mapViewSelector);

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const classes = useLayerSelectCardStyle();
  const { clientSetting } = useQueryClientSetting();

  const { key, label, visible, color } = item;
  const fullAnalyticId = `${analyticId}_${key}`;

  const updateColorMutation = useMutation(saveColorsAnalyticLayer, {
    onSuccess() {
      toast.success('Update color successful');
      handlePopoverClose();
      queryClient.invalidateQueries([QUERY_KEY.CLIENT_SETTINGS_BY_ID]);
    },
  });

  const handleChangeColor = () => {
    // with vector related, replace analytic by sensorId
    updateColorMutation.mutate({ analysisId: fullAnalyticId, settingId: clientSetting?._id!, color: selectedColor });
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <SwitchCustom
            checked={visible}
            onClick={() => handleSwitchListValue()}
            // onClick={() => dispatch(changeSwitchCircumferenceListValue({ key, visible: !visible }))}
          />
        }
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            {color && (
              <Box
                sx={{
                  width: '14px',
                  height: '14px',
                  border: `1px solid ${color}`,
                  borderRadius: '100%',
                  backgroundColor: color,
                }}
                onClick={(e: any) => handleClick(e)}
              />
            )}
            <Typography className={classes.selectText} ml="4px">
              {label}
            </Typography>
          </Box>
        }
      />
      <Popover
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
          '.MuiPopover-paper': {
            overflowX: 'initial',
            overflowY: 'initial',
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 160,
          horizontal: 30,
        }}>
        <Box>
          <HexColorPickerDebounce
            style={{
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '10px',
            }}
            color={color}
            onChange={(color: string) => {
              setSelectedColor(color);
            }}
          />
          <Box sx={{ my: '8px', textAlign: 'center' }}>
            <Button color="inherit" variant="outlined" size="small" onClick={handlePopoverClose}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" size="small" sx={{ ml: '12px' }} onClick={handleChangeColor}>
              Save
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default CommonSwitchItem;
