export const ScheduleIcon = ({ isSelected }: any) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill={isSelected ? '#23BE6A' : '#737373'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2539 2.25H12.7539V1.5C12.7539 1.05 12.4539 0.75 12.0039 0.75C11.5539 0.75 11.2539 1.05 11.2539 1.5V2.25H6.75391V1.5C6.75391 1.05 6.45391 0.75 6.00391 0.75C5.55391 0.75 5.25391 1.05 5.25391 1.5V2.25H3.75391C2.47891 2.25 1.50391 3.225 1.50391 4.5V15C1.50391 16.275 2.47891 17.25 3.75391 17.25H14.2539C15.5289 17.25 16.5039 16.275 16.5039 15V4.5C16.5039 3.225 15.5289 2.25 14.2539 2.25ZM3.75391 3.75H5.25391V4.5C5.25391 4.95 5.55391 5.25 6.00391 5.25C6.45391 5.25 6.75391 4.95 6.75391 4.5V3.75H11.2539V4.5C11.2539 4.95 11.5539 5.25 12.0039 5.25C12.4539 5.25 12.7539 4.95 12.7539 4.5V3.75H14.2539C14.7039 3.75 15.0039 4.05 15.0039 4.5V6.75H3.00391V4.5C3.00391 4.05 3.30391 3.75 3.75391 3.75ZM3.75391 15.75H14.2539C14.7039 15.75 15.0039 15.45 15.0039 15V8.25H3.00391V15C3.00391 15.45 3.30391 15.75 3.75391 15.75Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
      <mask id="mask0_5830_11209" maskUnits="userSpaceOnUse" x="1" y="0" width="16" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2539 2.25H12.7539V1.5C12.7539 1.05 12.4539 0.75 12.0039 0.75C11.5539 0.75 11.2539 1.05 11.2539 1.5V2.25H6.75391V1.5C6.75391 1.05 6.45391 0.75 6.00391 0.75C5.55391 0.75 5.25391 1.05 5.25391 1.5V2.25H3.75391C2.47891 2.25 1.50391 3.225 1.50391 4.5V15C1.50391 16.275 2.47891 17.25 3.75391 17.25H14.2539C15.5289 17.25 16.5039 16.275 16.5039 15V4.5C16.5039 3.225 15.5289 2.25 14.2539 2.25ZM3.75391 3.75H5.25391V4.5C5.25391 4.95 5.55391 5.25 6.00391 5.25C6.45391 5.25 6.75391 4.95 6.75391 4.5V3.75H11.2539V4.5C11.2539 4.95 11.5539 5.25 12.0039 5.25C12.4539 5.25 12.7539 4.95 12.7539 4.5V3.75H14.2539C14.7039 3.75 15.0039 4.05 15.0039 4.5V6.75H3.00391V4.5C3.00391 4.05 3.30391 3.75 3.75391 3.75ZM3.75391 15.75H14.2539C14.7039 15.75 15.0039 15.45 15.0039 15V8.25H3.00391V15C3.00391 15.45 3.30391 15.75 3.75391 15.75Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5830_11209)">
        <rect x="0.00390625" width="18" height="18" fill={isSelected ? '#23BE6A' : '#737373'} />
      </g>
    </svg>
  );
};
