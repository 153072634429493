import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { circle, point } from '@turf/turf';
import { DIFFERENT_COLOR } from 'common/defines/constants';
import { sizeByZoomLevel } from 'common/utils/util';
import update from 'immutability-helper';
import { RootState } from 'store/store';

interface IGapSlice {
  dataGap: any;
  dataGapPolygon: any;
  dataGapInterval: any;
  isShowAllLayer: boolean;
  dataGeoJsonPoint: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  dataGeoJsonPolygon: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  zoomLayer: number;
  sizeFixedPoint: number;
  chartDataGapPreview: any;
}

const initialState: IGapSlice = {
  dataGap: null,
  dataGapPolygon: [],
  dataGapInterval: null,
  isShowAllLayer: true,
  dataGeoJsonPoint: undefined,
  dataGeoJsonPolygon: undefined,
  zoomLayer: 16,
  sizeFixedPoint: 0.0015,
  chartDataGapPreview: null,
};

export const gapAnalyticSlice = createSlice({
  name: 'gapAnalytic',
  initialState,
  reducers: {
    clearGapAnalytics: () => {
      return initialState;
    },
    changeDataGapAnalytics: (state, action: PayloadAction<any>) => {
      state.dataGap = action.payload;
      if (!state.dataGapInterval || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((gapInstance: any) => {
          let color = 'transparent';
          color = gapInstance.status === 'Blank spot' ? DIFFERENT_COLOR[0] : DIFFERENT_COLOR[1];
          return circle(
            point([Number(gapInstance.longX || 0), Number(gapInstance.latY || 0)], {
              ...gapInstance,
              color,
            }),
            sizeByZoomLevel(state.zoomLayer),
            { steps: 32 }
          );
        }),
      };
    },
    changeDataGapInterval: (state, action: PayloadAction<any>) => {
      state.dataGapInterval = action.payload;
      if (state.dataGapPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataGapPolygon.map((gapInstance: any) => {
            let color = 'black';
            const gapInterval = state.dataGapInterval.range?.find(
              (item: any, index: number) =>
                item.from <= gapInstance.length &&
                (index === state.dataGapInterval?.range?.length - 1
                  ? item.to >= gapInstance.length
                  : item.to > gapInstance.length)
            );
            if (gapInterval?.visible) {
              color = gapInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...gapInstance.geometry },
              properties: {
                ...gapInstance,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
      if (!state.dataGap || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: state.dataGap.map((gapInstance: any) => {
          let color = 'black';
          color = gapInstance.status === 'Blank spot' ? DIFFERENT_COLOR[0] : DIFFERENT_COLOR[1];

          return circle(
            point([Number(gapInstance.longX || 0), Number(gapInstance.latY || 0)], {
              ...gapInstance,
              color,
            }),
            sizeByZoomLevel(state.zoomLayer),
            { steps: 32 }
          );
        }),
      };
    },
    changeVisibleIntervalGap: (state, action: PayloadAction<number>) => {
      const currentInterval = state.chartDataGapPreview
        ? state.chartDataGapPreview.intervalLimit
        : state.dataGapInterval;
      state.isShowAllLayer = currentInterval?.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      currentInterval.range = update(currentInterval?.range, {
        [action.payload]: {
          visible: { $set: !currentInterval.range[action.payload].visible },
        },
      });
      if (state.dataGap) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataGap.map((gapInstance: any) => {
            let color = 'black';
            color = gapInstance.status === 'Blank spot' ? DIFFERENT_COLOR[0] : DIFFERENT_COLOR[1];

            return circle(
              point([Number(gapInstance.longX || 0), Number(gapInstance.latY || 0)], {
                ...gapInstance,
                color,
              }),
              sizeByZoomLevel(state.zoomLayer),
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataGapPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataGapPolygon.map((gapInstance: any) => {
            let color = 'black';
            const gapInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= gapInstance.length &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= gapInstance.length
                  : item.to > gapInstance.length)
            );
            if (gapInterval?.visible) {
              color = gapInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...gapInstance.geometry },
              properties: {
                ...gapInstance,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeVisibleIntervalGapAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataGapInterval.range = update(state.dataGapInterval.range, {
        $apply: (intervalRange: Array<any>) =>
          intervalRange.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
      if (state.dataGap) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataGap.map((gapInstance: any) => {
            let color = 'black';
            color = gapInstance.status === 'Blank spot' ? DIFFERENT_COLOR[0] : DIFFERENT_COLOR[1];

            return circle(
              point([Number(gapInstance.longX || 0), Number(gapInstance.latY || 0)], {
                ...gapInstance,
                color,
              }),
              sizeByZoomLevel(state.zoomLayer),
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataGapPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataGapPolygon.map((gapInstance: any) => {
            let color = 'black';
            const gapInterval = state.dataGapInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= gapInstance.length &&
                (index === state.dataGapInterval?.range?.length - 1
                  ? item.to >= gapInstance.length
                  : item.to > gapInstance.length)
            );
            if (gapInterval?.visible) {
              color = gapInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...gapInstance.geometry },
              properties: {
                ...gapInstance,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeDataPolygonGapAnalytics: (state, action: PayloadAction<any>) => {
      state.dataGapPolygon = action.payload;
      if (!state.dataGapInterval) {
        return;
      }
      const geoJson = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((gapInstance: any) => {
          let color = 'black';
          const gapInterval = state.dataGapInterval.range?.find(
            (item: any, index: number) =>
              item.from <= gapInstance.length &&
              (index === state.dataGapInterval?.range?.length - 1
                ? item.to >= gapInstance.length
                : item.to > gapInstance.length)
          );
          if (gapInterval?.visible) {
            color = gapInterval.color;
          } else color = 'transparent';

          return {
            geometry: { ...gapInstance.geometry },
            properties: {
              ...gapInstance,
              color: color,
            },
          };
        }),
      };
      state.dataGeoJsonPolygon = geoJson;
    },

    changeZoomLayerGap: (state, action: PayloadAction<number>) => {
      state.zoomLayer = action.payload;
      const zoomLevel = action.payload;

      if (state.dataGap) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataGap.map((gapInstance: any) => {
            let color = 'black';
            color = gapInstance.status === 'Blank spot' ? DIFFERENT_COLOR[0] : DIFFERENT_COLOR[1];

            return circle(
              point([Number(gapInstance.longX || 0), Number(gapInstance.latY || 0)], {
                ...gapInstance,
                color,
              }),
              sizeByZoomLevel(zoomLevel),
              { steps: 32 }
            );
          }),
        };
      }
    },
    changeFixedSizePointGap: (state, action: PayloadAction<any>) => {
      state.sizeFixedPoint = action.payload;
    },
    changeChartDataGapPreview: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        const intervalLimit = {
          ...state.dataGapInterval,
          range: action.payload.range,
        };
        const chartData: any = [];
        action.payload.range.forEach((item: any) => {
          const listByRange = state.dataGapPolygon.filter(
            (gap: any) => gap.length >= item.from && gap.length <= item.to
          );
          chartData.push({ [item.lable]: listByRange.length });
        });
        let dataPreview = { chartData, intervalLimit };

        state.chartDataGapPreview = dataPreview;

        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataGap.map((gapInstance: any) => {
            let color = 'black';
            color = gapInstance.status === 'Blank spot' ? DIFFERENT_COLOR[0] : DIFFERENT_COLOR[1];

            return circle(
              point([Number(gapInstance.longX || 0), Number(gapInstance.latY || 0)], {
                ...gapInstance,
                color,
              }),
              sizeByZoomLevel(state.zoomLayer),
              { steps: 32 }
            );
          }),
        };
        state.dataGeoJsonPolygon = {
          type: 'FeatureCollection' as any,
          features: state.dataGapPolygon.map((gapInstance: any) => {
            let color = 'black';
            const gapInterval = state.chartDataGapPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= gapInstance.length &&
                (index === state.chartDataGapPreview?.intervalLimit?.range?.length - 1
                  ? item.to >= gapInstance.length
                  : item.to > gapInstance.length)
            );
            if (gapInterval?.visible) {
              color = gapInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...gapInstance.geometry },
              properties: {
                ...gapInstance,
                color: color,
              },
            };
          }),
        };
      } else {
        state.chartDataGapPreview = null;
      }
    },
  },
});

export const {
  clearGapAnalytics,
  changeDataGapAnalytics,
  changeDataGapInterval,
  changeVisibleIntervalGapAllLayers,
  changeVisibleIntervalGap,
  changeDataPolygonGapAnalytics,
  changeZoomLayerGap,
  changeFixedSizePointGap,
  changeChartDataGapPreview,
} = gapAnalyticSlice.actions;

export const gapAnalyticsSelector = (state: RootState) => state.gapAnalyticSlice;

export default gapAnalyticSlice.reducer;
