export const FlightPlannerIcon = ({ isSelected }: any) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5183 5.9361L13.5184 5.93613C13.7267 6.27473 13.9335 6.58802 14.106 6.84092H6.80028C5.27588 6.84092 4.03583 8.08097 4.03583 9.60537C4.03583 11.1298 5.27588 12.3698 6.80028 12.3698H10.2117C10.9254 12.3698 11.5062 12.9506 11.5062 13.6642C11.5062 14.3779 10.9254 14.9587 10.2117 14.9587H3.81523C3.58336 14.2634 2.92642 13.7608 2.1541 13.7608C1.18875 13.7608 0.403906 14.546 0.403906 15.5113C0.403906 16.4763 1.18876 17.2615 2.1541 17.2615C2.89235 17.2615 3.525 16.8023 3.78206 16.1548H10.2118C11.5851 16.1548 12.7023 15.0376 12.7023 13.6642C12.7023 12.2909 11.5851 11.1737 10.2118 11.1737H6.80028C5.93558 11.1737 5.23192 10.4701 5.23192 9.60537C5.23192 8.74067 5.93555 8.03701 6.80028 8.03701H15.0203H15.0205H15.0206H15.071L15.101 7.99653L15.5011 7.45634C15.5011 7.45633 15.5011 7.45632 15.5011 7.45631C15.5231 7.42668 16.0189 6.75551 16.5226 5.9361C16.8829 5.35007 17.1524 4.84399 17.332 4.41264C17.5112 3.98252 17.6039 3.62002 17.6039 3.3231C17.6039 1.89858 16.4452 0.739844 15.0206 0.739844C13.5961 0.739844 12.4374 1.89858 12.4374 3.3231C12.4374 3.62001 12.53 3.98249 12.7091 4.41263C12.8887 4.84398 13.1581 5.35007 13.5183 5.9361ZM2.1541 16.0654C1.84874 16.0654 1.6 15.8167 1.6 15.5113C1.6 15.2056 1.84878 14.9569 2.1541 14.9569C2.45979 14.9569 2.70854 15.2056 2.70854 15.5113C2.70854 15.8166 2.45982 16.0654 2.1541 16.0654ZM15.943 4.54564C15.6842 5.03243 15.3494 5.57369 15.0206 6.0622C14.6917 5.5737 14.357 5.03244 14.0982 4.54564C13.9579 4.28175 13.8407 4.03549 13.759 3.82331C13.6761 3.60843 13.6335 3.43831 13.6335 3.3231C13.6335 2.55822 14.2558 1.93594 15.0206 1.93594C15.7852 1.93594 16.4078 2.55823 16.4078 3.3231C16.4078 3.43831 16.3651 3.60842 16.2823 3.8233C16.2005 4.03548 16.0833 4.28174 15.943 4.54564Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
        stroke={isSelected ? '#23BE6A' : '#737373'}
        strokeWidth="0.2"
      />
      <path
        d="M15.0198 2.67773C14.7416 2.67773 14.5161 2.90318 14.5161 3.18143C14.5161 3.45967 14.7416 3.68512 15.0198 3.68512C15.2977 3.68512 15.5232 3.45967 15.5232 3.18143C15.5232 2.90318 15.2977 2.67773 15.0198 2.67773Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
    </svg>
  );
};
