import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeIsShowAllInfoTooltip } from 'store/slices/mapViewSlice';
import { ChlorophyllAnalytics } from './Chlorophyll';
import ChlorophyllPaddyAnalytics from './ChlorophyllPaddy';
import { CircumferenceAnalytics } from './Circumference';
import { Co2SequestrationAnalytics } from './Co2Sequestration';
import CropCoverAnalytics from './CropCover';
import { CrownAreaAnalytics } from './CrownArea';
import CustomFieldAnalytics from './CustomField';
import ElevationFieldAnalytics from './ElevationField';
import FissureAnalytics from './FissureAnalytics';
import { GapAnalytics } from './Gap';
import { LandUseAnalytics } from './LandUse';
import LeafPhenologyFieldAnalytics from './LeafPhenologyField';
import PlantHealthAnalytics from './PlantHealth';
import { PlantHealthOilPailmAnalytics } from './PlantHealthOilPailm';
import { PlantStressAnalytics } from './PlantStress';
import SlopeFieldAnalytics from './SlopeField';
import SoilErosionFieldAnalytics from './SoilErosionField';
import SoilMoistureContentAnalytics from './SoilMoistureContentAnalytics';
import SoilWaterContentFieldAnalytics from './SoilWaterContentField';
import StandCountAnalytics from './StandCount';
import StressFieldAnalytics from './StressField';
import TilerDensityAnalytics from './TilerDensity';
import { TreeHeightAnalytics } from './TreeHeight';
import { TreeTiltFallenAnalytics } from './TreeTiltFallen';
import VacantAreaAnalytics from './VacantArea';
import VegetationEncroachmentAnalytics from './VegetationEncroachment';
import { VigorAnalytics } from './Vigor';
import VigorPaddyAnalytics from './VigorPaddy';
import { WatershedBasinAnalytics } from './WatershedBasin';
import { WaterUptakeAnalytics } from './WaterUptake';
import WaterUptakeFieldAnalytics from './WaterUptakeField';
import { WeedInvasionAnalytics } from './WeedInvasionAnalytics';

interface ICategoriesTab {
  analyticType: any;
}

export const CategoriesTab = ({ analyticType }: ICategoriesTab) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeIsShowAllInfoTooltip(false));
  }, [analyticType, dispatch]);

  const children = useMemo(() => {
    switch (analyticType) {
      case TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS:
        return <LandUseAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE:
        return <CropCoverAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT:
        return <StandCountAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
        return <CircumferenceAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT:
        return <TreeHeightAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION:
        return <Co2SequestrationAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE:
        return <VigorAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE:
        return <PlantStressAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
        return <CrownAreaAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.GAP:
        return <GapAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE:
        return <WaterUptakeAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE:
        return <ChlorophyllAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD:
        return <PlantHealthAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY:
        return <TilerDensityAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA:
        return <VacantAreaAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD:
        return <ChlorophyllPaddyAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION:
        return <WeedInvasionAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD:
        return <VigorPaddyAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE:
        return <PlantHealthOilPailmAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD:
        return <StressFieldAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD:
        return <WaterUptakeFieldAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.SOIL_WATER_CONTENT_FIELD:
        return <SoilWaterContentFieldAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD:
        return <SoilErosionFieldAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD:
        return <LeafPhenologyFieldAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD:
        return <ElevationFieldAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD:
        return <SlopeFieldAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT:
        return <VegetationEncroachmentAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.CUSTOM_FIELD:
        return <CustomFieldAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.SOIL_MOISTURE_CONTENT:
        return <SoilMoistureContentAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.FISSURE:
        return <FissureAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN:
        return <WatershedBasinAnalytics />;
      case TYPE_ANALYTICS_MAP_VIEW.TREE_TILT_FALLEN:
        return <TreeTiltFallenAnalytics />;
      default:
        return <></>;
    }
  }, [analyticType]);

  return <>{children}</>;
};
