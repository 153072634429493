import { useTheme } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { FC } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeShow3dModal } from 'store/slices/tilingMapSlice';
import React3dMap from '../3d-map';
interface Mesh3dModalProps {
  isShow: boolean;
}

const Mesh3dModal: FC<Mesh3dModalProps> = ({ isShow }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isLightMode = theme.palette.mode === 'light';

  return (
    <CommonModal
      isShow={isShow}
      handleClose={() => dispatch(changeShow3dModal({ isShow3dMesh: false }))}
      style={{ height: '100vh', width: '100vw' }}
      closeIconColor={isLightMode ? theme.palette.color.green5 : ''}>
      <React3dMap />
    </CommonModal>
  );
};

export default Mesh3dModal;
