import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  permissionsData,
  QUERY_KEY,
  userTypeNameOptionsAerodyne,
  userTypeNameOptionsClient,
} from 'constants/constants';
import { useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { createRole, getClientList } from 'services/clients/apiClient.services';
import SinglePermission from './SinglePermission';

interface IRoleAddModal {
  open: boolean;
  setOpen: () => void;
  reloadData: any;
}

export const RoleAddModal = ({ open, setOpen, reloadData }: IRoleAddModal) => {
  const [roleName, setRoleName] = useState<string>('');
  const [userTypeName, setUserTypeName] = useState(userTypeNameOptionsAerodyne[0]);
  const [clientId, setClientId] = useState<string>('');
  const [permissionRole, setPermissionRole] = useState<string[]>([]);
  const [clientName, setClientName] = useState<string>('');
  const [clientList, setClientList] = useState<any[]>([]);
  const theme = useTheme();

  const isSubAdmin = useMemo(() => {
    return userTypeNameOptionsAerodyne.includes(userTypeName) ? true : false;
  }, [userTypeName]);

  // Call clientId list data
  useQuery(QUERY_KEY.CLIENT_LIST, () => getClientList(), {
    onSuccess: (data: any) => {
      const clientNameData = data.data.map((item: any) => {
        return {
          value: item._id,
          name: item.name,
          avatar: item.avatar,
          email: item.email,
        };
      });
      setClientList(clientNameData);
    },
  });

  // UserType name options
  const handleChangeUserTypeName = (event: any) => {
    setUserTypeName(event.target.value);
  };

  // Set clientId and clientName
  const handleSelectClient = useMemo(() => {
    return (event: any) => {
      setClientId(event.target.value);
      clientList.forEach((item: any) => {
        if (item.value === event.target.value) {
          setClientName(item.name.replace(/\s/g, '-'));
        }
      });
    };
  }, [clientList]);

  const generateRoleName = useMemo(() => {
    if (!roleName) return;
    return `${userTypeName}_${roleName}_${clientName}`;
  }, [userTypeName, roleName, clientName]);

  const useCreateRole = () => {
    return useMutation(
      (param: any) =>
        createRole({
          ...param,
          clientId,
        }),
      {
        onSuccess: () => {
          toast.success('Add successes', { toastId: 1 });
          handleCloseModal();
          reloadData();
        },
        onError: (e: any) => {
          if (e.status === 400) {
            toast.error(e.data.message[0], { toastId: 1 });
            return;
          }
        },
      }
    );
  };

  const mutationCreateRole = useCreateRole();

  const handleSubmitForm = () => {
    mutationCreateRole.mutate({
      clientId: clientId,
      name: generateRoleName,
      permissions: permissionRole,
      isSubAdmin: isSubAdmin,
      isActive: true,
      isSuperAdmin: false,
      isSuperUser: false,
    });
  };

  const handleCloseModal = () => {
    setPermissionRole([]);
    setRoleName('');
    setOpen();
    setClientId('');
    setClientName('');
    setUserTypeName(userTypeNameOptionsAerodyne[0]);
  };


  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      sx={{
        '.MuiPaper-root': {
          width: '900px',
          maxWidth: '900px',
        },
      }}>
      <DialogTitle
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
        }}>
        <Typography
          sx={{
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            color: theme.palette.mode === 'dark' ? '#fff' : '#5E5873',
          }}>
          Add New Role
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            p: 0,
            position: 'absolute',
            right: 8,
            top: 8,
            '.MuiSvgIcon-root': {
              width: '16px',
              height: '16px',
            },
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={{ border: '0.5px solid #EDF1F1' }} />
      <Typography sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Scrollbars style={{ height: 'calc(100vh - 420px)' }} autoHide>
          <DialogContent
            sx={{
              bgcolor: (theme) => theme.palette.background.paper,
            }}>
            <>
              <Box
                sx={{
                  display: 'flex',
                  height: '24px',
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Barlow',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '18px',
                    pb: '6px',
                  }}>
                  Name
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '20px',
                  height: '37px',
                }}>
                <Select
                  sx={{
                    minWidth: '186px',
                    textAlign: 'left',
                    borderRadius: '5px 0 0 5px',
                    height: '100%',
                    minHeight: '37px',
                  }}
                  value={userTypeName}
                  onChange={handleChangeUserTypeName}>
                  <option
                    style={{
                      height: '36px',
                      lineHeight: '36px',
                      fontWeight: 'bold',
                      color: theme.palette.mode === 'dark' ? '#fff' : '#5E5873',
                      padding: '0px 10px',
                    }}
                    value="">
                    USER TYPE
                  </option>
                  <option
                    style={{
                      height: '36px',
                      lineHeight: '36px',
                      borderTop: `1px solid ${theme.palette.divider}`,
                      padding: '0px 10px',
                    }}
                    value="">
                    AERODYNE
                  </option>
                  {userTypeNameOptionsAerodyne.map((data) => (
                    <MenuItem key={data} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                  <option
                    value=""
                    style={{
                      borderTop: `1px solid ${theme.palette.divider}`,
                      height: '36px',
                      lineHeight: '36px',
                      padding: '0px 10px',
                    }}>
                    CLIENT
                  </option>
                  {userTypeNameOptionsClient.map((data) => (
                    <MenuItem key={data} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  type="text"
                  size="small"
                  required
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderRadius: '0 5px 5px 0',
                    },
                  }}
                  placeholder="Enter user name"
                  fullWidth
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                />
              </Box>
            </>
            <>
              <Typography
                sx={{
                  fontFamily: 'Barlow',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '18px',
                  pb: '6px',
                  mt: '20px',
                }}>
                Select Client
              </Typography>
              <Select
                sx={{
                  height: '50px',
                }}
                fullWidth
                value={clientId}
                name={clientName}
                displayEmpty={true}
                onChange={handleSelectClient}>
                <option
                  style={{
                    height: '36px',
                    lineHeight: '36px',
                    fontWeight: 'bold',
                    color: theme.palette.mode === 'dark' ? '#fff' : '#5E5873',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    padding: '0 10px',
                  }}
                  value="">
                  CLIENT...
                </option>
                {clientList ? (
                  clientList.map((data: any) => (
                    <MenuItem key={data} value={data.value}>
                      <Box
                        sx={{
                          display: 'flex',
                        }}>
                        <Avatar
                          sx={{
                            width: '36px',
                            height: '36px',
                            marginRight: '10px',
                          }}
                          src={data.avatar}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}>
                          <Typography>{data.name}</Typography>
                          <Typography>{data.email}</Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>No Client Found</MenuItem>
                )}
              </Select>
            </>
            {permissionsData.map((data) => {
              return (
                <Stack spacing={1} key={data.key} sx={{ my: 2 }}>
                  <SinglePermission data={data} setPermissionRole={setPermissionRole} permissionRole={permissionRole} />
                </Stack>
              );
            })}
          </DialogContent>
        </Scrollbars>
        <Box sx={{ border: '0.5px solid #EDF1F1' }} />
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '24px',
            bgcolor: (theme) => theme.palette.background.paper,
          }}>
          <Button
            sx={{
              display: 'block',
              width: '88px',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              color: '#232323',
              marginRight: '8px',
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#9E9E9E',
                border: `1px solid ${theme.palette.divider}`,
                boxShadow: 'none',
              },
              '&:active': {
                boxShadow: 'none',
                backgroundColor: '#9E9E9E',
                border: `1px solid ${theme.palette.divider}`,
              },
              '&:focus': {
                boxShadow: '0 0 0 0.1rem #9E9E9E',
                border: `1px solid ${theme.palette.divider}`,
              },
            }}
            variant="contained"
            onClick={handleCloseModal}>
            Back
          </Button>
          <Button sx={{ display: 'block', width: '88px' }} variant="contained" onClick={() => handleSubmitForm()}>
            Save
          </Button>
        </DialogActions>
      </Typography>
    </Dialog>
  );
};
