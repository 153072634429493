import { ISoilMoistureContentAnalysis } from 'common/defines/analytic';
import { DEFAULT_BORDER_COLOR, ISettingsClientUI, SettingUIName } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { useClientData } from 'hooks/useClientData';
import { useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getSoilMoistureContentPagination } from 'services/analytics/apiAnalyticsData.services';
import { queryMe } from 'services/clients/apiClient.services';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { useAppSelector } from '../../../store/hooks';
import { mapViewSelector } from '../../../store/slices/mapViewSlice';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

interface IMetadata {
  nextPage: number | null;
  pageSize: number;
  pageCount: number;
  totalCount: number;
}

export const useSoilMoistureContent = () => {
  const [page, setPage] = useState<number>(1);
  const [soilMoistureContentData, setSoilMoistureContentData] = useState<ISoilMoistureContentAnalysis[]>([]);

  const { analyticId, isDisplayBoundaryLine, isLayer3D } = useAppSelector(mapViewSelector);
  useAppSelector(mapViewSelector);
  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();
  const { analyticName } = useAppSelector(rightBarSelector);
  const { clientSetting, colorAnalysisLayer } = useQueryClientSetting();
  const { contourSetting } = useClientData();

  const uiSetting = clientSetting?.uiSetting as ISettingsClientUI[];
  const strokeWidthValue = uiSetting && uiSetting.find((item) => item.name === SettingUIName.STROKE_WIDTH)?.value;

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const colorFromClientSetting = colorAnalysisLayer?.find(
    (item) => item.analysisId === analyticId && item.userId === userInfo?.data?._id
  )?.color;

  useQuery(
    [QUERY_KEY.GET_SOIL_MOISTURE_CONTENT_PAGINATION, page],
    () => getSoilMoistureContentPagination({ analysisId: analyticId!, page }),
    {
      enabled: !!analyticId && analyticName === TYPE_ANALYTICS_MAP_VIEW.SOIL_MOISTURE_CONTENT,
      keepPreviousData: true,
      onSuccess(res) {
        const resData = res.data?.data as ISoilMoistureContentAnalysis[];
        const metaData = res.data?.metadata as IMetadata;
        if (metaData?.nextPage) {
          setPage((prev) => prev + 1);
        }
        setSoilMoistureContentData((prev) => [...prev, ...resData]);
      },
    }
  );

  // reset soilMoistureContentData when analyticId = null
  useEffect(() => {
    if (!analyticId || analyticName !== TYPE_ANALYTICS_MAP_VIEW.SOIL_MOISTURE_CONTENT) {
      setSoilMoistureContentData([]);
      setPage(1);
    }
  }, [analyticId, analyticName]);

  const geojsonData: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: soilMoistureContentData.map((item) => ({
      type: 'Feature',
      properties: {},
      geometry: item.geometry,
    })) as GeoJSON.Feature[],
  };

  const layerSoilMoistureContent = soilMoistureContentData.length && (
    <Source id="SoilMoistureContent" key="SoilMoistureContent" type="geojson" data={geojsonData}>
      <Layer
        id={`polygon_SoilMoistureContent`}
        type="line"
        paint={{
          'line-color': colorFromClientSetting || DEFAULT_BORDER_COLOR,
          'line-opacity': 1,
          'line-width': strokeWidthValue || 2,
        }}
        layout={{ visibility: 'visible' }}
      />
      <Layer
        id={`SoilMoistureContent_fill-polygon`}
        type="fill"
        paint={{
          'fill-color': colorFromClientSetting || DEFAULT_BORDER_COLOR,
          'fill-opacity': !isLayer3D ? opacity2D : 0,
          'fill-antialias': true,
          'fill-translate-anchor': 'viewport',
        }}
        layout={{
          visibility: !isDisplayBoundaryLine ? 'visible' : 'none',
        }}
      />
      <Layer
        id={`SoilMoistureContent_top-surface`}
        type="fill-extrusion"
        beforeId={`SoilMoistureContent_fill-polygon`}
        paint={{
          'fill-extrusion-color': colorFromClientSetting || DEFAULT_BORDER_COLOR,
          'fill-extrusion-height': contourSetting,
          'fill-extrusion-base': contourSetting,
          'fill-extrusion-opacity': getTopSurfaceOpacity,
          'fill-extrusion-vertical-gradient': true,
        }}
        layout={{
          visibility: !isDisplayBoundaryLine ? 'visible' : 'none',
        }}
      />
      <Layer
        id={`SoilMoistureContent_side-surface`}
        type="fill-extrusion"
        beforeId={`SoilMoistureContent_top-surface`}
        paint={{
          'fill-extrusion-color': isSameColorTopSurface
            ? colorFromClientSetting || DEFAULT_BORDER_COLOR
            : colorTransparent,
          'fill-extrusion-height': contourSetting,
          'fill-extrusion-base': 0,
          'fill-extrusion-opacity': getOpacityExtrusion,
          'fill-extrusion-vertical-gradient': true,
        }}
        layout={{
          visibility: !isDisplayBoundaryLine ? 'visible' : 'none',
        }}
      />
    </Source>
  );

  return { layerSoilMoistureContent };
};
