import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAnalyticBoxPlot,
  getAnalyticPolygon,
  getDataPlantHealthOilPailmAnalytic,
  updateInterval,
} from 'services/analytics/apiAnalyticsConfig.services';
import { getChartsPlantHealthOilPailm } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeChartDataPlantHealthOilPailmPreview,
  changeDataPlantHealthOilPailmAnalytics,
  changeDataPlantHealthOilPailmInterval,
  changeDataPolygonPlantHealthOilPailmAnalytics,
  plantHealthOilPailmAnalyticsSelector,
} from 'store/slices/map-view/plantHealthOilPailmAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { PlantHealthCardLayer } from '../../Layer/PlantHealthOilPailm';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const PlantHealthOilPailmAnalytics = ({ uploadDate }: any) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { dataPlantHealthOilPailmInterval, dataPlantHealthOilPailmPolygon, chartDataPlantHealthOilPailmPreview } =
    useAppSelector(plantHealthOilPailmAnalyticsSelector);

  const { data: dataPlantHealthOilPailmBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.PLANT_HEALTH_OIL_PAILM_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'plant-health-oilpalm'),
    { enabled: !!analyticId }
  );

  const [currentPage, setCurrentPage] = useState(1);

  useQuery(
    [QUERY_KEY.PLANT_HEALTH_OIL_PAILM_POLYGON, analyticId, currentPage],
    () => getAnalyticPolygon(analyticId || '', 'plant-health-oilpalm', currentPage),
    {
      enabled: !!analyticId,
      onSuccess: (data: any) => {
        if (data.data?.nextPage && data.data.nextPage !== currentPage) {
          setCurrentPage(data.data?.nextPage);
        }
        if (data.data?.docs) {
          dispatch(
            changeDataPolygonPlantHealthOilPailmAnalytics([...dataPlantHealthOilPailmPolygon, ...data.data?.docs])
          );
        }
      },
    }
  );

  useQuery(
    [QUERY_KEY.PLANT_HEALTH_OIL_PAILM_ANALYTIC, analyticId],
    () => {
      return getDataPlantHealthOilPailmAnalytic(analyticId || '');
    },
    {
      enabled: !!analyticId,
      onSuccess: (mainData) => {
        dispatch(changeDataPlantHealthOilPailmAnalytics(mainData?.data || []));
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries([QUERY_KEY.CHARTS_PLANT_HEALTH_OIL_PAILM_ANALYTIC, isDefaultInterval])
          .then(() => {});
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_PLANT_HEALTH_OIL_PAILM_ANALYTIC, isDefaultInterval],
    () => getChartsPlantHealthOilPailm(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (data) => {
        dispatch(
          changeDataPlantHealthOilPailmInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataPlantHealthOilPailmInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataPlantHealthOilPailmBoxPlot?.data) return [];
    return dataPlantHealthOilPailmBoxPlot?.data;
  }, [dataPlantHealthOilPailmBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataPlantHealthOilPailmPreview
                      ? chartDataPlantHealthOilPailmPreview?.chartData
                      : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE}
                  getCharts={getChartsPlantHealthOilPailm}
                  intervalRange={
                    chartDataPlantHealthOilPailmPreview
                      ? chartDataPlantHealthOilPailmPreview?.intervalLimit?.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_PLANT_HEALTH_OIL_PAILM_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataPlantHealthOilPailmPreview}
                  showXAxis
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<PlantHealthCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
