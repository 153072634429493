import { ICropTypes } from 'common/defines/clients';
import { IEditWorkspace, IGenerateVector, IWorkspacePagination, Map2dOptionEnum } from 'interfaces/workspace';
import { sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest } from 'services';
import { AgeEnum } from './../../interfaces/workspace';

export const getListWorkspace = (data: IWorkspacePagination) => sendGetRequest('/workspace/pagination', data);

export const createNewWorkspace = (data: Omit<IEditWorkspace, '_id'>) => sendPostRequest('/workspace', data);

export const editWorkspaceInfo = (data: IEditWorkspace) => sendPatchRequest(`/workspace/${data._id}`, data);

export const deleteWorkspace = (id: string) => sendDeleteRequest(`/workspace/${id}`);

export const getWorkspaceInfo = (id: string) => sendGetRequest(`/workspace/${id}`);

export const handleRawImage = (data: FormData) => sendPostRequest('/field', data);

export const handleReconstructedImage = (data: FormData) => sendPostRequest('/field/handleReconstructedImage', data);

export const updateFieldInfo = (data: { fieldId: string; name: string; age: string; cropType: ICropTypes }) => {
  const { fieldId, name, age, cropType } = data;
  return sendPatchRequest(`/field/updateField/${fieldId}`, { name, age, cropType });
};

export const updateRawImage = (data: {
  _id: string;
  age: AgeEnum;
  cropType: ICropTypes;
  description: string;
  name: string;
  date: string;
}) => {
  const { _id, age, cropType, description, name, date } = data;
  return sendPatchRequest(`/field/updateRawImage/${_id}`, { age, cropType, description, name, date });
};

export const updateReconstructedImage = (data: { _id: string; formData: FormData }) => {
  const { _id, formData } = data;
  return sendPatchRequest(`/field/updateReconstructedImage/${_id}`, formData);
};

export const getFieldList = (data: IWorkspacePagination) => sendGetRequest('/field/pagination', data);

export const getFieldDetail = (_id: string) => sendGetRequest(`/field/${_id}`);

export const getWorkspaceAnalysisRaster = (fieldId: string) =>
  sendGetRequest(`/workspace-analysis/getWorkSpaceAnalysisRaster/${fieldId}`);

export const getMinMaxZoom = (projectId: string, taskId: string, layerType: Map2dOptionEnum) =>
  sendGetRequest(`/field/getMinMaxZoom/${projectId}/${taskId}`, { layerType });

export const getAvailableAsset = (projectId: string, taskId: string) =>
  sendGetRequest(`/field/getAvailableAsset/${projectId}/${taskId}`);

export const downloadAsset = (req: { projectId: string; taskId: string; asset: string }) => {
  const { projectId, taskId, asset } = req;
  return sendGetRequest(`/field/downloadAsset/${projectId}/${taskId}/${asset}`);
};

export const getView3D = (projectId: string, taskId: string) => {
  return sendGetRequest(`/field/3D/${projectId}/${taskId}`);
};

export const updateFieldBoundary = (data: { _id: string; geojson: GeoJSON.FeatureCollection | null }) => {
  const { _id, geojson } = data;
  return sendPatchRequest(`/field/updateRasterBoundary/${_id}`, { boundary: geojson });
};

export const stopFieldProgress = (fieldId: string) => {
  return sendPostRequest(`/field/stopFieldProcess`, { fieldId });
};

export const restartFieldProgress = (fieldId: string) => {
  return sendPostRequest(`/field/restartFieldProcess`, { fieldId });
};

export const deleteField = (fieldId: string) => {
  return sendDeleteRequest(`/field/${fieldId}`);
};

export const generateAnalysis = (taskId: string) => {
  return sendPostRequest(`/field/generateAnalysis`, { workspaceRasterId: taskId });
};

export const getListWorkspaceAnalysis = (fieldId: string) => {
  return sendGetRequest(`/workspace-analysis/getAnalysis/${fieldId}`);
};

export const getWorkspaceIntervalLimit = (fieldId: string, analysisId: string, isDefaultInterval: boolean) => {
  return sendGetRequest(`/workspace-analysis/getIntervalLimit/${fieldId}/${analysisId}/${isDefaultInterval}`);
};

export const getWorkspaceStandCountAnalysis = (analysisId: string) => {
  return sendGetRequest(`/workspace-stand-count/getAll/${analysisId}`);
};

export const getWorkspaceStandCountTotalTree = (analysisId: string) => {
  return sendGetRequest(`/workspace-stand-count/getTotalTree/${analysisId}`);
};

export const getWorkspaceCrownAreaPolygon = (analysisId: string, page: number) => {
  return sendGetRequest(`/workspace-analysis-crown-area/getPolygon`, {
    page,
    perPage: 500,
    sortDate: 'desc',
    paginationVesion: 2,
    analysisId,
  });
};

export const getChartsWorkspaceCrownArea = (analysisId: string, isDefaultInterval: boolean) => {
  return sendGetRequest(`/workspace-analysis-crown-area/getChart/${analysisId}/${isDefaultInterval}`);
};

export const getDataWorkspaceCrownAreaAnalytic = (analysisId: string) => {
  return sendGetRequest(`/workspace-analysis-crown-area/getAll/${analysisId}`);
};

export const getWorkspaceCrownAreaBoxPlot = (analysisId: string) => {
  return sendGetRequest(`/workspace-analysis-crown-area/getBoxPlot/${analysisId}`);
};

export const getWorkspaceCrownAreaLabel = (analysisId: string) => {
  return sendGetRequest(`/workspace-analysis-crown-area/getLabel/${analysisId}`);
};

export const getWorkspaceCircumferenceBoxPlot = (analysisId: string) => {
  return sendGetRequest(`/workspace-analysis-circumference/getBoxPlot/${analysisId}`);
};

export const getWorkspaceCircumferencePolygon = (analysisId: string, page: number) => {
  return sendGetRequest(`/workspace-analysis-circumference/getPolygon`, {
    page,
    perPage: 500,
    sortDate: 'desc',
    paginationVesion: 2,
    analysisId,
  });
};

export const getDataWorkspaceCircumferenceAnalytic = (analysisId: string) => {
  return sendGetRequest(`/workspace-analysis-circumference/getAll/${analysisId}`);
};

export const getChartsWorkspaceCircumference = (analysisId: string, isDefaultInterval: boolean) => {
  return sendGetRequest(`/workspace-analysis-circumference/getChart/${analysisId}/${isDefaultInterval}`);
};

export const getWorkspaceCircumferenceLabel = (analysisId: string) => {
  return sendGetRequest(`/workspace-analysis-circumference/getLabel/${analysisId}`);
};

export const deleteAiDetection = (fieldId: string) => {
  return sendDeleteRequest(`/field/deleteAIDetection/${fieldId}`);
};

export const generateStreamlineOrderIntelligent = (_id: string) => {
  return sendGetRequest(`/workspace-streamline-order/generate/${_id}`);
};

export const getAllStreamlineOrderIntelligent = (_id: string) => {
  return sendGetRequest(`/workspace-streamline-order/getAllStreamLineOrder/${_id}`);
};

export const deleteStreamlineOrderIntelligent = (_id: string) => {
  return sendDeleteRequest(`/workspace-streamline-order/${_id}`);
};

export const updateStreamlineOrderIntelligentColor = (data: { _id: string; order: number; colorCode: string }) => {
  const { _id, order, colorCode } = data;
  return sendPatchRequest(`/workspace-streamline-order/updateStreamLineOrderColor/${_id}`, { order, colorCode });
};

export const getChartStreamlineOrderIntelligent = (_id: string) => {
  return sendGetRequest(`/workspace-streamline-order/getChart/${_id}`);
};

export const deleteTasks = ({ idList }: { idList: string[] }) => {
  return sendDeleteRequest(`/field/deleteRaster`, {
    data: {
      rasterIds: idList,
    },
  });
};

export const generateVectorContour = (data: IGenerateVector) => {
  return sendPostRequest(`/field/generateContour`, data);
};

export const getVectorContourIntervalIntelligent = (_id: string) => {
  return sendGetRequest(`/workspace-contour/getInterval/${_id}`);
};

export const getAllVectorContourIntelligent = (_id: string, size: string) => {
  return sendGetRequest(`/workspace-contour/getAll/${_id}/${size}`);
};

export const deleteVectorContourIntelligent = (_id: string) => {
  return sendDeleteRequest(`/workspace-contour/delete/${_id}`);
};
