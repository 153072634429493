import { Box, Tab, Tabs } from '@mui/material';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { QUERY_KEY } from 'constants/constants';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUIDisplay, queryMe } from 'services/clients/apiClient.services';
import { useAppDispatch } from 'store/hooks';
import { changeBreadScrumbsSelected } from 'store/slices/breadcrumbsSlice';

const styles = {
  positionsCenter: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: 'none',
    p: 'auto',
  },

  textStyles: {
    textTransform: 'none !important',
    fontFamily: 'Barlow !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '16px !important',
    m: '0 30px !important',
    // color: '#3C4043 !important',
  },
};

const Navbars = [
  'Overview',
  'Data Configuration',
  'User Management',
  'Activity',
  'Client Settings',
  'Audit Trail',
  'Client Apis',
];

interface ITabsBar {
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
}

export const TabsBar = ({ tab, setTab }: ITabsBar) => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const { t } = useTranslation();

  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);

  const { data: userInfoByClient } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && userId !== '' && !isSuperAdmin && !isSuperUser,
    }
  );

  const flagTabData = useMemo(() => {
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser) {
      return Navbars;
    }
    if (!userInfoByClient?.data) return [];
    const dataRoleValue = userInfoByClient?.data?.flagTab;
    return dataRoleValue;
  }, [userInfo, userInfoByClient]);

  const handleChange = (event: React.SyntheticEvent, newValue: React.SetStateAction<string>) => {
    setTab(newValue);
    dispatch(changeBreadScrumbsSelected(newValue));
  };

  const newNavbars = React.useMemo(() => {
    const newNavbarsData: string[] = [];
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser || flagTabData === undefined) {
      return flagTabData;
    } else {
      Navbars.forEach((item: any) => {
        switch (item) {
          case 'Overview':
            if (flagTabData.includes('DASHBOARD-OVERVIEW')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'User Management':
            if (flagTabData.includes('DASHBOARD-USER-MANAGEMENT')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Data Configuration':
            if (flagTabData.includes('DASHBOARD-DATA-CONFIGURATION')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Activity':
            if (flagTabData.includes('DASHBOARD-ACTIVITY')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Client Settings':
            if (flagTabData.includes('DASHBOARD-CLIENT-SETTINGS')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Audit Trail':
            if (flagTabData.includes('DASHBOARD-AUDIT-TRAIL')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Client Apis':
            if (flagTabData.includes('DASHBOARD-CLIENT-APIS')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          default:
            return '';
        }
      });
      return newNavbarsData;
    }
  }, [flagTabData, userInfo]);

  return (
    <Box
      sx={{
        ...styles.positionsCenter,
        position: 'relative',
        backgroundColor: (theme) => theme.palette.background.paper,
        borderBottom: (theme) => theme.palette.divider,
      }}>
      <Tabs value={tab} onChange={handleChange} textColor="primary" indicatorColor="primary">
        {!newNavbars || newNavbars.length === 0 ? (
          <></>
        ) : (
          newNavbars.map((navbar: string) => (
            <Tab
              sx={{ ...styles.textStyles, color: (theme) => (theme.palette.mode === 'dark' ? '#FFFFFF' : '#000') }}
              key={navbar}
              label={t(convertTextToMultiLanguage(navbar, 'dashboard'))}
              value={navbar}
            />
          ))
        )}
      </Tabs>
    </Box>
  );
};
