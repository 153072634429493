import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  List,
  Typography,
  useTheme,
} from '@mui/material';
import { ModalMessageInUserManagement, TypeModalDelete } from 'common/defines/clients';
import Scrollbars from 'react-custom-scrollbars';

type ErrorsDialogProps = {
  title: string;
  data: any;
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  cancelLabel?: string;
  saveLabel?: string;
  submit: boolean;
  clientAt?: TypeModalDelete;
  clientName?: string;
  userManagementAction?: ModalMessageInUserManagement;
} & DialogProps;

export const ErrorsDialog = ({
  title,
  data,
  open,
  onClose,
  onCancel,
  cancelLabel = 'Close',
  submit,
  clientAt,
  clientName,
  userManagementAction,
  ...props
}: ErrorsDialogProps) => {
  const theme = useTheme();

  const styles = {
    modalStyles: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      bgColor: theme.palette.background.paper,
    },
    btnCloseModal: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: '#9E9E9E',
      maxWidth: '60px',
      p: '10px 0',
    },
    iconWarningModal: {
      width: '50px',
      height: '50px',
      mt: '40px',
      borderRadius: '50%',
      alignSelf: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleModal: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#5E5873',
      pb: '31px',
    },
    descriptionModal: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#737373',
    },
  };

  return (
    <Dialog
      {...props}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm">
      <Box
        sx={{
          ...styles.modalStyles,
          background: theme.palette.background.paper,
        }}>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            p: 0,
            position: 'absolute',
            right: 8,
            top: 8,
            '.MuiSvgIcon-root': {
              width: '16px',
              height: '16px',
            },
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            ...styles.iconWarningModal,
            color: submit ? (theme) => theme.palette.primary.main : '#DA3E3E',
            backgroundColor: submit ? 'rgba(35, 190, 106, 0.15)' : 'rgba(255, 70, 70, 0.15)',
          }}>
          {submit ? (
            <CheckCircleOutlineRoundedIcon
              sx={{
                alignSelf: 'center',
                fontSize: '26px',
              }}
            />
          ) : (
            <ReportProblemRoundedIcon
              sx={{
                alignSelf: 'center',
                fontSize: '26px',
              }}
            />
          )}
        </Typography>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            ...styles.titleModal,
            background: theme.palette.background.paper,
          }}>
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            background: theme.palette.background.paper,
            width: '100%',
          }}>
          <Box sx={{ height: 'fit-content' }}>
            <Scrollbars style={{ height: '300px' }} autoHide>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {data && Array.isArray(data) ? (
                  data.map((item, i) => (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }} key={i}>
                      <div>{item?.error}</div>
                    </Box>
                  ))
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
                    <div>{data}</div>
                  </Box>
                )}
              </List>
            </Scrollbars>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            background: theme.palette.background.paper,
          }}>
          {onCancel && (
            <Button
              color="neutral"
              variant="outlined"
              sx={{
                minWidth: '240px',
                height: '40px',
                borderRadius: '5px',
                p: '12px 10px',
                mb: '30px',
                textTransform: 'none',
                border: `1px solid ${theme.palette.divider}`,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              }}
              onClick={onCancel}>
              {cancelLabel}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};
