import { useTheme } from '@mui/material';
import { useClientData } from 'hooks/useClientData';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { IIssue, LineStyleEnum } from '../../interfaces';

interface ShowPolygonLayerProps {
  item: IIssue;
}

const ShowPolygonLayer: FC<ShowPolygonLayerProps> = ({ item }) => {
  const theme = useTheme();
  const { contourSetting } = useClientData();
  const {
    issuesTab: { polygon3dList },
  } = useAppSelector(rightBarSelector);
  const foundPolygon3d = polygon3dList.find((ele) => ele.id === item._id);
  const {
    isLayer3D,
    isDisplayBoundaryLine,
    sideSurfaceOpacity,
    isSameColorTopSurface,
    colorSideSurface,
    isSameSideSurface,
    topSurfaceOpacity,
  } = foundPolygon3d || {};

  const defaultBorder = theme.palette.primary.main;
  const key = item._id;

  const geojson: any = {
    type: 'FeatureCollection',
    features: [{ type: 'Feature', geometry: item.geometry }],
    properties: {
      color: item.border?.color,
    },
  };

  const checkIsDisplayBoundaryLine = () => {
    if (typeof isDisplayBoundaryLine === 'undefined') {
      return true;
    } else return isDisplayBoundaryLine;
  };

  return (
    <Source id={`${key}_source_polygon`} key={`${key}_source_polygon`} type="geojson" data={geojson}>
      {/* 2d contour layer */}
      <Layer
        id={`${key}_border_polygon`}
        key={`${key}_border_polygon`}
        type="line"
        source={`${key}_source_polygon`}
        paint={{
          'line-color': item.border?.color || defaultBorder,
          'line-opacity': 0.8,
          'line-width': item.border?.width || 2,
          'line-dasharray': item.border?.style === LineStyleEnum.DASHED ? [3, 2] : [1, 0],
        }}
        layout={{ visibility: checkIsDisplayBoundaryLine() ? 'visible' : 'none' }}
      />

      {/* 2d fill layer */}
      <Layer
        id={`${key}_2d_fill-polygon`}
        type="fill"
        paint={{
          'fill-color': item.border?.color || defaultBorder,
          'fill-opacity': topSurfaceOpacity || 1,
        }}
        layout={{ visibility: !checkIsDisplayBoundaryLine() && !isLayer3D ? 'visible' : 'none' }}
      />

      {/* 3d fill layer */}
      <Layer
        id={`${key}_fill-polygon`}
        type="fill"
        paint={{
          'fill-color': item.border?.color,
          'fill-opacity': 0,
        }}
        source={`${key}-source`}
        layout={{ visibility: !checkIsDisplayBoundaryLine() && isLayer3D ? 'visible' : 'none' }}
      />
      <Layer
        id={`${key}_top-surface`}
        type="fill-extrusion"
        beforeId={`${key}_fill-polygon`}
        source={`${key}_source`}
        filter={['!=', 'color', 'transparent']}
        paint={{
          'fill-extrusion-color': item.border?.color,
          'fill-extrusion-height': contourSetting,
          'fill-extrusion-base': contourSetting,
          'fill-extrusion-opacity': !!isSameSideSurface ? sideSurfaceOpacity || 1 : 1,
          'fill-extrusion-vertical-gradient': false,
        }}
        layout={{ visibility: !checkIsDisplayBoundaryLine() && isLayer3D ? 'visible' : 'none' }}
      />
      <Layer
        id={`${key}_side-surface`}
        type="fill-extrusion"
        beforeId={`${key}_top-surface`}
        source={`${key}_source`}
        filter={['!=', 'color', 'transparent']}
        paint={{
          'fill-extrusion-color': !!isSameColorTopSurface
            ? item.border?.color
            : colorSideSurface || 'rgb(255, 255, 255)',
          'fill-extrusion-height': contourSetting,
          'fill-extrusion-base': 0,
          'fill-extrusion-opacity': sideSurfaceOpacity || 1,
          'fill-extrusion-vertical-gradient': false,
        }}
        layout={{ visibility: !checkIsDisplayBoundaryLine() && isLayer3D ? 'visible' : 'none' }}
      />
    </Source>
  );
};

export default ShowPolygonLayer;
