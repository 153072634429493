import { isEmpty, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useAppSelector } from '../../../store/hooks';
import { mapViewSelector } from '../../../store/slices/mapViewSlice';
import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

export const useWeedInvasionAnalytics = ({ mapRef }: any) => {
  const { dataWeedInvasion, isDisplayBoundaryLine, isLayer3D } = useAppSelector(mapViewSelector);

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();

  const { contourSetting } = useClientData();

  const polygonWeedInvasionViewer = useCallback(
    (_item: any) => (
      <Source id={`${_item._id}_WeedInvasion`} key={`${_item._id}_WeedInvasion`} type="geojson" data={_item.geometry}>
        <Layer
          id={`${_item._id}-polygon_WeedInvasion`}
          type="line"
          paint={{
            'line-color': _item.color,
            'line-opacity': isDisplayBoundaryLine ? 1 : 0,
            'line-width': 2,
          }}
          // source={`${_item._id}_WeedInvasion`}
          layout={{ visibility: _item.visible ? 'visible' : 'none' }}
        />
        <Layer
          id={`${_item._id}_WeedInvasion_fill-polygon`}
          type="fill"
          paint={{
            'fill-color': _item.color,
            'fill-opacity': !isLayer3D ? opacity2D : 0,
            'fill-antialias': true,
            'fill-translate-anchor': 'viewport',
          }}
          // source={`${_item._id}_WeedInvasion`}
          layout={{ visibility: _item.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
        />
        <Layer
          id={`${_item._id}_WeedInvasion_top-surface`}
          type="fill-extrusion"
          // source={`${_item._id}_WeedInvasion`}
          beforeId={`${_item._id}_WeedInvasion_fill-polygon`}
          paint={{
            'fill-extrusion-color': _item.color,
            'fill-extrusion-height': contourSetting,
            'fill-extrusion-base': contourSetting,
            'fill-extrusion-opacity': getTopSurfaceOpacity,
            'fill-extrusion-vertical-gradient': true,
          }}
          layout={{ visibility: _item.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
        />
        <Layer
          id={`${_item._id}_WeedInvasion_side-surface`}
          type="fill-extrusion"
          beforeId={`${_item._id}_WeedInvasion_top-surface`}
          // source={`${_item._id}_WeedInvasion`}
          paint={{
            'fill-extrusion-color': isSameColorTopSurface ? _item.color : colorTransparent,
            'fill-extrusion-height': contourSetting,
            'fill-extrusion-base': 0,
            'fill-extrusion-opacity': getOpacityExtrusion,
            'fill-extrusion-vertical-gradient': true,
          }}
          layout={{ visibility: _item.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
        />
      </Source>
    ),
    [
      colorTransparent,
      contourSetting,
      getOpacityExtrusion,
      getTopSurfaceOpacity,
      isDisplayBoundaryLine,
      isLayer3D,
      opacity2D,
      isSameColorTopSurface,
    ]
  );

  const layerWeedInvasion = useMemo(() => {
    if (isEmpty(dataWeedInvasion)) {
      return null;
    }
    return <>{sortBy(dataWeedInvasion, 'type').map((_item: any) => polygonWeedInvasionViewer(_item))}</>;
  }, [dataWeedInvasion, polygonWeedInvasionViewer]);

  return { layerWeedInvasion };
};
