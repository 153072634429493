import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDataInterval, IRange } from 'common/defines/clients';
import { RootState } from 'store/store';

interface IUserSlice {
  dataCo2SequestrationInterval: IDataInterval;
  isShowAllLayer: boolean;
  chartDataCo2SequestrationPreview: any;
}

const initialState: IUserSlice = {
  dataCo2SequestrationInterval: {
    _id: '',
    isDefault: true,
    range: [],
  },
  isShowAllLayer: true,
  chartDataCo2SequestrationPreview: null,
};

export const co2SequestrationAnalyticsSlice = createSlice({
  name: 'co2SequestrationAnalytic',
  initialState,
  reducers: {
    clearCo2SequestrationAnalytics: () => {
      return initialState;
    },
    changeVisibleIntervalCo2Sequestration: (state, action: PayloadAction<number>) => {
      state.dataCo2SequestrationInterval.range = state.dataCo2SequestrationInterval.range.map((item) =>
        item.key === action.payload ? { ...item, visible: !item.visible } : item
      );
      state.isShowAllLayer = state.dataCo2SequestrationInterval.range.every((item) => item.visible);
    },
    changeVisibleIntervalCo2SequestrationAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataCo2SequestrationInterval.range = state.dataCo2SequestrationInterval.range.map((range) => ({
        ...range,
        visible: action.payload,
      }));
    },
    changeDataCo2SequestrationInterval: (state, action: PayloadAction<Partial<IDataInterval>>) => {
      state.dataCo2SequestrationInterval = { ...state.dataCo2SequestrationInterval, ...action.payload };
    },
    changeChartDataCo2SequestrationPreview: (state, action: PayloadAction<{ range: IRange[] } | null>) => {
      if (!action.payload) return;
      state.dataCo2SequestrationInterval.range = action.payload.range.map((item, index) => ({
        ...item,
        key: index + 1,
      }));
    },
  },
});

export const {
  clearCo2SequestrationAnalytics,
  changeDataCo2SequestrationInterval,
  changeChartDataCo2SequestrationPreview,
  changeVisibleIntervalCo2Sequestration,
  changeVisibleIntervalCo2SequestrationAllLayers,
} = co2SequestrationAnalyticsSlice.actions;

export const co2SequestrationAnalyticsSelector = (state: RootState) => state.co2SequestrationAnalytic;

export default co2SequestrationAnalyticsSlice.reducer;
