import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { Typography } from '@mui/material';
import { Marker } from 'react-map-gl';

export const PointPolygon = ({ item }: any) => {
  return (
    <>
      <Marker
        key={`${item._id}-point-polygon`}
        longitude={item.geometry.coordinates[0]}
        latitude={item.geometry.coordinates[1]}
        anchor={'center'}
        // style={{ zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        style={{ zIndex: 10 }}>
        <GpsFixedIcon fontSize={'small'} />
      </Marker>
      <Marker
        key={`${item._id}-point-label`}
        longitude={item.geometry.coordinates[0]}
        latitude={item.geometry.coordinates[1]}
        anchor={'center'}
        style={{ zIndex: 10 }}
        offset={[0, -22]}>
        <Typography
          component="span"
          style={{
            width: 'fit-content',
            height: '24px',
            display: 'flex',
            padding: '0 10px',
            color: 'black',
            cursor: 'pointer',
          }}>
          {item.pointType}
        </Typography>
      </Marker>
    </>
  );
};
