import { ISettingCropType, ISettingsDataSensor } from 'common/defines/clients';
import { sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest } from 'services';

export const getSettingsDataAnalytics = async (
  page: number,
  perPage: number,
  keyword: string | null,
  analysisArea: string | null
) => {
  return sendGetRequest(
    `/data-analysis-setting/pagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=desc&paginationVersion=2&analysisArea=${analysisArea}`
  );
};
export const createUserAnalyticSettings = async (data: ISettingsDataSensor) => {
  return sendPostRequest('/data-analysis-setting', { ...data });
};
export const updateUserAnalyticSettings = async (data: ISettingsDataSensor) => {
  return sendPatchRequest(`/data-analysis-setting/${data.id}`, { ...data });
};

export const deleteSettingByIdReactQuery = (id: string) => {
  return sendDeleteRequest(`/data-analysis-setting/${id}`);
};

export const getUserAnalyticSettingsById = async (id: string) => {
  return sendGetRequest(`/data-analysis-setting/${id}`);
};

export const getSettingsDataCroptype = async (page: number, perPage: number, keyword: string | null) => {
  return sendGetRequest(
    `/crop-type/pagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=desc&paginationVersion=2`
  );
};
export const getAllSettingsDataCroptype = async () => {
  return sendGetRequest(`/crop-type`);
};
export const getSettingsDataCroptypeById = async (id: string) => {
  return sendGetRequest(`/crop-type/${id}`);
};
export const createCropTypeSettings = async (data: ISettingCropType) => {
  return sendPostRequest('/crop-type', { ...data });
};
export const updateCropTypeSettings = async (data: ISettingCropType) => {
  return sendPatchRequest(`/crop-type/${data.id}`, { ...data });
};
export const deleteCropTypeSettingsById = (id: string) => {
  return sendDeleteRequest(`/crop-type/${id}`);
};
