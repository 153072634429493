import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Feature } from '@turf/turf';
import DrawControl from 'components/Common/DrawControl';
import { QUERY_KEY } from 'constants/constants';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeDrawIssueShape, changeShowIssueModal, rightBarSelector } from 'store/slices/rightBarSlice';
import { DrawIssueShapeEnum } from '../interfaces';

let initialControls = {
  point: false,
  line_string: false,
  polygon: false,
  trash: true,
};

const DrawIssueShape = () => {
  const [features, setFeatures] = useState({});
  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    issuesTab: { drawIssueShape },
  } = useAppSelector(rightBarSelector);
  const { isLockMap } = drawIssueShape;

  const onUpdate = useCallback((e: { features: any }) => {
    setFeatures((currFeatures) => {
      const newFeatures: any = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e: { features: any }) => {
    setFeatures((currFeatures) => {
      const newFeatures: any = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const convertTypeToKey = (type: DrawIssueShapeEnum) => {
    if (type === DrawIssueShapeEnum.LINE) {
      return 'line_string';
    }
    return type.toLocaleLowerCase();
  };

  const handleControlsValue = () => {
    const { type } = drawIssueShape;

    const controls = type
      ? {
          ...initialControls,
          [convertTypeToKey(type)]: true,
        }
      : initialControls;

    return controls;
  };

  const handleDrawDone = () => {
    const featureList = Object.values(features);
    const drawObjectInfo: Feature<any> = featureList[featureList.length - 1] as Feature<any>;
    const geometry = drawObjectInfo.geometry;
    dispatch(changeDrawIssueShape({ ...drawIssueShape, isShow: false, geometry, isLockMap: false }));
    dispatch(changeShowIssueModal(true));
  };

  const handleCancelDraw = () => {
    dispatch(
      changeDrawIssueShape({ type: drawIssueShape.type, isShow: false, initialData: { id: '' }, isLockMap: false })
    );
    queryClient.invalidateQueries([QUERY_KEY.GET_LIST_DISPLAYED_ISSUES]);
  };

  const handleLockMap = () => {
    dispatch(changeDrawIssueShape({ ...drawIssueShape, isLockMap: !isLockMap }));
  };

  return (
    <Box>
      {drawIssueShape.isShow && (
        <Box>
          <Box
            sx={{
              position: 'absolute',
              left: '60px',
              backgroundColor: theme.palette.background.paper,
            }}>
            <Typography sx={{ display: 'inline-block', px: '8px' }}>Click here after finish drawing</Typography>
            <Button variant="outlined" size="small" onClick={handleDrawDone}>
              Done
            </Button>
            {drawIssueShape.initialData?.id && (
              <Button variant="outlined" size="small" color="error" onClick={handleCancelDraw} sx={{ ml: '8px' }}>
                Cancel
              </Button>
            )}
          </Box>
          <Box
            style={{
              width: '29px',
              height: '29px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              cursor: 'pointer',
              borderRadius: '0px 0px 4px 4px',
              position: 'absolute',
              color: '#000000',
              top: '68px',
              left: '22px',
              boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
              borderTop: '1px solid #ddd',
            }}
            onClick={handleLockMap}>
            {isLockMap ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
          </Box>
          <DrawControl
            position="top-left"
            displayControlsDefault={false}
            controls={handleControlsValue()}
            defaultMode={'simple_select'}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        </Box>
      )}
    </Box>
  );
};

export default DrawIssueShape;
