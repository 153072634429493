import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { circle, point } from '@turf/turf';
import { sizeByZoomLevel } from 'common/utils/util';
import update from 'immutability-helper';
import { RootState } from 'store/store';

interface IUserSlice {
  dataPlantHealthOilPailm: any;
  dataPlantHealthOilPailmPolygon: any;
  dataPlantHealthOilPailmInterval: any;
  isShowAllLayer: boolean;
  dataGeoJsonPoint: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  dataGeoJsonPolygon: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  zoomLayer: number;
  configSizePoint: any;
  sizeFixedPoint: number;
  chartDataPlantHealthOilPailmPreview: any;
}

const initialState: IUserSlice = {
  dataPlantHealthOilPailm: null,
  dataPlantHealthOilPailmPolygon: [],
  dataPlantHealthOilPailmInterval: null,
  isShowAllLayer: true,
  dataGeoJsonPoint: undefined,
  dataGeoJsonPolygon: undefined,
  zoomLayer: 16,
  configSizePoint: null,
  sizeFixedPoint: 0.0015,
  chartDataPlantHealthOilPailmPreview: null,
};

export const plantHealthOilPailmAnalyticsSlice = createSlice({
  name: 'plantHealthOilPailmAnalytic',
  initialState,
  reducers: {
    clearPlantHealthOilPailmAnalytics: () => {
      return initialState;
    },
    changeDataPlantHealthOilPailmAnalytics: (state, action: PayloadAction<any>) => {
      state.dataPlantHealthOilPailm = action.payload;
      if (!state.dataPlantHealthOilPailmInterval || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((plantHealthOilPailmInstance: any) => {
          let color = 'black';
          const plantHealthOilPailmInterval = state.dataPlantHealthOilPailmInterval?.range?.find(
            (item: any, index: number) =>
              item.from <= plantHealthOilPailmInstance.phIn &&
              (index === state.dataPlantHealthOilPailmInterval?.range?.length - 1
                ? item.to >= plantHealthOilPailmInstance.phIn
                : item.to > plantHealthOilPailmInstance.phIn)
          );
          if (plantHealthOilPailmInterval?.visible) {
            color = plantHealthOilPailmInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(plantHealthOilPailmInstance.longX || 0), Number(plantHealthOilPailmInstance.latY || 0)], {
              ...plantHealthOilPailmInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeVisibleIntervalPlantHealthOilPailm: (state, action: PayloadAction<number>) => {
      const currentInterval = state.chartDataPlantHealthOilPailmPreview
        ? state.chartDataPlantHealthOilPailmPreview.intervalLimit
        : state.dataPlantHealthOilPailmInterval;
      state.isShowAllLayer = currentInterval?.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      currentInterval.range = update(currentInterval?.range, {
        [action.payload]: {
          visible: { $set: !currentInterval.range[action.payload].visible },
        },
      });

      if (state.dataPlantHealthOilPailm) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantHealthOilPailm.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.phIn &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.phIn
                  : item.to > vigorInstance.phIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataPlantHealthOilPailmPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantHealthOilPailmPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.phIn &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.phIn
                  : item.to > vigorInstance.phIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeVisibleIntervalPlantHealthOilPailmAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      if (state.dataPlantHealthOilPailmInterval) {
        state.dataPlantHealthOilPailmInterval.range = update(state.dataPlantHealthOilPailmInterval.range, {
          $apply: (intervalRange: Array<any>) =>
            intervalRange.map((item: any) => {
              return {
                ...item,
                visible: action.payload,
              };
            }),
        });
      }
      if (state.dataPlantHealthOilPailm) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantHealthOilPailm.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataPlantHealthOilPailmInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.phIn &&
                (index === state.dataPlantHealthOilPailmInterval?.range?.length - 1
                  ? item.to >= vigorInstance.phIn
                  : item.to > vigorInstance.phIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataPlantHealthOilPailmPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantHealthOilPailmPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataPlantHealthOilPailmInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.phIn &&
                (index === state.dataPlantHealthOilPailmInterval?.range?.length - 1
                  ? item.to >= vigorInstance.phIn
                  : item.to > vigorInstance.phIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeDataPlantHealthOilPailmInterval: (state, action: PayloadAction<any>) => {
      state.dataPlantHealthOilPailmInterval = action.payload;
      if (state.dataPlantHealthOilPailmPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantHealthOilPailmPolygon.map((plantHealthOilPailmInstance: any) => {
            let color = 'black';
            const plantHealthOilPailmInterval = state.dataPlantHealthOilPailmInterval.range?.find(
              (item: any, index: number) =>
                item.from <= plantHealthOilPailmInstance.phIn &&
                (index === state.dataPlantHealthOilPailmInterval?.range?.length - 1
                  ? item.to >= plantHealthOilPailmInstance.phIn
                  : item.to > plantHealthOilPailmInstance.phIn)
            );
            if (plantHealthOilPailmInterval?.visible) {
              color = plantHealthOilPailmInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...plantHealthOilPailmInstance.geometry },
              properties: {
                ...plantHealthOilPailmInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
      if (!state.dataPlantHealthOilPailm || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: state.dataPlantHealthOilPailm.map((plantHealthOilPailmInstance: any) => {
          let color = 'black';
          const vigorInterval = action.payload?.range?.find(
            (item: any, index: number) =>
              item.from <= plantHealthOilPailmInstance.phIn &&
              (index === action.payload?.range?.length - 1
                ? item.to >= plantHealthOilPailmInstance.phIn
                : item.to > plantHealthOilPailmInstance.phIn)
          );
          if (vigorInterval?.visible) {
            color = vigorInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(plantHealthOilPailmInstance.longX || 0), Number(plantHealthOilPailmInstance.latY || 0)], {
              ...plantHealthOilPailmInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeDataPolygonPlantHealthOilPailmAnalytics: (state, action: PayloadAction<any>) => {
      state.dataPlantHealthOilPailmPolygon = action.payload;
      if (!state.dataPlantHealthOilPailmInterval) {
        return;
      }
      const geoJson = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((plantHealthOilPailmInstance: any) => {
          let color = 'black';
          const plantHealthOilPailmInterval = state.dataPlantHealthOilPailmInterval.range?.find(
            (item: any, index: number) =>
              item.from <= plantHealthOilPailmInstance.phIn &&
              (index === state.dataPlantHealthOilPailmInterval?.range?.length - 1
                ? item.to >= plantHealthOilPailmInstance.phIn
                : item.to > plantHealthOilPailmInstance.phIn)
          );
          if (plantHealthOilPailmInterval?.visible) {
            color = plantHealthOilPailmInterval.color;
          } else color = 'transparent';

          return {
            geometry: { ...plantHealthOilPailmInstance.geometry },
            properties: {
              ...plantHealthOilPailmInstance.properties,
              color: color,
            },
          };
        }),
      };
      state.dataGeoJsonPolygon = geoJson;
    },

    changeZoomLayerPlantHealthOilPailm: (state, action: PayloadAction<number>) => {
      state.zoomLayer = action.payload;
      const zoomLevel = action.payload;
      const currentRange = state.chartDataPlantHealthOilPailmPreview
        ? state.chartDataPlantHealthOilPailmPreview.intervalLimit?.range
        : state.dataPlantHealthOilPailmInterval?.range;

      if (state.dataPlantHealthOilPailm) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantHealthOilPailm.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentRange?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.phIn &&
                (index === currentRange?.length - 1 ? item.to >= vigorInstance.phIn : item.to > vigorInstance.phIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              sizeByZoomLevel(zoomLevel),
              { steps: 32 }
            );
          }),
        };
      }
    },
    changeFixedSizePoint: (state, action: PayloadAction<any>) => {
      state.sizeFixedPoint = action.payload;
    },
    changeChartDataPlantHealthOilPailmPreview: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        const intervalLimit = {
          ...state.dataPlantHealthOilPailmInterval,
          range: action.payload.range,
        };
        const chartData: any = [];
        action.payload.range.forEach((item: any) => {
          const listByRange = state.dataPlantHealthOilPailm.filter(
            (chlorophyll: any) => chlorophyll.phIn >= item.from && chlorophyll.phIn <= item.to
          );
          chartData.push({ [item.lable]: listByRange.length });
        });
        let dataPreview = { chartData, intervalLimit };

        state.chartDataPlantHealthOilPailmPreview = dataPreview;

        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantHealthOilPailm.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.chartDataPlantHealthOilPailmPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.phIn &&
                (index === state.chartDataPlantHealthOilPailmPreview?.intervalLimit?.range?.length - 1
                  ? item.to >= vigorInstance.phIn
                  : item.to > vigorInstance.phIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
        state.dataGeoJsonPolygon = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantHealthOilPailmPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.chartDataPlantHealthOilPailmPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.phIn &&
                (index === state.chartDataPlantHealthOilPailmPreview?.intervalLimit?.range?.length - 1
                  ? item.to >= vigorInstance.phIn
                  : item.to > vigorInstance.phIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
      } else {
        state.chartDataPlantHealthOilPailmPreview = null;
      }
    },
  },
});

export const {
  clearPlantHealthOilPailmAnalytics,
  changeDataPlantHealthOilPailmAnalytics,
  changeDataPlantHealthOilPailmInterval,
  changeVisibleIntervalPlantHealthOilPailm,
  changeVisibleIntervalPlantHealthOilPailmAllLayers,
  changeDataPolygonPlantHealthOilPailmAnalytics,
  changeZoomLayerPlantHealthOilPailm,
  changeFixedSizePoint,
  changeChartDataPlantHealthOilPailmPreview,
} = plantHealthOilPailmAnalyticsSlice.actions;

export const plantHealthOilPailmAnalyticsSelector = (state: RootState) => state.plantHealthOilPailmAnalytic;

export default plantHealthOilPailmAnalyticsSlice.reducer;
