import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import ChangePassword from './TabProfile/ChangePassword/ChangePassword';
import ProfileUser from './TabProfile/ProfileUser';

const styles = {
  tabControlUser: {
    flexGrow: 1,
    display: 'flex',
    '.MuiButtonBase-root': {
      justifyContent: 'start',
    },
  },
  tabsList: {
    borderRight: 1,
    borderColor: 'divider',
    minWidth: '220px',
  },
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        width: '100%',
      }}
      {...other}>
      {value === index && (
        <Box sx={{ p: '0 20px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function TabControlUser() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        ...styles.tabControlUser,
      }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{
          ...styles.tabsList,
        }}>
        <Tab icon={<PersonOutlineRoundedIcon />} iconPosition="start" label="Profile" />
        <Tab icon={<LockResetRoundedIcon />} iconPosition="start" label="Change Password" />
      </Tabs>
      {/* Profile */}
      <TabPanel value={value} index={0}>
        <ProfileUser />
      </TabPanel>
      {/* Change Password */}
      <TabPanel value={value} index={1}>
        <ChangePassword />
      </TabPanel>
    </Box>
  );
}
