import { ICreateHierarchyStructure, IUpdateHierarchyStructureDto } from 'common/defines/clients';
import { useMutation } from 'react-query';
import {
  createLevelCropType,
  deleteLevelCropTypeById,
  sendPatchHierarchyStructure,
} from 'services/clients/apiClient.services';

export const useCreateTreeLevel = () => {
  return useMutation((param: ICreateHierarchyStructure) =>
    createLevelCropType(param.parent, param.clientId, param.cropType, param.name)
  );
};

export const useDeleteLevel = () => useMutation((id: string) => deleteLevelCropTypeById(id));

export const useUpdateLevelProperties = () =>
  useMutation((data: IUpdateHierarchyStructureDto) => sendPatchHierarchyStructure(data));
