import { makeStyles } from '@mui/styles';

export const useSettingsPageStyle = makeStyles((theme: any) => {
  return {
    positionsCenter: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      boxShadow: 'none',
      padding: 'auto',
      borderBottom:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.color.black}`
          : `1px solid ${theme.palette.color.white1}`,
      position: 'relative',
    },
    textStyles: {
      margin: '0 20px !important',
    },
    clientActionsIcon: {
      width: '16px !important',
      height: '16px !important',
      color: theme.palette.color.grey2,
    },
    dialogTitle: {
      color: theme.palette.color.black1,
      fontSize: '20px',
      fontWeight: '600',
    },
    errorMsg: {
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.color.red1,
      marginTop: '8px',
    },
    saveButton: {
      background: theme.palette.color.green4,
      width: '200px',
      height: '40px',
      align: 'right',
      color: theme.palette.color.white,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.color.grey5,
      },
    },
  };
});
