/* eslint-disable */
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Button, SvgIcon, useTheme } from '@mui/material';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import AppLayout from 'components/AppLayout';
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IFormType } from '../../common/defines/clients';
import { useAppDispatch } from '../../store/hooks';
import { clearClientState } from '../../store/slices/clientSlice';
import { ClientActionsDialog } from './ClientActionsDialog';
import ClientAssetStructure from './ClientAssetStructure';
import { ClientBasicInfo } from './ClientBasicInfo';
import { ClientLevelProperties } from './ClientLevelProperties';

const steps = ['Basic Information', 'Set Level Properties', 'Set the Asset Structure Details'];

const ClientsForm = () => {
  const [openWarningModal, SetOpenWarningModal] = React.useState(false);
  const [isUpdateData, setIsUpdateData] = React.useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const CustomStepConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderTopWidth: 2,
      borderRadius: 1,
    },
  }));

  const styles = {
    stepper: {
      width: '100%',
      maxWidth: '1024px',
      py: '17px',
      m: '0 auto',
    },
    stepBtn: {
      height: '60px',
      py: '0',
      '.MuiStepIcon-root.Mui-active, .MuiStepIcon-root.Mui-completed': {
        color: theme.palette.primary.main,
      },
      '.MuiStepLabel-alternativeLabel': {
        fontSize: '14px',
        fontWeight: '500',
        color: '#616161',
      },
    },
    btnBackDashBoard: {
      fontFamily: 'Barlow',
      fontWeight: '600',
      fontStyle: 'normal',
      fontSize: '20px',
      lineHeight: '26px',
      textTransform: 'none',
      py: '16px',
      my: '4px',
    },
  };

  const { clientId } = useParams();

  const location = useLocation();
  const state = location.state as IFormType;

  const [activeStep, setActiveStep] = useState<number>(0);
  const type = state || (clientId ? IFormType.EDIT : IFormType.ADD);

  const onLeave = () => {
    dispatch(clearClientState());
    navigate(`/dashboard-admin`);
  };

  const handleOpenWarningModal = () => {
    SetOpenWarningModal(true);
  };

  const closeWarningModal = () => {
    SetOpenWarningModal(false);
  };

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 0:
        return (
          <ClientBasicInfo
            setIsUpdateData={setIsUpdateData}
            clientId={clientId}
            formType={type}
            nextStep={setActiveStep}
          />
        );
      // case 1:
      //   return <ClientsUserAdmin setIsUpdateData={setIsUpdateData} nextStep={setActiveStep} />;
      case 1:
        return <ClientLevelProperties setIsUpdateData={setIsUpdateData} nextStep={setActiveStep} formType={type} />;
      case 3:
        return <ClientAssetStructure nextStep={setActiveStep} />;
      default:
        return null;
    }
  }, [activeStep]);

  return (
    <AppLayout>
      <Box
        sx={{
          m: '0 28px',
        }}>
        <Button
          startIcon={<SvgIcon sx={{ width: '20px', height: '20px' }} component={ArrowBackRoundedIcon} inheritViewBox />}
          sx={{
            ...styles.btnBackDashBoard,
          }}
          onClick={() => {
            isUpdateData ? handleOpenWarningModal() : onLeave();
          }}>
          Return Dashboard
        </Button>
        <Box
          sx={{
            // background: 'white',
            background: (theme) => theme.palette.background.paper,
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.divider}`,
          }}>
          <Box>
            <Box
              sx={{
                ...styles.stepper,
              }}>
              <Stepper activeStep={activeStep} nonLinear connector={<CustomStepConnector />}>
                {steps.map((label, index) => (
                  <Step key={label} completed={activeStep > index}>
                    <StepButton color="inherit" sx={styles.stepBtn}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box sx={{ border: `1px solid ${theme.palette.divider}` }} />
            <Box sx={{ height: '100%', m: 3.75, mr: 1 }}>{stepContent}</Box>
          </Box>
        </Box>
      </Box>
      {/* delete */}
      <ClientActionsDialog
        title="Are you leaving?"
        open={openWarningModal}
        onClose={closeWarningModal}
        description="You have unsaved changes. Are you sure you want to leave?"
        onCancel={closeWarningModal}
        onSave={onLeave}
        saveLabel="Return to Dashboard"
        cancelLabel="Keep Editing"
        submit={false}
      />
    </AppLayout>
  );
};

export default ClientsForm;
