import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { circle, point } from '@turf/turf';
import useClientDataConfig from 'hooks/useClientDataConfig';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Layer, Marker, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { changeFixedSizePointChlorophyll, changeZoomLayerChlorophyll, chlorophyllAnalyticsSelector } from '../../../store/slices/map-view/chlorophyllAnalytics';
import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

const styles = {
  tooltip: {
    borderRadius: '18px',
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#FFF',
      padding: 0,
      margin: 0,
    },
  },
};
const CustomTooltip = withStyles(styles)(Tooltip);

export const useChlorophyllAnalytics = ({ mapRef }: any) => {
  const { isLayer3D, isDisplayBoundaryLine, isDisplay3DCylinder } = useAppSelector(mapViewSelector);
  const { dataGeoJsonPolygon, dataGeoJsonPoint, dataChlorophyllInterval } = useAppSelector(chlorophyllAnalyticsSelector);

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();
  const dispatch = useDispatch()
  const { isFixedPoint, value } = useClientDataConfig()
  const { analyticId } = useAppSelector(mapViewSelector);

  const { contourSetting } = useClientData();

  const zoomLevel = useMemo(() => {
    if (!mapRef?.current) return 0;
    return mapRef?.current?.getZoom();
  }, [mapRef?.current?.getZoom()]);

  useEffect(() => {
    if (!mapRef?.current) return;

    if (isFixedPoint && value) {
      dispatch(changeFixedSizePointChlorophyll(value))
    } else
      dispatch(changeZoomLayerChlorophyll(zoomLevel))

  }, [dispatch, isFixedPoint, mapRef, value, zoomLevel, analyticId])

  const layerChlorophyll = useMemo(() => {
    if (!dataGeoJsonPoint && !dataGeoJsonPolygon) {
      return null;
    }

    return (
      <>
        {dataGeoJsonPoint &&
          <Source id={`chlorophyll-polygon-point`} type="geojson" data={dataGeoJsonPoint}>
            {/* 2D Point */}
            <Layer
              id={`fill-top-surface-point`}
              type="fill"
              source={`chlorophyll-circle-point`}
              paint={{
                'fill-color': ['get', 'color'],
              }}
              layout={{ visibility: !isLayer3D && !isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Cylinder */}
            <Layer
              id={`chlorophyll-top-surface-point`}
              type="fill-extrusion"
              source={`chlorophyll-circle-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`chlorophyll-side-surface-point`}
              type="fill-extrusion"
              source={`chlorophyll-circle-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                // getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
          </Source>}
        {dataGeoJsonPolygon &&
          <Source id={`chlorophyll-source`} type="geojson" data={dataGeoJsonPolygon}>
            {/* 2D Contour */}
            <Layer
              id={`chlorophyll_polygon`}
              type="line"
              paint={{
                'line-color': ['get', 'color'],
                'line-width': 2,
                'line-dasharray': [2, 2],
              }}
              source={`chlorophyll-source`}
              layout={{ visibility: !isLayer3D && isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Filled contour */}
            <Layer
              id={`chlorophyll_fill-polygon`}
              type="fill"
              paint={{
                'fill-color': ['get', 'color'],
                'fill-opacity': 0,
              }}
              source={`chlorophyll-source`}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`chlorophyll_top-surface`}
              type="fill-extrusion"
              beforeId={`chlorophyll_fill-polygon`}
              source={`chlorophyll_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`chlorophyll_side-surface`}
              type="fill-extrusion"
              beforeId={`chlorophyll_top-surface`}
              source={`chlorophyll_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />

          </Source>
        }
        {/* {!isLayer3D &&
          zoomLevel > 19 &&
          chlorophyllGeoJSON?.features.map((item: any) => {
            return (
              <Marker
                key={`${item.properties._id}-chlorophyll-radius-marker`}
                longitude={item.properties.longX}
                latitude={item.properties.latY}
                anchor={'center'}
                style={{ zIndex: 10 }}
                offset={[0, -5]}>
                <Typography
                  component="span"
                  style={{
                    width: 'fit-content',
                    height: '24px',
                    display: 'flex',
                    padding: '0 10px',
                    color: 'white',
                    cursor: 'pointer',
                  }}>
                  {item.properties.chloroIn.toFixed(2)}m
                </Typography>
              </Marker>
            );
          })}
        {!isLayer3D &&
          zoomLevel > 20 &&
          chlorophyllGeoJSON?.features.map((item: any) => {
            return (
              <Marker
                key={`${item.properties._id}-chlorophyll-name-marker`}
                longitude={item.properties.longX}
                latitude={item.properties.latY}
                anchor={'center'}
                style={{ zIndex: 10 }}
                offset={[0, -50]}>
                <Box
                  sx={{
                    backgroundColor: '#bdbeb8',
                    borderRadius: '10px',
                    borderWidth: 2,
                    borderColor: 'white',
                    borderStyle: 'solid',
                  }}>
                  <Typography
                    component="span"
                    style={{
                      height: '24px',
                      display: 'flex',
                      padding: '10px',
                      color: 'black',
                      cursor: 'pointer',
                    }}>
                    {item.properties.treeId}
                  </Typography>
                </Box>
              </Marker>
            );
          })}
        {!isLayer3D &&
          !isDisplayBoundaryLine &&
          chlorophyllpointGeoJSON?.features.map((item: any) => {
            const pointInfo = categoryByColor.find((category: any) => {
              return category?.color === item?.properties?.color;
            });

            return pointInfo?.visible ? (
              <Marker
                key={`${item.properties._id}-chlorophyll-radius-marker`}
                longitude={item.properties.longX}
                latitude={item.properties.latY}
                anchor={'center'}
                style={{ zIndex: 10 }}
                offset={[0, -5]}>
                <TooltipAnalytics item={item} pointInfo={pointInfo} isChlorophyll />
              </Marker>
            ) : null;
          })} */}
      </>
    );
  }, [
    colorTransparent, contourSetting, dataGeoJsonPoint, dataGeoJsonPolygon, getOpacityExtrusion, getTopSurfaceOpacity, isDisplay3DCylinder, isDisplayBoundaryLine, isLayer3D, isSameColorTopSurface, opacity2D,
  ]);
  return { layerChlorophyll };
};
