import { Box, styled, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface CustomBoxProps {
  children: ReactNode;
}

const CustomBox: FC<CustomBoxProps> = ({ children }) => {
  const theme = useTheme();

  const MyBox = styled(Box)({
    borderRadius: '8px',
    border: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #EFEFEF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '48px',
    padding: '16px',
  });

  return <MyBox>{children}</MyBox>;
};

export default CustomBox;
