import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import PieChartCommon from 'components/Common/PieChartCommon';
import { QUERY_KEY, STATUS_API } from 'constants/constants';
import { get, isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getDataCropCoverAnalytic, getSizeCropCoverAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSizeCropCoverageList, mapViewSelector, setDataCropCoverage } from 'store/slices/mapViewSlice';
import { getClientSettingsById } from '../../../../../../services/clients/apiClient.services';
import {
  getColorsAnalyticLayer,
  saveColorsAnalyticLayer,
} from '../../../../../../services/MapView/apiMapViewConfig.services';
import { CropCoverCardLayer } from '../../Layer/CropCoverage';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import InfoTable from './InfoTable';

const CropCoverAnalytics = () => {
  const dispatch = useAppDispatch();

  const { analyticId, sizeCropCoverageSelected, dataCropCoverage, sizeCropCoverageList } =
    useAppSelector(mapViewSelector);

  const { clientId } = useParams();

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const { data: dataSize, status: statusDataSize } = useQuery(
    [QUERY_KEY.CROP_COVER_ANALYTIC, analyticId],
    () => getSizeCropCoverAnalytic(analyticId || ''),
    { enabled: !!analyticId && isEmpty(sizeCropCoverageList) }
  );

  const { data: dataCropType } = useQuery(
    [QUERY_KEY.SIZE_CROP_COVER_ANALYTIC, analyticId, sizeCropCoverageSelected],
    () => getDataCropCoverAnalytic(analyticId || '', sizeCropCoverageSelected + ''),
    { enabled: !!analyticId && !!sizeCropCoverageSelected }
  );

  const {
    data: colorsData,
    status,
    refetch,
  } = useQuery(
    [QUERY_KEY.COLOR_ANALYTIC_LAYER, clientSetting?._id, analyticId],
    () => getColorsAnalyticLayer(clientSetting?._id || '', analyticId || ''),
    { enabled: !!clientSetting?._id && !!analyticId }
  );

  const { mutate } = useMutation(
    (data: { analysisId: string; settingId: string; color: any }) => saveColorsAnalyticLayer(data),
    {
      onSuccess: () => {
        refetch().then();
      },
    }
  );

  const colorsDataAnalytics = useMemo(() => {
    return get(colorsData, 'data.data[0].color');
  }, [colorsData]);

  useEffect(() => {
    if (dataSize?.data) {
      dispatch(changeSizeCropCoverageList(dataSize.data));
    }
  }, [dataSize, dispatch]);

  const onSaveData = useCallback(
    (dataColor: any) => {
      if (analyticId && clientSetting) {
        mutate({ analysisId: analyticId, settingId: clientSetting._id, color: dataColor });
      }
    },
    [analyticId, clientSetting]
  );

  const colorCropCover = useMemo(() => {
    if (dataCropType?.data) {
      let colors: string[] = [];
      if (isEmpty(colorsDataAnalytics)) {
        colors = [...DIFFERENT_COLOR];
      } else {
        colors = colorsDataAnalytics;
      }
      if (status === STATUS_API.SUCCESS) {
        dispatch(
          setDataCropCoverage(
            dataCropType.data.map((_item: any, index: number) => ({
              ..._item,
              visible: true,
              color: get(colors, `[${index}]`),
            }))
          )
        );
      }
      return colors;
    }
    return [];
  }, [dataCropType, dispatch, colorsDataAnalytics, status]);

  useEffect(() => {
    if (
      statusDataSize !== STATUS_API.SUCCESS ||
      status !== STATUS_API.SUCCESS ||
      isEmpty(dataCropCoverage) ||
      !colorsDataAnalytics
    ) {
      return;
    }
    const colorNews = dataCropCoverage.map((_item: any) => _item.color);
    if (!isEqual(colorsDataAnalytics, colorNews)) {
      onSaveData(colorNews);
    }
  }, [colorsDataAnalytics, statusDataSize, status, dataCropCoverage]);

  const dataPie = useMemo(() => {
    if (dataCropCoverage) {
      return dataCropCoverage.map((_item: any) => ({
        name: _item.type,
        value: _item.area,
      }));
    }
    return [];
  }, [dataCropCoverage]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE}
      analyticTab={
        <>
          {dataCropCoverage?.length > 0 && (
            <>
              <PieChartCommon title="Crop coverage" dataChart={dataPie} colorCharts={colorCropCover} />
              <InfoTable dataPie={dataPie} />
            </>
          )}
        </>
      }
      layerTab={<CropCoverCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE} />}
      isShowDownloadButton={dataCropCoverage?.length > 0}
    />
  );
};

export default CropCoverAnalytics;
