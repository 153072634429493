import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useCustomizeSwitchStyle = makeStyles((theme: any) => {
  return {
    root: {
      padding: '4px 2px !important',
      marginTop: '0px',
      marginRight: '6px',
      width: '30px !important',
    },
    thumb: {
      borderRadius: '0 !important',
      width: '10px !important',
      height: '10px !important',
      marginTop: '12px !important',
      marginLeft: '8px !important',
      paddingLeft: '10px !important',
    },
    switchBase: {
      width: '10px !important',
      height: '10px !important',
      borderRadius: '0 !important',
      padding: '0 !important',
    },
    track: {
      borderRadius: '0 !important',
    },
    checked: {
      '& $thumb': {
        color: '#fff !important',
        marginTop: '13px !important',
        paddingLeft: '0px !important',
        marginLeft: '0px !important',
      },
      '& + $track': {
        color: theme.palette.primary.main + '!important',
        opacity: `1 !important`,
      },
    },
    focusVisible: {},
  };
});

export const SwitchCustom = (props: any) => {
  const classes = useCustomizeSwitchStyle();
  return <Switch classes={classes} size="small" {...props} />;
};
