import { Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import { area } from '@turf/turf';
import { QUERY_KEY } from 'constants/constants';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { useQuery } from 'react-query';
import { getWorkspaceStandCountTotalTree } from 'services/workspaces';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

const InfoTable = () => {
  const theme = useTheme();
  const { analysisId } = useAppSelector(tilingMapSelector);
  const { selectedTask } = useQueryListTaskOfField();
  const { boundary } = selectedTask || {};
  const { data: dataTree } = useQuery(
    [QUERY_KEY.GET_WORKSPACE_TOTAL_TREE, analysisId],
    () => getWorkspaceStandCountTotalTree(analysisId || ''),
    { enabled: !!analysisId }
  );

  const totalTree = dataTree?.data.totalTree;
  const boundaryArea = boundary ? area(boundary) : 0;
  const standsPerHectare = boundaryArea ? (totalTree / (boundaryArea / 10000)).toFixed(2) : 0;

  const tableData = [
    { key: 1, label: 'Tree count:', value: totalTree },
    boundary && { key: 2, label: 'Stands per hectare:', value: standsPerHectare },
  ];

  const styles = {
    userListTitle: {
      py: '10px',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18.2px',
      textAlign: 'center',
    },
    clientUpdateTime: {
      color: theme.palette.mode === 'dark' ? '#fff !important' : '#6E6B7B !important',
      textAlign: 'center',
      px: '16px',
    },
  };

  return (
    <TableContainer sx={{ overflow: 'hidden' }}>
      <Table>
        <TableBody
          sx={{
            pt: 0,
            flexDirection: 'column',
            minHeight: '510px',
            maxHeight: '510px',
            overflowY: 'scroll',
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
          {tableData.map((item) => (
            <>
              {item && (
                <TableRow key={item.key}>
                  <TableCell
                    component="td"
                    sx={{
                      ...styles.clientUpdateTime,
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}>
                    {item.label}
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      ...styles.clientUpdateTime,
                      fontWeight: 'bold',
                    }}>
                    {item.value}
                  </TableCell>
                </TableRow>
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InfoTable;
