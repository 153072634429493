import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { QUERY_KEY, STATUS_API } from 'constants/constants';
import { get, isEmpty, isEqual } from 'lodash';
import randomColor from 'randomcolor';
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getDataWatershedBasinAnalytic, getEnableSlopeArea } from 'services/analytics/apiAnalyticsConfig.services';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { getColorsAnalyticLayer, saveColorsAnalyticLayer } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changePolygonWatershedBasinAnalytics, mapViewSelector } from 'store/slices/mapViewSlice';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { WatershedBasinCardLayer } from '../../Layer/WatershedBasin';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import { WatershedBasinChart } from './ChartWatershedBasin/WatershedBasinChart';

export const WatershedBasinAnalytics = () => {
  const dispatch = useAppDispatch();

  const { analyticId, watershedBasinAnalytics, dateAnalyticSelected: uploadDate } = useAppSelector(mapViewSelector);
  const { clientId } = useParams();

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const { data: enableSlopeArea } = useQuery(
    [QUERY_KEY.ENABLE_SLOPE_AREA, analyticId],
    () => getEnableSlopeArea(analyticId || ''),
    {
      enabled: !!analyticId,
    }
  );

  const { data: mainData, status: statusMainData } = useQuery(
    [QUERY_KEY.WATERSHED_BASIN_ANALYSIS, uploadDate, analyticId],
    () => getDataWatershedBasinAnalytic(analyticId || ''),
    { enabled: !!analyticId }
  );

  const {
    data: colorsData,
    status,
    refetch,
  } = useQuery(
    [QUERY_KEY.COLOR_ANALYTIC_LAYER, clientSetting?._id, analyticId],
    () => getColorsAnalyticLayer(clientSetting?._id || '', analyticId || ''),
    { enabled: !!clientSetting?._id && !!analyticId }
  );

  const { mutate } = useMutation(
    (data: { analysisId: string; settingId: string; color: any }) => saveColorsAnalyticLayer(data),
    {
      onSuccess: () => {
        refetch().then();
      },
    }
  );

  const dataWatershedBasinAnalysis = useMemo(() => {
    return mainData?.data || [];
  }, [mainData]);

  const colorsDataAnalytics = useMemo(() => {
    if (status === STATUS_API.LOADING) {
      return null;
    }
    return get(colorsData, 'data.data[0].color');
  }, [status, colorsData]);

  const onSaveData = useCallback(
    (dataColor: any) => {
      if (analyticId && clientSetting) {
        mutate({ analysisId: analyticId, settingId: clientSetting._id, color: dataColor });
      }
    },
    [analyticId, clientSetting, mutate]
  );

  const mainDataColor = useMemo(() => {
    let colors: string[] = [];

    if (isEmpty(colorsDataAnalytics)) {
      colors = [...DIFFERENT_COLOR];
    } else {
      colors = colorsDataAnalytics;
    }

    if (dataWatershedBasinAnalysis.length > colors.length) {
      const extraColor = randomColor({
        count: dataWatershedBasinAnalysis.length - colors.length || 0,
        luminosity: 'bright',
      });
      colors = [...colors, ...extraColor];
    }
    if (status === STATUS_API.SUCCESS) {
      dispatch(
        changePolygonWatershedBasinAnalytics(
          dataWatershedBasinAnalysis.map((_item: any, index: number) => ({
            ..._item,
            color: colors[index],
            visible: true,
          }))
        )
      );
    }

    return colors;
  }, [dataWatershedBasinAnalysis, dispatch, colorsDataAnalytics, status]);

  useEffect(() => {
    refetch().then();
  }, []);

  useEffect(() => {
    if (
      statusMainData !== STATUS_API.SUCCESS ||
      status !== STATUS_API.SUCCESS ||
      isEmpty(watershedBasinAnalytics) ||
      !colorsDataAnalytics
    ) {
      return;
    }
    const colorNews = watershedBasinAnalytics.map((_item: any) => _item.color);
    if (!isEqual(colorsDataAnalytics, colorNews)) {
      onSaveData(colorNews);
    }
  }, [colorsDataAnalytics, statusMainData, status, watershedBasinAnalytics, onSaveData]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN}
      analyticTab={
        <>
          {isEmpty(dataWatershedBasinAnalysis) ? (
            <SkeletonCharts count={1} />
          ) : (
            <WatershedBasinChart
              uploadDate={uploadDate}
              analysisId={analyticId || ''}
              mainData={dataWatershedBasinAnalysis}
              mainColor={mainDataColor}
              clientSetting={clientSetting}
              enableSlopeArea={enableSlopeArea?.data}
            />
          )}
        </>
      }
      layerTab={<WatershedBasinCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN} />}
      isShowDownloadButton={!isEmpty(dataWatershedBasinAnalysis)}
    />
  );
};
