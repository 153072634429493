import { ICreateClientSettings, SettingUnitName } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import { useMemo } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';

const useQueryClientSetting = () => {
  const { clientId } = useParams();

  const { data: clientSetting }: UseQueryResult<ICreateClientSettings> = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const lengthSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.LENGTH) ||
      defaultClientUnitSetting[1]
    );
  }, [clientSetting]);

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const otherSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.OTHER_MEASUREMENTS) ||
      defaultClientUnitSetting[2]
    );
  }, [clientSetting]);

  const colorAnalysisLayer = clientSetting?.colorAnalysisLayer;

  return { lengthSetting, areaSetting, clientSetting, colorAnalysisLayer, otherSetting };
};

export default useQueryClientSetting;
