import { ILinesVectorDisplay, SettingUIName } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getAllLines } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';

interface LinesVectorLayerProps {}

const LinesVectorLayer: FC<LinesVectorLayerProps> = () => {
  const { linesVectorDisplay, linesVectorSensorId: sensorId } = useAppSelector(rightBarSelector);

  const { clientSetting } = useQueryClientSetting();
  const uiSetting = clientSetting?.uiSetting;
  const strokeWidthValue = uiSetting && uiSetting.find((item) => item.name === SettingUIName.STROKE_WIDTH)?.value;

  const { data: linesVectorData } = useQuery([QUERY_KEY.GET_LINES_VECTOR_LIST, sensorId], () => getAllLines(sensorId), {
    enabled: !!sensorId,
  });

  const linesVectorList = linesVectorData?.data?.docs as ILinesVectorDisplay[];

  const convertDataVector = (): GeoJSON.FeatureCollection => {
    return {
      type: 'FeatureCollection',
      features: linesVectorList
        ?.filter((item) => {
          const listVisibleOrder = linesVectorDisplay.filter((ele) => ele.visible).map((ele) => ele._id);
          return listVisibleOrder.includes(item._id);
        })
        ?.map((item) => ({
          type: 'Feature',
          geometry: item?.geometry,
          properties: {
            color: item?.colorCode,
          },
        })) as GeoJSON.Feature[],
    };
  };

  return (
    <>
      {linesVectorDisplay?.length && (
        <Source id="streamline_order_source" type="geojson" data={convertDataVector()} lineMetrics={true}>
          <Layer
            id={`streamline_order`}
            type="line"
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
              visibility: 'visible',
            }}
            paint={{
              'line-opacity': 1,
              'line-width': strokeWidthValue || 1.4,
              'line-color': ['get', 'color'],
            }}
          />
        </Source>
      )}
    </>
  );
};

export default LinesVectorLayer;
