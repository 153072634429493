export const DocumentsIcon = ({ isSelected }: any) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.404 4.69091C13.4221 4.72727 13.4403 4.76364 13.4767 4.8C13.5494 4.87273 13.5494 5.01818 13.5494 5.09091V13.8182C13.5494 15.0545 12.604 16 11.3676 16H2.64031C1.40395 16 0.458496 15.0545 0.458496 13.8182V2.18182C0.458496 0.945455 1.40395 0 2.64031 0H8.4585C8.53122 0 8.67668 0 8.7494 0.0727273C8.82213 0.0727273 8.89486 0.145455 8.96759 0.218182L13.3312 4.58182C13.3676 4.61818 13.3858 4.65455 13.404 4.69091ZM11.0767 4.36368L9.18577 2.47277V4.36368H11.0767ZM11.3676 14.5455H2.64031C2.20395 14.5455 1.91304 14.2545 1.91304 13.8182V2.18182C1.91304 1.74545 2.20395 1.45455 2.64031 1.45455H7.73122V5.09091C7.73122 5.52727 8.02213 5.81818 8.4585 5.81818H12.0949V13.8182C12.0949 14.2545 11.804 14.5455 11.3676 14.5455ZM9.91304 9.45455C10.3494 9.45455 10.6403 9.16364 10.6403 8.72727C10.6403 8.29091 10.3494 8 9.91304 8H4.09486C3.6585 8 3.36759 8.29091 3.36759 8.72727C3.36759 9.16364 3.6585 9.45455 4.09486 9.45455H9.91304ZM10.6403 11.6364C10.6403 12.0727 10.3494 12.3636 9.91304 12.3636H4.09486C3.6585 12.3636 3.36759 12.0727 3.36759 11.6364C3.36759 11.2 3.6585 10.9091 4.09486 10.9091H9.91304C10.3494 10.9091 10.6403 11.2 10.6403 11.6364ZM4.09486 5.09091C3.6585 5.09091 3.36759 5.38182 3.36759 5.81818C3.36759 6.25455 3.6585 6.54545 4.09486 6.54545H5.54941C5.98577 6.54545 6.27668 6.25455 6.27668 5.81818C6.27668 5.38182 5.98577 5.09091 5.54941 5.09091H4.09486Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
      <mask id="mask0_5830_11172" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.404 4.69091C13.4221 4.72727 13.4403 4.76364 13.4767 4.8C13.5494 4.87273 13.5494 5.01818 13.5494 5.09091V13.8182C13.5494 15.0545 12.604 16 11.3676 16H2.64031C1.40395 16 0.458496 15.0545 0.458496 13.8182V2.18182C0.458496 0.945455 1.40395 0 2.64031 0H8.4585C8.53122 0 8.67668 0 8.7494 0.0727273C8.82213 0.0727273 8.89486 0.145455 8.96759 0.218182L13.3312 4.58182C13.3676 4.61818 13.3858 4.65455 13.404 4.69091ZM11.0767 4.36368L9.18577 2.47277V4.36368H11.0767ZM11.3676 14.5455H2.64031C2.20395 14.5455 1.91304 14.2545 1.91304 13.8182V2.18182C1.91304 1.74545 2.20395 1.45455 2.64031 1.45455H7.73122V5.09091C7.73122 5.52727 8.02213 5.81818 8.4585 5.81818H12.0949V13.8182C12.0949 14.2545 11.804 14.5455 11.3676 14.5455ZM9.91304 9.45455C10.3494 9.45455 10.6403 9.16364 10.6403 8.72727C10.6403 8.29091 10.3494 8 9.91304 8H4.09486C3.6585 8 3.36759 8.29091 3.36759 8.72727C3.36759 9.16364 3.6585 9.45455 4.09486 9.45455H9.91304ZM10.6403 11.6364C10.6403 12.0727 10.3494 12.3636 9.91304 12.3636H4.09486C3.6585 12.3636 3.36759 12.0727 3.36759 11.6364C3.36759 11.2 3.6585 10.9091 4.09486 10.9091H9.91304C10.3494 10.9091 10.6403 11.2 10.6403 11.6364ZM4.09486 5.09091C3.6585 5.09091 3.36759 5.38182 3.36759 5.81818C3.36759 6.25455 3.6585 6.54545 4.09486 6.54545H5.54941C5.98577 6.54545 6.27668 6.25455 6.27668 5.81818C6.27668 5.38182 5.98577 5.09091 5.54941 5.09091H4.09486Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5830_11172)"></g>
    </svg>
  );
};
