import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { ICropTypes } from 'common/defines/clients';
import { AgeEnum, ITilingMapField } from 'interfaces/workspace';
import React, { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

interface ISelectArea {
  form: UseFormReturn<ITilingMapField>;
}

const SelectArea: FC<ISelectArea> = ({ form }) => {
  const { control } = form;

  const cropTypeList = [{ key: 1, value: ICropTypes.OIL_PALM, label: 'OIL PALM' }];

  const ageList = [
    { key: 1, value: AgeEnum.IMMATURE, label: 'Immature' },
    { key: 2, value: AgeEnum.MATURE, label: 'Mature' },
  ];

  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: '8px' }}>Crop type</Typography>
        <Controller
          name="cropType"
          control={control}
          defaultValue={ICropTypes.OIL_PALM}
          render={({ field }) => (
            <FormControl fullWidth>
              <Select {...field} size="small">
                {cropTypeList.map((item) => (
                  <MenuItem key={item.key} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={4}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: '8px' }}>Age</Typography>
        <Controller
          name="age"
          control={control}
          defaultValue={AgeEnum.IMMATURE}
          render={({ field }) => (
            <FormControl fullWidth>
              <Select {...field} size="small">
                {ageList.map((item) => (
                  <MenuItem key={item.key} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SelectArea;
