import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { circle, point } from '@turf/turf';
import { sizeByZoomLevel } from 'common/utils/util';
import update from 'immutability-helper';
import { RootState } from 'store/store';

interface IUserSlice {
  dataWaterUptake: any;
  dataWaterUptakePolygon: any;
  dataWaterUptakeInterval: any;
  isShowAllLayer: boolean;
  dataGeoJsonPoint: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  dataGeoJsonPolygon: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  zoomLayer: number;
  sizeFixedPoint: number;
  chartDataWaterUptakePreview: any;
}

const initialState: IUserSlice = {
  dataWaterUptake: null,
  dataWaterUptakePolygon: [],
  dataWaterUptakeInterval: null,
  isShowAllLayer: true,
  dataGeoJsonPoint: undefined,
  dataGeoJsonPolygon: undefined,
  zoomLayer: 16,
  sizeFixedPoint: 0.0015,
  chartDataWaterUptakePreview: null,
};

export const waterUptakeAnalyticsSlice = createSlice({
  name: 'waterUptakeAnalytics',
  initialState,
  reducers: {
    clearWaterUptakeAnalytics: () => {
      return initialState;
    },
    changeDataWaterUptakeAnalytics: (state, action: PayloadAction<any>) => {
      state.dataWaterUptake = action.payload;
      if (!state.dataWaterUptakeInterval || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((waterUptakeInstance: any) => {
          let color = 'black';
          const circumferenceInterval = state.dataWaterUptakeInterval?.range?.find(
            (item: any, index: number) =>
              item.from <= waterUptakeInstance.uptakeIn &&
              (index === state.dataWaterUptakeInterval?.range?.length - 1
                ? item.to >= waterUptakeInstance.uptakeIn
                : item.to > waterUptakeInstance.uptakeIn)
          );
          if (circumferenceInterval?.visible) {
            color = circumferenceInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(waterUptakeInstance.longX || 0), Number(waterUptakeInstance.latY || 0)], {
              ...waterUptakeInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeDataWaterUptakeInterval: (state, action: PayloadAction<any>) => {
      state.dataWaterUptakeInterval = action.payload;
      if (state.dataWaterUptakePolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataWaterUptakePolygon.map((waterUptakeInstance: any) => {
            let color = 'black';
            const waterUptakeInterval = state.dataWaterUptakeInterval.range?.find(
              (item: any, index: number) =>
                item.from <= waterUptakeInstance.uptakeIn &&
                (index === state.dataWaterUptakeInterval?.range?.length - 1
                  ? item.to >= waterUptakeInstance.uptakeIn
                  : item.to > waterUptakeInstance.uptakeIn)
            );
            if (waterUptakeInterval?.visible) {
              color = waterUptakeInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...waterUptakeInstance.geometry },
              properties: {
                ...waterUptakeInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
      if (!state.dataWaterUptake || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: state.dataWaterUptake.map((waterUptakeInstance: any) => {
          let color = 'black';
          const circumferenceInterval = action.payload?.range?.find(
            (item: any, index: number) =>
              item.from <= waterUptakeInstance.uptakeIn &&
              (index === action.payload?.range?.length - 1
                ? item.to >= waterUptakeInstance.uptakeIn
                : item.to > waterUptakeInstance.uptakeIn)
          );
          if (circumferenceInterval?.visible) {
            color = circumferenceInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(waterUptakeInstance.longX || 0), Number(waterUptakeInstance.latY || 0)], {
              ...waterUptakeInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeVisibleIntervalWaterUptake: (state, action: PayloadAction<number>) => {
      const currentInterval = state.chartDataWaterUptakePreview
        ? state.chartDataWaterUptakePreview.intervalLimit
        : state.dataWaterUptakeInterval;
      state.isShowAllLayer = currentInterval.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      currentInterval.range = update(currentInterval.range, {
        [action.payload]: {
          visible: { $set: !currentInterval.range[action.payload].visible },
        },
      });
      if (state.dataWaterUptake) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataWaterUptake.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.uptakeIn &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.uptakeIn
                  : item.to > vigorInstance.uptakeIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataWaterUptakePolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataWaterUptakePolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.uptakeIn &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.uptakeIn
                  : item.to > vigorInstance.uptakeIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeVisibleIntervalWaterUptakeAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataWaterUptakeInterval.range = update(state.dataWaterUptakeInterval.range, {
        $apply: (intervalRange: Array<any>) =>
          intervalRange.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
      if (state.dataWaterUptake) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataWaterUptake.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataWaterUptakeInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.uptakeIn &&
                (index === state.dataWaterUptakeInterval?.range?.length - 1
                  ? item.to >= vigorInstance.uptakeIn
                  : item.to > vigorInstance.uptakeIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataWaterUptakePolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataWaterUptakePolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataWaterUptakeInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.uptakeIn &&
                (index === state.dataWaterUptakeInterval?.range?.length - 1
                  ? item.to >= vigorInstance.uptakeIn
                  : item.to > vigorInstance.uptakeIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeDataPolygonWaterUptakeAnalytics: (state, action: PayloadAction<any>) => {
      state.dataWaterUptakePolygon = action.payload;
      if (!state.dataWaterUptakeInterval) {
        return;
      }
      const geoJson = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((waterUptakeInstance: any) => {
          let color = 'black';
          const waterUptakeInterval = state.dataWaterUptakeInterval.range?.find(
            (item: any, index: number) =>
              item.from <= waterUptakeInstance.uptakeIn &&
              (index === state.dataWaterUptakeInterval?.range?.length - 1
                ? item.to >= waterUptakeInstance.uptakeIn
                : item.to > waterUptakeInstance.uptakeIn)
          );
          if (waterUptakeInterval?.visible) {
            color = waterUptakeInterval.color;
          } else color = 'transparent';

          return {
            geometry: { ...waterUptakeInstance.geometry },
            properties: {
              ...waterUptakeInstance.properties,
              color: color,
            },
          };
        }),
      };
      state.dataGeoJsonPolygon = geoJson;
    },
    changeZoomLayerWater: (state, action: PayloadAction<number>) => {
      state.zoomLayer = action.payload;
      const zoomLevel = action.payload;
      const currentRange = state.chartDataWaterUptakePreview
        ? state.chartDataWaterUptakePreview.intervalLimit?.range
        : state.dataWaterUptakeInterval?.range;

      if (state.dataWaterUptake) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataWaterUptake.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentRange?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.uptakeIn &&
                (index === currentRange?.length - 1
                  ? item.to >= vigorInstance.uptakeIn
                  : item.to > vigorInstance.uptakeIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              sizeByZoomLevel(zoomLevel),
              { steps: 32 }
            );
          }),
        };
      }
    },
    changeFixedSizePointWaterUptake: (state, action: PayloadAction<any>) => {
      state.sizeFixedPoint = action.payload;
    },
    changeChartDataWaterUptakePreview: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        const intervalLimit = {
          ...state.dataWaterUptakeInterval,
          range: action.payload.range,
        };
        const chartData: any = [];
        action.payload.range.forEach((item: any) => {
          const listByRange = state.dataWaterUptake.filter(
            (vigor: any) => vigor.uptakeIn >= item.from && vigor.uptakeIn <= item.to
          );
          chartData.push({ [item.lable]: listByRange.length });
        });
        let dataPreview = { chartData, intervalLimit };

        state.chartDataWaterUptakePreview = dataPreview;

        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataWaterUptake.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.chartDataWaterUptakePreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.uptakeIn &&
                (index === state.chartDataWaterUptakePreview?.intervalLimit?.range?.length - 1
                  ? item.to >= vigorInstance.uptakeIn
                  : item.to > vigorInstance.uptakeIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
        state.dataGeoJsonPolygon = {
          type: 'FeatureCollection' as any,
          features: state.dataWaterUptakePolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.chartDataWaterUptakePreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.uptakeIn &&
                (index === state.chartDataWaterUptakePreview?.intervalLimit?.range?.length - 1
                  ? item.to >= vigorInstance.uptakeIn
                  : item.to > vigorInstance.uptakeIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
      } else {
        state.chartDataWaterUptakePreview = null;
      }
    },
  },
});

export const {
  clearWaterUptakeAnalytics,
  changeDataWaterUptakeAnalytics,
  changeDataWaterUptakeInterval,
  changeVisibleIntervalWaterUptake,
  changeVisibleIntervalWaterUptakeAllLayers,
  changeDataPolygonWaterUptakeAnalytics,
  changeZoomLayerWater,
  changeFixedSizePointWaterUptake,
  changeChartDataWaterUptakePreview,
} = waterUptakeAnalyticsSlice.actions;

export const waterUptakeAnalyticsSelector = (state: RootState) => state.waterUptakeAnalytic;

export default waterUptakeAnalyticsSlice.reducer;
