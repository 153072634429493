import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface IIntervalSelector {
  control: any;
  item: any;
  index: number;
  max: number;
  min: number;
  length: number;
  onChangeInterval?: (data: { value: number | undefined; isFrom: boolean; index: number }) => void;
}

export const IntervalSelect = ({ control, item, index, max, min, length, onChangeInterval }: IIntervalSelector) => {
  return (
    <>
      <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
        <Controller
          name={`range.${index}.from`}
          control={control}
          render={({ field }) => (
            <NumericFormat
              size="small"
              variant="standard"
              value={field.value}
              onValueChange={(e) => {
                onChangeInterval?.({ value: e.floatValue, isFrom: true, index });
              }}
              customInput={TextField}
              decimalScale={3}
              disabled={!onChangeInterval}
              sx={{ width: 1 / 5 }}
              isAllowed={(values) => {
                const { floatValue } = values;
                if (index === 0) {
                  return floatValue === min;
                }
                if (!floatValue) {
                  return true;
                }
                return floatValue <= max;
              }}
              onFocus={(e) => {
                e.target.select();
              }}
            />
          )}
        />
        <Typography sx={{ mx: 2 }}>-</Typography>
        <Controller
          name={`range.${index}.to`}
          control={control}
          render={({ field }) => (
            <NumericFormat
              size="small"
              variant="standard"
              sx={{ width: 1 / 5 }}
              value={field.value}
              onValueChange={(e) => {
                onChangeInterval?.({ value: e.floatValue, isFrom: false, index });
              }}
              customInput={TextField}
              decimalScale={3}
              disabled={!onChangeInterval}
              isAllowed={(values) => {
                const { floatValue } = values;
                if (index === length - 1) {
                  return floatValue === max;
                }
                if (!floatValue) {
                  return true;
                }
                return floatValue <= max;
              }}
              onFocus={(e) => {
                e.target.select();
              }}
            />
          )}
        />
        <Controller
          name={`range.${index}.lable`}
          control={control}
          render={({ field }) => <TextField {...field} sx={{ width: 3 / 5 }} size="small" variant="standard" />}
        />
      </Stack>
    </>
  );
};
