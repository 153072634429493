import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';

const useMapSetting = () => {
  const {
    issuesTab: {
      drawIssueShape: { isLockMap },
    },
  } = useAppSelector(rightBarSelector);

  const fixedSetting = {
    minZoom: 0,
    maxZoom: 30,
    minPitch: 0,
    maxPitch: 85,
  };

  const settingsMap = isLockMap
    ? {
        ...fixedSetting,
        scrollZoom: false,
        boxZoom: false,
        dragRotate: false,
        dragPan: false,
        keyboard: false,
        doubleClickZoom: false,
        touchZoomRotate: false,
        touchPitch: false,
      }
    : {
        ...fixedSetting,
        scrollZoom: true,
        boxZoom: true,
        dragRotate: true,
        dragPan: true,
        keyboard: true,
        doubleClickZoom: false,
        touchZoomRotate: true,
        touchPitch: true,
      };

  return settingsMap;
};

export default useMapSetting;
