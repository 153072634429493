import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import BigNumber from 'bignumber.js';
import { MeasurementUnit } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { clientAreaUnitMeasurement } from 'components/MapView/RightBar/IssuesTab/utils';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  userListTitle: {
    py: '10px',
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18.2px',
    textAlign: 'center',
  },
  clientUpdateTime: {
    textAlign: 'center',
    px: '16px',
  },
};

interface WatershedBasinCategoriesTableProps {
  data: any[];
}

const WatershedBasinCategoriesTable: FC<WatershedBasinCategoriesTableProps> = ({ data }) => {
  const convertedData = data.map((item) => ({ area: item.lduseArea, type: item.type }));
  const [typeSortDistance, setTypeSortDistance] = useState<boolean>(true);
  const [typeSortArea, setTypeSortArea] = useState<boolean>(true);
  const theme = useTheme();
  const { areaSetting } = useQueryClientSetting();
  const { t } = useTranslation();

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const handleSortTable = useCallback(
    (typeSort: string) => {
      if (typeSort === 'area') {
        setTypeSortArea(!typeSortArea);
      } else {
        setTypeSortDistance(!typeSortDistance);
      }
    },
    [typeSortArea, typeSortDistance]
  );

  const renderTableBody = useMemo(() => {
    if (convertedData && convertedData.length > 0) {
      const totalArea = convertedData.reduce((pre: any, current: any) => {
        return pre + current.area;
      }, 0);

      const sortedArray = convertedData.sort((a: any, b: any) => {
        return typeSortArea ? a.area - b.area : b.area - a.area;
      });

      if (sortedArray && sortedArray.length > 0) {
        return (
          <>
            {sortedArray.map((item: any, index: number) => {
              return (
                item.area > 0 && (
                  <TableRow key={index}>
                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                        textAlign: 'left',
                        minWidth: '30px',
                      }}>
                      {item.type}
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                        textAlign: 'left',
                        minWidth: '60px',
                      }}>
                      {new BigNumber(clientAreaUnitMeasurement(item.area, areaSetting)).toFixed(
                        areaSetting.decimalPlace
                      )}
                    </TableCell>
                  </TableRow>
                )
              );
            })}
            <TableRow>
              <TableCell
                component="th"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}>
                Total:
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}>
                {new BigNumber(clientAreaUnitMeasurement(totalArea, areaSetting)).toFixed(areaSetting.decimalPlace)}
              </TableCell>
            </TableRow>
          </>
        );
      }
    }
  }, [areaSetting, convertedData, typeSortArea]);

  return (
    <>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19px',
          color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
          alignSelf: 'center',
          padding: '8px 12px 16px 12px',
        }}>
        {TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN}
      </Typography>
      <Box m={2}>
        <TableContainer
          sx={{
            overflow: 'hidden',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '5px',
          }}>
          <Table>
            <TableHead>
              {
                <TableRow
                  component="tr"
                  sx={{
                    backgroundColor: (theme) => theme.palette.background.paper,
                  }}>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    {t('analytic.watershed_basin')}
                  </TableCell>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {`${t('trans.area')} (${areaUnit})`}
                      <SvgIcon
                        component={typeSortArea ? DescIcon : AscIcon}
                        inheritViewBox
                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => handleSortTable('area')}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableHead>
            <TableBody
              sx={{
                pt: 0,
                flexDirection: 'column',
                minHeight: '510px',
                maxHeight: '510px',
                overflowY: 'scroll',
                '-ms-overflow-style': 'none' /* IE and Edge */,
                scrollbarWidth: 'none' /* Firefox */,
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
              {renderTableBody}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default WatershedBasinCategoriesTable;
