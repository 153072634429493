import { Box } from '@mui/material';
import { IRasterCardItemProps } from 'common/defines/clients';
import { convertAnalyticNameToMultiLanguage } from 'common/utils/convert';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSensorSelected } from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { AnalyticListDnd } from './RasterCardItem/AnalyticListDnd';

export const RasterList = ({ sensorList, resetPageRaster }: any) => {
  const dispatch = useAppDispatch();
  const { levelId } = useAppSelector(mapViewSelector);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(changeSensorSelected({ sensor: null, type: null }));
  }, [levelId, dispatch]);

  const convertData = (data: IRasterCardItemProps[], sensor: any): any => {
    if (!data) return [];
    return [
      {
        ...sensor,
        id: sensor._id,
        children: data.map((item) => ({
          ...item,
          parentId: sensor._id,
          id: item._id,
          label: t(convertAnalyticNameToMultiLanguage(item.name)),
        })),
      },
    ];
  };

  return (
    <>
      {sensorList.map((sensor: any, index: number) => {
        return (
          <Box sx={{ marginBottom: '10px' }} key={sensor._id}>
            <AnalyticListDnd item={convertData(sensor.analysis, sensor)} key={sensor._id} />
          </Box>
        );
      })}
    </>
  );
};
