import { SvgIcon } from '@mui/material';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import { LinkedIn2 } from 'assets/icons';
import { LINKEDIN_CLIENT_ID } from 'common/dummy/dummyClients';
import { ISocialLogin, TypeSocial } from 'common/defines/clients';
import { useMutation } from 'react-query';
import { socialLogin } from 'services/clients/apiClient.services';
import { useNavigate } from 'react-router-dom';

const LinkedInPage = () => {
  const navigate = useNavigate();
  let tmpCheck = 1;
  const mutation = useMutation((data: ISocialLogin) => socialLogin(data), {
    onSuccess: (data) => {
      const isFirstLogin = data.user.isFirstLogin;
      const providerId = data.user.providerId;
      const isRequireField = data.user.jobTitle;
      tmpCheck = 1;
      if (isFirstLogin) {
        if (providerId && !isRequireField) {
          navigate('/request-infomation');
          return;
        }
        navigate('/waiting-approval');
        return;
      }
      const token = localStorage.getItem('token');
      const isAuthenticated = localStorage.getItem('isAuthenticated');
      const sessionLogin = localStorage.getItem('sessionLogin');
      if (token && isAuthenticated && sessionLogin && !isFirstLogin) navigate('/landing');
    },
    onError: (error) => {
      // toast.error('Login failed');
      tmpCheck = 1;
    },
    retry: 0,
  });
  const { linkedInLogin } = useLinkedIn({
    scope: 'r_emailaddress,r_liteprofile',
    clientId: LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (res) => {
      const data = {
        snsToken: res,
        snsType: TypeSocial.LINKEDIN,
      };
      if (tmpCheck > 1) return;
      tmpCheck++;
      mutation.mutate(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <SvgIcon
      onClick={() => {
        linkedInLogin();
      }}
      component={LinkedIn2}
      inheritViewBox
      style={{ cursor: 'pointer' }}
      fontSize="small"
    />
  );
};

export default LinkedInPage;
