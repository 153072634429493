import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import PieChartCommon from 'components/Common/PieChartCommon';
import SkeletonCharts from 'components/Common/SkeletonCharts';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import randomColor from 'randomcolor';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getDataVacantAreaAnalytic, getSizeVacantAreaAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSizeVacantAreaList, mapViewSelector, setDataVacantArea } from 'store/slices/mapViewSlice';
import VacantAreaCardLayer from '../../Layer/VacantArea';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import InfoTable from '../CropCover/InfoTable';

const VacantAreaAnalytics = () => {
  const dispatch = useAppDispatch();

  const { analyticId, sizeVacantAreaSelected, dataVacantArea, sizeVacantAreaList } = useAppSelector(mapViewSelector);

  useQuery([QUERY_KEY.VACANT_AREA_ANALYTIC, analyticId], () => getSizeVacantAreaAnalytic(analyticId || ''), {
    enabled: !!analyticId && isEmpty(sizeVacantAreaList),
    onSuccess: (response) => {
      if (response.data) {
        dispatch(changeSizeVacantAreaList(response.data));
      }
    },
  });

  const { data: dataCropType } = useQuery(
    [QUERY_KEY.SIZE_VACANT_AREA_ANALYTIC, analyticId, sizeVacantAreaSelected],
    () => getDataVacantAreaAnalytic(analyticId || '', sizeVacantAreaSelected + ''),
    { enabled: !!analyticId && !!sizeVacantAreaSelected }
  );

  const dataPie = useMemo(() => {
    if (dataVacantArea) {
      return dataVacantArea.map((_item: any) => ({
        name: _item.type,
        value: _item.lduseArea,
      }));
    }
    return [];
  }, [dataVacantArea]);

  const colorVacantArea = useMemo(() => {
    if (dataCropType?.data) {
      let colors: string[] = [];
      dispatch(
        setDataVacantArea(
          dataCropType.data.map((item: any, index: number) => {
            const colorRandom = randomColor({ luminosity: 'dark' });
            const _color = item.color || get(DIFFERENT_COLOR, `[${index}]`, colorRandom);
            colors.push(_color);
            return {
              ...item,
              visible: true,
              color: _color,
            };
          })
        )
      );
      return colors;
    }
    return [];
  }, [dataCropType, dispatch]);

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA}
      analyticTab={
        <>
          {dataVacantArea?.length > 0 ? (
            <>
              <PieChartCommon title="Vacant area" dataChart={dataPie} colorCharts={colorVacantArea} />
              <InfoTable dataPie={dataPie} />
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<VacantAreaCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA} />}
      isShowDownloadButton={dataVacantArea?.length > 0}
    />
  );
};

export default VacantAreaAnalytics;
