import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { IconButton, Tooltip } from '@mui/material';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import React, { useMemo, useState } from 'react';

interface IAbilityDownload {
  dataPoints?: any[] | null;
  type?: string;
  color?: string;
}
const AbilityDownload = ({ dataPoints, type, color }: IAbilityDownload) => {
  const [nameFile, setNameFile] = useState('');

  const geoJsonData = useMemo(() => {
    let geojson = {
      type: 'FeatureCollection',
      features: [] as any[],
    };
    if (dataPoints) {
      if (type === TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE) {
        // Crop Coverage
        setNameFile(`${dataPoints[0]?.type?.toLowerCase().replace(/\s/g, '')}`);
        geojson.features = dataPoints.map((dataPoint) => {
          return {
            type: 'Feature',
            properties: {
              Type: dataPoint?.type,
              'Bin size': `${dataPoint?.size}`,
              'Bin unit': `${dataPoint?.sizeUnit}`,
              Lduse_area: dataPoint?.area,
            },
            geometry: {
              type: dataPoint.geometry.type,
              coordinates: dataPoint.geometry.coordinates,
            },
          };
        });
      } else if (type === TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT) {
        // Stand Count
        setNameFile(`${dataPoints[0]?.status?.toLowerCase().replace(/\s/g, '')}`);
        const features: Array<any> = [];
        dataPoints.forEach((dataPoint) => {
          dataPoint.data.forEach((i: any) => {
            features.push({
              type: 'Feature',
              properties: {
                Tree_lon: i.longX,
                Tree_lat: i.latY,
                Tree_ID: i.treeId,
                Status: i.status,
              },
              geometry: { ...i.geometry },
            });
          });
        });
        geojson.features = features;
      }
      return geojson;
    }
    return null;
  }, [dataPoints, type]);

  const exportData = () => {
    if (!dataPoints) return;
    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(geoJsonData))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = dataPoints.length > 1 ? `MultiData.geojson` : `${nameFile}.geojson`;
    link.click();
  };
  return (
    <Tooltip title="Download Select File" arrow>
      <IconButton
        sx={{
          width: '32px',
          height: '32px',
        }}
        onClick={exportData}>
        <DownloadRoundedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AbilityDownload;
