import { Box, Button, FormControlLabel, Popover, Switch, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { FC, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { queryMe, updateUserInfo } from 'services/clients/apiClient.services';
interface ChangeFormulaProps {}

const ChangeFormula: FC<ChangeFormulaProps> = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const changeFormulaMutation = useMutation(updateUserInfo, {
    onSuccess() {
      queryClient.invalidateQueries([QUERY_KEY.CHECK_SUPER_ADMIN]);
    },
  });

  const sumAreaMode = useMemo(() => {
    return userInfo?.data.sumAreaMode;
  }, [userInfo]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowToggles = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as any);
  };

  const onChangeFormula = () => {
    changeFormulaMutation.mutate({ sumAreaMode: !sumAreaMode });
  };

  const toggleList = [
    { key: 1, label: t('trans.boundary_at_each_level'), checked: !sumAreaMode },
    { key: 2, label: t('trans.sum_from_bottom_most_level'), checked: sumAreaMode },
  ];

  return (
    <Box sx={{ position: 'absolute', top: '10px', right: '10px' }} aria-describedby={id}>
      <Button aria-describedby={id} color="neutral" variant="contained" onClick={handleShowToggles}>
        {t('trans.change_formula')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 106, vertical: 0 }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', p: '10px', backgroundColor: theme.palette.background.paper }}>
          {t('trans.area_at_higher_levels')}:
          {toggleList.map((item) => (
            <FormControlLabel
              sx={{ mr: 0 }}
              key={item.key}
              control={<Switch checked={item.checked} onChange={onChangeFormula} />}
              label={item.label}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default ChangeFormula;
