import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import PieChartCommon from 'components/Common/PieChartCommon';
import SkeletonCharts from 'components/Common/SkeletonCharts';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import randomColor from 'randomcolor';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  getDataTilerDensityAnalytic,
  getSizeTilerDensityAnalytic,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSizeTilerDensityList, mapViewSelector, setDataTilerDensity } from 'store/slices/mapViewSlice';
import TillerDensityCardLayer from '../../Layer/TilerDensity';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import InfoTable from '../CropCover/InfoTable';

const TilerDensityAnalytics = () => {
  const dispatch = useAppDispatch();

  const { analyticId, sizeTilerDensitySelected, dataTilerDensity, sizeTilerDensityList } =
    useAppSelector(mapViewSelector);

  useQuery([QUERY_KEY.TILER_DENSITY_ANALYTIC, analyticId], () => getSizeTilerDensityAnalytic(analyticId || ''), {
    enabled: !!analyticId && isEmpty(sizeTilerDensityList),
    onSuccess: (response) => {
      if (response.data) {
        dispatch(changeSizeTilerDensityList(response.data));
      }
    },
  });

  const { data: dataCropType } = useQuery(
    [QUERY_KEY.SIZE_TILER_DENSITY_ANALYTIC, analyticId, sizeTilerDensitySelected],
    () => getDataTilerDensityAnalytic(analyticId || '', sizeTilerDensitySelected + ''),
    { enabled: !!analyticId && !!sizeTilerDensitySelected }
  );

  const dataPie = useMemo(() => {
    if (dataTilerDensity) {
      return dataTilerDensity.map((_item: any) => ({
        name: _item.type,
        value: _item.lduseArea,
      }));
    }
    return [];
  }, [dataTilerDensity]);

  const colorTilerDensity = useMemo(() => {
    if (dataCropType?.data) {
      let colors: string[] = [];
      dispatch(
        setDataTilerDensity(
          dataCropType.data.map((item: any, index: number) => {
            const colorRandom = randomColor({ luminosity: 'dark' });
            const _color = item.color || get(DIFFERENT_COLOR, `[${index}]`, colorRandom);
            colors.push(_color);
            return {
              ...item,
              visible: true,
              color: _color,
            };
          })
        )
      );
      return colors;
    }
    return [];
  }, [dataCropType, dispatch]);

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY}
      analyticTab={
        <>
          {dataTilerDensity?.length > 0 ? (
            <>
              <PieChartCommon title="Tiller density" dataChart={dataPie} colorCharts={colorTilerDensity} />
              <InfoTable dataPie={dataPie} />
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<TillerDensityCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY} />}
      isShowDownloadButton={dataTilerDensity?.length > 0}
    />
  );
};

export default TilerDensityAnalytics;
