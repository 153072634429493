import BigNumber from 'bignumber.js';
import { ISettingsClientMeasure, MeasurementUnit } from 'common/defines/clients';

export const clientAreaUnitMeasurement = (area: number, areaSetting: ISettingsClientMeasure) => {
  switch (areaSetting.unit) {
    case MeasurementUnit.ACRE:
      return new BigNumber(area * 2.47105).toFixed(areaSetting.decimalPlace);
    case MeasurementUnit.KM2:
      return new BigNumber(area * 0.01).toFixed(areaSetting.decimalPlace);
    case MeasurementUnit.M2:
      return new BigNumber(area * 10000).toFixed(areaSetting.decimalPlace);
    default:
      return new BigNumber(area).toFixed(areaSetting.decimalPlace);
  }
};

export const clientLengthUnitMeasurement = (length: number, lengthSetting: ISettingsClientMeasure) => {
  switch (lengthSetting.unit) {
    case MeasurementUnit.KM:
      return new BigNumber(length * 0.001).toFixed(lengthSetting.decimalPlace);
    case MeasurementUnit.MILES:
      return new BigNumber(length * 0.000621371).toFixed(lengthSetting.decimalPlace);
    default:
      return length ? new BigNumber(length).toFixed(lengthSetting.decimalPlace) : null;
  }
};

export const clientOtherUnitMeasurement = (value: number, otherSetting: ISettingsClientMeasure) => {
  return new BigNumber(value).toFixed(otherSetting.decimalPlace);
};
