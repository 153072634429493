import { Box, Button, Grid } from '@mui/material';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { useAppDispatch } from 'store/hooks';
import { changeShow3dModal } from 'store/slices/tilingMapSlice';

const Layer3dMap = () => {
  const dispatch = useAppDispatch();
  const { selectedTask } = useQueryListTaskOfField();

  return (
    <>
      {selectedTask ? (
        <Box sx={{ mt: '24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => dispatch(changeShow3dModal({ isShowPotree: true }))}>
                Point Cloud
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() =>
                  dispatch(
                    changeShow3dModal({
                      isShow3dMesh: true,
                    })
                  )
                }>
                Mesh high resolution
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() =>
                  dispatch(
                    changeShow3dModal({
                      isShow3dMapbox: true,
                    })
                  )
                }>
                Mesh low resolution
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
};

export default Layer3dMap;
