import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MapGL, { Layer, Marker, Source } from '@urbica/react-map-gl';
import Draw from '@urbica/react-map-gl-draw';
import { MapGlDrawSchema } from 'common/defines/clients';
import { MAPBOX_ACCESS_TOKEN } from 'common/dummy/dummyClients';
import { GeneratorColor, getBoundsForPoints } from 'common/utils/util';
import Minimap from 'components/Minimap';
import { DEFAULT_VIEWPORT } from 'constants/constants';
import { get, isArray } from 'lodash';
import polylabel from 'polylabel';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeLocation, clearLevelState, clientSelector, redoLocation, undoLocation } from 'store/slices/clientSlice';

export const MODES = {
  DRAW_LINE_STRING: 'draw_line_string',
  DRAW_POLYGON: 'draw_polygon',
  DRAW_POINT: 'draw_point',
  SIMPLE_SELECT: 'simple_select',
  DIRECT_SELECT: 'direct_select',
  STATIC: 'static',
};

const NameLevelDiv = styled('div')(() => ({
  color: '#6b6b6b',
  fontWeight: 'bold',
}));

interface MapCropTypeProps {
  levelId: string | undefined;
  themeMode: string | undefined;
}

export const MapCropType = ({ levelId, themeMode }: MapCropTypeProps) => {
  const dispatch = useAppDispatch();
  const mapTheme = useTheme();

  // const [mapStyle, setMapStyle] = useState(
  //   theme.palette.mode === 'dark' ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10'
  // );
  const [mapStyle, setMapStyle] = useState(
    themeMode === 'dark' ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10'
  );
  const [modeDraw, setModeDraw] = useState<string>(MODES.SIMPLE_SELECT);
  const [isPolygonControl, setIsPolygonControl] = useState<boolean>(true);
  const [locationState, setLocationState] = useState<MapGlDrawSchema>();

  const [viewport, setViewport] = useState({
    ...DEFAULT_VIEWPORT,
  });

  // useEffect(() => {
  //   if (theme.palette.mode === 'dark') {
  //     setMapStyle('mapbox://styles/mapbox/dark-v10');
  //   } else {
  //     setMapStyle('mapbox://styles/mapbox/light-v10');
  //   }
  // }, []);

  const mapRef = useRef<any>(null);

  const { location, allLocationCropType } = useAppSelector(clientSelector);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.getMap().resize();
    }
    if (location === locationState) {
      return;
    }
    setIsPolygonControl(get(location, 'features', []).length === 0);
    try {
      if (location) {
        setLocationState(location as MapGlDrawSchema);
        const { width, height } = mapRef.current.getMap().painter;
        const coordinates = get(location, 'features[0].geometry.coordinates[0]');
        if (isArray(get(coordinates, '[0][0]'))) {
          setViewport(getBoundsForPoints((location as any).features[0].geometry.coordinates[0][0], width, height));
        } else {
          setViewport(getBoundsForPoints((location as any).features[0].geometry.coordinates[0], width, height));
        }
      } else {
        setLocationState(undefined);
        setViewport(DEFAULT_VIEWPORT);
      }
    } catch (error) {}
  }, [location, setLocationState, setViewport, setIsPolygonControl, locationState]);

  const handleKeyboard = useCallback(
    (event: any) => {
      if (!event.ctrlKey) {
        return;
      }

      if (event.keyCode === 90) {
        // ctrl + Z
        dispatch(undoLocation());
      } else if (event.keyCode === 89) {
        // ctrl + Y
        dispatch(redoLocation());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboard);
    return () => {
      dispatch(clearLevelState());
      document.removeEventListener('keydown', handleKeyboard);
    };
  }, [dispatch, handleKeyboard]);

  const nameLevel = useMemo(() => {
    return allLocationCropType?.find((i) => i._id === levelId)?.name;
  }, [allLocationCropType, levelId]);

  const allLocation = useMemo(() => {
    if (!levelId) {
      return [];
    }

    const dataMapping = allLocationCropType
      ?.filter((i) => i.location && i._id !== levelId)
      ?.map((item: any) => ({
        id: item._id,
        name: item.name,
        position: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: get(item, 'location.features[0].geometry.coordinates', []),
          },
        },
        color: GeneratorColor(),
      }));

    return dataMapping || [];
  }, [allLocationCropType, levelId]);

  const onChangeStyleMap = useCallback((style: string) => {
    style && setMapStyle(style);
  }, []);

  const onChangeDataDraw = (newLocation: MapGlDrawSchema) => {
    if (get(newLocation, 'features', []).length > 1) {
      return;
    }
    setLocationState(newLocation);
    const isExistAreaDraw = get(newLocation, 'features', []).length === 0;
    setIsPolygonControl(isExistAreaDraw);
    setModeDraw((mode: string) => (isExistAreaDraw ? MODES.SIMPLE_SELECT : mode));
    dispatch(changeLocation(newLocation));
  };

  const drawLayoutChildrenList = useCallback((childrenList?: any[]) => {
    if (!childrenList) {
      return <></>;
    }

    return childrenList.map((children) => {
      return (
        <Fragment key={children.id}>
          <Source id={`${children.id}source`} type="geojson" data={children.position} />
          <Layer
            id={`border_${children.id}`}
            type="line"
            source={`${children.id}source`}
            paint={{
              'line-color': children.color.length < 7 ? 'red' : children.color,
              'line-opacity': 0.8,
              'line-width': 1.4,
            }}
          />
          <Layer
            id={`layout_${children.id}`}
            source={`${children.id}source`}
            type="fill"
            paint={{
              'fill-color': children.color.length < 7 ? 'red' : children.color,
              'fill-opacity': 0.1,
            }}
          />
        </Fragment>
      );
    });
  }, []);

  const drawNameLevel = useCallback(() => {
    if (!levelId || !nameLevel || !locationState) {
      return <></>;
    }
    try {
      const coordinates = get(locationState, 'features[0].geometry.coordinates[0]');
      let point: any[];
      if (isArray(get(coordinates, '[0][0]'))) {
        point = polylabel(get(locationState, 'features[0].geometry.coordinates[0]'), 0.001);
      } else {
        point = polylabel(get(locationState, 'hjfeatures[0].geometry.coordinates'), 0.001);
      }
      return (
        <Marker longitude={point[0]} latitude={point[1]}>
          <NameLevelDiv>{nameLevel}</NameLevelDiv>
        </Marker>
      );
    } catch (error) {
      return <></>;
    }
  }, [locationState, nameLevel, levelId]);

  return (
    <Box
      sx={{
        height: '100%',
        '& .mapboxgl-ctrl-top-left .active': {
          backgroundColor: mapTheme.palette.primary.main,
        },
      }}>
      <MapGL
        mapStyle={mapStyle}
        accessToken={MAPBOX_ACCESS_TOKEN}
        longitude={viewport.longitude}
        latitude={viewport.latitude}
        zoom={viewport.zoom}
        style={{
          width: '100%',
          height: '100%',
          flexGrow: 1,
        }}
        ref={mapRef}
        onViewportChange={setViewport}
        viewportChangeMethod="flyTo">
        <Draw
          mode={modeDraw}
          data={locationState}
          onChange={(newData: MapGlDrawSchema) => onChangeDataDraw(newData)}
          position={isPolygonControl ? 'top-left' : 'top-right'}
          polygonControl={isPolygonControl}
          trashControl={!isPolygonControl}
          pointControl={false}
          lineStringControl={false}
          combineFeaturesControl={false}
          uncombineFeaturesControl={false}
          displayControlsDefault={false}
          styles={theme}
        />
        {allLocation && drawLayoutChildrenList(allLocation)}
        {drawNameLevel()}
      </MapGL>
      <Minimap onChangeStyleMap={onChangeStyleMap} />
    </Box>
  );
};

const theme = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    paint: {
      'fill-color': '#3bb2d0',
      'fill-outline-color': '#3bb2d0',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#fbb03b',
      'fill-outline-color': '#fbb03b',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#35a1bd',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#3bb2d0',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#3bb2d0',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['!=', 'meta', 'midpoint'], ['==', 'active', 'true']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040',
    },
  },
];
