import { Box, Button, FormControlLabel, Popover, Typography } from '@mui/material';
import { IStreamlineOrderDisplay } from 'common/defines/clients';
import { MapEnum } from 'common/defines/constants';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { clientLengthUnitMeasurement } from 'components/MapView/RightBar/IssuesTab/utils';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { FC, MouseEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateStreamlineOrderColor } from 'services/analytics/apiAnalyticsConfig.services';
import { updateStreamlineOrderIntelligentColor } from 'services/workspaces';
import { useAppDispatch } from 'store/hooks';
import { changeEachStreamlineOrderDisplay } from 'store/slices/rightBarSlice';

interface SwitchStreamlineOrderProps {
  item: IStreamlineOrderDisplay;
  mode: MapEnum;
  _id: string | undefined;
}

const SwitchStreamlineOrder: FC<SwitchStreamlineOrderProps> = ({ item, mode, _id }) => {
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const classes = useLayerSelectCardStyle();
  const { lengthSetting } = useQueryClientSetting();

  const { order, colorCode, length, sensorId } = item;

  const onSuccessAction = () => {
    queryClient.invalidateQueries([QUERY_KEY.GET_STREAMLINE_ORDER_LIST]);
    queryClient.invalidateQueries([QUERY_KEY.GET_STREAMLINE_ORDER_INTELLIGENT_LIST]);
    toast.success('Update color successful');
    handlePopoverClose();
  };

  const changeStreamlineOrderColorMutation = useMutation(updateStreamlineOrderColor, {
    onSuccess() {
      onSuccessAction();
    },
  });

  const changeStreamlineOrderColorIntelligentMutation = useMutation(updateStreamlineOrderIntelligentColor, {
    onSuccess() {
      onSuccessAction();
    },
  });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = () => {
    if (mode === MapEnum.MAP_VIEW) {
      changeStreamlineOrderColorMutation.mutate({ sensorId, order, colorCode: selectedColor });
    } else if (mode === MapEnum.CROP_INTELLIGENT) {
      _id && changeStreamlineOrderColorIntelligentMutation.mutate({ _id, order, colorCode: selectedColor });
    }
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <SwitchCustom checked={item.visible} onClick={() => dispatch(changeEachStreamlineOrderDisplay({ order }))} />
        }
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            {colorCode && (
              <Box
                sx={{
                  width: '14px',
                  height: '14px',
                  border: `1px solid ${colorCode}`,
                  borderRadius: '100%',
                  backgroundColor: colorCode,
                }}
                onClick={(e: any) => handleClick(e)}
              />
            )}
            <Typography className={classes.selectText} ml="6px">
              {order}
            </Typography>
            <Typography className={classes.selectText} ml="4px">{`(${clientLengthUnitMeasurement(
              length,
              lengthSetting
            )}${lengthSetting?.unit})`}</Typography>
          </Box>
        }
      />
      <Popover
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
          '.MuiPopover-paper': {
            overflowX: 'initial',
            overflowY: 'initial',
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 160,
          horizontal: 30,
        }}>
        <Box>
          <HexColorPickerDebounce
            style={{
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '10px',
            }}
            color={colorCode}
            onChange={(color: string) => {
              setSelectedColor(color);
            }}
          />
          <Box sx={{ my: '8px', textAlign: 'center' }}>
            <Button color="inherit" variant="outlined" size="small" onClick={handlePopoverClose}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" size="small" sx={{ ml: '12px' }} onClick={handleChangeColor}>
              Save
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default SwitchStreamlineOrder;
