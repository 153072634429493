import { Avatar, Box, Grid, Typography } from '@mui/material';
import { BigNumber } from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';

const styles = {
  circularProgress: {
    position: 'relative',
    display: 'inline-flex',
    borderRadius: '30px',
    backgroundColor: '#F9F9F9',
  },
  circularPercent: {
    top: 0,
    left: '4px',
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelProgress: {
    fontSize: '14px',
    color: '#3C4043',
    height: 'fit-content !important',
    lineHeight: 'initial',
  },
  label: {
    fontSize: '12px',
    color: '#9E9E9E',
    height: 'fit-content !important',
    lineHeight: 'initial',
  },
};

interface IPopupInfo {
  name: string;
  cropType: any;
  area: number;
  count?: string;
  icon?: any;
  iconColor?: string;
  noOfFields?: number;
}

export const PopupInfo = (props: IPopupInfo) => {
  const { t } = useTranslation();
  const [isMouseEnterFieldName, setIsMouseEnterFieldName] = useState<boolean>(false);
  const { name, cropType, area, count, icon, iconColor, noOfFields } = props;
  const maxTextLength = 22;

  // Call get clientSetting
  const { clientId } = useParams();
  const { data: clientData } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientData?.unitSetting.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientData]);

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const clientAreaUnitMeasurement = useCallback(
    (_area: number) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return new BigNumber(_area * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return new BigNumber(_area * 0.01).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return new BigNumber(_area * 10000).toFixed(areaSetting.decimalPlace);
        default:
          return new BigNumber(_area).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const truncateMiddle = (text: string) => {
    if (text.length <= maxTextLength) {
      return text;
    } else {
      const halfLength = Math.floor((maxTextLength - 3) / 2);
      const truncatedText = text.substring(0, halfLength) + '...' + text.substring(text.length - halfLength);
      return truncatedText;
    }
  };

  const renderLongText = (text: string) => {
    return (
      <Box component="span" sx={{ position: 'absolute', top: '-8px', left: 0 }}>
        {text}
      </Box>
    );
  };

  const isShowLongText = isMouseEnterFieldName && name.length > maxTextLength;

  const getCountTrees = useMemo(() => {
    const nameCropType = cropType.name;
    const ArrayCondition = ['OIL PALM', 'DURIAN'];

    if (!ArrayCondition.includes(nameCropType)) {
      return null;
    }
    return count ? (
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          component="span"
          sx={{ fontSize: '12px', color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#9E9E9E') }}>
          Count
        </Typography>
        <Typography
          component="span"
          sx={{
            color: (theme) => (theme.palette.mode === 'dark' ? '#9E9E9E' : '#616161'),
            fontSize: '12px',
          }}>
          {count}
        </Typography>
      </Grid>
    ) : (
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          component="span"
          sx={{ fontSize: '12px', color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#9E9E9E') }}>
          Count
        </Typography>
        <Typography
          component="span"
          sx={{
            color: (theme) => (theme.palette.mode === 'dark' ? '#9E9E9E' : '#616161'),
            fontSize: '12px',
          }}>
          -
        </Typography>
      </Grid>
    );
  }, [cropType, count]);

  return (
    <Grid
      width={360}
      minWidth={360}
      height={120}
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        border: 0,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        padding: '24px 24px 24px 30px',
      }}>
      <Box sx={styles.circularProgress}>
        <Box
          sx={{
            backgroundColor: `${iconColor}`,
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
          }}>
          <Avatar
            src={icon}
            sx={{
              height: '20px',
              width: '20px',
              color: '',
            }}
          />
        </Box>
      </Box>
      <Grid container padding={1}>
        <Grid
          item
          xs={12}
          direction="row"
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '37px' }}>
          <Typography
            onMouseEnter={() => setIsMouseEnterFieldName(true)}
            onMouseLeave={() => setIsMouseEnterFieldName(false)}
            component="span"
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
              margin: '8px 8px 8px 0',
              position: 'relative',
            }}>
            {isShowLongText ? renderLongText(name) : truncateMiddle(name)}
          </Typography>
          <Typography
            component="span"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
              padding: '2px 8px',
              color: '#1C9855',
              textTransform: 'uppercase',
              width: 'fit-content',
              display: isShowLongText ? 'none' : 'initial',
            }}>
            {cropType.name}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              component="span"
              sx={{ fontSize: '12px', color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#9E9E9E') }}>
              Area
            </Typography>

            <Typography
              component="span"
              sx={{
                color: (theme) => (theme.palette.mode === 'dark' ? '#9E9E9E' : '#616161'),
                fontSize: '12px',
              }}>
              {`${clientAreaUnitMeasurement(area).toLocaleString()} ${areaUnit}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              component="span"
              sx={{ fontSize: '12px', color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#9E9E9E') }}>
              {t('trans.no_of_fields')}
            </Typography>
            <Typography
              component="span"
              sx={{
                color: (theme) => (theme.palette.mode === 'dark' ? '#9E9E9E' : '#616161'),
                fontSize: '12px',
              }}>
              {noOfFields}
            </Typography>
          </Grid>
          {getCountTrees}
        </Grid>
      </Grid>
    </Grid>
  );
};
