import { point } from '@turf/turf';
import { useMemo } from 'react';
import { Layer } from 'react-map-gl';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Threebox } from 'threebox-plugin';
import { generatorPointsParabola } from '../../common/utils/util';
import { useAppSelector } from '../../store/hooks';
import { mapViewSelector } from '../../store/slices/mapViewSlice';
import { useClientData } from '../useClientData';

export const useDrawArcLayer = ({ mapRef, isMap3D }: any) => {
  const { pointCenters, pointCenterSelect } = useAppSelector(mapViewSelector);

  const { lengthUnit, distanceCalculator } = useClientData();

  const arcLayer = useMemo(() => {
    if (!pointCenters || !mapRef.current || isMap3D) {
      return <></>;
    }
    const pointLocationSelect = pointCenters.find((p) => p.id === pointCenterSelect);
    if (!pointLocationSelect || !pointCenterSelect) {
      return <></>;
    }

    const arcsPoints = generatorPointsParabola(pointCenters, pointLocationSelect, pointCenterSelect!);
    let threeJS: any;
    try {
      mapRef?.current?.getMap().removeLayer('layer-custom');
      const collection = document.getElementsByClassName('tooltip-map');
      while (collection[0]) {
        collection[0]?.parentNode?.removeChild(collection[0]);
      }
    } catch (error) {}
    return (
      <Layer
        id={'layer-custom'}
        type="custom"
        onAdd={(map: any, mbxContext: any) => {
          // instantiate threebox
          threeJS = new Threebox(map, mbxContext, {
            defaultLights: true,
            enableSelectingObjects: true,
            enableTooltips: true,
          });

          for (let pointTes of arcsPoints) {
            const lineOptions3 = {
              geometry: pointTes,
              color: 0x23be6a,
              width: 6,
            };
            const lineMesh2 = threeJS.line(lineOptions3);
            const pointFrom = point([pointTes[0][0], pointTes[0][1]]);
            const pointTo = point([pointTes[100][0], pointTes[100][1]]);

            try {
              let tooltipDiv = document.createElement('div');
              tooltipDiv.className = 'tooltip-map';
              tooltipDiv.textContent = `${distanceCalculator(pointFrom, pointTo, lengthUnit)} ${lengthUnit}`;
              const cubeLabel = new CSS2DObject(tooltipDiv);
              cubeLabel.position.set(0, 0, pointTes[50][2] / 100 + 2);
              lineMesh2.add(cubeLabel);
            } catch (error) {}

            threeJS.add(lineMesh2);
          }
        }}
        render={() => {
          if (threeJS) {
            threeJS.update();
          }
        }}
      />
    );
  }, [pointCenters, pointCenterSelect, mapRef, isMap3D, distanceCalculator, lengthUnit]);

  return { arcLayer };
};
