import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { circle, point } from '@turf/turf';
import { sizeByZoomLevel } from 'common/utils/util';
import update from 'immutability-helper';
import { RootState } from 'store/store';

interface IUserSlice {
  dataVigor: any;
  dataVigorPolygon: any;
  dataVigorInterval: any;
  isShowAllLayer: boolean;
  dataGeoJsonPoint: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  dataGeoJsonPolygon: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  zoomLayer: number;
  sizeFixedPoint: number;
  chartDataVigorPreview: any;
}

const initialState: IUserSlice = {
  dataVigor: null,
  dataVigorPolygon: [],
  dataVigorInterval: null,
  isShowAllLayer: true,
  dataGeoJsonPoint: undefined,
  dataGeoJsonPolygon: undefined,
  zoomLayer: 16,
  sizeFixedPoint: 0.0015,
  chartDataVigorPreview: null,
};

export const vigorAnalyticsSlice = createSlice({
  name: 'vigorAnalytics',
  initialState,
  reducers: {
    clearVigorAnalytics: () => {
      return initialState;
    },
    changeDataVigorAnalytics: (state, action: PayloadAction<any>) => {
      state.dataVigor = action.payload;
      if (!state.dataVigorInterval || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((vigorInstance: any) => {
          let color = 'black';
          const vigorInterval = state.dataVigorInterval?.range?.find(
            (item: any, index: number) =>
              item.from <= vigorInstance.vigorIn &&
              (index === state.dataVigorInterval?.range?.length - 1
                ? item.to >= vigorInstance.vigorIn
                : item.to > vigorInstance.vigorIn)
          );
          if (vigorInterval?.visible) {
            color = vigorInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
              ...vigorInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeDataPolygonVigorAnalytics: (state, action: PayloadAction<any>) => {
      state.dataVigorPolygon = action.payload;
      if (!state.dataVigorInterval) {
        return;
      }
      const geoJson = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((vigorInstance: any) => {
          let color = 'black';
          const vigorInterval = state.dataVigorInterval.range?.find(
            (item: any, index: number) =>
              item.from <= vigorInstance.vigorIn &&
              (index === state.dataVigorInterval?.range?.length - 1
                ? item.to >= vigorInstance.vigorIn
                : item.to > vigorInstance.vigorIn)
          );
          if (vigorInterval?.visible) {
            color = vigorInterval.color;
          } else color = 'transparent';
          return {
            geometry: { ...vigorInstance.geometry },
            properties: {
              ...vigorInstance.properties,
              color: color,
            },
          };
        }),
      };
      state.dataGeoJsonPolygon = geoJson;
    },
    changeDataVigorInterval: (state, action: PayloadAction<any>) => {
      state.dataVigorInterval = action.payload;
      if (!action.payload) return;
      if (state.dataVigorPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataVigorPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataVigorInterval.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.vigorIn &&
                (index === state.dataVigorInterval?.range?.length - 1
                  ? item.to >= vigorInstance.vigorIn
                  : item.to > vigorInstance.vigorIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
      if (state.dataVigor) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataVigor.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = action.payload?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.vigorIn &&
                (index === action.payload?.range?.length - 1
                  ? item.to >= vigorInstance.vigorIn
                  : item.to > vigorInstance.vigorIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
    },
    changeVisibleIntervalVirgo: (state, action: PayloadAction<number>) => {
      const currentInterval = state.chartDataVigorPreview
        ? state.chartDataVigorPreview.intervalLimit
        : state.dataVigorInterval;
      state.isShowAllLayer = currentInterval.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      currentInterval.range = update(currentInterval.range, {
        [action.payload]: {
          visible: { $set: !currentInterval.range[action.payload].visible },
        },
      });
      if (state.dataVigor) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataVigor.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.vigorIn &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.vigorIn
                  : item.to > vigorInstance.vigorIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataVigorPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataVigorPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.vigorIn &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.vigorIn
                  : item.to > vigorInstance.vigorIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeVisibleIntervalVirgoAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataVigorInterval.range = update(state.dataVigorInterval.range, {
        $apply: (intervalRange: Array<any>) =>
          intervalRange.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
      if (state.dataVigor) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataVigor.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataVigorInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.vigorIn &&
                (index === state.dataVigorInterval?.range?.length - 1
                  ? item.to >= vigorInstance.vigorIn
                  : item.to > vigorInstance.vigorIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataVigorPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataVigorPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataVigorInterval.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.vigorIn &&
                (index === state.dataVigorInterval?.range?.length - 1
                  ? item.to >= vigorInstance.vigorIn
                  : item.to > vigorInstance.vigorIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeZoomLayer: (state, action: PayloadAction<number>) => {
      state.zoomLayer = action.payload;
      const zoomLevel = action.payload;
      const currentRange = state.chartDataVigorPreview
        ? state.chartDataVigorPreview.intervalLimit?.range
        : state.dataVigorInterval?.range;

      if (state.dataVigor) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataVigor.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentRange?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.vigorIn &&
                (index === currentRange?.length - 1
                  ? item.to >= vigorInstance.vigorIn
                  : item.to > vigorInstance.vigorIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              sizeByZoomLevel(zoomLevel),
              { steps: 32 }
            );
          }),
        };
      }
    },
    changeFixedSizePointVigor: (state, action: PayloadAction<any>) => {
      state.sizeFixedPoint = action.payload;
    },
    changeChartDataVigorPreview: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        const intervalLimit = {
          ...state.dataVigorInterval,
          range: action.payload.range,
        };
        const chartData: any = [];
        action.payload.range.forEach((item: any) => {
          const listByRange = state.dataVigor.filter(
            (vigor: any) => vigor.vigorIn >= item.from && vigor.vigorIn <= item.to
          );
          chartData.push({ [item.lable]: listByRange.length });
        });
        let dataPreview = { chartData, intervalLimit };

        state.chartDataVigorPreview = dataPreview;

        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataVigor.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.chartDataVigorPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.vigorIn &&
                (index === state.chartDataVigorPreview?.intervalLimit?.range?.length - 1
                  ? item.to >= vigorInstance.vigorIn
                  : item.to > vigorInstance.vigorIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
        state.dataGeoJsonPolygon = {
          type: 'FeatureCollection' as any,
          features: state.dataVigorPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.chartDataVigorPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.vigorIn &&
                (index === state.chartDataVigorPreview?.intervalLimit?.range?.length - 1
                  ? item.to >= vigorInstance.vigorIn
                  : item.to > vigorInstance.vigorIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
      } else {
        state.chartDataVigorPreview = null;
      }
    },
  },
});

export const {
  changeDataVigorAnalytics,
  changeDataPolygonVigorAnalytics,
  changeDataVigorInterval,
  clearVigorAnalytics,
  changeVisibleIntervalVirgo,
  changeVisibleIntervalVirgoAllLayers,
  changeZoomLayer,
  changeFixedSizePointVigor,
  changeChartDataVigorPreview,
} = vigorAnalyticsSlice.actions;

export const vigorAnalyticsSelector = (state: RootState) => state.vigorAnalytics;

export default vigorAnalyticsSlice.reducer;
