import LoopIcon from '@mui/icons-material/Loop';
import React from 'react';

const LoopIconAnimation = () => {
  return (
    <LoopIcon
      style={{
        color: '#ffffff',
        fontSize: '20px',
      }}
      sx={{
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(360deg)',
          },
          '100%': {
            transform: 'rotate(0deg)',
          },
        },
      }}
    />
  );
};

export default LoopIconAnimation;
