import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, Typography } from '@mui/material';
import { inside, point as pointTurf } from '@turf/turf';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { isArray, isEmpty } from 'lodash';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeDataPointClicked,
  changeIsPointerMode,
  changeIsPolygonMode,
  changeVisiblePopupAdd,
  standCountAnalyticSelector,
} from 'store/slices/map-view/standCountAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { useLandUsageLayerStyle } from '../../../MapViewStyle';
import DialogDeleteStandPoint from '../Category/StandCount/DialogDeleteStandPoint';
import { DialogStandPoint } from '../Category/StandCount/DialogStandPoint';

interface StandCountEditProps {
  layerTab: ReactNode;
}

const StandCountEdit: FC<StandCountEditProps> = ({ layerTab }) => {
  const [isDisplayExpandEditMode, setIsDisplayExpandEditMode] = useState(true);
  const [idPointsDelete, setIdPointDelete] = useState<string | string[]>('');
  const [open, setOpen] = useState<boolean>(false);
  const [isDisplayDeleteButton, setIsDisplayDeleteButton] = useState(false);

  const { t } = useTranslation();
  const classes = useLandUsageLayerStyle();
  const dispatch = useAppDispatch();

  const { isShowPopupAdd, dataPointClicked, dataPointPolygon, isLoading, isPointerMode, isPolygonMode } =
    useAppSelector(standCountAnalyticSelector);
  const { dataStandCount } = useAppSelector(mapViewSelector);

  const standCountGeoJSON = useMemo(() => {
    if (isEmpty(dataStandCount) || isLoading) {
      return null;
    }

    let standCountGeoPoint: any = [];

    dataStandCount?.forEach((category: any) => {
      category?.data?.forEach((standCount: any) => {
        if (standCount.latY && standCount.longX) {
          standCountGeoPoint.push(pointTurf([standCount.longX, standCount.latY], standCount));
        } else if (standCount.geometry?.coordinates && !isArray(standCount.geometry?.coordinates?.[0])) {
          standCountGeoPoint.push(pointTurf(standCount.geometry.coordinates));
        } else if (standCount.geometry?.coordinates?.[0]) {
          standCountGeoPoint.push(pointTurf(standCount.geometry.coordinates[0], standCount));
        }
      });
    });

    return standCountGeoPoint;
  }, [dataStandCount, isLoading]);

  const arrayIdInside = useMemo(() => {
    let tempArrayId = [] as string[];

    if (!dataPointPolygon.length) return [];

    dataPointPolygon.forEach((item: any) => {
      standCountGeoJSON?.forEach((point: any) => {
        let pointInsidePolygon = inside(point, item);
        if (pointInsidePolygon) tempArrayId.push(point.properties?._id);
      });
    });

    return tempArrayId;
  }, [dataPointPolygon, standCountGeoJSON]);

  useEffect(() => {
    if ((isPolygonMode && arrayIdInside.length > 0) || dataPointClicked?._id) {
      setIsDisplayDeleteButton(true);
    } else {
      setIsDisplayDeleteButton(false);
    }
  }, [arrayIdInside.length, dataPointClicked?._id, isPolygonMode]);

  const onClickAddPoint = () => {
    dispatch(changeVisiblePopupAdd(true));
    dispatch(changeDataPointClicked(undefined));
  };

  const handleChangePointerMode = useCallback(() => {
    dispatch(changeIsPointerMode(!isPointerMode));
    dispatch(changeDataPointClicked(undefined));
    if (isPolygonMode) {
      dispatch(changeIsPolygonMode(false));
    }
    dispatch(changeDataPointClicked(undefined));
  }, [dispatch, isPointerMode, isPolygonMode]);

  const handleChangePolygonMode = useCallback(() => {
    dispatch(changeIsPolygonMode(!isPolygonMode));
    if (isPointerMode) {
      dispatch(changeIsPointerMode(false));
    }
    dispatch(changeDataPointClicked(undefined));
  }, [dispatch, isPointerMode, isPolygonMode]);

  const handleDeletePoint = useCallback(() => {
    if (isPolygonMode && arrayIdInside.length > 0) {
      setIdPointDelete(arrayIdInside);
      setOpen(true);
    } else if (isPointerMode) {
      setIdPointDelete(dataPointClicked?._id);
      setOpen(true);
    }
  }, [arrayIdInside, dataPointClicked?._id, isPointerMode, isPolygonMode]);

  return (
    <Scrollbars style={{ height: 'calc(100vh - 350px)' }} autoHide>
      <Grid container classes={{ root: classes.containerStyle }} direction="column" sx={{ display: 'flex', flex: 1 }}>
        <Box className={classes.boxStyle}>
          <Box>{layerTab}</Box>
          <Box className="mode" sx={{}}>
            <Grid sx={{}}>
              <Button
                startIcon={isDisplayExpandEditMode ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                onClick={() => setIsDisplayExpandEditMode(!isDisplayExpandEditMode)}
                classes={{ root: classes.buttonTextStyle }}>
                {t('trans.edit_mode')}
              </Button>
              <Collapse in={isDisplayExpandEditMode} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
                <Grid container>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      control={<SwitchCustom checked={isPointerMode} onClick={handleChangePointerMode} />}
                      label={<Typography classes={{ root: classes.selectText }}>{t('trans.pointer')}</Typography>}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      control={<SwitchCustom checked={isPolygonMode} onClick={handleChangePolygonMode} />}
                      label={<Typography classes={{ root: classes.selectText }}>{t('trans.polygon')}</Typography>}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Box>
          {dataPointClicked && isPointerMode ? (
            <Box className="info" sx={{ padding: '16px 8px 16px 8px', color: '#616161' }}>
              <Typography
                sx={{
                  fontWeight: '500',
                  display: 'flex',
                }}>
                {t('trans.tree_id')}:{' '}
                <Typography sx={{ color: '#23BE63', px: 1 }}>{dataPointClicked?.treeId}</Typography>
              </Typography>
              <Typography
                sx={{
                  fontWeight: '500',
                  display: 'flex',
                }}>
                {t('trans.longitude')}:{' '}
                <Typography sx={{ color: '#23BE63', px: 1 }}>{dataPointClicked?.longX}</Typography>
              </Typography>
              <Typography
                sx={{
                  fontWeight: '500',
                  display: 'flex',
                }}>
                {t('trans.latitude')}:{' '}
                <Typography sx={{ color: '#23BE63', px: 1 }}>{dataPointClicked?.latY} </Typography>
              </Typography>
              <Typography
                sx={{
                  fontWeight: '500',
                  display: 'flex',
                }}>
                {t('trans.status')}:
                <Typography sx={{ color: '#23BE63', px: 1 }}>{dataPointClicked?.status} </Typography>
              </Typography>
            </Box>
          ) : null}
          <Box sx={{ display: 'flex', pt: 2 }}>
            <Typography component="div">
              <Button variant="contained" sx={{ m: 1 }} onClick={onClickAddPoint}>
                {t('trans.add_point')}
              </Button>
            </Typography>
            {isDisplayDeleteButton ? (
              <Typography component="div">
                <Button color="error" variant="contained" sx={{ m: 1 }} onClick={handleDeletePoint}>
                  {t('trans.delete_point')}
                </Button>
              </Typography>
            ) : null}
          </Box>
          {isShowPopupAdd && <DialogStandPoint />}
          <DialogDeleteStandPoint
            open={open}
            setOpen={setOpen}
            idPoints={idPointsDelete}
            isPointerMode={isPointerMode}
          />
        </Box>
      </Grid>
    </Scrollbars>
  );
};

export default StandCountEdit;
