import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, Skeleton, Stack, Typography } from '@mui/material';
import { IStreamlineOrderData } from 'common/defines/clients';
import { MapEnum } from 'common/defines/constants';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { SortDateEnum } from 'components/MapView/RightBar/IssuesTab/interfaces';
import { QUERY_KEY } from 'constants/constants';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getStreamlineOrderList } from 'services/analytics/apiAnalyticsConfig.services';
import { getAllStreamlineOrderIntelligent } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeAllStreamlineOrderDisplay, rightBarSelector } from 'store/slices/rightBarSlice';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';
import StreamlineOrderPieChart from './StreamlineOrderPieChart';
import SwitchStreamlineOrderList from './SwitchStreamlineOrderList';

interface StreamLineOrderCollapseProps {
  isShowVector: boolean;
  mode: MapEnum;
}

const StreamLineOrderCollapse: FC<StreamLineOrderCollapseProps> = ({ isShowVector, mode }) => {
  const [isShowStreamlineList, setIsShowStreamlineList] = useState<boolean>(false);
  const { streamlineOrderDisplay, streamlineOrderSensorId: sensorId } = useAppSelector(rightBarSelector);
  const { selectedTaskId, isGeneratingStreamlineOrder } = useAppSelector(tilingMapSelector);
  const dispatch = useAppDispatch();
  const classes = useLayerSelectCardStyle();
  const { t } = useTranslation();

  const { isLoading: isLoadingMapView, isRefetching: isRefetchingMapView } = useQuery(
    [QUERY_KEY.GET_STREAMLINE_ORDER_LIST, sensorId],
    () => getStreamlineOrderList({ page: 1, perPage: 20, sortDate: SortDateEnum.DESC, sensorId }),
    {
      enabled: !!sensorId && mode === MapEnum.MAP_VIEW,
      onSuccess(res) {
        const resData = res.data?.docs as IStreamlineOrderData[];
        const filteredStreamlineOrderList = resData.map(({ order, colorCode, length, lengthPercentage, sensorId }) => ({
          order,
          colorCode,
          length,
          lengthPercentage,
          visible: false,
          sensorId,
        }));
        dispatch(changeAllStreamlineOrderDisplay(filteredStreamlineOrderList));
      },
    }
  );

  const { isLoading: isLoadingIntelligent, isRefetching: isRefetchingIntelligent } = useQuery(
    [QUERY_KEY.GET_STREAMLINE_ORDER_INTELLIGENT_LIST, selectedTaskId],
    () => getAllStreamlineOrderIntelligent(selectedTaskId),
    {
      enabled: !!selectedTaskId && mode === MapEnum.CROP_INTELLIGENT,
      onSuccess(res) {
        const resData = res.data as IStreamlineOrderData[];
        const filteredStreamlineOrderList = resData.map(({ order, colorCode, length, lengthPercentage, sensorId }) => ({
          order,
          colorCode,
          length,
          lengthPercentage,
          visible: false,
          sensorId,
        }));
        dispatch(changeAllStreamlineOrderDisplay(filteredStreamlineOrderList));
      },
    }
  );

  // clear streamline order data when sensorId is empty
  useEffect(() => {
    if (!sensorId) {
      dispatch(changeAllStreamlineOrderDisplay([]));
    }
  }, [dispatch, sensorId]);

  const isLoading =
    isLoadingMapView ||
    isLoadingIntelligent ||
    isRefetchingMapView ||
    isRefetchingIntelligent ||
    isGeneratingStreamlineOrder;
  const isDisplayedChart =
    sensorId || (selectedTaskId && (!!streamlineOrderDisplay.length || isGeneratingStreamlineOrder));

  return (
    <Collapse in={isShowVector} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
      {isDisplayedChart && (
        <Button
          startIcon={
            isShowStreamlineList ? (
              <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
            ) : (
              <ChevronRightIcon style={{ fontWeight: 'bold' }} />
            )
          }
          onClick={() => setIsShowStreamlineList(!isShowStreamlineList)}
          classes={{ root: classes.buttonTextStyle }}
          style={{ width: '100%' }}>
          <Typography
            className={classes.buttonTextStyle}
            style={{ marginRight: 'auto', fontWeight: '500', fontSize: '13px !important' }}>
            {t('mapView.streamline_order')}
          </Typography>
        </Button>
      )}
      <Collapse in={isShowStreamlineList} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
        {streamlineOrderDisplay?.length > 0 && (
          <>
            <Stack>
              {isLoading ? (
                <Box>
                  {Array(6)
                    .fill(null)
                    .map((_, i) => (
                      <Skeleton key={i} variant="rectangular" width="100%" sx={{ mt: '8px' }} />
                    ))}
                </Box>
              ) : (
                <SwitchStreamlineOrderList mode={mode} _id={selectedTaskId} />
              )}
            </Stack>
            <StreamlineOrderPieChart isLoading={isLoading} data={streamlineOrderDisplay} />
          </>
        )}
      </Collapse>
    </Collapse>
  );
};

export default StreamLineOrderCollapse;
