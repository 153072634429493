import { isEmpty, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import PolygonLayer from '../../../components/MapView/PolygonLayer';
import { useAppSelector } from '../../../store/hooks';
import { mapViewSelector } from '../../../store/slices/mapViewSlice';
import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

export const useCropCoverageAnalytics = ({ mapRef }: any) => {
  const { dataCropCoverage, plantationCropCoverage, colorBorderPlantation, isDisplayBoundaryLine, isLayer3D } =
    useAppSelector(mapViewSelector);

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();

  const { contourSetting } = useClientData();

  const polygonCropCoverageViewer = useCallback(
    (_item: any) => {
      return (
        <Source id={`${_item._id}_CropCoverage`} key={`${_item._id}_CropCoverage`} type="geojson" data={_item.geometry}>
          <Layer
            id={`${_item._id}-polygon_CropCoverage`}
            type="line"
            paint={{
              'line-color': _item.color,
              'line-opacity': isDisplayBoundaryLine ? 1 : 0,
              'line-width': 2,
            }}
            // source={`${_item._id}_CropCoverage`}
            layout={{ visibility: _item.visible ? 'visible' : 'none' }}
          />
          <Layer
            id={`${_item._id}_CropCoverage_fill-polygon`}
            type="fill"
            paint={{
              'fill-color': _item.color,
              'fill-opacity': !isLayer3D ? opacity2D : 0,
              'fill-antialias': true,
              'fill-translate-anchor': 'viewport',
            }}
            // source={`${_item._id}_CropCoverage`}
            layout={{ visibility: _item.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
          />
          <Layer
            id={`${_item._id}_CropCoverage_top-surface`}
            type="fill-extrusion"
            // source={`${_item._id}_CropCoverage`}
            beforeId={`${_item._id}_CropCoverage_fill-polygon`}
            paint={{
              'fill-extrusion-color': _item.color,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': contourSetting,
              'fill-extrusion-opacity': getTopSurfaceOpacity,
              'fill-extrusion-vertical-gradient': true,
            }}
            layout={{ visibility: _item.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
          />
          <Layer
            id={`${_item._id}_CropCoverage_side-surface`}
            type="fill-extrusion"
            beforeId={`${_item._id}_CropCoverage_top-surface`}
            // source={`${_item._id}_CropCoverage`}
            paint={{
              'fill-extrusion-color': isSameColorTopSurface ? _item.color : colorTransparent,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': 0,
              'fill-extrusion-opacity': getOpacityExtrusion,
              'fill-extrusion-vertical-gradient': true,
            }}
            layout={{ visibility: _item.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
          />
        </Source>
      );
    },
    [
      colorTransparent,
      contourSetting,
      getOpacityExtrusion,
      getTopSurfaceOpacity,
      isDisplayBoundaryLine,
      isLayer3D,
      opacity2D,
      isSameColorTopSurface,
    ]
  );

  const layerCropCoverage = useMemo(() => {
    if (isEmpty(dataCropCoverage)) {
      return null;
    }
    return (
      <>
        {plantationCropCoverage && (
          <PolygonLayer
            item={{ ...plantationCropCoverage, location: plantationCropCoverage.geometry }}
            isLastLevel
            mapRef={mapRef}
            colorBorder={colorBorderPlantation}
          />
        )}
        {sortBy(dataCropCoverage, 'type').map((_item: any) => polygonCropCoverageViewer(_item))}
      </>
    );
  }, [dataCropCoverage, polygonCropCoverageViewer, plantationCropCoverage, mapRef, colorBorderPlantation]);

  return { layerCropCoverage };
};
