import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { ICreateUserFormData } from 'common/defines/clients';
import AvatarUser from 'components/Common/AvatarUser';
import configs from 'constants/config';
import { QUERY_KEY } from 'constants/constants';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { queryMe, updateUserInfoNonAuth } from 'services/clients/apiClient.services';

const UserUpdateProfile = (props: any) => {
  const { open, onClose } = props;
  const [avaShow, setAvaShow] = useState('');

  const { data: userInfo, refetch } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const userProfileData = useMemo(() => {
    if (!userInfo) return {};
    return userInfo?.data;
  }, [userInfo]);

  const theme = useTheme();
  const token = localStorage.getItem('token');

  const styles = {
    userDialogContainer: {
      '& .MuiDialog-container': {
        '& MuiPaper-root': {
          overflowY: 'scroll',
          backgroundColor: theme.palette.background.paper + ' !important',
          backgroundImage: 'none !important',
          '-ms-overflow-style': 'none' /* IE and Edge */,
          scrollbarWidth: 'none' /* Firefox */,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      },
    },
    userDialogTitle: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      fontSize: '18px',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    userDialogCloseBtn: {
      p: 0,
      position: 'absolute',
      right: 8,
      top: 8,
      '.MuiSvgIcon-root': {
        width: '16px',
        height: '16px',
      },
    },
    userDialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    userDialogContentBox: {
      display: 'flex',
      flexDirection: 'column',
    },
    boxForm: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    avatarContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    formDetail: {
      display: 'flex',
      justifyContent: 'space-between',
      mt: '30px',
    },
    formDetailLeft: {
      flex: 1,
      mr: '10px',
    },
    formDetailRight: {
      flex: 1,
      ml: '10px',
    },
  };

  const { control, handleSubmit, reset, setValue } = useForm<ICreateUserFormData>({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      zipCode: '',
      state: '',
      country: '',
      jobTitle: '',
      company: '',
      department: '',
      workLocation: '',
      manager: '',
      status: true,
      joinDate: null,
      avatar: '',
      isSuperAdmin: false,
      isSuperUser: false,
      tokenExpiredIn: null,
    },
  });

  useEffect(() => {
    setValue('avatar', userProfileData?.avatar);
    setValue('name', userProfileData?.name);
    setValue('email', userProfileData?.email);
    setValue('phoneNumber', userProfileData?.phoneNumber);
    setValue('address', userProfileData?.address);
    setValue('zipCode', userProfileData?.zipCode);
    setValue('state', userProfileData?.state);
    setValue('country', userProfileData?.country);
    setValue('jobTitle', userProfileData?.jobTitle);
    setValue('company', userProfileData?.company);
    setValue('department', userProfileData?.department);
    setValue('workLocation', userProfileData?.workLocation);
    setValue('manager', userProfileData?.manager);
    setValue('status', userProfileData?.status);
    setValue('joinDate', userProfileData?.joinDate);
    setValue('isSuperAdmin', userProfileData?.isSuperAdmin);
    setValue('isSuperUser', userProfileData?.isSuperUser);
    setValue('tokenExpiredIn', userProfileData?.tokenExpiredIn);
  }, [setValue, userProfileData]);

  // Call PATCH
  const useUpdateProfileData = () => {
    return useMutation(
      (param: ICreateUserFormData) =>
        updateUserInfoNonAuth({
          ...param,
          id: userProfileData?._id,
        }),
      {
        onSuccess: () => {
          toast.success('Update profile success', { toastId: 1 });
          refetch();
          onClose();
        },
        onError: (err: any) => {
          console.log(err);
        },
      }
    );
  };

  const mutationUpdateProfileData = useUpdateProfileData();

  const handleSubmitForm: SubmitHandler<ICreateUserFormData> = (data) => {
    mutationUpdateProfileData.mutate({ ...data });
  };

  const handleClose = () => {
    onClose();
  };

  const handleChangeField = (event: ChangeEvent<HTMLInputElement>, fieldName: keyof ICreateUserFormData) => {
    const trimInputValue = event.target.value.replace(/\s+/g, ' ');
    setValue(fieldName, trimInputValue);
  };

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const file = event.target.files;
    if (!file) return;
    const newFile = file[0];
    (newFile as any).preview = URL.createObjectURL(newFile);
    const formData = new FormData();
    formData.append('file', newFile);

    setAvaShow((newFile as any).preview);
    fetch(`${configs.API_DOMAIN}/media/uploadGeneral`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        value = result.path;
        setValue('avatar', value);
        return value;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    return () => {
      avaShow && URL.revokeObjectURL((avaShow as any).preview);
    };
  }, [avaShow]);

  useEffect(() => {
    if (!userProfileData) return;
    reset({
      name: userProfileData.name || '',
      email: userProfileData.email || '',
      phoneNumber: userProfileData.phoneNumber || '',
      address: userProfileData.address || '',
      zipCode: userProfileData.zipCode || '',
      state: userProfileData.state || '',
      country: userProfileData.country || '',
      jobTitle: userProfileData.jobTitle || '',
      company: userProfileData.company || '',
      department: userProfileData.department || '',
      workLocation: userProfileData.workLocation || '',
      manager: userProfileData.manager || '',
      clientId: userProfileData.clientId || [],
      status: userProfileData.status || true,
      joinDate: userProfileData.joinDate || '',
      avatar: userProfileData.avatar || '',
      isSuperAdmin: userProfileData.isSuperAdmin || false,
      isSuperUser: userProfileData.isSuperUser || false,
      tokenExpiredIn: userProfileData.tokenExpiredIn || '',
    });
    setAvaShow(userProfileData.avatar);
  }, [userProfileData, reset, setValue]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      open={open}
      sx={{
        ...styles.userDialogContainer,
      }}>
      <DialogTitle
        sx={{
          ...styles.userDialogTitle,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}>
        Edit Profile
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          ...styles.userDialogCloseBtn,
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[500]),
        }}>
        <CloseIcon />
      </IconButton>
      {true ? (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
          }}>
          <Typography
            sx={{
              ...styles.userDialogContent,
            }}>
            <DialogContent
              sx={{
                ...styles.userDialogContentBox,
              }}>
              <Box component="form" onSubmit={handleSubmit(handleSubmitForm)}>
                <Box
                  sx={{
                    ...styles.boxForm,
                  }}>
                  {/* Avatar */}
                  <Controller
                    name="avatar"
                    control={control}
                    render={({ field }) => (
                      <Box
                        sx={{
                          ...styles.avatarContainer,
                        }}>
                        <InputLabel>Profile Photo</InputLabel>
                        <AvatarUser avatar={avaShow} handleChangeFile={handleChangeFile} />
                      </Box>
                    )}
                  />
                  {/* Button */}
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </Box>
                {/* Form details */}
                <Box
                  sx={{
                    ...styles.formDetail,
                  }}>
                  {/* Form left: user, fullName, Gender */}
                  <Box
                    sx={{
                      ...styles.formDetailLeft,
                    }}>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        mb: '8px',
                      }}>
                      Basic Information
                    </Typography>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="name">
                          <InputLabel>
                            Full Name{' '}
                            <span
                              style={{
                                color: 'red',
                              }}>
                              *
                            </span>{' '}
                          </InputLabel>
                          <TextField
                            required
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'name')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="email">
                          <InputLabel>
                            Email{' '}
                            <span
                              style={{
                                color: 'red',
                              }}>
                              *
                            </span>{' '}
                          </InputLabel>
                          <TextField
                            required
                            type="email"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'email')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="address"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="address">
                          <InputLabel>
                            Address{' '}
                            <span
                              style={{
                                color: 'red',
                              }}>
                              *
                            </span>{' '}
                          </InputLabel>
                          <TextField
                            required
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'address')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="phoneNumber">
                          <InputLabel>
                            Phone Number{' '}
                            <span
                              style={{
                                color: 'red',
                              }}>
                              *
                            </span>{' '}
                          </InputLabel>
                          <TextField
                            required
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'phoneNumber')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="zipCode"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="zipCode">
                          <InputLabel>Zip Code</InputLabel>
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'zipCode')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="state"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="state">
                          <InputLabel>State</InputLabel>
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'state')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="country">
                          <InputLabel>Country</InputLabel>
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'country')}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                  {/* Form right: email, phone, DOB */}
                  <Box
                    sx={{
                      ...styles.formDetailRight,
                    }}>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        mb: '8px',
                      }}>
                      Work Information
                    </Typography>
                    <Controller
                      name="jobTitle"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="jobTitle">
                          <InputLabel>Job Title</InputLabel>
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'jobTitle')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="department"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="department">
                          <InputLabel>Department</InputLabel>
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'department')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="company"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="company">
                          <InputLabel>Company</InputLabel>
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'company')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="manager"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="manager">
                          <InputLabel>Reporting To</InputLabel>
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            placeholder='Default: "None"'
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'manager')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="workLocation"
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            mb: '10px',
                          }}
                          key="workLocation">
                          <InputLabel>Work Location</InputLabel>
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'workLocation')}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="joinDate"
                      control={control}
                      render={({ field }) => (
                        <Box>
                          <InputLabel>Joined Date</InputLabel>
                          <Box
                            sx={{
                              display: 'flex',
                            }}>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <DatePicker
                                {...field}
                                disableFuture
                                renderInput={(params: TextFieldProps) => (
                                  <TextField
                                    type="text"
                                    fullWidth
                                    {...params}
                                    size="small"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'joinDate')}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </DialogContent>
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default UserUpdateProfile;
