import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAnalyticBoxPlot,
  getAnalyticPolygon,
  getDataVigorAnalytic,
  updateInterval,
} from 'services/analytics/apiAnalyticsConfig.services';
import { getChartsVigor } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeChartDataVigorPreview,
  changeDataPolygonVigorAnalytics,
  changeDataVigorAnalytics,
  changeDataVigorInterval,
  vigorAnalyticsSelector,
} from 'store/slices/map-view/vigorAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { VigorLayer } from '../../Layer/Vigor';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const VigorAnalytics = ({ uploadDate }: any) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { dataVigorInterval, dataVigorPolygon, chartDataVigorPreview } = useAppSelector(vigorAnalyticsSelector);

  const { data: dataVigorBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.VIGOR_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'vigor'),
    { enabled: !!analyticId }
  );
  const [currentPage, setCurrentPage] = useState(1);

  useQuery(
    [QUERY_KEY.VIGOR_POLYGON, analyticId, currentPage],
    () => getAnalyticPolygon(analyticId || '', 'vigor', currentPage),
    {
      enabled: !!analyticId,
      onSuccess: (data: any) => {
        if (data.data?.nextPage && data.data.nextPage !== currentPage) {
          setCurrentPage(data.data?.nextPage);
        }
        if (data.data?.docs) {
          dispatch(changeDataPolygonVigorAnalytics([...dataVigorPolygon, ...data.data?.docs]));
        }
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY.CHARTS_VIGOR_ANALYTIC, isDefaultInterval]).then(() => {});
      },
    }
  );

  useQuery(
    [QUERY_KEY.VIGOR_ANALYSIS, analyticId],
    () => {
      return getDataVigorAnalytic(analyticId || '');
    },
    {
      enabled: !!analyticId,
      onSuccess: (mainData) => {
        dispatch(changeDataVigorAnalytics(mainData?.data || []));
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_VIGOR_ANALYTIC, isDefaultInterval],
    () => getChartsVigor(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (data) => {
        dispatch(
          changeDataVigorInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataVigorInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataVigorBoxPlot?.data) return [];
    return dataVigorBoxPlot.data;
  }, [dataVigorBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataVigorPreview ? chartDataVigorPreview?.chartData : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE}
                  getCharts={getChartsVigor}
                  intervalRange={
                    chartDataVigorPreview
                      ? chartDataVigorPreview?.intervalLimit?.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_VIGOR_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataVigorPreview}
                  showXAxis
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<VigorLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
