import { useTheme } from '@mui/material';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { Layer, Source } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

const ShowBoundaryLayer = () => {
  const theme = useTheme();
  const {
    drawingBoundary: { isShow: isShowDrawing, geometry },
  } = useAppSelector(tilingMapSelector);
  const { selectedTask } = useQueryListTaskOfField();

  const boundary = selectedTask?.boundary;

  const defaultBorder = theme.palette.primary.main;

  const geojson: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [{ type: 'Feature', geometry: geometry, properties: {} }],
  };

  return !isShowDrawing && boundary ? (
    <Source id={`polygon_boundary_source`} type="geojson" data={boundary || geojson}>
      {/* 2d contour layer */}
      <Layer
        id={`polygon_boundary_layer`}
        type="line"
        source={`polygon_boundary_source`}
        paint={{
          'line-color': ['get', 'color'] || defaultBorder,
          'line-opacity': 0.8,
          'line-width': 2,
          'line-dasharray': [1, 0],
        }}
        layout={{ visibility: 'visible' }}
      />
    </Source>
  ) : null;
};

export default ShowBoundaryLayer;
