import { TabContext, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import AppLayout from 'components/AppLayout';
import { ClientsListing } from 'components/ClientsInfo/ClientsListing';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { AnalyticsConfig } from 'components/Dashboard/AnalyticsConfig';
import ClientAuditTrail from 'components/Dashboard/AuditTrail/ClientAuditTrail';
import { ClientApis } from 'components/Dashboard/ClientApis/ClientApis';
import { ClientSettings } from 'components/Dashboard/ClientSettings/ClientSettings';
import { TabsBar } from 'components/Dashboard/TabsBar';
import { UserManagement } from 'components/Dashboard/UserProfile/UserManagement';
import OverviewClient from 'components/OverviewClient';
import { QUERY_KEY } from 'constants/constants';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUIDisplay, queryMe } from 'services/clients/apiClient.services';

const TabPanelCustom = styled(TabPanel)(({ theme }) => ({
  ...theme.mixins.toolbar,
  paddingBottom: 0,
}));

const Navbars = [
  'Overview',
  'Data Configuration',
  'User Management',
  'Activity',
  'Client Settings',
  'Audit Trail',
  'Client Apis',
];

export const DashboardPage = (): ReactElement => {
  const { clientId } = useParams();
  const { data: userInfo, isLoading: loadingMe } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const { t } = useTranslation();

  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);

  const { data: userInfoByClient, isLoading: loadingUI } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && userId !== '' && !isSuperAdmin && !isSuperUser,
    }
  );

  const flagTabData = useMemo(() => {
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser) {
      return Navbars;
    }
    if (!userInfoByClient?.data) return [];
    const dataRoleValue = userInfoByClient?.data?.flagTab;
    if (dataRoleValue) {
      return dataRoleValue;
    } else {
      return [];
    }
  }, [userInfo, userInfoByClient]);

  const newNavbars = useMemo(() => {
    const newNavbarsData: string[] = [];
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser || flagTabData.length === 0) {
      return flagTabData;
    } else {
      Navbars.forEach((item: any) => {
        switch (item) {
          case 'Overview':
            if (flagTabData.includes('DASHBOARD-OVERVIEW')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'User Management':
            if (flagTabData.includes('DASHBOARD-USER-MANAGEMENT')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Data Configuration':
            if (flagTabData.includes('DASHBOARD-DATA-CONFIGURATION')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Activity':
            if (flagTabData.includes('DASHBOARD-ACTIVITY')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Client Settings':
            if (flagTabData.includes('DASHBOARD-CLIENT-SETTINGS')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Audit Trail':
            if (flagTabData.includes('DASHBOARD-AUDIT-TRAIL')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Client Apis':
            if (flagTabData.includes('DASHBOARD-CLIENT-APIS')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          default:
            return '';
        }
      });
      return newNavbarsData;
    }
  }, [flagTabData, userInfo]);

  const [value, setValue] = useState(newNavbars[0] || flagTabData[0] || Navbars[0]);

  return (
    <>
      {loadingMe || loadingUI ? (
        <WaitingPageAPI height="100vh" width="100vw" />
      ) : (
        <AppLayout>
          <TabsBar tab={value} setTab={setValue} />
          <ClientsListing tab={value} />
          <TabContext value={value}>
            <TabPanelCustom value="Overview">
              <OverviewClient />
              {/* Overview */}
            </TabPanelCustom>
            <TabPanelCustom value="Data Configuration">
              <AnalyticsConfig />
            </TabPanelCustom>
            <TabPanelCustom value="User Management">
              <UserManagement />
            </TabPanelCustom>
            <TabPanelCustom value="Activity">{t('dashboard.activity')}</TabPanelCustom>
            <TabPanelCustom value="Client Settings">
              <ClientSettings />
            </TabPanelCustom>
            <TabPanelCustom value="Audit Trail">
              <ClientAuditTrail />
            </TabPanelCustom>
            <TabPanelCustom value="Client Apis">
              <ClientApis />
            </TabPanelCustom>
          </TabContext>
        </AppLayout>
      )}
    </>
  );
};
