import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export interface IAccount {
  email: string;
  name: string;
  avatar: string;
}

export interface IAuthState {
  token?: string;
  account?: IAccount;
  isLoggingIn: boolean;
  isLoginSuccess: boolean;
  isLoginError: boolean;
  loginErrorMessage: string;
}

export const initialState: IAuthState = {
  token: undefined,
  account: undefined,
  isLoggingIn: false,
  isLoginSuccess: false,
  isLoginError: false,
  loginErrorMessage: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => {
      state.isLoginError = false;
      state.isLoginSuccess = false;
      state.isLoggingIn = false;
      return state;
    },
    setAccountInfo: (state, { payload }) => {
      state.account = {
        email: payload.user.email,
        name: payload.user.name,
        avatar: payload.user.avatar,
      };
      state.isLoggingIn = false;
    },
  },
});

export const { clearAuthState, setAccountInfo } = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

export default authSlice.reducer;
