import { Box, Grid, MenuItem, Popover, Select, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { FC, MouseEvent, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { DrawIssueShapeEnum, LineStyleEnum } from '../../../interfaces';

interface SelectShapeStyleProps {
  formik: any;
  pickedColor: string;
  setPickedColor: (arg: string) => void;
}

const StyledPopover = styled(Popover)({
  '& .MuiPopover-paper': {
    overflow: 'visible',
    borderRadius: '8px',
  },
});

const SelectShapeStyle: FC<SelectShapeStyleProps> = ({ formik, pickedColor, setPickedColor }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { t } = useTranslation();
  const isShowPickColor = Boolean(anchorEl);
  const id = isShowPickColor ? 'simple-popover' : undefined;

  const widthList = [
    { key: 1, value: 1 },
    { key: 2, value: 2 },
    { key: 3, value: 3 },
    { key: 4, value: 4 },
    { key: 5, value: 5 },
    { key: 6, value: 6 },
  ];

  const lineStyleList = [
    { key: 1, value: LineStyleEnum.SOLID },
    { key: 2, value: LineStyleEnum.DASHED },
  ];

  const openPickColor = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isShow = () => {
    const shapeList = [DrawIssueShapeEnum.POINT, DrawIssueShapeEnum.IMAGE];
    return !shapeList.includes(formik.values.type);
  };

  return (
    <>
      <Grid item xs={3}>
        <Typography sx={{ fontWeight: '500' }}>{t('trans.style')}: </Typography>
      </Grid>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={3} display="flex" flexDirection="row" alignItems="center">
            <Typography sx={{ display: 'inline-block', verticalAlign: 'center', mr: '8px' }}>
              {t('trans.color')}
            </Typography>
            <Box
              sx={{
                backgroundColor: pickedColor,
                height: '37px',
                width: '100%',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={openPickColor}></Box>
            <StyledPopover
              id={id}
              open={isShowPickColor}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <HexColorPicker color={pickedColor} onChange={setPickedColor} style={{ overflow: 'visible' }} />
            </StyledPopover>
          </Grid>
          {isShow() && (
            <Grid item xs={4} display="flex" flexDirection="row" alignItems="center" pl="16px">
              <Typography sx={{ display: 'inline-block', verticalAlign: 'center', mr: '8px' }}>
                {t('trans.width')}
              </Typography>
              <Select
                sx={{ display: 'inline-block', minWidth: 0 }}
                fullWidth
                size="small"
                id="width"
                name="width"
                value={formik.values.width}
                onChange={formik.handleChange}>
                {widthList.map((item) => (
                  <MenuItem key={item.key} value={item.value}>
                    {item.value}px
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {isShow() && (
            <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', pl: '16px' }}>
              <Typography sx={{ display: 'inline-block', verticalAlign: 'center', mr: '8px' }}>
                {t('trans.line')}
              </Typography>
              <Select
                sx={{ display: 'inline-block', minWidth: 0 }}
                fullWidth
                size="small"
                id="style"
                name="style"
                value={formik.values.style}
                onChange={formik.handleChange}>
                {lineStyleList.map((item) => (
                  <MenuItem key={item.key} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SelectShapeStyle;
