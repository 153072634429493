import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { MapTypeSelection } from 'components/Minimap/Minimap';
import { CreateAndEditMode, IGenerateVectorConfig, Map2dOptionEnum } from 'interfaces/workspace';
import { RootState } from 'store/store';
import { IImageInfo } from './../../interfaces/workspace';

export interface IDrawingBoundary {
  geometry: {
    type: any;
    coordinates: any[];
  };
  isShow: boolean;
  _id: string;
  isLoading: boolean;
}

export interface IShow3dModal {
  isShowPotree: boolean;
  isShow3dMesh: boolean;
  isShow3dMapbox: boolean;
}

interface ICreateAndEditFieldModal {
  mode: CreateAndEditMode;
  isShow: boolean;
}

interface IOptions2d {
  name: Map2dOptionEnum;
  value: boolean;
  label: string;
  isVisible: boolean;
}

interface ITilingMap {
  selectedFieldId: string;
  newestCreatedFieldId: string;
  miniMapTypeSelected: MapTypeSelection;
  mapStyleSelected: string;
  isResetMapData: boolean;
  isGeneratingAnalysis: boolean;
  isGeneratingStreamlineOrder: boolean;
  analysisId: string;
  selectedAnalysisName: TYPE_ANALYTICS_MAP_VIEW | '';
  selectedTaskId: string;
  rightBar: {
    options2d: IOptions2d[];
    selectedTabHistory: number[];
    show3dModal: IShow3dModal;
    isClickedGenerateAnalytic: boolean;
    isClickedGenerateStreamlineOrder: boolean;
    dataVectorIntelligent: IGenerateVectorConfig[];
  };
  drawingBoundary: IDrawingBoundary;
  flyTo: {
    isDone: boolean;
  };
  upload: {
    rawImageList: File[];
    reconstructedImageList: IImageInfo[];
  };
  createAndEditFieldModal: ICreateAndEditFieldModal;
  isAddDataMode: boolean;
}

const initialState: ITilingMap = {
  selectedFieldId: '',
  newestCreatedFieldId: '',
  isResetMapData: false,
  miniMapTypeSelected: {
    type: '',
    image: '',
  },
  mapStyleSelected: '',
  isGeneratingAnalysis: false,
  isGeneratingStreamlineOrder: false,
  analysisId: '',
  selectedAnalysisName: '',
  selectedTaskId: '',
  rightBar: {
    options2d: [
      { name: Map2dOptionEnum.orthophoto, value: true, label: 'Orthoimage - RGB Multispectral', isVisible: true },
      { name: Map2dOptionEnum.dsm, value: false, label: 'Digital Surface Model - DSM', isVisible: true },
      { name: Map2dOptionEnum.dtm, value: false, label: 'Digital Terrain Model - DTM', isVisible: true },
    ],
    show3dModal: {
      isShowPotree: false,
      isShow3dMesh: false,
      isShow3dMapbox: false,
    },
    selectedTabHistory: [0],
    isClickedGenerateAnalytic: false,
    isClickedGenerateStreamlineOrder: false,
    dataVectorIntelligent: [],
  },
  drawingBoundary: {
    geometry: {
      type: 'Polygon',
      coordinates: [],
    },
    isShow: false,
    _id: '',
    isLoading: false,
  },
  flyTo: {
    isDone: true,
  },
  upload: {
    rawImageList: [],
    reconstructedImageList: [],
  },
  createAndEditFieldModal: {
    mode: CreateAndEditMode.FIELD,
    isShow: false,
  },
  isAddDataMode: false,
};

export const tilingMapSlice = createSlice({
  name: 'tilingMap',
  initialState,
  reducers: {
    changeSelectedFieldId: (state, action: PayloadAction<string>) => {
      state.selectedFieldId = action.payload;
    },
    changeMapTypeSelected: (state, action: PayloadAction<MapTypeSelection>) => {
      state.miniMapTypeSelected = action.payload;
    },
    changeMapStyleSelected: (state, action: PayloadAction<string>) => {
      state.mapStyleSelected = action.payload;
    },
    changeNewestCreatedFieldId: (state, action: PayloadAction<string>) => {
      state.newestCreatedFieldId = action.payload;
    },
    changeRightBar2dOptionsValue: (state, action: PayloadAction<{ name: Map2dOptionEnum; value: boolean }>) => {
      state.rightBar.options2d = state.rightBar.options2d.map((item) =>
        item.name === action.payload.name ? { ...item, value: action.payload.value } : { ...item, value: false }
      );
    },
    changeRightBar2dOptionsVisible: (state, action: PayloadAction<Pick<IOptions2d, 'name' | 'isVisible'>>) => {
      state.rightBar.options2d = state.rightBar.options2d.map((item) =>
        item.name === action.payload.name ? { ...item, isVisible: action.payload.isVisible } : item
      );
    },
    changeSelectedTabHistory: (state, action: PayloadAction<{ id: number }>) => {
      const lastTabIndex = state.rightBar.selectedTabHistory.slice(-1)[0];
      if (lastTabIndex !== action.payload.id) {
        state.rightBar.selectedTabHistory = [...state.rightBar.selectedTabHistory, action.payload.id];
      }
    },
    changeIsClickedGenerateAnalytic: (state, action: PayloadAction<boolean>) => {
      state.rightBar.isClickedGenerateAnalytic = action.payload;
    },
    changeIsClickedGenerateStreamlineOrder: (state, action: PayloadAction<boolean>) => {
      state.rightBar.isClickedGenerateStreamlineOrder = action.payload;
    },
    changeSelectedAnalysisName: (state, action: PayloadAction<TYPE_ANALYTICS_MAP_VIEW | ''>) => {
      state.selectedAnalysisName = action.payload;
    },
    changeDrawingBoundary: (state, action: PayloadAction<Partial<IDrawingBoundary>>) => {
      state.drawingBoundary = { ...state.drawingBoundary, ...action.payload };
    },
    changeFlyingToState: (state, action: PayloadAction<{ isDone: boolean }>) => {
      state.flyTo = action.payload;
    },
    changeIsResetMapData: (state, action: PayloadAction<boolean>) => {
      state.isResetMapData = action.payload;
    },
    changeShow3dModal: (state, action: PayloadAction<Partial<IShow3dModal>>) => {
      state.rightBar.show3dModal = { ...state.rightBar.show3dModal, ...action.payload };
    },
    changeIsGeneratingAnalysis: (state, action: PayloadAction<boolean>) => {
      state.isGeneratingAnalysis = action.payload;
    },
    changeIsGeneratingStreamlineOrder: (state, action: PayloadAction<boolean>) => {
      state.isGeneratingStreamlineOrder = action.payload;
    },
    changeAnalysisId: (state, action: PayloadAction<string>) => {
      state.analysisId = action.payload;
    },
    changeUploadRawImageList: (state, action: PayloadAction<File[]>) => {
      state.upload.rawImageList = action.payload;
    },
    changeUploadReconstructedImageList: (state, action: PayloadAction<IImageInfo[]>) => {
      state.upload.reconstructedImageList = action.payload;
    },
    changeIsAddDataMode: (state, action: PayloadAction<boolean>) => {
      state.isAddDataMode = action.payload;
    },
    changeCreateAndEditFieldModal: (state, action: PayloadAction<Partial<ICreateAndEditFieldModal>>) => {
      state.createAndEditFieldModal = { ...state.createAndEditFieldModal, ...action.payload };
    },
    changeSelectedTaskId: (state, action: PayloadAction<string>) => {
      state.selectedTaskId = action.payload;
    },
    changeDataVectorIntelligent: (state, action: PayloadAction<IGenerateVectorConfig[]>) => {
      state.rightBar.dataVectorIntelligent = action.payload;
    },
    clearAllTilingMapData: (state) => {
      return {
        ...initialState,
        miniMapTypeSelected: state.miniMapTypeSelected,
        mapStyleSelected: state.mapStyleSelected,
      };
    },
  },
});

export const {
  changeSelectedFieldId,
  changeMapTypeSelected,
  changeMapStyleSelected,
  changeNewestCreatedFieldId,
  changeRightBar2dOptionsValue,
  changeRightBar2dOptionsVisible,
  changeSelectedTabHistory,
  changeSelectedAnalysisName,
  changeDrawingBoundary,
  changeFlyingToState,
  changeIsResetMapData,
  clearAllTilingMapData,
  changeIsGeneratingAnalysis,
  changeIsGeneratingStreamlineOrder,
  changeAnalysisId,
  changeShow3dModal,
  changeUploadRawImageList,
  changeUploadReconstructedImageList,
  changeIsClickedGenerateAnalytic,
  changeIsClickedGenerateStreamlineOrder,
  changeCreateAndEditFieldModal,
  changeSelectedTaskId,
  changeIsAddDataMode,
  changeDataVectorIntelligent,
} = tilingMapSlice.actions;

export const tilingMapSelector = (state: RootState) => state.tilingMap;

export default tilingMapSlice.reducer;
