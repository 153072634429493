import { Box, Typography } from '@mui/material';
import { MapEnum } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import { Expression } from 'mapbox-gl';
import { FC, useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getVectorContour } from 'services/analytics/apiAnalyticsConfig.services';
import { getAllVectorContourIntelligent } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import {
  changeAllLinesVectorDisplay,
  changeAllStreamlineOrderDisplay,
  resetDataVectorContour,
  rightBarSelector,
  updateDataVectorContour,
} from 'store/slices/rightBarSlice';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';
import { StyledPopup } from '../RightBar/IssuesTab/components/show-issue-shape/issue-mark';

interface IFeatures {
  elev: number;
  geometry: Object;
  _id?: string;
}

export interface IHoverVectorInfo {
  lat: number;
  lng: number;
  elev: number;
}

interface VectorContourLayerProps {
  hoverVectorInfo?: IHoverVectorInfo;
  mode: MapEnum;
}

const VectorContourLayer: FC<VectorContourLayerProps> = ({ hoverVectorInfo, mode }) => {
  const [listElevator, setListElevator] = useState<number[]>([]);
  const [dataVectorList, setDataVectorList] = useState<IFeatures[]>([]);

  const dispatch = useAppDispatch();
  const { dataVectorContour } = useAppSelector(rightBarSelector);
  const { levelId } = useAppSelector(mapViewSelector);
  const { vectorId, size, color, page = 1 } = dataVectorContour || {};
  const { selectedTaskId } = useAppSelector(tilingMapSelector);

  const isGradientColor = color && color.includes('linear-gradient');

  useQuery(
    [QUERY_KEY.GET_VECTOR_CONTOUR, page, vectorId, size],
    () => getVectorContour({ page: page!, sensorId: vectorId!, size }),
    {
      enabled: !!vectorId && !!color && !!page && !!vectorId && mode === MapEnum.MAP_VIEW,
      keepPreviousData: true,
      onSuccess: (res) => {
        dispatch(updateDataVectorContour({ ...dataVectorContour, isLoading: false }));
        const featuresList = res.data?.docs as IFeatures[];
        setDataVectorList((prev) => [...prev, ...featuresList]);
        if (res.data?.totalPages > page) {
          dispatch(updateDataVectorContour({ ...dataVectorContour, page: page + 1 }));
        }
      },
    }
  );

  useQuery(
    [QUERY_KEY.GET_VECTOR_CONTOUR_INTELLIGENT, selectedTaskId, size],
    () => getAllVectorContourIntelligent(selectedTaskId, size),
    {
      onSuccess(res) {
        const resData = res.data as IFeatures[];
        const newDataVectorList = resData.map(({ elev, geometry, _id }) => ({ elev, geometry, _id }));
        setDataVectorList(newDataVectorList);
        dispatch(updateDataVectorContour({ ...dataVectorContour, isLoading: false }));
      },
      enabled: !!selectedTaskId && mode === MapEnum.CROP_INTELLIGENT && !!size,
    }
  );

  // Reset vector contour when change date
  useEffect(() => {
    setDataVectorList([]);
    dispatch(resetDataVectorContour());
  }, [dispatch, selectedTaskId]);

  // reset list data when change vector size
  useEffect(() => {
    setDataVectorList([]);
  }, [size]);

  // reset vector map when change levelId
  useEffect(() => {
    dispatch(resetDataVectorContour());
    dispatch(changeAllStreamlineOrderDisplay([]));
    dispatch(changeAllLinesVectorDisplay([]));
  }, [dispatch, levelId]);

  // get list of elevator
  useEffect(() => {
    const newListElevator = dataVectorList.map((item) => item.elev).sort((a, b) => a - b);
    setListElevator(Array.from(new Set(newListElevator)));
  }, [dataVectorList]);

  const convertDataVector: any = () => {
    return {
      type: 'FeatureCollection',
      features: dataVectorList.map((item) => ({
        type: 'Feature',
        geometry: item.geometry,
        properties: {
          color,
          elev: item.elev,
        },
      })),
    };
  };

  const handleDisplayLogic = (trueValue: string | number, falseValue: string | number): Expression => {
    return [
      'case',
      ['==', ['%', ['get', 'elev'], 5], 0], // Check if value % 5 equals 0
      trueValue, // If divisible by 5
      falseValue, // If not divisible by 5
    ];
  };

  // convert color format
  const listColorAndPercent = () => {
    const colorReg = /(rgba\(.*?\) *\d*%)/gi;
    const listColor = color.match(colorReg);
    if (!Array.isArray(listColor)) return [];
    const convertedListColor = listColor.map((item) => {
      const colorAndPercent = item.replaceAll(', ', ',').split(' ');
      const color = colorAndPercent[0];
      let percent = 0;
      const rawPercentNumber = Number(colorAndPercent[1].replace('%', ''));
      if (!isNaN(rawPercentNumber)) percent = rawPercentNumber / 100;
      return { color, percent };
    });
    return convertedListColor;
  };

  const lineColorGradient = () => {
    const minElev = listElevator[0];
    const maxElev = listElevator[listElevator.length - 1];
    const listLineColor = listColorAndPercent().map((item) => [
      minElev + (maxElev - minElev) * item.percent,
      item.color,
    ]);
    return listLineColor.flat();
  };

  const lineColor: any =
    listElevator?.length && listElevator.length === 1
      ? listColorAndPercent()?.[0]?.color || color
      : isGradientColor
      ? ['interpolate', ['linear'], ['get', 'elev'], ...lineColorGradient()]
      : color || '#ff0000';

  return (
    <>
      {listElevator.length && (
        <>
          <Source id="vector_contour_source" type="geojson" data={convertDataVector()} lineMetrics={true}>
            <Layer
              id="elevator-number-layer"
              type="symbol"
              layout={{
                'text-field': ['get', 'elev'],
                'text-size': 12,
                'text-anchor': 'center',
              }}
              paint={{
                'text-color': handleDisplayLogic('black', 'transparent'),
                // Stroke effect
                'text-halo-width': 1, // Adjust the width of the stroke
                'text-halo-color': handleDisplayLogic('white', 'transparent'), // Set the color of the stroke
                'text-halo-blur': 3, // Add a blur effect to the stroke
              }}
            />
            <Layer
              id={`vector_contour`}
              key={`vector_contour`}
              type="line"
              layout={{
                'line-cap': 'round',
                'line-join': 'round',
              }}
              beforeId="elevator-number-layer"
              paint={{
                'line-opacity': 0.8,
                'line-width': handleDisplayLogic(3.6, 1.4),
                'line-color': lineColor,
              }}
            />
          </Source>
          {hoverVectorInfo?.elev && (
            <StyledPopup
              longitude={hoverVectorInfo?.lng}
              latitude={hoverVectorInfo?.lat}
              offset={[0, -10]}
              anchor="bottom"
              closeButton={false}>
              <Box
                sx={{
                  p: '8px',
                  backgroundColor: (theme) => theme.palette.background.default,
                  border: 0,
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  borderRadius: '5px',
                }}>
                <Typography
                  sx={{ color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'), display: 'inline' }}>
                  Elevation:&nbsp;
                </Typography>
                <Typography
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                    color: '#1C9855',
                    display: 'inline',
                  }}>
                  {hoverVectorInfo?.elev}
                </Typography>
              </Box>
            </StyledPopup>
          )}
        </>
      )}
    </>
  );
};

export default VectorContourLayer;
