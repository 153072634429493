import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { useAppSelector } from '../../../store/hooks';
import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

export const useWatershedBasinAnalytics = () => {
  const { isDisplayBoundaryLine, isLayer3D, watershedBasinAnalytics } = useAppSelector(mapViewSelector);

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();

  const { contourSetting } = useClientData();

  const polygonViewer = useCallback(
    (_item: any) => {
      return (
        <Source
          id={`${_item._id}_watershedBasin`}
          key={`${_item._id}_watershedBasin`}
          type="geojson"
          data={_item.geometry}>
          <Layer
            id={`${_item._id}-polygon`}
            type="line"
            paint={{
              'line-color': _item.color,
              'line-opacity': isDisplayBoundaryLine ? 1 : 0,
              'line-width': 2,
            }}
            source={`${_item._id}_watershedBasin`}
            layout={{ visibility: _item.visible ? 'visible' : 'none' }}
          />
          <Layer
            id={`${_item._id}_watershedBasin_fill-polygon`}
            type="fill"
            paint={{
              'fill-color': _item.color,
              'fill-opacity': !isLayer3D ? opacity2D : 0,
            }}
            source={`${_item._id}_watershedBasin`}
            layout={{ visibility: _item.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
          />
          <Layer
            id={`${_item._id}_watershedBasin_top-surface`}
            type="fill-extrusion"
            beforeId={`${_item._id}_watershedBasin_fill-polygon`}
            source={`${_item._id}_watershedBasin`}
            paint={{
              'fill-extrusion-color': _item.color,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': contourSetting,
              'fill-extrusion-opacity': getTopSurfaceOpacity,
              'fill-extrusion-vertical-gradient': false,
            }}
            layout={{ visibility: _item.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
          />
          <Layer
            id={`${_item._id}_watershedBasin_side-surface`}
            type="fill-extrusion"
            beforeId={`${_item._id}_watershedBasin_top-surface`}
            source={`${_item._id}_watershedBasin`}
            paint={{
              'fill-extrusion-color': isSameColorTopSurface ? _item.color : colorTransparent,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': 0,
              'fill-extrusion-opacity': getOpacityExtrusion,
              'fill-extrusion-vertical-gradient': false,
            }}
            layout={{ visibility: _item.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
          />
        </Source>
      );
    },
    [
      colorTransparent,
      contourSetting,
      getOpacityExtrusion,
      getTopSurfaceOpacity,
      isDisplayBoundaryLine,
      isLayer3D,
      opacity2D,
      isSameColorTopSurface,
    ]
  );

  const layerWatershedBasin = useMemo(() => {
    if (isEmpty(watershedBasinAnalytics)) {
      return null;
    }

    return <>{watershedBasinAnalytics.map((_item: any) => polygonViewer(_item))}</>;
  }, [watershedBasinAnalytics, polygonViewer]);

  return { layerWatershedBasin };
};
