import { Box } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getListDisplayedIssues } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { initial3dPolygonList, rightBarSelector } from 'store/slices/rightBarSlice';
import { DrawIssueShapeEnum, IIssue } from '../../interfaces';
import IssueMark from './issue-mark';
import ShowLineLayer from './ShowLineLayer';
import ShowPointLayer from './ShowPointLayer';
import ShowPolygonLayer from './ShowPolygonLayer';

const ShowIssueShape = () => {
  const [listIssues, setListIssues] = useState<IIssue[]>([]);
  const dispatch = useAppDispatch();

  const {
    issuesTab: { dropMarker, drawIssueShape },
  } = useAppSelector(rightBarSelector);
  const { levelId } = useAppSelector(mapViewSelector);

  useQuery([QUERY_KEY.GET_LIST_DISPLAYED_ISSUES, levelId], () => getListDisplayedIssues(levelId!), {
    onSuccess: (res: any) => {
      const resData = res.data as IIssue[];
      setListIssues(resData.filter((issue) => issue.status));
      const listPolygonIssues = resData.filter((issue) => issue.status && issue.type === DrawIssueShapeEnum.POLYGON);
      const filteredPolygon3dValues = listPolygonIssues.map((polygonIssue) => ({
        ...polygonIssue,
        id: polygonIssue._id,
      }));
      dispatch(initial3dPolygonList(filteredPolygon3dValues));
    },
    enabled: !!levelId,
  });

  useEffect(() => {
    const id = dropMarker.initialData?.id || drawIssueShape.initialData?.id;
    if (id) {
      const newListIssues = listIssues.filter((item) => item._id !== id);
      setListIssues(newListIssues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropMarker.initialData?.id, drawIssueShape.initialData?.id]);

  const filterComponentWithShapeType = (item: IIssue) => {
    switch (item.type) {
      case DrawIssueShapeEnum.POINT:
        return <ShowPointLayer item={item} />;
      case DrawIssueShapeEnum.IMAGE:
        return <IssueMark item={item} />;
      case DrawIssueShapeEnum.LINE:
        return <ShowLineLayer item={item} />;
      case DrawIssueShapeEnum.POLYGON:
      default:
        return <ShowPolygonLayer item={item} />;
    }
  };

  return (
    <Box>
      {listIssues.length && listIssues.map((item) => <Box key={item._id}>{filterComponentWithShapeType(item)}</Box>)}
    </Box>
  );
};

export default ShowIssueShape;
