/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CalenderIcon, DropdownIcon, TextIcon, UploadIcon } from '../../assets/icons';
import {
  IAdditionalInformationEdit,
  ICropTypes,
  InforArray,
  IOptionalPropertyType,
} from '../../common/defines/clients';
import { VALIDATION_REQUIRED } from '../../common/defines/constants';
import { PropertyTypeProps, TypeIcon } from './ClientsInfo.type';

interface ClientsAdditionalModalProps {
  open: boolean;
  handleClose: () => void;
  additionalField: (data: InforArray) => void;
  cropType?: ICropTypes;
  level?: number;
  dataAdditional?: IAdditionalInformationEdit | null;
}

const propertyType: PropertyTypeProps[] = [
  {
    value: 'Date',
    key: IOptionalPropertyType.DATE,
    icon: CalenderIcon,
  },
  {
    value: 'File Upload',
    key: IOptionalPropertyType.FILE,
    icon: UploadIcon,
  },
  {
    value: 'Select',
    key: IOptionalPropertyType.SELECT,
    icon: DropdownIcon,
  },
  {
    value: 'Text',
    key: IOptionalPropertyType.TEXT,
    icon: TextIcon,
  },
];

const inputLength = [
  {
    value: 'Long',
  },
  {
    value: 'Short',
  },
];

const clientsAdditionalValidationSchema = yup
  .object({
    name: yup.string().trim().required(VALIDATION_REQUIRED.propertyName),
    propertyType: yup
      .string()
      // .mixed<IOptionalPropertyType>()
      // .oneOf(Object.values(IOptionalPropertyType))
      .required(VALIDATION_REQUIRED.propertyType),
    selectOptions: yup
      .string()
      .trim()
      .when('propertyType', {
        is: (val: string) => val === IOptionalPropertyType.SELECT,
        then: yup
          .string()
          .required(VALIDATION_REQUIRED.valueOptions)
          .trim()
          .test('len', 'Must be less than 10 options', (val: any) => val.split(/,(?! )/, -1).length < 11),
        otherwise: yup.string().notRequired(),
      }),
    inputLength: yup
      .string()
      .trim()
      .when('propertyType', {
        is: (val: string) => val === IOptionalPropertyType.TEXT,
        then: yup.string().trim().required(VALIDATION_REQUIRED.inputLength),
        otherwise: yup.string().notRequired(),
      }),
  })
  .required();

export const ClientsAdditionalModal = ({
  open,
  handleClose,
  additionalField,
  cropType,
  level,
  dataAdditional,
}: ClientsAdditionalModalProps) => {
  const [selectedValue, setSelectedValue] = React.useState<string>('');
  const [showedValue, setShowedValue] = React.useState<string>('');

  const initialFormData = {
    name: '',
    propertyType: '' as IOptionalPropertyType,
    selectOptions: '',
    inputLength: '',
  };
  const typeIcon: TypeIcon = {
    [IOptionalPropertyType.DATE]: CalenderIcon,
    [IOptionalPropertyType.SELECT]: DropdownIcon,
    [IOptionalPropertyType.FILE]: UploadIcon,
    [IOptionalPropertyType.TEXT]: TextIcon,
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialFormData,
    },
    resolver: yupResolver(clientsAdditionalValidationSchema),
  });

  React.useEffect(() => {
    if (!dataAdditional) return reset({ ...initialFormData });
    reset({
      name: dataAdditional.name,
      propertyType: dataAdditional.type as IOptionalPropertyType,
      selectOptions: dataAdditional.type === IOptionalPropertyType.SELECT ? (dataAdditional.listValue as string) : '',
      inputLength: dataAdditional.type === IOptionalPropertyType.TEXT ? (dataAdditional.listValue as string) : '',
    });
    handleOnChangeType(dataAdditional.type);
  }, [dataAdditional]);

  const handleSubmitForm: SubmitHandler<InforArray> = (data) => {
    const { name, inputLength, selectOptions } = data;
    const formatedData = {
      ...data,
      propertyType: data.propertyType as IOptionalPropertyType,
      name: name.trim(),
      inputLength: inputLength ? inputLength.trim() : '',
      selectOptions: selectOptions ? selectOptions.trim() : '',
    };
    if (dataAdditional) {
      additionalField({ ...formatedData, cropType, level, index: dataAdditional?.index, id: dataAdditional?.id });
      setSelectedValue('');
      setShowedValue('');
      reset({ ...initialFormData });
      handleClose();
      return;
    }

    if (!dataAdditional) {
      additionalField({ ...formatedData, cropType, level });
      setSelectedValue('');
      setShowedValue('');
      reset({ ...initialFormData });
      handleClose();
      return;
    }
  };

  const handleOnChangeType = (type: IOptionalPropertyType) => {
    setValue('propertyType', type);
    const valueType = propertyType.find((val) => val.key === type);
    if (valueType) {
      setShowedValue(valueType.value);
    }
    setSelectedValue(type);
  };

  const handleCloseResetForm = () => {
    reset();
    handleClose();
    setShowedValue('');
    setSelectedValue('');
  };

  const handleChangeInputLength = (value: string) => {
    setValue('inputLength', value);
  };

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleCloseResetForm}
      sx={{
        '.MuiPaper-root': {
          width: '566px',
          height: '446px',
          backgroundColor: (theme) => theme.palette.background.paper,
          backgroundImage: 'none !important',
        },
      }}>
      <DialogTitle
        sx={{
          fontFamily: 'Barlow',
          fontStyle: 'Medium',
          fontSize: '20px',
          lineHeight: '26px',
          pt: 3.75,
        }}>
        Add New Property
        <IconButton
          aria-label="close"
          onClick={handleCloseResetForm}
          sx={{
            p: 0,
            position: 'absolute',
            right: 8,
            top: 8,
            '.MuiSvgIcon-root': {
              width: '16px',
              height: '16px',
            },
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Typography component="form" onSubmit={handleSubmit(handleSubmitForm)}>
        <DialogContent sx={{ px: 2.75 }}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <>
                <Typography
                  sx={{
                    fontFamily: 'Barlow',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '18px',
                    pb: '6px',
                  }}>
                  Name
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  // required
                  placeholder="Enter property name"
                  fullWidth
                  sx={{ pb: 2.5 }}
                  {...field}
                />
                {errors.name && (
                  <Typography
                    component="div"
                    className="errorMsg"
                    sx={{
                      fontFamily: 'Barlow',
                      fontSize: '14px',
                      lineHeight: '12px',
                      color: '#FF4646',
                      pb: 2.5,
                    }}>
                    {errors.name.message}
                  </Typography>
                )}
              </>
            )}
          />
          <Controller
            name="propertyType"
            control={control}
            render={({ field }) => (
              <>
                <Typography
                  sx={{
                    fontFamily: 'Barlow',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '18px',
                    pb: '6px',
                  }}>
                  Property Type
                </Typography>
                <Select
                  {...field}
                  size="small"
                  sx={{
                    width: '220px',
                    mb: 2.5,
                    '.MuiSelect-select': {
                      display: 'flex',
                    },
                    '.MuiTypography-root': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                  onChange={(e) => handleOnChangeType(e.target.value as IOptionalPropertyType)}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === ('' as IOptionalPropertyType)) {
                      return (
                        <Typography
                          component="div"
                          sx={{
                            color: '#CACED3',
                            fontSize: '16px',
                            lineHeight: '17px',
                            display: 'flex',
                            justifyItems: 'center',
                            alignItems: 'center',
                          }}>
                          Select a property type
                        </Typography>
                      );
                    }
                    return (
                      <>
                        <SvgIcon
                          component={typeIcon[selected as IOptionalPropertyType]}
                          inheritViewBox
                          sx={{ mr: '9px' }}
                        />
                        <Typography
                          component="div"
                          sx={{
                            fontFamily: 'Barlow',
                            color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                            fontSize: '14px',
                            lineHeight: '17px',
                          }}>
                          {showedValue}
                        </Typography>
                      </>
                    );
                  }}>
                  {propertyType.map((type) => (
                    <MenuItem sx={{ alignItems: 'center' }} key={type.key} value={type.key}>
                      <SvgIcon component={type.icon} inheritViewBox sx={{ mr: '9px' }} />
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: 'Barlow',
                          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                          fontSize: '14px',
                          lineHeight: '17px',
                        }}>
                        {type.value}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                {errors.propertyType && (
                  <Typography
                    component="div"
                    className="errorMsg"
                    sx={{
                      fontFamily: 'Barlow',
                      fontSize: '14px',
                      lineHeight: '12px',
                      color: '#FF4646',
                      pb: 2.5,
                    }}>
                    {errors.propertyType.message}
                  </Typography>
                )}
              </>
            )}
          />
          {selectedValue === IOptionalPropertyType.TEXT && (
            <Controller
              name="inputLength"
              control={control}
              render={({ field }) => (
                <>
                  <Typography
                    sx={{
                      fontFamily: 'Barlow',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '18px',
                      pb: '6px',
                    }}>
                    Input Length
                  </Typography>
                  <Autocomplete
                    size="small"
                    sx={{ width: '220px', pb: '13px' }}
                    options={inputLength}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.value}
                    filterSelectedOptions
                    onChange={(_, data) => handleChangeInputLength(data!.value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        placeholder="Select input length"
                        sx={{
                          '.MuiChip-root': {
                            backgroundColor: 'rgba(35,190,106,0.1)',
                          },
                          '.MuiChip-label': {
                            pr: 2.5,
                            color: theme.palette.primary.main,
                          },
                        }}
                      />
                    )}
                  />
                  {errors.inputLength && (
                    <Typography
                      component="div"
                      className="errorMsg"
                      sx={{
                        fontFamily: 'Barlow',
                        fontSize: '14px',
                        lineHeight: '12px',
                        color: '#FF4646',
                        pb: 2.5,
                      }}>
                      {errors.inputLength.message}
                    </Typography>
                  )}
                </>
              )}
            />
          )}
          {selectedValue === IOptionalPropertyType.SELECT && (
            <Controller
              name="selectOptions"
              control={control}
              render={({ field }) => (
                <>
                  <Typography
                    sx={{
                      fontFamily: 'Barlow',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '18px',
                      pb: '6px',
                    }}>
                    Value Options
                  </Typography>
                  <TextField
                    type="text"
                    size="small"
                    fullWidth
                    placeholder="Add up to 10 options"
                    sx={{ pb: 2.5 }}
                    {...field}
                  />
                  {errors.selectOptions && (
                    <Typography
                      component="div"
                      className="errorMsg"
                      sx={{
                        fontFamily: 'Barlow',
                        fontSize: '14px',
                        lineHeight: '12px',
                        color: '#FF4646',
                        pb: 2.5,
                      }}>
                      {errors.selectOptions.message}
                    </Typography>
                  )}
                </>
              )}
            />
          )}
          {(!selectedValue ||
            selectedValue === IOptionalPropertyType.DATE ||
            selectedValue === IOptionalPropertyType.FILE) && <Box sx={{ width: 'fit-content', height: '77px' }} />}
        </DialogContent>
        <DialogActions sx={{ pb: 5, pr: 2.75 }}>
          <Button
            sx={{
              background: 'white',
              width: '200px',
              height: '40px',
              align: 'right',
              color: '#616161',
              border: `1px solid ${theme.palette.divider}`,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#737373',
              },
            }}
            onClick={handleCloseResetForm}
            data-testid="back-btn">
            Back
          </Button>
          <Button
            type="submit"
            sx={{
              ml: 2.5,
              background: theme.palette.primary.main,
              width: '200px',
              height: '40px',
              align: 'right',
              color: 'white',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#737373',
              },
            }}
            data-testid="next-btn">
            Save
          </Button>
        </DialogActions>
      </Typography>
    </Dialog>
  );
};
