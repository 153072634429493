import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import useClientDataConfig from 'hooks/useClientDataConfig';
import { useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { changeFixedSizePointWaterUptake, changeZoomLayerWater, waterUptakeAnalyticsSelector } from '../../../store/slices/map-view/waterUptakeAnalytics';
import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

const styles = {
  tooltip: {
    borderRadius: '18px',
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#FFF',
      padding: 0,
      margin: 0,
    },
  },
};
const CustomTooltip = withStyles(styles)(Tooltip);

export const useWaterUptakeAnalytics = ({ mapRef }: any) => {
  const { isLayer3D, isDisplayBoundaryLine, isDisplay3DCylinder } = useAppSelector(mapViewSelector);
  const { dataGeoJsonPoint, dataGeoJsonPolygon, dataWaterUptakeInterval } = useAppSelector(waterUptakeAnalyticsSelector);

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();
  const { isFixedPoint, value } = useClientDataConfig()
  const { analyticId } = useAppSelector(mapViewSelector);
  const dispatch = useDispatch()

  const { contourSetting } = useClientData();

  const zoomLevel = useMemo(() => {
    if (!mapRef?.current) return 0;
    return mapRef?.current?.getZoom();
  }, [mapRef?.current?.getZoom()]);

  useEffect(() => {
    if (!mapRef?.current) return;

    if (isFixedPoint && value) {
      dispatch(changeFixedSizePointWaterUptake(value))
    } else
      dispatch(changeZoomLayerWater(zoomLevel))

  }, [dispatch, isFixedPoint, mapRef, value, zoomLevel, analyticId])

  const categoryByColor = useMemo(() => {
    let options = [] as any;
    dataWaterUptakeInterval?.range?.forEach((item: any) => {
      options.push({
        label: item.lable,
        color: item.color,
        visible: item.visible,
      });
    });
    return options;
  }, [dataWaterUptakeInterval]);

  const layerWaterUptake = useMemo(() => {
    if (!dataGeoJsonPoint && !dataGeoJsonPolygon) {
      return null;
    }

    return (
      <>
        {dataGeoJsonPoint &&
          <Source id={`water-uptake-polygon-point`} type="geojson" data={dataGeoJsonPoint}>
            {/* 2D Point */}
            <Layer
              id={`fill-top-surface-point`}
              type="fill"
              source={`water-uptake-circle-point`}
              paint={{
                'fill-color': ['get', 'color'],
              }}
              layout={{ visibility: !isLayer3D && !isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Cylinder */}
            <Layer
              id={`water-uptake-top-surface-point`}
              type="fill-extrusion"
              source={`water-uptake-circle-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`water-uptake-side-surface-point`}
              type="fill-extrusion"
              source={`water-uptake-circle-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                // getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
          </Source>}
        {dataGeoJsonPolygon &&
          <Source id={`water-uptake-source`} type="geojson" data={dataGeoJsonPolygon}>
            {/* 2D Contour */}
            <Layer
              id={`water-uptake_polygon`}
              type="line"
              paint={{
                'line-color': ['get', 'color'],
                'line-width': 2,
                'line-dasharray': [2, 2],
              }}
              source={`water-uptake-source`}
              layout={{ visibility: !isLayer3D && isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Filled contour */}
            <Layer
              id={`water-uptake_fill-polygon`}
              type="fill"
              paint={{
                'fill-color': ['get', 'color'],
                'fill-opacity': 0,
              }}
              source={`water-uptake-source`}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`water-uptake_top-surface`}
              type="fill-extrusion"
              beforeId={`water-uptake_fill-polygon`}
              source={`water-uptake_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`water-uptake_side-surface`}
              type="fill-extrusion"
              beforeId={`water-uptake_top-surface`}
              source={`water-uptake_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />

          </Source>
        }

        {/* {!isLayer3D &&
          zoomLevel > 19 &&
          waterUptakeGeoJSON?.features.map((item: any) => {
            return (
              <Marker
                key={`${item.properties._id}-water-uptake-radius-marker`}
                longitude={item.properties.longX}
                latitude={item.properties.latY}
                anchor={'center'}
                style={{ zIndex: 10 }}
                offset={[0, -5]}>
                <Typography
                  component="span"
                  style={{
                    width: 'fit-content',
                    height: '24px',
                    display: 'flex',
                    padding: '0 10px',
                    color: 'white',
                    cursor: 'pointer',
                  }}>
                  {item.properties.uptakeIn.toFixed(2)}m
                </Typography>
              </Marker>
            );
          })}
        {!isLayer3D &&
          zoomLevel > 20 &&
          waterUptakeGeoJSON?.features.map((item: any) => {
            return (
              <Marker
                key={`${item.properties._id}-water-uptake-name-marker`}
                longitude={item.properties.longX}
                latitude={item.properties.latY}
                anchor={'center'}
                style={{ zIndex: 10 }}
                offset={[0, -50]}>
                <Box
                  sx={{
                    backgroundColor: '#bdbeb8',
                    borderRadius: '10px',
                    borderWidth: 2,
                    borderColor: 'white',
                    borderStyle: 'solid',
                  }}>
                  <Typography
                    component="span"
                    style={{
                      height: '24px',
                      display: 'flex',
                      padding: '10px',
                      color: 'black',
                      cursor: 'pointer',
                    }}>
                    {item.properties.treeId}
                  </Typography>
                </Box>
              </Marker>
            );
          })}
        {!isLayer3D &&
          !isDisplayBoundaryLine &&
          waterUptakePointGeoJSON?.features.map((item: any) => {
            const pointInfo = categoryByColor.find((category: any) => {
              return category?.color === item?.properties?.color;
            });
            return pointInfo?.visible ? (
              <Marker
                key={`${item.properties._id}-vigor-radius-marker`}
                longitude={item.properties.longX}
                latitude={item.properties.latY}
                anchor={'center'}
                style={{ zIndex: 10 }}
                offset={[0, -5]}>
                <TooltipAnalytics item={item} pointInfo={pointInfo} isWaterUptake />
              </Marker>
            ) : null;
          })} */}
      </>
    );
  }, [
    colorTransparent, contourSetting, dataGeoJsonPoint, dataGeoJsonPolygon, getOpacityExtrusion, getTopSurfaceOpacity, isDisplay3DCylinder, isDisplayBoundaryLine, isLayer3D, isSameColorTopSurface, opacity2D
  ]);
  return { layerWaterUptake };
};
