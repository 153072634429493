import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAnalyticBoxPlot,
  getAnalyticPolygon,
  getDataCrownAreaAnalytic,
  updateInterval,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeChartDataCrownAreaPreview,
  changeDataCrownAreaAnalytics,
  changeDataCrownAreaInterval,
  changeDataPolygonCrownAreaAnalytics,
} from 'store/slices/map-view/crownAreaAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import { getChartsCrownArea } from '../../../../../../services/analytics/apiAnalyticsData.services';
import { crownAreaAnalyticsSelector } from '../../../../../../store/slices/map-view/crownAreaAnalytics';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { CrownAreaLayer } from '../../Layer/CrownArea';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const CrownAreaAnalytics = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { dataCrownAreaInterval, dataCrownAreaPolygon, chartDataCrownAreaPreview } =
    useAppSelector(crownAreaAnalyticsSelector);

  const { data: dataChlorophyllBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.CROWN_AREA_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'crown-area'),
    { enabled: !!analyticId }
  );

  const [currentPage, setCurrentPage] = useState(1);

  useQuery(
    [QUERY_KEY.CROWN_AREA_POLYGON, analyticId, currentPage],
    () => getAnalyticPolygon(analyticId || '', 'crown-area', currentPage),
    {
      enabled: !!analyticId,
      onSuccess: (data: any) => {
        if (data.data?.nextPage && data.data.nextPage !== currentPage) {
          setCurrentPage(data.data?.nextPage);
        }
        const crownAreaPolygonList = currentPage === 1 ? [] : dataCrownAreaPolygon;
        if (data.data?.docs) {
          dispatch(changeDataPolygonCrownAreaAnalytics([...crownAreaPolygonList, ...data.data?.docs]));
        }
      },
    }
  );

  useQuery(
    [QUERY_KEY.CROWN_AREA_ANALYTIC, analyticId],
    () => {
      return getDataCrownAreaAnalytic(analyticId || '');
    },
    {
      enabled: !!analyticId,
      onSuccess: (mainData) => {
        dispatch(changeDataCrownAreaAnalytics(mainData?.data || []));
      },
    }
  );
  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_CROWN_AREA_ANALYTIC, isDefaultInterval],
    () => getChartsCrownArea(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (data) => {
        dispatch(
          changeDataCrownAreaInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataCrownAreaInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.CHARTS_CROWN_AREA_ANALYTIC).then(() => {});
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataChlorophyllBoxPlot?.data) return [];
    return dataChlorophyllBoxPlot.data;
  }, [dataChlorophyllBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataCrownAreaPreview ? chartDataCrownAreaPreview?.chartData : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA}
                  getCharts={getChartsCrownArea}
                  intervalRange={
                    chartDataCrownAreaPreview
                      ? chartDataCrownAreaPreview.intervalLimit.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_CROWN_AREA_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataCrownAreaPreview}
                  showXAxis
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<CrownAreaLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
