import { Box, InputLabel, styled, useTheme } from '@mui/material';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'services/clients/apiClient.services';

const TitleLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 600,
  color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
  height: 'fit-content !important',
}));

const InfoLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
  height: 'fit-content !important',
  marginBottom: '40px',
}));

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
};

const SessionExpiredPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // Call Logout API
  const useLogoutUser = useMutation((sessionId: string) => logoutUser(sessionId || ''), {
    onSuccess: () => {
      navigate('/login');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });

  const handleLogOutCurrentUser = () => {
    const sessionId = localStorage.getItem('sessionLogin');
    const theme = localStorage.getItem('theme');
    localStorage.clear();
    localStorage.setItem('theme', theme || 'light');
    useLogoutUser.mutate(sessionId || '');
  };

  return (
    <Box
      sx={{
        ...styles.container,
      }}>
      <Box
        sx={{
          ...styles.box,
        }}>
        <TitleLabel>Your account has been logged in from another device.</TitleLabel>
        <InfoLabel>If you want to continue, please login again.</InfoLabel>
      </Box>
      <ButtonCustom
        backgroundColor={theme.palette.primary.main}
        colorLabel="#FFF"
        title="Login Again"
        border={`1px solid ${theme.palette.primary.main}`}
        sx={{ ml: 3 }}
        onClick={handleLogOutCurrentUser}
      />
    </Box>
  );
};

export default SessionExpiredPage;
