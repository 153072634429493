import { QUERY_KEY } from 'constants/constants';
import { IGenerateVectorConfig } from 'interfaces/workspace';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getVectorContourIntervalIntelligent } from 'services/workspaces';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

const useQueryVectorContourIntelligent = () => {
  const { selectedTaskId } = useAppSelector(tilingMapSelector);
  const [vectorIntervalIntelligent, setVectorIntervalIntelligent] = useState<IGenerateVectorConfig[]>([]);

  useQuery(
    [QUERY_KEY.GET_VECTOR_CONTOUR_INTERVAL_INTELLIGENT, selectedTaskId],
    () => getVectorContourIntervalIntelligent(selectedTaskId),
    {
      onSuccess(res) {
        const resData = res.data?.config as IGenerateVectorConfig[];
        setVectorIntervalIntelligent(resData);
      },
      enabled: !!selectedTaskId,
    }
  );

  return { vectorIntervalIntelligent };
};

export default useQueryVectorContourIntelligent;
