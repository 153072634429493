import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { FC } from 'react';
interface FieldDownloadProps {
  isIconActive: boolean;
}

const FieldDownload: FC<FieldDownloadProps> = ({ isIconActive }) => {
  return <FileDownloadOutlinedIcon sx={{ opacity: isIconActive ? 1 : 0.5 }} />;
};

export default FieldDownload;
