import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import PerpendicularLine from 'components/PerpendicularLine';
import StandCountMarker from 'components/StandCountMarker/StandCountMarker';
import ToolTipMaker from 'components/ToolTipMaker/ToolTipMaker';
import { QUERY_KEY } from 'constants/constants';
import { useLabelAnalytic } from 'hooks/map-view/useLabelAnalytic';
import { FC, useCallback } from 'react';
import { useQuery } from 'react-query';
import { getDataLabelMaker } from 'services/analytics/apiAnalyticsData.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';

interface MapViewMarkerProps {
  hoverInfo: any;
  mouseRef: any;
}

const MapViewMarker: FC<MapViewMarkerProps> = ({ hoverInfo, mouseRef }) => {
  const analyticListWithStandCountMarker = [
    TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT,
    TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT,
    TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION,
  ];

  const { typeLabel, categoryByColor } = useLabelAnalytic();
  const { analyticId, isInEditTab } = useAppSelector(mapViewSelector);
  const { analyticName } = useAppSelector(rightBarSelector);

  const getLabelMakerCallBack = useCallback(
    (analyticId: string) => {
      if (!typeLabel) return;
      return getDataLabelMaker(analyticId || '', typeLabel);
    },
    [typeLabel]
  );

  const { data: dataLabel } = useQuery(
    [QUERY_KEY.GET_LABEL_ANALYTIC, analyticId],
    () => getLabelMakerCallBack(analyticId || ''),
    {
      enabled: !!analyticId && !analyticListWithStandCountMarker.includes(analyticName as TYPE_ANALYTICS_MAP_VIEW),
    }
  );

  const isShowMarker = () => {
    if (analyticName === TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT) {
      return true;
    } else {
      return hoverInfo?.properties?.color && hoverInfo?.properties?.color !== 'transparent';
    }
  };

  return (
    <>
      {!isInEditTab &&
        hoverInfo &&
        isShowMarker() &&
        (analyticListWithStandCountMarker.includes(analyticName as TYPE_ANALYTICS_MAP_VIEW) ? (
          <>
            <StandCountMarker
              hoverInfo={hoverInfo}
              mouseRef={mouseRef}
              analyticName={analyticName as TYPE_ANALYTICS_MAP_VIEW}
            />
            {analyticName === TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT && <PerpendicularLine hoverInfo={hoverInfo} />}
          </>
        ) : (
          <ToolTipMaker
            mouseRef={mouseRef}
            hoverInfo={hoverInfo}
            dataLabel={dataLabel}
            categoryByColor={categoryByColor}
            analyticName={analyticName}
          />
        ))}
    </>
  );
};

export default MapViewMarker;
