import { Box, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import ReactECharts from 'echarts-for-react';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { getAllSettingsDataCroptype } from 'services/settings/settings.services';

const TreeStructureChartCommon = (props: any) => {
  const { data, cropTypeId, switchStatusList } = props;
  const theme = useTheme();
  const { clientId } = useParams();

  const { data: cropTypeSettings } = useQuery([QUERY_KEY.USER_SETTINGS_CROPTYPE], () => getAllSettingsDataCroptype());

  const { data: clientData } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const cropTypeName = cropTypeSettings?.data.find((item: any) => item._id === cropTypeId)?.name;
  const decimalPlace = clientData?.unitSetting.find((item: any) => item.unit === 'ha')?.decimalPlace || 2;

  const calculateAreaAndTree = useCallback((data: any) => {
    const area = data?.children.reduce((acc: any, cur: any) => acc + cur.area, 0);
    const totalTree = data?.children.reduce((acc: any, cur: any) => acc + cur.totalTree, 0);
    return { area, totalTree };
  }, []);

  const tooltipFormatter = useCallback(
    (params: any) => {
      const especiallyListName = ['OIL PALM', 'DURIAN'];
      const { dataIndex, data } = params;
      const { area, totalTree } = dataIndex !== 1 ? data : calculateAreaAndTree(data);
      const { name } = data;

      const content = [
        '<b>' + name + '</b>' + '<br/>',
        'Area: ' + area?.toFixed(decimalPlace) + '<br/>',
        'Tree count: ' + totalTree + '<br/>',
        'SPH: ' + (area !== 0 ? (totalTree / Number(area?.toFixed(decimalPlace)))?.toFixed(0) : 0) + '<br/>',
      ];
      if (!especiallyListName.includes(cropTypeName)) return content.slice(0, 2);
      return content;
    },
    [calculateAreaAndTree, cropTypeName, decimalPlace]
  );

  // tree deep index is the first key of object has false checked value
  const findTreeDeepIndex = useCallback(() => {
    for (const item of switchStatusList) {
      if (!item.checked) return item.key;
    }
    return -1;
  }, [switchStatusList]);

  const options = useMemo(() => {
    return {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        backgroundColor: theme.palette.color.white1,
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
        formatter: (params: any) => {
          return tooltipFormatter(params).join('');
        },
      },
      backgroundColor: theme.palette.color.white1,
      series: [
        {
          type: 'tree',
          data: [data],
          top: '5%',
          left: '7%',
          bottom: '1%',
          right: '20%',
          symbol: 'emptyCircle',
          symbolSize: 12,
          initialTreeDepth: findTreeDeepIndex(),
          label: {
            position: [0, -6],
            verticalAlign: 'middle',
            align: 'center',
            fontSize: 12,
            width: 160,
            overflow: 'truncate',
          },
          leaves: {
            label: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left',
            },
            itemStyle: {
              color: 'green', // Set the color of the leaf nodes
            },
          },
          emphasis: {
            focus: 'none',
            label: {
              show: true,
            },
            itemStyle: {
              color: 'green',
              borderColor: 'white',
            },
          },
          itemStyle: {
            color: (node: any) => {
              // Check if the node can explain more children
              if (node.children && node.children.length > 0) {
                return 'green'; // Set the color to blue for nodes with children
              }
              return 'green'; // Set the color to transparent for other nodes
            },
          },
          expandAndCollapse: true,
          animationDuration: 500,
          animationDurationUpdate: 750,
        },
      ],
    };
  }, [data, findTreeDeepIndex, theme.palette, tooltipFormatter]);

  return (
    <Box
      sx={{
        // border: `1px solid ${theme.palette.divider}`,
        // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pt: '16px',
      }}>
      <ReactECharts
        option={options}
        style={{
          height: '100%',
          width: '100%',
        }}
        theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
      />
    </Box>
  );
};

export default TreeStructureChartCommon;
