import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { MapStateKey } from 'common/defines/constants';
import AppLayout from 'components/AppLayout';
import { ToolMapView } from 'components/MapView/ToolMapView';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { useMapViewPageStyle } from 'pages/MapViewPage/MapViewPageStyle';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeAllStreamlineOrderDisplay } from 'store/slices/rightBarSlice';
import { clearAllTilingMapData } from 'store/slices/tilingMapSlice';
import AllModal3d from './all-modal-3d';
import NavbarTilingMap from './NavbarTilingMap';
import ReactMapGLTiling from './react-mapgl-tiling';
import RightBarTilingMap from './right-bar-tiling-map';

const TilingMap = () => {
  const [isShowNavbar, setIsShowNavbar] = useState<boolean>(true);
  const [isShowRightBar, setIsShowRightBar] = useState<boolean>(false);

  const theme = useTheme();
  const classes = useMapViewPageStyle();
  const dispatch = useAppDispatch();

  const { fieldDetail } = useQueryFieldDetail();
  const { projectId } = fieldDetail || {};
  const { selectedTask } = useQueryListTaskOfField();
  const { taskId } = selectedTask || {};

  useEffect(() => {
    return () => {
      dispatch(clearAllTilingMapData());
      // reset data of streamline order
      dispatch(changeAllStreamlineOrderDisplay([]));
    };
  }, [dispatch]);

  return (
    <AppLayout>
      <ToolMapView showAnalytics={isShowRightBar} openAnalytic={setIsShowRightBar} />

      {/* Left Bar */}
      <Grid
        className={classes.columnNavbar}
        sx={{
          width: '350px',
          height: '100%',
          display: isShowNavbar ? '' : 'none',
          position: 'fixed',
          zIndex: 8,
          backgroundColor: theme.palette.background.default,
        }}>
        <NavbarTilingMap />
      </Grid>
      <Button
        sx={{
          left: isShowNavbar ? '338px' : ' 12px',
          position: 'absolute',
          top: '140px',
        }}
        className={classes.navBarButton}
        onClick={() => setIsShowNavbar((value) => !value)}>
        {isShowNavbar ? (
          <ArrowBackIosNewIcon className={classes.arrowIcon} fontSize="small" />
        ) : (
          <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />
        )}
      </Button>
      {/* End Left Bar */}

      {/* Map View  */}
      <Box sx={{ width: '100%', height: 'calc(100% - 6px)' }}>
        <ReactMapGLTiling isShowNavbar={isShowNavbar} mapStateKey={MapStateKey.MAP_VIEW} />;
      </Box>
      {/* End Map View  */}

      {/* Right Bar */}
      <Box
        sx={{
          width: '455px',
          height: '100%',
          borderLeft: `1px solid ${theme.palette.divider}`,
          display: isShowRightBar ? '' : 'none',
          position: 'fixed',
          right: 0,
          top: '64px',
          backgroundColor: theme.palette.background.default,
        }}>
        <Button
          sx={{
            position: 'absolute',
            visibility: isShowRightBar ? 'visible' : 'hidden',
            right: '438px',
            top: '76px',
          }}
          classes={{ root: isShowRightBar ? classes.showPanelBtn : undefined }}
          onClick={() => setIsShowRightBar((value) => !value)}>
          {isShowRightBar && <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />}
        </Button>
        <RightBarTilingMap />
      </Box>
      {/* End Right Bar */}

      {/* 3d modals area */}
      <AllModal3d projectId={projectId} taskId={taskId} />
    </AppLayout>
  );
};

export default TilingMap;
