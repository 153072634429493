import { ICropTypes } from 'common/defines/clients';
import { SortDateEnum } from 'components/MapView/RightBar/IssuesTab/interfaces';

export interface IWorkspace {
  _id: string;
  name: string;
  description: string;
}

export type IEditWorkspace = Pick<IWorkspace, 'name' | 'description' | '_id'>;

export interface IWorkspacePagination {
  keyword?: string;
  page: number;
  perPage: number;
  sortDate: SortDateEnum;
  paginationVersion: number;
  workspaceId: string;
}

export interface IRawFileUpload {
  workspaceRaster: {
    taskId: string;
  };
}

export interface ITilingMapField {
  fieldName: string;
  cropType: ICropTypes;
  age: AgeEnum;
  type: CropIntelligenceEnum;
  sensorType: SensorTypeEnum;
  date: string;
  description: string;
}

export enum FieldOptionEnum {
  dsm = 'dsm',
  dtm = 'dtm',
  'auto-boundary' = 'auto-boundary',
  tiles = 'tiles',
  '3d-tiles' = '3d-tiles',
  'dem-resolution' = 'dem-resolution',
  'orthophoto-resolution' = 'orthophoto-resolution',
  'feature-quality' = 'feature-quality',
  'pc-quality' = 'pc-quality',
}

export enum OdmStatusCode {
  QUEUED = 10,
  RUNNING = 20,
  FAILED = 30,
  COMPLETED = 40,
  CANCELED = 50,
}

export interface IEventSourceData {
  processingTime: number;
  status: OdmStatusCode;
  runningProgress: number;
  lastErr: string | null;
}

export interface IField extends ITilingMapField {
  createdAt: Date;
  name: string;
  projectId: string;
  status: boolean;
  updatedAt: Date;
  _id: string;
  workspaceId: string;
  rasters: {
    date: Date;
    _id: string;
  }[];
}

export interface ITask extends Omit<IField, 'projectId' | 'workspaceId'> {
  boundary: GeoJSON.FeatureCollection;
  maxX: string;
  maxY: string;
  zoom: number;
  taskId: string;
  dsmGeoTIFFPath: string | null;
  dtmGeoTIFFPath: string | null;
  geoTIFFPath: string | null;
  fieldId: string;
  dsmBlendDem?: string;
  dtmBlendDem?: string;
}

export interface ITilingMapOptions {
  dsm: boolean;
  dtm: boolean;
  orthophoto: boolean;
}

export enum Map2dOptionEnum {
  dsm = 'dsm',
  dtm = 'dtm',
  orthophoto = 'orthophoto',
}

export interface IMap3dOptions {
  model3d: boolean;
  pointCloud: boolean;
}

export enum Map3dOptionEnum {
  MODEL_3D = 'model',
  POINT_CLOUD = 'pointcloud',
}

export interface IOption {
  key: number;
  label: string;
  disabled: boolean;
  value: boolean;
}

export enum AgeEnum {
  IMMATURE = 'IMMATURE',
  MATURE = 'MATURE',
}

export enum CropIntelligenceEnum {
  RAW_IMAGE = 'RAW_IMAGE',
  RECONSTRUCTED_IMAGE = 'RECONSTRUCTED_IMAGE',
}

export enum SensorTypeEnum {
  RGB = 'RGB',
  RGB_MS = 'RGB_MS',
}

export enum ReconstructedBandEnum {
  R_BAND = 'R_BAND',
  G_BAND = 'G_BAND',
  B_BAND = 'B_BAND',
  RGB_BAND = 'RGB_BAND',
  RE_BAND = 'RE_BAND',
  NIR_BAND = 'NIR_BAND',
  DTM = 'DTM',
  DSM = 'DSM',
}

export interface IImageInfo {
  name: ReconstructedBandEnum;
  file: File;
  is3DTexturedSurface: boolean;
}

export enum CreateAndEditMode {
  FIELD = 'FIELD',
  TASK = 'TASK',
}

export enum ContourUnitEnum {
  CM = 'CM',
  METERS = 'METERS',
}

export interface IGenerateVectorConfig {
  size: string;
  color: string;
}

export interface IGenerateVector {
  config: IGenerateVectorConfig[];
  unit: ContourUnitEnum;
  workspaceRasterId: string;
}
