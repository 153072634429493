import { useTheme } from '@mui/material';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { IIssue, LineStyleEnum } from '../../interfaces';

interface ShowLineLayerProps {
  item: IIssue;
}

const ShowLineLayer: FC<ShowLineLayerProps> = ({ item }) => {
  const theme = useTheme();

  const defaultBorder = theme.palette.primary.main;
  const key = item._id;

  const geojson: any = {
    type: 'FeatureCollection',
    features: [{ type: 'Feature', geometry: item.geometry }],
  };

  return (
    <Source id={`${key}_source_line`} key={`${key}_source_line`} type="geojson" data={geojson}>
      <Layer
        id={`${key}_border_line`}
        key={`${key}_border_line`}
        type="line"
        source={`${key}_source_line`}
        paint={{
          'line-color': item.border?.color || defaultBorder,
          'line-opacity': 0.8,
          'line-width': item.border?.width || 2,
          'line-dasharray': item.border?.style === LineStyleEnum.DASHED ? [3, 2] : [1, 0],
        }}
      />
    </Source>
  );
};

export default ShowLineLayer;
