import { makeStyles } from '@mui/styles';

export const useSignupPageStyle = makeStyles((theme: any) => {
  return {
    baseStyles: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '10px',
    },
    signupBtn: {
      marginTop: '24px !important',
      color: `${theme.palette.color.white} !important`,
      height: '48px',
      transition: 'all 0.3s ease-in-out',
      background: `linear-gradient(90deg, ${theme.palette.color.green1} 0%, ${theme.palette.color.green2} 97.14%)`,
      '&:disabled': {
        background: `linear-gradient(90deg, ${theme.palette.color.grey5} 0%, ${theme.palette.color.grey5} 97.14%)`,
      },
    },
  };
});
