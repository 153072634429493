import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as turf from '@turf/turf';
import { IUpdateProperties, MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import saveAs from 'file-saver';
import { get } from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { exportKMLFile, getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import { convertGeoJson2Shp } from '../../workers/convertGeoJson2Shp';

interface ClientCardType {
  onDrop: (file: any) => void;
  levelId?: string;
  onDeleteFile?: () => void;
  control?: Control<IUpdateProperties, object>;
  setValue: UseFormSetValue<IUpdateProperties>;
  defaultBoundaryFile?: any;
}

const DropzoneFile = ({ onDrop, levelId, onDeleteFile, control, setValue, defaultBoundaryFile }: ClientCardType) => {
  const [filesData, setFilesData] = useState(defaultBoundaryFile || null);
  const [isDisplayLandSize, setIsDisplayLandSize] = useState(false);
  const { location } = useAppSelector(clientSelector);
  const theme = useTheme();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files: any) => {
      onDrop(files[0]);
      setFilesData(files[0]);
    },
  });

  const accountUploaded = useMemo(() => {
    const account = JSON.parse(localStorage.getItem('account') || '{}');
    return account;
  }, []);

  useEffect(() => {
    if (!defaultBoundaryFile) setFilesData(null);
    if (defaultBoundaryFile) setFilesData(defaultBoundaryFile);
  }, [defaultBoundaryFile]);

  // Call get clientSetting
  const { clientId } = useParams();
  const { data: clientData } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientData?.unitSetting.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientData]);

  const clientAreaUnitMeasurement = useCallback(
    (area: any) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return (Number(area) * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return (Number(area) * 0.01).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return (Number(area) * 10000).toFixed(areaSetting.decimalPlace);
        default:
          return Number(area).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const landSize = useMemo((): number => {
    if (!location) {
      setIsDisplayLandSize(false);
      return 0;
    }
    try {
      setIsDisplayLandSize(true);
      const area = turf.area(location as any);
      // get inside the location or calculator area of geometry location
      return get(location, 'features[0].properties.Lduse_area', +(area * 0.0001).toFixed(2));
    } catch (error) {
      return 0;
    }
  }, [location, setIsDisplayLandSize, filesData]);

  useEffect(() => {
    setValue('area', landSize);
  }, [landSize, setValue]);

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDeleteFile = () => {
    // const newFileData = filesData.filter((item: any) => item?.path !== key);
    // newFileData && setFilesData(newFileData);
    onDeleteFile && onDeleteFile();
    setFilesData(null);
    setIsDisplayLandSize(false);
  };

  const downloadFile = useMutation(
    (data: { id: string | undefined; type: string }) => exportKMLFile(data.id, data.type),
    {
      onSuccess: (response: any) => {
        if (response.headers['content-type']?.includes('json')) {
          saveAs(
            new Blob([JSON.stringify(response.data)], { type: response.headers['content-type'] }),
            response.headers['filename']
          );
        } else if (response.headers['content-type']?.includes('kml')) {
          saveAs(new Blob([response.data], { type: response.headers['content-type'] }), response.headers['filename']);
        } else {
          convertGeoJson2Shp(response.data, {
            folder: 'layers',
            types: {
              point: 'mypoints',
              polygon: 'mypolygons',
              line: 'mylines',
            },
            file: response.headers['filename'],
          });
        }

        alert('your file has downloaded!');
      },
    }
  );

  const files = useMemo(() => {
    if (!filesData) return null;
    return (
      <ListItem
        key={filesData.name}
        sx={{
          background: (theme) => theme.palette.background.default,
          borderRadius: '5px',
          fontSize: '14px',
          mt: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <InsertDriveFileIcon fontSize="large" color="info" />
          </Grid>
          <Grid item xs={8}>
            <Grid>
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  maxWidth: '150px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  fontSize: '20px',
                  fontWeight: '600',
                }}>
                {filesData.name}
              </Typography>
            </Grid>
            <Grid>
              <Typography color="disabled" sx={{ fontSize: '12px' }}>
                {filesData ? (
                  <>
                    {moment(filesData?.createdAt).format('LL')}, {formatBytes(filesData.size)}
                  </>
                ) : (
                  moment(Date.now()).format('LL')
                )}
              </Typography>
            </Grid>
            <Grid>
              <Typography color="disabled" sx={{ fontSize: '12px' }}>
                Uploaded by:{' '}
                <Link sx={{ fontSize: '12px' }} underline="none">
                  {filesData?.uploadedBy?.name ? filesData?.uploadedBy?.name : accountUploaded?.name}
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <IconButton>
              <ClearOutlinedIcon color="disabled" onClick={() => handleDeleteFile()} />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>
    );
  }, [accountUploaded?.name, filesData, handleDeleteFile]);

  return (
    <Stack className="container">
      <Stack
        {...getRootProps({ className: 'dropzone' })}
        sx={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          borderWidth: '2px',
          borderRadius: '2px',
          borderStyle: 'dashed',
          // backgroundColor: '#fafafa',
          backgroundColor: (theme) => theme.palette.background.paper,
          color: '#bdbdbd',
          outline: 'none',
          transition: 'border .24s ease-in-out',
        }}>
        <input {...getInputProps()} />
        <Stack sx={{ alignItems: 'center' }}>
          <CloudUploadOutlinedIcon />
        </Stack>
        <Typography
          sx={{
            textAlign: 'center',
            mt: 2,
            mb: 1,
            color: (theme) => (theme.palette.mode === 'dark' ? 'white' : 'black'),
          }}>
          Drag and drop your files here
        </Typography>
        <Button
          variant="contained"
          data-testid="next-btn"
          sx={{
            width: '90%',
            minWidth: '100px',
            textTransform: 'none',
          }}
          onClick={open}>
          Browse File
        </Button>
        <Typography sx={{ textAlign: 'center', mt: 2, mb: 1 }}>Upload Geojson or KML file format</Typography>
      </Stack>
      {isDisplayLandSize && (
        <Stack sx={{ mt: 2 }}>
          <Controller
            name={`area`}
            control={control}
            render={({ field }) => (
              <Box>
                <InputLabel>{`Land Size (${areaSetting.unit})`}</InputLabel>
                <TextField
                  type="text"
                  size="small"
                  fullWidth
                  aria-readonly
                  sx={{ pointerEvents: 'none' }}
                  {...field}
                  value={clientAreaUnitMeasurement(landSize).toLocaleString()}
                />
              </Box>
            )}
          />
        </Stack>
      )}
      <aside>
        <h4>Files</h4>
        {files ? (
          <List>{files}</List>
        ) : (
          <Typography sx={{ textAlign: 'center', my: 1, color: '#bdbdbd' }}>No files uploaded</Typography>
        )}
        {location && (
          <Stack sx={{ my: '8px' }}>
            <Typography
              sx={{
                mb: '8px',
              }}>
              Boundary file have been uploaded. Click the button to download the Geojson file
            </Typography>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState: any) => (
                <React.Fragment>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    Download
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      sx={{ fontSize: '14px' }}
                      onClick={() => {
                        popupState.close();
                        downloadFile.mutate({
                          id: levelId,
                          type: 'SHAPE',
                        });
                        // saveAs(`${configs.API_DOMAIN}/hierarchy-structure/exportKMLFile/${levelId}?responseType=blob&fileType=SHAPE`, `${levelId}.zip`);
                      }}>
                      Download Zip
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: '14px' }}
                      onClick={() => {
                        popupState.close();
                        downloadFile.mutate({
                          id: levelId,
                          type: 'GEOJSON',
                        });
                      }}>
                      Download Geojson
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: '14px' }}
                      onClick={() => {
                        popupState.close();
                        downloadFile.mutate({
                          id: levelId,
                          type: 'KML',
                        });
                      }}>
                      Download KML
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Stack>
        )}
      </aside>
    </Stack>
  );
};

export default DropzoneFile;
