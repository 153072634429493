import { booleanPointInPolygon, circle, point as pointTurf, polygon } from '@turf/turf';
import { CLICKED_STAND_POINT_COLOR } from 'common/defines/clients';
import { sizeByZoomLevel } from 'common/utils/util';
import useClientDataConfig from 'hooks/useClientDataConfig';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useAppSelector } from '../../../store/hooks';
import { standCountAnalyticSelector } from '../../../store/slices/map-view/standCountAnalytics';
import { mapViewSelector } from '../../../store/slices/mapViewSlice';
import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

export const useStandCountAnalytics = ({ mapRef }: any) => {
  const { dataStandCount, isLayer3D, isDisplayBoundaryLine } = useAppSelector(mapViewSelector);
  const { point, isLoading, dataPointClicked, dataPointPolygon } = useAppSelector(standCountAnalyticSelector);
  const { isFixedPoint, value } = useClientDataConfig();

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();

  const { contourSetting, cylindarSetting } = useClientData();

  const zoomLevel = useMemo(() => {
    if (!mapRef?.current) return 0;
    return mapRef?.current?.getZoom();
  }, [mapRef?.current?.getZoom()]);

  const radiusPointStandCount = useMemo(() => {
    if (isFixedPoint && value) {
      return value;
    } else {
      return sizeByZoomLevel(zoomLevel);
    }
  }, [isFixedPoint, value, zoomLevel]);

  const generateStandCountColor = useCallback(
    (color: string): mapboxgl.Expression => {
      return ['case', ['==', ['get', '_id'], dataPointClicked?._id ?? ''], CLICKED_STAND_POINT_COLOR, color];
    },
    [dataPointClicked?._id]
  );

  const createPoint = useCallback(
    (standCount: any, radius: number, properties?: any): any => {
      let point: any = {};
      if (standCount.latY && standCount.longX) {
        point = circle(pointTurf([standCount.longX, standCount.latY]), radius, { properties });
      } else if (standCount.geometry?.coordinates?.[0]) {
        point = circle(pointTurf(standCount.geometry.coordinates[0]), radius, { properties });
      }
      point.properties = {
        ...standCount,
        steps: 8,
        isInsidePolygon: dataPointPolygon?.length
          ? booleanPointInPolygon(
              pointTurf([standCount.longX, standCount.latY]),
              polygon(dataPointPolygon?.[0].geometry.coordinates)
            )
          : false,
      };
      return point;
    },
    [dataPointPolygon]
  );

  const [standCountGeoJSON, standCountFilledCircleGeoJSON] = useMemo(() => {
    if (isEmpty(dataStandCount) || isLoading) {
      return [null, null];
    }

    let standCountCategoryPoint: any = [];
    let standCountCategoryCircle: any = [];

    dataStandCount.forEach((category: any) => {
      let standCountGeoPoint: any = [];
      let standCountGeoCircle: any = [];
      try {
        category.data.forEach((standCount: any, index: number) => {
          const radius =
            standCount.latY === dataPointClicked?.latY && standCount.longX === dataPointClicked?.longX
              ? 3 * radiusPointStandCount
              : radiusPointStandCount;
          const point = createPoint(standCount, radius);
          standCountGeoPoint.push(point);

          if (standCount.latY !== dataPointClicked?.latY && standCount.longX !== dataPointClicked?.longX) {
            standCountGeoCircle.push(createPoint(standCount, (cylindarSetting || 1) / 1000));
          }
        });
      } catch (error) {
        console.log(error);
      }
      standCountCategoryPoint.push({
        status: category.status,
        visible: category.visible,
        color: generateStandCountColor(category.color),
        data: {
          type: 'FeatureCollection' as any,
          features: standCountGeoPoint,
        },
      });
      standCountCategoryCircle.push({
        status: category.status,
        visible: category.visible,
        color: generateStandCountColor(category.color),
        data: {
          type: 'FeatureCollection' as any,
          features: standCountGeoCircle,
        },
      });
    });

    return [standCountCategoryPoint, standCountCategoryCircle];
  }, [
    dataStandCount,
    isLoading,
    generateStandCountColor,
    dataPointClicked?.latY,
    dataPointClicked?.longX,
    radiusPointStandCount,
    createPoint,
    cylindarSetting,
  ]);

  const pointNew = useMemo(() => {
    if (!point || isLoading) {
      return null;
    }

    return (
      <Source
        type="geojson"
        id={'point-new'}
        data={circle(pointTurf([point.longitude, point.latitude]), cylindarSetting / 1000)}>
        <Layer
          id={`stand-count-circle`}
          type="fill"
          paint={{
            'fill-color': 'orange',
            'fill-opacity': 1,
            'fill-antialias': true,
            'fill-translate-anchor': 'viewport',
          }}
        />
      </Source>
    );
  }, [point, cylindarSetting, isLoading]);

  const layerStandCount = useMemo(() => {
    if (!standCountGeoJSON || !standCountFilledCircleGeoJSON) {
      return null;
    }
    return (
      <>
        {isLayer3D &&
          standCountFilledCircleGeoJSON.map((category: any) => (
            <Source
              key={`stand-count-polygon-${category.status}`}
              id={`stand-count-polygon-${category.status}`}
              type="geojson"
              data={category.data}>
              <Layer
                id={`stand-count-circle-${category.status}`}
                type="fill"
                paint={{
                  'fill-color': generateStandCountColor(category.color),
                  'fill-opacity': !isLayer3D ? opacity2D : 0,
                  'fill-antialias': true,
                  'fill-translate-anchor': 'viewport',
                }}
                layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
              />
              <Layer
                id={`stand-count-top-surface-${category.status}`}
                type="fill-extrusion"
                source={`stand-count-circle-${category.status}`}
                paint={{
                  'fill-extrusion-color': generateStandCountColor(category.color),
                  'fill-extrusion-height': contourSetting,
                  'fill-extrusion-base': contourSetting,
                  'fill-extrusion-opacity': getTopSurfaceOpacity,
                  'fill-extrusion-vertical-gradient': false,
                }}
                layout={{ visibility: category.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
              />
              <Layer
                id={`stand-count-side-surface-${category.status}`}
                type="fill-extrusion"
                source={`stand-count-circle-${category.status}`}
                paint={{
                  'fill-extrusion-color': isSameColorTopSurface
                    ? generateStandCountColor(category.color)
                    : colorTransparent,
                  'fill-extrusion-height': contourSetting,
                  'fill-extrusion-base': 0,
                  'fill-extrusion-opacity': getOpacityExtrusion,
                  'fill-extrusion-vertical-gradient': false,
                }}
                layout={{ visibility: category.visible && !isDisplayBoundaryLine ? 'visible' : 'none' }}
              />
            </Source>
          ))}
        {standCountGeoJSON.map((category: any) => (
          <Source
            key={`stand-count-${category.status}`}
            id={`stand-count-${category.status}`}
            type="geojson"
            data={category.data}>
            <Layer
              type={'fill'}
              id={`stand-count-layer-${category.status}`}
              paint={{
                'fill-color': ['case', ['get', 'isInsidePolygon'], CLICKED_STAND_POINT_COLOR, category.color],
              }}
              layout={{ visibility: !isLayer3D && category.visible ? 'visible' : 'none' }}
            />
          </Source>
        ))}
        {pointNew}
      </>
    );
  }, [
    standCountGeoJSON,
    standCountFilledCircleGeoJSON,
    isLayer3D,
    pointNew,
    generateStandCountColor,
    opacity2D,
    contourSetting,
    getTopSurfaceOpacity,
    isDisplayBoundaryLine,
    isSameColorTopSurface,
    colorTransparent,
    getOpacityExtrusion,
  ]);

  return {
    layerStandCount,
  };
};
