import SearchIcon from '@mui/icons-material/Search';
import { Box, Tooltip, useTheme } from '@mui/material';
import { IField } from 'interfaces/workspace';
import { FC, MouseEvent } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeFlyingToState } from 'store/slices/tilingMapSlice';

interface FieldSearchPositionProps {
  data: IField;
  isIconActive: boolean;
}

const FieldSearchPosition: FC<FieldSearchPositionProps> = ({ data, isIconActive }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleSearchPosition = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isIconActive) {
      dispatch(changeFlyingToState({ isDone: false }));
    }
  };

  return (
    <Box
      sx={{
        '&:hover': {
          color: isIconActive ? theme.palette.primary.main : '',
        },
      }}
      onClick={handleSearchPosition}>
      <Tooltip title="Find Location">
        <SearchIcon aria-disabled={!isIconActive} sx={{ opacity: isIconActive ? 1 : 0.5 }} />
      </Tooltip>
    </Box>
  );
};

export default FieldSearchPosition;
