import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Box, Collapse, FormControlLabel, Switch, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

const ShowSingleUI = (props: any) => {
  const { data, flagTab, setFlagTab, isShowMapview, isShowDashboard, isShowUtilitySetting } = props;
  const [showUITab, setShowUITab] = useState(false);
  const newFlagTab = useMemo(() => {
    if (!flagTab) {
      return [];
    }
    return flagTab;
  }, [flagTab]);
  const theme = useTheme();

  useEffect(() => {
    flagTab && setShowUITab(flagTab.includes(data.name));
  }, [data.name, flagTab]);

  const handleShowUITab = useCallback(
    (e: string) => {
      setShowUITab(!showUITab);
      if (showUITab) {
        setFlagTab(newFlagTab.filter((item: string) => item !== e));
        setShowUITab(false);
      } else {
        setFlagTab([...newFlagTab, e]);
        setShowUITab(true);
      }
    },
    [newFlagTab, setFlagTab, showUITab]
  );
  return (
    <>
      <Collapse in={isShowDashboard} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            borderRadius: '10px',
          }}>
          {data.label === 'Client_Settings' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${theme.palette.divider}`,
                width: '100%',
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                paddingY: 1,
                borderRadius: '0px 0px 10px 10px',
              }}>
              <Typography
                sx={{
                  marginRight: '1rem',
                  paddingLeft: '1rem',
                }}>
                {data.label}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={showUITab ? newFlagTab.includes(data.name) : false}
                    onChange={() => handleShowUITab(data.name)}
                  />
                }
                label=""
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${theme.palette.divider}`,
                width: '100%',
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                paddingY: 1,
              }}>
              <Typography
                sx={{
                  marginRight: '1rem',
                  paddingLeft: '1rem',
                }}>
                {data.label}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={showUITab ? newFlagTab.includes(data.name) : false}
                    onChange={() => handleShowUITab(data.name)}
                  />
                }
                label=""
              />
            </Box>
          )}
        </Box>
      </Collapse>
      <Collapse in={isShowMapview} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            borderRadius: '10px',
          }}>
          {data.label === 'Flight Log' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${theme.palette.divider}`,
                width: '100%',
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                paddingY: 1,
                borderRadius: '0px 0px 10px 10px',
              }}>
              <Typography
                sx={{
                  marginRight: '1rem',
                  paddingLeft: '1rem',
                }}>
                {data.isChildren ? (
                  <>
                    <RadioButtonCheckedIcon />
                    {data.label}
                  </>
                ) : (
                  data.label
                )}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={showUITab ? newFlagTab.includes(data.name) : false}
                    onChange={() => handleShowUITab(data.name)}
                  />
                }
                label=""
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${theme.palette.divider}`,
                width: '100%',
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                paddingY: 1,
              }}>
              <Typography
                sx={{
                  marginRight: '1rem',
                  paddingLeft: '1rem',
                }}>
                {data.isChildren ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <RadioButtonCheckedIcon
                      sx={{
                        fontSize: '0.8rem',
                        marginRight: '0.5rem',
                        color: '#757575',
                      }}
                    />
                    {data.label}
                  </Box>
                ) : (
                  data.label
                )}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={showUITab ? newFlagTab.includes(data.name) : false}
                    onChange={() => handleShowUITab(data.name)}
                  />
                }
                label=""
              />
            </Box>
          )}
        </Box>
      </Collapse>
      <Collapse in={isShowUtilitySetting} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            borderRadius: '10px',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: `1px solid ${theme.palette.divider}`,
              width: '100%',
              borderLeft: `1px solid ${theme.palette.divider}`,
              borderRight: `1px solid ${theme.palette.divider}`,
              paddingY: 1,
            }}>
            <Typography
              sx={{
                marginRight: '1rem',
                paddingLeft: '1rem',
              }}>
              {data.isChildren ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <RadioButtonCheckedIcon
                    sx={{
                      fontSize: '0.8rem',
                      marginRight: '0.5rem',
                      color: '#757575',
                    }}
                  />
                  {data.label}
                </Box>
              ) : (
                data.label
              )}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showUITab ? newFlagTab.includes(data.name) : false}
                  onChange={() => handleShowUITab(data.name)}
                />
              }
              label=""
            />
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

export default ShowSingleUI;
