import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { forwardRef, useContext, useState } from 'react';
import { useCreateTreeLevel, useDeleteLevel } from 'hooks/useClientProperties';

import AddIcon from '@mui/icons-material/Add';
import { ClientActionsDialog } from 'components/ClientsInfo/ClientActionsDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { ICropTypes } from 'common/defines/clients';
import { LevelContext } from 'components/ClientsInfo/ClientAssetStructure';
import { TreeItemComponentProps } from 'dnd-kit-sortable-tree';
import classNames from 'classnames';
import { clientSelector } from 'store/slices/clientSlice';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';

export type CollapseItemPayload = {
  key: string;
  collapsed: boolean;
}

type MinimalTreeItemData = {
  name: string;
  id: string;
  level: number;
  parent: any | null;
  parentId: string | null;
  children?: any[];
  isLastLevel?: boolean;
  isFavorite?: any;
  refetch?: any;
  recentLevel?: string;
  deleteId?: string | '';
  key: string;
  handleCollapseItem: (payload: CollapseItemPayload) => void
};

const MinimalTreeItemComponent = forwardRef<HTMLDivElement, TreeItemComponentProps<MinimalTreeItemData>>(
  (props, ref) => {
    const {
      clone,
      depth,
      disableSelection,
      disableInteraction,
      disableSorting,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      item,
      wrapperRef,
      style,
      hideCollapseButton,
      childCount,
      manualDrag,
      showDragHandle,
      disableCollapseOnItemClick,
      isLast,
      parent,
      ...rest
    } = props;

    const { cropType, refetchLevelTree, levelId, setLevelId, setParentId } = useContext(LevelContext);

    const { clientId: clientIdParamUrl } = useParams();
    const { clientData: clientDataHook } = useAppSelector(clientSelector);

    const clientId = clientIdParamUrl || clientDataHook?._id;

    const [levelIdDelete, setLevelIdDelete] = useState<string | undefined>();

    const mutationDeleteLevel = useDeleteLevel();
    const mutationCreateTreeLevel = useCreateTreeLevel();

    const onCreateLevel = (parent: string | null, clientIdParam: string, cropTypeParam: ICropTypes, name: string) =>
      mutationCreateTreeLevel.mutate(
        { parent, clientId: clientIdParam, cropType: cropTypeParam, name },
        {
          onSuccess: (res) => {
            refetchLevelTree();
            if (res?.data?.status === 400) {
              toast.error(res.data.response.message, { toastId: 1 });
            }
          },
        }
      );

    const addNewLevelHierarchyStructure = (parent: string | null) => {
      if (cropType && clientId) {
        // default expand parent level when add new child
        item.handleCollapseItem({ key: item.key, collapsed: true })
        onCreateLevel(parent, clientId, cropType, 'Untitled');
      }
    };

    const theme = useTheme();

    const onDeleteLevel = (id: string) => {
      mutationDeleteLevel.mutate(id, {
        onSuccess: () => {
          refetchLevelTree();
          toast.success('The level has been deleted', { toastId: 1 });
          setLevelIdDelete(undefined);
          onRemove?.();
        },
      });
    };

    return (
      <>
        <li
          className={classNames(
            'dnd-sortable-tree_simple_wrapper',
            clone && 'dnd-sortable-tree_simple_clone',
            ghost && 'dnd-sortable-tree_simple_ghost',
            disableSelection && 'dnd-sortable-tree_simple_disable-selection',
            disableInteraction && 'dnd-sortable-tree_simple_disable-interaction'
          )}
          ref={wrapperRef}
          {...rest}
          style={{
            ...style,
            minHeight: '40px',
            paddingLeft: clone ? indentationWidth : indentationWidth * depth,
          }}>
          <div
            className={'dnd-sortable-tree_simple_tree-item'}
            ref={ref}
            {...(manualDrag ? undefined : handleProps)}
            style={{
              minHeight: '40px',
              backgroundColor: item.id === levelId ? theme.palette.primary.main : 'transparent',
              padding: '0 10px',
              color: theme.palette.mode === 'dark' ? '#fff' : '#616161',
              borderRadius: '5px',
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            {!manualDrag && !hideCollapseButton && !!onCollapse && !!childCount && (
              <button
                onClick={(e) => {
                  // store status of level when user collapse or expand level tree
                  item.handleCollapseItem({ key: item.key, collapsed: !!item.collapsed })
                  onCollapse();

                  e.preventDefault();
                }}
                className={classNames(
                  'dnd-sortable-tree_simple_tree-item-collapse_button',
                  collapsed && 'dnd-sortable-tree_folder_simple-item-collapse_button-collapsed'
                )}
                style={{ cursor: 'pointer' }}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 0.5,
                pr: 0.5,
                justifyContent: 'space-around',
                width: '100%',
              }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'inherit', flexGrow: 1, cursor: 'pointer' }}
                onClick={() => {
                  if (item.id !== levelId) {
                    setLevelId(item.id);
                    setParentId(item.parentId);
                  }
                }}>
                {item.name}
              </Typography>
              <Box>
                {!item.isLastLevel && (
                  <IconButton aria-label="add" size="small" onClick={(e) => addNewLevelHierarchyStructure(item.id)}>
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                )}
                <IconButton
                  style={{ left: 'auto' }}
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    setLevelIdDelete(item.id);
                  }}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
          </div>
        </li>
        {levelIdDelete && (
          <ClientActionsDialog
            title="Delete level?"
            open={!!levelIdDelete}
            onClose={() => setLevelIdDelete(undefined)}
            description="Are you sure you want to delete this level?"
            onCancel={() => setLevelIdDelete(undefined)}
            onSave={() => levelIdDelete && !mutationDeleteLevel.isLoading && onDeleteLevel(levelIdDelete)}
            saveLabel="Delete"
            cancelLabel="Cancel"
            submit={false}
          />
        )}
      </>
    );
  }
);

export default MinimalTreeItemComponent;
