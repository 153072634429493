import { DatePicker } from '@mui/lab';
import { Grid, TextField, TextFieldProps, Typography } from '@mui/material';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import moment from 'moment';

interface SelectDateProps {
  control: Control<any>;
}

const SelectDate: FC<SelectDateProps> = ({ control }) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Date</Typography>
        <Controller
          name="date"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              value={value}
              onChange={(date: any) => onChange(moment(date).format())}
              renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                <TextField type="text" {...params} sx={{ width: '250px !important', mt: '8px' }} size="small" />
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SelectDate;
