import { Close } from '@mui/icons-material';
import { Box, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import AvatarUser from 'components/Common/AvatarUser';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { uploadMedia } from 'services/media';

const PersonInfoForm = ({ control, setValue, remove, personIndex = 0, personInfo }: any) => {
  let [avatar, setAvatar] = useState<string>('');

  useEffect(() => {
    setAvatar(personInfo?.avatar);
  }, [personInfo?.avatar]);

  const uploadFileMutation = useMutation((file: any) => uploadMedia(file), {
    onSuccess: (response) => {
      setValue(`personInCharge.${personIndex}.avatar`, response?.data?.path);
      setAvatar(response?.data?.path);
    },
    onError: () => {
      toast.error('Upload file failed. Please try again!');
    },
  });

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files;
    const formData = new FormData();
    if (!file) return;
    const newFile = file[0];
    (newFile as any).preview = URL.createObjectURL(newFile);

    setAvatar((newFile as any).preview);
    formData.append('file', file[0]);
    uploadFileMutation.mutate(formData);
  };
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <InputLabel sx={{ mb: 0 }}> {`Person in charge-${personIndex + 1}`} </InputLabel>
        <IconButton
          sx={{ mr: '3px' }}
          onClick={() => {
            remove(personIndex);
          }}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <AvatarUser
        avatar={avatar}
        handleChangeFile={(e) => handleChangeFile(e, personIndex)}
        heightAvatar="50px"
        widthAvatar="50px"
        heightBtnUpload="30px"
        pic={true}
      />
      <Typography component="ul" sx={{ pl: 0, pb: 1, pt: 1 }}>
        <Controller
          name={`personInCharge.${personIndex}.name`}
          control={control}
          render={({ field }) => (
            <Box>
              <InputLabel>Name</InputLabel>
              <TextField
                // required
                type="text"
                size="small"
                {...field}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          )}
        />
      </Typography>
      <Typography component="ul" sx={{ pl: 0, pb: 1, pt: 1 }}>
        <Controller
          name={`personInCharge.${personIndex}.jobTitle`}
          control={control}
          render={({ field }) => (
            <Box>
              <InputLabel>Job Title</InputLabel>
              <TextField
                // required
                type="text"
                size="small"
                {...field}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          )}
        />
      </Typography>
      <Typography component="ul" sx={{ pl: 0, pb: 1, pt: 1 }}>
        <Controller
          name={`personInCharge.${personIndex}.contactNumber`}
          control={control}
          render={({ field }) => (
            <Box>
              <InputLabel>Contact Number</InputLabel>
              <TextField
                // required
                type="text"
                size="small"
                {...field}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          )}
        />
      </Typography>
      <Typography component="ul" sx={{ pl: 0, pb: 1, pt: 1 }}>
        <Controller
          name={`personInCharge.${personIndex}.email`}
          control={control}
          render={({ field }) => (
            <Box>
              <InputLabel>Email</InputLabel>
              <TextField
                // required
                type="email"
                size="small"
                {...field}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          )}
        />
      </Typography>
    </div>
  );
};

export default PersonInfoForm;
