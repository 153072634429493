import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, Grid } from '@mui/material';
import { SettingSectionName } from 'common/defines/clients';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SizePointCard = ({ data, type }: any) => {
  const { t } = useTranslation();

  const [isOpenExpand, setIsOpenExpand] = useState(true);

  const settingData = useMemo(() => {
    switch (type) {
      case SettingSectionName.SIZE_POINT_SETTING:
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            {data?.value
              ? `Fixed Size: ${data?.value} `
              : `${t('dashboard.fixed_size_point_analysis')} : ${data?.isFixedPoint || t('trans.false')}`}
          </Box>
        );
      default:
        return <></>;
    }
  }, [data?.isFixedPoint, data?.value, t, type]);

  return (
    <Grid>
      <Box>
        <Button
          startIcon={isOpenExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          onClick={() => setIsOpenExpand(!isOpenExpand)}>
          {/* {data.name} */}
          {t('trans.size_point_config_uppercase')}
        </Button>
      </Box>
      <Collapse in={isOpenExpand} timeout="auto" unmountOnExit sx={{ px: 4 }}>
        <Grid container>{settingData}</Grid>
      </Collapse>
    </Grid>
  );
};
