import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { CropIntelligenceEnum, ITilingMapField } from 'interfaces/workspace';
import { FC, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { changeUploadRawImageList, changeUploadReconstructedImageList } from 'store/slices/tilingMapSlice';
import RawImages from './RawImages';
import ReconstructedImages from './ReconstructedImages';

const imageTypeRadioList = [
  { key: 1, value: CropIntelligenceEnum.RAW_IMAGE, label: 'Raw Images' },
  { key: 2, value: CropIntelligenceEnum.RECONSTRUCTED_IMAGE, label: 'Reconstructed Images' },
];

interface UploadImagesProps {
  form: UseFormReturn<ITilingMapField>;
  isInEditMode: boolean;
}

const UploadImages: FC<UploadImagesProps> = ({ form, isInEditMode }) => {
  const { control, getValues, setValue } = form;
  const [, setImageType] = useState<CropIntelligenceEnum>(CropIntelligenceEnum.RAW_IMAGE);
  const dispatch = useAppDispatch();

  const isDisabledRadioButton = getValues('type') === CropIntelligenceEnum.RECONSTRUCTED_IMAGE && isInEditMode;

  const handleChangeImageType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const typeValue = event.target.value as CropIntelligenceEnum;
    setValue('type', typeValue);
    setImageType(typeValue);
    dispatch(changeUploadRawImageList([]));
    dispatch(changeUploadReconstructedImageList([]));
  };

  return (
    <>
      <Controller
        name="type"
        control={control}
        defaultValue={CropIntelligenceEnum.RAW_IMAGE}
        render={({ field }) => (
          <RadioGroup {...field} row onChange={handleChangeImageType}>
            {imageTypeRadioList.map((item) => (
              <FormControlLabel
                key={item.key}
                value={item.value}
                control={<Radio disabled={isDisabledRadioButton} />}
                label={
                  <Box component="div" fontSize="1rem">
                    {item.label}
                  </Box>
                }
                sx={{ fontSize: '18px !important' }}
              />
            ))}
          </RadioGroup>
        )}
      />
      {getValues('type') === CropIntelligenceEnum.RAW_IMAGE ? (
        <RawImages form={form} />
      ) : (
        <ReconstructedImages form={form} isInEditMode={isInEditMode} />
      )}
    </>
  );
};

export default UploadImages;
