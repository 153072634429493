import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
const styles = {
  userListTitle: {
    py: '10px',
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18.2px',
    textAlign: 'center',
  },
  clientUpdateTime: {
    textAlign: 'center',
    px: '16px',
  },
};

interface IInfoTable {
  dataPie: Array<{ name: string; value: number }>;
}
const InfoTable = ({ dataPie }: IInfoTable) => {
  const { clientId } = useParams();
  const { t } = useTranslation();

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const clientAreaUnitMeasurement = useCallback(
    (area: any) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return new BigNumber(area * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return new BigNumber(area * 0.01).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return new BigNumber(area * 10000).toFixed(areaSetting.decimalPlace);
        default:
          return new BigNumber(area).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const tableAreaPolygon = useMemo(() => {
    if (dataPie) {
      return dataPie.map((_item: any, index: number) => (
        <TableRow key={index}>
          <TableCell
            component="td"
            sx={{
              ...styles.clientUpdateTime,
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),

              textAlign: 'left',
            }}>
            {_item.name}
          </TableCell>
          <TableCell
            component="td"
            sx={{
              ...styles.clientUpdateTime,
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
            }}>
            {clientAreaUnitMeasurement(_item.value || 0)}
          </TableCell>
        </TableRow>
      ));
    }
    return <></>;
  }, [dataPie, clientAreaUnitMeasurement]);

  const getTotalArea = useMemo(() => {
    if (dataPie) {
      const sum = dataPie.reduce((accumulator: any, item: any) => accumulator + item.value, 0);
      return clientAreaUnitMeasurement(sum || 0);
    }
  }, [dataPie, clientAreaUnitMeasurement]);
  const theme = useTheme();

  return (
    <TableContainer
      sx={{
        overflow: 'hidden',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        mt: '-1px',
        mb: '12px',
      }}>
      <Table>
        <TableHead>
          <TableRow
            component="tr"
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
            }}>
            <TableCell
              component="th"
              sx={{
                ...styles.userListTitle,
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                minWidth: '250px',
                textAlign: 'left',
              }}>
              {t('trans.level')}
            </TableCell>
            <TableCell
              component="th"
              sx={{
                ...styles.userListTitle,
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
              }}>
              {`${t('trans.area')} (${areaUnit})`}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            pt: 0,
            flexDirection: 'column',
            minHeight: '510px',
            maxHeight: '510px',
            overflowY: 'scroll',
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
          {tableAreaPolygon}
          {dataPie.length > 1 && getTotalArea ? (
            <TableRow>
              <TableCell
                component="td"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}>
                {t('trans.total')}
              </TableCell>
              <TableCell
                component="td"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  fontWeight: 'bold',
                }}>
                {getTotalArea}
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InfoTable;
