import { Add } from '@mui/icons-material';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material';
import { RoleType } from 'common/defines/clients';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { EditPermissionModal } from 'components/PermissionRole/EditPermissionModal';
import { RoleAddModal } from 'components/PermissionRole/RoleAddModal';
import { TableDataRow } from 'components/PermissionRole/TableDataRoleRow';
import { QUERY_KEY, rowPerPageOptions } from 'constants/constants';
import { useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { deleteRoleById, getRoleListByPagination } from 'services/clients/apiClient.services';
import { ClientActionsDialog } from '../ClientsInfo/ClientActionsDialog';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export const RolePage = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [roleData, setRoleData] = useState<RoleType>();
  const [searchRole, setSearchRole] = useState('');
  const titleDialog = useRef<HTMLDivElement>();
  const [openEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const theme = useTheme();

  const handleChangePageSize = (event: any) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleCloseAddModal = () => {
    setOpen(false);
    refetch();
  };

  const handleCloseEditModal = () => {
    setOpenEdit(false);
    refetch();
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteRole = useMutation((id: string | undefined) => deleteRoleById(id), {
    onSuccess: () => {
      toast.success('Delete successes', { toastId: 1 });
      setOpenDeleteModal(false);
      refetch();
    },
    onError: (error: any) => {
      toast.error(error.data.message, { toastId: 1 });
    },
  });

  const onSave = () => {
    return handleDeleteRole.mutate(roleData?._id);
  };

  const { data, refetch, isLoading } = useQuery(
    [QUERY_KEY.ROLE_DETAILS, page, rowsPerPage, searchRole],
    () => getRoleListByPagination(page, rowsPerPage, searchRole),
    { keepPreviousData: true }
  );

  const dataRoleList = useMemo(() => {
    if (!data) return [];
    const userAssigned = data.data?.assigned;
    const dataRoleListValue = data?.data?.data?.docs;
    dataRoleListValue?.forEach((item: any, index: number) => {
      item.userAssigned = userAssigned?.[index];
    });

    return dataRoleListValue;
  }, [data]);
  const dataValue = useMemo(() => {
    return data?.data?.data;
  }, [data]);

  const indexStartPage = useMemo(() => {
    return (dataValue?.page - 1) * dataValue?.limit + 1;
  }, [dataValue]);

  const indexEndPage = useMemo(() => {
    if (!dataValue) {
      return 0;
    }
    return Math.min(dataValue.page * dataValue.limit, dataValue.totalDocs);
  }, [dataValue]);

  return (
    <Box>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: '20px',
            borderBottom: theme.palette.divider,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography mr="10px" fontSize="16px">
              Show
            </Typography>
            <Select
              sx={{
                height: '40px',
                border: 'none',
                width: '65px',
              }}
              id="demo-simple-select"
              value={rowsPerPage}
              onChange={handleChangePageSize}>
              {rowPerPageOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <TextField
              type="text"
              placeholder="Search Roles..."
              sx={{
                mr: 1,
                width: '340px',
                minWidth: '340px',
                height: '40px',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '.MuiOutlinedInput-root': {
                  height: '40px',
                },
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '5px',
              }}
              onChange={(e) => {
                setSearchRole(e.target.value);
              }}
              InputProps={{
                size: 'small',
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ height: '24px', width: '24px' }} />
                  </InputAdornment>
                ),
                inputProps: {
                  style: {
                    fontFamily: 'Barlow',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '17px',
                  },
                },
              }}
            />
            <Button
              startIcon={<Add />}
              onClick={() => setOpen(true)}
              sx={{
                height: '40px',
                border: `1px solid ${theme.palette.primary.main} !important`,
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: 'white !important',
                padding: '0 32px',
                '&:hover': {
                  backgroundColor: '#1C9D57 !important',
                  border: `1px solid ${theme.palette.primary.main} !important`,
                  boxShadow: 'none',
                },
                '&:active': {
                  boxShadow: 'none',
                  backgroundColor: '#1C9D57 !important',
                  border: `1px solid ${theme.palette.primary.main} !important`,
                },
                '&:focus': {
                  boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15) !important',
                  border: `1px solid ${theme.palette.primary.main} !important`,
                },
              }}>
              Add Role
            </Button>
          </Box>
        </Box>
        <TableContainer sx={{ overflow: 'hidden' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  borderBottom: (theme) => `2px solid ${theme.palette.divider} !important`,
                }}>
                <TableCell
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: 'none',
                  }}>
                  <Typography
                    sx={{
                      fontWeight: '500',
                    }}>
                    Role
                  </Typography>
                  <CustomWidthTooltip
                    title="Role name convention is: <User Type>_<Role Name>_<Client Name>"
                    placement="right"
                    arrow>
                    <HelpOutlineRoundedIcon
                      sx={{
                        fontSize: '18px',
                        color: '#5E5873',
                        marginLeft: '6px',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          color: (theme) => theme.palette.primary.main,
                        },
                      }}
                    />
                  </CustomWidthTooltip>
                </TableCell>
                <TableCell>User Assigned</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell sx={{ width: '300px' }}>List of Permissions</TableCell>
                <TableCell sx={{ textAlign: 'center', width: '200px' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      sx={{
                        padding: '16px 0',
                      }}>
                      <WaitingPageAPI />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <TableBody
                sx={{
                  pt: 0,
                  flexDirection: 'column',
                  minHeight: '510px',
                  maxHeight: '510px',
                  overflowY: 'scroll',
                  '-ms-overflow-style': 'none' /* IE and Edge */,
                  scrollbarWidth: 'none' /* Firefox */,
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}>
                {dataRoleList &&
                  dataRoleList.map((val: any, index: number) => {
                    return (
                      <TableDataRow
                        data={val}
                        index={index}
                        isEdit={setOpenEdit}
                        setData={setRoleData}
                        setOpenDeleteModal={setOpenDeleteModal}
                      />
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {dataRoleList && dataRoleList.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '20px 20px',
            }}>
            <Typography>
              Showing {indexStartPage} to {indexEndPage} of {dataValue?.totalDocs} roles
            </Typography>
            <Stack spacing={2}>
              <Pagination
                sx={{
                  '& .MuiPagination-ul': {
                    li: {
                      '& .MuiButtonBase-root': {
                        justifyContent: 'center',
                      },
                    },
                    '& .Mui-selected': {
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: 'white',
                    },
                    '& .Mui-selected:hover': {
                      backgroundColor: '#23BE6AB0',
                    },
                    '& .MuiPaginationItem-icon': {
                      color: (theme) => theme.palette.primary.main,
                    },
                  },
                }}
                count={dataValue?.totalPages}
                defaultPage={1}
                page={page}
                onChange={(_, value) => {
                  setPage(value);
                  //scroll to titleDialog
                  titleDialog.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }}
              />
            </Stack>
          </Box>
        )}
      </Stack>
      {roleData && (
        <EditPermissionModal data={roleData} reloadData={refetch} open={openEdit} onClose={handleCloseEditModal} />
      )}
      {open && <RoleAddModal open={open} setOpen={handleCloseAddModal} reloadData={refetch} />}
      <ClientActionsDialog
        title="Delete Role"
        open={openDeleteModal}
        onClose={closeDeleteModal}
        description="Are you sure to delete this role?  This action cannot be undone."
        saveLabel="Delete Role"
        onCancel={closeDeleteModal}
        onSave={onSave}
        submit={false}
      />
    </Box>
  );
};
