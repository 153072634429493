import { area } from '@turf/turf';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import SkeletonCharts from 'components/Common/SkeletonCharts';
import ChartsAnalytics from 'components/MapView/RightBar/AnalyticsTab/Category/ChartsAnalytics/ChartsAnalytics';
import { CrownAreaLayer } from 'components/MapView/RightBar/AnalyticsTab/Layer/CrownArea';
import { SwipeableViewsCustom } from 'components/MapView/RightBar/AnalyticsTab/SwipeableViewsCustom';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { updateInterval } from 'services/analytics/apiAnalyticsConfig.services';
import {
  getChartsWorkspaceCrownArea,
  getDataWorkspaceCrownAreaAnalytic,
  getWorkspaceCrownAreaBoxPlot,
  getWorkspaceCrownAreaPolygon,
} from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeChartDataCrownAreaPreview,
  changeDataCrownAreaAnalytics,
  changeDataCrownAreaInterval,
  changeDataPolygonCrownAreaAnalytics,
  crownAreaAnalyticsSelector,
} from 'store/slices/map-view/crownAreaAnalytics';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

const CrownAreaAnalytic = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { analysisId } = useAppSelector(tilingMapSelector);
  const { dataCrownAreaInterval, dataCrownAreaPolygon, chartDataCrownAreaPreview } =
    useAppSelector(crownAreaAnalyticsSelector);
  const isDefaultInterval = true;

  const { data: dataChlorophyllBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.WORKSPACE_CROWN_AREA_BOX_PLOT, analysisId],
    () => getWorkspaceCrownAreaBoxPlot(analysisId),
    { enabled: !!analysisId }
  );

  useQuery(
    [QUERY_KEY.WORKSPACE_CROWN_AREA_POLYGON, analysisId, currentPage],
    () => getWorkspaceCrownAreaPolygon(analysisId, currentPage),
    {
      enabled: !!analysisId,
      onSuccess: (data: any) => {
        if (data.data?.nextPage && data.data.nextPage !== currentPage) {
          setCurrentPage(data.data?.nextPage);
        }
        const crownAreaPolygonList = currentPage === 1 ? [] : dataCrownAreaPolygon;
        const polygonListRes = data.data?.docs;
        if (polygonListRes) {
          const convertedPolygonList = polygonListRes.map((item: any) => ({ ...item, area: area(item.geometry) }));
          dispatch(changeDataPolygonCrownAreaAnalytics([...crownAreaPolygonList, ...convertedPolygonList]));
        }
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_WORKSPACE_CROWN_AREA_ANALYTIC, isDefaultInterval],
    () => getChartsWorkspaceCrownArea(analysisId, isDefaultInterval),
    {
      enabled: !!analysisId,
      onSuccess: (data) => {
        dispatch(
          changeDataCrownAreaInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataCrownAreaInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  useQuery(
    [QUERY_KEY.WORKSPACE_CROWN_AREA_ANALYTIC, analysisId],
    () => {
      return getDataWorkspaceCrownAreaAnalytic(analysisId);
    },
    {
      enabled: !!analysisId,
      onSuccess: (res) => {
        const convertedData = res.data.map((item: any) => ({ ...item, longX: item.long, latY: item.lat }));
        dispatch(changeDataCrownAreaAnalytics(convertedData || []));
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.CHARTS_CROWN_AREA_ANALYTIC).then(() => {});
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataChlorophyllBoxPlot?.data) return [];
    return dataChlorophyllBoxPlot.data;
  }, [dataChlorophyllBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataCrownAreaPreview ? chartDataCrownAreaPreview?.chartData : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA}
                  getCharts={getChartsWorkspaceCrownArea}
                  intervalRange={
                    chartDataCrownAreaPreview
                      ? chartDataCrownAreaPreview.intervalLimit.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_CROWN_AREA_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataCrownAreaPreview}
                  isInWorkspace
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<CrownAreaLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA} isInTilingMap />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
      isInWorkspace
    />
  );
};

export default CrownAreaAnalytic;
