import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { changeShowShareIssueModal } from 'store/slices/rightBarSlice';
import { IIssue } from '../../interfaces';

interface ShareIssueProps {
  data: IIssue;
  colorByTheme?: string;
}

const ShareIssue: FC<ShareIssueProps> = ({ data, colorByTheme }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('mapView.issue.share')}>
        <ReplyOutlinedIcon
          sx={{ transform: 'scaleX(-1)', cursor: 'pointer', color: colorByTheme }}
          onClick={() => {
            dispatch(changeShowShareIssueModal({ isShow: true, data: data }));
          }}
        />
      </Tooltip>
    </>
  );
};

export default ShareIssue;
