import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { circle, point } from '@turf/turf';
import { sizeByZoomLevel } from 'common/utils/util';
import update from 'immutability-helper';
import { RootState } from 'store/store';

interface IUserSlice {
  dataPlantStress: any;
  dataPlantStressPolygon: any;
  dataPlantStressInterval: any;
  isShowAllLayer: boolean;
  dataGeoJsonPoint: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  dataGeoJsonPolygon: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  zoomLayer: number;
  sizeFixedPoint: number;
  chartDataPlantStressPreview: any;
}

const initialState: IUserSlice = {
  dataPlantStress: null,
  dataPlantStressPolygon: [],
  dataPlantStressInterval: null,
  isShowAllLayer: true,
  dataGeoJsonPoint: undefined,
  dataGeoJsonPolygon: undefined,
  zoomLayer: 16,
  sizeFixedPoint: 0.0015,
  chartDataPlantStressPreview: null,
};

export const plantStressAnalyticsSlice = createSlice({
  name: 'plantStressAnalytic',
  initialState,
  reducers: {
    clearPlantStressAnalytics: () => {
      return initialState;
    },
    changeDataPlantStressAnalytics: (state, action: PayloadAction<any>) => {
      state.dataPlantStress = action.payload;
      if (!state.dataPlantStressInterval || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((stressInstance: any) => {
          let color = 'black';
          const stressInterval = state.dataPlantStressInterval?.range?.find(
            (item: any, index: number) =>
              item.from <= stressInstance.stressIn &&
              (index === state.dataPlantStressInterval?.range?.length - 1
                ? item.to >= stressInstance.stressIn
                : item.to > stressInstance.stressIn)
          );
          if (stressInterval?.visible) {
            color = stressInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(stressInstance.longX || 0), Number(stressInstance.latY || 0)], {
              ...stressInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeDataPlantStressInterval: (state, action: PayloadAction<any>) => {
      state.dataPlantStressInterval = action.payload;
      if (state.dataPlantStressPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantStressPolygon.map((stressInstance: any) => {
            let color = 'black';
            const stressInterval = state.dataPlantStressInterval.range?.find(
              (item: any, index: number) =>
                item.from <= stressInstance.stressIn &&
                (index === state.dataPlantStressInterval?.range?.length - 1
                  ? item.to >= stressInstance.stressIn
                  : item.to > stressInstance.stressIn)
            );
            if (stressInterval?.visible) {
              color = stressInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...stressInstance.geometry },
              properties: {
                ...stressInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
      if (!state.dataPlantStress || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: state.dataPlantStress.map((stressInstance: any) => {
          let color = 'black';
          const stressInterval = action.payload?.range?.find(
            (item: any, index: number) =>
              item.from <= stressInstance.stressIn &&
              (index === action.payload?.range?.length - 1
                ? item.to >= stressInstance.stressIn
                : item.to > stressInstance.stressIn)
          );
          if (stressInterval?.visible) {
            color = stressInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(stressInstance.longX || 0), Number(stressInstance.latY || 0)], {
              ...stressInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeVisibleIntervalStress: (state, action: PayloadAction<number>) => {
      const currentInterval = state.chartDataPlantStressPreview
        ? state.chartDataPlantStressPreview.intervalLimit
        : state.dataPlantStressInterval;
      state.isShowAllLayer = currentInterval.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      currentInterval.range = update(currentInterval.range, {
        [action.payload]: {
          visible: { $set: !currentInterval.range[action.payload].visible },
        },
      });
      if (state.dataPlantStress) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantStress.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.stressIn &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.stressIn
                  : item.to > vigorInstance.stressIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataPlantStressPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantStressPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.stressIn &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.stressIn
                  : item.to > vigorInstance.stressIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeVisibleIntervalStressAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataPlantStressInterval.range = update(state.dataPlantStressInterval.range, {
        $apply: (intervalRange: Array<any>) =>
          intervalRange.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
      if (state.dataPlantStress) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantStress.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataPlantStressInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.stressIn &&
                (index === state.dataPlantStressInterval?.range?.length - 1
                  ? item.to >= vigorInstance.stressIn
                  : item.to > vigorInstance.stressIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataPlantStressPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantStressPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataPlantStressInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.stressIn &&
                (index === state.dataPlantStressInterval?.range?.length - 1
                  ? item.to >= vigorInstance.stressIn
                  : item.to > vigorInstance.stressIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeDataPolygonStressAnalytics: (state, action: PayloadAction<any>) => {
      state.dataPlantStressPolygon = action.payload;
      if (!state.dataPlantStressInterval) {
        return;
      }
      const geoJson = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((stressInstance: any) => {
          let color = 'black';
          const stressInterval = state.dataPlantStressInterval.range?.find(
            (item: any, index: number) =>
              item.from <= stressInstance.stressIn &&
              (index === state.dataPlantStressInterval?.range?.length - 1
                ? item.to >= stressInstance.stressIn
                : item.to > stressInstance.stressIn)
          );
          if (stressInterval?.visible) {
            color = stressInterval.color;
          } else color = 'transparent';

          return {
            geometry: { ...stressInstance.geometry },
            properties: {
              ...stressInstance.properties,
              color: color,
            },
          };
        }),
      };
      state.dataGeoJsonPolygon = geoJson;
    },

    changeZoomLayerStress: (state, action: PayloadAction<number>) => {
      state.zoomLayer = action.payload;
      const zoomLevel = action.payload;
      const currentRange = state.chartDataPlantStressPreview
        ? state.chartDataPlantStressPreview.intervalLimit?.range
        : state.dataPlantStressInterval?.range;
      if (state.dataPlantStress) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantStress.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentRange?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.stressIn &&
                (index === currentRange?.length - 1
                  ? item.to >= vigorInstance.stressIn
                  : item.to > vigorInstance.stressIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              sizeByZoomLevel(zoomLevel),
              { steps: 32 }
            );
          }),
        };
      }
    },
    changeFixedSizePointStress: (state, action: PayloadAction<any>) => {
      state.sizeFixedPoint = action.payload;
    },
    changeChartDataPlantStressPreview: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        const intervalLimit = {
          ...state.dataPlantStressInterval,
          range: action.payload.range,
        };
        const chartData: any = [];
        action.payload.range.forEach((item: any) => {
          const listByRange = state.dataPlantStress.filter(
            (chlorophyll: any) => chlorophyll.stressIn >= item.from && chlorophyll.stressIn <= item.to
          );
          chartData.push({ [item.lable]: listByRange.length });
        });
        let dataPreview = { chartData, intervalLimit };

        state.chartDataPlantStressPreview = dataPreview;

        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantStress.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.chartDataPlantStressPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.stressIn &&
                (index === state.chartDataPlantStressPreview?.intervalLimit?.range?.length - 1
                  ? item.to >= vigorInstance.stressIn
                  : item.to > vigorInstance.stressIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
        state.dataGeoJsonPolygon = {
          type: 'FeatureCollection' as any,
          features: state.dataPlantStressPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.chartDataPlantStressPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.stressIn &&
                (index === state.chartDataPlantStressPreview?.intervalLimit?.range?.length - 1
                  ? item.to >= vigorInstance.stressIn
                  : item.to > vigorInstance.stressIn)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
              },
            };
          }),
        };
      } else {
        state.chartDataPlantStressPreview = null;
      }
    },
  },
});

export const {
  changeDataPlantStressAnalytics,
  changeDataPlantStressInterval,
  clearPlantStressAnalytics,
  changeVisibleIntervalStress,
  changeVisibleIntervalStressAllLayers,
  changeDataPolygonStressAnalytics,
  changeZoomLayerStress,
  changeFixedSizePointStress,
  changeChartDataPlantStressPreview,
} = plantStressAnalyticsSlice.actions;

export const plantStressAnalyticsSelector = (state: RootState) => state.plantStressAnalytic;

export default plantStressAnalyticsSlice.reducer;
