import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { multiLineString } from '@turf/turf';
import update from 'immutability-helper';
import { RootState } from 'store/store';

interface IUserSlice {
  dataTreeTiltFallen: any;
  dataTreeTiltFallenPolygon: any;
  dataTreeTiltFallenInterval: any;
  isShowAllLayer: boolean;
  dataGeoJsonPoint: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  dataGeoJsonPolygon: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  zoomLayer: number;
  sizeFixedPoint: number;
  chartDataTreeTiltFallenPreview: any;
}

const initialState: IUserSlice = {
  dataTreeTiltFallen: null,
  dataTreeTiltFallenPolygon: [],
  dataTreeTiltFallenInterval: null,
  isShowAllLayer: true,
  dataGeoJsonPoint: undefined,
  dataGeoJsonPolygon: undefined,
  zoomLayer: 16,
  sizeFixedPoint: 0.0015,
  chartDataTreeTiltFallenPreview: null,
};

export const treeTiltFallenAnalyticsSlice = createSlice({
  name: 'TreeTiltFallenAnalytic',
  initialState,
  reducers: {
    clearTreeTiltFallenAnalytics: () => {
      return initialState;
    },
    changeDataTreeTiltFallenAnalytics: (state, action: PayloadAction<any>) => {
      state.dataTreeTiltFallen = action.payload;
      if (!state.dataTreeTiltFallenInterval || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((TreeTiltFallenInstance: any) => {
          let color = 'black';
          const TreeTiltFallenInterval = state.dataTreeTiltFallenInterval?.range?.find(
            (item: any, index: number) =>
              item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
          );
          if (TreeTiltFallenInterval?.visible) {
            color = TreeTiltFallenInterval.color;
          } else color = 'transparent';

          const properties = {
            color: color,
            length: TreeTiltFallenInstance.length,
            status: TreeTiltFallenInterval.lable,
            _id: TreeTiltFallenInstance._id,
          };

          return multiLineString(TreeTiltFallenInstance.geometry.coordinates, properties);
        }),
      };
    },
    changeDataTreeTiltFallenInterval: (state, action: PayloadAction<any>) => {
      state.dataTreeTiltFallenInterval = action.payload;
      if (state.dataTreeTiltFallenPolygon && state.dataTreeTiltFallenInterval) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataTreeTiltFallenPolygon.map((TreeTiltFallenInstance: any) => {
            let color = 'black';
            const TreeTiltFallenInterval = state.dataTreeTiltFallenInterval.range?.find(
              (item: any, index: number) =>
                item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
            );
            if (TreeTiltFallenInterval?.visible) {
              color = TreeTiltFallenInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...TreeTiltFallenInstance.geometry },
              properties: {
                ...TreeTiltFallenInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
      if (!state.dataTreeTiltFallen || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: state.dataTreeTiltFallen.map((TreeTiltFallenInstance: any) => {
          let color = 'black';
          const TreeTiltFallenInterval = action.payload?.range?.find(
            (item: any, index: number) =>
              item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
          );
          if (TreeTiltFallenInterval?.visible) {
            color = TreeTiltFallenInterval.color;
          } else color = 'transparent';

          const properties = {
            color: color,
            length: TreeTiltFallenInstance.length,
            status: TreeTiltFallenInterval.lable,
            _id: TreeTiltFallenInstance._id,
          };

          return multiLineString(TreeTiltFallenInstance.geometry.coordinates, properties);
        }),
      };
    },
    changeVisibleIntervalTreeTiltFallen: (state, action: PayloadAction<number>) => {
      const currentInterval = state.chartDataTreeTiltFallenPreview
        ? state.chartDataTreeTiltFallenPreview.intervalLimit
        : state.dataTreeTiltFallenInterval;
      state.isShowAllLayer = currentInterval.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      currentInterval.range = update(currentInterval.range, {
        [action.payload]: {
          visible: { $set: !currentInterval.range[action.payload].visible },
        },
      });
      if (state.dataTreeTiltFallen) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataTreeTiltFallen.map((TreeTiltFallenInstance: any) => {
            let color = 'black';
            const TreeTiltFallenInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
            );
            if (TreeTiltFallenInterval?.visible) {
              color = TreeTiltFallenInterval.color;
            } else color = 'transparent';

            const properties = {
              color: color,
              length: TreeTiltFallenInstance.length,
              status: TreeTiltFallenInterval.lable,
              _id: TreeTiltFallenInstance._id,
            };

            return multiLineString(TreeTiltFallenInstance.geometry.coordinates, properties);
          }),
        };
      }
      if (state.dataTreeTiltFallenPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataTreeTiltFallenPolygon.map((TreeTiltFallenInstance: any) => {
            let color = 'black';
            const TreeTiltFallenInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
            );
            if (TreeTiltFallenInterval?.visible) {
              color = TreeTiltFallenInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...TreeTiltFallenInstance.geometry },
              properties: {
                ...TreeTiltFallenInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeVisibleIntervalTreeTiltFallenAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataTreeTiltFallenInterval.range = update(state.dataTreeTiltFallenInterval.range, {
        $apply: (intervalRange: Array<any>) =>
          intervalRange.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
      if (state.dataTreeTiltFallen) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataTreeTiltFallen.map((TreeTiltFallenInstance: any) => {
            let color = 'black';
            const TreeTiltFallenInterval = state.dataTreeTiltFallenInterval?.range?.find(
              (item: any, index: number) =>
                item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
            );
            if (TreeTiltFallenInterval?.visible) {
              color = TreeTiltFallenInterval.color;
            } else color = 'transparent';

            const properties = {
              color: color,
              length: TreeTiltFallenInstance.length,
              status: TreeTiltFallenInterval.lable,
              _id: TreeTiltFallenInstance._id,
            };

            return multiLineString(TreeTiltFallenInstance.geometry.coordinates, properties);
          }),
        };
      }
      if (state.dataTreeTiltFallenPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataTreeTiltFallenPolygon.map((TreeTiltFallenInstance: any) => {
            let color = 'black';
            const TreeTiltFallenInterval = state.dataTreeTiltFallenInterval?.range?.find(
              (item: any, index: number) =>
                item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
            );
            if (TreeTiltFallenInterval?.visible) {
              color = TreeTiltFallenInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...TreeTiltFallenInstance.geometry },
              properties: {
                ...TreeTiltFallenInstance.properties,
                color: color,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeDataPolygonTreeTiltFallenAnalytics: (state, action: PayloadAction<any>) => {
      state.dataTreeTiltFallenPolygon = action.payload;
      if (!state.dataTreeTiltFallenInterval) {
        return;
      }
      const geoJson = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((TreeTiltFallenInstance: any) => {
          let color = 'black';
          const TreeTiltFallenInterval = state.dataTreeTiltFallenInterval.range?.find(
            (item: any, index: number) =>
              item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
          );
          if (TreeTiltFallenInterval?.visible) {
            color = TreeTiltFallenInterval.color;
          } else color = 'transparent';

          return {
            geometry: { ...TreeTiltFallenInstance.geometry },
            properties: {
              ...TreeTiltFallenInstance.properties,
              color: color,
            },
          };
        }),
      };
      state.dataGeoJsonPolygon = geoJson;
    },

    changeZoomLayerTreeTiltFallen: (state, action: PayloadAction<number>) => {
      state.zoomLayer = action.payload;
      const zoomLevel = action.payload;
      const currentRange = state.chartDataTreeTiltFallenPreview
        ? state.chartDataTreeTiltFallenPreview.intervalLimit?.range
        : state.dataTreeTiltFallenInterval?.range;
      if (state.dataTreeTiltFallen) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataTreeTiltFallen.map((TreeTiltFallenInstance: any) => {
            let color = 'black';
            const TreeTiltFallenInterval = currentRange?.find(
              (item: any, index: number) =>
                item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
            );
            if (TreeTiltFallenInterval?.visible) {
              color = TreeTiltFallenInterval.color;
            } else color = 'transparent';

            const properties = {
              color: color,
              length: TreeTiltFallenInstance.length,
              status: TreeTiltFallenInterval.lable,
              _id: TreeTiltFallenInstance._id,
            };

            return multiLineString(TreeTiltFallenInstance.geometry.coordinates, properties);
          }),
        };
      }
    },
    changeFixedSizePointTreeTiltFallen: (state, action: PayloadAction<any>) => {
      state.sizeFixedPoint = action.payload;
    },
    changeChartDataTreeTiltFallenPreview: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        const intervalLimit = {
          ...state.dataTreeTiltFallenInterval,
          range: action.payload.range,
        };
        const chartData: any = [];
        action.payload.range.forEach((item: any) => {
          const listByRange = state.dataTreeTiltFallen.filter(
            (TreeTiltFallen: any) => TreeTiltFallen.chloroIn >= item.from && TreeTiltFallen.chloroIn <= item.to
          );
          chartData.push({ [item.lable]: listByRange.length });
        });
        let dataPreview = { chartData, intervalLimit };

        state.chartDataTreeTiltFallenPreview = dataPreview;

        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataTreeTiltFallen.map((TreeTiltFallenInstance: any) => {
            let color = 'black';
            const TreeTiltFallenInterval = state.chartDataTreeTiltFallenPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
            );
            if (TreeTiltFallenInterval?.visible) {
              color = TreeTiltFallenInterval.color;
            } else color = 'transparent';

            const properties = {
              color: color,
              length: TreeTiltFallenInstance.length,
              status: TreeTiltFallenInterval.lable,
              _id: TreeTiltFallenInstance._id,
            };

            return multiLineString(TreeTiltFallenInstance.geometry.coordinates, properties);
          }),
        };
        state.dataGeoJsonPolygon = {
          type: 'FeatureCollection' as any,
          features: state.dataTreeTiltFallenPolygon.map((TreeTiltFallenInstance: any) => {
            let color = 'black';
            const TreeTiltFallenInterval = state.chartDataTreeTiltFallenPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from < TreeTiltFallenInstance.length && item.to >= TreeTiltFallenInstance.length
            );
            if (TreeTiltFallenInterval?.visible) {
              color = TreeTiltFallenInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...TreeTiltFallenInstance.geometry },
              properties: {
                ...TreeTiltFallenInstance.properties,
                color: color,
              },
            };
          }),
        };
      } else {
        state.chartDataTreeTiltFallenPreview = null;
      }
    },
  },
});

export const {
  clearTreeTiltFallenAnalytics,
  changeDataTreeTiltFallenAnalytics,
  changeDataTreeTiltFallenInterval,
  changeVisibleIntervalTreeTiltFallen,
  changeVisibleIntervalTreeTiltFallenAllLayers,
  changeDataPolygonTreeTiltFallenAnalytics,
  changeZoomLayerTreeTiltFallen,
  changeFixedSizePointTreeTiltFallen,
  changeChartDataTreeTiltFallenPreview,
} = treeTiltFallenAnalyticsSlice.actions;

export const treeTiltFallenAnalyticsSelector = (state: RootState) => state.treeTiltFallenAnalytic;

export default treeTiltFallenAnalyticsSlice.reducer;
