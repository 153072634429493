/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Box,
  Button,
  debounce,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { IFormType } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import { useCallback, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { deleteClientByIdReactQuery, getClientListByPagination, queryMe } from 'services/clients/apiClient.services';
import { useAppDispatch } from '../../store/hooks';
import { setClientData } from '../../store/slices/clientSlice';
import { ClientActionsDialog } from './ClientActionsDialog';

const ClientListDialog = (props: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { onClose, open } = props;
  const [page, setPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [clientIndex, setClientIndex] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const titleDialog = useRef<HTMLDivElement>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowPerPageOptions = [5, 10, 15, 20];
  const { data } = useQuery(
    [QUERY_KEY.CLIENT_DETAILS, page, rowsPerPage, searchClient],
    () => getClientListByPagination(page, rowsPerPage, searchClient),
    { keepPreviousData: false }
  );
  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const handleDelete = useMutation((id: string) => deleteClientByIdReactQuery(id), {
    onSuccess: () => {
      setOpenDeleteModal(false);
      setClientIndex('');
      queryClient.invalidateQueries(QUERY_KEY.CLIENT_DETAILS);
      queryClient.invalidateQueries(QUERY_KEY.CLIENT_LIST);
    },
  });

  const onSave = () => {
    handleDelete.mutate(clientIndex);
  };

  const handleClose = () => {
    onClose();
  };

  const flexValue = [4, 2, 1];

  const styles = {
    clientListContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      // border: `1px solid ${theme.palette.divider}`,
      p: '16px',
      height: '57px',
    },
    clientListTitle: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18.2px',
    },
    clientContainerName: {
      display: 'flex',
      alignItems: 'center',
      flex: flexValue[0],
      overflowX: 'hidden',
      mr: '20px',
    },
    clientAvatar: {
      width: '33px',
      height: '33px',
    },
    clientName: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '16px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
    },
    clientUpdateTime: {
      display: 'flex',
      alignItems: 'center',
      flex: flexValue[1],
      color: theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B',
    },
    clientStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: flexValue[2],
      color: theme.palette.primary.main,
      p: '5px 0',
      backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F3FDF7',
      borderRadius: '21px',
    },
    clientActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: flexValue[2],
    },
    clientActionsIcon: {
      width: '16px',
      height: '16px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#9F9F9F',
    },
    clientShowTotal: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '20px 20px',
    },
  };
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setClientIndex('');
  };

  const getDate = useCallback((dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
  }, []);

  const dataClientList = useMemo(() => {
    return data?.data?.data;
  }, [data]);
  const dataValue = useMemo(() => {
    return data?.data?.metadata;
  }, [data]);

  const indexStartPage = useMemo(() => {
    return (dataValue?.currentPage - 1) * dataValue?.pageSize + 1;
  }, [dataValue?.currentPage, dataValue?.pageSize]);

  const indexEndPage = useMemo(() => {
    if (!dataValue) {
      return 0;
    }
    return Math.min(dataValue.currentPage * dataValue.pageSize, dataValue.totalCount);
  }, [dataValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchClient = useCallback(
    debounce((value: string) => {
      setSearchClient(value);
    }, 500),
    []
  );

  const handleChangePageSize = (event: any) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleAddClient = () => {
    dispatch(setClientData(null));
    navigate('/create-clients');
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& MuiPaper-root': {
            overflowY: 'scroll',
            background: theme.palette.background.paper,
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        },
      }}>
      <DialogTitle
        ref={titleDialog}
        sx={{
          color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
          fontSize: '20px',
          fontWeight: '600',
          background: theme.palette.background.paper,
        }}>
        List of Clients
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          p: 0,
          position: 'absolute',
          right: 8,
          top: 8,
          '.MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: '20px',
          borderTop: `1px solid ${theme.palette.divider}`,
          background: theme.palette.background.paper,
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography mr="10px" fontSize="16px">
            Show
          </Typography>
          <Button
            sx={{
              height: '40px',
            }}>
            <Select
              sx={{
                border: 'none',
                width: '65px',
                height: '40px',
              }}
              id="demo-simple-select"
              value={rowsPerPage}
              onChange={handleChangePageSize}>
              {rowPerPageOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Button>
        </Box>
        <Box>
          <TextField
            type="text"
            placeholder="Search Clients..."
            sx={{
              mr: 1,
              width: '340px',
              minWidth: '340px',
              height: '40px',
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '.MuiOutlinedInput-root': {
                height: '40px',
              },
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '5px',
            }}
            onChange={(e) => {
              debouncedSearchClient(e.target.value);
            }}
            InputProps={{
              size: 'small',
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ height: '24px', width: '24px' }} />
                </InputAdornment>
              ),
              inputProps: {
                style: {
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '17px',
                },
              },
            }}
          />
          <Button
            startIcon={<Add />}
            onClick={handleAddClient}
            sx={{
              width: '160px',
              height: '40px',
              border: `1px solid ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#1C9D57',
                border: `1px solid ${theme.palette.primary.main}`,
                boxShadow: 'none',
              },
              '&:active': {
                boxShadow: 'none',
                backgroundColor: '#1C9D57',
                border: `1px solid ${theme.palette.primary.main}`,
              },
              '&:focus': {
                boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15)',
                border: `1px solid ${theme.palette.primary.main}`,
              },
            }}>
            Add Client
          </Button>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            ...styles.clientListContainer,
          }}>
          <Typography
            sx={{
              ...styles.clientListTitle,
              mr: '20px',
              flex: flexValue[0],
            }}>
            Client Name
          </Typography>
          <Typography
            sx={{
              ...styles.clientListTitle,
              flex: flexValue[1],
            }}>
            Last Activity
          </Typography>
          <Typography
            sx={{
              ...styles.clientListTitle,
              flex: flexValue[1],
            }}>
            Date Registered
          </Typography>
          <Typography
            sx={{
              ...styles.clientListTitle,
              flex: flexValue[2],
              textAlign: 'center',
            }}>
            Status
          </Typography>
          <Typography
            sx={{
              ...styles.clientListTitle,
              flex: flexValue[2],
              textAlign: 'center',
            }}>
            Actions
          </Typography>
        </Box>
        <Scrollbars
          style={{
            height: 'calc(100vh - 350px)',
            background: theme.palette.background.paper,
          }}
          autoHide>
          <List
            sx={{
              pt: 0,
              display: 'flex',
              flexDirection: 'column',
            }}>
            {dataClientList && dataClientList.length > 0 ? (
              dataClientList.map((clientDetail: any) => {
                return (
                  <ListItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      height: '50px',
                      '&:hover': {
                        backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#f5f5f5',
                      },
                    }}
                    key={clientDetail._id}>
                    <Box
                      sx={{
                        ...styles.clientContainerName,
                      }}>
                      <ListItemAvatar>
                        <Avatar src={clientDetail.avatar} sx={{ ...styles.clientAvatar }} />
                      </ListItemAvatar>
                      <Typography
                        sx={{
                          ...styles.clientName,
                        }}>
                        {clientDetail.name}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        ...styles.clientUpdateTime,
                      }}>
                      {getDate(clientDetail.updatedAt)}
                    </Typography>
                    <Typography
                      sx={{
                        ...styles.clientUpdateTime,
                      }}>
                      {getDate(clientDetail.createdAt)}
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        ...styles.clientStatus,
                      }}>
                      Active
                    </Typography>
                    <Box
                      sx={{
                        ...styles.clientActions,
                      }}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => navigate(`/clients/edit/${clientDetail._id}`, { state: IFormType.EDIT })}>
                        <EditRoundedIcon
                          sx={{
                            ...styles.clientActionsIcon,
                          }}
                        />
                      </IconButton>
                      {!isSuperUser && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setClientIndex(clientDetail._id);
                            setOpenDeleteModal(true);
                          }}>
                          <DeleteRoundedIcon
                            sx={{
                              ...styles.clientActionsIcon,
                            }}
                          />
                        </IconButton>
                      )}
                    </Box>
                  </ListItem>
                );
              })
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50px',
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}>
                <Typography
                  sx={{
                    ...styles.clientListTitle,
                    color: theme.palette.mode === 'dark' ? '#ffffff99' : '#00000099',
                  }}>
                  No Client Found
                </Typography>
              </Box>
            )}
          </List>
        </Scrollbars>
        {dataClientList && dataClientList.length > 0 && (
          <Box
            sx={{
              ...styles.clientShowTotal,
              background: theme.palette.background.paper,
            }}>
            <Typography>
              Showing {indexStartPage} to {indexEndPage} of {dataValue?.totalCount} clients
            </Typography>
            <Stack spacing={2}>
              <Pagination
                sx={{
                  '& .MuiPagination-ul': {
                    '& .Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                    },
                    '& .Mui-selected:hover': {
                      backgroundColor: '#23BE6AB0',
                    },
                    '& .MuiPaginationItem-icon': {
                      color: theme.palette.primary.main,
                    },
                  },
                }}
                count={dataValue?.pageCount}
                defaultPage={1}
                page={page}
                onChange={(_, value) => {
                  setPage(value);
                }}
              />
            </Stack>
          </Box>
        )}
      </Box>
      {/* delete */}
      <ClientActionsDialog
        title="Delete Client"
        open={openDeleteModal}
        onClose={closeDeleteModal}
        description="Are you sure about that?"
        onCancel={closeDeleteModal}
        onSave={onSave}
        submit={false}
      />
    </Dialog>
  );
};

export default ClientListDialog;
