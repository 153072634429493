import { Box, Button, Grid, styled, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import ExcelButton from 'components/Common/ExcelButton';
import { TabPanel } from 'components/MapView/TabPanel';
import { QUERY_KEY } from 'constants/constants';
import { saveAs } from 'file-saver';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import { getAllDataIntervalLimit } from 'services/analytics/apiAnalyticsData.services';
import { exportExcelLastLevelAnalytics, getUIDisplay, queryMe } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import {
  changeIsPointerMode,
  changeIsPolygonMode,
  clearDataPointPolygon,
  standCountAnalyticSelector,
} from 'store/slices/map-view/standCountAnalytics';
import { changeDataIntervalAllLimit, changeIsInEditTab, mapViewSelector } from 'store/slices/mapViewSlice';
import { useLandUsageLayerStyle, useLandUsageTabStyle } from '../../../MapViewStyle';
import CircumferenceEdit from '../Edit/circumference-edit';
import CrownAreaEdit from '../Edit/crown-area-edit';
import StandCountEdit from '../Edit/StandCountEdit';

const fieldList = [
  TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE,
  TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY,
  TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA,
  TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION,
  TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.SOIL_WATER_CONTENT_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.CUSTOM_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT,
];

const analyticWithEditTab = [
  TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT,
  TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS,
  TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA,
];
interface ISwipeableViewsCustomProps {
  analyticName?: string;
  analyticTab: ReactNode;
  layerTab: ReactNode;
  isDisableLabel?: boolean;
  isShowDownloadButton?: boolean;
  isInWorkspace?: boolean;
}

export const SwipeableViewsCustom = ({
  analyticName,
  analyticTab,
  layerTab,
  isShowDownloadButton = false,
  isInWorkspace = false,
}: ISwipeableViewsCustomProps) => {
  const classesLandUse = useLandUsageTabStyle();
  const classes = useLandUsageLayerStyle();
  const bottomBock = useRef() as React.MutableRefObject<HTMLDivElement>;
  const scrollBlockRef = useRef() as any;
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const [tabChildNumber, setTabChildNumber] = useState<number>(0);
  const [isShowMoreBtn, setIsShowMoreBtn] = useState(false);
  const [ableToView, setAbleToView] = useState(false);
  const [isAbleShowEditTabUI, setIsAbleShowEditTabUI] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  const { data: userInfo } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: false,
  });

  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);
  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);

  const { data: userInfoByClient } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && userId !== '' && !isSuperAdmin && !isSuperUser,
    }
  );

  const isShowEditTabUI = useMemo(() => {
    if (isSuperAdmin || isSuperUser) return true;
    if (!userInfo?.data?.role) return false;
    const dataRoleValue = userInfoByClient?.data?.flagTab;

    if (!dataRoleValue || dataRoleValue.length === 0) return false;
    dataRoleValue.includes('MAPVIEW-ANALYSIS-TAB-EDIT') && setIsAbleShowEditTabUI(true);
    return isAbleShowEditTabUI;
  }, [isSuperAdmin, isSuperUser, userInfo, userInfoByClient, isAbleShowEditTabUI]);

  useEffect(() => {
    if (isSuperAdmin || isSuperUser) {
      setAbleToView(true);
      return;
    }
    if (!userInfo?.data?.role) {
      setAbleToView(false);
      return;
    }

    const permissionsData: string[] = [];
    userInfo?.data?.role?.map((item: any) => {
      if (item.clientId === clientId) {
        permissionsData.push(...item.permissions);
      }
    });
    if (permissionsData.length === 0) return;
    permissionsData.includes('MAPVIEW_ANALYSIS_STAND_COUNT_TAB_EDIT_VIEW') && setAbleToView(isShowEditTabUI);
  }, [clientId, isShowEditTabUI, isSuperAdmin, isSuperUser, userInfo]);

  const {
    analyticId,
    isDefaultInterval,
    parentAnalyticsId,
    sizeCropCoverageSelected,
    sizePlantHealthSelected,
    sizeTilerDensitySelected,
    sizeVacantAreaSelected,
    sizeChlorophyllPaddySelected,
    sizeVigorPaddySelected,
    sizeStressFieldSelected,
    sizeWaterUptakeFieldSelected,
    sizeSoilWaterContentFieldSelected,
    sizeSoilErosionFieldSelected,
    sizeLeafPhenologyFieldSelected,
    sizeElevationFieldSelected,
    sizeSlopeFieldSelected,
    sizeCustomFieldSelected,
    sizeVegetationEncroachmentSelected,
  } = useAppSelector(mapViewSelector);
  const { isPolygonMode } = useAppSelector(standCountAnalyticSelector);

  useQuery(
    [QUERY_KEY.GET_ALL_LIMIT_INTERVAL, isDefaultInterval, parentAnalyticsId, analyticId],
    () => getAllDataIntervalLimit(parentAnalyticsId || '', analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId && !!parentAnalyticsId,
      onSuccess: (data) => {
        dispatch(changeDataIntervalAllLimit(data.data));
      },
    }
  );

  useEffect(() => {
    dispatch(clearDataPointPolygon());
  }, [dispatch, isPolygonMode]);

  useEffect(() => {
    dispatch(changeIsPolygonMode(false));
    dispatch(changeIsPointerMode(false));
  }, [dispatch]);

  // redux follow value from tab
  useEffect(() => {
    dispatch(changeIsInEditTab(tabChildNumber === 2));
  }, [dispatch, tabChildNumber]);

  const handleParamCondition = () => {
    let size = null;
    if (fieldList.includes(analyticName as TYPE_ANALYTICS_MAP_VIEW)) {
      size =
        sizeCropCoverageSelected ||
        sizePlantHealthSelected ||
        sizeVigorPaddySelected ||
        sizeChlorophyllPaddySelected ||
        sizeVacantAreaSelected ||
        sizeTilerDensitySelected ||
        sizeStressFieldSelected ||
        sizeWaterUptakeFieldSelected ||
        sizeSoilWaterContentFieldSelected ||
        sizeSoilErosionFieldSelected ||
        sizeLeafPhenologyFieldSelected ||
        sizeElevationFieldSelected ||
        sizeSlopeFieldSelected ||
        sizeCustomFieldSelected ||
        sizeVegetationEncroachmentSelected;
    }
    const defaultInterval = size ? undefined : isDefaultInterval;
    return exportExcelLastLevelAnalytics(analyticId!, defaultInterval, size!);
  };

  const exportExcelMutation = useMutation(() => handleParamCondition(), {
    onSuccess(res) {
      saveAs(res.request.responseURL, `${analyticName}-Analytics.xlsx`);
      toast.success('Download file successful');
    },
    onError() {
      toast.error('Download failed');
    },
  });

  const renderEditTabWithAnalytic = () => {
    switch (analyticName as TYPE_ANALYTICS_MAP_VIEW) {
      case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
        return <CircumferenceEdit />;
      case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
        return <CrownAreaEdit />;
      case TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT:
      default:
        return <StandCountEdit layerTab={layerTab} />;
    }
  };

  const tabChildMenu = [
    {
      id: 0,
      label: t('trans.categories'),
    },
    {
      id: 1,
      label: t('trans.layer'),
    },
  ];

  return (
    <Grid
      direction="column"
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        height: 'fit-content',
        width: '100%',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
      }}>
      <Grid direction="row">
        <Tabs
          value={tabChildNumber}
          onChange={(_event, value) => setTabChildNumber(value)}
          variant="fullWidth"
          className={classesLandUse.tabsContainer}>
          {tabChildMenu.map((item) => {
            return (
              <TabStyled
                key={item.id}
                label={item.label}
                {...a11yProps(item.id)}
                sx={{
                  fontSize: '16px',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
              />
            );
          })}
          {!isInWorkspace && analyticWithEditTab.includes(analyticName as TYPE_ANALYTICS_MAP_VIEW) && ableToView ? (
            <TabStyled
              label={t('trans.edit')}
              {...a11yProps(2)}
              sx={{
                fontSize: '16px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                '&.Mui-selected': {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
            />
          ) : null}
        </Tabs>
      </Grid>
      <Grid direction="row">
        <SwipeableViews axis={'x-reverse'} index={tabChildNumber}>
          <TabPanel value={tabChildNumber} index={0} isAnalyticsTab={true}>
            <Scrollbars
              autoHeight
              autoHeightMin={'46vh'}
              ref={scrollBlockRef}
              style={{
                backgroundColor: theme.palette.background.paper,
              }}
              autoHide
              onScrollFrame={(values: any) => {
                if (values.top > 0.16) {
                  setIsShowMoreBtn(false);
                } else {
                  setIsShowMoreBtn(true);
                }
              }}>
              {analyticTab}
              {isShowDownloadButton && (
                <Box textAlign="center" mt="16px" mb="24px">
                  {/* Inside analytics -> categories tab */}
                  <ExcelButton
                    onClick={() => exportExcelMutation.mutate()}
                    disabled={exportExcelMutation.isLoading}
                    isLoading={exportExcelMutation.isLoading}
                  />
                </Box>
              )}
              <div ref={bottomBock}></div>
              <Box
                sx={{
                  display: isShowMoreBtn ? 'block' : 'none',
                  width: '100%',
                  height: '40px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  backgroundColor: 'transparent',
                  position: 'fixed',
                  bottom: '60px',
                  transition: 'all 0.3s ease',
                }}>
                <Button
                  sx={{
                    maxWidth: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.background.default,
                    borderRadius: '5px',
                    padding: '5px',
                    border: `1px solid ${theme.palette.primary.main}`,
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5)',
                    margin: 'auto',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: '#fff',
                    },
                  }}
                  onClick={() => {
                    scrollBlockRef.current.view.scroll({
                      top: 10000,
                      behavior: 'smooth',
                    });
                  }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      width: '200px',
                    }}>
                    {t('trans.more')}
                  </Typography>
                </Button>
              </Box>
            </Scrollbars>
          </TabPanel>
          <TabPanel value={tabChildNumber} index={1} isAnalyticsTab={true}>
            <Scrollbars autoHeight autoHeightMin={'46vh'}>
              <Grid
                container
                classes={{ root: classes.containerStyle }}
                direction="column"
                sx={{ display: 'flex', flex: 1 }}>
                <Box className={classes.boxStyle}>
                  <Box className={classes.boxDataStyle}>{layerTab}</Box>
                </Box>
              </Grid>
            </Scrollbars>
          </TabPanel>
          <TabPanel value={tabChildNumber} index={2} isAnalyticsTab={true}>
            {renderEditTabWithAnalytic()}
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
};

const TabStyled = styled(Tab)((theme) => ({
  textTransform: 'unset',
  fontWeight: 500,
  fontSize: '12px',
}));
