import { BigNumber } from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import PieChartCommon from 'components/Common/PieChartCommon';
import { useCallback, useMemo } from 'react';
import WatershedBasinCategoriesTable from './WatershedBasinCategoriesTable';

export const WatershedBasinChart = ({ mainData, mainColor, clientSetting }: any) => {
  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const clientAreaUnitMeasurement = useCallback(
    (area: any) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return new BigNumber(area * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return new BigNumber(area * 0.01).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return new BigNumber(area * 10000).toFixed(areaSetting.decimalPlace);
        default:
          return new BigNumber(area).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const dataPie = useMemo(() => {
    if (!mainData) return [];
    const dataMapping: Array<{ name: string; value: string }> = [];
    mainData.forEach((val: any) => {
      const _name = val.type;
      const area = val.lduseArea;
      dataMapping.push({
        name: _name,
        value: clientAreaUnitMeasurement(area),
      });
    });
    return dataMapping;
  }, [clientAreaUnitMeasurement, mainData]);

  const formatText = useCallback(
    (name: any) => {
      const dataValue = dataPie.find((val: any) => val.name === name);
      if (!dataValue) {
        return '';
      }
      return `${name}: ${dataValue?.value} ${areaUnit}`;
    },

    [areaUnit, dataPie]
  );

  return (
    <>
      {dataPie.length > 0 && (
        <>
          <PieChartCommon
            title={TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN}
            dataChart={dataPie}
            formatText={formatText}
            colorCharts={mainColor}
            tooltipFormatter={`{b}: {c} ${areaUnit}`}
            withoutConvertMultiLanguage
          />
        </>
      )}
      <WatershedBasinCategoriesTable data={mainData} />
    </>
  );
};
