import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeDrawIssueShape,
  changeDropMarkerInfo,
  changeShowIssueModal,
  rightBarSelector,
} from 'store/slices/rightBarSlice';
import { DrawIssueShapeEnum, IIssue } from '../../../interfaces';

interface EditShapeAndLocationButtonProps {
  formik: any;
  initialData: IIssue;
  handleClose: () => void;
}

const EditShapeAndLocationButton: FC<EditShapeAndLocationButtonProps> = ({ formik, initialData, handleClose }) => {
  const dispatch = useAppDispatch();
  const {
    issuesTab: {},
  } = useAppSelector(rightBarSelector);
  const handleChangeLocation = () => {
    dispatch(changeShowIssueModal(false));
    dispatch(
      changeDropMarkerInfo({
        isShow: true,
        initialData: {
          id: initialData._id,
          coordinates: initialData.geometry.coordinates,
        },
      })
    );
  };

  const handleChangeShape = () => {
    dispatch(
      changeDrawIssueShape({
        isShow: true,
        type: formik.values.type,
        initialData: { id: initialData._id },
      })
    );
    handleClose();
  };

  const commonButton = (label: string) => {
    return (
      <Button variant="contained" color="neutral" size="small" onClick={handleChangeShape}>
        <Typography>{label}</Typography>
      </Button>
    );
  };

  const renderButton = () => {
    if (!initialData._id) return null;
    if (initialData.isNonGeotag && formik.values.type === DrawIssueShapeEnum.IMAGE) {
      return (
        <Button variant="contained" color="neutral" size="small" onClick={handleChangeLocation}>
          <Typography>Edit Location</Typography>
        </Button>
      );
    } else if (formik.values.type === DrawIssueShapeEnum.POLYGON) {
      return commonButton('Redraw Polygon');
    } else if (formik.values.type === DrawIssueShapeEnum.LINE) {
      return commonButton('Redraw Line');
    } else if (formik.values.type === DrawIssueShapeEnum.POINT) {
      return commonButton('Redraw Point');
    }
    return null;
  };

  return renderButton();
};

export default EditShapeAndLocationButton;
