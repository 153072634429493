import { Box, Stack, TablePagination } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import { ChangeEvent, FC, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useQuery } from 'react-query';
import { getListIssuesWithPagination } from 'services/MapView/apiMapViewConfig.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { IDateRange, IIssue, SortDateEnum } from '../interfaces';
import CommonIssueCard from './common-issue-card';

interface CardIssueListProps {
  selectedDate: IDateRange;
}

const CardIssueList: FC<CardIssueListProps> = ({ selectedDate }) => {
  const [listIssues, setListIssues] = useState<IIssue[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { levelId } = useAppSelector(mapViewSelector);

  useQuery(
    [QUERY_KEY.GET_LIST_ISSUE_PAGINATION, selectedDate, currentPage, rowsPerPage],
    () =>
      getListIssuesWithPagination({
        startDate: moment(selectedDate.startDate).format('YYYY-MM-DD'),
        endDate: moment(selectedDate.endDate).format('YYYY-MM-DD'),
        page: currentPage,
        paginationVersion: 2,
        perPage: rowsPerPage,
        sortDate: SortDateEnum.DESC,
        levelId: levelId!,
      }),
    {
      onSuccess: (res: any) => {
        setListIssues(res.data.data);
        setTotalCount(res.data.metadata.totalCount);
      },
      enabled: !!levelId,
    }
  );

  const handleChangeStatus = (id: string, value: boolean) => {
    const newListIssues = listIssues.map((item) => (item._id === id ? { ...item, status: value } : item));
    setListIssues(newListIssues);
  };

  const handleChangePage = (_: any, newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <Box>
      <Scrollbars style={{ height: 'calc(100vh - 76px - 240px)' }}>
        <Stack spacing={1}>
          {listIssues.map((item, i) => (
            <CommonIssueCard data={item} key={i} handleChangeStatus={handleChangeStatus} />
          ))}
        </Stack>
      </Scrollbars>
      <TablePagination
        component="div"
        count={totalCount}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '& .MuiToolbar-root': { pl: 0 },
        }}
      />
    </Box>
  );
};

export default CardIssueList;
