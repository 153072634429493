import { Box } from '@mui/material';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { Map2dOptionEnum } from 'interfaces/workspace';
import { useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeRightBar2dOptionsValue,
  changeRightBar2dOptionsVisible,
  tilingMapSelector,
} from 'store/slices/tilingMapSlice';
import CommonRastersVectors from './common-rasters-vectors';
import ListAnalytic from './ListAnalytic';
import SwitchCategories from './SwitchCategories';

const Layer2dMap = () => {
  const { taskList } = useQueryListTaskOfField();
  const { selectedTaskId } = useAppSelector(tilingMapSelector);
  const { selectedTask } = useQueryListTaskOfField();
  const { dsmGeoTIFFPath, dtmGeoTIFFPath, taskId } = selectedTask || {};
  const dispatch = useAppDispatch();

  // reset raster into orthophoto whenever change taskId
  // also need to invisible dsm or dtm if data not existed
  useEffect(() => {
    dispatch(changeRightBar2dOptionsValue({ name: Map2dOptionEnum.orthophoto, value: true }));
    dispatch(changeRightBar2dOptionsVisible({ name: Map2dOptionEnum.dsm, isVisible: !!dsmGeoTIFFPath || !!taskId }));
    dispatch(changeRightBar2dOptionsVisible({ name: Map2dOptionEnum.dtm, isVisible: !!dtmGeoTIFFPath || !!taskId }));
  }, [dispatch, dsmGeoTIFFPath, dtmGeoTIFFPath, selectedTaskId, taskId]);

  return (
    <>
      {taskList?.length ? (
        <Scrollbars autoHeight autoHeightMin={'calc(100vh - 270px)'}>
          <Box>
            <ListAnalytic />
            <CommonRastersVectors />
            <SwitchCategories />
          </Box>
        </Scrollbars>
      ) : null}
    </>
  );
};

export default Layer2dMap;
