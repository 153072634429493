import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { point as pointTurf } from '@turf/turf';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  changePointStandCount,
  changeVisiblePopupAdd,
  clearStandCountAnalytic,
  standCountAnalyticSelector,
} from 'store/slices/map-view/standCountAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { IStandCount } from '../../../../../../common/entity/IStandCount';
import { QUERY_KEY } from '../../../../../../constants/constants';
import { addTreeManual } from '../../../../../../services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { SwitchCustom } from '../../../../../Common/SwitchCustom';

export const DialogStandPoint = () => {
  const { analyticId } = useAppSelector(mapViewSelector);
  const { point } = useAppSelector(standCountAnalyticSelector);
  const queryClient = useQueryClient();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm<IStandCount>({
    defaultValues: {
      status: 'Tree',
      // treeID: '',
      latitude: '',
      longitude: '',
    },
  });

  const mutationCreatePoint = useMutation((data: any) => addTreeManual(data), {
    onSuccess: () => {
      toast.success('Point created successfully');
      // dispatch(changeVisiblePopupAdd(false));
      dispatch(clearStandCountAnalytic());
      queryClient.invalidateQueries([QUERY_KEY.STAND_COUNT_ANALYSIS, analyticId]);
      queryClient.invalidateQueries([QUERY_KEY.STAND_COUNT_CHART_ANALYSIS, analyticId]);
      setValue('longitude', '');
      setValue('latitude', '');
    },
    onError: (e: any) => {
      e?.status !== 403 && toast.error('Error creating point');
      dispatch(changeVisiblePopupAdd(false));
      dispatch(clearStandCountAnalytic());
    },
  });

  const statusPoint = watch('status');
  const latitudeData = watch('latitude');
  const longitudeData = watch('longitude');

  useEffect(() => {
    if (!point?.latitude || !point?.longitude) {
      return;
    }

    if (point.latitude === parseFloat(latitudeData) && point.longitude === parseFloat(longitudeData)) {
      return;
    }
    setValue('latitude', point.latitude.toString());
    setValue('longitude', point.longitude.toString());
  }, [point]);

  useEffect(() => {
    if (!latitudeData || !longitudeData) {
      return;
    }
    dispatch(changePointStandCount({ latitude: parseFloat(latitudeData), longitude: parseFloat(longitudeData) }));
  }, []);

  const onSubmit = (value: any) => {
    mutationCreatePoint.mutate({
      analysisId: analyticId,
      // treeId: value.treeID,
      status: value.status,
      latY: +value.latitude,
      longX: +value.longitude,
      geometry: pointTurf([+value.longitude, +value.latitude]).geometry,
    });
  };

  const onClose = () => {
    dispatch(changePointStandCount(null as any));
    dispatch(changeVisiblePopupAdd(false));
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiPaper-root ': {
            maxWidth: '320px',
            borderRadius: '12px',
            backgroundColor: (theme) => theme.palette.background.default,
          },
          '& .MuiBackdrop-root': {
            width: '380px',
            maxWidth: '380px',
            marginRight: 'auto',
            backgroundColor: 'transparent',
          },
          width: '380px',
          maxWidth: '380px',
          marginRight: 'auto',
        }}
        fullWidth
        maxWidth="xs"
        disableEscapeKeyDown
        open={true}
        onClose={onClose}
      />
      <Dialog
        sx={{
          '& .MuiPaper-root ': {
            width: '100%',
            height: '100%',
            borderRadius: '12px',
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundImage: 'none',
          },
          '& .MuiBackdrop-root': {
            width: '464px',
            marginLeft: 'auto',
            backgroundColor: 'transparent !important',
            shadow: '10px 10px 20px rgba(0,0,0,0.5) !important',
          },
          width: '464px',
          marginLeft: 'auto',
        }}
        fullWidth
        maxWidth="xs"
        disableEscapeKeyDown
        open={true}
        onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              py: 2,
              px: 2,
              fontSize: '24px',
              lineHeight: '24px',
              fontWeight: 'bold',
              borderBottom: `1px solid ${theme.palette.divider}`,
              backgroundColor: (theme) => theme.palette.background.paper,
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
            }}>
            Add new stand count
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              p: 0,
              position: 'absolute',
              right: 8,
              top: 8,
              '.MuiSvgIcon-root': {
                width: '16px',
                height: '16px',
              },
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
            }}>
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              py: 2,
              px: 2,
              backgroundColor: (theme) => theme.palette.background.paper,
            }}>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name={`status`}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <SwitchCustom
                      sx={{ ml: 1 }}
                      checked={value === 'Tree'}
                      onChange={(e: any) => onChange(e.target.checked ? 'Tree' : 'Blank spot')}
                    />
                  )}
                />
              }
              label={<Typography>{statusPoint}</Typography>}
            />
            {/* <Controller
              control={control}
              name={`treeID`}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Box
                  sx={{
                    flex: 1,
                    mt: 1,
                  }}>
                  <InputLabel>Tree ID</InputLabel>
                  <TextField
                    type="text"
                    size="small"
                    hiddenLabel
                    fullWidth
                    value={value}
                    onBlur={onBlur}
                    onChange={(e: any) => onChange(e.target.value)}
                    inputRef={ref}
                  />
                </Box>
              )}
            /> */}
            <Controller
              name={`latitude`}
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, isDirty, error },
                formState,
              }) => (
                <Box
                  sx={{
                    flex: 1,
                    mt: 1,
                  }}>
                  <InputLabel>Latitude</InputLabel>
                  <TextField
                    disabled={true}
                    type="text"
                    size="small"
                    fullWidth
                    value={value}
                    sx={{
                      '& .MuiInputBase-input': {
                        WebkitTextFillColor: (theme) => (theme.palette.mode === 'dark' ? '#fff !important' : ''),
                      },
                    }}
                  />
                </Box>
              )}
            />
            <Controller
              name={`longitude`}
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Box
                  sx={{
                    flex: 1,
                    mt: 1,
                  }}>
                  <InputLabel>Longitude</InputLabel>
                  <TextField
                    type="text"
                    disabled={true}
                    size="small"
                    fullWidth
                    value={value}
                    sx={{
                      '& .MuiInputBase-input': {
                        WebkitTextFillColor: (theme) => (theme.palette.mode === 'dark' ? '#fff !important' : ''),
                      },
                    }}
                  />
                </Box>
              )}
            />
          </DialogContent>
          <DialogActions
            sx={{
              py: 2,
              px: 2,
              backgroundColor: (theme) => theme.palette.background.paper,
            }}>
            <Button
              type={'button'}
              sx={{
                border: (theme) => `1px solid ${theme.palette.divider}`,
                background: (theme) => (theme.palette.mode === 'dark' ? '#fff' : 'transparent'),
                color: '#3C4043',
              }}
              onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" type={'submit'} autoFocus color={'primary'} disabled={!point}>
              Add point
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
