import { IRange } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAnalyticBoxPlot, updateInterval } from 'services/analytics/apiAnalyticsConfig.services';
import { getChartsTreeHeight } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import {
  changeChartDataTreeHeightPreview,
  changeDataTreeHeightInterval,
  treeHeightAnalyticsSelector,
} from '../../../../../../store/slices/map-view/treeHeightAnalytics';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { TreeHeightCardLayer } from '../../Layer/TreeHeight';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const TreeHeightAnalytics = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { chartDataTreeHeightPreview } = useAppSelector(treeHeightAnalyticsSelector);

  const { data: dataTreeHeightBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.TREE_HEIGHT_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'tree-height'),
    { enabled: !!analyticId }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY.CHARTS_TREE_HEIGHT_ANALYTIC, isDefaultInterval]).then(() => {});
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_TREE_HEIGHT_ANALYTIC, isDefaultInterval],
    () => getChartsTreeHeight(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (res) => {
        const { _id, range }: { _id: string; range: IRange[] } = res.data?.intervalLimit;
        const newRange = range.map((range, index) => {
          return {
            key: index + 1,
            color: range.color,
            lable: range.lable,
            visible: true,
            from: range.from,
            to: range.to,
          };
        });
        dispatch(changeDataTreeHeightInterval({ _id, isDefault: true, range: newRange }));
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataTreeHeightBoxPlot?.data) return [];
    return dataTreeHeightBoxPlot?.data;
  }, [dataTreeHeightBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataTreeHeightPreview
                      ? chartDataTreeHeightPreview?.chartData
                      : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT}
                  getCharts={getChartsTreeHeight}
                  intervalRange={
                    chartDataTreeHeightPreview
                      ? chartDataTreeHeightPreview?.intervalLimit?.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_TREE_HEIGHT_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataTreeHeightPreview}
                  showXAxis
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<TreeHeightCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
