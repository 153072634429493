import CompareIcon from '@mui/icons-material/Compare';
import { Button, Tooltip } from '@mui/material';
import { MapEnum } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { t } from 'i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUIDisplay, queryMe } from 'services/clients/apiClient.services';
import { getAllDateLandUseAnalytic } from 'services/MapView/apiMapViewConfig.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

interface TemporalButtonProps {
  setIsShowTemporalModal: (arg: boolean) => void;
  mode: MapEnum;
}
const TemporalButton: FC<TemporalButtonProps> = ({ setIsShowTemporalModal, mode }) => {
  const [isTurnOnSetting, setIsTurnOnSetting] = useState<boolean>(false);
  const [dateListLength, setDateListLength] = useState<number>(0);
  const { levelId } = useAppSelector(mapViewSelector);
  const { clientId } = useParams();
  const { taskList } = useQueryListTaskOfField();

  const { data: userInfo } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: false,
  });

  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);
  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  useQuery([QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId], () => getUIDisplay(clientId!, userId), {
    enabled: !!clientId && userId !== '' && !isSuperAdmin && !isSuperUser,
    onSuccess(data) {
      const flagTabList = data.data?.flagTab;
      if (flagTabList.includes('UTILITY-SETTINGS-TEMPORAL-BUTTON')) {
        setIsTurnOnSetting(true);
      } else {
        setIsTurnOnSetting(false);
      }
    },
  });

  useQuery([QUERY_KEY.DATE_HISTORY_LEVEL_M, levelId], () => getAllDateLandUseAnalytic(levelId!), {
    enabled: !!levelId && mode === MapEnum.MAP_VIEW,
    keepPreviousData: false,
    onSuccess(res) {
      setDateListLength(res.data.length);
    },
  });

  // handle logic to show temporal button in crop intelligent
  useEffect(() => {
    if (taskList.length && mode === MapEnum.CROP_INTELLIGENT) {
      setDateListLength(taskList.length);
    }
  }, [mode, taskList.length]);

  const isShowFormulaButton = () => {
    return (isSuperAdmin || isSuperUser || isTurnOnSetting) && dateListLength > 1;
  };

  return (
    <>
      {isShowFormulaButton() && (
        <Tooltip title={t('trans.temporal_view')}>
          <Button color="neutral" variant="contained" size="medium" onClick={() => setIsShowTemporalModal(true)}>
            <CompareIcon />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default TemporalButton;
