import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import { BigNumber } from 'bignumber.js';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import ReactECharts from 'echarts-for-react';
import { get, isArray, isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import StandExtendChartModal from './StandExtendChartModal';

const styles = {
  userListTitle: {
    py: '10px',
    // color: '#3C4043',
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18.2px',
    textAlign: 'center',
  },
  clientUpdateTime: {
    // color: '#6E6B7B',
    textAlign: 'center',
    px: '16px',
  },
};

const StandCountTable = () => {
  const [isShowExtendChart, setIsShowExtendChart] = useState<boolean>(false);
  const { allLocationCropType } = useAppSelector(clientSelector);
  const theme = useTheme();
  const { t } = useTranslation();

  const listNameLevel = useMemo(() => {
    const options = [] as any;
    if (!allLocationCropType) return;
    allLocationCropType.forEach((item) => {
      const isDisplayNameChart = item.standCountAnalysisSummary.some((item: any) => item.value);
      isDisplayNameChart && options.push(item.name);
    });
    return options;
  }, [allLocationCropType]);

  const isShowExtendIcon = listNameLevel.length > 20;

  const listStandsPerHectar = useMemo(() => {
    const options = [] as any;
    if (!allLocationCropType) return;
    allLocationCropType.forEach((item) => {
      if (item.totalTree && item.area) {
        const standsPerHa = new BigNumber(item.totalTree / item.area).toFixed(0);
        options.push({ value: standsPerHa, itemStyle: { color: '#10B981' } });
      }
      return options;
    });
    return options;
  }, [allLocationCropType]);

  const option = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
      backgroundColor: theme.palette.mode === 'dark' ? '#24292e' : '#fff',
      textStyle: {
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    xAxis: {
      type: 'category',
      data: listNameLevel,
      axisLabel: {
        rotate: 90,
        hideOverlap: false,
        overflow: 'truncate',
        width: 60,
        fontSize: 10,
      },
    },

    yAxis: {
      nameLocation: 'middle',
      name: t('mapView.stands_per_hectare'),
      nameTextStyle: {
        verticalAlign: 'middle',
        fontSize: 13,
        fontWeight: '500',
        fontFamily: 'Barlow',
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      max: function (value: any) {
        return value.max + 40;
      },
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [7, 10],
      },
    },
    backgroundColor: theme.palette.mode === 'dark' ? '#24292e' : '#fff',
    series: [
      {
        data: listStandsPerHectar,
        type: 'bar',
        label: {
          show: !(listNameLevel.length > 20),
          position: 'top',
          color: '#10B981',
          fontSize: 8,
        },
        barWidth: listNameLevel.length > 10 ? Math.floor(300 / listNameLevel.length) + 'px' : '30px',
      },
    ],
  };

  const extendChartOption = {
    ...option,
    series: option.series.map((item: any) => ({
      ...item,
      barWidth: listNameLevel.length > 10 ? Math.floor(800 / listNameLevel.length) + 'px' : '40px',
      label: { ...item.label, show: true, fontSize: 10 },
    })),
  };

  const tableCropCoverage = useMemo(() => {
    if (allLocationCropType && allLocationCropType.length > 0) {
      let tilteArray: Array<string> = [];
      const dataRows = allLocationCropType.map((row, i) => {
        !isEmpty(row.standCountAnalysisSummary) &&
          tilteArray.push(...row.standCountAnalysisSummary.map((i: any) => i.title));
        return {
          name: row?.name,
          data: row?.standCountAnalysisSummary,
        };
      });
      const tilteUniqueArray = Array.from(new Set(tilteArray)).sort((a, b) => a.localeCompare(b));
      return dataRows.map((row, i) => (
        <TableRow key={i}>
          {row.data && (
            <>
              <TableCell
                component="td"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  textAlign: 'left',
                  minWidth: '60px',
                }}>
                {row.name}
              </TableCell>
              <>
                {tilteUniqueArray.map((i, index) => (
                  <TableCell
                    key={index}
                    component="td"
                    sx={{
                      ...styles.clientUpdateTime,
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    {get(
                      row.data.find((v: any) => v.title === i),
                      'value',
                      0
                    )}
                  </TableCell>
                ))}
              </>
            </>
          )}
        </TableRow>
      ));
    }
    return <></>;
  }, [allLocationCropType]);

  const optionCol = useMemo(() => {
    const optionsCell: Array<string> = [];
    isArray(allLocationCropType) &&
      allLocationCropType.forEach((item: any) => {
        item.standCountAnalysisSummary.forEach((jtem: any) => {
          optionsCell.push(jtem.title);
        });
      });

    return Array.from(new Set(optionsCell)).sort((a, b) => a.localeCompare(b));
  }, [allLocationCropType]);

  return (
    <>
      <TableContainer
        sx={{
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '5px',
          mt: '-1px',
          mb: '12px',
        }}>
        <Table>
          <TableHead>
            {
              <TableRow
                component="tr"
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                }}>
                <TableCell
                  component="th"
                  sx={{
                    ...styles.userListTitle,
                    color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                    textAlign: 'left',
                    minWidth: '60px',
                  }}>
                  {t('trans.level')}
                </TableCell>
                {isArray(optionCol) &&
                  optionCol.map((col: any, index: number) => (
                    <TableCell
                      key={index}
                      component="th"
                      sx={{
                        ...styles.userListTitle,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                        textAlign: 'left',
                        minWidth: '60px',
                      }}>
                      {t(convertTextToMultiLanguage(col, 'mapView'))}
                    </TableCell>
                  ))}
              </TableRow>
            }
          </TableHead>
          <TableBody
            sx={{
              pt: 0,
              flexDirection: 'column',
              minHeight: '510px',
              maxHeight: '510px',
              overflowY: 'scroll',
              '-ms-overflow-style': 'none' /* IE and Edge */,
              scrollbarWidth: 'none' /* Firefox */,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
            {tableCropCoverage}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '5px',
          mt: '-1px',
          mb: '12px',
        }}>
        {isShowExtendIcon && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', height: '0px' }}>
            <Box
              sx={{
                top: '20px',
                right: '35px',
                position: 'relative',
                zIndex: 100,
              }}>
              <FullscreenOutlinedIcon
                sx={{
                  cursor: 'pointer',
                  color: theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B',
                }}
                onClick={() => setIsShowExtendChart(true)}
              />
            </Box>
          </Box>
        )}
        <ReactECharts option={option} theme={theme.palette.mode === 'dark' ? 'dark' : 'light'} />
      </Box>
      <StandExtendChartModal
        isShow={isShowExtendChart}
        handleClose={() => setIsShowExtendChart(false)}
        extendChartOption={extendChartOption}
      />
    </>
  );
};

export default StandCountTable;
