export const ClientsIcon = ({ isSelected }: any) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25391 5.25C5.25391 3.15 6.90391 1.5 9.00391 1.5C11.1039 1.5 12.7539 3.15 12.7539 5.25C12.7539 7.35 11.1039 9 9.00391 9C6.90391 9 5.25391 7.35 5.25391 5.25ZM15.7539 14.25V15.75C15.7539 16.2 15.4539 16.5 15.0039 16.5C14.5539 16.5 14.2539 16.2 14.2539 15.75V14.25C14.2539 12.975 13.2789 12 12.0039 12H6.00391C4.72891 12 3.75391 12.975 3.75391 14.25V15.75C3.75391 16.2 3.45391 16.5 3.00391 16.5C2.55391 16.5 2.25391 16.2 2.25391 15.75V14.25C2.25391 12.15 3.90391 10.5 6.00391 10.5H12.0039C14.1039 10.5 15.7539 12.15 15.7539 14.25ZM9.00391 7.5C7.72891 7.5 6.75391 6.525 6.75391 5.25C6.75391 3.975 7.72891 3 9.00391 3C10.2789 3 11.2539 3.975 11.2539 5.25C11.2539 6.525 10.2789 7.5 9.00391 7.5Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
      <mask id="mask0_6086_6567" maskUnits="userSpaceOnUse" x="2" y="1" width="14" height="16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25391 5.25C5.25391 3.15 6.90391 1.5 9.00391 1.5C11.1039 1.5 12.7539 3.15 12.7539 5.25C12.7539 7.35 11.1039 9 9.00391 9C6.90391 9 5.25391 7.35 5.25391 5.25ZM15.7539 14.25V15.75C15.7539 16.2 15.4539 16.5 15.0039 16.5C14.5539 16.5 14.2539 16.2 14.2539 15.75V14.25C14.2539 12.975 13.2789 12 12.0039 12H6.00391C4.72891 12 3.75391 12.975 3.75391 14.25V15.75C3.75391 16.2 3.45391 16.5 3.00391 16.5C2.55391 16.5 2.25391 16.2 2.25391 15.75V14.25C2.25391 12.15 3.90391 10.5 6.00391 10.5H12.0039C14.1039 10.5 15.7539 12.15 15.7539 14.25ZM9.00391 7.5C7.72891 7.5 6.75391 6.525 6.75391 5.25C6.75391 3.975 7.72891 3 9.00391 3C10.2789 3 11.2539 3.975 11.2539 5.25C11.2539 6.525 10.2789 7.5 9.00391 7.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6086_6567)">
        <rect x="0.00390625" width="18" height="18" fill={isSelected ? '#23BE6A' : '#737373'} />
      </g>
    </svg>
  );
};
