import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { RoleType } from 'common/defines/clients';
import { useCallback, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updateRoleById } from 'services/clients/apiClient.services';
import { permissionsData } from '../../constants/constants';

interface IEditPermissionModal {
  open: boolean;
  data: RoleType;
  reloadData: any;
  onClose: () => void;
}

export const EditPermissionModal = ({ open, data, reloadData, onClose }: IEditPermissionModal) => {
  const [permissionRole, setPermissionRole] = useState<string[]>([]);
  const theme = useTheme();

  const styles = {
    editModalTitleText: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontSize: '16px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      lineHeight: '16px',
      fontWeight: '600',
    },
    editRow: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  };

  useEffect(() => {
    setPermissionRole(data.permissions);
  }, [data]);

  const handleOnSelect = useCallback(
    (text: string, isSelected: boolean) => {
      if (!permissionRole) return;
      if (!isSelected) {
        return setPermissionRole((prev) => {
          return prev?.filter((val) => val !== text) || [];
        });
      }
      permissionRole.push(text);
      const permissionUnique: string[] = Array.from(new Set(permissionRole));
      return setPermissionRole(permissionUnique);
    },
    [permissionRole]
  );

  const handleUpdateRoleById = useMutation((data: RoleType) => updateRoleById(data), {
    onSuccess: () => {
      toast.success('Update Role Success', { toastId: 1 });
      onClose();
      reloadData();
    },
  });

  const handleOnSave = () => {
    handleUpdateRoleById.mutate({
      name: data.name,
      isActive: data.isActive,
      isSubAdmin: data.isSubAdmin,
      id: data._id,
      permissions: permissionRole,
    });
  };

  const handleSelectAll = (text: string) => {
    const selectedPermission = permissionsData.find((data) => data.name === text);
    selectedPermission?.items.forEach((data) => {
      permissionRole.push(data.key);
      const permissionUnique: string[] = Array.from(new Set(permissionRole));
      return setPermissionRole(permissionUnique);
    });
  };

  const handleUnselectAll = (text: string) => {
    const selectedPermission = permissionsData.find((data) => data.name === text);
    const unselectedPermission = permissionRole.filter(
      (val) => !selectedPermission?.items.some((data) => data.key === val)
    );
    setPermissionRole(unselectedPermission);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          width: '960px',
          maxWidth: '960px',
        },
      }}>
      <DialogTitle
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
        }}>
        <Typography
          sx={{
            fontFamily: 'Barlow',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#5E5873'),
          }}>
          Edit Permission - {data.name}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            p: 0,
            position: 'absolute',
            right: 8,
            top: 8,
            '.MuiSvgIcon-root': {
              width: '16px',
              height: '16px',
            },
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={{ border: '0.5px solid #EDF1F1' }} />
      <Scrollbars style={{ height: 'calc(100vh - 420px)' }} autoHide>
        <DialogContent sx={{ px: 3.75, py: 1.25, bgcolor: (theme) => theme.palette.background.paper }}>
          {permissionsData
            .filter((item) => !item.key.includes('UTILITY-SETTINGS'))
            .map((data, index) => {
              return (
                <Stack
                  spacing={1}
                  key={index}
                  sx={{ my: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: '8px', padding: '16px' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '40px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ ...styles.editModalTitleText }}>{data.label}</Typography>
                    </Box>
                    <Stack direction="row" spacing={2}>
                      <Button variant="contained" onClick={() => handleSelectAll(data.name)}>
                        Select All
                      </Button>
                      <Button variant="outlined" onClick={() => handleUnselectAll(data.name)}>
                        Unselect All
                      </Button>
                    </Stack>
                  </Box>
                  <Box sx={{ border: `1px solid ${theme.palette.divider}` }} />
                  <Grid container rowSpacing={1}>
                    {data.items.map((dt) => {
                      return (
                        <Grid item xs={3} key={dt.key}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permissionRole.includes(`${dt.key}`)}
                                onChange={(event) => handleOnSelect(`${dt.key}`, event.target.checked)}
                              />
                            }
                            label={dt.name}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>
              );
            })}
        </DialogContent>
      </Scrollbars>
      <Box sx={{ border: '0.5px solid #EDF1F1' }} />
      <DialogActions
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            m: '10px',
          }}>
          <Button
            sx={{
              display: 'block',
              width: '88px',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              color: '#232323',
              marginRight: '16px',
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#9E9E9E',
                border: `1px solid ${theme.palette.divider}`,
                boxShadow: 'none',
              },
              '&:active': {
                boxShadow: 'none',
                backgroundColor: '#9E9E9E',
                border: `1px solid ${theme.palette.divider}`,
              },
              '&:focus': {
                boxShadow: '0 0 0 0.1rem #9E9E9E',
                border: `1px solid ${theme.palette.divider}`,
              },
            }}
            variant="contained"
            onClick={onClose}>
            Cancel
          </Button>
          <Button sx={{ display: 'block', width: '88px' }} variant="contained" onClick={() => handleOnSave()}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
