import { makeStyles } from '@mui/styles';

export const useCropTypeSettingDialogStyles = makeStyles((theme: any) => {
  return {
    dialogContainer: {
      '& .MuiDialog-container': {
        '& MuiPaper-root': {
          overflowY: 'scroll',
          '-ms-overflow-style': 'none' /* IE and Edge */,
          scrollbarWidth: 'none' /* Firefox */,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      },
    },
    dialogTitle: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      fontSize: '20px !important',
      fontWeight: '600 !important',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },

    // Dialog Content
    dialogContent: {
      padding: '1rem 2rem',
    },

    // Form
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    formBlock: {
      padding: '0',
    },
    formBlockPickup: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
    },
    formBlockItem: {
      marginBottom: '1rem',
    },
    formBlockItemAva: {
      flex: 1,
    },
    formBlockItemColor: {
      marginBottom: '1rem',
      flex: 1,
      position: 'relative',
    },
    pickupColorBox: {
      height: '52px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    pickupColorBtn: {
      height: '37.13px',
      width: '50px',
      borderRadius: '5px',
      border: '2px solid white',
      marginLeft: '8px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
      },
    },
    pickupColorValue: {
      fontSize: '14px',
      fontWeight: '500',
      width: '73px',
      height: '9.13px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      padding: '14px 8.5px',
      justifyContent: 'center',
    },
    pickupColorValueContainer: {
      position: 'absolute',
      height: '200px',
      width: '200px',
      zIndex: 10,
      borderRadius: '10px',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
      marginTop: '10px',
    },
    editColor: {
      position: 'absolute',
      top: '-55px',
      left: '1px',
      width: '90px !important',
    },
    viewDataContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    dialogActions: {
      display: 'flex',
      alignItems: 'center !important',
      justifyContent: 'center !important',
    },
    submitBtnForm: {
      background: theme.palette.primary.main,
      width: '200px !important',
      height: '40px',
      align: 'right',
      color: 'white',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#737373',
      },
    },

    sampleContainer: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '248px',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      transition: 'all 0.3s ease-in-out',
    },

    // svgIcon
    dataViewSample: {
      height: '160px',
      width: '240px',
    },

    // Loading Screen
    loadingScreen: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '700px',
    },
  };
});
