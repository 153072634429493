export const InventoryIcon = ({ isSelected }: any) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill={isSelected ? '#23BE6A' : '#737373'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00391 0.75H15.0039C16.2789 0.75 17.2539 1.725 17.2539 3V6C17.2539 7.275 16.2789 8.25 15.0039 8.25H3.00391C1.72891 8.25 0.753906 7.275 0.753906 6V3C0.753906 1.725 1.72891 0.75 3.00391 0.75ZM15.0039 6.75C15.4539 6.75 15.7539 6.45 15.7539 6V3C15.7539 2.55 15.4539 2.25 15.0039 2.25H3.00391C2.55391 2.25 2.25391 2.55 2.25391 3V6C2.25391 6.45 2.55391 6.75 3.00391 6.75H15.0039ZM15.0039 9.75H3.00391C1.72891 9.75 0.753906 10.725 0.753906 12V15C0.753906 16.275 1.72891 17.25 3.00391 17.25H15.0039C16.2789 17.25 17.2539 16.275 17.2539 15V12C17.2539 10.725 16.2789 9.75 15.0039 9.75ZM15.0039 15.75C15.4539 15.75 15.7539 15.45 15.7539 15V12C15.7539 11.55 15.4539 11.25 15.0039 11.25H3.00391C2.55391 11.25 2.25391 11.55 2.25391 12V15C2.25391 15.45 2.55391 15.75 3.00391 15.75H15.0039ZM5.02891 3.975C5.17891 4.125 5.25391 4.275 5.25391 4.5C5.25391 4.725 5.17891 4.875 5.02891 5.025C4.87891 5.175 4.72891 5.25 4.50391 5.25C4.27891 5.25 4.12891 5.175 3.97891 5.025C3.90391 4.95 3.82891 4.875 3.82891 4.8C3.75391 4.725 3.75391 4.575 3.75391 4.5C3.75391 4.425 3.75391 4.275 3.82891 4.2C3.86641 4.1625 3.88516 4.125 3.90391 4.0875C3.92266 4.05 3.94141 4.0125 3.97891 3.975C4.05391 3.9 4.12891 3.825 4.20391 3.825C4.42891 3.75 4.57891 3.75 4.80391 3.825C4.87891 3.825 4.95391 3.9 5.02891 3.975ZM5.25391 13.5C5.25391 13.275 5.17891 13.125 5.02891 12.975C4.72891 12.675 4.27891 12.675 3.97891 12.975C3.94141 13.0125 3.92266 13.05 3.90391 13.0875C3.88516 13.125 3.86641 13.1625 3.82891 13.2C3.75391 13.275 3.75391 13.425 3.75391 13.5C3.75391 13.725 3.82891 13.875 3.97891 14.025C4.12891 14.175 4.27891 14.25 4.50391 14.25C4.72891 14.25 4.87891 14.175 5.02891 14.025C5.17891 13.875 5.25391 13.725 5.25391 13.5Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
      <mask id="mask0_5830_11223" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.00391 0.75H15.0039C16.2789 0.75 17.2539 1.725 17.2539 3V6C17.2539 7.275 16.2789 8.25 15.0039 8.25H3.00391C1.72891 8.25 0.753906 7.275 0.753906 6V3C0.753906 1.725 1.72891 0.75 3.00391 0.75ZM15.0039 6.75C15.4539 6.75 15.7539 6.45 15.7539 6V3C15.7539 2.55 15.4539 2.25 15.0039 2.25H3.00391C2.55391 2.25 2.25391 2.55 2.25391 3V6C2.25391 6.45 2.55391 6.75 3.00391 6.75H15.0039ZM15.0039 9.75H3.00391C1.72891 9.75 0.753906 10.725 0.753906 12V15C0.753906 16.275 1.72891 17.25 3.00391 17.25H15.0039C16.2789 17.25 17.2539 16.275 17.2539 15V12C17.2539 10.725 16.2789 9.75 15.0039 9.75ZM15.0039 15.75C15.4539 15.75 15.7539 15.45 15.7539 15V12C15.7539 11.55 15.4539 11.25 15.0039 11.25H3.00391C2.55391 11.25 2.25391 11.55 2.25391 12V15C2.25391 15.45 2.55391 15.75 3.00391 15.75H15.0039ZM5.02891 3.975C5.17891 4.125 5.25391 4.275 5.25391 4.5C5.25391 4.725 5.17891 4.875 5.02891 5.025C4.87891 5.175 4.72891 5.25 4.50391 5.25C4.27891 5.25 4.12891 5.175 3.97891 5.025C3.90391 4.95 3.82891 4.875 3.82891 4.8C3.75391 4.725 3.75391 4.575 3.75391 4.5C3.75391 4.425 3.75391 4.275 3.82891 4.2C3.86641 4.1625 3.88516 4.125 3.90391 4.0875C3.92266 4.05 3.94141 4.0125 3.97891 3.975C4.05391 3.9 4.12891 3.825 4.20391 3.825C4.42891 3.75 4.57891 3.75 4.80391 3.825C4.87891 3.825 4.95391 3.9 5.02891 3.975ZM5.25391 13.5C5.25391 13.275 5.17891 13.125 5.02891 12.975C4.72891 12.675 4.27891 12.675 3.97891 12.975C3.94141 13.0125 3.92266 13.05 3.90391 13.0875C3.88516 13.125 3.86641 13.1625 3.82891 13.2C3.75391 13.275 3.75391 13.425 3.75391 13.5C3.75391 13.725 3.82891 13.875 3.97891 14.025C4.12891 14.175 4.27891 14.25 4.50391 14.25C4.72891 14.25 4.87891 14.175 5.02891 14.025C5.17891 13.875 5.25391 13.725 5.25391 13.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5830_11223)">
        <rect x="0.00390625" width="18" height="18" fill={isSelected ? '#23BE6A' : '#737373'} />
      </g>
    </svg>
  );
};
