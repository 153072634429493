import { Box, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { circle, point } from '@turf/turf';
import useClientDataConfig from 'hooks/useClientDataConfig';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Layer, Marker, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { changeFixedSizePoint, changeZoomLayerPlantHealthOilPailm, plantHealthOilPailmAnalyticsSelector } from 'store/slices/map-view/plantHealthOilPailmAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';

import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

const styles = {
  tooltip: {
    borderRadius: '18px',
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#FFF',
      padding: 0,
      margin: 0,
    },
  },
};
const CustomTooltip = withStyles(styles)(Tooltip);

export const usePlantHealthOilPailmAnalytics = ({ mapRef }: any) => {
  const { isLayer3D, isDisplayBoundaryLine, isDisplay3DCylinder } = useAppSelector(mapViewSelector);
  const { dataPlantHealthOilPailmInterval, dataGeoJsonPoint, dataGeoJsonPolygon } = useAppSelector(plantHealthOilPailmAnalyticsSelector);

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();
  const dispatch = useDispatch()
  const { isFixedPoint, value } = useClientDataConfig()
  const { analyticId } = useAppSelector(mapViewSelector);

  const { contourSetting } = useClientData();

  const zoomLevel = useMemo(() => {
    if (!mapRef?.current) return 0;
    return mapRef?.current?.getZoom();
  }, [mapRef?.current?.getZoom()]);

  useEffect(() => {
    if (!mapRef?.current) return;

    if (isFixedPoint && value) {
      dispatch(changeFixedSizePoint(value))
    } else
      dispatch(changeZoomLayerPlantHealthOilPailm(zoomLevel))

  }, [dispatch, isFixedPoint, mapRef, value, zoomLevel, analyticId])

  const categoryByColor = useMemo(() => {
    let options = [] as any;
    dataPlantHealthOilPailmInterval?.range?.forEach((item: any) => {
      options.push({
        label: item.lable,
        color: item.color,
        visible: item.visible,
      });
    });
    return options;
  }, [dataPlantHealthOilPailmInterval]);


  const layerPlantHealthOilPailm = useMemo(() => {
    if (!dataGeoJsonPoint && !dataGeoJsonPolygon) {
      return null;
    }

    return (
      <>
        {dataGeoJsonPoint &&
          <Source id={`plantHealthOilPailm-polygon-point`} type="geojson" data={dataGeoJsonPoint}>
            {/* 2D Point */}
            <Layer
              id={`fill-top-surface-point`}
              type="fill"

              paint={{
                'fill-color': ['get', 'color'],
              }}
              layout={{ visibility: !isLayer3D && !isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Cylinder */}
            <Layer
              id={`plantHealthOilPailm-top-surface-point`}
              type="fill-extrusion"

              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`plantHealthOilPailm-side-surface-point`}
              type="fill-extrusion"

              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                // getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
          </Source>}
        {dataGeoJsonPolygon &&
          <Source id={`plantHealthOilPailm-source`} type="geojson" data={dataGeoJsonPolygon}>
            {/* 2D Contour */}
            <Layer
              id={`plantHealthOilPailm_polygon`}
              type="line"
              paint={{
                'line-color': ['get', 'color'],
                'line-width': 2,
                'line-dasharray': [2, 2],
              }}

              layout={{ visibility: !isLayer3D && isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Filled contour */}
            <Layer
              id={`plantHealthOilPailm_fill-polygon`}
              type="fill"
              paint={{
                'fill-color': ['get', 'color'],
                'fill-opacity': 0,
              }}

              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`plantHealthOilPailm_top-surface`}
              type="fill-extrusion"
              beforeId={`plantHealthOilPailm_fill-polygon`}

              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`plantHealthOilPailm_side-surface`}
              type="fill-extrusion"
              beforeId={`plantHealthOilPailm_top-surface`}

              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />

          </Source>
        }

        {/* {!isLayer3D &&
          zoomLevel > 19 &&
          dataPlantStress &&
          dataPlantStress.map((item: any) => {
            return (
              <Marker
                key={`${item._id}-plant-stress-radius-marker`}
                longitude={item.longX}
                latitude={item.latY}
                anchor={'center'}
                style={{ zIndex: 10 }}
                offset={[0, -5]}>
                <Typography
                  component="span"
                  style={{
                    width: 'fit-content',
                    height: '24px',
                    display: 'flex',
                    padding: '0 10px',
                    color: 'white',
                    cursor: 'pointer',
                  }}>
                  {item.properties?.stressIn ? `${item.properties.stressIn.toFixed(2)}m` : ''}
                </Typography>
              </Marker>
            );
          })}
        {!isLayer3D &&
          zoomLevel > 20 &&
          dataPlantStress &&
          dataPlantStress.map((item: any) => {
            return (
              <Marker
                key={`${item._id}-plant-stress-name-marker`}
                longitude={item.longX}
                latitude={item.latY}
                anchor={'center'}
                style={{ zIndex: 10 }}
                offset={[0, -50]}>
                <Box
                  sx={{
                    backgroundColor: '#bdbeb8',
                    borderRadius: '10px',
                    borderWidth: 2,
                    borderColor: 'white',
                    borderStyle: 'solid',
                  }}>
                  <Typography
                    component="span"
                    style={{
                      height: '24px',
                      display: 'flex',
                      padding: '10px',
                      color: 'black',
                      cursor: 'pointer',
                    }}>
                    {item.treeId}
                  </Typography>
                </Box>
              </Marker>
            );
          })}
        {!isLayer3D &&
          !isDisplayBoundaryLine &&
          plantStressPointJSON?.features.map((item: any) => {
            const pointInfo = categoryByColor.find((category: any) => {
              return category?.color === item?.properties?.color;
            });
            return pointInfo?.visible ? (
              <Marker
                key={`${item.properties._id}-stress-radius-marker`}
                longitude={item.properties.longX}
                latitude={item.properties.latY}
                anchor={'center'}
                style={{ zIndex: 10 }}
                offset={[0, -5]}>
                <TooltipAnalytics item={item} pointInfo={pointInfo} isStress />
              </Marker>
            ) : null;
          })} */}
      </>
    );
  }, [
    colorTransparent, contourSetting, dataGeoJsonPoint, dataGeoJsonPolygon, getOpacityExtrusion, getTopSurfaceOpacity, isDisplay3DCylinder, isDisplayBoundaryLine, isLayer3D, isSameColorTopSurface, opacity2D,
  ]);
  return { layerPlantHealthOilPailm };
};
