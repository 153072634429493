import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ISettingCropType } from 'common/defines/clients';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import AvatarUser from 'components/Common/AvatarUser';
import DropFileCustom from 'components/Common/DropFile';
import SkeletonCommon from 'components/Common/SkeletonCommon';
import configs from 'constants/config';
import { QUERY_KEY, STATUS_API } from 'constants/constants';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createCropTypeSettings,
  getSettingsDataCroptypeById,
  updateCropTypeSettings,
} from 'services/settings/settings.services';
import { useCropTypeSettingDialogStyles } from './CropTypeDialogSettingStyle';

const CardCustom = styled(Card)((theme) => ({
  boxShadow: 'none',
  '.MuiCardContent-root': {
    padding: '8px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
    border: 'rgba(99, 99, 99, 0.2)',
    margin: '16px 0',
  },
}));

const UserAnalysisDialogCropType = (props: any) => {
  const queryClient = useQueryClient();
  const { searchSettings, onClose, page, rowsPerPage, settingIndex, editData, typAnalysisOptons } = props;
  const [avaShow, setAvaShow] = useState('');
  const [openPickColor, setOpenPickColor] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentEdit, setDocumentEdit] = useState<any>(undefined);
  const theme = useTheme();
  const token = localStorage.getItem('token');

  const { control, handleSubmit, reset, setValue, getValues } = useForm<ISettingCropType>({
    defaultValues: {
      icon: '',
      pdfDocument: '',
      name: '',
      color: theme.palette.primary.main,
      analyticsDisplay: [],
      analysisConfig: [],
      isAutoCreateAnalysisRGB: [],
      isAutoCreateAnalysisRgbMs: [],
      rgbList: [],
      rgbMsList: [],
    },
  });

  // Call API
  const { data, isLoading, remove, status } = useQuery([QUERY_KEY.USER_SETTINGS_CROPTYPE_BY_ID, settingIndex], () =>
    getSettingsDataCroptypeById(settingIndex)
  );

  const dataSettingsAnalyticsValue = useMemo(() => {
    return data?.data;
  }, [data]);

  useEffect(() => {
    if (settingIndex) {
      if (status !== STATUS_API.SUCCESS) return;
      const arrayRgb = [] as any[];
      const arrayRgbList = [] as any[];
      const arrayRgbCheck = [] as any[];
      const arrayRgbMsCheck = [] as any[];

      dataSettingsAnalyticsValue?.analysisConfig.forEach((item: any) => {
        if (item.rasterType === 'RGB') {
          arrayRgb.push(item.name);
          if (item.isAutoCreateAnalysis) arrayRgbCheck.push(item.name);
        } else if (item.rasterType === 'RGB Multispectral') {
          arrayRgbList.push(item.name);
          if (item.isAutoCreateAnalysis) arrayRgbMsCheck.push(item.name);
        }
      });

      setAvaShow(dataSettingsAnalyticsValue.icon);
      // setValue('isAutoCreateAnalysis', dataSettingsAnalyticsValue?.isAutoCreateAnalysis);
      setValue('icon', dataSettingsAnalyticsValue?.icon);
      setValue('name', dataSettingsAnalyticsValue?.name);
      setValue('color', dataSettingsAnalyticsValue?.color);
      setValue('analyticsDisplay', dataSettingsAnalyticsValue?.analyticsDisplay);
      setValue('analysisConfig', dataSettingsAnalyticsValue?.analysisConfig);
      setValue('rgbList', arrayRgb);
      setValue('rgbMsList', arrayRgbList);
      setValue('isAutoCreateAnalysisRGB', arrayRgbCheck);
      setValue('isAutoCreateAnalysisRgbMs', arrayRgbMsCheck);
      setValue('pdfDocument', dataSettingsAnalyticsValue?.pdfDocument);
      const nameFileRegex = dataSettingsAnalyticsValue?.pdfDocument?.replace(/^.*[\\\/-]/, '');
      if (dataSettingsAnalyticsValue?.pdfDocument) {
        let fileConvertFromUrl = new File([dataSettingsAnalyticsValue.pdfDocument], decodeURI(nameFileRegex) || '');
        if (fileConvertFromUrl) {
          setDocumentEdit(fileConvertFromUrl);
          return;
        }
      }
      setDocumentEdit(null);
    } else {
      reset({
        icon: '',
        name: '',
        color: theme.palette.primary.main,
        // isAutoCreateAnalysis: false,
        analyticsDisplay: [],
        analysisConfig: [],
        isAutoCreateAnalysisRGB: [],
        isAutoCreateAnalysisRgbMs: [],
        rgbList: [],
        rgbMsList: [],
        pdfDocument: '',
      });
    }
  }, [dataSettingsAnalyticsValue, reset, setValue, settingIndex, status]);

  const handleChangeField = (event: ChangeEvent<HTMLInputElement>, fieldName: keyof ISettingCropType) => {
    const trimInputValue = event.target.value.replace(/\s+/g, ' ');
    setValue(fieldName, trimInputValue);
  };

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const file = event.target.files;
    if (!file) return;
    const newFile = file[0];
    if (newFile.type === 'image/svg+xml') (newFile as any).preview = URL.createObjectURL(newFile);
    else toast.error('Please upload svg file!', { toastId: 1 });
    const formData = new FormData();
    formData.append('file', newFile);

    setAvaShow((newFile as any).preview);
    fetch(`${configs.API_DOMAIN}/media/uploadGeneral`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        const isSvg = result.path.includes('.svg');
        value = isSvg ? result.path : null;

        setValue('icon', value);
        return value;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!editData) return;
    reset({
      icon: editData.icon,
      name: editData.name,
      color: editData.color,
      // isAutoCreateAnalysis: editData.isAutoCreateAnalysis,
      analyticsDisplay: editData.analyticsDisplay,
    });
  }, [editData, reset]);

  const handleClose = () => {
    setAvaShow('');
    reset({
      icon: '',
      name: '',
      color: theme.palette.primary.main,
      // isAutoCreateAnalysis: false,
      analyticsDisplay: [],
      isAutoCreateAnalysisRGB: [],
      isAutoCreateAnalysisRgbMs: [],
      rgbList: [],
      rgbMsList: [],
      analysisConfig: [],
      pdfDocument: '',
    });
    setDocument(null);
    setAvaShow('');
    setDocumentEdit(null);

    onClose();
  };

  useEffect(() => {
    return () => {
      reset({
        icon: '',
        name: '',
        color: theme.palette.primary.main,
        // isAutoCreateAnalysis: false,
        analyticsDisplay: [],
        isAutoCreateAnalysisRGB: [],
        isAutoCreateAnalysisRgbMs: [],
        rgbList: [],
        rgbMsList: [],
        analysisConfig: [],
        pdfDocument: '',
      });

      setDocumentEdit(undefined);
      remove();
    };
  }, [remove, reset]);

  // Call API Post
  const useCreateCropTypeSettings = () => {
    return useMutation(
      (param: ISettingCropType) =>
        createCropTypeSettings({
          ...param,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEY.USER_SETTINGS_CROPTYPE, page, rowsPerPage, searchSettings]);
          toast.success('Create setting successfully', { toastId: 1 });
          setAvaShow('');
          reset({
            icon: '',
            name: '',
            color: theme.palette.primary.main,
            // isAutoCreateAnalysis: false,
            analyticsDisplay: [],
            isAutoCreateAnalysisRGB: [],
            isAutoCreateAnalysisRgbMs: [],
            rgbList: [],
            rgbMsList: [],
            analysisConfig: [],
          });
          onClose();
        },
        onError: (result: any) => {
          if (result.status === 400) {
            toast.error(result.data.message[0], { toastId: 1 });
          }
        },
      }
    );
  };
  // Call API Patch
  const useUpdateCropTypeSettings = () => {
    return useMutation(
      (param: ISettingCropType) =>
        updateCropTypeSettings({
          ...param,
          id: settingIndex,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEY.USER_SETTINGS_CROPTYPE, page, rowsPerPage, searchSettings]);
          toast.success('Update setting successfully', { toastId: 1 });
          setAvaShow('');
          reset({
            icon: '',
            name: '',
            color: theme.palette.primary.main,
            analyticsDisplay: [],
            isAutoCreateAnalysisRGB: [],
            isAutoCreateAnalysisRgbMs: [],
            rgbList: [],
            rgbMsList: [],
            analysisConfig: [],
          });
          onClose();
        },
        onError: (result: any) => {
          if (result.status === 400) {
            toast.error(result.data.message[0], { toastId: 1 });
          }
        },
      }
    );
  };

  const mutationUpdateCropTypeSettings = useUpdateCropTypeSettings();
  const mutationCreateCropTypeSettings = useCreateCropTypeSettings();

  // Submit Form
  const handleSubmitForm: SubmitHandler<ISettingCropType> = (data: any) => {
    const array = [] as any;

    data.rgbList.map((item: any) => {
      array.push({
        name: item,
        rasterType: 'RGB',
        isAutoCreateAnalysis: data.isAutoCreateAnalysisRGB.some((i: any) => i === item) ? true : false,
      });
    });

    data.rgbMsList?.map((item: any) => {
      array.push({
        name: item,
        rasterType: 'RGB Multispectral',
        isAutoCreateAnalysis: data.isAutoCreateAnalysisRgbMs.some((i: any) => i === item) ? true : false,
      });
    });

    const { icon, name, color, analyticsDisplay, pdfDocument } = data;

    const dataBody = {
      icon,
      name,
      color,
      analyticsDisplay,
      analysisConfig: array,
      pdfDocument,
    };

    if (settingIndex) {
      // Update
      mutationUpdateCropTypeSettings.mutate({
        ...dataBody,
      });
    } else {
      // Create
      mutationCreateCropTypeSettings.mutate(dataBody);
    }
  };

  const classes = useCropTypeSettingDialogStyles();

  const handleClickColorPicker = () => {
    setOpenPickColor(!openPickColor);
  };

  const handleSelect = useCallback(
    (event: any, name: any) => {
      setValue(name, event.target.value);
    },
    [setValue]
  );

  const handleChangeDocument = (event: any) => {
    if (!event) {
      setValue('pdfDocument', '');
    }
    setDocumentEdit(event);
    setDocument(event);
    if (event) {
      const formData = new FormData();
      formData.append('file', event);

      // setAvaShow((newFile as any).preview);
      fetch(`${configs.API_DOMAIN}/media/uploadGeneral`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          result && setValue('pdfDocument', result.path);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={true} onClose={handleClose} className={classes.dialogContainer}>
      {settingIndex ? (
        <>
          {isLoading ? (
            <>
              <DialogTitle
                classes={{
                  root: classes.dialogTitle,
                }}
                sx={{
                  background: theme.palette.background.paper,
                }}>
                Update Type
              </DialogTitle>
              <Box
                className={classes.loadingScreen}
                sx={{
                  background: theme.palette.background.paper,
                }}>
                <CircularProgress />
              </Box>
            </>
          ) : (
            <Scrollbars autoHide style={{ height: '750px' }}>
              <DialogTitle
                className={classes.dialogTitle}
                sx={{
                  background: theme.palette.background.paper,
                }}>
                Update Type
              </DialogTitle>
              {/* Container */}
              <Box
                className={classes.dialogContent}
                sx={{ paddingBottom: '16px', background: theme.palette.background.paper }}>
                {/* Form */}
                <Typography
                  component="form"
                  className={classes.formContainer}
                  onSubmit={handleSubmit(handleSubmitForm)}>
                  <DialogContent className={classes.formBlock}>
                    <Box className={classes.formBlockPickup}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Controller
                            name="icon"
                            control={control}
                            render={({ field }) => (
                              <Box
                                className={classes.formBlockItem}
                                sx={{
                                  flex: 1,
                                }}>
                                <InputLabel htmlFor="cropTypeIcon">Type Icon</InputLabel>
                                <AvatarUser
                                  avatar={avaShow}
                                  handleChangeFile={(e) => handleChangeFile(e)}
                                  heightAvatar="50px"
                                  widthAvatar="50px"
                                  heightBtnUpload="30px"
                                  btnLabel="Upload Icon"
                                  backgroundColor={getValues('color')}
                                />
                              </Box>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="color"
                            control={control}
                            render={({ field }) => (
                              <Box
                                className={classes.formBlockItem}
                                sx={{
                                  flex: 1,
                                  position: 'relative',
                                }}>
                                <InputLabel htmlFor="cropTypeIcon">Type Color</InputLabel>
                                <Box onClick={() => handleClickColorPicker()} className={classes.pickupColorBox}>
                                  <Typography
                                    className={classes.pickupColorValue}
                                    sx={{
                                      color: getValues('color'),
                                      border: `1px solid ${getValues('color')}`,
                                    }}>
                                    {getValues('color') === theme.palette.primary.main ? 'Default' : getValues('color')}
                                  </Typography>
                                  <Tooltip
                                    title={openPickColor ? 'Close pick color' : 'Open pick color'}
                                    placement="right"
                                    arrow>
                                    <Box
                                      className={classes.pickupColorBtn}
                                      sx={{
                                        backgroundColor: getValues('color'),
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                                {
                                  // <ColorPicker
                                  openPickColor ? (
                                    <Box className={classes.pickupColorValueContainer}>
                                      <TextField
                                        className={classes.editColor}
                                        id="cropTypeColor"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        {...field}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'color')}
                                      />
                                      <HexColorPickerDebounce
                                        {...field}
                                        style={{
                                          position: 'absolute',
                                          top: '0px',
                                          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                                          borderRadius: '10px',
                                        }}
                                        color={getValues('color')}
                                        onChange={(color: any) => {
                                          setValue('color', color);
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    <></>
                                  )
                                }
                              </Box>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Box className={classes.formBlockItem}>
                          <InputLabel htmlFor="cropTypeName">Type Name</InputLabel>
                          <TextField
                            id="cropTypeName"
                            type="text"
                            size="small"
                            fullWidth
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'name')}
                          />
                        </Box>
                      )}
                    />
                    <Box className="rgb-card">
                      <CardCustom>
                        <Box
                          sx={{
                            pb: '12px',
                            background: theme.palette.background.paper,
                          }}>
                          <InputLabel sx={{ color: theme.palette.primary.main }}>RGB</InputLabel>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Controller
                                name="rgbList"
                                control={control}
                                render={({ field }) => (
                                  <Box
                                    sx={{
                                      mb: '10px',
                                    }}
                                    key="clientId">
                                    <InputLabel>Analysis Type </InputLabel>
                                    <Select
                                      sx={{
                                        height: '46px',
                                        padding: '0px 14px',
                                        width: '100%',
                                        maxWidth: '680.61px',
                                      }}
                                      fullWidth
                                      multiple
                                      {...field}
                                      onChange={(event) => handleSelect(event, 'rgbList')}
                                      // renderValue={(selected) => handleRenderRasterList(selected)}>
                                    >
                                      {typAnalysisOptons ? (
                                        typAnalysisOptons.map((data: any) => (
                                          <MenuItem key={data} value={data.value}>
                                            {data.label}
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem>No Raster Found</MenuItem>
                                      )}
                                    </Select>
                                    {/* {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>} */}
                                  </Box>
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Controller
                                name="isAutoCreateAnalysisRGB"
                                control={control}
                                render={({ field }) => (
                                  <Box
                                    sx={{
                                      mb: '10px',
                                    }}
                                    key="clientId">
                                    <InputLabel>Auto Created Analysis</InputLabel>
                                    <Select
                                      sx={{
                                        height: '46px',
                                        padding: '0px 14px',
                                        width: '100%',
                                        maxWidth: '680.61px',
                                      }}
                                      fullWidth
                                      multiple
                                      {...field}
                                      onChange={(event) => handleSelect(event, 'isAutoCreateAnalysisRGB')}
                                      // renderValue={(selected) => handleRenderRasterList(selected)}>
                                    >
                                      {typAnalysisOptons ? (
                                        typAnalysisOptons.map((data: any) => (
                                          <MenuItem key={data} value={data.value}>
                                            {data.label}
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem>No Raster Found</MenuItem>
                                      )}
                                    </Select>
                                    {/* {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>} */}
                                  </Box>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardCustom>
                      <CardCustom>
                        <Box
                          sx={{
                            pb: '12px',
                            background: theme.palette.background.paper,
                          }}>
                          <InputLabel sx={{ color: theme.palette.primary.main }}>RGB MS</InputLabel>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Controller
                                name="rgbMsList"
                                control={control}
                                render={({ field }) => (
                                  <Box
                                    sx={{
                                      mb: '10px',
                                    }}
                                    key="clientId">
                                    <InputLabel>Analysis Type </InputLabel>
                                    <Select
                                      sx={{
                                        height: '46px',
                                        padding: '0px 14px',
                                        width: '100%',
                                        maxWidth: '680.61px',
                                      }}
                                      fullWidth
                                      multiple
                                      {...field}
                                      onChange={(event) => handleSelect(event, 'rgbMsList')}>
                                      {typAnalysisOptons ? (
                                        typAnalysisOptons.map((data: any) => {
                                          const { label, id, value } = data;
                                          return <MenuItem value={value}>{label}</MenuItem>;
                                        })
                                      ) : (
                                        <MenuItem>No Raster Found</MenuItem>
                                      )}
                                    </Select>
                                  </Box>
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Controller
                                name="isAutoCreateAnalysisRgbMs"
                                control={control}
                                render={({ field }) => (
                                  <Box
                                    sx={{
                                      mb: '10px',
                                    }}
                                    key="clientId">
                                    <InputLabel>Auto Create Analysis </InputLabel>
                                    <Select
                                      sx={{
                                        height: '46px',
                                        padding: '0px 14px',
                                        width: '100%',
                                        maxWidth: '680.61px',
                                      }}
                                      fullWidth
                                      multiple
                                      {...field}
                                      onChange={(event) => handleSelect(event, 'isAutoCreateAnalysisRgbMs')}
                                      // renderValue={(selected) => handleRenderRasterList(selected)}>
                                    >
                                      {typAnalysisOptons ? (
                                        typAnalysisOptons.map((data: any) => {
                                          const { label, id, value } = data;
                                          return (
                                            <MenuItem key={id} value={value}>
                                              {label}
                                            </MenuItem>
                                          );
                                        })
                                      ) : (
                                        <MenuItem>No Raster Found</MenuItem>
                                      )}
                                    </Select>
                                    {/* {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>} */}
                                  </Box>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardCustom>
                    </Box>

                    {/* <Controller
                      name="isAutoCreateAnalysis"
                      control={control}
                      render={({ field }) => (
                        <Box className={classes.formBlockItem}>
                          <InputLabel htmlFor="cropTypeName">Auto Create Analysis</InputLabel>
                          <FormControlLabel
                            label="Add Stand count Analytics"
                            control={
                              <Checkbox
                                id="cropTypeName"
                                size="small"
                                checked={getValues('isAutoCreateAnalysis')}
                                {...field}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  setValue('isAutoCreateAnalysis', e.target.checked);
                                }}
                              />
                            }
                          />
                        </Box>
                      )}
                    /> */}
                    {/* <Controller
                      name="analyticsDisplay"
                      control={control}
                      render={({ field }) => (
                        <Box className={classes.formBlockItem}>
                          <InputLabel htmlFor="cropTypeName">Type View Data</InputLabel>
                          <Box className={classes.viewDataContainer}>
                            {ICropTypesDataView.map((item) => (
                              <CropTypeDataView
                                key={item.viewName}
                                dataSampleImg={item.viewImage}
                                dataSampleName={item.viewName}
                                {...field}
                                checked={getValues('analyticsDisplay')?.includes(item.viewValue)}
                                onChangeValue={(isCheck: any) => getDatavalueChecked(item.viewValue, isCheck)}
                              />
                            ))}
                          </Box>
                        </Box>
                      )}
                    /> */}
                    {status !== STATUS_API.SUCCESS || documentEdit === undefined ? (
                      <SkeletonCommon />
                    ) : (
                      <Grid item xs={12} sx={{ pb: 2 }}>
                        <DropFileCustom
                          fileSelected={documentEdit}
                          acceptFile="pdf"
                          labelFileSelected="Document"
                          subLabelTypeFile="Upload your document file here..."
                          onSelectFile={handleChangeDocument}
                        />
                      </Grid>
                    )}
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <Button
                      type="submit"
                      fullWidth
                      className={classes.submitBtnForm}
                      data-testid="next-btn"
                      variant="contained">
                      {settingIndex ? 'Update Type' : 'Create Type'}
                    </Button>
                  </DialogActions>
                </Typography>
              </Box>
            </Scrollbars>
          )}
        </>
      ) : (
        <Scrollbars autoHide style={{ height: '750px' }}>
          <DialogTitle
            className={classes.dialogTitle}
            sx={{
              background: theme.palette.background.paper,
            }}>
            Create Type
          </DialogTitle>
          {/* Container */}
          <Box
            className={classes.dialogContent}
            sx={{
              background: theme.palette.background.paper,
            }}>
            {/* Form */}
            <Typography component="form" className={classes.formContainer} onSubmit={handleSubmit(handleSubmitForm)}>
              <DialogContent className={classes.formBlock}>
                <Box className={classes.formBlockPickup} sx={{ paddingBottom: '16px' }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Controller
                        name="icon"
                        control={control}
                        render={({ field }) => (
                          <Box className={classes.formBlockItemAva}>
                            <InputLabel htmlFor="cropTypeIcon">Type Icon</InputLabel>
                            <AvatarUser
                              avatar={avaShow}
                              handleChangeFile={(e) => handleChangeFile(e)}
                              heightAvatar="50px"
                              widthAvatar="50px"
                              heightBtnUpload="30px"
                              btnLabel="Upload Icon"
                              backgroundColor={getValues('color')}
                            />
                          </Box>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="color"
                        control={control}
                        render={({ field }) => (
                          <Box className={classes.formBlockItemColor}>
                            <InputLabel htmlFor="cropTypeIcon">Type Color</InputLabel>
                            <Box onClick={() => handleClickColorPicker()} className={classes.pickupColorBox}>
                              <Typography
                                className={classes.pickupColorValue}
                                sx={{
                                  color: getValues('color'),
                                  border: `1px solid ${getValues('color')}`,
                                }}>
                                {getValues('color') === theme.palette.primary.main ? 'Default' : getValues('color')}
                              </Typography>
                              <Tooltip
                                title={openPickColor ? 'Close pick color' : 'Open pick color'}
                                placement="right"
                                arrow>
                                <Box
                                  className={classes.pickupColorBtn}
                                  sx={{
                                    backgroundColor: getValues('color'),
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            {
                              // <ColorPicker
                              openPickColor ? (
                                <Box className={classes.pickupColorValueContainer}>
                                  <TextField
                                    className={classes.editColor}
                                    id="cropTypeColor"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    {...field}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'color')}
                                  />
                                  <HexColorPickerDebounce
                                    {...field}
                                    style={{
                                      position: 'absolute',
                                      top: '0px',
                                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                                      borderRadius: '10px',
                                    }}
                                    color={getValues('color')}
                                    onChange={(color: any) => {
                                      setValue('color', color);
                                    }}
                                  />
                                </Box>
                              ) : (
                                <></>
                              )
                            }
                          </Box>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Box className={classes.formBlockItem}>
                      <InputLabel htmlFor="cropTypeName">Type Name</InputLabel>
                      <TextField
                        id="cropTypeName"
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'name')}
                      />
                    </Box>
                  )}
                />
                <CardCustom>
                  <Box
                    sx={{
                      pb: '12px',
                      background: theme.palette.background.paper,
                    }}>
                    <InputLabel sx={{ color: theme.palette.primary.main }}>RGB</InputLabel>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Controller
                          name="rgbList"
                          control={control}
                          render={({ field }) => (
                            <Box
                              sx={{
                                mb: '10px',
                              }}
                              key="clientId">
                              <InputLabel>Analysis Type</InputLabel>
                              <Select
                                sx={{
                                  height: '46px',
                                  padding: '0px 14px',
                                  width: '100%',
                                  maxWidth: '680.61px',
                                }}
                                fullWidth
                                multiple
                                {...field}
                                onChange={(event) => handleSelect(event, 'rgbList')}
                                // renderValue={(selected) => handleRenderRasterList(selected)}>
                              >
                                {typAnalysisOptons ? (
                                  typAnalysisOptons.map((data: any) => (
                                    <MenuItem key={data} value={data.value}>
                                      {data.label}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem>No Raster Found</MenuItem>
                                )}
                              </Select>
                              {/* {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>} */}
                            </Box>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="isAutoCreateAnalysisRGB"
                          control={control}
                          render={({ field }) => (
                            <Box
                              sx={{
                                mb: '10px',
                              }}
                              key="clientId">
                              <InputLabel>Auto Create Analysis</InputLabel>
                              <Select
                                sx={{
                                  height: '46px',
                                  padding: '0px 14px',
                                  width: '100%',
                                  maxWidth: '680.61px',
                                }}
                                fullWidth
                                multiple
                                {...field}
                                onChange={(event) => handleSelect(event, 'isAutoCreateAnalysisRGB')}
                                // renderValue={(selected) => handleRenderRasterList(selected)}>
                              >
                                {typAnalysisOptons ? (
                                  typAnalysisOptons.map((data: any) => (
                                    <MenuItem key={data} value={data.value}>
                                      {data.label}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem>No Raster Found</MenuItem>
                                )}
                              </Select>
                              {/* {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>} */}
                            </Box>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardCustom>
                <CardCustom>
                  <Box
                    sx={{
                      pb: '12px',
                      background: theme.palette.background.paper,
                    }}>
                    <InputLabel sx={{ color: theme.palette.primary.main }}>RGB MS</InputLabel>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Controller
                          name="rgbMsList"
                          control={control}
                          render={({ field }) => (
                            <Box
                              sx={{
                                mb: '10px',
                              }}
                              key="clientId">
                              <InputLabel>Analysis Type</InputLabel>
                              <Select
                                sx={{
                                  height: '46px',
                                  padding: '0px 14px',
                                  width: '100%',
                                  maxWidth: '680.61px',
                                }}
                                fullWidth
                                multiple
                                {...field}
                                onChange={(event) => handleSelect(event, 'rgbMsList')}
                                // renderValue={(selected) => handleRenderRasterList(selected)}>
                              >
                                {typAnalysisOptons ? (
                                  typAnalysisOptons.map((data: any) => {
                                    const { label, id, value } = data;
                                    return (
                                      <MenuItem key={id} value={value}>
                                        {label}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  <MenuItem>No Raster Found</MenuItem>
                                )}
                              </Select>
                              {/* {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>} */}
                            </Box>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="isAutoCreateAnalysisRgbMs"
                          control={control}
                          render={({ field }) => (
                            <Box
                              sx={{
                                mb: '10px',
                              }}
                              key="clientId">
                              <InputLabel>Auto Create Analysis</InputLabel>
                              <Select
                                sx={{
                                  height: '46px',
                                  padding: '0px 14px',
                                  width: '100%',
                                  maxWidth: '680.61px',
                                }}
                                fullWidth
                                multiple
                                {...field}
                                onChange={(event) => handleSelect(event, 'isAutoCreateAnalysisRgbMs')}
                                // renderValue={(selected) => handleRenderRasterList(selected)}>
                              >
                                {typAnalysisOptons ? (
                                  typAnalysisOptons.map((data: any) => {
                                    const { label, id, value } = data;
                                    return (
                                      <MenuItem key={id} value={value}>
                                        {label}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  <MenuItem>No Raster Found</MenuItem>
                                )}
                              </Select>
                              {/* {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>} */}
                            </Box>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardCustom>
                <Grid item xs={12}>
                  <DropFileCustom
                    fileSelected={document}
                    acceptFile="pdf"
                    labelFileSelected="Document"
                    subLabelTypeFile="Upload your document file here..."
                    onSelectFile={handleChangeDocument}
                  />
                </Grid>

                {/* <Controller
                  name="isAutoCreateAnalysis"
                  control={control}
                  render={({ field }) => (
                    <Box className={classes.formBlockItem}>
                      <InputLabel htmlFor="cropTypeName">Auto Create Analysis</InputLabel>
                      <FormControlLabel
                        label="Add Stand count Analytics"
                        control={
                          <Checkbox
                            id="cropTypeName"
                            size="small"
                            checked={getValues('isAutoCreateAnalysis')}
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setValue('isAutoCreateAnalysis', e.target.checked);
                            }}
                          />
                        }
                      />
                    </Box>
                  )}
                /> */}
                {/* <Controller
                  name="analyticsDisplay"
                  control={control}
                  render={({ field }) => (
                    <Box className={classes.formBlockItem}>
                      <InputLabel htmlFor="cropTypeName">Type View Data</InputLabel>
                      <Box className={classes.viewDataContainer}>
                        {ICropTypesDataView.map((item) => (
                          <CropTypeDataView
                            key={item.viewName}
                            dataSampleImg={item.viewImage}
                            dataSampleName={item.viewName}
                            checked={getValues('analyticsDisplay')?.includes(item.viewValue)}
                            onChangeValue={(isCheck: any) => getDatavalueChecked(item.viewValue, isCheck)}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}
                /> */}
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  fullWidth
                  className={classes.submitBtnForm}
                  data-testid="next-btn"
                  variant="contained">
                  {settingIndex ? 'Update Type' : 'Create Type'}
                </Button>
              </DialogActions>
            </Typography>
          </Box>
        </Scrollbars>
      )}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          padding: 0,
          position: 'absolute',
          right: 8,
          top: 8,
          '.MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          color: (theme) => theme.palette.color.grey5,
        }}>
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

export default UserAnalysisDialogCropType;
