import { Box, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { CreateAndEditMode, IRawFileUpload, ITilingMapField } from 'interfaces/workspace';
import { FC, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeUploadRawImageList,
  changeUploadReconstructedImageList,
  tilingMapSelector,
} from 'store/slices/tilingMapSlice';
import FillFieldData, { initialValues } from './FillFieldData';
import ProgressingData from './ProgressingData';

interface CreateAndEditFieldModalProps {
  isShow: boolean;
  handleClose: () => void;
}

const CreateAndEditFieldModal: FC<CreateAndEditFieldModalProps> = ({ isShow, handleClose }) => {
  const [rawFileUpload, setRawFileUpload] = useState<IRawFileUpload>();

  const queryClient = useQueryClient();
  const form = useForm<ITilingMapField>({ defaultValues: initialValues });
  const mapViewStylesClasses = useMapViewStyle();
  const { setTaskList, theLastTask, selectedTask } = useQueryListTaskOfField();
  const { fieldDetail } = useQueryFieldDetail();
  const dispatch = useAppDispatch();
  const {
    createAndEditFieldModal: { mode },
    isAddDataMode,
  } = useAppSelector(tilingMapSelector);

  const onCloseModal = () => {
    form.reset(initialValues);
    setRawFileUpload(undefined);
    setTaskList([]);
    queryClient.invalidateQueries([QUERY_KEY.GET_LIST_FIELD]);
    dispatch(changeUploadRawImageList([]));
    dispatch(changeUploadReconstructedImageList([]));
    handleClose();
  };

  const renderTitle = () => {
    if (mode === CreateAndEditMode.TASK) {
      return isAddDataMode ? 'Create New Task' : 'Edit Task';
    } else {
      return 'Create New Field';
    }
  };

  return (
    <CommonModal isShow={isShow} handleClose={onCloseModal}>
      <Box sx={{ width: '600px' }}>
        <Scrollbars style={{ width: '100%' }} autoHeight autoHeightMax={'95vh'}>
          <Box sx={{ p: '32px' }}>
            <Typography className={mapViewStylesClasses.titleInput} sx={{ mb: '24px', fontSize: '18px' }}>
              {renderTitle()}
            </Typography>
            {rawFileUpload || (theLastTask && !theLastTask?.status) ? (
              <ProgressingData rawFileUpload={rawFileUpload} theLastTask={theLastTask} handleClose={handleClose} />
            ) : (
              <FillFieldData
                selectedTask={selectedTask}
                fieldDetail={fieldDetail!}
                form={form}
                setRawFileUpload={setRawFileUpload}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Scrollbars>
      </Box>
    </CommonModal>
  );
};

export default CreateAndEditFieldModal;
