import { TextField } from '@mui/material';
import React, { FC } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

interface CommonTextFieldProps {
  control: Control<any>;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  name: string;
  disabled?: boolean;
}

const CommonTextField: FC<CommonTextFieldProps> = ({ control, rules, name, disabled }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          hiddenLabel={true}
          fullWidth={true}
          onChange={onChange}
          value={value || ''}
          helperText={error ? error.message : null}
          error={!!error}
          inputProps={{
            form: {
              autocomplete: 'off',
            },
          }}
          disabled={disabled}
        />
      )}
    />
  );
};

export default CommonTextField;
