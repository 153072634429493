import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { ICropTypes } from 'common/defines/clients';
import { ToastMessage } from 'common/defines/constants';
import CommonTextField from 'components/Common/CommonTextField';
import { QUERY_KEY } from 'constants/constants';
import useQueryWorkspaceInfo from 'hooks/workspace/useQueryWorkspaceInfo';
import {
  AgeEnum,
  CreateAndEditMode,
  CropIntelligenceEnum,
  FieldOptionEnum,
  IField,
  IRawFileUpload,
  ITask,
  ITilingMapField,
  ReconstructedBandEnum,
  SensorTypeEnum,
} from 'interfaces/workspace';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  handleRawImage,
  handleReconstructedImage,
  updateRawImage,
  updateReconstructedImage,
} from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeUploadRawImageList,
  changeUploadReconstructedImageList,
  tilingMapSelector,
} from 'store/slices/tilingMapSlice';
import SelectArea from './components/SelectArea';
import SelectDate from './components/SelectDate';
import UploadImages from './components/upload-images';

const formDataOptionList = [
  { name: FieldOptionEnum['auto-boundary'], value: true },
  { name: FieldOptionEnum.dsm, value: true },
  { name: FieldOptionEnum.dtm, value: true },
  { name: FieldOptionEnum.tiles, value: true },
  { name: FieldOptionEnum['3d-tiles'], value: true },
  { name: FieldOptionEnum['dem-resolution'], value: 1.0 },
  { name: FieldOptionEnum['orthophoto-resolution'], value: 0.1 },
  { name: FieldOptionEnum['feature-quality'], value: 'high' },
  { name: FieldOptionEnum['pc-quality'], value: 'high' },
];

export const initialValues: ITilingMapField = {
  fieldName: '',
  cropType: ICropTypes.OIL_PALM,
  age: AgeEnum.IMMATURE,
  type: CropIntelligenceEnum.RAW_IMAGE,
  sensorType: SensorTypeEnum.RGB,
  date: moment().format(),
  description: '',
};

interface FillFieldDataProps {
  form: UseFormReturn<ITilingMapField>;
  selectedTask: ITask | undefined;
  fieldDetail: IField;
  setRawFileUpload: (arg: IRawFileUpload) => void;
  handleClose: () => void;
}

const FillFieldData: FC<FillFieldDataProps> = ({ selectedTask, form, setRawFileUpload, handleClose, fieldDetail }) => {
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { workspaceData } = useQueryWorkspaceInfo();
  const { _id: workspaceId } = workspaceData || {};
  const { control, handleSubmit } = form;
  const {
    upload: { rawImageList, reconstructedImageList },
    isAddDataMode,
    createAndEditFieldModal: { mode },
  } = useAppSelector(tilingMapSelector);
  const { getValues } = form;

  const dispatch = useAppDispatch();
  const isInEditMode = !isAddDataMode && !!selectedTask?.status;
  const { _id, fieldId } = selectedTask || {};

  const warningToast = () =>
    toast.warning(ToastMessage.DSM_DTM_LONG_PROGRESS, {
      position: 'top-center',
      autoClose: 10000,
    });

  const getFilePath = (field: string | null) => {
    if (!field) return '';
    const getRawPath = field?.split('/')?.slice(-1)?.[0];
    return getRawPath.replace(/^\d+/, ''); // Regular expression to remove leading numbers
  };

  // prefill data in edit mode
  useEffect(() => {
    if (mode === CreateAndEditMode.TASK) {
      const { cropType } = selectedTask || {};
      const { name } = fieldDetail || {};
      form.setValue('fieldName', name);
      form.setValue('cropType', cropType!);

      if (isInEditMode) {
        const {
          age,
          geoTIFFPath,
          description,
          date,
          taskId,
          dsmGeoTIFFPath,
          dtmGeoTIFFPath,
          dtmBlendDem,
          dsmBlendDem,
        } = selectedTask || {};
        form.setValue('type', taskId ? CropIntelligenceEnum.RAW_IMAGE : CropIntelligenceEnum.RECONSTRUCTED_IMAGE);
        form.setValue('fieldName', name);
        form.setValue('age', age);
        form.setValue('description', description);
        form.setValue('date', date);

        if (!taskId) {
          const getRgbFilePath = getFilePath(geoTIFFPath);
          const getDsmFilePath = getFilePath(dsmGeoTIFFPath);
          const getDtmFilePath = getFilePath(dtmGeoTIFFPath);

          const reconstructedImageList = [
            {
              name: ReconstructedBandEnum.RGB_BAND,
              file: new File([], getRgbFilePath || ''),
              is3DTexturedSurface: false,
            },
          ];
          if (getDsmFilePath) {
            reconstructedImageList.push({
              name: ReconstructedBandEnum.DSM,
              file: new File([], getDsmFilePath || ''),
              is3DTexturedSurface: dsmBlendDem === '1',
            });
          }
          if (getDtmFilePath) {
            reconstructedImageList.push({
              name: ReconstructedBandEnum.DTM,
              file: new File([], getDtmFilePath || ''),
              is3DTexturedSurface: dtmBlendDem === '1',
            });
          }
          dispatch(changeUploadReconstructedImageList(reconstructedImageList));
        }
      }
    }
  }, [dispatch, selectedTask, form, isAddDataMode, isInEditMode, mode, fieldDetail]);

  const handleOnSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEY.GET_LIST_FIELD]);
    queryClient.invalidateQueries([QUERY_KEY.GET_TASK_LIST_OF_FIELD]);
    dispatch(changeUploadRawImageList([]));
    dispatch(changeUploadReconstructedImageList([]));
  };

  const handleExistedDateError = (error: any) => {
    if (error.data?.message === 'Date is exist') {
      toast.error('The date selected already exists in the field');
    }
  };

  const handleRawImageMutation = useMutation(handleRawImage, {
    onSuccess(res) {
      setRawFileUpload(res.data);
      handleOnSuccess();
    },
    onError(error: any) {
      handleExistedDateError(error);
    },
  });

  const handleCreateReconstructedImageMutation = useMutation(handleReconstructedImage, {
    onMutate() {
      const isShowMessage = reconstructedImageList.some(
        (item) => item.name === ReconstructedBandEnum.DSM || item.name === ReconstructedBandEnum.DTM
      );
      if (isShowMessage) {
        warningToast();
      }
    },
    onSuccess() {
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_FIELD]);
      queryClient.invalidateQueries([QUERY_KEY.GET_TASK_LIST_OF_FIELD]);
      toast.success('Create reconstructed image successful');
      setIsDisabledSaveButton(true);
    },
    onError(error: any) {
      handleExistedDateError(error);
    },
  });

  const handleUpdateRawImageMutation = useMutation(updateRawImage, {
    onSuccess() {
      toast.success('Update field information successful');
      handleOnSuccess();
      handleClose();
    },
  });

  const handleUpdateReconstructedImageMutation = useMutation(updateReconstructedImage, {
    onMutate() {
      const isShowMessage = reconstructedImageList.some(
        (item) =>
          item.file?.size && (item.name === ReconstructedBandEnum.DSM || item.name === ReconstructedBandEnum.DTM)
      );
      if (isShowMessage) {
        warningToast();
      }
    },
    onSuccess() {
      toast.success('Update field information successful');
      handleOnSuccess();
      queryClient.invalidateQueries([QUERY_KEY.GET_TASK_LIST_OF_FIELD]);
      handleClose();
    },
  });

  const isLoading =
    handleRawImageMutation.isLoading ||
    handleCreateReconstructedImageMutation.isLoading ||
    handleUpdateRawImageMutation.isLoading ||
    handleUpdateReconstructedImageMutation.isLoading;

  const handleFormDataWithReconstructedImage = (formData: FormData) => {
    reconstructedImageList.length &&
      reconstructedImageList.forEach((item) => {
        if (item.file?.size) {
          switch (item.name) {
            case ReconstructedBandEnum.DSM: {
              formData.append('dsm', item.file);
              formData.append('dsmBlendDem', item.is3DTexturedSurface ? '1' : '0');
              return;
            }
            case ReconstructedBandEnum.DTM: {
              formData.append('dtm', item.file);
              formData.append('dtmBlendDem', item.is3DTexturedSurface ? '1' : '0');
              return;
            }
            default:
              formData.append('image', item.file);
              return;
          }
        }
      });
  };

  const isLackOfRgbFile = () => {
    const rbgItem = reconstructedImageList.find((item) => item.name === ReconstructedBandEnum.RGB_BAND);
    const existDtmOrDsm = reconstructedImageList.some(
      (item) => item.name === ReconstructedBandEnum.DSM || ReconstructedBandEnum.DTM
    );
    return !(rbgItem && rbgItem.file?.size) && existDtmOrDsm;
  };

  const onSubmit = (data: ITilingMapField) => {
    if (isInEditMode && _id) {
      const formData = new FormData();
      const { age, fieldName: name, cropType, type, description, date } = data;
      if (type === CropIntelligenceEnum.RAW_IMAGE) {
        handleUpdateRawImageMutation.mutate({ _id, age, name, cropType, description, date });
      } else if (type === CropIntelligenceEnum.RECONSTRUCTED_IMAGE) {
        handleFormDataWithReconstructedImage(formData);
      }
      formData.append('age', age);
      formData.append('name', name);
      formData.append('cropType', cropType);
      formData.append('description', description);
      formData.append('date', date);

      for (const pair of formData.entries()) {
        console.log(`${pair[0]}, ${JSON.stringify(pair[1])}`);
      }
      handleUpdateReconstructedImageMutation.mutate({ _id, formData });
      return;
    }

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'fieldName') formData.append('name', value);
      else formData.append(key, value);
    });

    workspaceId && formData.append('workspaceId', workspaceId);
    fieldId && formData.append('fieldId', fieldId);

    if (getValues('type') === CropIntelligenceEnum.RAW_IMAGE) {
      rawImageList.length &&
        rawImageList.forEach((item) => {
          formData.append('images', item);
        });
      formData.append('options', JSON.stringify(formDataOptionList));
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}, ${JSON.stringify(pair[1])}`);
      }
      handleRawImageMutation.mutate(formData);
    } else {
      handleFormDataWithReconstructedImage(formData);
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}, ${JSON.stringify(pair[1])}`);
      }
      if (isLackOfRgbFile()) {
        toast.error(ToastMessage.LACK_ORTHOIMAGE_FILE);
        return;
      }
      handleCreateReconstructedImageMutation.mutate(formData);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: '8px' }}>Field name</Typography>
            <CommonTextField
              control={control}
              name="fieldName"
              rules={{
                required: {
                  value: true,
                  message: 'Name is required',
                },
              }}
              disabled={mode === CreateAndEditMode.TASK}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: '8px' }}>Description</Typography>
            <CommonTextField control={control} name="description" />
          </Box>
          <SelectArea form={form} />
          {<SelectDate control={control} />}
          {(!isInEditMode || (isInEditMode && getValues('type') === CropIntelligenceEnum.RECONSTRUCTED_IMAGE)) && (
            <UploadImages form={form} isInEditMode={isInEditMode} />
          )}
        </Stack>
        <Box sx={{ mt: '16px', textAlign: 'right' }}>
          <LoadingButton loading={isLoading} variant="contained" type="submit" disabled={isDisabledSaveButton}>
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default FillFieldData;
