import { Box, CircularProgress, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { FC, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeCreateAndEditFieldModal } from 'store/slices/tilingMapSlice';

interface StatusCheckingModalProps {
  isShow: boolean;
  handleClose: () => void;
}

const StatusCheckingModal: FC<StatusCheckingModalProps> = ({
  isShow,
  handleClose,
}) => {
  const { theLastTask } = useQueryListTaskOfField();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (theLastTask) {
      setTimeout(() => {
        handleClose();
        if (!theLastTask.status) {
          dispatch(changeCreateAndEditFieldModal({ isShow: true }))
        }
      }, 2000);
    }
  }, [dispatch, theLastTask, handleClose]);

  return (
    <CommonModal isShow={isShow} handleClose={handleClose}>
      <Box sx={{ width: '600px', p: '32px', textAlign: 'center' }}>
        <Typography sx={{ fontSize: '18px', mb: '30px' }}>Checking field status</Typography>
        <CircularProgress />
      </Box>
    </CommonModal>
  );
};

export default StatusCheckingModal;
