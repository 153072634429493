import { center } from '@turf/turf';
import { StandPointKeyEnum } from 'common/defines/analytic';
import { CLICKED_STAND_POINT_COLOR } from 'common/defines/clients';
import { useGetClientSettingMapView } from 'hooks/map-view/useGetClientSettingMapView';
import { useClientData } from 'hooks/useClientData';
import { Layer, Source } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { crownAreaAnalyticsSelector } from 'store/slices/map-view/crownAreaAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const EditTabLayer = () => {
  const { isLayer3D, isInEditTab, clickedStandPointId } = useAppSelector(mapViewSelector);
  const { contourSetting } = useClientData();

  const {
    switchCrownAreaList,
    dataGeoJsonPoint,
    dataGeoJsonPolygon,
    drawCrownArea: { editedId, geometry },
  } = useAppSelector(crownAreaAnalyticsSelector);
  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, getOpacityExtrusion } =
    useGetClientSettingMapView();

  const crownSwitchState = switchCrownAreaList.find((item) => item.key === StandPointKeyEnum.CROWN_AREA);
  const standCountSwitchState = switchCrownAreaList.find((item) => item.key === StandPointKeyEnum.STAND_COUNT);

  const generateCrownAreaColor: mapboxgl.Expression = [
    'case',
    ['==', ['get', '_id'], clickedStandPointId],
    CLICKED_STAND_POINT_COLOR,
    crownSwitchState?.color,
  ];

  const convertedDataGeojsonPoint: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: dataGeoJsonPoint?.features.map((item: any) => ({
      ...center(item),
      properties: item.properties,
    })) as GeoJSON.Feature[],
  };

  // filter crown area in edit mode
  const convertedDataGeojsonPolygon: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: dataGeoJsonPolygon?.features
      .map((item: GeoJSON.Feature) => (item.properties?._id !== editedId ? item : { ...item, geometry }))
      .filter((item) => item.geometry?.type !== 'Point') as GeoJSON.Feature[],
  };

  return (
    <>
      {/* Stand Point */}
      {convertedDataGeojsonPoint && isInEditTab && (
        <Source id={`stand-edit-point`} type="geojson" data={convertedDataGeojsonPoint}>
          <Layer
            id={`stand-edit-point`}
            type="circle"
            source={`stand-edit-point`}
            paint={{
              'circle-radius': 5,
              'circle-color': isInEditTab ? standCountSwitchState?.color : ['get', 'color'],
              'circle-opacity': 1,
            }}
            layout={{ visibility: standCountSwitchState?.visible ? 'visible' : 'none' }}
          />
        </Source>
      )}

      {/* Crown Area */}
      {convertedDataGeojsonPolygon && isInEditTab && (
        <Source id={`crown-area-edit-source`} type="geojson" data={convertedDataGeojsonPolygon}>
          {/* 2D Contour */}
          <Layer
            id={`crown-area-edit`}
            source={`crown-area-edit`}
            type="line"
            paint={{
              'line-color': generateCrownAreaColor,
              'line-width': 2,
            }}
            layout={{ visibility: crownSwitchState?.visible && !isLayer3D ? 'visible' : 'none' }}
          />
          {/* 3D Filled contour */}
          <Layer
            id={`crown-area_fill-polygon`}
            type="fill"
            paint={{
              'fill-color': generateCrownAreaColor,
              'fill-opacity': 0,
            }}
            source={`crown-area-edit-source`}
            layout={{ visibility: crownSwitchState?.visible && isLayer3D ? 'visible' : 'none' }}
          />
          <Layer
            id={`crown-area_top-surface`}
            type="fill-extrusion"
            beforeId={`crown-area_fill-polygon`}
            source={`crown-area-edit-source`}
            filter={['!=', 'color', 'transparent']}
            paint={{
              'fill-extrusion-color': generateCrownAreaColor,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': contourSetting,
              'fill-extrusion-opacity': getTopSurfaceOpacity,
              'fill-extrusion-vertical-gradient': false,
            }}
            layout={{ visibility: crownSwitchState?.visible && isLayer3D ? 'visible' : 'none' }}
          />
          <Layer
            id={`crown-area_side-surface`}
            type="fill-extrusion"
            beforeId={`crown-area_top-surface`}
            source={`crown-area-edit-source`}
            filter={['!=', 'color', 'transparent']}
            paint={{
              'fill-extrusion-color': isSameColorTopSurface ? generateCrownAreaColor : colorTransparent,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': 0,
              'fill-extrusion-opacity': getOpacityExtrusion,
              'fill-extrusion-vertical-gradient': false,
            }}
            layout={{ visibility: crownSwitchState?.visible && isLayer3D ? 'visible' : 'none' }}
          />
        </Source>
      )}
    </>
  );
};

export default EditTabLayer;
