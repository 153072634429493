import DrawControl from 'components/Common/DrawControl';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeDataPointPolygon, standCountAnalyticSelector } from 'store/slices/map-view/standCountAnalytics';

const DrawPolygonStandCount = () => {
  const dispatch = useAppDispatch();
  const { dataPointPolygon } = useAppSelector(standCountAnalyticSelector);

  const handleUpdateDraw = useCallback(
    (val: any) => {
      const isValidPolygon = val?.features?.[0].geometry.coordinates.length;
      isValidPolygon && dispatch(changeDataPointPolygon(val?.features));
    },
    [dispatch]
  );

  const handleDeleteDraw = useCallback(
    (val: any) => {
      const deletedId = val?.features?.[0].id;
      const filteredDataPointPolygon = dataPointPolygon.filter((item) => item.id === deletedId);
      dispatch(changeDataPointPolygon(filteredDataPointPolygon));
    },
    [dataPointPolygon, dispatch]
  );

  return (
    <DrawControl
      position="top-left"
      controls={{
        point: false,
        line_string: false,
        polygon: true,
        trash: true,
      }}
      onCreate={handleUpdateDraw}
      onUpdate={handleUpdateDraw}
      onDelete={handleDeleteDraw}
    />
  );
};

export default DrawPolygonStandCount;
