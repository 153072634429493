import { Grid, Skeleton } from '@mui/material';
import { memo } from 'react';

interface ISkeletonCommon {
  count?: number;
  animation?: 'pulse' | 'wave' | false;
  heightItems?: number;
}

const SkeletonCommon = ({ count = 1, animation = 'wave', heightItems = 37 }: ISkeletonCommon) => {
  return (
    <Grid display="flex" direction="column">
      {Array.from(Array(count).keys()).map((item) => (
        <Skeleton key={item} animation={animation} variant="text" width="100%" height={`${heightItems}px`} />
      ))}
    </Grid>
  );
};

export default memo(SkeletonCommon);
