import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, Skeleton, Stack, Typography } from '@mui/material';
import { ILinesVectorDisplay } from 'common/defines/clients';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAllLines } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeAllLinesVectorDisplay, rightBarSelector } from 'store/slices/rightBarSlice';
import SwitchLinesVector from './SwitchLinesVector';

interface LinesVectorCollapseProps {
  isShowVector: boolean;
}

const LinesVectorCollapse: FC<LinesVectorCollapseProps> = ({ isShowVector }) => {
  const [isShowLines, setIsShowLines] = useState<boolean>(false);
  const { linesVectorDisplay, linesVectorSensorId: sensorId } = useAppSelector(rightBarSelector);

  const dispatch = useAppDispatch();
  const classes = useLayerSelectCardStyle();
  const { t } = useTranslation();

  const { isLoading } = useQuery([QUERY_KEY.GET_LINES_VECTOR_LIST, sensorId], () => getAllLines(sensorId), {
    enabled: !!sensorId,
    onSuccess(res) {
      const resData = res.data?.docs as ILinesVectorDisplay[];
      const filteredLinesVectorList = resData.map(({ colorCode, _id, lineCategory, sensorId, geometry }) => ({
        _id,
        colorCode,
        sensorId,
        lineCategory,
        visible: false,
        geometry,
      }));

      dispatch(changeAllLinesVectorDisplay(filteredLinesVectorList));
    },
  });

  // clear lines vector data when sensorId is empty
  useEffect(() => {
    if (!sensorId) {
      dispatch(changeAllLinesVectorDisplay([]));
    }
  }, [dispatch, sensorId]);

  return (
    <Collapse in={isShowVector} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
      {linesVectorDisplay?.length > 0 && (
        <Button
          startIcon={
            isShowLines ? (
              <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
            ) : (
              <ChevronRightIcon style={{ fontWeight: 'bold' }} />
            )
          }
          onClick={() => setIsShowLines(!isShowLines)}
          classes={{ root: classes.buttonTextStyle }}
          style={{ width: '100%' }}>
          <Typography
            className={classes.buttonTextStyle}
            style={{ marginRight: 'auto', fontWeight: '500', fontSize: '13px !important' }}>
            {t('mapView.lines')}
          </Typography>
        </Button>
      )}
      <Collapse in={isShowLines} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
        <Stack>
          {isLoading ? (
            <Box>
              {Array(6)
                .fill(null)
                .map((_, i) => (
                  <Skeleton key={i} variant="rectangular" width="100%" sx={{ mt: '8px' }} />
                ))}
            </Box>
          ) : (
            linesVectorDisplay?.map((item) => <SwitchLinesVector item={item} key={item?._id} />)
          )}
        </Stack>
      </Collapse>
    </Collapse>
  );
};

export default LinesVectorCollapse;
