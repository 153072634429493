import { Close } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import { IOptionalProperty } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getMediaInfo } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch } from 'store/hooks';
import { changeFileProperty } from 'store/slices/clientSlice';

interface IMediaInfo {
  createdAt: Date;
  fileSize: number;
  name: string;
  uploadedBy: {
    name: string;
  };
}

interface IFileHyperLink {
  propertyItem: IOptionalProperty;
  properties: IOptionalProperty[];
}

export const FileHyperLink = ({ propertyItem, properties }: IFileHyperLink) => {
  const dispatch = useAppDispatch();
  const [mediaInfo, setMediaInfo] = useState<IMediaInfo>({
    createdAt: new Date(),
    fileSize: 0,
    name: '',
    uploadedBy: {
      name: '',
    },
  });

  useQuery([QUERY_KEY.MEDIA_DETAIL, propertyItem.mediaId], () => getMediaInfo(propertyItem.mediaId!), {
    enabled: !!propertyItem.mediaId,
    onSuccess(res) {
      setMediaInfo(res.data);
    },
  });

  const formatBytes = (size: number) => {
    const bytes = size;
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1 }}>
        <Box sx={{ pr: 2, display: 'flex' }}>
          <Link
            underline="none"
            href={propertyItem.value[0]}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ display: 'flex', alignItems: 'center', fontWeight: 500, cursor: 'pointer' }}>
            <InsertDriveFileIcon sx={{ pr: 2, color: 'rgba(0, 0, 0, 0.87)' }} />
            {propertyItem.name}
          </Link>
        </Box>
        <IconButton
          sx={{ pr: 1 }}
          onClick={() => {
            const _dataNew = properties.map((_item: any) => {
              if (_item.key === propertyItem.key) {
                return {
                  ..._item,
                  value: [],
                };
              }
              return _item;
            });
            dispatch(changeFileProperty(_dataNew));
          }}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ marginLeft: '25px' }}>
        <Grid>
          <Typography color="disabled" sx={{ fontSize: '12px' }}>
            {mediaInfo && `${moment(mediaInfo?.createdAt).format('YYYY-MM-DD')}, ${formatBytes(mediaInfo?.fileSize)}`}
          </Typography>
        </Grid>
        <Grid>
          {mediaInfo?.name && (
            <Typography color="disabled" sx={{ fontSize: '12px' }}>
              {`Upload by ${mediaInfo?.uploadedBy?.name}`}
            </Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
