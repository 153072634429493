import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface ISubBreadCrumbsSlice {
  subBreadCrumbs: string;
}

const initialState: ISubBreadCrumbsSlice = {
  subBreadCrumbs: 'Overview',
};

export const breadCrumbsSlice = createSlice({
  name: 'breadCrumbs',
  initialState,
  reducers: {
    changeBreadScrumbsSelected: (state, action: PayloadAction<any>) => {
      state.subBreadCrumbs = action.payload;
    },
  },
});

export const { changeBreadScrumbsSelected } = breadCrumbsSlice.actions;

export const breadCrumbsSelector = (state: RootState) => state.breadCrumbs;

export default breadCrumbsSlice.reducer;
