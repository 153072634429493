import { Box, Button, Typography } from '@mui/material';
import AppLayout from 'components/AppLayout';
import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutUser } from 'services/clients/apiClient.services';
import TabControlUser from './TabControlUser';

const MyProfilePage = () => {
  const navigate = useNavigate();
  const sessionId = localStorage.getItem('sessionLogin');

  const styles = {
    userPage: {
      width: '100%',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    userContainer: {
      p: '20px 15px',
    },
    userBox: {
      display: 'flex',
      justifyContent: 'space-between',
      p: '10px 0',
    },
    userBoxTitle: {
      fontWeight: '400',
      fontSize: '2.25rem',
      lineHeight: '42px',
    },
    logoutButton: {
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        opacity: '0.6',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    controlBox: {
      p: '10px 0',
    },
  };

  // Call Logout API
  const useLogoutUser = () => {
    return useMutation(() => logoutUser(sessionId || ''), {
      onSuccess: () => {
        const theme = localStorage.getItem('theme');
        localStorage.clear();
        localStorage.setItem('theme', theme || 'light');
        navigate('/login');
        toast.success('Logout successfully');
      },
      onError: (err) => {
        console.log('err', err);
      },
    });
  };

  const mutationLogoutUser = useLogoutUser();

  const handleLogout = () => {
    if (!sessionId) {
      navigate('/login');
      return;
    }
    mutationLogoutUser.mutate();
  };

  return (
    <AppLayout>
      <Box
        sx={{
          ...styles.userContainer,
        }}>
        <Box
          sx={{
            ...styles.userBox,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}>
          <Typography
            sx={{
              ...styles.userBoxTitle,
              color: (theme) => theme.palette.primary.main,
            }}
            variant="h1">
            My Account
          </Typography>
          <Button
            variant="text"
            onClick={handleLogout}
            sx={{
              ...styles.logoutButton,
            }}>
            Logout
          </Button>
        </Box>
        <Box
          sx={{
            ...styles.controlBox,
          }}>
          <TabControlUser />
        </Box>
      </Box>
    </AppLayout>
  );
};

export default MyProfilePage;
