import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { area, Feature } from '@turf/turf';
import DrawControl from 'components/Common/DrawControl';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeDrawCrownAreaState, crownAreaAnalyticsSelector } from 'store/slices/map-view/crownAreaAnalytics';

let initialControls = {
  point: false,
  line_string: false,
  polygon: true,
  trash: true,
};

const DrawCrownAreaShape = () => {
  const [features, setFeatures] = useState({});
  const {
    drawCrownArea: { crownAreaEditedDetail },
  } = useAppSelector(crownAreaAnalyticsSelector);

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    drawCrownArea: { isLockMap, isShow },
  } = useAppSelector(crownAreaAnalyticsSelector);

  const onUpdate = useCallback((e: { features: any }) => {
    setFeatures((currFeatures) => {
      const newFeatures: any = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e: { features: any }) => {
    setFeatures((currFeatures) => {
      const newFeatures: any = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const handleDrawDone = () => {
    const featureList = Object.values(features);
    const drawObjectInfo: Feature<any> = featureList[featureList.length - 1] as Feature<any>;
    const geometry = drawObjectInfo.geometry;
    const areaOfCrown = area(geometry);
    dispatch(
      changeDrawCrownAreaState({
        isShow: false,
        geometry,
        crownAreaEditedDetail: { ...crownAreaEditedDetail!, area: areaOfCrown },
        isLockMap: false,
      })
    );
  };

  const handleCancelDraw = () => {
    dispatch(changeDrawCrownAreaState({ isShow: false, editedId: '', isLockMap: false }));
  };

  const handleLockMap = () => {
    dispatch(changeDrawCrownAreaState({ isLockMap: !isLockMap }));
  };

  return (
    <Box>
      {isShow && (
        <Box>
          <Box
            sx={{
              position: 'absolute',
              left: '60px',
              top: '10px',
              backgroundColor: theme.palette.background.paper,
              p: '4px',
              borderRadius: '4px',
            }}>
            <Typography sx={{ display: 'inline-block', px: '8px' }}>Click here after finish drawing</Typography>
            <Button variant="outlined" size="small" onClick={handleDrawDone}>
              Done
            </Button>
            <Button variant="outlined" size="small" color="error" onClick={handleCancelDraw} sx={{ ml: '8px' }}>
              Cancel
            </Button>
          </Box>
          <Box
            style={{
              width: '29px',
              height: '29px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              cursor: 'pointer',
              borderRadius: '0px 0px 4px 4px',
              position: 'absolute',
              color: '#000000',
              top: '68px',
              left: '22px',
              boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
              borderTop: '1px solid #ddd',
            }}
            onClick={handleLockMap}>
            {isLockMap ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
          </Box>
          <DrawControl
            position="top-left"
            displayControlsDefault={false}
            controls={initialControls}
            defaultMode={'simple_select'}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        </Box>
      )}
    </Box>
  );
};

export default DrawCrownAreaShape;
