import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import useClientDataConfig from 'hooks/useClientDataConfig';
import { useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { changeFixedSizePointVigor, changeZoomLayer, vigorAnalyticsSelector } from 'store/slices/map-view/vigorAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

const styles = {
  tooltip: {
    borderRadius: '18px',
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#FFF',
      padding: 0,
      margin: 0,
    },
  },
};
const CustomTooltip = withStyles(styles)(Tooltip);

export const useVigorAnalytics = ({ mapRef }: any) => {
  const { isLayer3D, isDisplayBoundaryLine, isDisplay3DCylinder } = useAppSelector(mapViewSelector);
  const { dataVigorInterval, dataGeoJsonPolygon, dataGeoJsonPoint } = useAppSelector(vigorAnalyticsSelector);
  const { analyticId } = useAppSelector(mapViewSelector);

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();

  const { contourSetting } = useClientData();
  const dispatch = useDispatch()

  const { isFixedPoint, value } = useClientDataConfig()

  const zoomLevel = useMemo(() => {
    if (!mapRef.current) return 0;
    return mapRef?.current?.getZoom();
  }, [mapRef?.current?.getZoom()]);

  useEffect(() => {
    if (!mapRef?.current) return;

    if (isFixedPoint && value) {
      dispatch(changeFixedSizePointVigor(value))
    } else
      dispatch(changeZoomLayer(zoomLevel))

  }, [dispatch, isFixedPoint, mapRef, value, zoomLevel, analyticId])

  const layerVigor = useMemo(() => {
    if (!dataGeoJsonPoint && !dataGeoJsonPolygon) {
      return null
    }
    return (
      <>
        {dataGeoJsonPoint &&
          <Source id={`vigor-polygon-point`} type="geojson" data={dataGeoJsonPoint}>
            {/* 2D Point */}
            <Layer
              id={`fill-top-surface-point`}
              type="fill"
              source={`vigor-circle-point`}
              paint={{
                'fill-color': ['get', 'color'],
              }}
              layout={{ visibility: !isLayer3D && !isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Cylinder */}
            <Layer
              id={`vigor-top-surface-point`}
              type="fill-extrusion"
              source={`vigor-circle-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`vigor-side-surface-point`}
              type="fill-extrusion"
              source={`vigor-circle-point`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                // getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && isDisplay3DCylinder ? 'visible' : 'none' }}
            />
          </Source>}
        {dataGeoJsonPolygon &&
          <Source id={`vigor-source`} type="geojson" data={dataGeoJsonPolygon}>
            {/* 2D Contour */}
            <Layer
              id={`vigor_polygon`}
              type="line"
              paint={{
                'line-color': ['get', 'color'],
                'line-width': 2,
                'line-dasharray': [2, 2],
              }}
              source={`vigor-source`}
              layout={{ visibility: !isLayer3D && isDisplayBoundaryLine ? 'visible' : 'none' }}
            />
            {/* 3D Filled contour */}
            <Layer
              id={`vigor_fill-polygon`}
              type="fill"
              paint={{
                'fill-color': ['get', 'color'],
                'fill-opacity': 0,
              }}
              source={`vigor-source`}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`vigor_top-surface`}
              type="fill-extrusion"
              beforeId={`vigor_fill-polygon`}
              source={`vigor_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': contourSetting,
                'fill-extrusion-opacity': getTopSurfaceOpacity,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />
            <Layer
              id={`vigor_side-surface`}
              type="fill-extrusion"
              beforeId={`vigor_top-surface`}
              source={`vigor_source`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D && !isDisplay3DCylinder ? 'visible' : 'none' }}
            />

          </Source>
        }
      </>
    );
  }, [colorTransparent, contourSetting, dataGeoJsonPoint, dataGeoJsonPolygon, getOpacityExtrusion, getTopSurfaceOpacity, isDisplay3DCylinder, isDisplayBoundaryLine, isLayer3D, isSameColorTopSurface, opacity2D]);
  return { layerVigor };
};
