import { Grid } from '@mui/material';
import { MapEnum } from 'common/defines/constants';
import TemporalButton from 'components/MapView/RightBar/AnalyticsTab/TemporalButton';
import { ITask } from 'interfaces/workspace';
import { FC } from 'react';
import TilingMapSliderSelectDate from './TilingMapSliderSelectDate';
interface SelectDateAreaProps {
  taskList: ITask[];
  setIsShowTemporalModal: (arg: boolean) => void;
}

const SelectDateArea: FC<SelectDateAreaProps> = ({ taskList, setIsShowTemporalModal }) => {
  return (
    <Grid container sx={{ marginLeft: '10px' }}>
      <Grid item xs={9}>
        <TilingMapSliderSelectDate taskList={taskList} />
      </Grid>
      <Grid item xs={3} display="flex" alignItems="center">
        <TemporalButton setIsShowTemporalModal={setIsShowTemporalModal} mode={MapEnum.CROP_INTELLIGENT} />
      </Grid>
    </Grid>
  );
};

export default SelectDateArea;
