import { Grid, InputLabel, styled, useTheme } from '@mui/material';
import AppLayout from 'components/AppLayout';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import { QUERY_KEY } from 'constants/constants';
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutUser, queryMe } from 'services/clients/apiClient.services';

const TitleLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 600,
  height: 'fit-content !important',
}));

const InfoLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
  height: 'fit-content !important',
  marginBottom: '40px',
}));

interface ErrorsPageInterface {
  status: string;
}

const ErrorsPage = ({ status }: ErrorsPageInterface) => {
  const navigate = useNavigate();
  const sessionId = localStorage.getItem('sessionLogin');
  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const theme = useTheme();

  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const isFirstLogin = useMemo(() => {
    return userInfo?.data?.isFirstLogin;
  }, [userInfo]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goHome = useCallback(() => {
    navigate('/landing');
  }, [navigate]);

  const reloadPage = useCallback(() => {
    window.location.reload();
    if (isFirstLogin && !isSuperAdmin && !isSuperUser) {
      navigate('/waiting-approval');
      return;
    }
    navigate('/landing');
  }, [isFirstLogin, isSuperAdmin, isSuperUser, navigate]);

  // Call Logout API
  const useLogoutUser = () => {
    return useMutation(() => logoutUser(sessionId || ''), {
      onSuccess: () => {
        const theme = localStorage.getItem('theme');
        localStorage.clear();
        localStorage.setItem('theme', theme || 'light');
        navigate('/login');
        toast.success('Logout successfully');
      },
      onError: (err) => {
        console.log('err', err);
      },
    });
  };

  const mutationLogoutUser = useLogoutUser();

  const handleLogout = () => {
    if (!sessionId) {
      navigate('/login');
      return;
    }
    mutationLogoutUser.mutate();
  };

  const getContentErrors = useMemo(() => {
    let content = null as any;
    switch (status) {
      case '401':
        content = (
          <>
            <TitleLabel>Hey, you! Stop right there.</TitleLabel>
            <InputLabel
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                height: 'fit-content !important',
                marginBottom: '4px',
              }}>
              Access to this page is restricted to authorized users.
            </InputLabel>
            <InfoLabel>Or your account has been timed out. Please log in again!</InfoLabel>
          </>
        );
        break;
      case '403':
        content = (
          <>
            <TitleLabel>Sorry, you do not have permission to this page.</TitleLabel>
            <InfoLabel>If you insist on going to this page, please contact the admin for more details.</InfoLabel>
          </>
        );
        break;
      case '404':
        content = (
          <>
            <TitleLabel>Sorry, the page requested can no longer be found.</TitleLabel>
            <InfoLabel>The page you are looking for doesn’t exist or it has been moved.</InfoLabel>
          </>
        );
        break;
      case 'waiting':
        content = (
          <>
            <TitleLabel>Your request has been successfully submitted</TitleLabel>
            <InfoLabel>
              Our team will reach out to you once your request has been processed, within approximately 1-2 business
              days.
            </InfoLabel>
          </>
        );
        break;
      default:
        <>
          <TitleLabel>Oops </TitleLabel>
          <InfoLabel>Something went wrong.</InfoLabel>
        </>;
        break;
    }
    return content;
  }, [status]);

  return (
    <AppLayout hidden={status === 'waiting'}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: 'calc(100vh - 240px)' }}>
        <Grid item xs={6} textAlign="center">
          {status && getContentErrors}
          <Grid>
            <ButtonCustom
              backgroundColor="#FFF"
              colorLabel="#9E9E9E"
              title={status === 'waiting' ? 'Logout' : 'Go back'}
              border="1px solid #9E9E9E"
              onClick={status === 'waiting' ? handleLogout : goBack}
            />
            <ButtonCustom
              backgroundColor={theme.palette.primary.main}
              colorLabel="#FFF"
              title={status === 'waiting' ? 'Reload Page' : 'Go Home'}
              border={`1px solid ${theme.palette.primary.main}`}
              sx={{ ml: 3 }}
              onClick={status === 'waiting' ? reloadPage : goHome}
            />
          </Grid>
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default ErrorsPage;
