import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Box,
  Button,
  debounce,
  InputAdornment,
  ListItemAvatar,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { QUERY_KEY } from 'constants/constants';
import { get } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getUserLoginSessionByPagination } from 'services/clients/apiClient.services';
import FilterAuditTrail from './FilterAuditTrail';

const flexValue = [4, 2, 1];

const AuditTrail = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowPerPageOptions = [5, 10, 15, 20];
  const [searchClient, setSearchClient] = useState('');
  const titleDialog = useRef<HTMLDivElement>();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isUsingJapanese = i18n.language === 'jp';
  // Filter
  const [openFilterLog, setOpenFilterLog] = useState(false);

  const styles = {
    userListTitle: {
      py: '10px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18.2px',
      textAlign: 'center',
    },
    userContainerName: {
      flex: flexValue[0],
      overflowX: 'hidden',
      mr: '20px',
      borderBottom: 'none',
      px: '16px',
    },
    userAvatar: {
      width: '33px',
      height: '33px',
    },
    userName: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '16px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
    },
    clientUpdateTime: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B',
      textAlign: 'center',
      borderBottom: 'none',
      px: '16px',
    },
    clientStatus: {
      height: '78px',
      width: '100%',
      p: '0',
      borderBottom: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    clientAssignedBy: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B',
      textAlign: 'left',
      borderBottom: 'none',
      px: '16px',
    },
    clientStatusText: {
      height: '24px',
      boxSizing: 'border-box',
      padding: '8px 12px',
      borderRadius: '21px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    // Pagination
    clientShowTotal: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '20px 20px',
    },
  };

  // call API session-login/pagination
  const { data: dataSessionLogin, isLoading } = useQuery(
    [QUERY_KEY.SESSION_LOGIN_USER, page, rowsPerPage, searchClient],
    () => getUserLoginSessionByPagination(page, rowsPerPage, searchClient),
    { keepPreviousData: true }
  );

  const getDate = useCallback((dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
  }, []);

  const dataSessionUser = useMemo(() => {
    return get(dataSessionLogin, 'data[0].docs', null);
  }, [dataSessionLogin]);

  // Clients Assigned By
  const clientsAssignedBy = useMemo(() => {
    if (!dataSessionUser) return [];
    dataSessionUser.forEach((item: any) => {
      if (item.user.clients.length > 0) {
        item.clientsName = item.user.clients.map((item: any) => item.name).join(', ');
      } else {
        item.clientsName = 'No clients found';
      }
    });
    return dataSessionUser.map((item: any) => item.clientsName);
  }, [dataSessionUser]);

  // Roles Assigned By
  const rolesAssignedBy = useMemo(() => {
    if (!dataSessionUser) return [];
    dataSessionUser.forEach((item: any) => {
      if (item.user.userRoles.length > 0) {
        item.rolesName = item.user.userRoles.map((item: any) => item.name).join(', ');
      } else {
        item.rolesName = 'No role found';
      }
    });
    return dataSessionUser.map((item: any) => item.rolesName);
  }, [dataSessionUser]);

  const dataValue = useMemo(() => {
    return get(dataSessionLogin, 'data[0].metadata[0]', null);
  }, [dataSessionLogin]);

  const indexStartPage = useMemo(() => {
    return (dataValue?.currentPage - 1) * dataValue?.limit + 1;
  }, [dataValue]);

  const indexEndPage = useMemo(() => {
    if (!dataValue) {
      return 0;
    }
    return Math.min(dataValue.currentPage * dataValue.limit, dataValue.total);
  }, [dataValue]);

  const pageCount = useMemo(() => {
    return Math.ceil(dataValue?.total / dataValue?.limit);
  }, [dataValue]);

  const handleChangePageSize = (event: any) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchClient = useCallback(
    debounce((value: string) => {
      setSearchClient(value);
    }, 500),
    []
  );

  // Open/Close Filter Dialog
  const handleOpenFilterLog = () => {
    setOpenFilterLog(true);
  };
  const handleCloseFilterLog = () => {
    setOpenFilterLog(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: '6px',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
        border: `1px solid ${theme.palette.divider}`,
      }}>
      <Box
        sx={{
          padding: '0 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '74px',
        }}>
        <Typography
          sx={{
            fontFamily: 'Barlow',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
            lineHeight: '20px',
          }}>
          {t('dashboard.audit_log')}
        </Typography>
      </Box>
      <Box sx={{ border: '0.5px solid #EDF1F1' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: '20px',
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography mr="10px" fontSize="16px" lineHeight="19px" color="#A8AEB1">
            {t('dashboard.show')}
          </Typography>
          <Button
            sx={{
              height: '40px',
              border: 'none',
            }}>
            <Select
              sx={{
                border: 'none',
              }}
              id="demo-simple-select"
              value={rowsPerPage}
              onChange={handleChangePageSize}>
              {rowPerPageOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => handleOpenFilterLog()}
            sx={{
              width: '160px',
              height: '40px',
              border: `1px solid ${theme.palette.primary.main}`,
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
              textTransform: 'none',
              marginRight: '20px',
              '&:hover': {
                backgroundColor: '#1C9D57',
                border: `1px solid ${theme.palette.primary.main}`,
                boxShadow: 'none',
              },
              '&:active': {
                boxShadow: 'none',
                backgroundColor: '#1C9D57',
                border: `1px solid ${theme.palette.primary.main}`,
              },
              '&:focus': {
                boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15)',
                border: `1px solid ${theme.palette.primary.main}`,
              },
            }}>
            {t('dashboard.view_user_statistic')}
          </Button>
          <TextField
            type="text"
            placeholder={t('dashboard.search_users')}
            sx={{
              mr: 1,
              width: '340px',
              minWidth: '340px',
              height: '40px',
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '.MuiOutlinedInput-root': {
                height: '40px',
              },
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '5px',
            }}
            onChange={(e) => {
              debouncedSearchClient(e.target.value);
            }}
            InputProps={{
              size: 'small',
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ height: '24px', width: '24px' }} />
                </InputAdornment>
              ),
              inputProps: {
                style: {
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '17px',
                },
              },
            }}
          />
        </Box>
        <FilterAuditTrail open={openFilterLog} onClose={handleCloseFilterLog} />
      </Box>
      <TableContainer sx={{ overflow: 'hidden' }}>
        <Table>
          <TableHead>
            <TableRow
              component="tr"
              sx={{
                backgroundColor: theme.palette.background.default,
                padding: '16px',
                height: '56px',
              }}>
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                  minWidth: '250px',
                  textAlign: 'left',
                }}>
                {t('dashboard.audit_log')}
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                  minWidth: '200px',
                  textAlign: 'left',
                }}>
                {t('dashboard.client_assigned')}
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                  minWidth: '200px',
                  textAlign: 'left',
                }}>
                {t('dashboard.role_assigned')}
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                }}>
                {t('dashboard.user_ID')}
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                }}>
                {t('dashboard.duration')}
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                }}>
                {t('dashboard.login_time')}
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      padding: '16px 0',
                    }}>
                    <WaitingPageAPI />
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
          ) : (
            <TableBody
              sx={{
                pt: 0,
                flexDirection: 'column',
                minHeight: '510px',
                maxHeight: '510px',
                overflowY: 'scroll',
                '-ms-overflow-style': 'none' /* IE and Edge */,
                scrollbarWidth: 'none' /* Firefox */,
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
              {dataSessionUser && dataSessionUser.length > 0 ? (
                dataSessionUser.map((logDetail: any, index: number) => {
                  return (
                    <TableRow
                      component="tr"
                      sx={{
                        display: 'table-row',
                        flex: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        height: '50px',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#F5F5F5',
                        },
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                      key={index}>
                      <TableCell
                        component="td"
                        sx={{
                          ...styles.userContainerName,
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <ListItemAvatar>
                            <Avatar src={logDetail.user.avatar} sx={{ ...styles.userAvatar }} />
                          </ListItemAvatar>
                          <Box>
                            <Typography
                              sx={{
                                ...styles.userName,
                              }}>
                              {logDetail.user.name}
                            </Typography>
                            <Typography
                              sx={{
                                ...styles.userName,
                                fontSize: '14px',
                              }}>
                              {logDetail.user.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      {/* Client */}
                      <TableCell
                        component="td"
                        sx={{
                          ...styles.clientAssignedBy,
                          color: logDetail?.user?.isSuperAdmin ? (theme) => theme.palette.primary.main : '#3C4043',
                        }}>
                        {logDetail?.user?.isSuperAdmin ? (
                          t('trans.super_admin')
                        ) : (
                          <Scrollbars
                            style={{
                              height: '40px',
                              width: '100%',
                              padding: '0px',
                              color:
                                clientsAssignedBy[index] === 'No client found'
                                  ? '#A8AEB1'
                                  : theme.palette.mode === 'dark'
                                  ? '#FFFFFF'
                                  : '#3C4043',
                            }}
                            autoHide>
                            {clientsAssignedBy[index]}
                          </Scrollbars>
                        )}
                      </TableCell>
                      {/* Role */}
                      <TableCell
                        component="td"
                        sx={{
                          ...styles.clientAssignedBy,
                          color: logDetail?.user?.isSuperAdmin ? (theme) => theme.palette.primary.main : '#3C4043',
                        }}>
                        {logDetail?.user?.isSuperAdmin ? (
                          t('trans.super_admin')
                        ) : (
                          <Scrollbars
                            style={{
                              height: '40px',
                              width: '100%',
                              padding: '0px',
                              color:
                                rolesAssignedBy[index] === 'No role found'
                                  ? '#A8AEB1'
                                  : theme.palette.mode === 'dark'
                                  ? '#FFFFFF'
                                  : '#3C4043',
                            }}
                            autoHide>
                            {rolesAssignedBy[index] === 'No role found'
                              ? t('trans.no_role_found')
                              : rolesAssignedBy[index]}
                          </Scrollbars>
                        )}
                      </TableCell>
                      <TableCell
                        component="td"
                        sx={{
                          ...styles.clientUpdateTime,
                        }}>
                        {logDetail.ipAddress}
                      </TableCell>
                      <TableCell
                        component="td"
                        sx={{
                          ...styles.clientUpdateTime,
                        }}>
                        {logDetail.duration === 0 ? '-' : `${logDetail.duration}`}
                      </TableCell>
                      <TableCell
                        component="td"
                        sx={{
                          ...styles.clientUpdateTime,
                        }}>
                        {getDate(logDetail.createdAt)}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="body1" color="textSecondary">
                      {t('dashboard.no_data_found_or_no_permission')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {dataValue && (
        <Box
          sx={{
            ...styles.clientShowTotal,
          }}>
          {isUsingJapanese ? (
            <Typography>
              {dataValue.total} 件のクライアントのうち {indexStartPage} ～ {indexEndPage} 件を表示
            </Typography>
          ) : (
            <Typography>
              Showing {indexStartPage} to {indexEndPage} of {dataValue.total} records
            </Typography>
          )}
          <Stack spacing={2}>
            <Pagination
              sx={{
                '& .MuiPagination-ul': {
                  '& .Mui-selected': {
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: 'white',
                  },
                  '& .Mui-selected:hover': {
                    backgroundColor: '#23BE6AB0',
                  },
                  '& .MuiPaginationItem-icon': {
                    color: (theme) => theme.palette.primary.main,
                  },
                },
              }}
              count={pageCount}
              defaultPage={1}
              page={page}
              onChange={(_, value) => {
                setPage(value);
                //scroll to titleDialog
                titleDialog.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default AuditTrail;
