import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDataInterval, IRange } from 'common/defines/clients';
import { RootState } from 'store/store';

interface IUserSlice {
  dataTreeHeightInterval: IDataInterval;
  isShowAllLayer: boolean;
  chartDataTreeHeightPreview: any;
}

const initialState: IUserSlice = {
  dataTreeHeightInterval: {
    _id: '',
    isDefault: true,
    range: [],
  },
  isShowAllLayer: true,
  chartDataTreeHeightPreview: null,
};

export const treeHeightAnalyticsSlice = createSlice({
  name: 'treeHeightAnalytic',
  initialState,
  reducers: {
    clearTreeHeightAnalytics: () => {
      return initialState;
    },
    changeVisibleIntervalTreeHeight: (state, action: PayloadAction<number>) => {
      state.dataTreeHeightInterval.range = state.dataTreeHeightInterval.range.map((item) =>
        item.key === action.payload ? { ...item, visible: !item.visible } : item
      );
      state.isShowAllLayer = state.dataTreeHeightInterval.range.every((item) => item.visible);
    },
    changeVisibleIntervalTreeHeightAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataTreeHeightInterval.range = state.dataTreeHeightInterval.range.map((range) => ({
        ...range,
        visible: action.payload,
      }));
    },
    changeDataTreeHeightInterval: (state, action: PayloadAction<Partial<IDataInterval>>) => {
      state.dataTreeHeightInterval = { ...state.dataTreeHeightInterval, ...action.payload };
    },
    changeChartDataTreeHeightPreview: (state, action: PayloadAction<{ range: IRange[] } | null>) => {
      if (!action.payload) return;
      state.dataTreeHeightInterval.range = action.payload.range.map((item, index) => ({ ...item, key: index + 1 }));
    },
  },
});

export const {
  clearTreeHeightAnalytics,
  changeDataTreeHeightInterval,
  changeChartDataTreeHeightPreview,
  changeVisibleIntervalTreeHeight,
  changeVisibleIntervalTreeHeightAllLayers,
} = treeHeightAnalyticsSlice.actions;

export const treeHeightAnalyticsSelector = (state: RootState) => state.treeHeightAnalytic;

export default treeHeightAnalyticsSlice.reducer;
