import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import { useQuery } from 'react-query';
import { getWorkspaceCircumferenceLabel, getWorkspaceCrownAreaLabel } from 'services/workspaces';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

const useDataLabel = () => {
  const { analysisId, selectedAnalysisName } = useAppSelector(tilingMapSelector);

  const generateGetWorkspaceLabelApi = () => {
    switch (selectedAnalysisName) {
      case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
        return getWorkspaceCrownAreaLabel(analysisId);
      case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
      default:
        return getWorkspaceCircumferenceLabel(analysisId);
    }
  };

  const { data: dataLabel } = useQuery(
    [QUERY_KEY.WORKSPACE_ANALYSIS_LABEL, analysisId],
    () => generateGetWorkspaceLabelApi(),
    {
      enabled: !!analysisId,
    }
  );

  return dataLabel;
};

export default useDataLabel;
