import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { circle, point } from '@turf/turf';
import { IStandPointData, ISwitchItem, ISwitchItemPayload, StandPointKeyEnum } from 'common/defines/analytic';
import { sizeByZoomLevel } from 'common/utils/util';
import update from 'immutability-helper';
import { RootState } from 'store/store';

interface IDrawCrownArea {
  isShow: boolean;
  isLockMap: boolean;
  crownAreaEditedDetail: IStandPointData | undefined;
  geometry: GeoJSON.Geometry | undefined;
  editedId: string;
}

interface IUserSlice {
  dataCrownArea: any;
  dataCrownAreaPolygon: any;
  dataCrownAreaInterval: any;
  isShowAllLayer: boolean;
  dataGeoJsonPoint: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  dataGeoJsonPolygon: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  zoomLayer: number;
  sizeFixedPoint: number;
  chartDataCrownAreaPreview: any;
  switchCrownAreaList: ISwitchItem[];
  drawCrownArea: IDrawCrownArea;
}

const initialState: IUserSlice = {
  dataCrownArea: null,
  dataCrownAreaPolygon: [],
  dataCrownAreaInterval: null,
  isShowAllLayer: true,
  dataGeoJsonPoint: undefined,
  dataGeoJsonPolygon: undefined,
  zoomLayer: 16,
  sizeFixedPoint: 0.0015,
  chartDataCrownAreaPreview: null,
  switchCrownAreaList: [
    { key: StandPointKeyEnum.STAND_COUNT, label: 'Stand Count', visible: true, color: '#008000' },
    { key: StandPointKeyEnum.CROWN_AREA, label: 'Crown', visible: true, color: '#FFA500' },
  ],
  drawCrownArea: {
    isShow: false,
    isLockMap: false,
    crownAreaEditedDetail: undefined,
    geometry: undefined,
    editedId: '',
  },
};

export const crownAreaAnalyticSlice = createSlice({
  name: 'crownAreaAnalytic',
  initialState,
  reducers: {
    clearCrownAreaAnalytics: () => {
      return initialState;
    },
    changeDataCrownAreaAnalytics: (state, action: PayloadAction<any>) => {
      state.dataCrownArea = action.payload;
      if (!state.dataCrownAreaInterval || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((crownAreaInstance: any) => {
          let color = 'black';
          const crownAreaInterval = state.dataCrownAreaInterval?.range?.find(
            (item: any, index: number) =>
              item.from <= crownAreaInstance.area &&
              (index === state.dataCrownAreaInterval?.range?.length - 1
                ? item.to >= crownAreaInstance.area
                : item.to > crownAreaInstance.area)
          );
          if (crownAreaInterval?.visible) {
            color = crownAreaInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(crownAreaInstance.longX || 0), Number(crownAreaInstance.latY || 0)], {
              ...crownAreaInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeDataCrownAreaInterval: (state, action: PayloadAction<any>) => {
      state.dataCrownAreaInterval = action.payload;
      if (state.dataCrownAreaPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataCrownAreaPolygon.map((crownAreaInstance: any) => {
            let color = 'black';
            const crownAreaInterval = state.dataCrownAreaInterval.range?.find(
              (item: any, index: number) =>
                item.from <= crownAreaInstance.area &&
                (index === state.dataCrownAreaInterval?.range?.length - 1
                  ? item.to >= crownAreaInstance.area
                  : item.to > crownAreaInstance.area)
            );
            if (crownAreaInterval?.visible) {
              color = crownAreaInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...crownAreaInstance.geometry },
              properties: {
                ...crownAreaInstance.properties,
                color: color,
                _id: crownAreaInstance._id,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
      if (!state.dataCrownArea || !action.payload) return;
      state.dataGeoJsonPoint = {
        type: 'FeatureCollection' as any,
        features: state.dataCrownArea.map((crownAreaInstance: any) => {
          let color = 'black';
          const crownAreaInterval = state.dataCrownAreaInterval?.range?.find(
            (item: any, index: number) =>
              item.from <= crownAreaInstance.area &&
              (index === state.dataCrownAreaInterval?.range?.length - 1
                ? item.to >= crownAreaInstance.area
                : item.to > crownAreaInstance.area)
          );
          if (crownAreaInterval?.visible) {
            color = crownAreaInterval.color;
          } else color = 'transparent';

          return circle(
            point([Number(crownAreaInstance.longX || 0), Number(crownAreaInstance.latY || 0)], {
              ...crownAreaInstance,
              color,
            }),
            state.sizeFixedPoint,
            { steps: 32 }
          );
        }),
      };
    },
    changeVisibleIntervalCrownArea: (state, action: PayloadAction<number>) => {
      const currentInterval = state.chartDataCrownAreaPreview
        ? state.chartDataCrownAreaPreview.intervalLimit
        : state.dataCrownAreaInterval;
      state.isShowAllLayer = currentInterval?.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      currentInterval.range = update(currentInterval?.range, {
        [action.payload]: {
          visible: { $set: !currentInterval.range[action.payload].visible },
        },
      });
      if (state.dataCrownArea) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataCrownArea.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.area &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.area
                  : item.to > vigorInstance.area)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataCrownAreaPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataCrownAreaPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.area &&
                (index === currentInterval?.range?.length - 1
                  ? item.to >= vigorInstance.area
                  : item.to > vigorInstance.area)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
                _id: vigorInstance._id,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeVisibleIntervalCrownAreaAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataCrownAreaInterval.range = update(state.dataCrownAreaInterval.range, {
        $apply: (intervalRange: Array<any>) =>
          intervalRange.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
      if (state.dataCrownArea) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataCrownArea.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataCrownAreaInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.area &&
                (index === state.dataCrownAreaInterval?.range?.length - 1
                  ? item.to >= vigorInstance.area
                  : item.to > vigorInstance.area)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
      }
      if (state.dataCrownAreaPolygon) {
        const geoJson = {
          type: 'FeatureCollection' as any,
          features: state.dataCrownAreaPolygon.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.dataCrownAreaInterval?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.area &&
                (index === state.dataCrownAreaInterval?.range?.length - 1
                  ? item.to >= vigorInstance.area
                  : item.to > vigorInstance.area)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';
            return {
              geometry: { ...vigorInstance.geometry },
              properties: {
                ...vigorInstance.properties,
                color: color,
                _id: vigorInstance._id,
              },
            };
          }),
        };
        state.dataGeoJsonPolygon = geoJson;
      }
    },
    changeDataPolygonCrownAreaAnalytics: (state, action: PayloadAction<any>) => {
      state.dataCrownAreaPolygon = action.payload;
      if (!state.dataCrownAreaInterval) {
        return;
      }
      const geoJson = {
        type: 'FeatureCollection' as any,
        features: action.payload.map((crownAreaInstance: any) => {
          let color = 'black';
          const crownAreaInterval = state.dataCrownAreaInterval.range?.find(
            (item: any, index: number) =>
              item.from <= crownAreaInstance.area &&
              (index === state.dataCrownAreaInterval?.range?.length - 1
                ? item.to >= crownAreaInstance.area
                : item.to > crownAreaInstance.area)
          );
          if (crownAreaInterval?.visible) {
            color = crownAreaInterval.color;
          } else color = 'transparent';

          return {
            geometry: { ...crownAreaInstance.geometry },
            properties: {
              ...crownAreaInstance.properties,
              color: color,
              _id: crownAreaInstance._id,
            },
          };
        }),
      };
      state.dataGeoJsonPolygon = geoJson;
    },

    changeZoomLayerCrownArea: (state, action: PayloadAction<number>) => {
      state.zoomLayer = action.payload;
      const zoomLevel = action.payload;
      const currentRange = state.chartDataCrownAreaPreview
        ? state.chartDataCrownAreaPreview.intervalLimit?.range
        : state.dataCrownAreaInterval?.range;

      if (state.dataCrownArea) {
        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataCrownArea.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = currentRange?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.area &&
                (index === currentRange?.length - 1 ? item.to >= vigorInstance.area : item.to > vigorInstance.area)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              sizeByZoomLevel(zoomLevel),
              { steps: 32 }
            );
          }),
        };
      }
    },
    changeFixedSizePointCrownArea: (state, action: PayloadAction<any>) => {
      state.sizeFixedPoint = action.payload;
    },
    changeChartDataCrownAreaPreview: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        const intervalLimit = {
          ...state.dataCrownAreaInterval,
          range: action.payload.range,
        };
        const chartData: any = [];
        action.payload.range.forEach((item: any) => {
          const listByRange = state.dataCrownArea.filter(
            (chlorophyll: any) => chlorophyll.area >= item.from && chlorophyll.area <= item.to
          );
          chartData.push({ [item.lable]: listByRange.length });
        });
        let dataPreview = { chartData, intervalLimit };

        state.chartDataCrownAreaPreview = dataPreview;

        state.dataGeoJsonPoint = {
          type: 'FeatureCollection' as any,
          features: state.dataCrownArea.map((vigorInstance: any) => {
            let color = 'black';
            const vigorInterval = state.chartDataCrownAreaPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= vigorInstance.area &&
                (index === state.chartDataCrownAreaPreview?.intervalLimit?.range?.length - 1
                  ? item.to >= vigorInstance.area
                  : item.to > vigorInstance.area)
            );
            if (vigorInterval?.visible) {
              color = vigorInterval.color;
            } else color = 'transparent';

            return circle(
              point([Number(vigorInstance.longX || 0), Number(vigorInstance.latY || 0)], {
                ...vigorInstance,
                color,
              }),
              state.sizeFixedPoint,
              { steps: 32 }
            );
          }),
        };
        state.dataGeoJsonPolygon = {
          type: 'FeatureCollection' as any,
          features: state.dataCrownAreaPolygon.map((chlorophyllInstance: any) => {
            let color = 'black';
            const chlorophyllInterval = state.chartDataCrownAreaPreview?.intervalLimit?.range?.find(
              (item: any, index: number) =>
                item.from <= chlorophyllInstance.area &&
                (index === state.chartDataCrownAreaPreview?.intervalLimit?.range?.length - 1
                  ? item.to >= chlorophyllInstance.area
                  : item.to > chlorophyllInstance.area)
            );
            if (chlorophyllInterval?.visible) {
              color = chlorophyllInterval.color;
            } else color = 'transparent';

            return {
              geometry: { ...chlorophyllInstance.geometry },
              properties: {
                ...chlorophyllInstance.properties,
                color: color,
                _id: chlorophyllInstance._id,
              },
            };
          }),
        };
      } else {
        state.chartDataCrownAreaPreview = null;
      }
    },

    changeSwitchCrownAreaListValue: (state, action: PayloadAction<ISwitchItemPayload>) => {
      const itemKey = action.payload.key;
      state.switchCrownAreaList = state.switchCrownAreaList.map((item) =>
        item.key === itemKey ? { ...item, ...action.payload } : item
      );
    },

    changeDrawCrownAreaState: (state, action: PayloadAction<Partial<IDrawCrownArea>>) => {
      state.drawCrownArea = { ...state.drawCrownArea, ...action.payload };
    },

    resetDrawCrownAreaState: (state) => {
      state.drawCrownArea = initialState.drawCrownArea;
    },
  },
});

export const {
  clearCrownAreaAnalytics,
  changeDataCrownAreaAnalytics,
  changeDataCrownAreaInterval,
  changeVisibleIntervalCrownAreaAllLayers,
  changeVisibleIntervalCrownArea,
  changeDataPolygonCrownAreaAnalytics,
  changeZoomLayerCrownArea,
  changeFixedSizePointCrownArea,
  changeChartDataCrownAreaPreview,
  changeSwitchCrownAreaListValue,
  changeDrawCrownAreaState,
  resetDrawCrownAreaState,
} = crownAreaAnalyticSlice.actions;

export const crownAreaAnalyticsSelector = (state: RootState) => state.crownAreaAnalyticSlice;

export default crownAreaAnalyticSlice.reducer;
