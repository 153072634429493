import { Box, Button, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { FC } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeDropMarkerInfo } from 'store/slices/rightBarSlice';

interface DropMarkerModalProps {
  isShow: boolean;
  handleClose: () => void;
}

const DropMarkerModal: FC<DropMarkerModalProps> = ({ isShow, handleClose }) => {
  const dispatch = useAppDispatch();

  const handleDropMarker = () => {
    handleClose();
    dispatch(changeDropMarkerInfo({ isShow: true, coordinates: [] }));
  };

  return (
    <CommonModal isShow={isShow} handleClose={handleClose}>
      <Box sx={{ width: '440px', p: '16px' }}>
        <Typography>
          The uploaded image doesn't have information of location, you have to drop a point in map to locate this image.
        </Typography>
        <Box sx={{ textAlign: 'right', mt: '8px' }}>
          <Button variant="outlined" size="small" onClick={() => handleDropMarker()}>
            Yes
          </Button>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default DropMarkerModal;
