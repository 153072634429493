import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';
interface IUserSlice {
  dataWeedInvasion: any;
  dataWeedInvasionInterval: any;
  isShowAllLayer: boolean;
}

const initialState: IUserSlice = {
  dataWeedInvasion: null,
  dataWeedInvasionInterval: null,
  isShowAllLayer: true,
};

export const weedInvasionAnalyticsSlice = createSlice({
  name: 'weedInvasionAnalytic',
  initialState,
  reducers: {
    clearWeedInvasionAnalytics: () => {
      return initialState;
    },
    changeDataWeedInvasionAnalytics: (state, action: PayloadAction<any>) => {
      state.dataWeedInvasion = action.payload;
    },
    changeDataWeedInvasionInterval: (state, action: PayloadAction<any>) => {
      state.dataWeedInvasionInterval = action.payload;
    },
    changeVisibleIntervalWeedInvasion: (state, action: PayloadAction<number>) => {
      state.isShowAllLayer = state.dataWeedInvasionInterval.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataWeedInvasionInterval.range = update(state.dataWeedInvasionInterval.range, {
        [action.payload]: {
          visible: { $set: !state.dataWeedInvasionInterval.range[action.payload].visible },
        },
      });
    },
    changeVisibleIntervalWeedInvasionAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataWeedInvasionInterval.range = update(state.dataWeedInvasionInterval.range, {
        $apply: (intervalRange: Array<any>) =>
          intervalRange.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },
  },
});

export const {
  clearWeedInvasionAnalytics,
  changeDataWeedInvasionAnalytics,
  changeDataWeedInvasionInterval,
  changeVisibleIntervalWeedInvasion,
  changeVisibleIntervalWeedInvasionAllLayers,

} = weedInvasionAnalyticsSlice.actions;

export const weedInvasionAnalyticsSelector = (state: any) => state.weedInvasionAnalytic;

export default weedInvasionAnalyticsSlice.reducer;
