import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ModalMessageInUserManagement, TypeModalDelete } from 'common/defines/clients';
import { useState } from 'react';

type ClientActionsDialogProps = {
  title: string;
  description?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onSave: () => void;
  cancelLabel?: string;
  saveLabel?: string;
  submit: boolean;
  clientAt?: TypeModalDelete;
  clientName?: string;
  userManagementAction?: ModalMessageInUserManagement;
} & DialogProps;

export const ClientActionsDialog = ({
  title,
  description,
  open,
  onClose,
  onCancel,
  onSave,
  cancelLabel = 'Cancel',
  saveLabel = 'Delete',
  submit,
  clientAt,
  clientName,
  userManagementAction,
  ...props
}: ClientActionsDialogProps) => {
  const [deactiveButton, setDeactiveButton] = useState(true);
  const ButtonSubmit = styled(Button)((a) => {
    return {
      minWidth: '240px',
      height: '40px',
      borderRadius: '5px',
      padding: '12px 10px',
      marginBottom: '30px',
      textTransform: 'none',
      border: submit ? `1px solid ${theme.palette.primary.main}` : '1px solid #DA3E3E',
      color: 'white',
      backgroundColor: submit ? `${theme.palette.primary.main}` : '#EF4444',

      '&:hover': {
        backgroundColor: submit ? '#1C9D57' : '#DA3E3E',
        border: submit ? `1px solid ${theme.palette.primary.main}` : '1px solid #DA3E3E',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: submit ? '#1C9D57' : '#DA3E3E',
        border: submit ? `1px solid ${theme.palette.primary.main}` : '1px solid #DA3E3E',
      },
      '&:focus': {
        boxShadow: submit ? '0 0 0 0.1rem rgba(70, 255, 70, 0.15)' : '0 0 0 0.1rem rgba(255, 70, 70, 0.15)',
        border: submit ? `1px solid ${theme.palette.primary.main}` : '1px solid #DA3E3E',
      },
    };
  });

  const theme = useTheme();

  const styles = {
    modalStyles: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      bgColor: theme.palette.background.paper,
    },
    btnCloseModal: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: '#9E9E9E',
      maxWidth: '60px',
      p: '10px 0',
    },
    iconWarningModal: {
      width: '50px',
      height: '50px',
      mt: '40px',
      borderRadius: '50%',
      alignSelf: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleModal: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#5E5873',
      pb: '31px',
    },
    descriptionModal: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#737373',
    },
  };

  const handleIOnValidateName = (val: string) => {
    if (clientAt === TypeModalDelete.USER_MANAGEMENT && val === clientName) {
      return setDeactiveButton(false);
    }
    setDeactiveButton(true);
  };

  const handleSubmitDialog = () => {
    onSave();
  };

  return (
    <Dialog
      {...props}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm">
      <Box
        sx={{
          ...styles.modalStyles,
          background: theme.palette.background.paper,
        }}>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            p: 0,
            position: 'absolute',
            right: 8,
            top: 8,
            '.MuiSvgIcon-root': {
              width: '16px',
              height: '16px',
            },
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            ...styles.iconWarningModal,
            color: submit ? (theme) => theme.palette.primary.main : '#DA3E3E',
            backgroundColor: submit ? 'rgba(35, 190, 106, 0.15)' : 'rgba(255, 70, 70, 0.15)',
          }}>
          {submit ? (
            <CheckCircleOutlineRoundedIcon
              sx={{
                alignSelf: 'center',
                fontSize: '26px',
              }}
            />
          ) : (
            <ReportProblemRoundedIcon
              sx={{
                alignSelf: 'center',
                fontSize: '26px',
              }}
            />
          )}
        </Typography>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            ...styles.titleModal,
            background: theme.palette.background.paper,
          }}>
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            background: theme.palette.background.paper,
          }}>
          {clientAt ? (
            userManagementAction === ModalMessageInUserManagement.DELETE ? (
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  ...styles.descriptionModal,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}>
                {description || 'Can not add a duplicate field'}
                {clientAt && (
                  <TextField
                    size="small"
                    sx={{ width: '100%', mt: 2.5 }}
                    placeholder={clientName}
                    onChange={(e) => handleIOnValidateName(e.target.value)}
                  />
                )}
              </DialogContentText>
            ) : (
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  ...styles.descriptionModal,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}>
                {description || 'Can not add a duplicate field'}
              </DialogContentText>
            )
          ) : (
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                ...styles.descriptionModal,
              }}>
              <Typography component="span">{description || 'Can not add a duplicate field'}</Typography>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            background: theme.palette.background.paper,
          }}>
          {onCancel && (
            <Button
              color="neutral"
              variant="outlined"
              sx={{
                minWidth: '240px',
                height: '40px',
                borderRadius: '5px',
                p: '12px 10px',
                mb: '30px',
                textTransform: 'none',
                border: `1px solid ${theme.palette.divider}`,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              }}
              onClick={onCancel}>
              {cancelLabel}
            </Button>
          )}
          <ButtonSubmit
            disabled={clientAt && userManagementAction === ModalMessageInUserManagement.DELETE ? deactiveButton : false}
            onClick={() => handleSubmitDialog()}>
            {saveLabel}
          </ButtonSubmit>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
