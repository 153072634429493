import { makeStyles } from '@mui/styles';

export const useCropTypeSettingStyles = makeStyles((theme: any) => {
  return {
    cropTypeSettings: {
      width: '100%',
    },
    cropTypeSettingsBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    controlBox: {
      display: 'flex',
      alignItems: 'center',
    },
    selectShow: {
      height: '40px',
      border: 'none',
      width: '65px',
    },
    addCropTypeBtn: {
      width: '160px',
      height: '40px',
      border: `1px solid ${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: 'white !important',
      '&:hover': {
        backgroundColor: '#1C9D57 !important',
        border: `1px solid ${theme.palette.primary.main} !important`,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#1C9D57 !important',
        border: `1px solid ${theme.palette.primary.main} !important`,
      },
      '&:focus': {
        boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15) !important',
        border: `1px solid ${theme.palette.primary.main} !important`,
      },
    },

    // Table
    tableBox: {
      minWidth: 650,
    },
    tableRowTitle: {
      backgroundColor: '#F7F8F8',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tableRowCpn: {
      height: '70px',
      minHeight: '70px',
    },
    cropTypeNameContainer: {
      display: 'flex !important',
      alignItems: 'center',
      width: '100%',
      flex: '1',
      overflow: 'hidden',
      marginRight: '20px',
      height: '100%',
      minHeight: '70px',
    },
    clientActionsIcon: {
      height: '16px !important',
      width: '16px !important',
      color: '#9F9F9F',
    },
    clientActionsIConSearch: {
      height: '24px',
      width: '24px',
    },

    tableSearch: {
      marginRight: '8px !important',
      width: '340px',
      minWidth: '340px !important',
      height: '40px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .MuiOutlinedInput-root': {
        height: '40px',
      },
      border: `1px solid ${theme.palette.divider} !important`,
      borderRadius: '5px',
    },

    dataViewIcon: {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      border: '2px solid white',
      // backgroundColor: '#059669',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '10px',
    },

    // Pagination
    paginationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
    },

    paginationBox: {
      '& .MuiPagination-ul': {
        '& .Mui-selected': {
          backgroundColor: `${theme.palette.primary.main} `,
          color: 'white',
        },
        '& .Mui-selected:hover': {
          backgroundColor: `${theme.palette.primary.main} `,
          opacity: '0.6',
        },
        '& .MuiPaginationItem-icon': {
          color: `${theme.palette.primary.main} `,
        },
      },
    },

    // MarginLeft
    marginLeft: {
      marginLeft: '10px',
    },
  };
});
