import { Box, FormControlLabel, InputLabel, Typography } from '@mui/material';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import PieChartCommon from 'components/Common/PieChartCommon';
import SkeletonCharts from 'components/Common/SkeletonCharts';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useClientSettingsStyle } from 'components/Dashboard/ClientSettings/ClientSettingsStyle';
import { QUERY_KEY } from 'constants/constants';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  getBufferSizeVegetationEncroachmentAnalytic,
  getSizeVegetationEncroachmentAnalytic,
  getVegetationEncroachmentAnalytic,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeBufferSizeVegetationEncroachmentSelected,
  changeSizeVegetationEncroachmentList,
  mapViewSelector,
  setDataVegetationEncroachment,
} from 'store/slices/mapViewSlice';
import VegetationEncroachmentCardLayer from '../../Layer/VegetationEncroachment';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import InfoTable from '../CropCover/InfoTable';
import { combineVegetationEncroachmentData } from './utils/combine';

const VegetationEncroachmentAnalytics = () => {
  const dispatch = useAppDispatch();
  const [chartMode, setChartMode] = useState(false);
  const classes = useClientSettingsStyle();
  const [listColors, setListColors] = useState<string[]>([]);

  const {
    analyticId,
    sizeVegetationEncroachmentSelected,
    dataVegetationEncroachment,
    sizeVegetationEncroachmentList,
    bufferSizeVegetationEncroachmentSelected,
  } = useAppSelector(mapViewSelector);

  useQuery(
    [QUERY_KEY.SIZE_VEGETATION_ENCROACHMENT_ANALYTIC, analyticId, sizeVegetationEncroachmentList],
    () => getSizeVegetationEncroachmentAnalytic(analyticId || ''),
    {
      enabled: !!analyticId && isEmpty(sizeVegetationEncroachmentList),
      onSuccess: (response) => {
        if (response.data) {
          dispatch(changeSizeVegetationEncroachmentList(response.data));
        }
      },
    }
  );

  useQuery(
    [QUERY_KEY.BUFFER_SIZE_VEGETATION_ENCROACHMENT_ANALYTIC, analyticId],
    () => getBufferSizeVegetationEncroachmentAnalytic(analyticId || ''),
    {
      enabled: !!analyticId,
      onSuccess: (response) => {
        if (response.data) {
          if (!bufferSizeVegetationEncroachmentSelected) {
            dispatch(changeBufferSizeVegetationEncroachmentSelected(response.data?.[0]));
          }
        }
      },
    }
  );

  useQuery(
    [
      QUERY_KEY.VEGETATION_ENCROACHMENT_ANALYTIC,
      analyticId,
      sizeVegetationEncroachmentSelected,
      bufferSizeVegetationEncroachmentSelected,
    ],
    () =>
      getVegetationEncroachmentAnalytic(
        analyticId || '',
        sizeVegetationEncroachmentSelected || 300,
        bufferSizeVegetationEncroachmentSelected || 10
      ),
    {
      enabled: !!analyticId && !!sizeVegetationEncroachmentSelected,
      onSuccess(res) {
        const resData = res.data;
        const dataCropType = combineVegetationEncroachmentData(resData).map((item: any) => {
          return {
            ...item,
            visible: true,
          };
        });
        setListColors(dataCropType.map((item) => item.color));
        dispatch(setDataVegetationEncroachment(dataCropType));
      },
    }
  );

  const dataPie = useMemo(() => {
    if (dataVegetationEncroachment) {
      return dataVegetationEncroachment.map((_item: any) => ({
        name: _item.type,
        value: _item.lduseArea,
        totalTree: _item.totalTree,
      }));
    }
    return [];
  }, [dataVegetationEncroachment]);

  const getDataChart = useMemo(
    () =>
      dataVegetationEncroachment?.map((_item: any) => ({
        name: _item.type,
        value: chartMode ? _item.totalTree : _item.lduseArea,
      })),
    [chartMode, dataVegetationEncroachment]
  );

  const hasTreeCount = !!(
    dataVegetationEncroachment?.length && dataVegetationEncroachment.some((item: any) => item.totalTree > 0)
  );

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT}
      analyticTab={
        <>
          {dataVegetationEncroachment?.length > 0 ? (
            <>
              <PieChartCommon title="Vegetation Encroachment" dataChart={getDataChart} colorCharts={listColors} />
              {hasTreeCount && (
                <>
                  <InputLabel sx={{ marginLeft: '25px', fontSize: '14px' }}>Chart Mode:</InputLabel>
                  <FormControlLabel
                    sx={{ marginLeft: '30px' }}
                    control={
                      <SwitchCustom
                        checked={chartMode}
                        onClick={() => {
                          setChartMode(!chartMode);
                        }}
                      />
                    }
                    label={<Typography className={classes.textSetting}>{chartMode ? 'Tree Count' : 'Area'}</Typography>}
                  />
                </>
              )}
              <Box component={'div'} sx={{ marginTop: '30px' }}>
                <InfoTable dataPie={dataPie} hasTreeCount={hasTreeCount} />
              </Box>
            </>
          ) : (
            <>
              <SkeletonCharts count={1} />
            </>
          )}
        </>
      }
      layerTab={<VegetationEncroachmentCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT} />}
      isShowDownloadButton={dataVegetationEncroachment?.length > 0}
    />
  );
};

export default VegetationEncroachmentAnalytics;
