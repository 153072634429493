export const GroupIcon = ({ isSelected }: any) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={isSelected ? '#23BE6A' : '#737373'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49984 3.20923C5.19865 3.20923 3.33317 5.07471 3.33317 7.3759C3.33317 9.67708 5.19865 11.5426 7.49984 11.5426C9.80102 11.5426 11.6665 9.67708 11.6665 7.3759C11.6665 5.07471 9.80102 3.20923 7.49984 3.20923ZM4.99984 7.3759C4.99984 5.99518 6.11913 4.8759 7.49984 4.8759C8.88055 4.8759 9.99984 5.99518 9.99984 7.3759C9.99984 8.75661 8.88055 9.8759 7.49984 9.8759C6.11913 9.8759 4.99984 8.75661 4.99984 7.3759Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
      <path
        d="M14.0902 7.55779C13.8557 7.43826 13.5963 7.37595 13.3332 7.37595V5.70928C13.8595 5.70928 14.3784 5.83386 14.8473 6.07294C14.8989 6.09927 14.9498 6.1269 14.9998 6.15581C15.4043 6.38934 15.7558 6.70564 16.0309 7.08469C16.3401 7.51066 16.5439 8.00379 16.6259 8.5237C16.7078 9.04362 16.6654 9.57554 16.5023 10.0759C16.3391 10.5763 16.0598 11.031 15.6871 11.4027C15.3144 11.7744 14.8591 12.0525 14.3582 12.2144C13.9126 12.3584 13.4422 12.4067 12.9778 12.3569C12.9203 12.3507 12.8629 12.3431 12.8057 12.3339C12.2863 12.2507 11.7941 12.0457 11.3691 11.7358L11.3682 11.7352L12.3507 10.3889C12.5633 10.544 12.8096 10.6465 13.0694 10.6882C13.3293 10.7298 13.5953 10.7093 13.8457 10.6284C14.0961 10.5475 14.3238 10.4084 14.5101 10.2226C14.6964 10.0367 14.8361 9.80943 14.9177 9.55924C14.9993 9.30904 15.0204 9.04309 14.9795 8.78314C14.9385 8.52319 14.8366 8.27663 14.682 8.06366C14.5274 7.85068 14.3246 7.67733 14.0902 7.55779Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
      <path
        d="M16.6649 18.2092C16.6649 17.7717 16.5787 17.3384 16.4113 16.9342C16.2439 16.53 15.9985 16.1627 15.6891 15.8533C15.3797 15.5439 15.0124 15.2985 14.6082 15.1311C14.204 14.9637 13.7707 14.8775 13.3332 14.8775V13.2092C13.9016 13.2092 14.4652 13.3061 14.9998 13.4952C15.0828 13.5245 15.1651 13.5561 15.2466 13.5898C15.8532 13.8411 16.4044 14.2094 16.8687 14.6737C17.333 15.138 17.7013 15.6892 17.9526 16.2958C17.9863 16.3773 18.0179 16.4596 18.0472 16.5426C18.2363 17.0772 18.3332 17.6408 18.3332 18.2092H16.6649Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
      <path
        d="M13.3332 18.2092H11.6665C11.6665 15.908 9.80102 14.0426 7.49984 14.0426C5.19865 14.0426 3.33317 15.908 3.33317 18.2092H1.6665C1.6665 14.9876 4.27818 12.3759 7.49984 12.3759C10.7215 12.3759 13.3332 14.9876 13.3332 18.2092Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
    </svg>
  );
};
