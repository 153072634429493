import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { listOfApi } from 'constants/constants';
import React from 'react';

const PermissionPage = () => {
  const theme = useTheme();
  return (
    <Box>
      <TableContainer sx={{ overflow: 'hidden' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F2F2F2',
                borderBottom: `1px solid ${theme.palette.divider}`,
                display: 'flex',
              }}>
              <TableCell
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  flex: 1,
                  padding: '16px 0 16px 16px',
                }}>
                <Typography
                  sx={{
                    fontWeight: '500',
                  }}>
                  API Type
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  flex: 2,
                  padding: '16px 0',
                }}>
                <Typography
                  sx={{
                    fontWeight: '500',
                  }}>
                  API Name
                </Typography>
              </TableCell>
              <TableCell sx={{ flex: 4 }}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              pt: 0,
              flexDirection: 'column',
              minHeight: '510px',
              maxHeight: '510px',
              overflowY: 'scroll',
              '-ms-overflow-style': 'none' /* IE and Edge */,
              scrollbarWidth: 'none' /* Firefox */,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
            {listOfApi.map((api) => {
              return (
                <TableRow
                  key={api.Type}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                  }}>
                  <TableCell
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: 'none',
                      flex: 1,
                      padding: 0,
                      borderRight: `1px solid ${theme.palette.divider}`,
                      '&:last-child': {
                        borderRight: 'none',
                      },
                    }}
                    component="td">
                    <Typography
                      sx={{
                        fontWeight: '500',
                      }}>
                      {api.Type}
                    </Typography>
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      justifyContent: 'center',
                      border: 'none',
                      flex: 2,
                      borderRight: `1px solid ${theme.palette.divider}`,
                      padding: 0,
                      width: '100%',
                    }}>
                    {api.API.map((apiName) => {
                      return (
                        <Typography
                          sx={{
                            minHeight: '40px',
                            lineHeight: '40px',
                            width: '100%',
                            padding: '0 12px',
                            overflowX: 'hidden',
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            '&:last-child': {
                              borderBottom: 'none',
                            },
                          }}>
                          {apiName.name}
                        </Typography>
                      );
                    })}
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      flex: 4,
                      border: 'none',
                      padding: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      justifyContent: 'center',
                    }}>
                    {api.API.map((apiName) => {
                      return (
                        <Typography
                          sx={{
                            minHeight: '40px',
                            lineHeight: '40px',
                            width: '100%',
                            padding: '0 12px',
                            overflowX: 'hidden',
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            '&:last-child': {
                              borderBottom: 'none',
                            },
                          }}>
                          {apiName?.description}
                        </Typography>
                      );
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PermissionPage;
