import React, { useState } from 'react';
import VerifyEmail from './AddEmailForm';
import CheckEmail from './CheckEmail';

const AddEmail = () => {
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [dataEmail, setDataEmail] = useState('');
  return isSendEmail ? (
    <CheckEmail data={dataEmail} />
  ) : (
    <VerifyEmail setIsSendEmail={setIsSendEmail} setEmail={setDataEmail} />
  );
};

export default AddEmail;
