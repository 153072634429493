export const HomeIcon = ({ isSelected }: any) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill={isSelected ? '#23BE6A' : '#737373'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25391 1.5H7.50391C7.95391 1.5 8.25391 1.8 8.25391 2.25V7.5C8.25391 7.95 7.95391 8.25 7.50391 8.25H2.25391C1.80391 8.25 1.50391 7.95 1.50391 7.5V2.25C1.50391 1.8 1.80391 1.5 2.25391 1.5ZM3.00391 6.75H6.75391V3H3.00391V6.75ZM15.7539 1.5H10.5039C10.0539 1.5 9.75391 1.8 9.75391 2.25V7.5C9.75391 7.95 10.0539 8.25 10.5039 8.25H15.7539C16.2039 8.25 16.5039 7.95 16.5039 7.5V2.25C16.5039 1.8 16.2039 1.5 15.7539 1.5ZM11.2539 6.75H15.0039V3H11.2539V6.75ZM15.7539 9.75H10.5039C10.0539 9.75 9.75391 10.05 9.75391 10.5V15.75C9.75391 16.2 10.0539 16.5 10.5039 16.5H15.7539C16.2039 16.5 16.5039 16.2 16.5039 15.75V10.5C16.5039 10.05 16.2039 9.75 15.7539 9.75ZM11.2539 15H15.0039V11.25H11.2539V15ZM7.50391 9.75H2.25391C1.80391 9.75 1.50391 10.05 1.50391 10.5V15.75C1.50391 16.2 1.80391 16.5 2.25391 16.5H7.50391C7.95391 16.5 8.25391 16.2 8.25391 15.75V10.5C8.25391 10.05 7.95391 9.75 7.50391 9.75ZM3.00391 15H6.75391V11.25H3.00391V15Z"
        fill={isSelected ? '#23BE6A' : '#737373'}
      />
      <mask id="mask0_5849_10357" maskUnits="userSpaceOnUse" x="1" y="1" width="16" height="16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25391 1.5H7.50391C7.95391 1.5 8.25391 1.8 8.25391 2.25V7.5C8.25391 7.95 7.95391 8.25 7.50391 8.25H2.25391C1.80391 8.25 1.50391 7.95 1.50391 7.5V2.25C1.50391 1.8 1.80391 1.5 2.25391 1.5ZM3.00391 6.75H6.75391V3H3.00391V6.75ZM15.7539 1.5H10.5039C10.0539 1.5 9.75391 1.8 9.75391 2.25V7.5C9.75391 7.95 10.0539 8.25 10.5039 8.25H15.7539C16.2039 8.25 16.5039 7.95 16.5039 7.5V2.25C16.5039 1.8 16.2039 1.5 15.7539 1.5ZM11.2539 6.75H15.0039V3H11.2539V6.75ZM15.7539 9.75H10.5039C10.0539 9.75 9.75391 10.05 9.75391 10.5V15.75C9.75391 16.2 10.0539 16.5 10.5039 16.5H15.7539C16.2039 16.5 16.5039 16.2 16.5039 15.75V10.5C16.5039 10.05 16.2039 9.75 15.7539 9.75ZM11.2539 15H15.0039V11.25H11.2539V15ZM7.50391 9.75H2.25391C1.80391 9.75 1.50391 10.05 1.50391 10.5V15.75C1.50391 16.2 1.80391 16.5 2.25391 16.5H7.50391C7.95391 16.5 8.25391 16.2 8.25391 15.75V10.5C8.25391 10.05 7.95391 9.75 7.50391 9.75ZM3.00391 15H6.75391V11.25H3.00391V15Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5849_10357)">
        <rect x="0.00390625" width="18" height="18" fill={isSelected ? '#23BE6A' : '#737373'} />
      </g>
    </svg>
  );
};
