import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { IPoint } from '../../../common/entity/IPoint';

interface IStandCountAnalyticSlice {
  point: IPoint | null;
  isShowPopupAdd: boolean;
  isLoading: boolean;
  dataPointClicked: any;
  dataPointPolygon: Array<any>;
  isPointerMode: boolean;
  isPolygonMode: boolean;
}

const initialState: IStandCountAnalyticSlice = {
  point: null,
  isShowPopupAdd: false,
  isLoading: false,
  dataPointClicked: undefined,
  dataPointPolygon: [],
  isPointerMode: false,
  isPolygonMode: false,
};

export const standCountAnalyticSlice = createSlice({
  name: 'standCountAnalytic',
  initialState,
  reducers: {
    clearStandCountAnalytic: (state) => {
      return { ...initialState, isShowPopupAdd: state.isShowPopupAdd };
    },
    changePointStandCount: (state, action: PayloadAction<IPoint>) => {
      state.point = action.payload;
    },
    changeVisiblePopupAdd: (state, action: PayloadAction<boolean>) => {
      state.isShowPopupAdd = action.payload;
    },
    changeStatusLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    changeDataPointClicked: (state, action: PayloadAction<any>) => {
      state.dataPointClicked = action.payload;
    },
    changeDataPointPolygon: (state, action: PayloadAction<any>) => {
      state.dataPointPolygon = action.payload;
    },
    changeIsPointerMode: (state, action: PayloadAction<any>) => {
      state.isPointerMode = action.payload;
    },
    changeIsPolygonMode: (state, action: PayloadAction<any>) => {
      state.isPolygonMode = action.payload;
    },
    clearDataPointPolygon: (state) => {
      state.dataPointPolygon = [];
    },
  },
});

export const {
  clearStandCountAnalytic,
  changePointStandCount,
  changeVisiblePopupAdd,
  changeStatusLoading,
  changeDataPointClicked,
  changeDataPointPolygon,
  changeIsPointerMode,
  changeIsPolygonMode,
  clearDataPointPolygon,
} = standCountAnalyticSlice.actions;

export const standCountAnalyticSelector = (state: RootState) => state.standCountAnalytic;

export default standCountAnalyticSlice.reducer;
