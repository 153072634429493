import { DatePicker } from '@mui/lab';
import { Dialog, DialogContent, Grid, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { dataTypeRaster } from 'common/defines/constants';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { postCreateAnalytics } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector, changePageRasterPagination } from 'store/slices/analyticsConfigSlice';

interface IDialogCreateRaster {
  levelId: string;
  onClose: () => void;
}

export const DialogCreateRaster = ({ onClose, levelId }: IDialogCreateRaster) => {
  const queryClient = useQueryClient();
  const classes = useDialogCreateStyle();
  const dispatch = useAppDispatch();
  const [typeAnalytics, setTypeAnalytics] = useState<string>(dataTypeRaster[0].label);
  const { pageRaster } = useAppSelector(anaLyticConfigSelector);
  const createAnalyticMutation = useMutation((data: any) => postCreateAnalytics(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY.SENSORS, levelId]);

      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
      toast.success('Create Raster Info Successful', { toastId: 1 });
      onClose();
    },
    onError: () => {
      toast.error('Create Raster Info Error', { toastId: 1 });
    },
  });
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog onClose={() => onClose()} open={true} className={classes.dialog} maxWidth="sm">
      <DialogContent
        sx={{
          '& .MuiDialogContent-root': {
            backgroundColor: (theme) => theme.palette.background.paper,
          },
        }}>
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            pt: 2,
            mb: 2,
          }}>
          <InputLabel variant="standard" sx={{ fontSize: '14px' }} htmlFor="uncontrolled-native">
            {t('trans.type_of_raster')}
          </InputLabel>
          <Select
            displayEmpty
            value={typeAnalytics}
            onChange={(event: { target: { value: string } }) => setTypeAnalytics(event.target.value)}
            sx={{
              width: '250px',
              height: '37px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey2),
            }}>
            {dataTypeRaster.map((data) => (
              <MenuItem value={data.label} key={data.value}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Formik
          initialValues={{ date: null, description: '' }}
          onSubmit={(values, { resetForm }) => {
            const paramData = {
              name: typeAnalytics,
              levelId,
              date: moment(values.date).format('YYYY-MM-DD'),
              description: values.description,
            };
            createAnalyticMutation.mutate(paramData, {
              onSuccess: () => {
                resetForm();
              },
            });
          }}>
          {({ values, setFieldValue, handleSubmit, submitForm, touched, errors, handleChange }) => (
            <form onSubmit={handleSubmit}>
              <Grid display="flex" alignItems="center" justifyContent="space-between">
                <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                  {t('trans.date')}
                </InputLabel>
                <DatePicker
                  value={values.date}
                  disabled={createAnalyticMutation.isLoading}
                  renderInput={(params: any) => (
                    <TextField type="text" {...params} sx={{ width: '250px !important' }} size="small" />
                  )}
                  onChange={(value: any) => setFieldValue('date', value)}
                />
              </Grid>
              <Grid display="flex" alignItems="center" justifyContent="space-between" sx={{ pt: 2 }}>
                <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                  {t('trans.description')}
                </InputLabel>
                <TextField
                  sx={{
                    width: '250px',
                    minWidth: '250px',
                    height: '40px',
                    padding: 0,
                    '.MuiInputBase-input': {
                      padding: '8px 10px !important',
                    },
                    borderRadius: '5px',
                  }}
                  fullWidth
                  id="description"
                  name="description"
                  placeholder="Description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                />
              </Grid>

              <Grid display="flex" alignItems="center" mt={4} justifyContent="center" spacing={3}>
                <ButtonCustom
                  title="Cancel"
                  border="1px solid #3C4043"
                  colorLabel="#3C4043"
                  sx={{ width: '160px', height: '1.4375em', marginRight: '20px' }}
                  backgroundColor="#FFF"
                  onClick={() => onClose()}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
                <ButtonCustom
                  title="Save"
                  border="0px"
                  colorLabel="#FFF"
                  sx={{ width: '160px', height: '1.4375em' }}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={!values.date || createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const useDialogCreateStyle = makeStyles(() => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '500px',
      padding: '10px',
    },
  },
}));
