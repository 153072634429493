import { Grid, InputLabel, styled, useTheme } from "@mui/material";
import AppLayout from "components/AppLayout";
import { ButtonCustom } from "./ButtonCustom";

const TitleLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 600,
  height: 'fit-content !important',
}));

const InfoLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
  height: 'fit-content !important',
  marginBottom: '40px',
}));

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  const theme = useTheme();
  console.log(error)
  return (
    <AppLayout>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: 'calc(100vh - 240px)' }}>
        <Grid item xs={6} textAlign="center">
          <TitleLabel>Oops </TitleLabel>
          <InfoLabel>Something went wrong.</InfoLabel>
          <Grid>
            <ButtonCustom
              backgroundColor={theme.palette.primary.main}
              colorLabel="#FFF"
              title={'Try again'}
              border={`1px solid ${theme.palette.primary.main}`}
              sx={{ ml: 3 }}
              onClick={resetErrorBoundary}
            />
          </Grid>
        </Grid>
      </Grid>
    </AppLayout >
  );
};

export default ErrorFallback;