import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeLayer3D } from 'store/slices/mapViewSlice';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';
import CircumferenceAnalytic from './components/categories/CircumferenceAnalytic';
import CrownAreaAnalytic from './components/categories/CrownAreaAnalytic';
import StandCountAnalytic from './components/categories/stand-count-analytic';

interface SwitchCategoriesProps {}

const SwitchCategories: FC<SwitchCategoriesProps> = () => {
  const { selectedAnalysisName } = useAppSelector(tilingMapSelector);
  const dispatch = useAppDispatch();

  // reset to 2d option each time change analytic
  useEffect(() => {
    dispatch(changeLayer3D(false));
  }, [dispatch, selectedAnalysisName]);

  const renderAnalyticCategory = () => {
    switch (selectedAnalysisName) {
      case TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT:
        return <StandCountAnalytic />;
      case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
        return <CrownAreaAnalytic />;
      case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
        return <CircumferenceAnalytic />;
      default:
        return null;
    }
  };

  return <>{renderAnalyticCategory()}</>;
};

export default SwitchCategories;
