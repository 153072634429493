import { Grid, Skeleton } from '@mui/material';
import { memo } from 'react';

interface ISkeletonCommon {
  count?: number;
  animation?: 'pulse' | 'wave' | false;
  heightItems?: number;
}

const SkeletonCharts = ({ count = 1, animation = 'wave', heightItems = 37 }: ISkeletonCommon) => {
  return (
    <Grid display="flex" direction="column" justifyContent={'center'} alignItems={'left'}>
      <Skeleton
        animation={animation}
        style={{ marginTop: '14px' }}
        variant="text"
        width="40%"
        height={`${heightItems}px`}
      />
      <Skeleton animation={animation} style={{ marginTop: '14px' }} variant="rectangular" width={'80%'} height={220} />
      {Array.from(Array(count).keys()).map((item) => (
        <Skeleton
          key={item}
          animation={animation}
          style={{ marginTop: '14px' }}
          variant="text"
          width="40%"
          height={`${heightItems}px`}
        />
      ))}
      <Skeleton variant="circular" width={220} height={220} style={{ marginTop: '14px' }} />
    </Grid>
  );
};

export default memo(SkeletonCharts);
