import { QUERY_KEY } from 'constants/constants';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';



const useClientDataConfig = () => {
    const { clientId } = useParams();
    const { analyticId } = useAppSelector(mapViewSelector);


    const {
        data,
        isLoading,
        refetch,
    } = useQuery([QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId, analyticId], () => getClientSettingsById(clientId || ''), {
        enabled: !!clientId && !!analyticId,
    });
    const [isFixedPoint, value]: any = useMemo(() => {
        if (!data) return [false, null]
        if (data) {
            const isFixedPointtmp = data?.sizeOfPointSetting?.isFixedPoint
            const valuetmp = data?.sizeOfPointSetting?.value > 0 ? (data?.sizeOfPointSetting?.value) / 1000 : 0
            return [isFixedPointtmp, valuetmp]
        }
    }, [data, analyticId]);

    return { isFixedPoint, value };
};

export default useClientDataConfig;
