import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComparedIndex, TypeRaster } from 'common/defines/constants';
import { DEFAULT_VIEWPORT } from 'constants/constants';
import { ViewState } from 'react-map-gl';
import type { RootState } from '../store';

export interface IRaster {
  description: string;
  name: TypeRaster;
  _id: string;
}

export interface IDateInfo {
  date: string;
  raster: IRaster;
}

interface IViewPort {
  data: Omit<ViewState, 'padding'>;
  comparedIndex: ComparedIndex;
}

interface IMapPopupSlice {
  listDateInfo: IDateInfo[];
  viewPort: IViewPort;
  mapStyle: string;
  ableToTransport: boolean;
}

const initialState: IMapPopupSlice = {
  listDateInfo: [
    {
      date: '',
      raster: {
        description: '',
        name: TypeRaster.RGB_MULTI_SPECTRAL,
        _id: '',
      },
    },
  ],
  viewPort: {
    data: {
      ...DEFAULT_VIEWPORT,
      pitch: 0,
      bearing: 0,
    },
    comparedIndex: ComparedIndex.FIRST,
  },
  ableToTransport: false,
  mapStyle: '',
};

export const mapPopupSlice = createSlice({
  name: 'mapPopup',
  initialState,
  reducers: {
    changeListDateInfo: (state, action: PayloadAction<IDateInfo[]>) => {
      state.listDateInfo = action.payload;
    },
    changeViewPort: (state, action: PayloadAction<IViewPort>) => {
      state.viewPort = action.payload;
    },
    changeMapStyle: (state, action: PayloadAction<string>) => {
      state.mapStyle = action.payload;
    },
    changeAbleTransport: (state, action: PayloadAction<boolean>) => {
      state.ableToTransport = action.payload;
    },
    clearMapPopupData: () => initialState,
  },
});

export const { changeListDateInfo, changeViewPort, changeAbleTransport, changeMapStyle, clearMapPopupData } =
  mapPopupSlice.actions;

export const mapPopupSelector = (state: RootState) => state.mapPopup;

export default mapPopupSlice.reducer;
