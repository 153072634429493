import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeShowIssueModal, rightBarSelector, updateEditIssueData } from 'store/slices/rightBarSlice';
import { IIssue } from '../../interfaces';

interface EditIssueProps {
  initialData: IIssue;
  colorByTheme?: string;
}

const EditIssue: FC<EditIssueProps> = ({ initialData, colorByTheme }) => {
  const dispatch = useAppDispatch();
  const {
    issuesTab: { drawIssueShape },
  } = useAppSelector(rightBarSelector);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('mapView.issue.edit')}>
        <ModeEditOutlineOutlinedIcon
          sx={{ cursor: 'pointer', color: colorByTheme }}
          onClick={() => {
            if (!drawIssueShape.isShow) {
              dispatch(changeShowIssueModal(true));
              dispatch(updateEditIssueData(initialData));
            }
          }}
        />
      </Tooltip>
    </>
  );
};

export default EditIssue;
