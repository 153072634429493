import { circle } from '@turf/turf';
import { IStandPointData, StandPointKeyEnum } from 'common/defines/analytic';
import { CLICKED_STAND_POINT_COLOR } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import { useGetClientSettingMapView } from 'hooks/map-view/useGetClientSettingMapView';
import { useClientData } from 'hooks/useClientData';
import { useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getDataCircumfernenceAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppSelector } from 'store/hooks';
import { circumferenceAnalyticsSelector } from 'store/slices/map-view/circumferenceAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const EditTabLayer = () => {
  const [dataStandPoint, setDataStandPoint] = useState<IStandPointData[]>([]);

  const { isLayer3D, analyticId, isInEditTab, clickedStandPointId } = useAppSelector(mapViewSelector);
  const { switchCircumferenceList, circumferenceEditedDetail } = useAppSelector(circumferenceAnalyticsSelector);
  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, getOpacityExtrusion } =
    useGetClientSettingMapView();
  const { contourSetting } = useClientData();

  const circumferenceSwitchState = switchCircumferenceList.find((item) => item.key === StandPointKeyEnum.CIRCUMFERENCE);
  const standCountSwitchState = switchCircumferenceList.find((item) => item.key === StandPointKeyEnum.STAND_COUNT);

  useQuery(
    [QUERY_KEY.CIRCUMFERENCE_ANALYSIS, analyticId],
    () => {
      return getDataCircumfernenceAnalytic(analyticId || '');
    },
    {
      enabled: !!analyticId,
      onSuccess: (res) => {
        setDataStandPoint(res.data);
      },
    }
  );

  const calculateRadius = (diameters: number | undefined) => {
    if (!diameters) return 1;
    return diameters / 2;
  };

  const generateCircumferenceColor: mapboxgl.Expression = [
    'case',
    ['==', ['get', '_id'], clickedStandPointId],
    CLICKED_STAND_POINT_COLOR,
    circumferenceSwitchState?.color,
  ];

  const convertedDataStandPoint: GeoJSON.FeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: dataStandPoint.map((item) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.longX, item.latY],
        },
        properties: item,
      })) as GeoJSON.Feature[],
    };
  }, [dataStandPoint]);

  const convertedDataStandCircle: GeoJSON.FeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: dataStandPoint.map((item) => {
        const center = [item.longX, item.latY];
        const radius =
          item._id === circumferenceEditedDetail?._id
            ? calculateRadius(circumferenceEditedDetail?.diaLength)
            : calculateRadius(item.diaLength);
        return circle(center, radius, {
          units: 'meters',
          properties: item,
        });
      }) as GeoJSON.Feature[],
    };
  }, [circumferenceEditedDetail?._id, circumferenceEditedDetail?.diaLength, dataStandPoint]);

  return (
    <>
      {/* Stand Point */}
      {convertedDataStandPoint && isInEditTab && (
        <Source id={`stand-edit-point`} type="geojson" data={convertedDataStandPoint}>
          <Layer
            id={`stand-edit-point`}
            type="circle"
            source={`stand-edit-point`}
            paint={{
              'circle-radius': 5,
              'circle-color': isInEditTab ? standCountSwitchState?.color : ['get', 'color'],
              'circle-opacity': 1,
            }}
            layout={{ visibility: standCountSwitchState?.visible ? 'visible' : 'none' }}
          />
        </Source>
      )}

      {/* Circumference */}
      {convertedDataStandCircle && isInEditTab && (
        <Source id={`circumference-edit-circle`} type="geojson" data={convertedDataStandCircle}>
          {/* 2D Contour */}
          <Layer
            id={`circumference-edit-circle`}
            source={`circumference-edit-circle`}
            type="line"
            paint={{
              'line-color': generateCircumferenceColor,
              'line-width': 2,
            }}
            layout={{ visibility: circumferenceSwitchState?.visible && !isLayer3D ? 'visible' : 'none' }}
          />
          {/* 3D Filled contour */}
          <Layer
            id={`circumference_fill-polygon`}
            type="fill"
            paint={{
              'fill-color': generateCircumferenceColor,
              'fill-opacity': 0,
            }}
            source={`circumference-source`}
            layout={{ visibility: circumferenceSwitchState?.visible && isLayer3D ? 'visible' : 'none' }}
          />
          <Layer
            id={`circumference_top-surface`}
            type="fill-extrusion"
            beforeId={`circumference_fill-polygon`}
            source={`circumference_source`}
            filter={['!=', 'color', 'transparent']}
            paint={{
              'fill-extrusion-color': generateCircumferenceColor,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': contourSetting,
              'fill-extrusion-opacity': getTopSurfaceOpacity,
              'fill-extrusion-vertical-gradient': false,
            }}
            layout={{ visibility: circumferenceSwitchState?.visible && isLayer3D ? 'visible' : 'none' }}
          />
          <Layer
            id={`circumference_side-surface`}
            type="fill-extrusion"
            beforeId={`circumference_top-surface`}
            source={`circumference_source`}
            filter={['!=', 'color', 'transparent']}
            paint={{
              'fill-extrusion-color': isSameColorTopSurface ? generateCircumferenceColor : colorTransparent,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': 0,
              'fill-extrusion-opacity': getOpacityExtrusion,
              'fill-extrusion-vertical-gradient': false,
            }}
            layout={{ visibility: circumferenceSwitchState?.visible && isLayer3D ? 'visible' : 'none' }}
          />
        </Source>
      )}
    </>
  );
};

export default EditTabLayer;
