import { yupResolver } from '@hookform/resolvers/yup';
import { PersonOutline } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { DashboardIcon } from 'assets/icons';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetPassword } from 'services/clients/apiClient.services';
import * as yup from 'yup';
import { useForgotPasswordStyle } from './ForgotPasswordStyle';
const styles = {
  formStyles: {
    width: '310px',
    m: 'auto',
    fontFamily: 'Barlow',
  },
  labelStyles: {
    textAlign: 'left',
    fontSize: '18px',
    height: '24px',
  },
  fontStyles: {
    fontFamily: 'Barlow',
  },
};

interface IForgotPasswordForm {
  setIsSendEmail: (data: boolean) => void;
  setEmail: (email: string) => void;
}

const forgotPasswordValidationSchema = yup
  .object({
    email: yup.string().email('Invalid email format').required('Email is required'),
  })
  .required();

const ForgotPasswordForm = ({ setIsSendEmail, setEmail }: IForgotPasswordForm) => {
  const navigate = useNavigate();
  const classes = useForgotPasswordStyle();
  const darkModeTheme1 = useTheme();
  const checkDarkMode = darkModeTheme1.palette.mode === 'dark';
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const handleResetPassword = useMutation((email: string) => resetPassword(email), {
    onSuccess: () => {
      setIsSendEmail(true);
    },
    onError: (error) => {
      toast.error('User not found!', { toastId: 1 });
    },
  });

  const handleSubmitForm = handleSubmit((data) => {
    setEmail(data);
    handleResetPassword.mutate(data);
  });

  const handleChangeField = (e: any, fieldName: any) => {
    setValue(fieldName, e.target.value.trim());
  };
  return (
    <Typography
      component="div"
      sx={{
        height: '100vh',
        display: ' flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: checkDarkMode ? '#1F1F1F' : '#f9f9f9',
      }}>
      <Stack
        direction="row"
        sx={{
          height: 'fit-content',
          maxWidth: '1130px',
          borderRadius: '10px',
          backgroundColor: checkDarkMode ? '#3C4043' : '#fff',
          py: 5,
        }}>
        <Box
          alignItems="center"
          className={classes.baseStyles}
          sx={{
            width: '500px',
          }}>
          <Typography
            component="div"
            sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <DashboardIcon />
            <Typography className={classes.brandStyles} sx={{ px: 2 }}>
              {' '}
              Agrimor
            </Typography>
          </Typography>
          <Typography
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '310px',
              ...styles.formStyles,
            }}
            onSubmit={handleSubmit(handleSubmitForm)}>
            <Typography
              component="div"
              sx={{
                mt: '52px',
                color: checkDarkMode ? '#fff' : '#3C4043',
                fontSize: '24px',
                fontWeight: '600',
              }}>
              {'Forgot your password ?'}
            </Typography>
            <Typography
              component="div"
              sx={{
                mt: 1,
                color: checkDarkMode ? '#fff' : '#3C4043',
                fontSize: '18px',
                fontWeight: '400',
              }}>
              {' Enter your email address and we’ll send you a link to reset your password'}
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <>
                  <Typography
                    component="div"
                    sx={{
                      mt: '30px',
                      ...styles.labelStyles,
                      ...styles.fontStyles,
                    }}>
                    Email
                  </Typography>
                  <TextField
                    type="email"
                    placeholder="someone@gmail.com"
                    sx={{
                      mt: '4px',
                      ...styles.fontStyles,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutline
                            sx={{
                              height: '24px',
                              width: '24px',
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    onChange={(e) => handleChangeField(e, 'email')}
                  />
                  {errors.email && (
                    <Typography
                      component="div"
                      className="errorMsg"
                      sx={{
                        fontFamily: 'Barlow',
                        fontSize: '14px',
                        lineHeight: '12px',
                        color: '#FF4646',
                        textAlign: 'left',
                        my: '10px',
                      }}>
                      {errors.email.message?.toString()}
                    </Typography>
                  )}
                </>
              )}
            />
            <Stack sx={{ marginTop: '100px' }}>
              <LoadingButton
                type="submit"
                sx={{
                  textTransform: 'none',
                  '.MuiLoadingButton-loadingIndicator': {
                    color: '#ffffff',
                  },
                }}
                className={classes.resetBtn}
                loading={handleResetPassword.isLoading}
                data-testid="login-btn">
                Reset Password
              </LoadingButton>

              <Button
                onClick={() => navigate('/login')}
                sx={{
                  textTransform: 'none',
                }}
                className={classes.returnLogin}
                data-testid="login-btn">
                Return to Login
              </Button>
            </Stack>
          </Typography>
        </Box>
      </Stack>
    </Typography>
  );
};

export default ForgotPasswordForm;
