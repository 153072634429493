import { Button, Typography } from '@mui/material';
import * as React from 'react';

type ClientFormActionsProps = {
  onBack: () => void;
  isClear?: Boolean;
};

export const ClientFormActions = ({ onBack, isClear }: ClientFormActionsProps) => {
  return (
    <Typography
      sx={{
        display: 'flex',
        mt: 5,
        justifyContent: 'end',
      }}>
      <Button
        type={'button'}
        color="neutral"
        variant="outlined"
        sx={{
          minWidth: 200,
          marginRight: '1rem',
          textTransform: 'none',
        }}
        onClick={onBack}>
        {isClear ? 'Clear' : 'Back'}
      </Button>
      <Button
        type="submit"
        variant="contained"
        sx={{
          minWidth: 200,
          textTransform: 'none',
        }}>
        Next
      </Button>
    </Typography>
  );
};
