import { makeStyles } from '@mui/styles';

export const useMapViewStyle = makeStyles((theme: any) => {
  return {
    labelInfo: {
      borderRadius: '10px',
      backgroundColor: theme.palette.background.paper,
      padding: '0 8px',
      fontSize: '12px',
      color: theme.palette.mode === 'dark' ? '#fff !important' : '#616161 !important',
      marginLeft: 'auto',
    },
    mapViewTab: {
      '.MuiTabs-indicator': {
        display: 'none',
      },
      height: '36px',
      borderBottom: `1px solid ${theme.palette.color.white2}`,
    },
    titleInput: {
      paddingTop: '8px',
      fontWeight: '600 !important',
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.color.grey9,
    },
    titleProperties: {
      color: theme.palette.color.grey7,
    },
    avatarInfo: {
      padding: '20px 0',
      display: 'flex',
    },
    avatarImage: {
      height: '50px',
      width: '50px',
      border: `1px solid ${theme.palette.color.grey3}`,
      marginRight: '20px',
    },
    boxLayout: {
      backgroundColor: theme.palette.mode === 'dark' ? '#24292e !important' : '#f7f8f8 !important',
    },
  };
});

export const useAnalyticCardTabStyle = makeStyles((theme: any) => {
  return {
    buttonStyle: {
      width: '60px',
      height: '60px',
      minWidth: '60px',
      border: `1px solid ${theme.palette.divider} !important`,
      borderRadius: '5px !important',
    },
    buttonStyleActive: {
      width: '60px',
      height: '60px',
      minWidth: '60px',
      border: `1px solid ${theme.palette.color.green4} !important`,
      borderRadius: '5px !important',
    },
    buttonStyleDisabled: {
      width: '60px',
      height: '60px',
      minWidth: '60px',
      border: `1px solid ${theme.palette.divider} !important`,
      borderRadius: '5px !important',
      opacity: 0.5,
    },
    labelBox: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '50px',
    },
    labelText: {
      width: '50px',
      height: '24px',
      color: `${theme.palette.color.grey8}`,
      fontWeight: 500,
      fontSize: '12px !important',
      lineHeight: '1.5rem',
      margin: '0 !important',
      marginBottom: '0 !important',
    },
    labelTextActive: {
      width: '50px',
      height: '24px',
      color: `${theme.palette.color.green4} !important`,
      fontWeight: 500,
      fontSize: '12px !important',
      lineHeight: '1.5rem',
      margin: '0 !important',
      marginBottom: '0 !important',
    },
  };
});

export const useAnalyticTabStyle = makeStyles((theme: any) => {
  return {
    tabContainer: {
      // backgroundColor: theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default
    },
  };
});

export const useFieldLevelPanelStyle = makeStyles((theme: any) => {
  return {
    tabsContainer: {
      height: '36px !important',
      borderBottom: `1px solid ${theme.palette.color.white2} !important`,
    },
  };
});

export const useLandUsageTabStyle = makeStyles((theme: any) => {
  return {
    layerBtn: {
      marginTop: '20px !important',
      width: '90px !important',
      height: '30px !important',
      alignSelf: 'end',
      color: `${theme.palette.color.grey2}`,
      border: `1px solid ${theme.palette.color.grey3}`,
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        color: `${theme.palette.color.grey3} !important`,
        backgroundColor: `${theme.palette.color.green4} !important`,
      },
    },
    layerBtnActive: {
      marginTop: '20px !important',
      width: '90px !important',
      height: '30px !important',
      alignSelf: 'end',
      color: `${theme.palette.color.white}`,
      border: `1px solid ${theme.palette.color.green4}`,
      backgroundColor: `${theme.palette.color.green4}`,
      '&:hover': {
        color: `${theme.palette.color.grey3} !important`,
        backgroundColor: `${theme.palette.color.green4} !important`,
      },
    },
    tabsContainer: {
      height: '36px !important',
      borderBottom: `1px solid ${theme.palette.color.white2} !important`,
      backgroundColor: theme.palette.background.paper,
    },
  };
});

export const useLandUsageLayerStyle = makeStyles((theme: any) => {
  return {
    containerStyle: {
      display: 'flex',
      flex: 1,
    },
    boxStyle: {
      border: theme.palette.mode === 'dark' ? 'none' : `1px solid ${theme.palette.color.white} !important`,
      boxShadow: theme.palette.mode === 'dark' ? 'none' : `${theme.palette.color.grey4} 0px 2px 8px 0px !important`,
      borderRadius: '5px !important',
      color: theme.palette.moe === 'dark' ? '#fff !important' : theme.palette.color.black1,
    },
    labelText: {
      fontWeight: 500,
      fontSize: '16px !important',
      lineHeight: '19px !important',
      color: theme.palette.mode === 'dark' ? '#fff !important' : `${theme.palette.color.black1} !important`,
      paddingRight: '24px !important',
      paddingLeft: '24px !important',
      paddingTop: '8px !important',
    },
    boxDataStyle: {
      paddingRight: '20px !important',
      paddingBottom: '20px !important',
    },
    buttonTextStyle: {
      color: theme.palette.mode === 'dark' ? '#fff !important' : '#616161 !important',
    },
    numberDataStyle: {
      ml: 1,
      borderRadius: '10px !important',
      backgroundColor: `${theme.palette.color.white3} !important`,
      padding: '0 6px !important',
      fontSize: '12px !important',
      color: theme.palette.mode === 'dark' ? '#fff !important' : `${theme.palette.color.black1} !important`,
    },
    selectText: {
      fontSize: '12px !important',
      lineHeight: '14.4px !important',
      color: theme.palette.mode === 'dark' ? '#fff !important' : `${theme.palette.color.black1} !important`,
    },
    boxLayout: {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(81, 81, 81) !important' : '#f7f8f8 !important',
    },
  };
});

export const useLayerSelectCardStyle = makeStyles((theme: any) => {
  return {
    numberDataStyle: {
      ml: 1,
      borderRadius: '10px !important',
      backgroundColor: `${theme.palette.color.white3} !important`,
      padding: '0 6px !important',
      fontSize: '12px !important',
      color: theme.palette.mode === 'dark' ? '#fff !important' : `${theme.palette.color.black1} !important`,
    },
    buttonTextStyle: {
      color: theme.palette.mode === 'dark' ? '#fff !important' : '#616161 !important',
    },
    selectText: {
      fontSize: '12px !important',
      lineHeight: '14.4px !important',
      color: theme.palette.mode === 'dark' ? '#fff !important' : `${theme.palette.color.black1} !important`,
    },
    formBlockItem: {
      // marginBottom: '1rem',
    },
    pickupColorValue: {
      fontSize: '14px',
      fontWeight: '500',
      height: '9.13px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      paddingRight: '16px',
      justifyContent: 'center',
    },
    pickupColorBtn: {
      height: '30px',
      width: '50px',
      borderRadius: '5px',
      border: '2px solid white',
      marginLeft: '8px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
      },
    },
    pickupColorValueContainer: {
      position: 'absolute',
      height: '200px',
      width: '200px',
      zIndex: 10,
      borderRadius: '10px',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
      marginTop: '10px',
      top: '75px',
      right: '28px',
    },
    editColor: {
      position: 'absolute',
      top: '-55px',
      left: '1px',
      width: '90px !important',
    },
    pickupColorBox: {
      height: '52px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    textSetting: {
      color: theme.palette.color.grey5,
    },
    boxLayout: {
      backgroundColor: theme.palette.mode === 'dark' ? '#24292e !important' : '#f7f8f8 !important',
    },
  };
});

export const useStandCountAnalytics = makeStyles((theme: any) => {
  return {
    buttonAdd: {
      minWidth: '60px',
      border: `1px solid ${theme.palette.color.green4} !important`,
      borderRadius: '5px !important',
    },
    divBtn: {
      py: '10px',
      textAlign: 'right',
      mt: '20px',
      width: '100%',
    },
    boxLayout: {
      backgroundColor: theme.palette.mode === 'dark' ? '#24292e !important' : '#f7f8f8 !important',
    },
  };
});

export const useBoxLayout = makeStyles((theme: any) => {
  return {
    boxLayout: {
      backgroundColor: theme.palette.mode === 'dark' ? '#24292e !important' : '#f7f8f8 !important',
    },
  };
});
