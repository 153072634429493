import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, IconButton, TableCell, TableRow, useTheme } from '@mui/material';
import { RoleType } from 'common/defines/clients';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars';

interface ITableDataRow {
  data: RoleType;
  index: number;
  isEdit: (val: boolean) => void;
  setData: (val: RoleType) => void;
  setOpenDeleteModal: (val: boolean) => void;
}

const flexValue = [4, 2, 1];

export const TableDataRow = ({ data, index, isEdit, setData, setOpenDeleteModal }: ITableDataRow) => {
  const getDate = useCallback((dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
  }, []);
  const theme = useTheme();

  const styles = {
    tableCell: {
      textAlign: 'center',
    },
    clientActionsIcon: {
      width: '16px',
      height: '16px',
      color: '#9F9F9F',
    },
    clientActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: flexValue[2],
    },
    //TableRow
    tableRow: {
      minHeight: '96px',
      borderBottom: `2px solid ${theme.palette.divider} !important`,
    },
    tableCellListPermission: {
      display: 'flex',
      width: '250px',
      flexDirection: 'column',
      flexWrap: 'wrap',
      height: '60px',
      border: 'none',
    },

    // common
    scrollBar: {
      height: '60px',
      width: '250px',
      padding: '0px',
    },
    minWidth: {
      minWidth: '200px',
    },
  };

  const applyUser = useMemo(() => {
    return data?.clientId?.name;
  }, [data]);

  const listOfPermissions = useMemo(() => {
    const listData: any[] = [];
    data?.permissions?.forEach((val: any) => {
      if (val.includes('_VIEW')) {
        listData.push(val.split('_VIEW')[0]);
      } else if (val.includes('_EDIT')) {
        listData.push(val.split('_EDIT')[0]);
      } else if (val.includes('_DELETE')) {
        listData.push(val.split('_DELETE')[0]);
      } else if (val.includes('_ADD')) {
        listData.push(val.split('_ADD')[0]);
      }
      listData.forEach((val: any, index: number) => {
        if (listData[index + 1] === val) {
          listData.splice(index, 1);
        }
      });
    });
    return listData.join(', ');
  }, [data]);

  const listOfUserAssigned = useMemo(() => {
    const listData: any[] = [];
    if (data?.userAssigned?.length === 0) {
      listData.push('Not assigned yet');
    }
    data?.userAssigned?.forEach((val: any) => {
      listData.push(val);
    });
    return listData.join(', ');
  }, [data]);

  return (
    <TableRow key={index} sx={{ ...styles.tableRow }}>
      <TableCell
        component="td"
        sx={{
          ...styles.minWidth,
        }}>
        {data.name}
      </TableCell>
      <TableCell
        component="td"
        sx={{
          ...styles.minWidth,
          // color: listOfUserAssigned === 'Not assigned yet' ? '#9F9F9F' : '#000',
          color:
            listOfUserAssigned === 'Not assigned yet' ? '#9F9F9F' : theme.palette.mode === 'dark' ? '#fff' : '#000',
        }}>
        <Scrollbars
          style={{
            ...styles.scrollBar,
          }}
          autoHide>
          {listOfUserAssigned}
        </Scrollbars>
      </TableCell>
      <TableCell>{getDate(data.createdAt)}</TableCell>
      <TableCell>{getDate(data.updatedAt)}</TableCell>
      <TableCell
        component="td"
        sx={{
          ...styles.minWidth,
        }}>
        <Scrollbars
          style={{
            ...styles.scrollBar,
          }}
          autoHide>
          {listOfPermissions}
        </Scrollbars>
      </TableCell>
      <TableCell component="td" sx={{ ...styles.tableCell }}>
        <Box
          sx={{
            ...styles.clientActions,
          }}>
          <IconButton aria-label="edit">
            <EditRoundedIcon
              sx={{
                ...styles.clientActionsIcon,
              }}
              onClick={() => {
                isEdit(true);
                setData(data);
              }}
            />
          </IconButton>
          <IconButton aria-label="delete">
            <DeleteRoundedIcon
              sx={{
                ...styles.clientActionsIcon,
              }}
              onClick={() => {
                setData(data);
                setOpenDeleteModal(true);
              }}
            />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
