import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import PieChartCommon from 'components/Common/PieChartCommon';
import SkeletonCharts from 'components/Common/SkeletonCharts';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import randomColor from 'randomcolor';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  getAllDataWeedInvasionAnalytic,
  getSizeWeedInvasionAnalytic,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSizeWeedInvasionList, mapViewSelector, setDataWeedInvasion } from 'store/slices/mapViewSlice';
import WeedInvasionCardLayer from '../../Layer/WeedInvasion';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import InfoTable from './InfoTable';

export const WeedInvasionAnalytics = () => {
  const dispatch = useAppDispatch();
  const { analyticId, sizeWeedInvasionSelected, dataWeedInvasion, sizeWeedInvasionList } =
    useAppSelector(mapViewSelector);

  useQuery([QUERY_KEY.WEED_INVASION_ANALYTIC, analyticId], () => getSizeWeedInvasionAnalytic(analyticId || ''), {
    enabled: !!analyticId && isEmpty(sizeWeedInvasionList),
    onSuccess: (response) => {
      if (response.data) {
        dispatch(changeSizeWeedInvasionList(response.data));
      }
    },
  });

  const { data: dataCroptype } = useQuery(
    [QUERY_KEY.SIZE_WEED_INVASION_ANALYTIC, analyticId, sizeWeedInvasionSelected],
    () => getAllDataWeedInvasionAnalytic(analyticId || '', sizeWeedInvasionSelected + ''),
    { enabled: !!analyticId && !!sizeWeedInvasionSelected }
  );

  const colorWeedInvasion = useMemo(() => {
    if (dataCroptype?.data) {
      let colors: string[] = [];
      dispatch(
        setDataWeedInvasion(
          dataCroptype.data.map((item: any, index: number) => {
            const colorRandom = randomColor({ luminosity: 'dark' });
            const _color = item.color || get(DIFFERENT_COLOR, `[${index}]`, colorRandom);
            colors.push(_color);
            return {
              ...item,
              visible: true,
              color: _color,
            };
          })
        )
      );
      return colors;
    }
    return [];
  }, [dataCroptype, dispatch]);

  const dataPie = useMemo(() => {
    if (dataWeedInvasion) {
      return dataWeedInvasion.map((_item: any) => ({
        name: _item.type,
        value: _item.lduseArea,
      }));
    }
    return [];
  }, [dataWeedInvasion]);

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION}
      analyticTab={
        <>
          {dataWeedInvasion?.length > 0 ? (
            <>
              <PieChartCommon title="Weed Invasion" dataChart={dataPie} colorCharts={colorWeedInvasion} />
              <InfoTable dataPie={dataPie} />
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<WeedInvasionCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION}></WeedInvasionCardLayer>}
      isShowDownloadButton={dataWeedInvasion?.length > 0}
    />
  );
};
