import { Box, Typography } from '@mui/material';
import { area, length, lineString } from '@turf/turf';
import { ISettingsClientMeasure } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { DrawIssueShapeEnum, IShapeInfo } from '../../../interfaces';
import { clientAreaUnitMeasurement, clientLengthUnitMeasurement } from '../../../utils';

interface ShowMeasurementInfoProps {
  shapeInfo: IShapeInfo;
  setShapeInfo: (arg: IShapeInfo) => void;
}
const ShowMeasurementInfo: FC<ShowMeasurementInfoProps> = ({ shapeInfo, setShapeInfo }) => {
  const [unitSetting, setUnitSetting] = useState<ISettingsClientMeasure[]>(defaultClientUnitSetting);

  const {
    issuesTab: { drawIssueShape },
  } = useAppSelector(rightBarSelector);
  const { clientId } = useParams();

  useEffect(() => {
    const { type, geometry } = drawIssueShape;
    let areaPolygon = 0;
    let lengthLine = 0;
    if (geometry?.coordinates.length) {
      if (type === DrawIssueShapeEnum.POLYGON) {
        areaPolygon = area(geometry);
      } else if (type === DrawIssueShapeEnum.LINE) {
        const coordinates = geometry.coordinates;
        const line = lineString(coordinates);
        lengthLine = length(line, { units: 'meters' });
      }

      setShapeInfo({
        area: areaPolygon / 10000,
        length: lengthLine,
      });
    }
  }, [drawIssueShape, setShapeInfo]);

  useQuery([QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId], () => getClientSettingsById(clientId || ''), {
    enabled: !!clientId,
    onSuccess(data) {
      if (data.unitSetting?.length) {
        setUnitSetting(data?.unitSetting);
      }
    },
  });

  return (
    <Box>
      {shapeInfo.area ? (
        <Box>
          <Typography sx={{ display: 'inline' }}>Area: </Typography>
          <Typography sx={{ display: 'inline' }}>
            {clientAreaUnitMeasurement(Number(shapeInfo.area!), unitSetting.find((item) => item.name === 'AREA')!)}
            &nbsp;
            {unitSetting.find((item) => item.name === 'AREA')?.unit}
          </Typography>
        </Box>
      ) : null}
      {shapeInfo.length ? (
        <Box>
          <Typography sx={{ display: 'inline' }}>Length: </Typography>
          <Typography sx={{ display: 'inline' }}>
            {clientLengthUnitMeasurement(
              Number(shapeInfo.length!),
              unitSetting.find((item) => item.name === 'LENGTH')!
            )}
            &nbsp;
            {unitSetting.find((item) => item.name === 'LENGTH')?.unit}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default ShowMeasurementInfo;
