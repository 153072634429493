import { makeStyles } from '@mui/styles';

export const useRequestInfoStyle = makeStyles((theme: any) => {
  return {
    baseStyles: {
      // backgroundColor: theme.palette.color.white,
      borderRadius: '10px',
    },
    loginBtn: {
      marginTop: '24px !important',
      color: `${theme.palette.color.white} !important`,
      height: '48px',
      background: `linear-gradient(90deg, ${theme.palette.color.green1} 0%, ${theme.palette.color.green2} 97.14%)`,
    },
    brandStyles: {
      color: theme.palette.color.green3,
      fontSize: '24px',
      fontWeight: '600',
    },
  };
});
