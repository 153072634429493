import { useMemo } from 'react';
import { DEFAULT_SURFACE_SETTINGS } from '../../common/defines/constants';
import { useAppSelector } from '../../store/hooks';
import { mapViewSelector } from '../../store/slices/mapViewSlice';

export const useGetClientSettingMapView = () => {
  const {
    isLayer3D,
    sideSurfaceOpacity,
    colorSideSurface,
    isSameSideSurface,
    topSurfaceOpacity,
    isSameColorTopSurface,
  } = useAppSelector(mapViewSelector);

  const colorTransparent = useMemo(() => {
    return colorSideSurface ?? DEFAULT_SURFACE_SETTINGS.COLOR;
  }, [colorSideSurface]);

  const opacitySideSurface = useMemo(() => {
    return sideSurfaceOpacity ?? DEFAULT_SURFACE_SETTINGS.OPACITY;
  }, [sideSurfaceOpacity]);

  const isSameSideSurfaceOpacity = useMemo(() => {
    return isSameSideSurface ?? DEFAULT_SURFACE_SETTINGS.IS_SAME_SIDE_SURFACE_OPACITY;
  }, [isSameSideSurface]);

  const opacity2D = useMemo(() => {
    return topSurfaceOpacity ?? DEFAULT_SURFACE_SETTINGS.OPACITY_2D;
  }, [topSurfaceOpacity]);

  const getOpacityExtrusion = useMemo(() => {
    if (isLayer3D) return opacitySideSurface;
    return 0;
  }, [isLayer3D, opacitySideSurface]);

  const getTopSurfaceOpacity = useMemo(() => {
    if (isLayer3D) {
      // if (!opacitySideSurface) return 0;

      if (isSameSideSurfaceOpacity) return opacitySideSurface;

      return 1;
    }
    return 0;
  }, [opacitySideSurface, isLayer3D, isSameSideSurfaceOpacity]);

  return {
    colorTransparent,
    opacitySideSurface,
    isSameSideSurfaceOpacity,
    opacity2D,
    getOpacityExtrusion,
    getTopSurfaceOpacity,
    isSameColorTopSurface,
  };
};
