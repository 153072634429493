import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Box, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { ITilingMapField, ReconstructedBandEnum } from 'interfaces/workspace';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeUploadReconstructedImageList, tilingMapSelector } from 'store/slices/tilingMapSlice';
import DragAndDropFile from '../DragAndDropFile';

interface IReconstructedItem {
  key: number;
  name: ReconstructedBandEnum;
  label: string;
  disabled: boolean;
}

const reconstructedList: IReconstructedItem[] = [
  { key: 1, name: ReconstructedBandEnum.RGB_BAND, label: 'RGB Band', disabled: false },
  { key: 2, name: ReconstructedBandEnum.R_BAND, label: 'R Band', disabled: true },
  { key: 3, name: ReconstructedBandEnum.G_BAND, label: 'G Band', disabled: true },
  { key: 4, name: ReconstructedBandEnum.B_BAND, label: 'B Band', disabled: true },
  { key: 5, name: ReconstructedBandEnum.RE_BAND, label: 'RE Band', disabled: true },
  { key: 6, name: ReconstructedBandEnum.NIR_BAND, label: 'NIR Band', disabled: true },
  { key: 7, name: ReconstructedBandEnum.DSM, label: 'DSM', disabled: false },
  { key: 8, name: ReconstructedBandEnum.DTM, label: 'DTM', disabled: false },
];

const dtmAndDsmList = [ReconstructedBandEnum.DTM, ReconstructedBandEnum.DSM];

interface ReconstructedImagesProps {
  form: UseFormReturn<ITilingMapField>;
  isInEditMode: boolean;
}

const ReconstructedImages: FC<ReconstructedImagesProps> = ({ form, isInEditMode }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    upload: { reconstructedImageList },
  } = useAppSelector(tilingMapSelector);

  const handleDeleteReconstructedImage = (item: IReconstructedItem) => {
    const newReconstructedImageList = reconstructedImageList.filter((ele) => ele.name !== item.name);
    dispatch(changeUploadReconstructedImageList(newReconstructedImageList));
  };

  const onToggleButton = (reconstructedItem: IReconstructedItem) => {
    const newReconstructedImageList = reconstructedImageList.map((item) => ({
      ...item,
      is3DTexturedSurface: reconstructedItem.name !== item.name ? item.is3DTexturedSurface : !item.is3DTexturedSurface,
    }));
    dispatch(changeUploadReconstructedImageList(newReconstructedImageList));
  };

  const calculateCheckedValue = (name: ReconstructedBandEnum) => {
    const selectedReconstructImage = reconstructedImageList.find((item) => item.name === name);
    if (selectedReconstructImage) {
      return selectedReconstructImage.is3DTexturedSurface;
    }
  };

  return (
    <Grid
      container
      sx={{
        border: `2px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        p: '12px',
        pt: '8px',
      }}>
      {reconstructedList.map((item) => {
        const itemUploaded = reconstructedImageList.find((ele) => ele.name === item.name);

        return (
          <Grid item xs={6} key={item.key}>
            <Box sx={{ p: '4px' }}>
              <Typography sx={{ fontWeight: 500, mb: '4px' }}>{item.label}</Typography>
              <Box sx={{}}>
                {itemUploaded ? (
                  <Box
                    sx={{
                      flex: '1',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px',
                      borderRadius: '8px',
                      border: theme.palette.mode === 'dark' ? '2px dashed red' : '2px dashed #bdbdbd',
                      borderColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#bdbdbd',
                      color: '#bdbdbd',
                      outline: 'none',
                      transition: 'border .24s ease-in-out',
                      height: '125px',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ width: '100%' }}>
                      <Grid container alignItems="center" mb="8px">
                        <Grid item xs={2}>
                          <PermMediaIcon fontSize="medium" color="primary" />
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            width: '100%',
                          }}>
                          {itemUploaded.file?.name}
                        </Grid>
                        <Grid item xs={2} textAlign="right">
                          <DeleteOutlineOutlinedIcon
                            color="error"
                            sx={{ fontSize: '24px', cursor: 'pointer' }}
                            onClick={() => handleDeleteReconstructedImage(item)}
                          />
                        </Grid>
                      </Grid>
                      {dtmAndDsmList.includes(item.name) && (
                        <FormControlLabel
                          sx={{ ml: 0 }}
                          control={
                            <SwitchCustom
                              checked={calculateCheckedValue(item.name)}
                              onChange={() => onToggleButton(item)}
                              disabled={isInEditMode}
                            />
                          }
                          label="3D Textured Surface"
                        />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <DragAndDropFile name={item.name} form={form} disabled={item.disabled} />
                )}
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ReconstructedImages;
