import { Box, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { useSettingsPageStyle } from './SettingPageStyle';

// const Navbars = ['Type Configuration', 'Role Configuration', 'Access Control'];
const Navbars = ['Type Configuration', 'Access Control'];

interface ITabsBar {
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
}

export const TabsBarSettings = ({ tab, setTab }: ITabsBar) => {
  const classes = useSettingsPageStyle();
  const handleChange = (event: React.SyntheticEvent, newValue: React.SetStateAction<string>) => {
    setTab(newValue);
  };

  return (
    <Box className={classes.positionsCenter}>
      <Tabs value={tab} onChange={handleChange} textColor="primary" indicatorColor="primary">
        {Navbars.map((navbar) => (
          <Tab className={classes.textStyles} key={navbar} label={navbar} value={navbar} />
        ))}
      </Tabs>
    </Box>
  );
};
