import { Grid, Typography } from '@mui/material';

const StandCountPopup = ({ hoverInfo }: any) => {
  return (
    <Grid container padding={1} gap={0.25}>
      <Grid
        item
        xs={12}
        direction="row"
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
          }}>
          Tree Id
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'normal',
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5'),
            padding: '2px',
            color: '#1C9855',
            width: '80%',
            wordWrap: 'break-word',
          }}>
          {hoverInfo.properties.treeId}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        direction="row"
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
          }}>
          Longitude
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'normal',
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5'),
            padding: '2px',
            color: '#1C9855',
            width: 'fit-content',
          }}>
          {hoverInfo.properties.longX}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        direction="row"
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
          }}>
          Latitude
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'normal',
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5'),
            padding: '2px',
            color: '#1C9855',
            width: 'fit-content',
          }}>
          {hoverInfo.properties.latY}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        direction="row"
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
          }}>
          Status
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'normal',
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5'),
            padding: '2px',
            color: '#1C9855',
            width: 'fit-content',
          }}>
          {hoverInfo.properties.status}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StandCountPopup;
