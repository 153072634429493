import { useTheme } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { FC } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeShow3dModal } from 'store/slices/tilingMapSlice';
import Mapbox3d from '../mapbox-3d';

interface Mapbox3dModalProps {
  isShow: boolean;
  projectId: string;
  taskId: string;
}

const Mapbox3dModal: FC<Mapbox3dModalProps> = ({ isShow, projectId, taskId }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isLightMode = theme.palette.mode === 'light';

  return (
    <CommonModal
      isShow={isShow}
      handleClose={() =>
        dispatch(
          changeShow3dModal({
            isShow3dMapbox: false,
          })
        )
      }
      closeIconColor={isLightMode ? theme.palette.color.green5 : ''}
      style={{ height: '100vh', width: '100vw' }}>
      <Mapbox3d
        mapStyle={'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y'}
        isShowNavbar={true}
        projectId={projectId}
        taskId={taskId}
      />
    </CommonModal>
  );
};

export default Mapbox3dModal;
