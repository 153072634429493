import BuildIcon from '@mui/icons-material/Build';
import MapIcon from '@mui/icons-material/Map';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Grid, styled, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { MapEnum } from 'common/defines/constants';
import TemporalModal from 'components/MapView/RightBar/AnalyticsTab/TemporalModal';
import { TabPanel } from 'components/MapView/TabPanel';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { FC, useEffect, useMemo, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeIsClickedGenerateAnalytic,
  changeIsClickedGenerateStreamlineOrder,
  changeSelectedTabHistory,
  tilingMapSelector,
} from 'store/slices/tilingMapSlice';
import Layer2dMap from './layer-2d-map';
import SelectDateArea from './layer-2d-map/select-date-area';
import LayerTools from './layer-tools';
import Layer3dMap from './Layer3dMap';

const tabMenu = [
  {
    id: 0,
    label: <Typography fontSize="14px">Map 2D</Typography>,
    disabled: false,
    icon: <MapIcon />,
  },
  {
    id: 1,
    label: <Typography fontSize="14px">Map 3D</Typography>,
    disabled: false,
    icon: <ViewInArIcon />,
  },
  {
    id: 2,
    label: <Typography fontSize="14px">Tools</Typography>,
    disabled: false,
    icon: <BuildIcon />,
  },
];

interface RightBarTilingMapProps {}

const RightBarTilingMap: FC<RightBarTilingMapProps> = () => {
  const [isShowTemporalModal, setIsShowTemporalModal] = useState<boolean>(false);
  const [tabNumber, setTabNumber] = useState<number>(0);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { selectedFieldId, selectedTaskId } = useAppSelector(tilingMapSelector);
  const { selectedTask } = useQueryListTaskOfField();

  // reset to layer 2d each time change field
  useEffect(() => {
    setTabNumber(0);
    dispatch(changeSelectedTabHistory({ id: 0 }));
  }, [dispatch, selectedFieldId]);

  const a11yProps = (index: number) => {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  };

  // reset generate analytic, streamline order button when change field, task
  useEffect(() => {
    dispatch(changeIsClickedGenerateAnalytic(false));
    dispatch(changeIsClickedGenerateStreamlineOrder(false));
  }, [dispatch, selectedFieldId, selectedTaskId]);

  const TabStyled = styled(Tab)(() => ({
    textTransform: 'unset',
    color: '#CACED3',
    fontWeight: 500,
    fontSize: '12px',
    '&.Mui-selected': {
      color: '#3C4043',
    },
  }));

  const getTabMenuUpdated = useMemo(() => {
    // If task use reconstructed images, hide 3d tab
    return selectedTask?.taskId ? tabMenu : tabMenu.filter((tab) => tab.id !== 1);
  }, [selectedTask]);

  const { taskList } = useQueryListTaskOfField();

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default),
        height: 'fit-content',
        width: '100%',
      }}>
      <Grid direction="row" width="100%" height="100%">
        <Grid>
          <SelectDateArea taskList={taskList} setIsShowTemporalModal={setIsShowTemporalModal} />
        </Grid>
        <Grid direction="row">
          <Tabs
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.divider}`,
              borderTop: `1px solid ${theme.palette.divider}`,
              padding: '0px',
              margin: '0px',
            }}
            value={tabNumber}
            children={getTabMenuUpdated.map((item: any, index: number) => {
              return (
                <TabStyled
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    maxWidth: '100px',
                    '&.Mui-selected': {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                  key={item.id}
                  label={item.label}
                  {...a11yProps(item.id)}
                  onClick={() => {
                    setTabNumber(item.id);
                    dispatch(changeSelectedTabHistory({ id: item.id }));
                  }}
                  value={item.id}
                  icon={item.icon}
                />
              );
            })}
          />
        </Grid>
        <Grid direction="row" height={'100vh'}>
          <SwipeableViews index={tabNumber}>
            <TabPanel value={tabNumber} index={0}>
              <Layer2dMap />
            </TabPanel>
            <TabPanel value={tabNumber} index={1}>
              <Layer3dMap />
            </TabPanel>
            <TabPanel value={tabNumber} index={2}>
              <LayerTools />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>

      {isShowTemporalModal && (
        <TemporalModal
          isShow={true}
          handleClose={() => setIsShowTemporalModal(false)}
          mode={MapEnum.CROP_INTELLIGENT}
        />
      )}
    </Grid>
  );
};

export default RightBarTilingMap;
