import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import { useTheme } from '@mui/material';
import { FC } from 'react';
import { Marker } from 'react-map-gl';
import { IIssue } from '../../interfaces';
import './index.scss';
interface ShowPointLayerProps {
  item: IIssue;
}

const ShowPointLayer: FC<ShowPointLayerProps> = ({ item }) => {
  const theme = useTheme();

  const circleColor = item.border?.color || theme.palette.primary.main;

  return (
    <>
      <Marker
        longitude={Array.isArray(item?.geometry.coordinates) ? item?.geometry.coordinates[0] : 0}
        latitude={Array.isArray(item?.geometry.coordinates) ? item?.geometry.coordinates[1] : 0}
        offset={[0, 0]}>
        <GpsFixedOutlinedIcon style={{ fontSize: 24, color: circleColor, fontWeight: 600 }} />
      </Marker>
    </>
  );
};

export default ShowPointLayer;
