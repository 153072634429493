import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Marker, MarkerDragEvent } from 'react-map-gl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { changeDropMarkerInfo, changeShowIssueModal, rightBarSelector } from 'store/slices/rightBarSlice';

const DropMarker = () => {
  const { mapViewState, clientId } = useAppSelector(mapViewSelector);
  const [, setLongitude] = useState<number>(0);

  const theme = useTheme();
  const {
    issuesTab: { dropMarker },
  } = useAppSelector(rightBarSelector);
  const dispatch = useAppDispatch();

  const initialCoordinates = dropMarker.initialData?.coordinates;
  const initialLng = initialCoordinates?.[0] ?? mapViewState.longitude;
  const initialLat = initialCoordinates?.[1] ?? mapViewState.latitude;

  const markerLng = Number(localStorage.getItem('markerLng')) || initialLng;
  const markerLat = Number(localStorage.getItem('markerLat')) || initialLat;

  const handleDragDropMarker = (e: MarkerDragEvent) => {
    setLongitude(e.lngLat.lng);
    localStorage.setItem('markerLng', e.lngLat.lng.toString());
    localStorage.setItem('markerLat', e.lngLat.lat.toString());
  };

  const handleSelectedDropMarker = () => {
    if (markerLng && markerLat) {
      dispatch(
        changeDropMarkerInfo({
          isShow: false,
          coordinates: [markerLng, markerLat],
        })
      );
      localStorage.removeItem('markerLng');
      localStorage.removeItem('markerLat');
    }
    dispatch(changeShowIssueModal(true));
  };

  return (
    <Box>
      {dropMarker.isShow && (
        <Box sx={{ height: 0, width: 0, position: 'relative' }}>
          <Marker longitude={markerLng} latitude={markerLat} offset={[0, 0]} draggable onDragEnd={handleDragDropMarker}>
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '32px',
                  border: 0,
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  borderRadius: '8px',
                  backgroundColor: (theme) => theme.palette.background.default,
                }}>
                <Typography>
                  <Button
                    variant="outlined"
                    sx={{ minWidth: 0, padding: '5px 5px' }}
                    onClick={handleSelectedDropMarker}>
                    <CheckOutlinedIcon />
                  </Button>
                </Typography>
              </Box>
              <AddLocationOutlinedIcon
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '36px',
                  cursor: 'move',
                  position: 'fixed',
                  top: 'calc(50% - 36px)',
                  left: 'calc(50% - 18px)',
                }}
              />
            </Box>
          </Marker>
        </Box>
      )}
    </Box>
  );
};

export default DropMarker;
