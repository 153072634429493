import { Stack } from '@mui/material';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import AiDetection from './components/AiDetection';
import ContourGeneration from './components/contour-generation';
import DrawBoundaryBar from './components/DrawBoundaryBar';
import StreamlineOrderIntelligent from './components/StreamlineOrderIntelligent';

const LayerTools = () => {
  const { selectedTask } = useQueryListTaskOfField();

  return (
    <>
      {selectedTask ? (
        <Stack sx={{ mt: '24px' }} spacing="12px">
          <DrawBoundaryBar selectedTask={selectedTask} />
          <AiDetection />
          <StreamlineOrderIntelligent selectedTask={selectedTask} />
          <ContourGeneration selectedTask={selectedTask} />
        </Stack>
      ) : null}
    </>
  );
};

export default LayerTools;
