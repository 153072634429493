import { isEmpty, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../store/hooks';
import { mapViewSelector } from '../../../store/slices/mapViewSlice';
import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

export const useWaterUptakeFieldAnalytics = ({ mapRef }: any) => {
  const { dataWaterUptakeField, isDisplayBoundaryLine, isLayer3D, dataVacantAreaSpot, sizeWaterUptakeFieldSelected } =
    useAppSelector(mapViewSelector);

  const { getTopSurfaceOpacity, colorTransparent, isSameColorTopSurface, opacity2D, getOpacityExtrusion } =
    useGetClientSettingMapView();

  useEffect(() => {
    if (dataVacantAreaSpot?.isNull && sizeWaterUptakeFieldSelected) {
      toast.error(`The data for Vacant Area spot for grid size ${sizeWaterUptakeFieldSelected}cm is not available.`, {
        toastId: 1,
      });
    }
  }, [dataVacantAreaSpot, sizeWaterUptakeFieldSelected]);

  const isDisplayVacantAreaSpot = useMemo(() => {
    if (isEmpty(dataVacantAreaSpot)) {
      return false;
    }
    return dataVacantAreaSpot.visible;
  }, [dataVacantAreaSpot]);

  const { contourSetting } = useClientData();

  const polygonWaterUptakeFieldViewer = useCallback(
    (_item: any) => {
      return (
        <Source
          id={`${_item._id}_WaterUptakeField`}
          key={`${_item._id}_WaterUptakeField`}
          type="geojson"
          data={_item.geometry}>
          <Layer
            id={`${_item._id}-polygon_WaterUptakeField`}
            type="line"
            paint={{
              'line-color': _item.color,
              'line-opacity': isDisplayBoundaryLine ? 1 : 0,
              'line-width': 2,
            }}
            // source={`${_item._id}_WaterUptakeField`}
            layout={{ visibility: _item.visible ? 'visible' : 'none' }}
          />
          <Layer
            id={`${_item._id}_WaterUptakeField_fill-polygon`}
            type="fill"
            paint={{
              'fill-color': _item.color,
              'fill-opacity': !isLayer3D ? opacity2D : 0,
              'fill-antialias': true,
              'fill-translate-anchor': 'viewport',
            }}
            // source={`${_item._id}_WaterUptakeField`}
            layout={{
              visibility: _item.visible && !isDisplayBoundaryLine && !isDisplayVacantAreaSpot ? 'visible' : 'none',
            }}
          />
          <Layer
            id={`${_item._id}_WaterUptakeField_top-surface`}
            type="fill-extrusion"
            // source={`${_item._id}_WaterUptakeField`}
            beforeId={`${_item._id}_WaterUptakeField_fill-polygon`}
            paint={{
              'fill-extrusion-color': _item.color,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': contourSetting,
              'fill-extrusion-opacity': getTopSurfaceOpacity,
              'fill-extrusion-vertical-gradient': true,
            }}
            layout={{
              visibility: _item.visible && !isDisplayBoundaryLine && !isDisplayVacantAreaSpot ? 'visible' : 'none',
            }}
          />
          <Layer
            id={`${_item._id}_WaterUptakeField_side-surface`}
            type="fill-extrusion"
            beforeId={`${_item._id}_WaterUptakeField_top-surface`}
            // source={`${_item._id}_WaterUptakeField`}
            paint={{
              'fill-extrusion-color': isSameColorTopSurface ? _item.color : colorTransparent,
              'fill-extrusion-height': contourSetting,
              'fill-extrusion-base': 0,
              'fill-extrusion-opacity': getOpacityExtrusion,
              'fill-extrusion-vertical-gradient': true,
            }}
            layout={{
              visibility: _item.visible && !isDisplayBoundaryLine && !isDisplayVacantAreaSpot ? 'visible' : 'none',
            }}
          />
        </Source>
      );
    },
    [
      isDisplayBoundaryLine,
      isLayer3D,
      opacity2D,
      isDisplayVacantAreaSpot,
      contourSetting,
      getTopSurfaceOpacity,
      isSameColorTopSurface,
      colorTransparent,
      getOpacityExtrusion,
    ]
  );

  const polygonVacantAreaSpot = useMemo(() => {
    if (isEmpty(dataVacantAreaSpot)) {
      return null;
    }
    return (
      <Source
        id={`${dataVacantAreaSpot._id}_VacantAreaSpot`}
        key={`${dataVacantAreaSpot._id}_VacantAreaSpot`}
        type="geojson"
        data={{ type: 'FeatureCollection', features: [{ type: 'Feature', ...dataVacantAreaSpot }] }}>
        <Layer
          id={`${dataVacantAreaSpot._id}-polygon_VacantAreaSpot`}
          type="fill"
          paint={{
            'fill-color': dataVacantAreaSpot.color,
            'fill-opacity': 1,
            'fill-antialias': true,
            'fill-translate-anchor': 'viewport',
          }}
          layout={{ visibility: dataVacantAreaSpot.visible ? 'visible' : 'none' }}
        />
      </Source>
    );
  }, [dataVacantAreaSpot]);

  const layerWaterUptakeField = useMemo(() => {
    if (isEmpty(dataWaterUptakeField)) {
      return null;
    }

    return (
      <>
        {sortBy(dataWaterUptakeField, 'type').map((_item: any) => polygonWaterUptakeFieldViewer(_item))}
        {polygonVacantAreaSpot}
      </>
    );
  }, [dataWaterUptakeField, polygonWaterUptakeFieldViewer, polygonVacantAreaSpot]);

  return { layerWaterUptakeField };
};
