import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Box, Button, Collapse, Grid, Tooltip } from '@mui/material';
import { MeasurementUnit, SettingSectionName, SettingUnitName } from 'common/defines/clients';
import { uiIconSettingFavorite, uiSettingDescriptionMapping, uiViewAnalysisProperties } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useClientSettingsStyle } from './ClientSettingsStyle';

export const UnitCardSelect = ({ data, type }: any) => {
  console.log('data', data);
  const classes = useClientSettingsStyle();
  const { t } = useTranslation();

  const dataUnit = useMemo(() => {
    const unitSetting = data.unit;
    if (unitSetting === 'm2') {
      return 'm²';
    }
    if (unitSetting === 'km2') {
      return 'km²';
    }
    if (unitSetting === MeasurementUnit.OTHER && SettingUnitName.OTHER_MEASUREMENTS) {
      return ' - ';
    }
    return unitSetting;
  }, [data]);
  const [unitMeasurement, setUnitMeasurement] = useState(true);

  const iconFavorite = useMemo(() => {
    const icon = data.icon;
    const color = data.color;
    if (icon === 'StarRoundedIcon') {
      return (
        <StarRoundedIcon
          sx={{
            color: color,
          }}
        />
      );
    }
    if (icon === 'FavoriteIcon') {
      return (
        <FavoriteIcon
          sx={{
            color: color,
          }}
        />
      );
    }
    return icon;
  }, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const copyToClipboard = () => {
    const text = data.color.toUpperCase();
    navigator.clipboard.writeText(text);
    toast.success('Color copied to clipboard', { toastId: 1 });
  };

  const settingData = useMemo(() => {
    switch (type) {
      case SettingSectionName.UNIT_SETTING:
        return (
          <>
            <Grid item className={classes.textSetting} xs={6} md={6}>
              {`${t('trans.unit')}: ${dataUnit}`}
            </Grid>
            <Grid item className={classes.textSetting} xs={6} md={6}>
              {`${t('trans.decimal_places')}: ${data.decimalPlace}`}
            </Grid>
          </>
        );
      case SettingSectionName.UI_SETTING:
        return (
          <>
            <Grid item className={classes.textSetting} xs={6} md={6}>
              {`${t(convertTextToMultiLanguage(uiSettingDescriptionMapping[data.name], 'trans'))}: ${data.value}`}
            </Grid>
          </>
        );
      case SettingSectionName.FAVORITE_ICON_SETTING:
        return (
          <>
            <Grid item className={classes.textSetting} xs={6} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {`${t(convertTextToMultiLanguage(uiIconSettingFavorite[data.name], 'trans'))}: `}
                {iconFavorite}
              </Box>
            </Grid>
            <Grid item className={classes.textSetting} xs={6} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {t('trans.color')}
                <Tooltip title={data.color.toUpperCase() + ' click here to copy clipboard'} arrow placement="right">
                  <Box
                    sx={{
                      height: '24px',
                      width: '32px',
                      backgroundColor: data.color,
                      borderRadius: '4px',
                      marginLeft: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={copyToClipboard}
                  />
                </Tooltip>
              </Box>
            </Grid>
          </>
        );
      case SettingSectionName.VIEW_ANALYSIS_SETTING:
        return (
          <>
            <Grid item className={classes.textSetting} xs={6} md={6}>
              {data.map((item: any) => {
                return (
                  <Box
                    key={item.name}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {`${t(convertTextToMultiLanguage(uiViewAnalysisProperties[item.name], 'trans'))}: ${t(
                      convertTextToMultiLanguage(item.value.toString(), 'trans')
                    )}`}
                  </Box>
                );
              })}
            </Grid>
          </>
        );
      default:
        return <></>;
    }
  }, [classes.textSetting, copyToClipboard, data, dataUnit, iconFavorite, t, type]);
  return (
    <Grid>
      <Box>
        <Button
          startIcon={unitMeasurement ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          onClick={() => setUnitMeasurement(!unitMeasurement)}>
          {/* {data.name} */}
          {type === SettingSectionName.VIEW_ANALYSIS_SETTING
            ? data.map((item: any) => {
                return (
                  <Box
                    key={item.name}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {`${t(convertTextToMultiLanguage(uiViewAnalysisProperties[item.name], 'trans'))}`}
                  </Box>
                );
              })
            : t(convertTextToMultiLanguage(data.name, 'trans'))}
        </Button>
      </Box>
      <Collapse in={unitMeasurement} timeout="auto" unmountOnExit sx={{ px: 4 }}>
        <Grid container>{settingData}</Grid>
      </Collapse>
    </Grid>
  );
};
