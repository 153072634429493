import { HexColorPicker } from 'react-colorful';
import { useState } from 'react';
import { useDebouncyEffect } from 'use-debouncy';

export const HexColorPickerDebounce = ({ color, onChange }: any) => {
  const [value, setValue] = useState(color);

  useDebouncyEffect(() => onChange(value), 200, [value]);

  return (
    <HexColorPicker
      style={{
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
      }}
      color={color}
      onChange={setValue}
    />
  );
};
