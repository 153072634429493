import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { chlorophyllAnalyticsSelector } from 'store/slices/map-view/chlorophyllAnalytics';
import { circumferenceAnalyticsSelector } from 'store/slices/map-view/circumferenceAnalytics';
import { co2SequestrationAnalyticsSelector } from 'store/slices/map-view/co2SequestrationAnalytics';
import { crownAreaAnalyticsSelector } from 'store/slices/map-view/crownAreaAnalytics';
import { gapAnalyticsSelector } from 'store/slices/map-view/gapAnalytics';
import { plantHealthOilPailmAnalyticsSelector } from 'store/slices/map-view/plantHealthOilPailmAnalytics';
import { plantStressAnalyticsSelector } from 'store/slices/map-view/plantStressAnalytics';
import { standCountAnalyticSelector } from 'store/slices/map-view/standCountAnalytics';
import { treeHeightAnalyticsSelector } from 'store/slices/map-view/treeHeightAnalytics';
import { vigorAnalyticsSelector } from 'store/slices/map-view/vigorAnalytics';
import { waterUptakeAnalyticsSelector } from 'store/slices/map-view/waterUptakeAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

export const useLabelAnalytic = () => {
  const { analyticName } = useAppSelector(rightBarSelector);
  const { selectedAnalysisName: tilingMapAnalyticName } = useAppSelector(tilingMapSelector);
  const { dataVigorInterval } = useAppSelector(vigorAnalyticsSelector);
  const { dataWaterUptakeInterval } = useAppSelector(waterUptakeAnalyticsSelector);
  const { dataCircumferenceInterval } = useAppSelector(circumferenceAnalyticsSelector);
  const { dataTreeHeightInterval } = useAppSelector(treeHeightAnalyticsSelector);
  const { dataCo2SequestrationInterval } = useAppSelector(co2SequestrationAnalyticsSelector);
  const { dataChlorophyllInterval } = useAppSelector(chlorophyllAnalyticsSelector);
  const { dataPlantStressInterval } = useAppSelector(plantStressAnalyticsSelector);
  const { dataCrownAreaInterval } = useAppSelector(crownAreaAnalyticsSelector);
  const { dataGapInterval } = useAppSelector(gapAnalyticsSelector);
  const { dataPlantHealthOilPailmInterval } = useSelector(plantHealthOilPailmAnalyticsSelector);
  const { dataStandCount, isInEditTab } = useAppSelector(mapViewSelector);
  const { isShowPopupAdd } = useAppSelector(standCountAnalyticSelector);

  const [typeLabel, dataIntervalByAnalytic] = useMemo(() => {
    let typeLabel = '' as string;
    let dataInterval = null;

    const chosenAnalyticName = analyticName || tilingMapAnalyticName;

    switch (chosenAnalyticName) {
      case TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE:
        typeLabel = 'vigor';
        dataInterval = dataVigorInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE:
        typeLabel = 'water-uptake';
        dataInterval = dataWaterUptakeInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
        typeLabel = 'circumference';
        dataInterval = dataCircumferenceInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT:
        typeLabel = 'tree-height';
        dataInterval = dataTreeHeightInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION:
        typeLabel = 'co2-sequestration';
        dataInterval = dataCo2SequestrationInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE:
        typeLabel = 'chlorophyll';
        dataInterval = dataChlorophyllInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE:
        typeLabel = 'stress';
        dataInterval = dataPlantStressInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
        typeLabel = 'crown-area';
        dataInterval = dataCrownAreaInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE:
        typeLabel = 'plant-health-oilpalm';
        dataInterval = dataPlantHealthOilPailmInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.GAP:
        typeLabel = 'gap';
        dataInterval = dataGapInterval;
        break;
      default:
        break;
    }
    return [typeLabel, dataInterval];
  }, [
    analyticName,
    tilingMapAnalyticName,
    dataVigorInterval,
    dataWaterUptakeInterval,
    dataCircumferenceInterval,
    dataTreeHeightInterval,
    dataChlorophyllInterval,
    dataPlantStressInterval,
    dataCrownAreaInterval,
    dataPlantHealthOilPailmInterval,
    dataGapInterval,
    dataCo2SequestrationInterval,
  ]);

  const interactiveLayerIds = useMemo(() => {
    if (isShowPopupAdd || !analyticName) {
      return undefined;
    }

    if (analyticName === TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT) {
      const fullLayerId = ['stand-count-layer-Tree', 'stand-count-layer-Blank spot'];
      if (Array.isArray(dataStandCount)) {
        if (dataStandCount.length === 1) return fullLayerId.slice(0, 1);
        else if (dataStandCount.length === 2) return fullLayerId;
      }
      return fullLayerId;
    }

    const listAnalyticWithTopSurfacePoint = [
      TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE,
      TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS,
      TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT,
      TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION,
      TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA,
      TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE,
      TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE,
      TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE,
      TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE,
      TYPE_ANALYTICS_MAP_VIEW.GAP,
      TYPE_ANALYTICS_MAP_VIEW.TREE_TILT_FALLEN,
    ];

    if (isInEditTab) {
      return ['stand-edit-point'];
    } else {
      if (listAnalyticWithTopSurfacePoint.includes(analyticName as TYPE_ANALYTICS_MAP_VIEW)) {
        if (analyticName === TYPE_ANALYTICS_MAP_VIEW.TREE_TILT_FALLEN) return ['treeTileFallen_line'];
        const ids = ['fill-top-surface-point'];
        if (analyticName === TYPE_ANALYTICS_MAP_VIEW.GAP) ids.push('gap_line');
        return ids;
      }
    }
  }, [analyticName, dataStandCount, isInEditTab, isShowPopupAdd]);

  const categoryByColor = useMemo(() => {
    let options = [] as any;
    dataIntervalByAnalytic?.range?.forEach((item: any) => {
      options.push({
        label: item.lable ?? item.label,
        color: item.color,
        visible: item.visible,
      });
    });
    return options;
  }, [dataIntervalByAnalytic?.range]);

  return { interactiveLayerIds, typeLabel, categoryByColor };
};
