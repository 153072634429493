import { Avatar, Box, Button, Skeleton, Switch, Tooltip, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { queryMe, updateUserInfoNonAuth } from 'services/clients/apiClient.services';
import UserUpdateProfile from './UserUpdateProfile';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#3C4043' : '#FCD34D',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const ProfileUser = () => {
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const queryClient = useQueryClient();
  const theme = useTheme();
  const styles = {
    profileContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    titleContainer: {
      p: '18px 0',
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'space-between',
      mb: '20px',
    },
    titleItem: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      // color: '#333',
    },
    subTitleItem: {
      mt: '0.1875rem',
      fontSize: '.875rem',
      lineHeight: '1.0625rem',
      // color: '#555',
    },
    formInfoContainer: {
      flex: '3',
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      pt: '20px',
    },
    avatarContainer: {
      flex: '1',
      mr: '20px',
      borderRight: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    avatar: {
      height: '100px',
      width: '100px',
      mb: '20px',
      border: `1px solid ${theme.palette.divider}`,
    },
    userNameTitle: {
      fontSize: '1.3rem',
      fontWeight: 600,
    },
    fieldItemBox: {
      height: '20px',
      display: 'flex',
      mb: '20px',
    },
    fieldItemBoxTitle: {
      mr: '30px',
      width: '120px',
      textAlign: 'right',
    },
  };

  // Call API Get profile
  const { data: userInfo, refetch } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const userProfileData = useMemo(() => {
    return userInfo?.data;
  }, [userInfo]);

  const handleOpenEditProfile = () => {
    setOpenEditProfile(true);
  };
  const handleCloseEditProfile = () => {
    setOpenEditProfile(false);
    queryClient.invalidateQueries([QUERY_KEY.CHECK_SUPER_ADMIN]);
    refetch();
  };

  const getDate = useCallback((dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
  }, []);

  const useUpdateProfileData = () => {
    return useMutation(
      (data: any) =>
        updateUserInfoNonAuth({
          ...data,
          theme: localStorage.getItem('theme') || 'light',
        }),
      {
        onSuccess: () => {
          toast.success('Update theme successfully');
          refetch();
        },
        onError: () => {
          toast.error('Update theme failed');
        },
      }
    );
  };

  const mutationUpdateProfileData = useUpdateProfileData();

  const handleToggleTheme = () => {
    const theme = localStorage.getItem('theme');
    if (theme === 'light') {
      localStorage.setItem('theme', 'dark');
      mutationUpdateProfileData.mutate({ theme: 'dark' });
    } else {
      localStorage.setItem('theme', 'light');
      mutationUpdateProfileData.mutate({ theme: 'light' });
    }
    window.location.reload();
  };

  return (
    <Box
      sx={{
        ...styles.profileContainer,
      }}>
      {/* Title */}
      <Box
        sx={{
          ...styles.titleContainer,
        }}>
        <Box>
          <Typography
            sx={{
              ...styles.titleItem,
            }}>
            My Profile
          </Typography>
          <Typography
            sx={{
              ...styles.subTitleItem,
            }}>
            Manage and protect your account
          </Typography>
        </Box>
        <Box>
          <Tooltip
            arrow
            title={localStorage.getItem('theme') === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}>
            <MaterialUISwitch onChange={() => handleToggleTheme()} checked={localStorage.getItem('theme') === 'dark'} />
          </Tooltip>
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: '10px',
              marginBottom: '0',
              textAlign: 'center',
              textTransform: 'capitalize',
            }}
            onClick={handleOpenEditProfile}>
            Update profile
          </Button>
        </Box>
      </Box>
      <UserUpdateProfile open={openEditProfile} onClose={handleCloseEditProfile} />
      {/* Content */}
      {userProfileData ? (
        <Box
          sx={{
            ...styles.contentContainer,
          }}>
          <Box
            sx={{
              ...styles.avatarContainer,
            }}>
            <Avatar
              src={userProfileData?.avatar}
              sx={{
                ...styles.avatar,
              }}
            />
            <Typography
              sx={{
                ...styles.userNameTitle,
              }}>
              {userProfileData?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              ...styles.formInfoContainer,
            }}>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.userNameTitle,
                }}>
                Basic Info
              </Typography>
            </Box>
            <Box />
            <Box sx={{ border: '0.5px solid #dddddd', mb: '8px' }} />
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Email
              </Typography>
              {userProfileData?.email ? <Typography>{userProfileData?.email}</Typography> : <Typography>-</Typography>}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Phone Number
              </Typography>
              {userProfileData?.phoneNumber ? (
                <Typography>{userProfileData?.phoneNumber}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Address
              </Typography>
              {userProfileData?.address ? (
                <Typography>{userProfileData?.address}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Zip Code
              </Typography>
              {userProfileData?.zipCode ? (
                <Typography>{userProfileData?.zipCode}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                State
              </Typography>
              {userProfileData?.state ? <Typography>{userProfileData?.state}</Typography> : <Typography>-</Typography>}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Country
              </Typography>
              {userProfileData?.country ? (
                <Typography>{userProfileData?.country}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.userNameTitle,
                }}>
                Work Information
              </Typography>
            </Box>
            <Box />
            <Box sx={{ border: '0.5px solid #dddddd', mb: '8px' }} />
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Job Title
              </Typography>
              {userProfileData?.jobTitle ? (
                <Typography>{userProfileData?.jobTitle}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Joined Date
              </Typography>
              {userProfileData?.joinDate ? (
                <Typography>{getDate(userProfileData?.joinDate)}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Department
              </Typography>
              {userProfileData?.department ? (
                <Typography>{userProfileData?.department}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Company
              </Typography>
              {userProfileData?.company ? (
                <Typography>{userProfileData?.company}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Report To
              </Typography>
              {userProfileData?.manager ? (
                <Typography>{userProfileData?.manager}</Typography>
              ) : (
                <Typography
                  sx={{
                    color: '#999999',
                  }}>
                  None
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Work Location
              </Typography>
              {userProfileData?.workLocation ? (
                <Typography>{userProfileData?.workLocation}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            ...styles.contentContainer,
          }}>
          <Box
            sx={{
              ...styles.avatarContainer,
            }}>
            <Skeleton
              sx={{
                ...styles.avatar,
              }}
              variant="circular"
              width={100}
              height={100}
            />
            <Typography
              sx={{
                ...styles.userNameTitle,
              }}>
              <Skeleton variant="text" width={100} />
            </Typography>
          </Box>
          <Box
            sx={{
              ...styles.formInfoContainer,
            }}>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Name
              </Typography>
              <Skeleton variant="text" width={100} />
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Email
              </Typography>
              <Skeleton variant="text" width={100} />
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Phone Number
              </Typography>
              <Skeleton variant="text" width={100} />
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Gender
              </Typography>
              <Skeleton variant="text" width={100} />
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Date of Birth
              </Typography>
              <Skeleton variant="text" width={100} />
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Address
              </Typography>
              <Skeleton variant="text" width={100} />
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Zip Code
              </Typography>
              <Skeleton variant="text" width={100} />
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                State
              </Typography>
              <Skeleton variant="text" width={100} />
            </Box>
            <Box
              sx={{
                ...styles.fieldItemBox,
              }}>
              <Typography
                sx={{
                  ...styles.fieldItemBoxTitle,
                }}>
                Country
              </Typography>
              <Skeleton variant="text" width={100} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProfileUser;
