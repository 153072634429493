import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { CssBaseline, PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ErrorFallback from 'components/Common/ErrorFallback';
import i18nConfig from 'locales/configs';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { Root } from 'root';
import InjectAxiosInterceptors from 'services/InjectAxiosInterceptors';
import './App.scss';

const App = () => {
  const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              main: '#23be6a',
              contrastText: 'rgba(255,255,255,0.87)',
            },
            secondary: {
              main: '#f5004e',
            },
            divider: '#EFEFEF',
            neutral: {
              main: '#64748B',
              contrastText: '#fff',
            },
            color: {
              green: '#10B981',
              green1: '#00B09B',
              green2: '#96C93D',
              green3: '#409576',
              green4: '#23BE6A',
              green5: '#6FE4A5',
              white: '#FFF',
              white1: '#FFF',
              white2: '#E0E0E0',
              white3: '#E2E4E5',
              grey: '#E1E5EA',
              grey1: '#F1F1F2',
              grey2: '#9E9E9E',
              grey3: '#CACED3',
              grey4: 'rgba(99, 99, 99, 0.2)',
              grey5: '#737373',
              grey6: 'rgba(97, 97, 97, 0.1)',
              grey7: '#A8AEB1',
              grey8: '#C5C9CB',
              grey9: '#616161',
              black: '#000',
              black1: '#3C4043',
              black2: 'rgba(97, 97, 97)',
              red1: '#FF4646',
              yellow300: '#FCD34D',
            },
            background: {
              default: '#fff',
              paper: '#F7F8F8',
            },
          }
        : {
            primary: {
              main: '#4FE3AE',
              contrastText: 'rgba(255,255,255,0.87)',
            },
            secondary: {
              main: '#f5004e',
            },
            divider: 'rgba(81, 81, 81)',
            neutral: {
              main: '#64748B',
              contrastText: '#fff',
            },
            color: {
              green: '#10B981',
              green1: '#00B09B',
              green2: '#96C93D',
              green3: '#409576',
              green4: '#23BE6A',
              green5: '#6FE4A5',
              white: '#000',
              white1: '#1F1F1F',
              white2: '#3C4043',
              white3: '#000',
              grey: '#E1E5EA',
              grey1: '#F1F1F2',
              grey2: '#9E9E9E',
              grey3: '#CACED3',
              grey4: 'rgba(99, 99, 99, 0.2)',
              grey5: '#737373',
              grey6: 'rgba(97, 97, 97, 0.1)',
              grey7: '#A8AEB1',
              grey8: '#C5C9CB',
              grey9: '#616161',
              black: '#fff',
              black1: '#3C4043',
              black2: 'rgba(97, 97, 97)',
              red1: '#FF4646',
              yellow300: '#FCD34D',
            },
            background: {
              default: '#1e1e1e',
              paper: '#24292e',
            },
          }),
    },
    ...(mode === 'light'
      ? {
          spacing: 8,
          typography: {
            fontFamily: 'Barlow',
            fontWeightLight: 300,
            h5: {
              fontSize: '1.3rem !important',
              fontWeight: 600,
              color: '#3C4043',
            },
            h1: {
              fontSize: '1.5rem !important',
              fontWeight: 600,
              color: '#3C4043',
            },
            h2: {
              fontSize: '1.3rem !important',
              fontWeight: 500,
              color: '#3C4043',
            },
            subtitle1: {
              fontSize: '0.9rem',
            },
            button: {
              textTransform: 'none' as const,
            },
          },
          components: {
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  fontSize: '.875rem',
                  color: '#3C4043',
                  borderRadius: '5px',
                  borderColor: '#CACED3',
                },
              },
            },
            MuiTypography: {
              styleOverrides: {
                root: {
                  fontSize: '.875rem',
                },
                h1: {
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  color: '#3C4043',
                },
              },
            },
            MuiInputLabel: {
              styleOverrides: {
                root: {
                  fontSize: '.875rem',
                  fontWeight: 500,
                  color: '#737373',
                  marginBottom: '.5rem',
                },
              },
            },
            MuiButton: {
              styleOverrides: {
                root: {
                  borderRadius: '5px',
                },
              },
            },
          },
        }
      : {
          spacing: 8,
          typography: {
            fontFamily: 'Barlow',
            fontWeightLight: 300,
            h5: {
              fontSize: '1.3rem !important',
              fontWeight: 600,
              color: '#fff',
            },
            h1: {
              fontSize: '1.5rem !important',
              fontWeight: 600,
              color: '#fff',
            },
            h2: {
              fontSize: '1.3rem !important',
              fontWeight: 500,
              color: '#fff',
            },
            subtitle1: {
              fontSize: '0.9rem',
            },
            button: {
              textTransform: 'none' as const,
            },
          },
          components: {
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  fontSize: '.875rem',
                  color: '#fff',
                  borderRadius: '5px',
                  borderColor: '#CACED3',
                },
              },
            },
            MuiTypography: {
              styleOverrides: {
                root: {
                  fontSize: '.875rem',
                },
                h1: {
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  color: '#fff',
                },
              },
            },
            MuiInputLabel: {
              styleOverrides: {
                root: {
                  fontSize: '.875rem',
                  fontWeight: 500,
                  color: '#fff',
                  marginBottom: '.5rem',
                },
              },
            },
            MuiButton: {
              styleOverrides: {
                root: {
                  borderRadius: '5px',
                },
              },
            },
          },
        }),
  });

  const [theme, setTheme] = useState('light');
  const { i18n } = useTranslation();
  const localLanguage = localStorage.getItem('localLanguage');

  useEffect(() => {
    const localTheme = localStorage.getItem('theme');
    if (localTheme) {
      setTheme(localTheme);
    }
  }, [theme]);

  // set language for the whole app
  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (localLanguage) {
      i18n.changeLanguage(localLanguage);
    } else {
      if (timeZone === 'Asia/Tokyo') {
        localStorage.setItem('localLanguage', 'jp');
        i18n.changeLanguage('jp');
      } else {
        localStorage.setItem('localLanguage', 'en');
        i18n.changeLanguage('en');
      }
    }
  }, [i18n, localLanguage]);

  const darkModeTheme = createTheme(getDesignTokens(theme === 'dark' ? 'dark' : 'light'));

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18nConfig}>
        <BrowserRouter>
          <InjectAxiosInterceptors />
          <ThemeProvider theme={darkModeTheme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={DateAdapter}>
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                  // reset the state of your app so the error doesn't happen again
                }}>
                <Root />
              </ErrorBoundary>
            </LocalizationProvider>
          </ThemeProvider>
        </BrowserRouter>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          transition={Slide}
          theme={theme === 'dark' ? 'dark' : 'light'}
          toastStyle={{
            background: theme === 'dark' ? '#24292e' : '#F7F8F8',
            border: theme === 'dark' ? '1px solid #3C4043' : '1px solid #CACED3',
          }}
        />
        <ReactQueryDevtools position="bottom-right" />
      </I18nextProvider>
    </QueryClientProvider>
  );
};

export default App;
