import useClientDataConfig from 'hooks/useClientDataConfig';
import { useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import {
  changeFixedSizePointTreeTiltFallen,
  changeZoomLayerTreeTiltFallen,
  treeTiltFallenAnalyticsSelector,
} from '../../../store/slices/map-view/treeTiltFallenAnalytics';
import { useClientData } from '../../useClientData';
import { useGetClientSettingMapView } from '../useGetClientSettingMapView';

export const useTreeTiltFallenAnalytics = ({ mapRef }: any) => {
  const { isLayer3D } = useAppSelector(mapViewSelector);
  const { dataGeoJsonPolygon, dataGeoJsonPoint } = useAppSelector(treeTiltFallenAnalyticsSelector);

  const { colorTransparent, isSameColorTopSurface, getOpacityExtrusion } = useGetClientSettingMapView();
  const dispatch = useDispatch();
  const { isFixedPoint, value } = useClientDataConfig();
  const { analyticId } = useAppSelector(mapViewSelector);

  const { contourSetting } = useClientData();

  const zoomLevel = useMemo(() => {
    if (!mapRef?.current) return 0;
    return mapRef?.current?.getZoom();
  }, [mapRef?.current?.getZoom()]);

  useEffect(() => {
    if (!mapRef?.current) return;

    if (isFixedPoint && value) {
      dispatch(changeFixedSizePointTreeTiltFallen(value));
    } else dispatch(changeZoomLayerTreeTiltFallen(zoomLevel));
  }, [dispatch, isFixedPoint, mapRef, value, zoomLevel, analyticId]);

  const layerTreeTiltFallen = useMemo(() => {
    if (!dataGeoJsonPoint && !dataGeoJsonPolygon) {
      return null;
    }

    return (
      <>
        {dataGeoJsonPoint && (
          <Source id={`TreeTiltFallen-polygon-line`} type="geojson" data={dataGeoJsonPoint}>
            {/* 2D Cylinder */}
            <Layer
              id={`treeTileFallen_line`}
              type="line"
              paint={{
                'line-color': ['get', 'color'],
                'line-width': 2,
              }}
              source={`TreeTiltFallen-polygon-line`}
              layout={{ visibility: !isLayer3D ? 'visible' : 'none' }}
            />
            {/* 3D Cylinder */}
            <Layer
              id={`treeTiltFallen_fill-polygon`}
              type="fill"
              paint={{
                'fill-color': ['get', 'color'],
                'fill-opacity': 1,
              }}
              source={`TreeTiltFallen-polygon-line`}
              layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
            />
            <Layer
              id={`treeTiltFallen_top-surface`}
              type="fill-extrusion"
              beforeId={`treeTiltFallen_fill-polygon`}
              source={`TreeTiltFallen-polygon-line`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
            />
            <Layer
              id={`treeTiltFallen_side-surface`}
              type="fill-extrusion"
              beforeId={`treeTiltFallen_top-surface`}
              source={`TreeTiltFallen-polygon-line`}
              filter={['!=', 'color', 'transparent']}
              paint={{
                'fill-extrusion-color': isSameColorTopSurface ? ['get', 'color'] : colorTransparent,
                'fill-extrusion-height': contourSetting,
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': getOpacityExtrusion,
                'fill-extrusion-vertical-gradient': false,
              }}
              layout={{ visibility: isLayer3D ? 'visible' : 'none' }}
            />
          </Source>
        )}
      </>
    );
  }, [dataGeoJsonPoint, dataGeoJsonPolygon, isLayer3D]);
  return { layerTreeTiltFallen };
};
