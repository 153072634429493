import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export interface ISuperAdminSlice {
  isSuperAdmin: boolean;
}

const initialState: ISuperAdminSlice = {
  isSuperAdmin: false,
};

export const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    checkSuperAdmin: (state, action) => {
      state.isSuperAdmin = action.payload;
    },
  },
});

export const { checkSuperAdmin } = superAdminSlice.actions;

export const superAdminSelector = (state: RootState) => state.superAdmin;

export default superAdminSlice.reducer;
