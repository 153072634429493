import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { IClientData, IFormType } from 'common/defines/clients';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { changeBreadScrumbsSelected } from 'store/slices/breadcrumbsSlice';
import { changeUserSelected } from 'store/slices/userSlice';
import { ClientMenu } from './ClientMenu';

interface ClientCardType {
  clientDetail: IClientData;
  setClientIndex: (val: string) => void;
  setOpenDeleteModal: (val: boolean) => void;
}

export const ClientCard = ({ clientDetail, setClientIndex, setOpenDeleteModal }: ClientCardType) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const styles = {
    userCardStyles: {
      minWidth: '120px',
      width: '120px',
      height: '120px',
      backgroundColor: theme.palette.mode === 'dark' ? '#1F1F1F' : '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      borderRadius: '10px',
      textTransform: 'none',
      position: 'relative',
      boxShadow: '0 1px 2px 0 rgba(0, 0 ,0, .1), 0 1px 3px 0 rgba(0, 0, 0, 0.06)',
      border: `1px solid ${theme.palette.divider}`,
    },
    userNameStyles: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '14px',
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#616161',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      width: '100px',
    },
    avatarStyles: {
      height: '50px',
      width: '50px',
      m: 'auto',
      mt: 1.75,
      mb: 1.5,
    },
  };

  const handleEditClients = useCallback(() => {
    navigate(`/clients/edit/${clientDetail._id}`, { state: IFormType.EDIT });
    dispatch(changeBreadScrumbsSelected('Edit Client'));
  }, [navigate, clientDetail, dispatch]);

  const initialAnchorEl = {
    client: null,
    removeClient: null,
  };

  const [anchorEl, setAnchorEl] = useState<{
    client: null | HTMLElement;
    removeClient: null | HTMLElement;
  }>(initialAnchorEl);

  const openMenu = (anchorName: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl({
      ...anchorEl,
      [anchorName]: event.currentTarget,
    });
  };
  const closeMenu = (anchorName: string) => () => {
    setAnchorEl({
      ...anchorEl,
      [anchorName]: null,
    });
  };

  return (
    <Box
      key={clientDetail._id}
      sx={{
        ...styles.userCardStyles,
      }}>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
        }}>
        <ClientMenu
          open={Boolean(anchorEl.removeClient)}
          anchorEl={anchorEl.removeClient}
          onOpen={openMenu('removeClient')}
          onDelete={() => {
            setClientIndex(clientDetail._id);
            setOpenDeleteModal(true);
          }}
          handleEdit={handleEditClients}
          onClose={closeMenu('removeClient')}
          mainMenu
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
        }}
        onClick={() => {
          dispatch(changeUserSelected(clientDetail._id));
        }}>
        <Avatar sx={{ ...styles.avatarStyles }} src={clientDetail.avatar} />
        <Typography sx={{ ...styles.userNameStyles }}>{clientDetail.name}</Typography>
      </Box>
    </Box>
  );
};
