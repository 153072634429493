export interface DrawBarData {
  type: DrawIssueShapeEnum;
  isSelected: boolean;
}

export interface IDateRange {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  key?: string;
}

export enum DrawIssueShapeEnum {
  IMAGE = 'IMAGE',
  LINE = 'LINE',
  SQUARE = 'SQUARE',
  POLYGON = 'POLYGON',
  CIRCLE = 'CIRCLE',
  POINT = 'POINT',
}

export interface IIssue {
  _id: string;
  levelId: string;
  type: DrawIssueShapeEnum;
  images: string[];
  description: string;
  document: string;
  length?: number;
  area?: number;
  date: string | Date;
  geometry: {
    type: string;
    coordinates: number[];
  };
  status?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  border?: {
    color: string;
    style: string;
    width: number;
  };
  isNonGeotag?: boolean;
  isDisplayBoundaryLine: boolean;
  isLayer3D: boolean;
  colorSideSurface: string;
  sideSurfaceOpacity: number;
  isSameColorTopSurface: boolean;
  topSurfaceOpacity: number;
  isSameSideSurface: boolean;
}

export interface IGeoTaggedPhoto {
  geometry: {
    type: string;
    coordinates: number[];
  };
  path: string;
}

export enum SortDateEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IIssuePagination {
  status?: boolean;
  page: number;
  perPage: number;
  sortDate: SortDateEnum;
  startDate: string;
  endDate: string;
  paginationVersion: number;
  levelId: string;
}

export enum ExportFileEnum {
  IMAGE = 'IMAGE',
  FILE = 'FILE',
  KML = 'KML',
}

export interface IShapeInfo {
  area: number;
  length: number;
}

export enum LineStyleEnum {
  DASHED = 'DASHED',
  SOLID = 'SOLID',
}

export enum MediaTypeEnum {
  DOCUMENT = 'document',
  IMAGE = 'image',
}
