import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface IClientDataUserSlice {
  _id?: string;
}

export const clientDataUserSlice = createSlice({
  name: 'clientDataUser',
  initialState: {
    _id: '',
  },
  reducers: {
    changeId: (state: IClientDataUserSlice, action: PayloadAction<any>) => {
      state._id = action.payload;
    },
  },
} as any);

export const { changeId } = clientDataUserSlice.actions;

export const clientDataUserSelector = (state: RootState) => state.clientDataUser;

export default clientDataUserSlice.reducer;
