import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ComparedIndex, MapEnum } from 'common/defines/constants';
import CommonModal from 'components/Common/CommonModal';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { clearMapPopupData } from 'store/slices/mapPopupSlice';
import ComparedAnalytic from './ComparedAnalytic';

interface TemporalModalProps {
  isShow: boolean;
  handleClose: () => void;
  mode: MapEnum;
}

const TemporalModal: FC<TemporalModalProps> = ({ isShow, handleClose, mode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mapViewStylesClasses = useMapViewStyle();
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    handleClose();
    dispatch(clearMapPopupData());
  };

  return (
    <CommonModal isShow={isShow} handleClose={onCloseModal} style={{ height: '100%', width: '100%' }}>
      <Box sx={{ p: '24px', width: '99vw' }}>
        <Typography className={mapViewStylesClasses.titleInput} sx={{ mb: '24px' }}>
          {t('trans.temporal_analysis')}
        </Typography>
        <Grid container>
          <Grid item xs={6} sx={{ pr: '20px' }}>
            <ComparedAnalytic mode={mode} comparedIndex={ComparedIndex.FIRST} />
          </Grid>
          <Grid item xs={6} sx={{ borderLeft: `1px solid ${theme.palette.divider}`, pl: '20px' }}>
            <ComparedAnalytic mode={mode} comparedIndex={ComparedIndex.SECOND} />
          </Grid>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default TemporalModal;
