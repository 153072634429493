import { makeStyles } from '@mui/styles';

export const useAnalyticsConfigStyle = makeStyles((theme: any) => ({
  navBarButton: {
    width: '27px !important',
    minWidth: '27px !important',
    height: '27px !important',
    top: '20px',
    border: `1px solid ${theme.palette.color.grey8} !important`,
    backgroundColor: `${theme.palette.background.paper} !important`,
    zIndex: 9,
  },
  arrowIcon: {
    color: theme.palette.color.grey7,
  },
  columnNavbar: {
    height: '100%',
  },
  showPanelBtn: {
    width: '27px !important',
    minWidth: '27px !important',
    height: '27px !important',
    border: `1px solid ${theme.palette.color.grey8} !important`,
    top: '80px',
    display: 'inline-flex',
    right: '438px',
    backgroundColor: `${theme.palette.color.white} !important`,
    zIndex: 9,
  },
  showPanelBtnInactive: {
    width: '24px !important',
    minWidth: '24px !important',
    height: '24px !important',
    borderRadius: '20px',
    boxShadow: `0px 2px 10px ${theme.palette.color.grey5}`,
    top: '40px',
    display: 'none',
    right: 'none',
    backgroundColor: `${theme.palette.color.white} !important`,
    zIndex: 9,
  },
  dataConfigurationCard: {
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
    padding: '0 30px 0 30px',
    background: theme.palette.background.paper,
    borderRadius: '8px',
    height: 'calc(100vh - 240px)',
    width: '100%',
    overflow: 'auto',
    border: `1px solid ${theme.palette.divider}`,
  },
}));
