import { TabContext, TabPanel } from '@mui/lab';
import { Box, Typography, useTheme } from '@mui/material';
import AccessControl from 'components/AccessControl/AccessControl';
import { useState } from 'react';
import CropTypeSettings from './CroptypeSetting/CropTypeSettings';
import { TabsBarSettings } from './TabsBarSetting';

const SettingsPage = () => {
  const [value, setValue] = useState('Type Configuration');
  const theme = useTheme();

  const styles = {
    cropTypeSettings: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    cropTypeSettingsBox: {
      width: '100%',
      height: '100%',
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '6px',
      boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
    },
    headerBox: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      fontSize: '20px',
      fontWeight: '600',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      p: '20px',
    },
    tabPanel: {
      p: '0',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      textTransform: 'none',
    },
    navigateHomeBtn: {
      width: '200px',
      height: '40px',
      border: `1px solid ${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: 'white !important',
      '&:hover': {
        backgroundColor: '#1C9D57 !important',
        border: `1px solid ${theme.palette.primary.main} !important`,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#1C9D57 !important',
        border: `1px solid ${theme.palette.primary.main} !important`,
      },
      '&:focus': {
        boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15) !important',
        border: `1px solid ${theme.palette.primary.main} !important`,
      },
    },
  };

  return (
    <Box
      sx={{
        ...styles.cropTypeSettings,
      }}>
      <Box
        sx={{
          ...styles.cropTypeSettingsBox,
        }}>
        <Box
          sx={{
            ...styles.headerContainer,
          }}>
          <Typography
            sx={{
              ...styles.headerBox,
            }}>
            Settings
          </Typography>
        </Box>
        {/* Table Content */}
        <TabsBarSettings tab={value} setTab={setValue} />
        <TabContext value={value}>
          {/* <TabPanel
              sx={{
                ...styles.tabPanel,
              }}
              value="Analytics configuration">
              <AnalysisSettings />
            </TabPanel> */}
          <TabPanel
            sx={{
              ...styles.tabPanel,
            }}
            value="Type Configuration">
            <CropTypeSettings />
          </TabPanel>
          {/* <TabPanel
            sx={{
              ...styles.tabPanel,
            }}
            value="Role Configuration">
            <PermissionTable />
          </TabPanel> */}
          <TabPanel
            sx={{
              ...styles.tabPanel,
            }}
            value="Access Control">
            <AccessControl />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default SettingsPage;
