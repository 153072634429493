import {
  Box,
  InputLabel,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { BigNumber } from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';

const styles = {
  userListTitle: {
    py: '10px',
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18.2px',
    textAlign: 'center',
  },
  clientUpdateTime: {
    textAlign: 'center',
    px: '16px',
  },
};

const LandUseTable = () => {
  const { allLocationCropType } = useAppSelector(clientSelector);
  const { clientId } = useParams();
  const { t } = useTranslation();

  const [typeSortArea, setTypeSortArea] = useState(true);
  const [typeSortDistance, setTypeSortDistance] = useState(true);

  const formatData = (data: any[]) => {
    const helper = {} as any;
    const result = data.reduce((pre, current) => {
      const key = current.landUse;

      if (!helper[key]) {
        helper[key] = Object.assign({}, current);
        pre.push(helper[key]);
      } else {
        helper[key].area += current.area;
        helper[key].length += current.length;
      }

      return pre;
    }, []);
    return result;
  };

  const generatePercent = (data: any[]) => {
    const sumArea = data
      .map((_item: any) => _item.area)
      .reduce((previousValue: number, currentValue: number) => new BigNumber(previousValue).plus(currentValue), 0);
    let sumPercent = new BigNumber(0);

    const dataPercent = data.map((_item: any, index: number) => {
      if (index === data.length - 1) {
        return {
          ..._item,
          percent: new BigNumber(100).minus(sumPercent).toNumber(),
        };
      }
      const percent = new BigNumber(_item.area).div(sumArea).multipliedBy(100).toFixed(2);
      sumPercent = new BigNumber(percent).plus(sumPercent);
      return {
        ..._item,
        percent: +percent,
      };
    });
    return dataPercent;
  };

  const summaryLandUseAnalysis = (data: any[]) => {
    if (data.length > 0) {
      const result = formatData(data);
      const dataPercent = generatePercent(result);
      return dataPercent;
    }
    return [];
  };

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const lengthSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.LENGTH) ||
      defaultClientUnitSetting[1]
    );
  }, [clientSetting]);

  const clientLengthUnitMeasurement = useCallback(
    (length: any) => {
      switch (lengthSetting.unit) {
        case MeasurementUnit.KM:
          return new BigNumber(length * 0.001).toFixed(lengthSetting.decimalPlace);
        case MeasurementUnit.MILES:
          return new BigNumber(length * 0.000621371).toFixed(lengthSetting.decimalPlace);
        default:
          return length ? new BigNumber(length).toFixed(lengthSetting.decimalPlace) : null;
      }
    },
    [lengthSetting]
  );

  const clientAreaUnitMeasurement = useCallback(
    (area: any) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return new BigNumber(area * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return new BigNumber(area * 0.01).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return new BigNumber(area * 10000).toFixed(areaSetting.decimalPlace);
        default:
          return new BigNumber(area).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const tableLandUseArea = useMemo(() => {
    if (allLocationCropType && allLocationCropType.length > 0) {
      const mergeArray = allLocationCropType.reduce((pre: any, curent: any) => {
        return pre.concat(curent.landUseAnalysisSummary);
      }, []);
      //if exist m_use value, the area value will follow m_use data -> ticket AER21163-943
      const convertedMergeArray = mergeArray.map((item: { museArea: number | null; area: number }) => ({
        ...item,
        area: item.museArea ? item.museArea : item.area,
      }));
      const formatArray = summaryLandUseAnalysis(convertedMergeArray);

      const totalArea = formatArray.reduce((pre: any, current: any) => {
        return pre + current.area;
      }, 0);

      const totalAreaPercent = formatArray.reduce((pre: any, current: any) => {
        return new BigNumber(pre).plus(current.percent);
      }, 0);

      const sortedArray = formatArray.sort((a: any, b: any) => {
        return typeSortArea ? a.area - b.area : b.area - a.area;
      });

      if (sortedArray && sortedArray.length > 0) {
        return (
          <>
            {sortedArray.map((item: any, index: number) => {
              return (
                item.area > 0 && (
                  <TableRow key={index}>
                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                        textAlign: 'left',
                        minWidth: '30px',
                      }}>
                      {item.landUse}
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                        textAlign: 'left',
                        minWidth: '60px',
                      }}>
                      {clientAreaUnitMeasurement(item.area)}
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                        textAlign: 'left',
                        minWidth: '60px',
                      }}>
                      {item.percent} %
                    </TableCell>
                  </TableRow>
                )
              );
            })}
            <TableRow>
              <TableCell
                component="th"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}>
                {t('trans.total')}:
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}>
                {clientAreaUnitMeasurement(totalArea)}
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}>
                {totalAreaPercent.toNumber()}%
              </TableCell>
            </TableRow>
          </>
        );
      }
    }
  }, [allLocationCropType, clientAreaUnitMeasurement, summaryLandUseAnalysis, t, typeSortArea]);

  const tableLandUseDistance = useMemo(() => {
    if (allLocationCropType && allLocationCropType.length > 0) {
      const mergeArray = allLocationCropType.reduce((pre: any, curent: any) => {
        return pre.concat(curent.landUseAnalysisSummary);
      }, []);
      const formatArray = summaryLandUseAnalysis(mergeArray);

      const sortedArray = formatArray.sort((a: any, b: any) => {
        return typeSortDistance ? a.length - b.length : b.length - a.length;
      });

      if (sortedArray && sortedArray.length > 0) {
        return (
          <>
            {sortedArray.map((item: any, index: number) => {
              return (
                item.length > 0 && (
                  <TableRow key={index}>
                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                        textAlign: 'left',
                        minWidth: '30px',
                      }}>
                      {item.landUse}
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                        textAlign: 'left',
                        minWidth: '30px',
                      }}>
                      {clientLengthUnitMeasurement(item.length)} {`${lengthSetting.unit}`}
                    </TableCell>
                  </TableRow>
                )
              );
            })}
          </>
        );
      }
    }
  }, [allLocationCropType, clientLengthUnitMeasurement, lengthSetting.unit, summaryLandUseAnalysis, typeSortDistance]);

  const handleSortTable = useCallback(
    (typeSort: string) => {
      if (typeSort === 'area') {
        setTypeSortArea(!typeSortArea);
      } else {
        setTypeSortDistance(!typeSortDistance);
      }
    },
    [typeSortArea, typeSortDistance]
  );

  const theme = useTheme();

  return (
    <>
      <>
        <InputLabel sx={{ color: (theme) => theme.palette.primary.main }}>{`${t('analytic.land_use')} (${t(
          'trans.area'
        )})`}</InputLabel>
        <TableContainer
          sx={{
            overflow: 'hidden',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '5px',
            mt: '-1px',
            mb: '12px',
          }}>
          <Table>
            <TableHead>
              {
                <TableRow
                  component="tr"
                  sx={{
                    backgroundColor: (theme) => theme.palette.background.paper,
                  }}>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    {t('trans.main_category')}
                  </TableCell>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {`${t('trans.area')} (${areaUnit})`}
                      <SvgIcon
                        component={typeSortArea ? DescIcon : AscIcon}
                        inheritViewBox
                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => handleSortTable('area')}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {t('trans.percentage')}
                      <SvgIcon
                        component={typeSortArea ? DescIcon : AscIcon}
                        inheritViewBox
                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => handleSortTable('area')}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableHead>
            <TableBody
              sx={{
                pt: 0,
                flexDirection: 'column',
                minHeight: '510px',
                maxHeight: '510px',
                overflowY: 'scroll',
                '-ms-overflow-style': 'none' /* IE and Edge */,
                scrollbarWidth: 'none' /* Firefox */,
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
              {tableLandUseArea}
            </TableBody>
          </Table>
        </TableContainer>
      </>

      <Box sx={{ py: 2 }}>
        <InputLabel sx={{ color: (theme) => theme.palette.primary.main }}>{`${t('analytic.land_use')} (${t(
          'trans.distance'
        )})`}</InputLabel>
        <TableContainer>
          <Table>
            <TableHead>
              {
                <TableRow
                  component="tr"
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                  }}>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    {t('trans.main_category')}
                  </TableCell>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {t('trans.length')}
                      <SvgIcon
                        component={typeSortDistance ? DescIcon : AscIcon}
                        inheritViewBox
                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => handleSortTable('distance')}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableHead>
            <TableBody
              sx={{
                pt: 0,
                flexDirection: 'column',
                minHeight: '510px',
                maxHeight: '510px',
                overflowY: 'scroll',
                '-ms-overflow-style': 'none' /* IE and Edge */,
                scrollbarWidth: 'none' /* Firefox */,
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
              {tableLandUseDistance}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default LandUseTable;
