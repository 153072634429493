import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import SwitchAccountRoundedIcon from '@mui/icons-material/SwitchAccountRounded';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import PermissionPage from './PermissionPage';
import { RolePage } from './RolePage';
import UIConfigurationPage from './UIConfigurationPage';

const styles = {
  tabControlUser: {
    flexGrow: 1,
    display: 'flex',
    '.MuiButtonBase-root': {
      justifyContent: 'start',
    },
  },
  tabsList: {
    borderRight: 1,
    borderColor: 'divider',
    minWidth: '220px',
  },
  iconTab: {
    width: '28px',
    height: '28px',
    margin: '0 12px !important',
  },
};

interface IAccessControlProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: IAccessControlProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        width: '100%',
      }}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AccessControl = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        ...styles.tabControlUser,
      }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{
          ...styles.tabsList,
        }}>
        <Tab
          icon={
            <SwitchAccountRoundedIcon
              sx={{
                ...styles.iconTab,
              }}
            />
          }
          iconPosition="start"
          label="Role"
        />
        <Tab
          icon={
            <PreviewRoundedIcon
              sx={{
                ...styles.iconTab,
              }}
            />
          }
          iconPosition="start"
          label="UI Configuration"
        />
        <Tab
          icon={
            <LockOpenRoundedIcon
              sx={{
                ...styles.iconTab,
              }}
            />
          }
          iconPosition="start"
          label="Permission"
        />
      </Tabs>
      {/* Role */}
      <TabPanel value={value} index={0}>
        <RolePage />
      </TabPanel>
      {/* UI Configuration */}
      <TabPanel value={value} index={1}>
        <UIConfigurationPage />
      </TabPanel>
      {/* Permission */}
      <TabPanel value={value} index={2}>
        <PermissionPage />
      </TabPanel>
    </Box>
  );
};

export default AccessControl;
