import { TabContext, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import AppLayout from 'components/AppLayout';
import { ClientsListing } from 'components/ClientsInfo/ClientsListing';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import AuditTrail from 'components/Dashboard/AuditTrail/AuditTrail';
import { TabsBarAdmin } from 'components/Dashboard/TabsBarAdmin';
import { UserManagement } from 'components/Dashboard/UserProfile/UserManagement';
import ErrorsPage from 'components/ErrorsPage';
import Overview from 'components/Overview';
import { QUERY_KEY } from 'constants/constants';
import SettingsPage from 'pages/SettingsPage';
import { ReactElement, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { queryMe } from 'services/clients/apiClient.services';

const TabPanelCustom = styled(TabPanel)(({ theme }) => ({
  ...theme.mixins.toolbar,
  paddingBottom: 0,
}));

export const DashboardAdminPage = (): ReactElement => {
  const [value, setValue] = useState('Overview');
  const { data: checkSuperAdmin, isLoading } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: true,
  });

  const isSuperAdmin = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperAdmin;
  }, [checkSuperAdmin]);
  const isSuperUser = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperUser;
  }, [checkSuperAdmin]);

  const dashBoardAdmin = useMemo(() => {
    if (isLoading) {
      return <WaitingPageAPI />;
    } else if (isSuperAdmin || isSuperUser) {
      return (
        <TabContext value={value}>
          <TabsBarAdmin tab={value} setTab={setValue} />
          <ClientsListing tab={value} />
          <TabContext value={value}>
            <TabPanelCustom value="Overview">
              <Overview />
            </TabPanelCustom>
            <TabPanelCustom value="User Management">
              <UserManagement />
            </TabPanelCustom>
            <TabPanelCustom value="Audit Trail">
              <AuditTrail />
            </TabPanelCustom>
            <TabPanelCustom value="Global Settings">
              <SettingsPage />
            </TabPanelCustom>
          </TabContext>
        </TabContext>
      );
    } else {
      return <ErrorsPage status="403" />;
    }
  }, [isLoading, isSuperAdmin, isSuperUser, value]);

  return <AppLayout>{dashBoardAdmin}</AppLayout>;
};
