import { Typography } from '@mui/material';
import React from 'react';

const ErrorMessage = ({ children }: any) => {
  return (
    <Typography
      component="div"
      className="errorMsg"
      sx={{
        fontFamily: 'Barlow',
        fontSize: '14px',
        lineHeight: '12px',
        color: '#FF4646',
        textAlign: 'left',
        my: '10px',
      }}>
      {children}
    </Typography>
  );
};

export default ErrorMessage;
