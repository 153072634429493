import { Box, useTheme } from '@mui/material';
import ReactECharts from 'echarts-for-react';

const WaitingPageAPI = (props: { height?: string; width?: string }) => {
  const theme = useTheme();

  const options = {
    backgroundColor: theme.palette.background.paper,
    graphic: {
      elements: [
        {
          type: 'group',
          left: 'center',
          top: 'center',
          children: new Array(5).fill(0).map((val, i) => ({
            key: i,
            type: 'rect',
            x: i * 20,
            shape: {
              x: 0,
              y: -40,
              width: 10,
              height: 80,
            },
            style: {
              fill: theme.palette.primary.main,
            },
            keyframeAnimation: {
              duration: 800,
              delay: i * 200,
              loop: true,
              keyframes: [
                {
                  percent: 0.5,
                  scaleY: 0.3,
                  easing: 'cubicIn',
                },
                {
                  percent: 1,
                  scaleY: 1,
                  easing: 'cubicOut',
                },
              ],
            },
          })),
        },
      ],
    },
  };

  return (
    <Box
      sx={{
        height: props.height || '100%',
        width: props.width || '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <ReactECharts option={options} style={{ height: props.height || '100%', width: props.width || '100%' }} />
    </Box>
  );
};

export default WaitingPageAPI;
