import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import { Box, Grid, IconButton, Paper, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import ClientsDialog from './ClientsDialog';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
  borderRadius: '8px',
}));

interface Overviewprops {
  item?: any;
  otherData: {
    data: {
      totalClients: number;
      totalArea: number;
    };
  };
  isClientSummaryRefetching: boolean;
  listIdClients: string[];
  setListIdClients: (arg: string[]) => void;
}

const CardItem: React.FC<Overviewprops> = ({
  item,
  otherData,
  isClientSummaryRefetching = false,
  listIdClients,
  setListIdClients,
}) => {
  const theme = useTheme();
  const [openEditTable, setOpenEditTable] = useState(false);
  const updateClients = otherData?.data;

  const handleOpenEditTable = () => {
    setOpenEditTable(true);
  };

  const handleCloseEditTable = () => {
    setOpenEditTable(false);
  };

  return (
    <Item>
      <Grid
        container
        sx={{
          minHeight: '160px',
          pl: 4,
          position: 'relative',
        }}
        alignItems="center">
        <Grid
          sx={{
            position: 'absolute',
            right: '4px',
            top: '4px',
          }}>
          {item?.ableToEdit ? (
            <Box>
              <Tooltip title="Open Edit">
                <IconButton
                  sx={{
                    padding: 0,
                    textTransform: 'none',
                    fontFamily: 'Barlow',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#A8AEB1',
                    width: '24px',
                    height: '24px',
                    marginLeft: '14px',
                    boxShadow: '0px 2px 10px rgba(97, 97, 97, 0.1)',
                  }}
                  onClick={handleOpenEditTable}>
                  <EditRoundedIcon
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#A8AEB1',
                      fontSize: '15px',
                      cursor: 'pointer',
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* Dialog of table Edit */}
              <ClientsDialog
                open={openEditTable}
                onClose={handleCloseEditTable}
                listIdClients={listIdClients}
                setListIdClients={setListIdClients}
              />
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {item?.icon ? (
              <Grid item xs={2} md={2} sm={2} lg={2}>
                <Box
                  sx={{
                    borderRadius: '50%',
                    background: '#e3f2fd',
                    width: '35px',
                    height: '35px',
                    position: 'relative',
                  }}>
                  <ShowChartOutlinedIcon
                    sx={{
                      background: '#40C8E5',
                      color: 'white',
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: '15px',
                    }}
                  />
                </Box>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={item?.icon ? 5 : 6} md={item?.icon ? 5 : 6} sm={item?.icon ? 5 : 6} lg={item?.icon ? 5 : 6}>
              <Typography sx={{ fontWeight: '800' }}>{item?.title}</Typography>
              <Typography sx={{ fontWeight: '600' }}>{item?.name}</Typography>
            </Grid>
            <Grid item xs={item?.icon ? 5 : 6} md={item?.icon ? 5 : 6} sm={item?.icon ? 5 : 6} lg={item?.icon ? 5 : 6}>
              <Typography sx={{ fontWeight: '800' }}>{item?.details}</Typography>
              <Typography sx={{ fontWeight: '600' }}>{item?.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="end">
            <Grid item xs={12} sm={12} lg={6}>
              {updateClients.totalClients !== 0 && !isClientSummaryRefetching ? (
                <Typography variant="h1" sx={{ fontSize: '44px' }}>
                  {updateClients?.totalClients}
                </Typography>
              ) : (
                <Skeleton variant="text" sx={{ fontSize: '44px', width: '100px' }} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              {updateClients.totalArea !== 0 && !isClientSummaryRefetching ? (
                <Typography variant="h1" sx={{ fontSize: '44px' }}>
                  {updateClients?.totalArea.toFixed(2)}
                </Typography>
              ) : (
                <Skeleton variant="text" sx={{ fontSize: '44px', width: '100px' }} />
              )}
            </Grid>
            {item?.increase > 0 ? (
              <Grid item xs={12} sm={12} lg={6}>
                <Stack sx={{ alignItems: 'center' }} direction="row">
                  <ArrowUpwardIcon color="primary" fontSize="small" />
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }} color="primary">
                    {item?.increase}%{' '}
                  </Typography>
                  <Typography> vs last year</Typography>
                </Stack>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Item>
  );
};

export default CardItem;
