import { makeStyles } from '@mui/styles';

export const AuditSingleLogStyle = makeStyles((theme: any) => {
  return {
    positionsCenter: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
      padding: 'auto',
      borderBottom: `1px solid ${theme.palette.color.white1}`,
      position: 'relative',
    },
    dialogTitle: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      fontSize: '20px',
      fontWeight: '600',
    },
    boxContainer: {
      display: 'flex',
      padding: '20px',
      height: '100vh',
      width: '100vw',
      boxSizing: 'border-box',
    },
    boxFilter: {
      padding: '0',
      marginRight: '12px',
      maxWidth: '542px',
      flex: 1,
    },
    boxLeft: {
      overflow: 'auto',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& > div': {
        '& > .rdrDefinedRangesWrapper': {
          width: '188px',
          background: theme.palette.mode === 'dark' ? '#3C4043' : '#fff',
          '& > .rdrStaticRanges': {
            '& > .rdrStaticRange': {
              background: theme.palette.mode === 'dark' ? '#3C4043' : '#fff',
              color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
              '&:hover': {
                color: theme.palette.mode === 'dark' ? '#000' : '#64748B',
              },
            },
          },
        },
        '& > .rdrCalendarWrapper': {
          width: '352px',
          background: theme.palette.mode === 'dark' ? '#3C4043' : '#fff',
          color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
          '& > .rdrDateDisplayWrapper': {
            background: theme.palette.mode === 'dark' ? '#3C4043' : '#fff',
          },
          '& > .rdrMonthAndYearWrapper': {
            '& > .rdrMonthAndYearPickers select': {
              color: theme.palette.mode === 'dark' ? '#fff !important' : '#3C4043',
              background: theme.palette.mode === 'dark' ? '#3C4043' : '#fff',
            },
          },
          '& > .rdrMonths': {
            '& > .rdrMonth': {
              '& > .rdrDays': {
                '& > .rdrDay.rdrDayPassive': {
                  '& > .rdrDayNumber span': {
                    color: theme.palette.mode === 'dark' ? '#64748B !important' : '#849095 !important',
                  },
                },
                '& > .rdrDay': {
                  '& > .rdrDayNumber span': {
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
                  },
                },
              },
            },
          },
        },
      },
    },
    // Control
    boxControl: {
      padding: '8px 0 !important',
    },
    saveButton: {
      background: theme.palette.color.green4,
      width: '120px !important',
      height: '40px',
      align: 'right',
      color: theme.palette.color.white,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.color.grey5,
      },
    },

    // Chart
    boxRight: {
      padding: '0 20px',
      flex: 2,
      display: 'flex',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px',
      flexDirection: 'column',
      backgroundColor: theme.palette.mode === 'dark' ? '#3C4043' : '#fff',
    },

    // Table Logs
    tabSwipeBtn: {
      '.MuiTabs-indicator': {
        display: 'none',
      },
      height: '36px',
    },
    tableLogs: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    clientListTitle: {
      padding: '10px 0',
      color: '#3C4043',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18.2px',
    },
    tableLogsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableLogTitle: {
      fontWeight: 'bold !important',
      fontSize: '18px !important',
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      lineHeight: '16px !important',
    },
    tableLogControl: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
    },
    contentBox: {
      boxSizing: 'border-box',
      display: 'flex',
      height: '60px',
    },
    dateCellStyle: {
      height: '100%',
      borderBottom: theme.palette.mode === 'dark' ? `1px solid #191919 !important` : 'none',
    },
    cellStyle: {
      flex: 1,
      border: 'none',
      textOverflow: 'ellipsis',
      width: '100%',
      overflow: 'hidden',
    },
    cellStyleCenter: {
      flex: 1,
      border: 'none',
      textAlign: 'center',
    },

    contentColumnBox: {
      display: 'flex',
      cursor: 'pointer',
      transition: 'all .3s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#3C4043' : '#F2F2F2',
      },
    },
    noDataCell: {
      flex: 1,
      border: 'none',
      textAlign: 'center',
      height: '100%',
      lineHeight: '53.02px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#737373',
    },
  };
});
