import { makeStyles } from '@mui/styles';

export const useClientSettingsStyle = makeStyles((theme: any) => ({
  clientSettingsCard: {
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
    // background: `${theme.palette.color.white}`,
    background: theme.palette.background.paper,
    borderRadius: '8px',
    height: 'calc(100vh - 240px)',
    border: `1px solid ${theme.palette.color.white1}`,
    width: '100%',
    overflow: 'auto',
  },
  textSetting: {
    color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.black1,
  },
  dialogTitle: {
    color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.black1,
    fontSize: '20px',
    fontWeight: '600',
  },
  saveButton: {
    background: theme.palette.color.green4,
    width: '200px',
    height: '40px',
    align: 'right',
    color: theme.palette.color.white,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.color.grey5,
    },
  },
  formBlockItem: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '24px 0 !important',
    marginLeft: '-8px',
    marginBottom: '1rem',
  },
  pickupColorBtn: {
    height: '37.13px',
    width: '50px',
    borderRadius: '5px',
    border: '2px solid white',
    marginLeft: '16px',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
    },
  },
  pickupColorValueContainer: {
    height: '200px',
    width: '200px',
    zIndex: 10,
    borderRadius: '10px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
  },
  editColor: {
    position: 'absolute',
    top: '-55px',
    left: '1px',
    width: '90px !important',
  },
  pickupColorBox: {
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  textSideSurface: {
    color: theme.palette.primary.main,
  },
}));
