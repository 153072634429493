import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { ISwitchItem } from 'common/defines/analytic';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLandUsageLayerStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { ChangeEvent, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateCrownArea } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeDrawCrownAreaState,
  changeSwitchCrownAreaListValue,
  crownAreaAnalyticsSelector,
  resetDrawCrownAreaState,
} from 'store/slices/map-view/crownAreaAnalytics';
import {
  changeClickedStandPointId,
  changeIsStandPointEditMode,
  changeLayer3D,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import CommonSwitchItem from '../components/CommonSwitchItem';

const CrownAreaEdit = () => {
  const [isSelectAll, setIsSelectAll] = useState<boolean>(true);
  const [isShowCrownArea, setIsShowCrownArea] = useState<boolean>(true);
  const [isShowEditMode, setIsShowEditMode] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const classes = useLandUsageLayerStyle();
  const theme = useTheme();
  const { isLayer3D, isStandPointEditMode, analyticId } = useAppSelector(mapViewSelector);
  const {
    switchCrownAreaList,
    drawCrownArea: { crownAreaEditedDetail, geometry },
  } = useAppSelector(crownAreaAnalyticsSelector);
  const { colorAnalysisLayer } = useQueryClientSetting();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { _id, latY, longX, area, treeId } = crownAreaEditedDetail || {};

  // reset clicked stand point when turn off Point Edit
  useEffect(() => {
    if (!isStandPointEditMode) {
      dispatch(changeClickedStandPointId(''));
    }
  }, [dispatch, isStandPointEditMode]);

  // initial color for standCount and crown from client - setting
  useEffect(() => {
    switchCrownAreaList.forEach((item) => {
      const fullAnalyticId = `${analyticId}_${item.key}`;
      const getColorFromApi = colorAnalysisLayer?.find((item) => item.analysisId === fullAnalyticId);
      if (getColorFromApi) {
        dispatch(changeSwitchCrownAreaListValue({ ...item, color: getColorFromApi.color }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticId, colorAnalysisLayer, dispatch]);

  const updateCrownAreaMutation = useMutation(updateCrownArea, {
    onSuccess(res) {
      queryClient.invalidateQueries([QUERY_KEY.CROWN_AREA_POLYGON]);
      queryClient.invalidateQueries([QUERY_KEY.CROWN_AREA_ANALYTIC]);
      queryClient.invalidateQueries([QUERY_KEY.GET_LABEL_ANALYTIC]);
      dispatch(resetDrawCrownAreaState());
      toast.success('Update crown area successful');
    },
  });

  const handleChangeSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.checked;
    switchCrownAreaList.forEach((item) => {
      dispatch(changeSwitchCrownAreaListValue({ key: item.key, visible: changedValue }));
    });
    setIsSelectAll(changedValue);
  };

  const handleSwitchListValue = (item: ISwitchItem) => {
    dispatch(changeSwitchCrownAreaListValue({ key: item.key, visible: !item.visible }));
  };

  const handleTogglePointEdit = () => {
    dispatch(changeIsStandPointEditMode(!isStandPointEditMode));
    if (isStandPointEditMode) {
      dispatch(
        changeDrawCrownAreaState({ isShow: false, editedId: '', isLockMap: false, crownAreaEditedDetail: undefined })
      );
    }
  };

  const handleDelete = () => {
    if (_id && longX && latY) {
      updateCrownAreaMutation.mutate({
        id: _id,
        area: 0,
        geometry: {
          type: 'Point',
          coordinates: [longX, latY],
        },
      });
    }
  };

  const handleEdit = () => {
    dispatch(changeDrawCrownAreaState({ isShow: true, editedId: _id, geometry: undefined }));
  };

  const handleSave = () => {
    if (_id && geometry && area) {
      updateCrownAreaMutation.mutate({ id: _id, geometry, area });
    }
  };

  const renderDetailInfo = crownAreaEditedDetail && [
    { key: 1, label: 'Tree Id: ', value: treeId },
    { key: 2, label: 'Longitude: ', value: longX },
    { key: 3, label: 'Latitude: ', value: latY },
  ];

  return (
    <Scrollbars style={{ height: 'calc(100vh - 350px)' }} autoHide>
      <Grid container classes={{ root: classes.containerStyle }} direction="column" sx={{ display: 'flex', flex: 1 }}>
        <Box className={classes.boxStyle} p="10px" pt="16px">
          <Box sx={{ pl: '16px' }}>
            <FormControlLabel
              control={<SwitchCustom checked={isStandPointEditMode} onClick={handleTogglePointEdit} />}
              label={<Typography className={classes.selectText}>{t('trans.point_edit')}</Typography>}
            />
          </Box>
          <Button
            startIcon={isShowCrownArea ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setIsShowCrownArea((prev) => !prev)}
            classes={{ root: classes.buttonTextStyle }}>
            {t('trans.label')}
          </Button>
          <Collapse in={isShowCrownArea} timeout="auto" sx={{ px: '16px' }}>
            <Box>
              <FormControlLabel
                control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                label={
                  <Typography classes={{ root: classes.selectText }}>
                    {isLayer3D ? t('trans.3d_fill') : t('trans.2d_fill')}
                  </Typography>
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<SwitchCustom checked={isSelectAll} onClick={handleChangeSelectAll} />}
                label={
                  <Typography classes={{ root: classes.selectText }}>
                    {isSelectAll ? t('trans.unselect_all') : t('trans.select_all')}
                  </Typography>
                }
              />
            </Box>
            {switchCrownAreaList
              .map((item) => ({ ...item, label: t(convertTextToMultiLanguage(item.label, 'mapView')) }))
              .map((item) => (
                <Box key={item.key}>
                  <CommonSwitchItem
                    item={item}
                    handleSwitchListValue={() => {
                      handleSwitchListValue(item);
                    }}
                  />
                </Box>
              ))}
          </Collapse>

          <Button
            startIcon={isShowEditMode ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setIsShowEditMode((prev) => !prev)}
            classes={{ root: classes.buttonTextStyle }}>
            {t('trans.edit_mode')}
          </Button>
          <Collapse in={isShowEditMode} timeout="auto" sx={{ px: '8px' }}>
            {crownAreaEditedDetail && (
              <Box sx={{ color: theme.palette.mode === 'dark' ? '#ffffff' : '#3C4043' }}>
                {renderDetailInfo?.map((item) => (
                  <Grid container key={item.key} sx={{ minHeight: '40px', display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={4}>
                      <Typography sx={{ display: 'inline-block', fontWeight: 600 }}>{item.label}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography sx={{ display: 'inline-block', wordWrap: 'break-word', maxWidth: '100%' }}>
                        {item.value}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
                <Grid container alignItems="center" sx={{ mt: '32px' }}>
                  <Grid item xs={4}>
                    <DeleteForeverIcon
                      color="error"
                      onClick={handleDelete}
                      sx={{ cursor: 'pointer', fontSize: '30px' }}
                    />
                    <CreateOutlinedIcon
                      color="action"
                      onClick={handleEdit}
                      sx={{ cursor: 'pointer', fontSize: '30px', opacity: 0.7 }}
                    />
                  </Grid>
                  <Grid item xs={7} textAlign="right">
                    <Button size="small" variant="contained" color="primary" sx={{ ml: '8px' }} onClick={handleSave}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Collapse>
        </Box>
      </Grid>
    </Scrollbars>
  );
};

export default CrownAreaEdit;
