import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { ICreateUserFormData, IMoreInfomation } from 'common/defines/clients';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import MuiPhoneNumber from 'material-ui-phone-number';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutUser, updateUserInfoNonAuth } from 'services/clients/apiClient.services';
import * as yup from 'yup';
import { useRequestInfoStyle } from './RequestInfomationStyle';

const styles = {
  formStyles: {
    width: '310px',
    m: 'auto',
    fontFamily: 'Barlow',
  },
  crossLineStyles: {
    borderTop: 1,
    width: '94px',
    mt: 1.25,
  },
  labelStyles: {
    textAlign: 'left',
    fontSize: '18px',
    height: '24px',
  },
  textStyles: {
    fontSize: '14px',
    height: '17px',
  },
  fontStyles: {
    fontFamily: 'Barlow',
    '& .MuiInputBase-input': {
      height: '30px',
    },
  },
};

const moreInfomationSchema = yup
  .object({
    jobTitle: yup.string().required('Job title is required'),
    department: yup.string().required('Department is required'),
    company: yup.string().required('Company is required'),
    manager: yup.string().required('Manager is required'),
    workLocation: yup.string().required('WorkLocation is required'),
    address: yup.string().required('Address is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    zipCode: yup.string().required('ZipCode is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
  })
  .required();

const RequestInfomation = () => {
  const classes = useRequestInfoStyle();
  const navigate = useNavigate();
  const sessionId = localStorage.getItem('sessionLogin');
  const userAccount = JSON.parse(localStorage.getItem('account') || '{}');
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IMoreInfomation>({
    defaultValues: {
      address: '',
      phoneNumber: '',
      zipCode: '',
      state: '',
      country: '',
      jobTitle: '',
      department: '',
      company: '',
      manager: '',
      workLocation: '',
    },
    resolver: yupResolver(moreInfomationSchema),
  });

  const handleChangeField = (e: any, fieldName: any) => {
    if (fieldName === 'phoneNumber') setValue(fieldName, e.trim());
    else setValue(fieldName, e.target.value);
  };

  const useLogoutUser = () => {
    return useMutation(() => logoutUser(sessionId || ''), {
      onSuccess: () => {
        const theme = localStorage.getItem('theme');
        localStorage.clear();
        localStorage.setItem('theme', theme || 'light');
        navigate('/login');
      },
      onError: (err) => {
        console.log('err', err);
      },
    });
  };

  const mutationLogoutUser = useLogoutUser();

  const handleLogout = () => {
    if (!sessionId) {
      navigate('/login');
      return;
    }
    mutationLogoutUser.mutate();
  };

  const useUpdateProfileData = () => {
    return useMutation(
      (param: ICreateUserFormData) =>
        updateUserInfoNonAuth({
          ...param,
        }),
      {
        onSuccess: () => {
          toast.success('Update more infomation success', { toastId: 1 });
          // handleLogout();
          navigate('/waiting-approval');
          return;
        },
        onError: (err: any) => {
          err?.data?.message?.forEach((content: string) => toast.error(content));
        },
      }
    );
  };

  const mutationUpdateProfileData = useUpdateProfileData();

  const handleSubmitForm: SubmitHandler<IMoreInfomation> = (data) => {
    const body = {
      ...userAccount,
      jobTitle: data.jobTitle,
      department: data.department,
      company: data.company,
      manager: data.manager,
      workLocation: data.workLocation,
      address: data.address,
      phoneNumber: data.phoneNumber,
      state: data.state,
      zipCode: data.zipCode,
      country: data.country,
    };
    mutationUpdateProfileData.mutate({ ...body });
  };
  return (
    <>
      <Typography
        component="form"
        onSubmit={handleSubmit(handleSubmitForm)}
        sx={{
          height: '100%',
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1F1F1F' : '#f9f9f9'),
          display: ' flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Stack
          direction="row"
          sx={{
            minHeight: 'fit-content',
            maxWidth: '1130px',
            m: 'auto',
            borderRadius: '10px',
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#3C4043' : '#fff'),
            p: 2,
            my: 2,
          }}>
          <Typography
            // component="form"
            alignItems="center"
            className={classes.baseStyles}
            sx={{
              width: '610px',
            }}>
            <Box
              sx={{
                padding: ' 16px',
              }}>
              <Typography
                sx={{
                  pt: 2.5,
                  fontFamily: 'Barlow',
                  fontStyle: 'SemiBold',
                  fontSize: '20px',
                  lineHeight: '21px',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                  fontWeight: 'bold',
                  mb: '30px',
                }}>
                Add work infomation
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <Box key="address">
                        <InputLabel>Address</InputLabel>
                        <TextField
                          sx={{ ...styles.fontStyles }}
                          type="text"
                          size="small"
                          fullWidth
                          placeholder="Add adress"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          onChange={(e) => handleChangeField(e, 'address')}
                          // onChange={(e: ChangeEvent<HTMLInputElement>) => handleTrimValue(e, 'name')}
                        />
                        {errors.address && (
                          <Typography
                            component="div"
                            className="errorMsg"
                            sx={{
                              fontFamily: 'Barlow',
                              fontSize: '14px',
                              lineHeight: '12px',
                              color: '#FF4646',
                              mt: 1,
                            }}>
                            {errors.address.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                  <Grid container alignItems="flex-end" spacing={1} sx={{ pb: 1 }}>
                    <Grid item xs={6}>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => (
                          <>
                            <InputLabel sx={{ pt: 2 }}>Phone Number</InputLabel>
                            <MuiPhoneNumber
                              sx={{
                                width: '100%',
                                input: {
                                  height: '14px',
                                },
                              }}
                              name="phoneNumber"
                              variant="outlined"
                              type="tel"
                              dropdownClass="dropdown-container"
                              preferredCountries={['my', 'sa', 'ae', 'gm']}
                              disableAreaCodes={true}
                              autoFormat={true}
                              defaultCountry="my"
                              value="+60"
                              onChange={(e: any) => {
                                handleChangeField(e, 'phoneNumber');
                              }}
                            />
                            {errors.phoneNumber && (
                              <Typography
                                component="div"
                                className="errorMsg"
                                sx={{
                                  fontFamily: 'Barlow',
                                  fontSize: '14px',
                                  lineHeight: '12px',
                                  color: '#FF4646',
                                  textAlign: 'left',
                                  my: '10px',
                                }}>
                                {errors.phoneNumber.message}
                              </Typography>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <Box key="country">
                            <InputLabel>Country</InputLabel>
                            <TextField
                              sx={{ ...styles.fontStyles }}
                              type="text"
                              size="small"
                              fullWidth
                              placeholder="Add country"
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              onChange={(e) => handleChangeField(e, 'country')}
                              // onChange={(e: ChangeEvent<HTMLInputElement>) => handleTrimValue(e, 'name')}
                            />
                            {errors.country && (
                              <Typography
                                component="div"
                                className="errorMsg"
                                sx={{
                                  fontFamily: 'Barlow',
                                  fontSize: '14px',
                                  lineHeight: '12px',
                                  color: '#FF4646',
                                  mt: 1,
                                }}>
                                {errors.country.message}
                              </Typography>
                            )}
                          </Box>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                          <Box key="state">
                            <InputLabel>State</InputLabel>
                            <TextField
                              sx={{ ...styles.fontStyles }}
                              type="text"
                              size="small"
                              fullWidth
                              placeholder="Add state"
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              onChange={(e) => handleChangeField(e, 'state')}
                              // onChange={(e: ChangeEvent<HTMLInputElement>) => handleTrimValue(e, 'name')}
                            />
                            {errors.state && (
                              <Typography
                                component="div"
                                className="errorMsg"
                                sx={{
                                  fontFamily: 'Barlow',
                                  fontSize: '14px',
                                  lineHeight: '12px',
                                  color: '#FF4646',
                                  mt: 1,
                                }}>
                                {errors.state.message}
                              </Typography>
                            )}
                          </Box>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="zipCode"
                        control={control}
                        render={({ field }) => (
                          <Box key="zipCode">
                            <InputLabel>Zip code</InputLabel>
                            <TextField
                              sx={{ ...styles.fontStyles }}
                              type="text"
                              size="small"
                              fullWidth
                              placeholder="Add zip code"
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              onChange={(e) => handleChangeField(e, 'zipCode')}
                              // onChange={(e: ChangeEvent<HTMLInputElement>) => handleTrimValue(e, 'name')}
                            />
                            {errors.zipCode && (
                              <Typography
                                component="div"
                                className="errorMsg"
                                sx={{
                                  fontFamily: 'Barlow',
                                  fontSize: '14px',
                                  lineHeight: '12px',
                                  color: '#FF4646',
                                  mt: 1,
                                }}>
                                {errors.zipCode.message}
                              </Typography>
                            )}
                          </Box>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="jobTitle"
                    control={control}
                    render={({ field }) => (
                      <Box key="jobTitle">
                        <InputLabel>Job Title</InputLabel>
                        <TextField
                          sx={{ ...styles.fontStyles }}
                          type="text"
                          size="small"
                          fullWidth
                          placeholder="Add job title"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          onChange={(e) => handleChangeField(e, 'jobTitle')}
                        />
                        {errors.jobTitle && (
                          <Typography
                            component="div"
                            className="errorMsg"
                            sx={{
                              fontFamily: 'Barlow',
                              fontSize: '14px',
                              lineHeight: '12px',
                              color: '#FF4646',
                              mt: 1,
                            }}>
                            {errors.jobTitle.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="department"
                    control={control}
                    render={({ field }) => (
                      <Box key="department">
                        <InputLabel>Department</InputLabel>
                        <TextField
                          sx={{ ...styles.fontStyles }}
                          type="text"
                          size="small"
                          fullWidth
                          placeholder="Add Department"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          onChange={(e) => handleChangeField(e, 'department')}
                        />
                        {errors.department && (
                          <Typography
                            component="div"
                            className="errorMsg"
                            sx={{
                              fontFamily: 'Barlow',
                              fontSize: '14px',
                              lineHeight: '12px',
                              color: '#FF4646',
                              mt: 1,
                            }}>
                            {errors.department.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="company"
                    control={control}
                    render={({ field }) => (
                      <Box key="company">
                        <InputLabel>Company</InputLabel>
                        <TextField
                          sx={{ ...styles.fontStyles }}
                          type="text"
                          size="small"
                          fullWidth
                          placeholder="Add company name"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          onChange={(e) => handleChangeField(e, 'company')}
                        />
                        {errors.company && (
                          <Typography
                            component="div"
                            className="errorMsg"
                            sx={{
                              fontFamily: 'Barlow',
                              fontSize: '14px',
                              lineHeight: '12px',
                              color: '#FF4646',
                              mt: 1,
                            }}>
                            {errors.company.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="manager"
                    control={control}
                    render={({ field }) => (
                      <Box key="manager">
                        <InputLabel>Reporting To</InputLabel>
                        <TextField
                          sx={{ ...styles.fontStyles }}
                          type="text"
                          size="small"
                          fullWidth
                          placeholder="Add manager"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          onChange={(e) => handleChangeField(e, 'manager')}
                        />
                        {errors.manager && (
                          <Typography
                            component="div"
                            className="errorMsg"
                            sx={{
                              fontFamily: 'Barlow',
                              fontSize: '14px',
                              lineHeight: '12px',
                              color: '#FF4646',
                              mt: 1,
                            }}>
                            {errors.manager.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="workLocation"
                    control={control}
                    render={({ field }) => (
                      <Box key="workLocation">
                        <InputLabel>Work location</InputLabel>
                        <TextField
                          sx={{ ...styles.fontStyles }}
                          type="text"
                          size="small"
                          fullWidth
                          placeholder="Add work location"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          onChange={(e) => handleChangeField(e, 'workLocation')}
                        />
                        {errors.workLocation && (
                          <Typography
                            component="div"
                            className="errorMsg"
                            sx={{
                              fontFamily: 'Barlow',
                              fontSize: '14px',
                              lineHeight: '12px',
                              color: '#FF4646',
                              mt: 1,
                            }}>
                            {errors.workLocation.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>
              <Box sx={{ pt: 3, mx: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonCustom
                  sx={{ width: '90px', mx: 2 }}
                  backgroundColor="#FFF"
                  colorLabel="#9E9E9E"
                  title={'Cancel'}
                  border="1px solid #9E9E9E"
                  onClick={handleLogout}
                />
                <Button type="submit" variant="contained" color="primary" sx={{ width: '90px' }}>
                  Save
                </Button>
              </Box>
            </Box>
          </Typography>
        </Stack>
      </Typography>
    </>
  );
};

export default RequestInfomation;
