import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { ICreateClientSettings, SettingUIAnalysis } from 'common/defines/clients';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updateClientSettingsById } from 'services/clients/apiClient.services';
import { useClientSettingsStyle } from './ClientSettingsStyle';

const ViewAnalysisSlopeModal = (props: any) => {
  const { open, onClose, refetch, clientData, clientId, settingId } = props;
  const classes = useClientSettingsStyle();
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<{ isChecked: boolean }>({
    defaultValues: {
      isChecked: true,
    },
  });

  useEffect(() => {
    if (clientData) {
      const { viewAnalysisPropperties } = clientData;
      const showHideSlopeAnalysis = viewAnalysisPropperties.find(
        (item: any) => item.name === SettingUIAnalysis.SHOW_HIDE_SLOPE_ANALYSIS
      );
      reset({
        isChecked: showHideSlopeAnalysis?.value,
      });
    } else {
      reset({
        isChecked: true,
      });
    }
  }, [clientData, reset]);

  const handleClose = () => {
    onClose();
  };

  const handleUpdateUISlope = useMutation(
    (data: ICreateClientSettings) => updateClientSettingsById(settingId || '', data),
    {
      onSuccess: () => {
        onClose();
        refetch();
        toast.success('Change settings successfully');
      },
    }
  );

  const handleSubmitForm: SubmitHandler<{ isChecked: boolean }> = (data) => {
    handleUpdateUISlope.mutate({
      clientId: clientId || '',
      viewAnalysisPropperties: [
        {
          name: SettingUIAnalysis.SHOW_HIDE_SLOPE_ANALYSIS,
          value: data.isChecked,
        },
      ],
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            overflowY: 'scroll',
            backgroundColor: (theme) => theme.palette.background.paper,
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        },
      }}>
      <DialogTitle className={classes.dialogTitle}>{t('trans.client_settings_edit')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          p: 0,
          position: 'absolute',
          right: 8,
          top: 8,
          '.MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
        }}>
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          padding: '1rem 2rem',
        }}>
        <DialogContent
          sx={{
            padding: 0,
          }}>
          <Typography
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
            }}
            onSubmit={handleSubmit(handleSubmitForm)}>
            <DialogContent
              sx={{
                padding: 0,
              }}>
              <Grid container alignItems="center" sx={{ overflow: 'hidden' }}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}>
                    <Typography
                      sx={{
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#737373'),
                      }}>
                      {t('trans.show_slope_area')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}>
                      <Controller
                        name="isChecked"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Switch checked={field.value} onChange={(e: any) => field.onChange(e.target.checked)} />
                          );
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button type="submit" fullWidth className={classes.saveButton}>
                {t('trans.save')}
              </Button>
            </DialogActions>
          </Typography>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ViewAnalysisSlopeModal;
