import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Link, Typography } from '@mui/material';
import { IOptionalProperty, IUpdateProperties } from 'common/defines/clients';
import { ClientActionsDialog } from 'components/ClientsInfo/ClientActionsDialog';
import { FieldUploadFile } from 'components/FieldUploadFile';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import moment from 'moment';
import { FC, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

interface IInfoFileItem {
  propertyItem: IOptionalProperty;
  propertyIndex: number;
  control: any;
  setValue: any;
  properties: IOptionalProperty[];
  handleSubmitForm: SubmitHandler<IUpdateProperties>;
  hasPermission: boolean;
}

export const InfoFileItem: FC<IInfoFileItem> = ({
  propertyItem,
  propertyIndex,
  control,
  setValue,
  properties,
  handleSubmitForm,
  hasPermission,
}) => {
  const classes = useMapViewStyle();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const handleDeleteFile = () => {
    const _dataNew = properties.map((_item: any, idx: number) => {
      if (idx === propertyIndex) {
        return {
          ..._item,
          mediaId: '',
          value: [],
        };
      }
      return _item;
    });
    handleSubmitForm({ properties: _dataNew });
    setOpenDeleteModal(false);
  };

  return (
    <Box>
      {editMode ? (
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <FieldUploadFile
            name={`properties.${propertyIndex}.value`}
            index={propertyIndex}
            control={control}
            propertyItem={propertyItem}
            setValue={setValue}
            properties={properties}
            setEditMode={setEditMode}
            handleSubmitForm={handleSubmitForm}
          />
          {hasPermission && (
            <CloseIcon
              sx={{ marginLeft: '5px', fontSize: '18px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => setEditMode(!editMode)}
            />
          )}
        </Box>
      ) : propertyItem?.value.length && propertyItem?.value[0] !== '' ? (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link
              underline="none"
              href={propertyItem.value[0]}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: 'flex', alignItems: 'center' }}>
              <InsertDriveFileIcon fontSize="medium" color="info" sx={{ pr: 1 }} />
              {propertyItem.name}
            </Link>
            <Box>
              {hasPermission && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <EditOutlinedIcon
                    sx={{
                      marginLeft: '5px',
                      fontSize: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => setEditMode(!editMode)}
                  />
                  <DeleteOutlineOutlinedIcon
                    sx={{
                      marginLeft: '5px',
                      fontSize: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => setOpenDeleteModal(true)}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Typography sx={{ fontSize: '12px', color: '#9E9E9E' }}>
            {moment(propertyItem?.media?.createdAt).format('MMM Do, YYYY')},{' '}
            {propertyItem?.media?.fileSize / 1000 > 1000
              ? `${(propertyItem?.media?.fileSize / 1000 / 1000).toFixed(2)} MB`
              : `${(!propertyItem.media?.fileSize ? 0 : propertyItem.media?.fileSize / 1000).toFixed(2)} KB`}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.titleProperties}>{propertyItem?.name}</Typography>
          <Box>
            {hasPermission && (
              <AddCircleOutlineOutlinedIcon
                sx={{ marginLeft: '5px', fontSize: '18px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => setEditMode(!editMode)}
              />
            )}
          </Box>
        </Box>
      )}
      <ClientActionsDialog
        title={'Delete file'}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        description="Are you sure about that?"
        onCancel={() => setOpenDeleteModal(false)}
        onSave={handleDeleteFile}
        submit={false}
      />
    </Box>
  );
};
