import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, Typography } from '@mui/material';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useBoxLayout, useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { Map2dOptionEnum } from 'interfaces/workspace';
import { ChangeEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeRightBar2dOptionsValue, tilingMapSelector } from 'store/slices/tilingMapSlice';
import VectorCollapseItelligent from './VectorCollapseItelligent';

const CommonRastersVectors = () => {
  const [isShowRaster, setIsShowRaster] = useState<boolean>(false);
  const [isShowCommonRastersVectors, setIsShowCommonRastersVectors] = useState<boolean>(false);

  const classes = useLayerSelectCardStyle();
  const classes2 = useBoxLayout();

  const dispatch = useAppDispatch();
  const {
    rightBar: { options2d },
  } = useAppSelector(tilingMapSelector);

  const onToggle2dButton = async (event: ChangeEvent<HTMLInputElement>, name: Map2dOptionEnum) => {
    const { checked } = event.target;
    dispatch(changeRightBar2dOptionsValue({ name, value: checked }));
  };

  return (
    <Box className={classes2.boxLayout} style={{ padding: '20px 20px', borderRadius: '20px', marginBottom: '20px' }}>
      <Button
        endIcon={
          isShowCommonRastersVectors ? (
            <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
          ) : (
            <ChevronRightIcon style={{ fontWeight: 'bold' }} />
          )
        }
        onClick={() => setIsShowCommonRastersVectors(!isShowCommonRastersVectors)}
        classes={{ root: classes.buttonTextStyle }}
        style={{ width: '100%' }}>
        <Typography
          className={classes.buttonTextStyle}
          style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '14px !important' }}>
          Common rasters and vectors
        </Typography>
      </Button>
      <Collapse in={isShowCommonRastersVectors} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
        <Button
          startIcon={
            isShowRaster ? (
              <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
            ) : (
              <ChevronRightIcon style={{ fontWeight: 'bold' }} />
            )
          }
          onClick={() => setIsShowRaster(!isShowRaster)}
          classes={{ root: classes.buttonTextStyle }}
          style={{ width: '100%' }}>
          <Typography
            className={classes.buttonTextStyle}
            style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '13px !important' }}>
            Raster
          </Typography>
        </Button>
        <Collapse in={isShowRaster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
          {options2d.map(
            (item) =>
              item.isVisible && (
                <Grid item xs={12} md={12} key={item.name}>
                  <FormControlLabel
                    control={
                      <SwitchCustom
                        checked={item.value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onToggle2dButton(e, item.name)}
                      />
                    }
                    label={<Typography classes={{ root: classes.selectText }}>{item.label}</Typography>}
                  />
                </Grid>
              )
          )}
        </Collapse>
        <VectorCollapseItelligent />
      </Collapse>
    </Box>
  );
};

export default CommonRastersVectors;
