import { hint } from '@mapbox/geojsonhint';
import { AddCircleOutline } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { kml } from '@tmcw/togeojson';
import { area as turfArea } from '@turf/turf';
import {
  IOptionalProperty,
  IOptionalPropertyType,
  IPersonInChargeInfo,
  IUpdateHierarchyStructureDto,
  IUpdateProperties,
  SelectionOptionType,
} from 'common/defines/clients';
import DropzoneFile from 'components/DropZoneFile/index';
import { FieldUploadFile } from 'components/FieldUploadFile';
import { QUERY_KEY } from 'constants/constants';
import flatten from 'geojson-flatten';
import { useUpdateLevelProperties } from 'hooks/useClientProperties';
import { get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeLocation, clientSelector } from 'store/slices/clientSlice';
import { shapeFileParser } from '../../../parsers/shapeFileParser';
import { ClientActionsDialog } from '../ClientActionsDialog';
import { FileHyperLink } from './FIleHyperLink';
import PersonInfoForm from './PersonInfoForm';

interface IClientProperties {
  levelId: string | undefined;
  nextStep?: (step: number) => void;
  isLoading: boolean;
  clientSizeLimit: number | null;
  clientId?: string;
}

export const ClientProperties = ({ isLoading, levelId }: IClientProperties) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { clientId } = useParams();
  const dataRef = useRef<{ isSubmitAll: boolean }>({ isSubmitAll: false });
  const { properties, location, personInCharge, area, fileBoundaryInfo } = useAppSelector(clientSelector);
  const [tempFileUpload, setTempFileUpload] = useState<File | null>(null);

  const updateLevelProperties = useUpdateLevelProperties();
  const navigate = useNavigate();

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const closeDeleteModal = () => {
    setOpenSuccessModal(false);
  };

  const onSave = () => {
    setOpenSuccessModal(false);
    if (clientId) {
      navigate(`/dashboard/${clientId}`);
    } else {
      navigate('/dashboard-admin');
    }
  };

  const { control, setValue, handleSubmit, reset } = useForm<IUpdateProperties>({
    defaultValues: {
      properties: [
        {
          name: '',
          value: [''],
          mediaId: '',
        },
      ],
      area: 0,
      personInCharge: [
        {
          name: '',
          jobTitle: '',
          contactNumber: '',
          email: '',
          avatar: '',
        },
      ],
    },
    mode: 'onBlur',
  });

  const { fields, append, remove } = useFieldArray({
    name: 'personInCharge',
    control,
  });

  useEffect(() => {
    reset({
      properties: properties
        ? properties
        : [
            {
              name: '',
              value: [''],
            },
          ],
      personInCharge:
        personInCharge && personInCharge.length > 0
          ? personInCharge
          : [
              {
                name: '',
                jobTitle: '',
                contactNumber: '',
                email: '',
                avatar: '',
              },
            ],
      area: area || 0,
    });
  }, [levelId, properties, setValue, reset, personInCharge, area]);

  const handleAddPersonInCharge = () => {
    append({
      name: '',
      jobTitle: '',
      contactNumber: '',
      email: '',
      avatar: '',
    });
  };

  const handleSubmitForm: SubmitHandler<IUpdateProperties> = (data: any) => {
    const tmpProperties = data.properties;
    tmpProperties?.forEach((item: any, index: number) => {
      const arr = [];
      data.properties[index].name = item.name;
      if (!Array.isArray(item.value)) {
        arr.push(item.value);
        data.properties[index].value = arr;
      }
      data.properties[index].type = item.type;
      data.properties[index].isDefault = item.isDefault;
      data.properties[index].mediaId = item.mediaId;
      if (item.type === IOptionalPropertyType.SELECT) {
        delete data.properties[index].clientSetupValue;
      }
    });

    if (location) {
      // validate location
      const areaSize = turfArea(location as any);

      const dataStructure: IUpdateHierarchyStructureDto = {
        id: levelId,
        location: location,
        properties: data.properties,
        personInCharge: data.personInCharge,
        area:
          get(location, 'features[0].properties.Lduse_area', +(area * 0.0001).toFixed(2)) ||
          +(areaSize * 0.0001).toFixed(2),
        fileBoundaryInfo: {
          name: tempFileUpload?.name,
          size: tempFileUpload?.size,
        },
      };
      updateLevelProperties.mutate(dataStructure, {
        onSuccess: () => {
          dataRef.current.isSubmitAll && setOpenSuccessModal(true);
          queryClient.invalidateQueries(QUERY_KEY.LEVEL_LIST);
          queryClient.invalidateQueries(QUERY_KEY.ALL_LOCATION_CROP_TYPE);
          toast.success('Add properties success', { toastId: 1 });
        },
        onError: (err: any) => {
          toast.error(err || 'Save failed', { toastId: 1 });
        },
      });
    } else {
      toast.error("You haven't uploaded the location file yet", { toastId: 1 });
    }
  };

  const convertArrayStringToArrayObject = useCallback((array: [] | undefined) => {
    const newArrayObject = [] as SelectionOptionType[];

    if (array && array.length > 0) {
      array.forEach((item: any) => {
        newArrayObject.push({ key: item, value: item });
      });
    }
    return newArrayObject;
  }, []);

  const handleMultipleChange = useCallback(
    (data: SelectionOptionType[], index: number) => {
      const multiList = data.map((dt: SelectionOptionType) => dt.key);
      setValue(`properties.${index}.value`, multiList);
    },
    [setValue]
  );

  const handleFileRead = useCallback(
    (e: any, name: any) => {
      try {
        const pathName = name.split('.').at(-1);
        const content = e.currentTarget.result;
        if (pathName === 'kml') {
          kml(new DOMParser().parseFromString(content, 'text/xml'));
          const newLocation = flatten(kml(new DOMParser().parseFromString(content, 'text/xml')));
          dispatch(changeLocation(newLocation));
          return;
        }
        const validateData = hint(content);
        if (validateData?.length > 0) {
          toast.error(
            <div>
              {validateData.map((item: any) => (
                <p key={item.line}>
                  Line {item.line}: {item.message}
                </p>
              ))}
            </div>,
            { toastId: 1 }
          );
          dispatch(changeLocation(null));
          return;
        }
        const dataStructure = JSON.parse(content);
        dispatch(changeLocation(dataStructure));
      } catch (error) {
        console.log(error);
        toast.error('Get file failed');
      }
    },
    [dispatch]
  );

  const handleFileChosen = useCallback(
    async (file: any) => {
      try {
        if (file.type === 'application/zip') {
          const data = await shapeFileParser(file);
          dispatch(changeLocation(data));
          setTempFileUpload(file);
          return;
        }
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => handleFileRead(event, file.name);
        fileReader.readAsText(file);
        setTempFileUpload(file);
      } catch (error) {
        console.log(error);
        toast.error('Get file failed', { toastId: 1 });
      }
    },
    [handleFileRead]
  );

  const skeletonProperties = useMemo(() => {
    return (
      <Typography component="ul" sx={{ pl: 0, pt: 2, padding: 3 }}>
        {Array.from(Array(6).keys()).map((item) => (
          <Box sx={{ marginBottom: 3 }} key={item}>
            <Skeleton variant="text" width="100%" height="20px" sx={{ marginBottom: 1 }} />
            <Skeleton variant="rectangular" width="100%" height="40px" />
          </Box>
        ))}
      </Typography>
    );
  }, []);

  const getDefaultValue = (item: any) => {
    if (!item) {
      return [];
    }
    return item.map((i: any) => ({ value: i, key: i }));
  };

  const getItemProperty = (propertyItem: IOptionalProperty, propertyIndex: number) => {
    switch (propertyItem.type) {
      case IOptionalPropertyType.TEXT:
        if (propertyItem.name !== 'Land Size') {
          return (
            <Controller
              name={`properties.${propertyIndex}.value`}
              control={control}
              render={({ field }) => (
                <Box>
                  <InputLabel>{propertyItem?.name}</InputLabel>
                  <TextField
                    // required
                    inputProps={{
                      maxLength: propertyItem?.value === 'Length' ? 256 : 128,
                    }}
                    type="text"
                    size="small"
                    {...field}
                    sx={{
                      width: '100%',
                    }}
                  />
                </Box>
              )}
            />
          );
        }
        break;
      case IOptionalPropertyType.DATE:
        return (
          <Controller
            name={`properties.${propertyIndex}.value`}
            control={control}
            render={({ field }) => (
              <Box>
                <InputLabel>{propertyItem?.name}</InputLabel>
                <DatePicker
                  {...field}
                  renderInput={(params: any) => (
                    <TextField
                      type="text"
                      {...params}
                      size="small"
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
              </Box>
            )}
          />
        );
      case IOptionalPropertyType.SELECT:
        return (
          <Controller
            name={`properties.${propertyIndex}.value`}
            control={control}
            render={({ field }) => (
              <Box>
                <InputLabel>{propertyItem?.name}</InputLabel>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  size="small"
                  sx={{
                    mt: '7px',
                  }}
                  options={convertArrayStringToArrayObject(propertyItem?.clientSetupValue)}
                  getOptionLabel={(option) => option.value}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  defaultValue={getDefaultValue(propertyItem.value)}
                  filterSelectedOptions
                  onChange={(_, values) => handleMultipleChange(values, propertyIndex)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      sx={{
                        '.MuiChip-root': {
                          backgroundColor: 'rgba(35,190,106,0.1)',
                        },
                        '.MuiChip-label': {
                          pr: 2.5,
                          color: (theme) => theme.palette.primary.main,
                        },
                        background: 'white',
                      }}
                    />
                  )}
                />
              </Box>
            )}
          />
        );
      case IOptionalPropertyType.FILE:
        return propertyItem.value.length ? (
          <FileHyperLink propertyItem={propertyItem} properties={properties} />
        ) : (
          <FieldUploadFile
            name={`properties.${propertyIndex}.value`}
            index={propertyIndex}
            control={control}
            propertyItem={propertyItem}
            setValue={setValue}
            properties={properties}
          />
        );
      default:
        break;
    }
  };

  const renderProperty = (propertyItem: IOptionalProperty, propertyIndex: number) => {
    const item = getItemProperty(propertyItem, propertyIndex);
    return (
      <Typography component="ul" key={propertyItem.key} sx={{ pl: 0, pb: 1, pt: 1 }}>
        {item}
      </Typography>
    );
  };

  const theme = useTheme();

  return (
    <Grid item xs={24} sm={8} md={8} lg={6} xl={4}>
      <Typography
        component="form"
        onSubmit={handleSubmit(handleSubmitForm)}
        sx={{
          borderRadius: '8px',
          // background: '#F5F7F9',
          background: (theme) => theme.palette.background.paper,
          minHeight: 'calc(100vh - 240px)',
          border: `1px solid ${theme.palette.divider}`,
        }}>
        <Typography gutterBottom component="div" variant="h5" sx={{ padding: '24px 24px 0' }}>
          Properties
        </Typography>
        {isLoading && skeletonProperties}
        {!isLoading && properties && properties?.length > 0 && (
          <>
            <Scrollbars style={{ height: 'calc(100vh - 400px)' }} autoHide>
              <Stack sx={{ padding: 2 }}>
                {properties.map(renderProperty)}
                <Stack sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}>
                    <InputLabel sx={{ mb: 0 }}>Person In Charge</InputLabel>
                    <IconButton onClick={handleAddPersonInCharge}>
                      <AddCircleOutline fontSize="medium" />
                    </IconButton>
                  </Box>
                  {fields.map((personInfo: IPersonInChargeInfo, personIndex: number) => {
                    return (
                      <PersonInfoForm
                        control={control}
                        setValue={setValue}
                        remove={remove}
                        personIndex={personIndex}
                        personInfo={personInfo}
                        key={personInfo.key}
                      />
                    );
                  })}
                </Stack>
                <Stack className="upload-zone" sx={{ mt: 2 }}>
                  <InputLabel>Boundary</InputLabel>
                  <Typography sx={{ my: 1 }}>
                    Alternative you can <Link underline="none">Draw</Link> on map using the drawing tools
                  </Typography>
                  <DropzoneFile
                    defaultBoundaryFile={fileBoundaryInfo}
                    onDrop={handleFileChosen}
                    levelId={levelId}
                    control={control}
                    setValue={setValue}
                  />
                </Stack>
              </Stack>
            </Scrollbars>
            <Typography
              sx={{
                padding: 3,
                mt: 4,
              }}>
              <Typography
                sx={{
                  display: 'flex',
                }}>
                <Button
                  type="submit"
                  variant="contained"
                  data-testid="next-btn"
                  sx={{
                    p: '6px',
                    width: '90px',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    dataRef.current.isSubmitAll = true;
                  }}>
                  Save All
                </Button>
              </Typography>
            </Typography>
          </>
        )}
      </Typography>
      {/* delete */}
      <ClientActionsDialog
        title="Registration Completed"
        open={openSuccessModal}
        onClose={closeDeleteModal}
        description="You have successfully added a new client"
        onCancel={closeDeleteModal}
        onSave={onSave}
        cancelLabel="Keep Editing"
        saveLabel="Go to Dashboard"
        submit
      />
    </Grid>
  );
};
