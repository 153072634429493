import { Box, Button, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { QUERY_KEY } from 'constants/constants';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { deleteIssue } from 'services/MapView/apiMapViewConfig.services';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';

interface DeleteIssueModalProps {
  isShow: boolean;
  handleClose: () => void;
}

const DeleteIssueModal: FC<DeleteIssueModalProps> = ({ isShow, handleClose }) => {
  const queryClient = useQueryClient();
  const {
    issuesTab: { deleteIssueModal },
  } = useAppSelector(rightBarSelector);

  const deleteIssueMutation = useMutation(deleteIssue, {
    onSuccess: () => {
      toast.success('Delete successful', { position: 'top-center' });
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_ISSUE_PAGINATION]);
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_DISPLAYED_ISSUES]);
      handleClose();
    },
  });

  const handleDeleteIssue = () => {
    deleteIssueMutation.mutate({ id: deleteIssueModal.id });
  };
  return (
    <CommonModal isShow={isShow} handleClose={handleClose}>
      <Box sx={{ p: 4 }}>
        <Box sx={{ mb: 2 }}>
          <Typography>Do you want to delete this issue?</Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Button variant="outlined" size="small" sx={{ ml: '8px' }} color="error" onClick={handleDeleteIssue}>
            Yes
          </Button>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default DeleteIssueModal;
