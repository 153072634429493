import { Box, Modal, useTheme } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { FC } from 'react';

interface StandExtendChartModalProps {
  isShow: boolean;
  handleClose: (arg: boolean) => void;
  extendChartOption: any;
}

const StandExtendChartModal: FC<StandExtendChartModalProps> = ({ isShow, handleClose, extendChartOption }) => {
  const theme = useTheme();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1200px',
    bgcolor: theme.palette.mode === 'dark' ? '#24292e' : '#fff',
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 24,
    p: 2,
    borderRadius: '8px',
  };

  return (
    <Modal
      open={isShow}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <ReactECharts
          style={{ height: '600px' }}
          option={extendChartOption}
          theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
        />
      </Box>
    </Modal>
  );
};

export default StandExtendChartModal;
